import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { api_endpoint, endpoint } from '../../../../global-config'


export default class Information extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',

        }
    }

    componentWillReceiveProps(nextProps) {

    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <p className={'text-24'}>ข้อมูลการนำไปใช้</p>
                <Row className={'mt-3'}>
                    <Col lg="6">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="WebsiteName">ชื่อเว็บไซด์ </Label>
                            <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="WebsiteName" placeholder="กรุณากรอกชื่อเว็บไซด์" value={this.props.data.WebsiteName} onChange={(e) => { this.props.callback(e.target.value, 'WebsiteName') }} />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="WebsiteURL">Url เว็บไซต์ </Label>
                            <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="WebsiteURL" placeholder="กรุณากรอกUrl เว็บไซต์" value={this.props.data.WebsiteURL} onChange={(e) => { this.props.callback(e.target.value, 'WebsiteURL') }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col lg="6">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="AppAndroidName">ชื่อแอปพลิเคชั่น Android </Label>
                            <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="AppAndroidName" placeholder="กรุณากรอกชื่อแอปพลิเคชั่น Android" value={this.props.data.AppAndroidName} onChange={(e) => { this.props.callback(e.target.value, 'AppAndroidName') }} />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="AppAndroidUrl">Url แอปพลิเคชั่น Android </Label>
                            <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="AppAndroidUrl" placeholder="กรุณากรอก Url แอปพลิเคชั่น Android" value={this.props.data.AppAndroidUrl} onChange={(e) => { this.props.callback(e.target.value, 'AppAndroidUrl') }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col lg="6">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="AppIosName">ชื่อแอปพลิเคชั่น iOS</Label>
                            <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="AppIosName" placeholder="กรุณากรอกชื่อแอปพลิเคชั่น iOS" value={this.props.data.AppIosName} onChange={(e) => { this.props.callback(e.target.value, 'AppIosName') }} />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="AppIosUrl">Url แอปพลิเคชั่น iOS </Label>
                            <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="AppIosUrl" placeholder="กรุณากรอก Url แอปพลิเคชั่น iOS " value={this.props.data.AppIosUrl} onChange={(e) => { this.props.callback(e.target.value, 'AppIosUrl') }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col lg="12">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="Objective">รายละเอียดการนำไปใช้ </Label>
                            <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Objective" placeholder="กรุณากรอกรายละเอียดการนำไปใช้" value={this.props.data.Objective} onChange={(e) => { this.props.callback(e.target.value, 'Objective') }} />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        )
    }
}
