import React, { Component } from 'react'
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardSubtitle
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import pana from '../../assets/images/ttd/pana.svg'
import { Link } from "react-router-dom"
import { api_endpoint } from '../../global-config'
import DisasterForm from './DisasterDetail'

import * as loadingData from "../../loadingttd.json";
import Lottie from "react-lottie";
import FadeIn from "react-fade-in";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

export default class DisasterTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lang: "th",
      placeId: null,
      status: null,
      isLoading: true,
      Thaipage: true,
      Engpage: false,
      Chinapage: false,
      userData: JSON.parse(localStorage.getItem('authUser'))
    }
    this.Thaipage = this.Thaipage.bind(this);
    this.Engpage = this.Engpage.bind(this);
    this.Chinapage = this.Chinapage.bind(this);
    console.log(props)
  }

  Thaipage() {
    this.setState({
      Thaipage: true,
      Engpage: false,
      Chinapage: false,
    })
  }

  Engpage() {
    this.setState({
      Engpage: true,
      Thaipage: false,
      Chinapage: false,
    })
  }

  Chinapage() {
    this.setState({
      Chinapage: true,
      Engpage: false,
      Thaipage: false,
    })
  }

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    const status = params.get('status');
    this.setState({
      placeId: id,
      status: status,
    }, () => {
      // console.log(this.state.placeId)
      // console.log(this.state.status)
    })
  }

  render() {
    // if (this.state.isLoading === true) {
    //     return (
    //         <div className="text-center" >
    //             <FadeIn>
    //                 <div style={{ display: "flex", justifyContent: 'center' }}>
    //                     <div >
    //                         <Lottie options={defaultOptions} height={300} width={300} />
    //                         <div>
    //                             <h4 style={{ color: 'black', padding: 30 }}>กำลังโหลด</h4>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </FadeIn>
    //         </div>
    //     )
    // }
    // else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="หน้าแรก" link0="/dashboard-blog" breadcrumbItem="รายการจัดการแจ้งเตือนภัยพิบัติ" link1="/News" breadcrumbItems={this.state.placeId === null || this.state.status === null ? "เพิ่มข้อมูลแจ้งเหตุภัยพิบัติ" : 'รายละเอียดข้อมูลแจ้งเหตุภัยพิบัติ'} />
            <Card>
              <CardBody>
                <Row style={{ padding: 15 }}>
                  <div style={{ alignSelf: 'center', width: '70%' }}>
                    <h1 className="text-24" style={{ color: '#173865' }}>{this.state.placeId !== null || this.state.status !== null ? "รายละเอียดข้อมูลแจ้งเหตุภัยพิบัติ" : 'เพิ่มข้อมูลแจ้งเหตุภัยพิบัติ'}</h1>
                    <CardSubtitle className="text-16">{this.state.placeId !== null ? "กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนดำเนินการต่อไป" : 'การกรอกข้อมูลให้ละเอียดและถูกต้องจะทำให้ผู้ใช้งานเว็บคนอื่นๆหาสถานที่ของคุณเจอได้ง่ายขึ้น'} </CardSubtitle>
                  </div>
                  <div style={{ textAlign: 'center', width: '30%' }}>
                    <img style={{ width: '10vw', height: 'auto' }} src={pana}></img>
                  </div>
                </Row>
                <hr></hr>
                <Row>
                  <Col lg={4} style={{ cursor: 'pointer' }}>
                    <div style={{ textAlign: 'center' }}>
                      <h3 className="text-18" style={{ color: this.state.Thaipage == true ? '#173865' : '#808080' }} onClick={() => { this.Thaipage() }}>ภาษาไทย</h3>
                      {this.state.Thaipage == true && <div class="hr-line"></div>}
                    </div>
                  </Col>
                  <Col lg={4} style={{ cursor: 'pointer' }}>
                    <div style={{ textAlign: 'center' }}>
                      <h3 className="text-18" style={{ color: this.state.Engpage == true ? '#173865' : '#808080' }} onClick={() => { this.Engpage() }}>English</h3>
                      {this.state.Engpage == true && <div class="hr-line"></div>}
                    </div>
                  </Col>
                  <Col lg={4} style={{ cursor: 'pointer' }}>
                    <div style={{ textAlign: 'center' }}>
                      <h3 className="text-18" style={{ color: this.state.Chinapage == true ? '#173865' : '#808080' }} onClick={() => { this.Chinapage() }}>简体中文</h3>
                      {this.state.Chinapage == true && <div class="hr-line"></div>}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {this.state.Thaipage === true && (
              <DisasterForm lang={this.state.lang} />
            )}
            {this.state.Engpage === true && (
              <DisasterForm lang={'en'} />
            )}
            {this.state.Chinapage === true && (
              <DisasterForm lang={'zh'} />
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
// }

