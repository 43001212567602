import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { api_endpoint, endpoint } from '../../../../global-config'


export default class General extends Component {
    constructor(props) {
        super(props)
        this.state = {


        }

    }

    componentWillReceiveProps(nextProps) {

    }

    componentDidMount() {

    }

    render() {
        console.log(this.props)
        return (
            <div>
                <p className={'text-24'}>ข้อมูลผู้ให้บริการ</p>
                <Row className={'mt-3'}>
                    <Col lg="4">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="ContactTitleName">คำนำหน้า </Label><Label className="required"> *</Label>
                            <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="ContactTitleName" placeholder="กรุณากรอกคำนำหน้า" value={this.props.data.ContactTitleName} onChange={(e) => { this.props.callback(e.target.value, 'ContactTitleName') }} />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="ContactFirstName">ชื่อ </Label><Label className="required"> *</Label>
                            <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="ContactFirstName" placeholder="กรุณากรอกชื่อ" value={this.props.data.ContactFirstName} onChange={(e) => { this.props.callback(e.target.value, 'ContactFirstName') }} />
                        </FormGroup>
                    </Col>
                    <Col lg="4">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="ContactLastName">นามกสุล </Label><Label className="required"> *</Label>
                            <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="ContactLastName" placeholder="กรุณากรอกนามสกุล" value={this.props.data.ContactLastName} onChange={(e) => { this.props.callback(e.target.value, 'ContactLastName') }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col lg="12">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="ContactOrganizationName">บริษัท/หน่วยงาน </Label><Label className="required"> *</Label>
                            <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="ContactOrganizationName" placeholder="กรุณากรอกชื่อบริษัท/หน่วยงาน" value={this.props.data.ContactOrganizationName} onChange={(e) => { this.props.callback(e.target.value, 'ContactOrganizationName') }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col lg="12">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="ContactAddress">ที่อยู่บริษัท/หน่วยงาน </Label><Label className="required"> *</Label>
                            <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="ContactAddress" placeholder="กรุณากรอกที่อยู่บริษัท/หน่วยงาน" value={this.props.data.ContactAddress} onChange={(e) => { this.props.callback(e.target.value, 'ContactAddress') }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col lg="6">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="ContactTelephone">เบอร์โทรศัพท์</Label><Label className="required"> *</Label>
                            <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="ContactTelephone" placeholder="กรุณากรอกเบอร์โทรศัพท์" value={this.props.data.ContactTelephone} onChange={(e) => { this.props.callback(e.target.value, 'ContactTelephone') }} />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="ContactEmail">อีเมล์</Label><Label className="required"> *</Label>
                            <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="ContactEmail" placeholder="กรุณากรอกอีเมล์" value={this.props.data.ContactEmail} onChange={(e) => { this.props.callback(e.target.value, 'ContactEmail') }} />
                        </FormGroup>
                    </Col>
                </Row>
            </div >
        )
    }
}
