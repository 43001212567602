import React, { Component } from "react"
import PropTypes from 'prop-types'


// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

//Import config
import { facebook, google } from "../../config"

// availity-reactstrap-validation


// actions
import { apiError, loginUser, socialLogin } from "../../store/actions"
import { Col, Container, Form, FormGroup, Label, Row, Input } from "reactstrap"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import CarouselPage from "./CarouselPage"
import logologin from "../../assets/images/ttd/logo-d1.svg"
import { AvField, AvForm } from "availity-reactstrap-validation"

class Login2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      User: false,
      Pass: false,

    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }
  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.loginUser(values, this.props.history)
  }

  componentDidMount() {
    this.props.apiError("")
  }

  signIn = (res, type) => {
    const { socialLogin } = this.props
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      socialLogin(postData, this.props.history, type)
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      socialLogin(postData, this.props.history, type)
    }
  }

  //handleGoogleLoginResponse
  googleResponse = response => {
    this.signIn(response, "google")
  }

  //handleTwitterLoginResponse
  twitterResponse = () => { }

  //handleFacebookLoginResponse
  facebookResponse = response => {
    this.signIn(response, "facebook")
  }


  render() {
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <CarouselPage />

              <Col xl={4}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100" >
                      <div style={{ paddingLeft: 80 }}>
                        <Link to="dashboard" className="ml-5 d-block auth-logo">
                          <img
                            src={logologin}
                            alt=""
                            className="auth-logo-dark"

                          />
                          <img
                            src={logologin}
                            alt=""

                            className="auth-logo-light"
                          />
                        </Link>
                      </div>
                      <div className="my-auto">
                      <Row>
                          <Col className="col-7">
                            <div className="text" style={{color:"black"}}>
                              <h5 className="text">Welcome</h5>
                              <p>Sign in to TDD Admin Management</p>
                            </div>
                          </Col>
                          
                        </Row>
                        <div className="mt-1">
                          <AvForm className="needs-validation"
                            className="form-horizontal"
                            onValidSubmit={this.handleValidSubmit}
                          >
                            {this.props.error && this.props.error ? (
                              <Alert color="danger">{this.props.error}</Alert>
                            ) : null}


                            <FormGroup>
                              {/* <Label for="username" htmlFor="validationCustom01">
                                Username
                            </Label> */}
                              <AvField
                                name="email"
                                label="Email"
                                value=""
                                className="form-control"
                                placeholder="Enter email"
                                // type="email"
                                required
                              />
                            </FormGroup>


                            <FormGroup>

                              <AvField
                                name="password"
                                label="Password"
                                value=""
                                type="password"
                                required
                                placeholder="Enter Password"
                              />
                            </FormGroup>



                            <div className="custom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id="auth-remember-check"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="auth-remember-check"
                              >
                                Remember me
                              </label>
                            </div>

                            <div className="mt-3">
                              <button
                                className="btn btn-primary btn-block waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                          </button>
                            </div>

                           
                          </AvForm>
                          <div className="mt-5 text-center">
                           
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                      <p style={{color:"black"}}>
                        © {new Date().getFullYear()} Thailand Tourism Directory.

                      </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
Login2.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login2)
)
