import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"


const animatedComponents = makeAnimated()




export default class Registration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',
            SpaRegistration_types: this.props.SpaRegistration_types,
            LicenseDate: this.props.Spainfo.LicenseDate,
            LicenseExpireDate: this.props.Spainfo.LicenseExpireDate,
            Spainfo: this.props.Spainfo,
            Nature: false,
            Manmade: false,
            Sport: false,
            Event: false,
            idSelected: "",
        }
        console.log(props)
    }
    selectedHandle(data, type) {
        console.log(data)
        if (type === 'SpaRegistration_types') {
            this.props.callback(JSON.parse(data).ID, 'SpaRegistration_types')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'LicenseNo') {
            this.props.callback(data, 'LicenseNo')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'LicenseDate') {
            this.props.callback(data, 'LicenseDate')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'LicenseExpireDate') {
            this.props.callback(data, 'LicenseExpireDate')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        // if (type === 'accommodation_green_leafs') {
        //     this.props.callback(JSON.parse(data).ID, 'accommodation_green_leafs')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'HasParking') {
        //     this.props.callback(data, 'HasParking')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'HasPreOrder') {
        //     this.props.callback(data, 'HasPreOrder')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'HasDelivery') {
        //     this.props.callback(data, 'HasDelivery')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'Districts') {
        //     this.props.callback(JSON.parse(data).Code, 'District')
        //     return
        // }
        // else {
        //     this.props.callback(JSON.parse(data).Code, 'County')
        //     return
        // }
    }



    render() {


        return (
            <div>
                <p className={'text-24'}>ข้อมูลการขึ้นทะเบียนและข้อมูลใบอนุญาต</p>
                {this.props.lang === 'th' ?
                    <Row className={'mt-3 font-style-16 cl-black'}>
                        <Col lg="12">
                            <FormGroup className="select2-container">
                                <Label>ประเภทการการขึ้นทะเบียน</Label>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <select className="form-control" onChange={(e) => {
                                            console.log(e.target)
                                            if (e.target.value === null) {
                                                return
                                            }
                                            else {
                                                this.selectedHandle(e.target.value, 'SpaRegistration_types')
                                            }
                                        }} >
                                            {this.state.SpaRegistration_types.filter(item => item.isCheck === true).length < 1 ? <option value={null}>กรุณาเลือกประเภทขึ้นทะเบียน</option> : null}
                                            {this.state.SpaRegistration_types.map((e, i) => {

                                                if (e.isCheck === true) {

                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                            {this.state.SpaRegistration_types.map((e, i) => {
                                                if (e.isCheck === false) {
                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    :
                    <div>
                        <Label className="font-style-16 cl-black">ประเภทการการขึ้นทะเบียน</Label>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }
                {this.props.lang === 'th' ?
                    <Row className="font-style-16 cl-black">
                        <Col lg="12">
                            <FormGroup>
                                <Label for="agencyName">เลขที่ใบอนุญาต</Label>
                                <Input required type="text" className="form-control" id="LicenseNo" placeholder="กรุณา เลขที่ใบอนุญาต" value={this.props.Spainfo.LicenseNo} onChange={(e) => { this.props.callback(e.target.value, 'LicenseNo') }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    :
                    <div>
                        <Label className="font-style-16 cl-black">เลขที่ใบอนุญาต</Label>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }
                <Row className={'mt-3 font-style-16 cl-black'}>
                    {this.props.lang === 'th' ?
                        <Col lg={6}>
                            <div className="form-group">
                                <label>วันที่เริ่มต้น (เดือน/วัน/ปี)</label>
                                <input type="date" value={this.state.LicenseDate} onChange={(e) => {
                                    this.setState({
                                        LicenseDate: e.target.value
                                    }, () => {
                                        this.selectedHandle(e.target.value, 'LicenseDate')
                                    })
                                }} placeholder="ปรับปรุงตั้งแต่วันที่" className="form-control" ></input>
                            </div>
                        </Col>
                        :
                        <Col lg={6}>
                            <Label className="font-style-16 cl-black">วันที่เริ่มต้น</Label>
                            <p className="font-style-16 cl-darkblue">
                                (ให้จัดการข้อมูลที่ภาษาไทย)
                            </p>
                        </Col>
                    }
                    {this.props.lang === 'th' ?
                        <Col lg={6}>
                            <div className="form-group">
                                <label>วันที่สิ้นสุด (เดือน/วัน/ปี)</label>
                                <input type="date" value={this.state.LicenseExpireDate} onChange={(e) => {
                                    this.setState({
                                        LicenseExpireDate: e.target.value
                                    }, () => {
                                        this.selectedHandle(e.target.value, 'LicenseExpireDate')
                                    })
                                }} placeholder="ถึงวันที่" className="form-control" ></input>
                            </div>
                        </Col>
                        :
                        <Col lg={6}>
                            <Label className="font-style-16 cl-black">วันที่สิ้นสุด</Label>
                            <p className="font-style-16 cl-darkblue">
                                (ให้จัดการข้อมูลที่ภาษาไทย)
                            </p>
                        </Col>
                    }
                </Row>
            </div>
        )
    }
}
