import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal
} from "reactstrap"

import logoplus from "../../../../assets/images/ttd/Vector.svg"
import { Link } from "react-router-dom"
import DataGrid from '../component/Table'
import classnames from "classnames"
import { api_endpoint, endpoint } from '../../../../global-config'

export default class Activity extends Component {
    constructor(props) {
        super(props)
        this.handleCoverAcceptedFiles = this.handleCoverAcceptedFiles.bind(this)
        this.handleGalleryAcceptedFiles = this.handleGalleryAcceptedFiles.bind(this)

        this.state = {
            selectedFiles: [],
            Images: this.props.Images,
            modal_standard: false,
            // Store: this.props.Store,
            // StoreInfo: this.props.StoreInfo,
            product_categories: this.props.product_categories,

            customActiveTab: "1",
            idSelected: "",
            // subActivity: [],
            Attraction: this.props.Store,
            StoreProductCategoryID: "",
            // AttractionActivitySubTypeID: "",
            ProductName: "",
            ProductDetail: "",
            ProductPrice: "",
            dataSubmit: [],
            cover_img: {},
            gallery_imgs: [],
            reload: false,

        }
        console.log(this.state.product_categories)
        this.tog_standard = this.tog_standard.bind(this)
        this.toggleCustom = this.toggleCustom.bind(this)

    }

    handleCoverAcceptedFiles = async (file) => {
        var formData = new FormData();
        formData.append('files', file, file.name);
        let upload_response = await fetch(api_endpoint + '/file/upload', {
            method: "POST",
            body: formData
        })
        let upload_response_object = await upload_response.json()
        if (upload_response_object.status == 'success') {

            this.setState({
                cover_img: upload_response_object.uploads[0]
            }, () => {
                console.log(this.state.cover_img)
            })
        } else {
            alert('เกิดข้อผิดพลาดในการอัพโหลดไฟล์')
        }
    }
    handleGalleryAcceptedFiles = async (files) => {
        var formData = new FormData(); // Currently empty
        for (let file of files) {
            formData.append('files', file, file.name);
        }
        let upload_response = await fetch(api_endpoint + '/file/upload', {
            method: "POST",
            body: formData
        })
        let upload_response_object = await upload_response.json()
        if (upload_response_object.status == 'success') {
            upload_response_object.uploads
            let tmp_images = []
            for (let upload of upload_response_object.uploads) {
                tmp_images.push(upload)
            }
            // `${endpoint}/assets/upload${upload.Path}${upload.HashName}`,
            let gallery_imgs = this.state.gallery_imgs
            gallery_imgs = gallery_imgs.concat(tmp_images)
            this.setState({
                gallery_imgs: gallery_imgs
            }, () => {
                console.log(this.state.gallery_imgs)
            })
        } else {
            alert('เกิดข้อผิดพลาดในการอัพโหลดไฟล์')
        }
    }


    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    async uploadPic(data) {
        let formData = new FormData()
        for (let file of data) {
            formData.append('files[]', file, file.name);
        }
        const response = await fetch(
            api_endpoint + `/file/upload`,
            {
                method: "POST",
                headers: {

                },
                body: formData
            }
        );
        var _result = await response.json();
        if (_result.status === 'success') {
            const userData = JSON.parse(localStorage.getItem('authUser'))
            const asyncRes = await Promise.all(_result.uploads.map(async (e) => {
                return { ...e, Created: new Date(), CreatedBy: userData.UserID }
            }, () => {
                console.log(asyncRes)
            }));
            this.props.submit('ImageUpload', asyncRes)
            alert('อัพโหลดภาพสำเร็จ')
        }
        else {
            console.log('error uploadPic')
        }









    }




    toggleCustom(tab) {
        if (this.state.customActiveTab !== tab) {
            this.setState({
                customActiveTab: tab,
            })
        }
    }


    tog_standard() {
        this.setState(prevState => ({
            modal_standard: !prevState.modal_standard,
        }))
        this.removeBodyCss()
    }

    removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    onChangeThai() {
        this.setState({
            Thai: true
        })
    }
    onChangeEnglish() {
        this.setState({
            English: true
        })
    }

    onChangeChina() {
        this.setState({
            China: true
        })
    }

    // subActivity(id) {

    //     let arr = this.state.StoreInfo
    //     console.log(arr)
    //     // let filter = arr.filter(item => item.ID === Number(id))
    //     // if (filter[0].SubTypes !== undefined) {
    //     //     this.setState({
    //     //         subActivity: filter[0].SubTypes
    //     //     }, () => {
    //     //         // console.log(this.state.subActivity)
    //     //     })
    //     // }
    //     // else {
    //     //     this.setState({
    //     //         subActivity: []
    //     //     })
    //     // }



    // }

    submitStore() {
        // if (typeof this.state.cover_img.ID == 'undefined') {
        //     return alert('กรุณาอัพโหลดรูปหน้าปก')
        // }
        // if (this.state.gallery_imgs.length == 0) {
        //     return alert('กรุณาอัพโหลดรูปภาพร้าน')
        // }
        let arr = [...this.state.dataSubmit]

        arr.push(
            {
                id: arr.length,
                StoreProductCategoryID: Number(this.state.StoreProductCategoryID),
                // Images: this.state.Images,
                ProductName: this.state.ProductName,
                ProductPrice: this.state.ProductPrice,
                ProductDetail: this.state.ProductDetail,
                Cover_img: this.state.cover_img,
                Gallery_imgs: this.state.gallery_imgs
                // AttractionActivitySubTypeID: Number(this.state.AttractionActivitySubTypeID),
                // Details: {
                //     Lang: this.props.lang,
                //     Name: this.state.ProductName,
                //     Detail: this.state.ProductDetail,
                //     Price: this.state.ProductPrice,

                // }
            }
        )
        this.setState({
            dataSubmit: arr,
            modal_standard: !this.state.modal_standard,
            reload: false
        }, () => {
            this.props.submit('Store', this.state.dataSubmit)
        })
    }


    selectedHandle(data, type) {
        console.log(data)
        if (type === 'product_categories') {
            this.props.callback((data).ID, 'product_categories')
            return
        }
        if (type === 'StoreProductCategoryID') {
            this.props.callback((data).ID, 'StoreProductCategoryID')
            return
        }
        if (type === 'ProductDetail') {
            this.props.callback((data), 'ProductDetail')
            return
        }
        if (type === 'ProductName') {
            this.props.callback((data), 'ProductName')
            return
        }
        if (type === 'ProductPrice') {
            this.props.callback((data), 'ProductPrice')
            return
        }
        // if (type === 'accommodation_service_types') {
        //     this.props.callback(JSON.parse(data).ID, 'accommodation_service_types')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'cost_levels') {
        //     this.props.callback(JSON.parse(data).ID, 'cost_levels')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'IsThaiHotalAssociation') {
        //     this.props.callback(data, 'IsThaiHotalAssociation')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'IsHotalRegistration') {
        //     this.props.callback(data, 'IsHotalRegistration')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'LicenseDate') {
        //     this.props.callback(data, 'LicenseDate')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'LicenseExpireDate') {
        //     this.props.callback(data, 'LicenseExpireDate')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'LicenseNo') {
        //     this.props.callback(data, 'LicenseNo')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }



    }

    componentDidMount() {
        this.props.submit('Store', this.state.dataSubmit)
        // // this.props.submit('Product', this.state.dataSubmit)
        this.setState({
            dataSubmit: this.props.Store
        })
    }




    render() {
        // console.log(this.props)
        return (
            <div>
                <Label className={'text-24'}>ข้อมูลสินค้า</Label>
                {this.props.lang === 'th' ?
                    <Row className={'text-16'} style={{ paddingBottom: 15 }}>
                        <Col style={{ textAlign: 'end', alignSelf: 'center' }}>
                            <button type="button" onClick={this.tog_standard} class="btn btn-primary" data-toggle="modal" data-target="#myModal" style={{ backgroundColor: '#173865', borderColor: '#173865' }}>
                                <img src={logoplus} style={{ padding: '0px 10px 3px 0px', width: 30 }} /><span className="d-none d-sm-inline-block font-style-16nb" >เพิ่มข้อมูล</span>{" "}
                            </button>
                        </Col>
                    </Row>
                    :
                    <div>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }

                <Modal
                    isOpen={this.state.modal_standard}
                    toggle={this.tog_standard}
                >
                    <div className="modal-header">
                        <h5 className="font-style-24 cl-black modal-title mt-0" id="myModalLabel">
                            ข้อมูลสินค้า
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                this.setState({ modal_standard: false })
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Nav tabs className="nav-tabs-custom">
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: this.state.customActiveTab === "1",
                                    })}
                                    onClick={() => {
                                        this.toggleCustom("1")
                                    }}
                                >
                                    <span className="font-style-16 d-none d-sm-block">ภาษาไทย</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: this.state.customActiveTab === "2",
                                    })}
                                    onClick={() => {
                                        this.toggleCustom("2")
                                    }}
                                >
                                    <span className="font-style-16 d-none d-sm-block">English</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: this.state.customActiveTab === "3",
                                    })}
                                    onClick={() => {
                                        this.toggleCustom("3")
                                    }}
                                >
                                    <span className="font-style-16 d-none d-sm-block">简体中文</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <TabContent activeTab={this.state.customActiveTab}>
                        <TabPane tabId="1" className="p-3">
                            <FormGroup className="font-style-16 cl-black">
                                <Label for="Address">ชื่อสินค้า </Label><Label className="required"> *</Label>
                                <Input required type="text" className="form-control" id="Address" placeholder="กรุณากรอกชื่อสินค้า" value={this.state.ProductName} onChange={(e) => { this.setState({ ProductName: e.target.value }, () => { this.props.callback(e.target.value, 'ProductName') }) }} />
                            </FormGroup>
                            <Label className="font-style-16 cl-black" for="Address">ประเภทสินค้า  </Label><Label className="required"> *</Label>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <select className="form-control" onChange={(e) => {
                                        this.setState({
                                            idSelected: e.target.value,
                                            StoreProductCategoryID: e.target.value
                                        }, () => {
                                        })
                                    }} >
                                        {this.state.product_categories.map((e, i) => {
                                            return (
                                                <option key={i} value={e.ID}>{e.Details.th.Name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            {/* <div className="form-group row">
                                    <div className="col-md-12">
                                        <select className="form-control" onChange={(e) => {
                                            console.log(e.target)
                                            if (e.target.value === -1) {
                                                return
                                            }
                                            else {
                                                this.selectedHandle(e.target.value, 'product_categories')
                                            }
                                        }} >
                                            {this.state.product_categories.filter(item => item.isCheck === true).length < 1 ? <option value={-1}>กรุณาเลือกประเภทสินค้า</option> : null}
                                            {this.state.product_categories.map((e, i) => {

                                                if (e.isCheck === true) {

                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                            {this.state.product_categories.map((e, i) => {
                                                if (e.isCheck === false) {
                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div> */}
                            <FormGroup className="font-style-16 cl-black">
                                <Label for="agencyName">ราคา </Label><Label className="required"> *</Label>
                                <Input required type="text" className="form-control" id="Name" placeholder="กรุณากรอกราคา" value={this.state.ProductPrice} onChange={(e) => { this.setState({ ProductPrice: e.target.value }, () => { this.props.callback(e.target.value, 'ProductPrice') }) }} />
                            </FormGroup>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup className="font-style-16 cl-black">
                                        <Label for="agencyName">รูปภาพหน้าปก</Label>
                                        {typeof this.state.cover_img.ID == 'undefined' && (
                                            <div className="custom-file" >
                                                <input
                                                    onChange={e =>
                                                        // console.log([e.target.files[0]])
                                                        this.handleCoverAcceptedFiles(e.target.files[0])
                                                    }
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="customFile"
                                                />
                                                <label className="custom-file-label" htmlFor="customFile">
                                                    {this.state.selectedFiles.length < 1 ?
                                                        <span className="font-style-16b4 cl-gray"> Choose file</span>
                                                        :
                                                        <div>
                                                            {this.state.selectedFiles.map(f => {
                                                                return (<span style={{ marginRight: 10 }}>{f.name}</span>)
                                                            })}
                                                        </div>
                                                    }
                                                </label>
                                            </div>
                                        )}
                                        {typeof this.state.cover_img.ID != 'undefined' && (
                                            <div className="col-md-12">
                                                <img style={{ maxWidth: '100%' }} src={`${endpoint}/assets/upload${this.state.cover_img.Path}${this.state.cover_img.HashName}`}></img><br></br>
                                                <div style={{ textAlign: 'center', marginTop: 15 }}>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            this.setState({
                                                                cover_img: {}
                                                            })
                                                        }}
                                                        className="btn btn-danger waves-effect"
                                                        data-dismiss="modal"
                                                    >
                                                        ลบภาพ
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <FormGroup className="font-style-16 cl-black">
                                        <Label for="agencyName">รูปภาพแกลอรี่ </Label>
                                        <div className="custom-file" >
                                            <input
                                                onChange={e =>
                                                    // console.log([e.target.files[0]])
                                                    this.handleGalleryAcceptedFiles(e.target.files)
                                                }
                                                multiple
                                                type="file"
                                                className="custom-file-input"
                                                id="customFile"
                                            />
                                            <label className="custom-file-label" htmlFor="customFile">
                                                {this.state.selectedFiles.length < 1 ?
                                                    <span className="font-style-16b4 cl-gray"> Choose file</span>
                                                    :
                                                    <div>
                                                        {this.state.selectedFiles.map(f => {
                                                            return (<span style={{ marginRight: 10 }}>{f.name}</span>)
                                                        })}
                                                    </div>
                                                }
                                            </label>
                                        </div>

                                    </FormGroup>
                                </Col>
                            </Row>
                            {this.state.gallery_imgs.length > 0 && this.state.gallery_imgs.map((gallery_img, img_index) => {
                                return (
                                    <Row key={img_index}>
                                        <Col lg={3}>
                                            <img style={{ maxWidth: '100%' }} src={`${endpoint}/assets/upload${gallery_img.Path}${gallery_img.HashName}`}></img>
                                        </Col>
                                        <Col lg={9}>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    let gallery_imgs = this.state.gallery_imgs
                                                    gallery_imgs.splice(img_index, 1);
                                                    this.setState({
                                                        gallery_imgs: gallery_imgs
                                                    })
                                                }}
                                                className="btn btn-danger waves-effect btn-sm"
                                                data-dismiss="modal"
                                            >
                                                ลบภาพ
                                            </button>
                                        </Col>
                                    </Row>
                                )
                            })}
                            <Row>
                                <Col lg={12}>
                                    <FormGroup className="font-style-16 cl-black">
                                        <Label>รายละเอียด</Label>
                                        <Input
                                            type="textarea"
                                            id="textarea"
                                            onChange={(e) => { this.setState({ ProductDetail: e.target.value }) }}
                                            value={this.state.ProductDetail}
                                            // maxLength="225"
                                            rows="3"
                                            placeholder=""

                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {this.state.textareabadge ? (
                                <span className="badgecount badge badge-success">
                                    {" "}
                                    {this.state.textcount} / 225{" "}
                                </span>
                            ) : null}
                        </TabPane>
                        <TabPane tabId="2" className="p-3">
                            <FormGroup className="font-style-16 cl-black">
                                <Label for="Address">ชื่อสินค้า </Label><Label className="required"> *</Label>
                                <Input required type="text" className="form-control" id="Address" placeholder="กรุณากรอกชื่อสินค้า" value={this.state.ProductName} onChange={(e) => { this.setState({ ProductName: e.target.value }, () => { this.props.callback(e.target.value, 'ProductName') }) }} />
                            </FormGroup>
                            <FormGroup className="font-style-16 cl-black">
                                <Label for="agencyName">ราคา </Label><Label className="required"> *</Label>
                                <Input required type="text" className="form-control" id="Name" placeholder="กรุณากรอกราคา" value={this.state.ProductPrice} onChange={(e) => { this.setState({ ProductPrice: e.target.value }, () => { this.props.callback(e.target.value, 'ProductPrice') }) }} />
                            </FormGroup>
                            <FormGroup className="font-style-16 cl-black">
                                <Label>รายละเอียด</Label>
                                <Input
                                    type="textarea"
                                    id="textarea"
                                    onChange={(e) => { this.setState({ ProductDetail: e.target.value }) }}
                                    value={this.state.ProductDetail}
                                    // maxLength="225"
                                    rows="3"
                                    placeholder=""

                                />
                            </FormGroup>
                        </TabPane>
                        <TabPane tabId="3" className="p-3">
                            <FormGroup className="font-style-16 cl-black">
                                <Label for="Address">ชื่อสินค้า </Label><Label className="required"> *</Label>
                                <Input required type="text" className="form-control" id="Address" placeholder="กรุณากรอกชื่อสินค้า" value={this.state.ProductName} onChange={(e) => { this.setState({ ProductName: e.target.value }, () => { this.props.callback(e.target.value, 'ProductName') }) }} />
                            </FormGroup>
                            <FormGroup className="font-style-16 cl-black">
                                <Label for="agencyName">ราคา </Label><Label className="required"> *</Label>
                                <Input required type="text" className="form-control" id="Name" placeholder="กรุณากรอกราคา" value={this.state.ProductPrice} onChange={(e) => { this.setState({ ProductPrice: e.target.value }, () => { this.props.callback(e.target.value, 'ProductPrice') }) }} />
                            </FormGroup>
                            <FormGroup className="font-style-16 cl-black">
                                <Label>รายละเอียด</Label>
                                <Input
                                    type="textarea"
                                    id="textarea"
                                    onChange={(e) => { this.setState({ ProductDetail: e.target.value }) }}
                                    value={this.state.ProductDetail}
                                    // maxLength="225"
                                    rows="3"
                                    placeholder=""

                                />
                            </FormGroup>
                        </TabPane>
                    </TabContent>
                    <div className="modal-footer">
                        <Row style={{ justifyContent: 'flex-end' }}>
                            <div className="col-auto">
                                <Button
                                    style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                    type="submit"
                                    onClick={() => { this.setState({ reload: true }, () => { this.submitStore() }) }}
                                    className="chat-send w-md waves-effect waves-light"
                                >
                                    <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}
                                </Button>
                            </div>
                            <div className="col-auto">
                                <Button
                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                                    onClick={this.tog_standard}
                                    className="chat-send w-md waves-effect waves-light"
                                >
                                    <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}
                                </Button>
                            </div>
                        </Row>
                    </div>
                </Modal>


                {this.state.reload === false && this.props.lang === 'th' ?
                    <DataGrid pageSize={5}
                        data={[
                            { field: 'id', headerName: 'ลำดับ', width: 100 },
                            {
                                field: 'Cover_img', headerName: 'ภาพตัวอย่าง', width: 150,
                                renderCell: (params) => {
                                    return (
                                        <img
                                            data-dz-thumbnail=""
                                            width="100"
                                            height="100"
                                            className="avatar-sm rounded bg-light"
                                            //   alt={e.name}
                                            src={`${endpoint}/assets/upload${params.value.Path}${params.value.HashName}`}
                                        />
                                    )
                                }
                            },
                            { field: 'ProductName', headerName: 'ชื่อสินค้า', width: 200 },
                            { field: 'ProductDetail', headerName: 'รายละเอียดสินค้า', width: 200 },
                            { field: 'ProductPrice', headerName: 'ราคาสินค้า', width: 200 },
                            {
                                field: 'Manage',
                                headerName: 'จัดการ', width: 150,
                                renderCell: (params) => (
                                    <strong>
                                        <Link>
                                            {/* <i className="mdi mdi-chevron-right ml-1"></i> */}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{ marginLeft: 16 }}
                                                onClick={() => {
                                                    let tmp_data_submit = [...this.state.dataSubmit]
                                                    let index = tmp_data_submit.map(data => data.id).indexOf(params.row.id)
                                                    tmp_data_submit.splice(index, 1);
                                                    this.setState({
                                                        dataSubmit: tmp_data_submit
                                                    }, () => {
                                                        this.props.submit('Store', this.state.dataSubmit)
                                                    })
                                                }}
                                            >
                                                ลบ
                                            </Button>
                                        </Link>
                                    </strong>
                                ),
                            }]}
                        dataSubmit={this.state.dataSubmit}
                        checkboxSelection />
                    :
                    <div></div>
                }
            </div>
        )
    }
}
