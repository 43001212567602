import PropTypes from "prop-types"
import React, { Component } from "react"

//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userData: JSON.parse(localStorage.getItem('authUser'))
    }
    this.refDiv = React.createRef()
  }

  componentDidMount() {
    this.initMenu()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    return (
      <React.Fragment>
        <SimpleBar style={{ maxHeight: "100%" }} ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="/dashboard-blog" className="waves-effect">
                  <i className="bx bx-home-circle" />
                  <span className="font-style-16b4">หน้าหลัก</span>
                </Link>
              </li>
              <li>
                <Link to="/dashboard-report" className="waves-effect">
                  <i className="bx bx-home-circle" />
                  <span className="font-style-16b4">ข้อมูลทางสถิติ</span>
                </Link>
              </li>
              <li className="menu-title" style={{ fontSize: 16, fontWeight: 500 }}>ข้อมูล</li>
              <li>
                <Link to="/dashboard-crypto" className="has-arrow waves-effect">
                  <i className="bx bx-calendar" />
                  <span className="font-style-16b4">จัดการข้อมูล</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/managelocation" style={{ fontSize: 16, fontWeight: 500 }}>แหล่งท่องเที่ยว</Link>
                  </li>
                  <li>
                    <Link to="/Travelactivities" style={{ fontSize: 16, fontWeight: 500 }}>กิจกรรมการท่องเที่ยว</Link>
                  </li>
                  <li>
                    <Link to="/TouristRoutes" style={{ fontSize: 16, fontWeight: 500 }}>เส้นทางการท่องเที่ยว</Link>
                  </li>
                  <li>
                    <Link to="/Accommodation" style={{ fontSize: 16, fontWeight: 500 }}>ที่พัก</Link>
                  </li>
                  <li>
                    <Link to="/Travelbusinessoperator" style={{ fontSize: 16, fontWeight: 500 }}>ผู้ประกอบธุรกิจนำเที่ยว</Link>
                  </li>
                  <li>
                    <Link to="/Spa" style={{ fontSize: 16, fontWeight: 500 }}>สปา</Link>
                  </li>
                  <li>
                    <Link to="/Restaurant" style={{ fontSize: 16, fontWeight: 500 }}>ร้านอาหาร</Link>
                  </li>
                  <li>
                    <Link to="/Shop" style={{ fontSize: 16, fontWeight: 500 }}>ร้านค้า</Link>
                  </li>
                  {(this.state.userData.UserSubType === 'Admin' || this.state.userData.UserSubType === 'SuperAdmin') ?
                    <li>
                      <Link to="/Commentlist" style={{ fontSize: 16, fontWeight: 500 }}>ความคิดเห็น</Link>
                    </li>
                  :
                    <></>
                  }
                  <li>
                    <Link to="/notifyinformation" style={{ fontSize: 16, fontWeight: 500 }}>แจ้งแก้ไขข้อมูล</Link>
                  </li>
                </ul>
              </li>
              {(this.state.userData.UserSubType === 'Admin' || this.state.userData.UserSubType === 'SuperAdmin') ?
                <>
                  <li>
                    <Link to="/dashboard-crypto" className="has-arrow waves-effect">
                      <i className="bx bx-calendar" />
                      <span className="font-style-16b4">จัดการบทความ</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/Content" style={{ fontSize: 16, fontWeight: 500 }}>บทความ</Link>
                      </li>
                      <li>
                        <Link to="/News" style={{ fontSize: 16, fontWeight: 500 }}>ข่าวสารและกิจกรรม</Link>
                      </li>
                      <li>
                        <Link to="/Story" style={{ fontSize: 16, fontWeight: 500 }}>เรื่องราว</Link>
                      </li>
                      <li>
                        <Link to="/Campaign" style={{ fontSize: 16, fontWeight: 500 }}>Campaign</Link>
                      </li>
                      <li>
                        <Link to="/Disaster" style={{ fontSize: 16, fontWeight: 500 }}>แจ้งเหตุภัยพิบัติ</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/Openapi" className="waves-effect">
                      <i className="bx bx-calendar" />
                      <span className="font-style-16b4">จัดการ Open API</span>
                    </Link>
                  </li>
                </>
              :
                <></>
              }
              
              {(this.state.userData.UserSubType === 'Admin' || this.state.userData.UserSubType === 'SuperAdmin') ?
                <>
                  <li className="menu-title" style={{ fontSize: 16, fontWeight: 500 }}>ผู้ใช้งาน</li>
                  <li>
                    <Link to="/Manageteammainpage">
                      <i class="fas fa-users"></i>
                      <span className="font-style-16b4">ทีมผู้จัดทำข้อมูล</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/ManageUserRole">
                      <i class="fas fa-users"></i>
                      <span className="font-style-16b4">การจัดการสมาชิก</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/HistoryUserRole">
                      <i class="fas fa-users"></i>
                      <span className="font-style-16b4">ประวัติผู้ใช้งาน</span>
                    </Link>
                  </li> */}
                </>
                :
                <div />
              }
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
}

export default withRouter(withTranslation()(SidebarContent))
