import React, { Component } from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"

class TopCities extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <Card >
          <CardBody>
            <CardTitle className="mb-0">จังหวัดที่มีแหล่งท่องเที่ยวมากที่สุด</CardTitle>
            <div className="text-center">
              <div className="mb-0">
                <i className="bx bx-map-pin text-primary display-4" />
              </div>
              <h3>1,456</h3>
              <p>เชียงราย</p>
            </div>

            <div className="table-responsive mt-0">
              <table className="table table-centered table-nowrap mb-0">
                <tbody>
                  <tr>
                    <td style={{ width: "30%" }}>
                      <p className="mb-0">เชียงราย</p>
                    </td>
                    <td style={{ width: "25%" }}>
                      <h5 className="mb-0">1,456</h5>
                    </td>
                    <td>
                      <Progress
                        value="94"
                        color="primary"
                        className="bg-transparent"
                        size="sm"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="mb-0">นนทบุรี</p>
                    </td>
                    <td>
                      <h5 className="mb-0">1,123</h5>
                    </td>
                    <td>
                      <Progress
                        value="82"
                        color="success"
                        className="bg-transparent"
                        size="sm"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="mb-0">เชียงใหม่</p>
                    </td>
                    <td>
                      <h5 className="mb-0">1,026</h5>
                    </td>
                    <td>
                      <Progress
                        value="70"
                        color="warning"
                        className="bg-transparent"
                        size="sm"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default TopCities
