import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"

export default class Facilities extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',
            Facilities: this.props.Facilities,
            //part1
            Incinerator: false,
            Landfill: false,
            Recyclewaste: false,
            Garbagesorting: false,
            Other1: false,
            //part2
            Electricgenerator: false,
            Electricity: false,
            Other2: false,
            //part3
            Undergroundwater: false,
            Watersystem: false,
            Naturalwater: false,
            plumbing: false,
            Other3: false,
            //part4
            CCTV: false,
            Emergencyexit: false,
            Fireextinguishers: false,
            Securityguard: false,
            FirstAidUnitsample: false,
            FirstAidUnit: false,
            Alarm: false,
            Other4: false,
            //part5
            Wifisignal: false,
            Publicphone: false,
            Telephonesignal: false,
            Internetsignal: false,
            Postoffice: false,
            Other5: false,
            //part6
            Wastewatertreatment: false,
        }
        // console.log(this.state.Facilities)
    }

    componentDidMount() {
        let data = []
        let filter = this.state.Facilities.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
        filter.map((e) => {
            e.forEach(element => { if (element.isCheck === true) { data.push({ AccommodationFacilityTypeID: element.AccommodationFacilityTypeID, AccommodationFacilitySubTypeID: element.ID }) } });
        })
        this.props.submit('Facilities', data)
    }

    render() {
        return (
            <div>

                <Label className={'text-24'}>สาธารณูปโภค</Label>
                {this.props.lang === 'th' &&
                    <Row>
                        <Col lg={6}>
                            <Label className="font-style-16 cl-black">บริการอินเทอร์เน็ต</Label>
                            {this.state.Facilities.map((e, i) => {
                                if (e.ID === 1) {
                                    return (
                                        <div key={i} className="text-16">
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Facilities
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Facilities: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Facilities.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AccommodationFacilityTypeID: element.AccommodationFacilityTypeID, AccommodationFacilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Facilities', data)
                                                            })

                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4 cl-black"

                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    )


                                }
                                else {
                                    return null
                                }
                            })}


                        </Col>

                        <Col lg={6}>
                            <Label className={'mt-3 font-style-16 cl-black'}>การออกกำลังกายและพักผ่อนหย่อนใจ</Label>
                            {this.state.Facilities.map((e, i) => {
                                if (e.ID === 2) {
                                    return (
                                        <div key={i} className={'text-16'}>
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Facilities
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Facilities: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Facilities.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AccommodationFacilityTypeID: element.AccommodationFacilityTypeID, AccommodationFacilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Facilities', data)
                                                            })
                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4 cl-black"
                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}


                        </Col>
                        <Col lg={6}>
                            <Label className={'mt-3 font-style-16 cl-black'}>กิจกรรมผ่อนคลาย</Label>
                            {this.state.Facilities.map((e, i) => {
                                if (e.ID === 3) {
                                    return (
                                        <div key={i} className={'text-16'}>
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Facilities
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Facilities: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Facilities.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AccommodationFacilityTypeID: element.AccommodationFacilityTypeID, AccommodationFacilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Facilities', data)
                                                            })
                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4 cl-black"
                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                        <Col lg={6}>
                            <Label className={'mt-3 font-style-16 cl-black'}>บริการและสิ่งอำนวยความสะดวก</Label>
                            {this.state.Facilities.map((e, i) => {
                                if (e.ID === 4) {
                                    return (
                                        <div key={i} className={'text-16'}>
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Facilities
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Facilities: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Facilities.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AccommodationFacilityTypeID: element.AccommodationFacilityTypeID, AccommodationFacilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Facilities', data)
                                                            })
                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4 cl-black"
                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                        <Col lg={6}>
                            <Label className={'mt-3 font-style-16 cl-black'}>บริการสำหรับเด็ก</Label>
                            {this.state.Facilities.map((e, i) => {
                                if (e.ID === 5) {
                                    return (
                                        <div key={i} className="text-16">
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Facilities
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Facilities: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Facilities.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AccommodationFacilityTypeID: element.AccommodationFacilityTypeID, AccommodationFacilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Facilities', data)
                                                            })
                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4 cl-black"
                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                        <Col lg={6}>
                            <Label className={'mt-3 font-style-16 cl-black'}>อาหาร เครื่องดื่ม ของว่าง</Label>
                            {this.state.Facilities.map((e, i) => {
                                if (e.ID === 6) {
                                    return (
                                        <div key={i} className="text-16">
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Facilities
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Facilities: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Facilities.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AccommodationFacilityTypeID: element.AccommodationFacilityTypeID, AccommodationFacilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Facilities', data)
                                                            })
                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4 cl-black"
                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                        <Col lg={6}>
                            <Label className={'mt-3 font-style-16 cl-black'}>การเข้าที่พัก</Label>
                            {this.state.Facilities.map((e, i) => {
                                if (e.ID === 7) {
                                    return (
                                        <div key={i} className="text-16">
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Facilities
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Facilities: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Facilities.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AccommodationFacilityTypeID: element.AccommodationFacilityTypeID, AccommodationFacilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Facilities', data)
                                                            })
                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4 cl-black"
                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                        <Col lg={6}>
                            <Label className={'mt-3 font-style-16 cl-black'}>การเดินทางไปที่ต่างๆ</Label>
                            {this.state.Facilities.map((e, i) => {
                                if (e.ID === 8) {
                                    return (
                                        <div key={i} className="text-16">
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Facilities
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Facilities: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Facilities.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AccommodationFacilityTypeID: element.AccommodationFacilityTypeID, AccommodationFacilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Facilities', data)
                                                            })
                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4 cl-black"
                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                        <Col lg={12}>
                            <Label className={'mt-3 font-style-16 cl-black'}>ภาษาที่พนักงานให้บริการ</Label>
                            {this.state.Facilities.map((e, i) => {
                                if (e.ID === 10) {
                                    return (
                                        <div key={i} className="text-16">
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Facilities
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Facilities: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Facilities.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AccommodationFacilityTypeID: element.AccommodationFacilityTypeID, AccommodationFacilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Facilities', data)
                                                            })
                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4 cl-black"
                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                        <Col lg={12}>
                            <Label className={'mt-3 font-style-16 cl-black'}>มีให้บริการทุกห้อง</Label>
                            {this.state.Facilities.map((e, i) => {
                                if (e.ID === 9) {
                                    return (
                                        <div key={i} className="text-16">
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Facilities
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Facilities: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Facilities.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AccommodationFacilityTypeID: element.AccommodationFacilityTypeID, AccommodationFacilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Facilities', data)
                                                            })
                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4 cl-black"
                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                    </Row>
                }
                {this.props.lang === 'en' &&
                    <FormGroup className="font-style-16 cl-black">
                        <p style={{ color: '#173865' }}>
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </FormGroup>
                }
                {this.props.lang === 'zh' &&
                    <FormGroup className="font-style-16 cl-black">
                        <p style={{ color: '#173865' }}>
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </FormGroup>
                }
            </div>
        )
    }
}
