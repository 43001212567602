import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
export default class Journey extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',
            TravelTypeID: this.props.Travel_types,
            bike: false,
            Motorbike: false,
            Skytrain: false,
            Subway: false,
            foot: false,
            bus: false,
            train: false,
            car: false,
            ship: false,
        }
    }

    componentDidMount() {
        let filter = this.state.TravelTypeID.filter(item => item.isCheck === true)
        for (let i = 0; i < filter.length; i++) {
            filter[i] = { TravelTypeID: filter[i].ID }
        }
        this.props.submit('Travel_types', filter)
    }

    render() {
        return (
            <div>
                <Label className={'text-24'}>การเดินทาง</Label>
                {/* <CardTitle className={'mt-3'}>การเดินทาง</CardTitle> */}
                {this.props.lang === 'th' &&
                    <Row>
                        <Col lg={4}>
                            {this.state.TravelTypeID.map((e, i) => {
                                // console.log(e)
                                if (e.ID === 1) {
                                    return (
                                        <div key={i}>
                                            <div className="custom-control custom-checkbox mb-3"
                                                onClick={() => {
                                                    let arr = this.state.TravelTypeID
                                                    arr[i].isCheck = !arr[i].isCheck
                                                    this.setState({
                                                        TravelTypeID: arr
                                                    }, () => {
                                                        let filter = this.state.TravelTypeID.filter(item => item.isCheck === true)
                                                        for (let i = 0; i < filter.length; i++) {
                                                            filter[i] = { TravelTypeID: filter[i].ID }
                                                        }
                                                        this.props.submit('Travel_types', filter)
                                                    })
                                                }}>
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={e.isCheck}
                                                />
                                                <label
                                                    className="custom-control-label font-style-16b4 cl-black"
                                                >
                                                    {e.Details[this.state.Lang].Name}
                                                </label>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                            {this.state.TravelTypeID.map((e, i) => {
                                // console.log(e)
                                if (e.ID === 2) {
                                    return (
                                        <div key={i}>
                                            <div className="custom-control custom-checkbox mb-3"
                                                onClick={() => {
                                                    let arr = this.state.TravelTypeID
                                                    arr[i].isCheck = !arr[i].isCheck
                                                    this.setState({
                                                        TravelTypeID: arr
                                                    }, () => {
                                                        let filter = this.state.TravelTypeID.filter(item => item.isCheck === true)
                                                        for (let i = 0; i < filter.length; i++) {
                                                            filter[i] = { TravelTypeID: filter[i].ID }
                                                        }
                                                        this.props.submit('Travel_types', filter)
                                                    })
                                                }}>
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={e.isCheck}
                                                />
                                                <label
                                                    className="custom-control-label font-style-16b4 cl-black"
                                                >
                                                    {e.Details[this.state.Lang].Name}
                                                </label>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                            {this.state.TravelTypeID.map((e, i) => {
                                // console.log(e)
                                if (e.ID === 3) {
                                    return (
                                        <div key={i}>
                                            <div className="custom-control custom-checkbox mb-3"
                                                onClick={() => {
                                                    let arr = this.state.TravelTypeID
                                                    arr[i].isCheck = !arr[i].isCheck
                                                    this.setState({
                                                        TravelTypeID: arr
                                                    }, () => {
                                                        let filter = this.state.TravelTypeID.filter(item => item.isCheck === true)
                                                        for (let i = 0; i < filter.length; i++) {
                                                            filter[i] = { TravelTypeID: filter[i].ID }
                                                        }
                                                        this.props.submit('Travel_types', filter)
                                                    })
                                                }}>
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={e.isCheck}
                                                />
                                                <label
                                                    className="custom-control-label font-style-16b4 cl-black"
                                                >
                                                    {e.Details[this.state.Lang].Name}
                                                </label>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                        <Col lg={4}>
                            {this.state.TravelTypeID.map((e, i) => {
                                // console.log(e)
                                if (e.ID === 4) {
                                    return (
                                        <div key={i}>
                                            <div className="custom-control custom-checkbox mb-3"
                                                onClick={() => {
                                                    let arr = this.state.TravelTypeID
                                                    arr[i].isCheck = !arr[i].isCheck
                                                    this.setState({
                                                        TravelTypeID: arr
                                                    }, () => {
                                                        let filter = this.state.TravelTypeID.filter(item => item.isCheck === true)
                                                        for (let i = 0; i < filter.length; i++) {
                                                            filter[i] = { TravelTypeID: filter[i].ID }
                                                        }
                                                        this.props.submit('Travel_types', filter)
                                                    })
                                                }}>
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={e.isCheck}
                                                />
                                                <label
                                                    className="custom-control-label font-style-16b4 cl-black"
                                                >
                                                    {e.Details[this.state.Lang].Name}
                                                </label>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                            {this.state.TravelTypeID.map((e, i) => {
                                // console.log(e)
                                if (e.ID === 5) {
                                    return (
                                        <div key={i}>
                                            <div className="custom-control custom-checkbox mb-3"
                                                onClick={() => {
                                                    let arr = this.state.TravelTypeID
                                                    arr[i].isCheck = !arr[i].isCheck
                                                    this.setState({
                                                        TravelTypeID: arr
                                                    }, () => {
                                                        let filter = this.state.TravelTypeID.filter(item => item.isCheck === true)
                                                        for (let i = 0; i < filter.length; i++) {
                                                            filter[i] = { TravelTypeID: filter[i].ID }
                                                        }
                                                        this.props.submit('Travel_types', filter)
                                                    })
                                                }}>
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={e.isCheck}
                                                />
                                                <label
                                                    className="custom-control-label font-style-16b4 cl-black"
                                                >
                                                    {e.Details[this.state.Lang].Name}
                                                </label>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                            {this.state.TravelTypeID.map((e, i) => {
                                // console.log(e)
                                if (e.ID === 6) {
                                    return (
                                        <div key={i}>
                                            <div className="custom-control custom-checkbox mb-3"
                                                onClick={() => {
                                                    let arr = this.state.TravelTypeID
                                                    arr[i].isCheck = !arr[i].isCheck
                                                    this.setState({
                                                        TravelTypeID: arr
                                                    }, () => {
                                                        let filter = this.state.TravelTypeID.filter(item => item.isCheck === true)
                                                        for (let i = 0; i < filter.length; i++) {
                                                            filter[i] = { TravelTypeID: filter[i].ID }
                                                        }
                                                        this.props.submit('Travel_types', filter)
                                                    })
                                                }}>
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={e.isCheck}
                                                />
                                                <label
                                                    className="custom-control-label font-style-16b4 cl-black"
                                                >
                                                    {e.Details[this.state.Lang].Name}
                                                </label>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}

                        </Col>
                        <Col lg={4}>
                            {this.state.TravelTypeID.map((e, i) => {
                                // console.log(e)
                                if (e.ID === 7) {
                                    return (
                                        <div key={i}>
                                            <div className="custom-control custom-checkbox mb-3"
                                                onClick={() => {
                                                    let arr = this.state.TravelTypeID
                                                    arr[i].isCheck = !arr[i].isCheck
                                                    this.setState({
                                                        TravelTypeID: arr
                                                    }, () => {
                                                        let filter = this.state.TravelTypeID.filter(item => item.isCheck === true)
                                                        for (let i = 0; i < filter.length; i++) {
                                                            filter[i] = { TravelTypeID: filter[i].ID }
                                                        }
                                                        this.props.submit('Travel_types', filter)
                                                    })
                                                }}>
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={e.isCheck}
                                                />
                                                <label
                                                    className="custom-control-label font-style-16b4 cl-black"
                                                >
                                                    {e.Details[this.state.Lang].Name}
                                                </label>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                            {this.state.TravelTypeID.map((e, i) => {
                                // console.log(e)
                                if (e.ID === 8) {
                                    return (
                                        <div key={i}>
                                            <div className="custom-control custom-checkbox mb-3"
                                                onClick={() => {
                                                    let arr = this.state.TravelTypeID
                                                    arr[i].isCheck = !arr[i].isCheck
                                                    this.setState({
                                                        TravelTypeID: arr
                                                    }, () => {
                                                        let filter = this.state.TravelTypeID.filter(item => item.isCheck === true)
                                                        for (let i = 0; i < filter.length; i++) {
                                                            filter[i] = { TravelTypeID: filter[i].ID }
                                                        }
                                                        this.props.submit('Travel_types', filter)
                                                    })
                                                }}>
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={e.isCheck}
                                                />
                                                <label
                                                    className="custom-control-label font-style-16b4 cl-black"
                                                >
                                                    {e.Details[this.state.Lang].Name}
                                                </label>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                            {this.state.TravelTypeID.map((e, i) => {
                                // console.log(e)
                                if (e.ID === 9) {
                                    return (
                                        <div key={i}>
                                            <div className="custom-control custom-checkbox mb-3"
                                                onClick={() => {
                                                    let arr = this.state.TravelTypeID
                                                    arr[i].isCheck = !arr[i].isCheck
                                                    this.setState({
                                                        TravelTypeID: arr
                                                    }, () => {
                                                        let filter = this.state.TravelTypeID.filter(item => item.isCheck === true)
                                                        for (let i = 0; i < filter.length; i++) {
                                                            filter[i] = { TravelTypeID: filter[i].ID }
                                                        }
                                                        this.props.submit('Travel_types', filter)
                                                    })
                                                }}>
                                                <input

                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={e.isCheck}
                                                />
                                                <label
                                                    className="custom-control-label font-style-16b4 cl-black"
                                                >
                                                    {e.Details[this.state.Lang].Name}
                                                </label>

                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                    </Row>
                }
                {this.props.lang === 'en' &&
                    <FormGroup className="font-style-16 cl-black">
                        <p style={{ color: '#173865' }}>
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </FormGroup>
                }
                {this.props.lang === 'zh' &&
                    <FormGroup className="font-style-16 cl-black">
                        <p style={{ color: '#173865' }}>
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </FormGroup>
                }
            </div>
        )
    }
}
