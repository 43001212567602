import React, { Component } from "react"
import { Container, Row, Col, Card, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AllDashboard from "./AllDashboard"

export default class index extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="หน้าแรก" breadcrumbItem="dashboard-report" />
                        <Container fluid>
                            <AllDashboard />
                        </Container>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
