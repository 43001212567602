import React, { Component } from "react"
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Label,
  Row,
} from "reactstrap"

// Form Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

class FormEditorsContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editorState: this.props.details !== undefined ? EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(this.props.details))) : null
    }
    console.log(props)
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  onEditorStateChange(e) {
    this.setState({
      editorState: e
    });
  };

  render() {

    return (
      <React.Fragment>


        <Row>
          <Col>

            <Label className={'text-24'}>{this.props.title || 'รายละเอียดเพิ่มเติม'}</Label>

            <Form className={'mt-3'} method="post">
              <Editor
                onChange={(e) => { if (this.props.callback !== undefined) { this.props.callback(draftToHtml(e), 'Detail') } }}
                editorState={this.state.editorState}
                onEditorStateChange={(e) => this.onEditorStateChange(e)}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
              >
              </Editor>
            </Form>

          </Col>
        </Row>

      </React.Fragment>
    )
  }
}

export default FormEditorsContent
