import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { Link } from "react-router-dom"

import General from './topictouristRout/General'
import Picturesvideos from './topic/Picturesvideos'
import More from './topictouristRout/More'
import List from './topictouristRout/List'
import Status from './topic/Status'
import Feedback from './topicAll/Feedback';

import { api_endpoint } from '../../../global-config'
const moment = require('moment'); // require
import localization from 'moment/locale/th';
moment.updateLocale('th', localization);

export default class TouristRoutestap1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            IDEditter: null,
            IsEnabled: "Y",
            lang: props.lang,
            isLoading: true,
            isLoadingDetail: false,
            placeId: null,
            Attraction: [],
            Activities: [],
            Targets: [],
            Facilities: [],
            Utility: [],
            Types: [],
            TravelTypeID: [],
            OpenHours: [],
            Images: [],
            ImagesUpload: [],
            provinces: [],
            districts: [],
            subdistricts: [],
            Feedback: "",
            Route: [],
            routeId: null,
            th: {
                Name: '',
                IntroText: '',
                Activities: [],
                CoverImages: {},
                MapImage: {},
                Details: "",
                TravelRemark: "",
                History: "",
                AreaSize: "",
                ParkingDetail: "",
                ResponseDepartment: "",
                Feature: "",
                //
                Address: "",
                RegionID: "",
                Province: "",
                District: "",
                County: "",
                Latitude: "",
                Longitude: "",
                //
                Telephone: "",
                Mobile: "",
                Email: "",
                Website: "",
                Personname: "",
                Contactposition: "",
                //
                OpenHourRemark: "",
                OpencloseRemark: "",
                businesshours: "",
                Suitablerange: "",
                VdoYoutubeUrl: "",
                MoreDetail: ""
            },
            en: {
                Name: '',
                IntroText: '',
                Activities: [],
                Details: "",
                TravelRemark: "",
                History: "",
                AreaSize: "",
                ParkingDetail: "",
                ResponseDepartment: '',
                Feature: "",
                //
                Address: "",
                RegionID: "",
                Province: "",
                District: "",
                County: "",
                Latitude: "",
                Longitude: "",
                //
                Telephone: "",
                Mobile: "",
                Email: "",
                Website: "",
                Personname: "",
                Contactposition: "",
                //
                OpenHourRemark: "",
                OpencloseRemark: "",
                businesshours: "",
                Suitablerange: "",
                MoreDetail: ""

            },
            zh: {
                Name: '',
                IntroText: '',
                Activities: [],
                Details: "",
                TravelRemark: "",
                History: "",
                AreaSize: "",
                ParkingDetail: "",
                ResponseDepartment: "",
                Feature: "",
                //
                Address: "",
                RegionID: "",
                Province: "",
                District: "",
                County: "",
                Latitude: "",
                Longitude: "",
                //
                Telephone: "",
                Mobile: "",
                Email: "",
                Website: "",
                Personname: "",
                Contactposition: "",
                //
                OpenHourRemark: "",
                OpencloseRemark: "",
                businesshours: "",
                Suitablerange: "",
                MoreDetail: ""
            },
            Submit: {},
            status: null
        }
        this.callback = this.callback.bind(this);


        this.submitForm = this.submitForm.bind(this);

    }

    async get_provinces() {
        const response = await fetch(
            api_endpoint + `/lkup/provinces`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    RegionID: [],
                    Code: [],

                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();

        for (let i = 0; i < _result.length; i++) {
            if (_result[i].Details[this.state.lang].Name === this.state[this.state.lang].Province) {
                _result[i] = { ..._result[i], isCheck: true }
                let obj = this.state[this.state.lang]
                obj.Province = _result[i].Code
                obj.RegionID = _result[i].RegionID
                this.setState({
                    [this.state.lang]: obj
                })
            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            provinces: _result
        }, () => {
            this.get_districts()
        })
    }


    async get_districts() {
        if (this.state[this.state.lang].District === "" && this.state[this.state.lang].Province === "") {
            this.setState({
                districts: _result,
                isLoading: false
            }, () => {
            })
            return
        }
        const response = await fetch(
            api_endpoint + `/lkup/districts`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ProvinceCode: this.state[this.state.lang].Province.toString(),
                    // Code: [],

                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();


        for (let i = 0; i < _result.length; i++) {
            if (_result[i].Details[this.state.lang].Name === this.state[this.state.lang].District) {
                _result[i] = { ..._result[i], isCheck: true }
                let obj = this.state[this.state.lang]
                obj.District = _result[i].Code
                this.setState({
                    [this.state.lang]: obj
                })
            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            districts: _result,
            isLoading: false
        }, () => {
        })
    }














    callback(value, target) {
        if (target === 'IsEnabled') {
            this.state.IsEnabled = value
            this.setState({
                IsEnabled: this.state.IsEnabled
            }, () => {
                console.log(this.state.IsEnabled)
            })
            return
        }
        if (target === 'Feedback') {
            this.setState({
                Feedback: value
            }, () => {
                console.log(this.state.IsEnabled)
            })
            return
        }
        if (target === 'Latitude') {
            value = parseFloat(value)
        }
        if (target === 'Longitude') {
            value = parseFloat(value)
        }
        let obj = this.state[this.state.lang]
        // console.log(this.state.lang)
        obj[target] = value;
        console.log(obj)
        this.setState({
            [this.state.lang]: obj
        }, () => {
            if (target === 'Province') {
                this.get_districts()
            }
            if (target === 'District') {
                this.get_subdistricts()
            }

        })
    }

    submitForm(target, value) {

        if (this.state.Submit[target] === undefined) {
            this.state.Submit[target] = value
        }
        else {
            this.state.Submit[target] = value
        }
        console.log(this.state.Submit)

    }

    async getRouteDetail(id) {
        let path = `/maininfo/readone`
        if (this.state.status !== null) { path = '/maininfo/readoneupdate' }
        const userData = JSON.parse(localStorage.getItem('authUser'))
        const response = await fetch(
            api_endpoint + path,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                    ID: Number(id),
                    MainTypeID: [9],
                    IsBackend: 'Y'

                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        if (_result.result !== undefined) {
            let Route = []
            _result.result.Route.map(e => {
                Route.push({
                    id: e.ID, Name: e.Name[this.state.lang],
                    Province: e.Province[this.state.lang],
                    District: e.District[this.state.lang],
                    IntroImage: e.IntroImage,
                    MainInfoType: e.MainInfoType[this.state.lang]
                })
            })
            let Images = []
            _result.result.Images.map(e => {
                Images.push(e)
            })
            console.log(Images)

            let th = this.state.th
            let en = this.state.en
            let zh = this.state.zh
            if (this.state.lang === 'th') {
                th = {
                    ...this.state.th,
                    Name: _result.result.Name[this.state.lang],
                    CoverImages: _result.result.IntroImage,
                    //-----------------------------------------------//
                    Details: _result.result.Description[this.state.lang],
                    IntroText: _result.result.IntroText[this.state.lang],

                    Province: _result.result.Province[this.state.lang],
                    VdoYoutubeUrl: _result.result.VdoYoutubeUrl
                }
            }
            if (this.state.lang === 'en') {
                en = {
                    ...this.state.en,
                    Name: _result.result.Name[this.state.lang],
                    CoverImages: _result.result.IntroImage,
                    //-----------------------------------------------//
                    Details: _result.result.Description[this.state.lang],
                    IntroText: _result.result.IntroText[this.state.lang],

                    Province: _result.result.Province[this.state.lang],
                    VdoYoutubeUrl: _result.result.VdoYoutubeUrl
                }
            }
            if (this.state.lang === 'zh') {
                zh = {
                    ...this.state.zh,
                    Name: _result.result.Name[this.state.lang],
                    CoverImages: _result.result.IntroImage,
                    //-----------------------------------------------//
                    Details: _result.result.Description[this.state.lang],
                    IntroText: _result.result.IntroText[this.state.lang],

                    Province: _result.result.Province[this.state.lang],
                    VdoYoutubeUrl: _result.result.VdoYoutubeUrl
                }
            }
            this.state.IDEditter = _result.result.ID,
                this.state.IsEnabled = _result.result.IsEnabled,
                this.state.Feedback = _result.result.Feedback,
                this.setState({
                    th: th,
                    en: en,
                    zh: zh,
                    [this.state.lang]: {
                        ...this.state[this.state.lang],
                        Name: _result.result.Name[this.state.lang],
                        //-----------------------------------------------//
                        Details: _result.result.Description[this.state.lang],
                        IntroText: _result.result.IntroText[this.state.lang],

                        Province: _result.result.Province[this.state.lang],
                        VdoYoutubeUrl: _result.result.VdoYoutubeUrl
                        //-----------------------------------------------//
                    },
                    Route: Route,
                    Images: Images,
                    isLoadingDetail: false
                }, () => {
                    this.get_provinces()
                    console.log(this.state.CoverImages)
                })
        }
        else {
            console.log('Error : getRouteDetail ')
        }
    }

    async submitData() {
        if (this.state[this.state.lang].Name === "") {
            return alert('กรุณาใส่ชื่อ')
        }
        if (this.state.Submit.ImageUpload === undefined) {
            return alert('กรุณาใส่รูป')
        }

        let IsEnabled = this.state.IsEnabled
        let Feedback = this.state.Feedback
        const userData = JSON.parse(localStorage.getItem('authUser'))
        if (userData.UserSubType === "Editor") {
            console.log('Editer >>>>')
            IsEnabled = "N"
            console.log(IsEnabled)
        }
        let obj = {
            MainTypeID: 9,
            IsEnabled: IsEnabled,
            Feedback: Feedback,
            Main: {
                Info: {

                    ProvinceCode: this.state[this.state.lang].Province.toString(),
                    CategoryID: 0,
                    IsPolicySecondaryTown: null,
                    HashName: this.state.Submit.ImageUpload[0].HashName,
                    Path: this.state.Submit.ImageUpload[0].Path,
                    RegionID: 1,
                    VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl,
                    IntroImage: this.state.Submit.IntroImage,
                },
                Details: {
                    [this.state.lang]: {
                        Lang: this.state.lang,
                        Name: this.state[this.state.lang].Name,
                        IntroText: this.state[this.state.lang].IntroText,
                        Description: this.state[this.state.lang].Details,

                    }
                },
                Images: this.state.Submit.ImageUpload,
            },
            Route: this.state.Submit.Route

        }
        //ปิดส่ง
        // console.log(JSON.stringify(obj))
        // return
        const response = await fetch(
            api_endpoint + `/maininfo/create`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj), // body data type must match "Content-Type" header

            }
        );
        var _result = await response.json();
        console.log(_result)
        if (_result.status === "ok") {
            alert('เพิ่มเส้นทางการท่องเที่ยวสำเร็จ')
            window.location.href = "/TouristRoutes";
        }
        else {
            console.log('error : submitData')
        }
    }

    async updateData() {
        const userData = JSON.parse(localStorage.getItem('authUser'))
        let obj = {
            MainTypeID: 9,
            ID: Number(this.state.routeId),
            IsEnabled: this.state.IsEnabled,
            Feedback: this.state.Feedback,
            Main: {
                Info: {

                    ProvinceCode: this.state[this.state.lang].Province.toString(),
                    CategoryID: 0,
                    IsPolicySecondaryTown: null,
                    // HashName: this.state.Submit.ImageUpload[0].HashName,
                    // Path: this.state.Submit.ImageUpload[0].Path,
                    RegionID: 1,
                    VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl,
                    IntroImage: this.state.Submit.IntroImage,
                },
                Details: {
                    [this.state.lang]: {
                        Lang: this.state.lang,
                        Name: this.state[this.state.lang].Name,
                        IntroText: this.state[this.state.lang].IntroText,
                        Description: this.state[this.state.lang].Details,

                    }
                },
                Images: this.state.Submit.ImageUpload,
            },
            Route: this.state.Submit.Route

        }
        if (obj.Main.Images === undefined) {
            delete obj.Main.Images
        }

        const response = await fetch(
            api_endpoint + `/maininfo/update`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj), // body data type must match "Content-Type" header
                MainTypeID: [9]
            }
        );
        var _result = await response.json();
        console.log(_result)
        if (_result.status === "ok") {
            alert('แก้ไขเส้นทางการท่องเที่ยวสำเร็จ')
            if (userData.UserSubType !== 'Admin' && userData.UserSubType !== 'SuperAdmin') {
                window.location.href = "/TouristRoutes";
            }
        }
        else {
            console.log('error : updateData')
        }
    }




    async Approve() {
        const userData = JSON.parse(localStorage.getItem('authUser'))
        let obj = {
            MainTypeID: 9,
            ID: Number(this.state.IDEditter),
            IsEnabled: this.state.IsEnabled,
            Feedback: this.state.Feedback,
            Main: {
                Info: {

                    ProvinceCode: this.state[this.state.lang].Province.toString(),
                    CategoryID: 0,
                    IsPolicySecondaryTown: null,
                    // HashName: this.state.Submit.ImageUpload[0].HashName,
                    // Path: this.state.Submit.ImageUpload[0].Path,
                    RegionID: 1,
                    VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl,
                    IntroImage: this.state.Submit.IntroImage,
                },
                Details: {
                    [this.state.lang]: {
                        Lang: this.state.lang,
                        Name: this.state[this.state.lang].Name,
                        IntroText: this.state[this.state.lang].IntroText,
                        Description: this.state[this.state.lang].Details,

                    }
                },
                Images: this.state.Submit.ImageUpload,
            },
            Route: this.state.Submit.Route

        }
        if (obj.Main.Images === undefined) {
            delete obj.Main.Images
        }

        const response = await fetch(
            api_endpoint + `/maininfo/approve`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj),
            }
        );
        var _result = await response.json();

        if (_result.status === "ok") {


            alert('อนุมัติแก้ไขเส้นทางท่องเที่ยวสำเร็จ')

        }
        else {
            console.log('error : Approve')
        }
    }

    async reject() {
        console.log(Number(this.state.IDEditter))
        const userData = JSON.parse(localStorage.getItem('authUser'))
        let obj = {}
        if (this.state.lang === 'th') {
            obj = {
                ID: Number(this.state.IDEditter),
            }
        }
        if (this.state.lang === 'en') {
            obj = {
                ID: Number(this.state.IDEditter),
            }
        }
        if (this.state.lang === 'zh') {
            obj = {
                ID: Number(this.state.IDEditter),
            }
        }
        obj['Feedback'] = this.state.Feedback

        const response = await fetch(
            api_endpoint + `/maininfo/reject`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj),
            }
        );
        var _result = await response.json();
        console.log(_result)
        if (_result.status === "ok") {


            alert('แจ้งส่งกลับแก้ไขเส้นทางท่องเที่ยวสำเร็จ')

        }
        else {
            console.log('error : reject')
        }
    }

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const id = params.get('id');
        const status = params.get('status');
        if (id !== null) {
            console.log('in')
            this.setState({
                routeId: id,
                status: status,
                cloneId: id,
                isLoadingDetail: true
            }, () => {
                this.getRouteDetail(id)
            })

        }
        else {
            console.log('out')
            this.get_provinces()
        }
    }

    componentWillReceiveProps(nextProps) {

        // console.log(nextProps)
        if (nextProps.lang) {
            this.setState({
                lang: nextProps.lang
            }, () => {
                // console.log(this.state.lang)
            })
        }
    }

    render() {
        if (this.state.isLoading === true || this.state.isLoadingDetail === true) {
            return (
                <div className="text-center" >



                </div>
            )
        }
        else {
            const userData = JSON.parse(localStorage.getItem('authUser'))
            console.log(userData)
            console.log(this.state.status)
            return (

                <div>
                    <Card>
                        <CardBody>
                            <General callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Types={this.state.Types} submit={this.submitForm} provinces={this.state.provinces} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <More callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Images={this.state.Images} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Picturesvideos callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Images={this.state.Images} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <List lang={this.state.lang} submit={this.submitForm} route={this.state.Route} />
                        </CardBody>
                    </Card>
                    {this.state.status === 'Y' ?
                        (userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ?
                            <Card>
                                <CardBody>
                                    <Feedback callback={this.callback} lang={this.state.lang} disabled={true} data={this.state} submit={this.submitForm} />
                                </CardBody>
                            </Card>
                            :
                            <Card>
                                <CardBody>
                                    <Feedback callback={this.callback} lang={this.state.lang} disabled={false} data={this.state} submit={this.submitForm} />
                                </CardBody>
                            </Card>
                        :
                        <div />
                    }
                    <Card>
                        <CardBody>
                            {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.routeId === null ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                            {this.state.routeId !== null && this.state.status !== 'Y' && (userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                            {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.status === 'Y' ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                            {this.state.routeId !== null && this.state.status !== 'Y' && userData.UserSubType !== 'Admin' && userData.UserSubType !== 'SuperAdmin' ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                            {/* < Status /> */}
                            {this.state.routeId === null ?
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                            type="submit"
                                            onClick={() => { this.submitData() }}

                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}

                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/TouristRoutes">
                                            <Button
                                                style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                            </Button>
                                        </Link>
                                    </div>
                                </Row>
                                :

                                <div>

                                </div>
                            }
                            {this.state.routeId !== null && this.state.status !== 'Y' ?
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                            type="submit"
                                            onClick={() => { this.updateData() }}

                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">แก้ไข</span>{" "}
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/TouristRoutes">
                                            <Button
                                                style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                            </Button>
                                        </Link>
                                    </div>
                                </Row>
                                :
                                <div></div>
                            }
                            {this.state.status === 'Y' && userData.UserSubType === 'Admin' && userData.UserType === 'B' ?
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                            type="submit"
                                            onClick={() => { this.Approve() }}

                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">อนุมัติการแก้ไข</span>{" "}
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#F4D006', borderColor: '#F4D006' }}
                                            className="chat-send w-md waves-effect waves-light"
                                            onClick={() => { this.reject() }}
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">แจ้งส่งกลับแก้ไข</span>{" "}

                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/notifyinformation">
                                            <Button
                                                style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                            </Button>
                                        </Link>

                                    </div>
                                </Row>
                                :
                                <div>

                                </div>
                            }
                            {this.state.status === 'Y' && userData.UserSubType === 'Editor' && userData.UserType === 'B' ?
                                <div></div>
                                :
                                <div></div>

                            }
                        </CardBody>
                    </Card>

                </div>

            )
        }

    }
}
