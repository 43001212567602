import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { api_endpoint, endpoint } from '../../../../global-config'

export default class General extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',
            Types: this.props.Types,
            AgencyRegistrationinfo: this.props.AgencyRegistrationinfo,
            Nature: false,
            Manmade: false,
            Sport: false,
            Event: false,
            placeType: [],
            upload_images_loading: false,
        }
        console.log(this.props.AgencyRegistrationinfo)
    }

    typeSelected(value, target) {
        if (value) {
            this.state.placeType.push(target)
        }
        else {
            this.state.placeType.splice(this.state.placeType.indexOf(target), 1)
        }
        this.props.callback(this.state.placeType, 'Activities')
    }
    handleAcceptedFiles = async (files) => {
        if (files.length == 1) {
            let [file] = files
            console.log(file)
            var formData = new FormData(); // Currently empty
            formData.append('files', file, file.name);
            let upload_response = await fetch(api_endpoint + '/file/upload', {
                method: "POST",
                body: formData
            })
            let upload_response_object = await upload_response.json()
            if (upload_response_object.status == 'success') {
                console.log(upload_response_object.uploads[0])
                this.props.callback(upload_response_object.uploads[0], 'CoverImages')
                this.props.submit('IntroImage', upload_response_object.uploads[0])
            } else {
                alert('เกิดข้อผิดพลาดในการอัพโหลดไฟล์')
            }
        }
        this.setState({
            upload_images_loading: false
        })

    }
    selectedHandle(data, type) {
        console.log('sssssssssssssssssss')
        console.log(data)
        if (type === 'AgencyRegistration_types') {
            this.props.callback(JSON.parse(data).ID, 'AgencyRegistration_types')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'IsATTAMember') {
            this.props.callback(data, 'IsATTAMember')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'IsSNTMember') {
            this.props.callback(data, 'IsSNTMember')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'IsSTNMember') {
            this.props.callback(data, 'IsSTNMember')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'IsSTOMember') {
            this.props.callback(data, 'IsSTOMember')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'ServiceCharge') {
            this.props.callback(data, 'ServiceCharge')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'LicenseExpireDate') {
            this.props.callback(data, 'LicenseExpireDate')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }

    }

    render() {
        return (
            <div>
                <p className={'text-24'}>ข้อมูลทั่วไป</p>
                <Row className={'mt-3 font-style-16 cl-black'}>
                    <Col lg="12">
                        {this.props.lang === 'th' &&
                            <FormGroup>
                                <Label for="Name">ชื่อ </Label><Label className="required"> *</Label>
                                <Input required type="text" className="form-control" id="Name" placeholder="กรุณากรอกชื่อ" value={this.props.data.Name} onChange={(e) => { this.props.callback(e.target.value, 'Name') }} />
                            </FormGroup>
                        }
                        {this.props.lang === 'en' &&
                            <FormGroup>
                                <Label for="Name">ชื่อ </Label><Label className="required"> *</Label>
                                <Input required type="text" className="form-control" id="Name" placeholder="กรุณากรอกชื่อ" value={this.props.data.Name} onChange={(e) => { this.props.callback(e.target.value, 'Name') }} />
                            </FormGroup>
                        }
                        {this.props.lang === 'zh' &&
                            <FormGroup>
                                <Label for="Name">ชื่อ </Label><Label className="required"> *</Label>
                                <Input required type="text" className="form-control" id="Name" placeholder="กรุณากรอกชื่อ" value={this.props.data.Name} onChange={(e) => { this.props.callback(e.target.value, 'Name') }} />
                            </FormGroup>
                        }
                    </Col>
                </Row>
                {this.props.lang === 'th' &&
                    <Row className={'mt-3 font-style-16 cl-black'}>
                        <Col lg="12">
                            <Label>รายละเอียด </Label><Label className="required"> *</Label>
                            <Input
                                type="textarea"
                                id="textarea"
                                onChange={(e) => { this.props.callback(e.target.value, 'Details') }}
                                // maxLength="225"
                                rows="3"
                                placeholder=""
                                value={this.props.data.Details}
                            />
                            {this.state.textareabadge ? (
                                <span className="badgecount badge badge-success">
                                    {" "}
                                    {this.state.textcount} / 225{" "}
                                </span>
                            ) : null}
                        </Col>
                    </Row>
                }
                {this.props.lang === 'en' &&
                    <Row className={'mt-3 font-style-16 cl-black'}>
                        <Col lg="12">
                            <Label>รายละเอียด </Label><Label className="required"> *</Label>
                            <Input
                                type="textarea"
                                id="textarea"
                                onChange={(e) => { this.props.callback(e.target.value, 'Details') }}
                                // maxLength="225"
                                rows="3"
                                placeholder=""
                                value={this.props.data.Details}
                            />
                            {this.state.textareabadge ? (
                                <span className="badgecount badge badge-success">
                                    {" "}
                                    {this.state.textcount} / 225{" "}
                                </span>
                            ) : null}
                        </Col>
                    </Row>
                }
                {this.props.lang === 'zh' &&
                    <Row className={'mt-3 font-style-16 cl-black'}>
                        <Col lg="12">
                            <Label>รายละเอียด </Label><Label className="required"> *</Label>
                            <Input
                                type="textarea"
                                id="textarea"
                                onChange={(e) => { this.props.callback(e.target.value, 'Details') }}
                                // maxLength="225"
                                rows="3"
                                placeholder=""
                                value={this.props.data.Details}
                            />
                            {this.state.textareabadge ? (
                                <span className="badgecount badge badge-success">
                                    {" "}
                                    {this.state.textcount} / 225{" "}
                                </span>
                            ) : null}
                        </Col>
                    </Row>
                }
                {this.props.lang === 'th' ?
                    <Row className={'mt-3 font-style-16 cl-black'}>
                        <Col lg="12">
                            <Label>รูปภาพหน้าปก {this.props.data.test} </Label><Label className="required"> * {this.state.upload_images_loading && (<font color="green">โปรดรอกำลังอัพโหลดรูปภาพ</font>)}</Label>
                            <div style={{ display: typeof this.props.data.CoverImages.HashName != 'undefined' ? 'none' : '' }} className="custom-file" >
                                <input
                                    onChange={e => {
                                        this.setState({
                                            upload_images_loading: true
                                        }, () => {
                                            this.handleAcceptedFiles(e.target.files)
                                            e.target.value = ""
                                        }
                                        )
                                    }}
                                    accept=".jpeg,.gif,.png,.jpg"
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                />
                                <label className="custom-file-label" htmlFor="customFile">
                                    <span className="font-style-16 cl-gray"> Choose file</span>
                                    {/* {this.state.selectedFiles.length < 1 ?
                                        <span> Choose file</span>
                                        :
                                        <div>
                                            {this.state.selectedFiles.map(f => {
                                                return (<span style={{ marginRight: 10 }}>{f.name}</span>)
                                            })}
                                        </div>
                                    } */}
                                </label>
                                <p className="mt-3 font-style-14 darkblue">ขนาดไฟล์ไม่เกิน 2 MB, เฉพาะไฟล์นามสกุล: .jpg, .jpeg, .gif, .png และขนาดไฟล์ที่เหมาะสม: 800 x 600 pixel</p>
                            </div>
                            {typeof this.props.data.CoverImages.HashName != 'undefined' && (
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <img style={{ maxWidth: 350 }} src={`${endpoint}/assets/upload${this.props.data.CoverImages.Path}/${this.props.data.CoverImages.HashName}`}></img>
                                        <div style={{ marginTop: 25 }}>
                                            <button onClick={() => {
                                                this.props.callback({}, 'CoverImages')
                                                this.props.submit('IntroImage', {})
                                            }} type="button" class="btn btn-danger waves-effect waves-light">ยกเลิกไฟล์</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                    :
                    <Row className={'mt-3 font-style-16 cl-black'}>
                        <Col lg="12">
                            <Label>รูปภาพหน้าปก </Label><Label className="required"> *</Label>
                            <p style={{ color: '#173865' }}>
                                (ให้จัดการข้อมูลที่ภาษาไทย)
                            </p>
                        </Col>
                    </Row>
                }
                {this.props.lang === 'th' ?
                    <Row className={'mt-3 font-style-16 cl-black'}>
                        <Col lg={3} className={'mt-3 '}>
                            <Label>ความเป็นสมาชิก</Label>
                        </Col>
                        <Col lg={9} className={'mt-3 '} style={{ flexDirection: 'column' }}>
                            <Row>
                                <div className="custom-control custom-checkbox mb-3"
                                    onClick={() => {
                                        if (this.state.AgencyRegistrationinfo.IsATTAMember === "Y") {
                                            this.selectedHandle("N", 'IsATTAMember')
                                        }
                                        else {
                                            this.selectedHandle("Y", 'IsATTAMember')
                                        }
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={'IsATTAMember'}
                                        onChange={() => false}
                                        checked={this.state.AgencyRegistrationinfo.IsATTAMember === "Y" ? true : false}
                                    />
                                    <label
                                        className="custom-control-label"
                                    >
                                        เป็นสมาชิกสมาคมไทยธุรกิจการท่องเที่ยว
                                    </label>
                                </div>
                            </Row>
                            <Row>
                                <div className="custom-control custom-checkbox mb-3"
                                    onClick={() => {
                                        if (this.state.AgencyRegistrationinfo.IsSNTMember === "Y") {
                                            this.selectedHandle("N", 'IsSNTMember')
                                        }
                                        else {
                                            this.selectedHandle("Y", 'IsSNTMember')
                                        }
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={'IsSNTMember'}
                                        onChange={() => false}
                                        checked={this.state.AgencyRegistrationinfo.IsSNTMember === "Y" ? true : false}
                                    />
                                    <label
                                        className="custom-control-label"
                                    >
                                        เป็นสมาชิกสมาคมผู้ประกอบการนำเที่ยวไทย
                                    </label>
                                </div>
                            </Row>
                            <Row>
                                <div className="custom-control custom-checkbox mb-3"
                                    onClick={() => {
                                        if (this.state.AgencyRegistrationinfo.IsSTNMember === "Y") {
                                            this.selectedHandle("N", 'IsSTNMember')
                                        }
                                        else {
                                            this.selectedHandle("Y", 'IsSTNMember')
                                        }
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={'IsSTNMember'}
                                        onChange={() => false}
                                        checked={this.state.AgencyRegistrationinfo.IsSTNMember === "Y" ? true : false}
                                    />
                                    <label
                                        className="custom-control-label"
                                    >
                                        เป็นสมาชิกสมาคมธุรกิจท่องเที่ยวภายในประเทศ
                                    </label>
                                </div>
                            </Row>
                            <Row>
                                <div className="custom-control custom-checkbox mb-3"
                                    onClick={() => {
                                        if (this.state.AgencyRegistrationinfo.IsSTOMember === "Y") {
                                            this.selectedHandle("N", 'IsSTOMember')
                                        }
                                        else {
                                            this.selectedHandle("Y", 'IsSTOMember')
                                        }
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={'IsSTOMember'}
                                        onChange={() => false}
                                        checked={this.state.AgencyRegistrationinfo.IsSTOMember === "Y" ? true : false}
                                    />
                                    <label
                                        className="custom-control-label"
                                    >
                                        เป็นสมาชิกสมาคมไทยท่องเที่ยวเชิงอนุรักษ์และผจญภัย
                                    </label>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    :
                    <div>
                        <Label className="font-style-16 cl-black">ความเป็นสมาชิก</Label>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }



            </div>
        )
    }
}
