import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
  darken, lighten
} from "@material-ui/core/styles";

function getThemePaletteMode(palette) {
  return palette.type || palette.mode;
}

const defaultTheme = createMuiTheme({
  typography: {
    fontFamily: [
      'Prompt',
      // 'BlinkMacSystemFont',
      // '"Segoe UI"',
      // 'Roboto',
      // '"Helvetica Neue"',
      // 'Arial',
      // 'sans-serif',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(','),
  },
});

const useStyles = makeStyles(
  (theme) => {
    const getBackgroundColor = (color) =>
      getThemePaletteMode(theme.palette) === 'dark'
        ? darken(color, 0.6)
        : lighten(color, 0.6);

    const getHoverBackgroundColor = (color) =>
      getThemePaletteMode(theme.palette) === 'dark'
        ? darken(color, 0.5)
        : lighten(color, 0.5);

    return {
      root: {
        '& .super-app-theme-IsEnabled-Y': {
          backgroundColor: getBackgroundColor(theme.palette.info.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
          },
        },

        '& .super-app-theme-IsEnabled-N': {
          backgroundColor: getBackgroundColor(theme.palette.error.main),
          '&:hover': {
            backgroundColor: getHoverBackgroundColor(theme.palette.error.main),
          },
        },
      },
    };
  },
  { defaultTheme },
);
export default function DataTable(props) {
  const classes = useStyles();

  const columns = props.header;
  const data = props.data
  const pageSize = props.pageSize
  const rowCount = props.rowsCount
  const pagging = props.pagging
  const loading = props.loading
  const sortModel = props.sortModel
  const sort = props.sort
  const rowsPerPageOptions = props.rowsPerPageOptions
  const setPageSize = props.setPageSize
  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div style={{ height: 650, width: '100%' }} className={classes.root}>
        <DataGrid
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(e) => { sort(e) }}
          rows={data}
          columns={columns}
          pageSize={pageSize}
          rowCount={rowCount}
          loading={loading}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={(e) => { pagging(e) }}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize.page)}
          checkboxSelection={false}
          disableSelectionOnClick
          {...data}
          getRowClassName={(params) =>
            `super-app-theme-IsEnabled-${params.getValue(params.id, 'IsEnabled')}`
          }
        />
      </div>
    </MuiThemeProvider>

  );
}
