import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"


const animatedComponents = makeAnimated()




export default class Numberinformation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',
            Spainfo: this.props.Spainfo,
            StaffAmount: this.props.Details.StaffAmount,
            TargetRemark: this.props.Details.TargetRemark,
            // accommodation_service_types: this.props.accommodation_service_types,
            // AccommodationInfo: this.props.AccommodationInfo,
            // LicenseDate: this.props.AccommodationInfo.LicenseDate,
            // LicenseExpireDate: this.props.AccommodationInfo.LicenseDate,
            // cost_levels: this.props.cost_levels,
            Nature: false,
            Manmade: false,
            Sport: false,
            Event: false,
            idSelected: "",
        }
        console.log(this.state.Spainfo)
    }
    selectedHandle(data, type) {
        console.log(data)

        if (type === 'StaffAmount') {
            this.props.callback(data, 'StaffAmount')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'TargetRemark') {
            this.props.callback(data, 'TargetRemark')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }



        // if (type === 'HasParking') {
        //     this.props.callback(data, 'HasParking')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'HasPreOrder') {
        //     this.props.callback(data, 'HasPreOrder')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'HasDelivery') {
        //     this.props.callback(data, 'HasDelivery')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'Districts') {
        //     this.props.callback(JSON.parse(data).Code, 'District')
        //     return
        // }
        // else {
        //     this.props.callback(JSON.parse(data).Code, 'County')
        //     return
        // }
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            // registration_types: nextProps.registration_types,
            cost_levels: nextProps.cost_levels,
            // FoodTypes: nextProps.FoodTypes,


        })
    }

    componentDidMount() {
        // console.log(this.state.FoodTypes)
        // let filter = this.state.FoodTypes.filter(item => item.isCheck === true)
        // for (let i = 0; i < filter.length; i++) {
        //     filter[i] = { RestaurantFoodTypeID: filter[i].ID }
        // }
        // this.props.submit('FoodTypes', filter)


    }

    render() {

        return (
            <div>
                <Label className={'text-24'}>ข้อมูลจำนวนผู้ให้บริการ และ ประเภทผู้รับบริการ</Label>
                <Row className="font-style-16 cl-black">
                    <Col lg="12">
                        <FormGroup>
                            <Label for="agencyName">จำนวนผู้ให้บริการ</Label>
                            <Input required type="text" className="form-control" id="StaffAmount" placeholder="กรุณากรอก ข้อมูลจำนวนผู้ให้บริการ" value={this.props.Details.StaffAmount} onChange={(e) => { this.props.callback(e.target.value, 'StaffAmount') }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="font-style-16 cl-black">
                    <Col lg="12">
                        <FormGroup>
                            <Label for="agencyName">หมายเหตุผู้รับบริการที่เหมาะสม</Label>
                            <Input required type="text" className="form-control" id="TargetRemark" placeholder="กรุณากรอก หมายเหตุผู้รับบริการที่เหมาะสม" value={this.props.Details.TargetRemark} onChange={(e) => { this.props.callback(e.target.value, 'TargetRemark') }} />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        )
    }
}
