const employees = [{
    'ID': 1,
    'Provine': 'เชียงราย',
    'Location': '20',
    'Activity': '20',
    'Travelroutes': '20',
    'Accommodation': '20',
    'Spa': '20',
    'Restaurant': '20',
    'Shop': '20',
    'Total': '140'

}, {
    'ID': 2,
    'Provine': 'นนทบุรี',
    'Location': '20',
    'Activity': '20',
    'Travelroutes': '20',
    'Accommodation': '20',
    'Spa': '20',
    'Restaurant': '20',
    'Shop': '20',
    'Total': '140'
}, {
    'ID': 3,
    'Provine': 'เชียงใหม่',
    'Location': '20',
    'Activity': '20',
    'Travelroutes': '20',
    'Accommodation': '20',
    'Spa': '20',
    'Restaurant': '20',
    'Shop': '20',
    'Total': '140'
},];

export default {
    getEmployees() {
        return employees;
    }
};
