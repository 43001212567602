import React, { Component } from 'react'
import PropTypes from 'prop-types'
import logoplus from "../../../../assets/images/ttd/Vector.svg"
import pana from '../../../../assets/images/ttd/pana.svg'
import { Link } from "react-router-dom"

import {
    Button, Card, CardBody, CardSubtitle, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap"
import { api_endpoint } from '../../../../global-config'


const memberType = [
    { label: "ข้าราชการ/พนักงานสังกัดสำนักงานท่องเที่ยวและกีฬาจังหวัด", value: 'official', isCheck: true },
    { label: "ข้าราชการ/พนักงานสังกัดส่วนราชการอื่น", value: 'officialo', isCheck: false },
    { label: "ผู้ประกอบการธุรกิจ/เอกชน/ชุมชน", value: 'private', isCheck: false },
    { label: "ศูนย์ปฏิบัติการ Digital Tourism ส่วนกลาง", value: 'tdtc', isCheck: false },
    { label: "อื่นๆ", value: 'other', isCheck: false },
]

export default class membersAdd extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userData: JSON.parse(localStorage.getItem('authUser')),
            lang: "th",
            skip: 0,
            limit: 1,
            isLoading: true,
            memberType: memberType,
            passwordCheck: "",
            submitData: {
                EditorTeam: {
                    EditorTeamID: "",
                    MemberLevel: "Editor",
                    MemberType: "official",
                    MemberTypeOther: null,
                    IsEnable: "Y"
                },
                User: {
                    Username: "",
                    Password: "",
                    TitleName: "",
                    FirstName: "",
                    LastName: "",
                    Telephone: "",
                    Mobile: "",
                    Email: ""
                }
            }
        }
    }



    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const teamID = params.get('teamID');
        let obj = this.state.submitData
        obj.EditorTeam.EditorTeamID = Number(teamID)
        this.setState({
            teamID: teamID,
            submitData: obj
        }, () => {
            console.log(this.state.submitData)
        })
    }

    inpuntHandle(value, type) {
        let obj = this.state.submitData
        if (type === "FirstName") {
            obj.User.FirstName = value
            this.setState({
                submitData: obj
            })
        }
        if (type === "LastName") {
            obj.User.LastName = value
            this.setState({
                submitData: obj
            })
        }
        if (type === "Email") {
            obj.User.Email = value
            this.setState({
                submitData: obj
            })
        }
        if (type === "Mobile") {
            obj.User.Mobile = value
            this.setState({
                submitData: obj
            })
        }
        if (type === "Telephone") {
            obj.User.Telephone = value
            this.setState({
                submitData: obj
            })
        }
        if (type === "MemberTypeOther") {
            obj.EditorTeam.MemberTypeOther = value
            this.setState({
                submitData: obj
            })
        }
        if (type === "Username") {
            obj.User.Username = value
            this.setState({
                submitData: obj
            })
        }
        if (type === "PasswordCheck") {
            this.setState({
                passwordCheck: value
            })
        }
        if (type === "Password") {
            obj.User.Password = value
            this.setState({
                submitData: obj
            })
        }
    }

    async buttonHandle(type) {
        if (type === 'Submit') {
            let obj = this.state.submitData
            if (obj.User.FirstName === "") {
                return alert('กรุณากรอกชื่อจริง')
            }
            if (obj.User.LastName === "") {
                return alert('กรุณากรอกนามสกุล')
            }
            if (obj.User.Email === "") {
                return alert('กรุณากรอกอีเมล')
            }
            if (obj.EditorTeam.MemberType === "other" && obj.EditorTeam.MemberTypeOther === null) {
                return alert('กรุณากรอกชื่อหน่วยงานอื่นๆ')
            }
            if (obj.User.Username === "") {
                return alert('กรุณากรอกชื่อบัญชี')
            }
            if (obj.User.Password === "") {
                return alert('กรุณากรอกรหัสผ่าน')
            }
            if (obj.User.Password !== this.state.passwordCheck) {
                return alert('กรุณากรอกรหัสผ่านให้ตรงกัน')
            }
            else {
                const response = await fetch(
                    api_endpoint + `/editorteam/member/create`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: 'bearer ' + this.state.userData.token
                        },
                        body: JSON.stringify(this.state.submitData), // body data type must match "Content-Type" header
                    }
                );
                var _result = await response.json();
                if (_result.status === 'error') {
                    alert(_result.message)
                }
                else {
                    this.props.history.goBack()
                    alert('เพิ่มสมาชิกสำเร็จ')
                }
            }
        }
    }


    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col style={{ alignSelf: 'center' }}>
                                        <h1 className="text-24" style={{ color: '#173865' }}>เพิ่มสมาชิก</h1>
                                        <CardSubtitle className="text-16">การกรอกข้อมูลให้ละเอียดและถูกต้องก่อนกดบันทึก</CardSubtitle>
                                    </Col>
                                    {/* <img src={pana}></img> */}
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <p className={'text-24'}>ข้อมูลทั่วไป</p>
                                <Row>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label className="font-style-16 cl-black" for="Name">ชื่อ </Label><Label className="required"> *</Label>
                                            <Input style={{ fontSize: 14 }} required type="text" className="form-control" id="FirstName" value={this.state.submitData.User.FirstName} placeholder="กรุณากรอกชื่อ" onChange={(e) => { this.inpuntHandle(e.target.value, "FirstName") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label className="font-style-16 cl-black" for="Name">นามสกุล </Label><Label className="required"> *</Label>
                                            <Input style={{ fontSize: 14 }} required type="text" className="form-control" id="LastName" value={this.state.submitData.User.LastName} placeholder="กรุณากรอกนามสกุล" onChange={(e) => { this.inpuntHandle(e.target.value, "LastName") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label className="font-style-16 cl-black" for="Name">อีเมล </Label><Label className="required"> *</Label>
                                            <Input style={{ fontSize: 14 }} required type="email" className="form-control" id="Email" value={this.state.submitData.User.Email} placeholder="กรุณากรอกอีเมล" onChange={(e) => { this.inpuntHandle(e.target.value, "Email") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label className="font-style-16 cl-black" for="Name">เบอร์มือถือ</Label>
                                            <Input style={{ fontSize: 14 }} type="tel" className="form-control" id="Mobile" value={this.state.submitData.User.Mobile} placeholder="กรุณากรอกเบอร์มือถือ" onChange={(e) => { this.inpuntHandle(e.target.value, "Mobile") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label className="font-style-16 cl-black" for="Name">เบอร์โทรศัพท์</Label>
                                            <Input style={{ fontSize: 14 }} type="tel" className="form-control" id="Telephone" value={this.state.submitData.User.Telephone} placeholder="กรุณากรอกเบอร์โทรศัพท์" onChange={(e) => { this.inpuntHandle(e.target.value, "Telephone") }} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={2}>
                                        <Label className="font-style-16 cl-black" for="Name">ประเภท </Label><Label className="required"> *</Label>
                                    </Col>
                                    <Col lg={4}>
                                        {this.state.memberType.map((e, i) => {
                                            if (e.label === 'อื่นๆ') {
                                                return (
                                                    <div key={i}>
                                                        <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                            onClick={() => {
                                                                let arr = this.state.memberType
                                                                let obj = this.state.submitData
                                                                obj.EditorTeam.MemberType = arr[i].value
                                                                arr.forEach(e => {
                                                                    if (arr[i].label === e.label) {
                                                                        e.isCheck = true
                                                                    }
                                                                    else {
                                                                        e.isCheck = false
                                                                    }
                                                                })
                                                                this.setState({
                                                                    memberType: arr,
                                                                    submitData: obj
                                                                }, () => {
                                                                    console.log(this.state.submitData)
                                                                })
                                                            }}
                                                        >
                                                            <input
                                                                type="radio"
                                                                className="custom-control-input"
                                                                checked={e.isCheck}

                                                            />
                                                            <label style={{ paddingLeft: 10 }}
                                                                className="custom-control-label font-style-16 cl-black"
                                                                htmlFor={"type" + i}
                                                            >
                                                                {e.label}
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <Input style={{ marginLeft: '2rem', fontSize: 14 }} type="text" className="form-control" id="Telephone" value={this.state.submitData.EditorTeam.MemberTypeOther} placeholder="กรุณากรอกรายละเอียดอื่นๆ" onChange={(e) => { this.inpuntHandle(e.target.value, "MemberTypeOther") }} />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            else {
                                                return (
                                                    <div key={i} className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                        onClick={() => {
                                                            let arr = this.state.memberType
                                                            let obj = this.state.submitData
                                                            obj.EditorTeam.MemberType = arr[i].value
                                                            arr.forEach(e => {
                                                                if (arr[i].label === e.label) {
                                                                    e.isCheck = true
                                                                }
                                                                else {
                                                                    e.isCheck = false
                                                                }
                                                            })
                                                            this.setState({
                                                                memberType: arr,
                                                                submitData: obj
                                                            }, () => {
                                                                console.log(this.state.submitData)
                                                            })
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"

                                                            className="custom-control-input"
                                                            checked={e.isCheck}
                                                        />
                                                        <label style={{ paddingLeft: 10 }}
                                                            className="custom-control-label font-style-16 cl-black"
                                                            htmlFor={"type" + i}
                                                        >
                                                            {e.label}
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        })}
                                        <Row>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Label className={'text-24'}>จัดการบัญชีผู้ใช้งาน</Label>
                                <FormGroup>
                                    <Label className="font-style-16 cl-black" for="Username">ชื่อผู้ใช้งาน </Label><Label className="required"> *</Label>
                                    <Input style={{ fontSize: 14 }} type="text" className="form-control" id="Username" placeholder="กรุณาชื่อผู้ใช้งาน" value={this.state.submitData.User.Username} onChange={(e) => { this.inpuntHandle(e.target.value, "Username") }} />
                                    <p className="font-style-16" style={{ color: '#808080' }}><span style={{ color: 'red' }}>หมายเหตุ:</span> ชื่อผู้ใช้งานจะไม่สามารถเปลี่ยนแปลงได้ในภายหลัง</p>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="font-style-16 cl-black" for="Name">รหัสผ่าน </Label><Label className="required"> *</Label>
                                    <Input style={{ fontSize: 14 }} type="text" className="form-control" id="Name" placeholder="กรุณากรอกรหัสผ่าน" value={this.state.passwordCheck} onChange={(e) => { this.inpuntHandle(e.target.value, "PasswordCheck") }} />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="font-style-16 cl-black" for="Name">ยืนยันรหัสผ่าน </Label><Label className="required"> *</Label>
                                    {this.state.submitData.User.Password === this.state.passwordCheck
                                        && this.state.submitData.User.Password !== ""
                                        && this.state.submitData.User.passwordCheck !== ""
                                        &&
                                        <div className="font-style-14" style={{ color: '#41AB00' }}>สามารถใช้งานได้ <img src={'/images/icon/correct.png'} width="15" height="15" alt="" style={{ marginTop: -5 }}></img></div>
                                    }
                                    <Input style={{ fontSize: 14 }} type="text" className="form-control" id="Name" placeholder="กรุณากรอกรหัสผ่านอีกครั้ง" value={this.state.submitData.User.Password} onChange={(e) => { this.inpuntHandle(e.target.value, "Password") }} />
                                </FormGroup>
                                <Row>
                                    <Col lg={2}>
                                        <Label className="font-style-16 cl-black" for="Name">ระดับสมาชิก </Label><Label className="required"> *</Label>
                                    </Col>
                                    <Col lg={10}>
                                        <Row>
                                            <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                onClick={() => {
                                                    let obj = this.state.submitData
                                                    obj.EditorTeam.MemberLevel = "SuperEditor"
                                                    this.setState({
                                                        submitData: obj
                                                    })
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    id="SuperEditor"
                                                    name="SuperEditor"
                                                    className="custom-control-input"
                                                    checked={this.state.submitData.EditorTeam.MemberLevel === "SuperEditor" ? true : false}
                                                />
                                                <label style={{ paddingLeft: 10 }}
                                                    className="custom-control-label font-style-16 cl-black"
                                                    htmlFor="customRadio4"
                                                >
                                                    หัวหน้าทีม
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                onClick={() => {
                                                    let obj = this.state.submitData
                                                    obj.EditorTeam.MemberLevel = "Editor"
                                                    this.setState({
                                                        submitData: obj
                                                    })
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    id="Editor"
                                                    name="Editor"
                                                    className="custom-control-input"
                                                    checked={this.state.submitData.EditorTeam.MemberLevel === "Editor" ? true : false}
                                                />
                                                <label style={{ paddingLeft: 10 }}
                                                    className="custom-control-label font-style-16 cl-black"
                                                    htmlFor="customRadio5"
                                                >
                                                    สมาชิก
                                                </label>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <p className="text-24">สถานะข้อมูล</p>
                                <Row>
                                    <Col lg={2}>
                                        <Label className="font-style-16 cl-black" for="Name">สถานะข้อมูล </Label><Label className="required"> *</Label>
                                    </Col>
                                    <Col lg={10}>
                                        <Row>
                                            <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                onClick={() => {
                                                    let obj = this.state.submitData
                                                    obj.EditorTeam.IsEnable = "Y"
                                                    this.setState({
                                                        submitData: obj
                                                    })
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    id="isEnable"
                                                    name="isEnable"
                                                    className="custom-control-input"
                                                    checked={this.state.submitData.EditorTeam.IsEnable === "Y" ? true : false}
                                                />
                                                <label style={{ paddingLeft: 10 }}
                                                    className="custom-control-label font-style-16 cl-black"
                                                    htmlFor="customRadio4"
                                                >
                                                    ปกติ
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                onClick={() => {
                                                    let obj = this.state.submitData
                                                    obj.EditorTeam.IsEnable = "N"
                                                    this.setState({
                                                        submitData: obj
                                                    })
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    id="isNotEnable"
                                                    name="isNotEnable"
                                                    className="custom-control-input"
                                                    checked={this.state.submitData.EditorTeam.IsEnable === "N" ? true : false}
                                                />
                                                <label style={{ paddingLeft: 10 }}
                                                    className="custom-control-label font-style-16 cl-black"
                                                    htmlFor="customRadio5"
                                                >
                                                    ระงับการใช้งาน
                                                </label>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                                <hr></hr>
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                            type="submit"
                                            onClick={() => { this.buttonHandle('Submit') }}
                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/Manageteammainpage">
                                            <Button
                                                style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}
                                            </Button>
                                        </Link>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>

                    </Container>
                </div>
            </React.Fragment >
        )
    }
}
