import React, { Component } from 'react'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    CardSubtitle
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import pana from '../../../assets/images/ttd/pana.svg'
import { Link } from "react-router-dom"
import { api_endpoint } from '../../../global-config'
import AddUserLangTh from "./AddUserLangTh"
// import ManageteamLangTh from "./ManageteamLang/ManageteamLangTh"
// import ManageteamLangEn from "./ManageteamLang/ManageteamLangEn"
// import ManageteamLangZh from "./ManageteamLang/ManageteamLangZh"
import * as loadingData from "../../../loadingttd.json";
import Lottie from "react-lottie";
import FadeIn from "react-fade-in";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};


export default class AddUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lang: "th",
            isLoading: true,
            Thaipage: true,
            Engpage: false,
            Chinapage: false,
            userData: JSON.parse(localStorage.getItem('authUser'))
        }
        this.Thaipage = this.Thaipage.bind(this);
        this.Engpage = this.Engpage.bind(this);
        console.log(props)
    }

    Thaipage() {
        this.setState({
            Thaipage: true,
            Engpage: false,
            Chinapage: false,
        })
    }

    Engpage() {
        this.setState({
            Engpage: true,
            Thaipage: false,
            Chinapage: false,
        })
    }

    Chinapage() {

        this.setState({
            Chinapage: true,
            Engpage: false,
            Thaipage: false,
        })
    }

    componentDidMount() {

    }

    render() {
        // if (this.state.isLoading === true) {
        //     return (
        //         <div className="text-center" >
        //             <FadeIn>
        //                 <div style={{ display: "flex", justifyContent: 'center' }}>
        //                     <div >
        //                         <Lottie options={defaultOptions} height={300} width={300} />
        //                         <div>
        //                             <h4 style={{ color: 'black', padding: 30 }}>กำลังโหลด</h4>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </FadeIn>
        //         </div>
        //     )
        // }
        // else {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="หน้าแรก" link0="/dashboard-blog" breadcrumbItem="รายการจัดการสมาชิก (ภายนอก)" link1="/ManageUserRole" breadcrumbItems="เพิ่มสมาชิก" />
                        <Card>
                            <CardBody>
                                <Row style={{ padding: 15 }}>
                                    <div style={{ alignSelf: 'center', width: '70%' }}>
                                        <h1 className="text-24" style={{ color: '#173865' }}>เพิ่มสมาชิก</h1>
                                        <CardSubtitle className="text-16">กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนดำเนินการต่อไป </CardSubtitle>
                                    </div>
                                    <div style={{ textAlign: 'center', width: '30%' }}>
                                        <img style={{ width: '10vw', height: 'auto' }} src={pana}></img>
                                    </div>
                                </Row>
                                <hr></hr>
                                <Row>
                                    <Col lg={4} >
                                        <div style={{ textAlign: 'center' }}>
                                            <h3 className="text-18" style={{ color: this.state.Thaipage == true ? '#173865' : '#808080' }} onClick={() => { this.Thaipage() }}>ภาษาไทย</h3>
                                            {this.state.Thaipage == true && <div class="hr-line"></div>}
                                        </div>

                                    </Col>
                                    {/* <Col lg={4} style={{ cursor: 'pointer' }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <h3 className="text-18" style={{ color: this.state.Engpage == true ? '#173865' : '#808080' }} onClick={() => { this.Engpage() }}>English</h3>
                                                {this.state.Engpage == true && <div class="hr-line"></div>}
                                            </div>
                                        </Col>
                                        <Col lg={4} style={{ cursor: 'pointer' }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <h3 className="text-18" style={{ color: this.state.Chinapage == true ? '#173865' : '#808080' }} onClick={() => { this.Chinapage() }}>简体中文</h3>
                                                {this.state.Chinapage == true && <div class="hr-line"></div>}
                                            </div>
                                        </Col> */}
                                </Row>
                            </CardBody>
                        </Card>
                        {this.state.Thaipage === true && (
                            <AddUserLangTh />
                        )}
                        {/* {this.state.Engpage === true && (
                                <ManageteamLangEn />
                            )}
                            {this.state.Chinapage === true && (
                                < ManageteamLangZh />
                            )} */}
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
// }
