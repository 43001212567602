import React, { Component } from 'react'
import PropTypes, { element } from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { Link } from "react-router-dom"
import { api_endpoint } from '../../../global-config'

import Map from "../../Dashboard-blog/MapsGoogle"
import Facilities from './topic/Facilities'
import Utilities from './topic/Utilities'
import Journey from './topic/Journey'
import Activity from './topic/Activity'
import More from './topic/More'
import Status from './topic/Status'
import General from './topicSpa/General'
import Registration from './topicSpa/Registration'
import Address from './topicSpa/Address'
import Contact from './topic/Contact'
import Picturesvideos from './topic/Picturesvideos'
import DateTime from './topic/DateTime'
import Numberinformation from './topicSpa/Numberinformation'
import Rates from './topic/Rates'
import Target from './topic/Target'
import * as loadingData from "../../../loadingttd.json";
import Lottie from "react-lottie";
import FadeIn from "react-fade-in";
import Feedback from './topicAll/Feedback';

const moment = require('moment'); // require
import localization from 'moment/locale/th';
moment.updateLocale('th', localization);

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export default class SpaTap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            IDEditter: null,
            IsEnabled: "Y",
            // activeTabJustify: "5",
            lang: props.lang,
            isLoading: true,
            isLoadingDetail: false,
            placeId: null,
            Attraction: [],
            Activities: [],
            Targets: [],
            Facilities: [],
            Utility: [],
            Types: [],
            TravelTypeID: [],
            OpenHours: [],
            Images: [],
            ImagesUpload: [],
            provinces: [],
            districts: [],
            subdistricts: [],
            Feedback: "",
            SpaFacilities: [],
            SpaRegistration_types: [],
            Spa_service_types: [],
            Spainfo: {
                SpaRegistrationTypeID: null,
                LicenseNo: "",
                LicenseDate: null,
                LicenseExpireDate: null,
            },
            Details: {
                Lang: "th",
                OpenHourRemark: null,
                StaffAmount: "",
                CostRate: null,
                TargetRemark: ""
            },
            th: {
                Name: '',
                Activities: [],
                CoverImages: {},
                Details: "",
                TravelRemark: "",
                History: "",
                AreaSize: "",
                ParkingDetail: "",
                ResponseDepartment: "",
                Feature: "",
                //
                Address: "",
                MapImage: {},
                RegionID: "",
                Province: "",
                District: "",
                County: "",
                Latitude: "",
                Longitude: "",
                //
                Telephone: "",
                Mobile: "",
                Email: "",
                Website: "",
                ContactName: "",
                ContactPosition: "",
                //
                OpenHourRemark: "",
                OpencloseRemark: "",
                businesshours: "",
                Suitablerange: "",
                VdoYoutubeUrl: "",
                MoreDetail: ""
            },
            en: {
                Name: '',
                Activities: [],
                Details: "",
                TravelRemark: "",
                History: "",
                AreaSize: "",
                ParkingDetail: "",
                ResponseDepartment: '',
                Feature: "",
                //
                Address: "",
                RegionID: "",
                Province: "",
                District: "",
                County: "",
                Latitude: "",
                Longitude: "",
                //
                Telephone: "",
                Mobile: "",
                Email: "",
                Website: "",
                ContactName: "",
                ContactPosition: "",
                //
                OpenHourRemark: "",
                OpencloseRemark: "",
                businesshours: "",
                Suitablerange: "",
                MoreDetail: ""

            },
            zh: {
                Name: '',
                Activities: [],
                Details: "",
                TravelRemark: "",
                History: "",
                AreaSize: "",
                ParkingDetail: "",
                ResponseDepartment: '',
                Feature: "",
                //
                Address: "",
                RegionID: "",
                Province: "",
                District: "",
                County: "",
                Latitude: "",
                Longitude: "",
                //
                Telephone: "",
                Mobile: "",
                Email: "",
                Website: "",
                ContactName: "",
                ContactPosition: "",
                //
                OpenHourRemark: "",
                OpencloseRemark: "",
                businesshours: "",
                Suitablerange: "",
                MoreDetail: ""

            },
            Submit: {},
            status: null
        }
        this.callback = this.callback.bind(this);
        this.attraction_types = this.attraction_types.bind(this);
        this.get_attraction = this.get_attraction.bind(this);
        this.get_attraction_targets = this.get_attraction_targets.bind(this);
        this.get_attraction_facilities = this.get_attraction_facilities.bind(this);
        this.attraction_utility_types = this.attraction_utility_types.bind(this);

        this.submitForm = this.submitForm.bind(this);

    }




    async attraction_types() {
        const response = await fetch(
            api_endpoint + `/lkup/attraction_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        for (let i = 0; i < _result.length; i++) {
            for (let j = 0; j < _result[i].SubTypes.length; j++) {
                _result[i].SubTypes[j] = { ..._result[i].SubTypes[j], isCheck: false }
            }
        }
        this.setState({
            Types: _result,
        }, () => {
            this.get_provinces()
        })
    }

    async get_provinces() {
        const response = await fetch(
            api_endpoint + `/lkup/provinces`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    RegionID: [],
                    Code: [],

                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();

        for (let i = 0; i < _result.length; i++) {
            if (_result[i].Details[this.state.lang].Name === this.state[this.state.lang].Province) {
                _result[i] = { ..._result[i], isCheck: true }
                let obj = this.state[this.state.lang]
                obj.Province = _result[i].Code
                obj.RegionID = _result[i].RegionID
                this.setState({
                    [this.state.lang]: obj
                })
            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            provinces: _result
        }, () => {
            this.get_districts()
        })
    }


    async get_districts() {
        console.log(this.state[this.state.lang].District)
        console.log(this.state[this.state.lang].Province)
        if (this.state[this.state.lang].District === "" && this.state[this.state.lang].Province === "") {
            this.get_attraction()
            return
        }
        const response = await fetch(
            api_endpoint + `/lkup/districts`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ProvinceCode: this.state[this.state.lang].Province.toString(),
                    // Code: [],

                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();


        for (let i = 0; i < _result.length; i++) {
            if (_result[i].Details[this.state.lang].Name === this.state[this.state.lang].District) {
                _result[i] = { ..._result[i], isCheck: true }
                let obj = this.state[this.state.lang]
                obj.District = _result[i].Code
                this.setState({
                    [this.state.lang]: obj
                })
            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            districts: _result,
        }, () => {
            this.get_subdistricts()
        })
    }

    async get_subdistricts() {
        if (this.state[this.state.lang].County === "" && this.state[this.state.lang].District === "") {
            this.get_attraction()
            return
        }
        const response = await fetch(
            api_endpoint + `/lkup/subdistricts`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ProvinceCode: this.state[this.state.lang].Province.toString(),
                    DistrictCode: this.state[this.state.lang].District.toString(),

                    // Code: [],

                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        for (let i = 0; i < _result.length; i++) {
            if (_result[i].Details[this.state.lang].Name === this.state[this.state.lang].County) {
                _result[i] = { ..._result[i], isCheck: true }
                let obj = this.state[this.state.lang]
                obj.County = _result[i].Code
                this.setState({
                    [this.state.lang]: obj
                })
            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            subdistricts: _result,
        }, () => {
            this.get_attraction()
        })
    }




    async get_attraction() {
        const response = await fetch(
            api_endpoint + `/lkup/attraction_activity_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();

        this.setState({
            Attraction: _result,
        }, () => {
            this.get_attraction_targets()
        })
    }

    async get_attraction_targets() {
        const response = await fetch(
            api_endpoint + `/lkup/attraction_targets`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (Array.isArray(this.state.Targets)) {
            for (let i = 0; i < _result.length; i++) {
                _result[i] = { ..._result[i], isCheck: false }
            }
            _result.map(e => { this.state.Targets.forEach(element => { if (e.ID === element.ID) { e.isCheck = true } }) })
            this.setState({
                Targets: _result,

            }, () => {
                console.log(this.state.Targets)
                this.get_attraction_facilities()
            })
        }
        else {
            for (let i = 0; i < _result.length; i++) {
                _result[i] = { ..._result[i], isCheck: false }
            }
            this.setState({
                Targets: _result,

            }, () => {
                this.get_attraction_facilities()
            })
        }

    }




    async get_attraction_facilities() {
        const response = await fetch(
            api_endpoint + `/lkup/attraction_facilities`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        for (let i = 0; i < _result.length; i++) {
            console.log(this.state.Facilities)
            console.log(_result[i])
            if (_result[i].AttractionFacilityTypeID === 1) {
                this.state.Facilities.map((e) => {
                    if (e.ID === _result[i].ID) {
                        _result[i] = { ..._result[i], total: 0, quantity: 0, isCheck: true }
                    }
                    else {
                        if (_result[i].isCheck === true) {
                            return
                        }
                        else {
                            _result[i] = { ..._result[i], total: 0, quantity: 0, isCheck: false }
                        }
                    }
                })

            }
            else {
                this.state.Facilities.map((e) => {
                    if (e.ID === _result[i].ID) {

                        _result[i] = { ..._result[i], quantity: 0, isCheck: true }
                    }
                    else {
                        if (_result[i].isCheck === true) {
                            return
                        }
                        else {
                            _result[i] = { ..._result[i], quantity: 0, isCheck: false }
                        }

                    }
                })

            }

        }
        console.log(_result)
        this.setState({
            Facilities: _result,

        }, () => {
            this.get_travel_types()
        })


    }

    async get_travel_types() {
        const response = await fetch(
            api_endpoint + `/lkup/travel_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (Array.isArray(this.state.TravelTypeID)) {
            for (let i = 0; i < _result.length; i++) {
                _result[i] = { ..._result[i], isCheck: false }
            }
            _result.map(e => { this.state.TravelTypeID.forEach(element => { if (e.ID === element.ID) { e.isCheck = true } }) })
            this.setState({
                TravelTypeID: _result,

            }, () => {
                this.spa_facility_types()
            })
        }
        else {
            for (let i = 0; i < _result.length; i++) {
                _result[i] = { ..._result[i], isCheck: false }
            }
            this.setState({
                TravelTypeID: _result,

            }, () => {
                this.spa_facility_types()
            })
        }
    }


    async spa_facility_types() {
        const response = await fetch(
            api_endpoint + `/lkup/spa_facility_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        let arr_spa_facilities = []
        for (const [key, value] of Object.entries(this.state.SpaFacilities)) {
            arr_spa_facilities = arr_spa_facilities.concat(value.SubType)
        }
        if (Array.isArray(arr_spa_facilities)) {

            for (let i = 0; i < _result.length; i++) {
                for (let j = 0; j < _result[i].SubTypes.length; j++) {
                    console.log(_result[i].SubTypes);
                    arr_spa_facilities.map(e => {
                        if (e !== undefined && e.ID === _result[i].SubTypes[j].ID) { _result[i].SubTypes[j] = { ..._result[i].SubTypes[j], isCheck: true } }
                    })
                }
            }
            this.setState({
                SpaFacilities: _result,
            }, () => {
                this.spa_registration_types()
            })
        }
        else {
            for (let i = 0; i < _result.length; i++) {
                _result[i] = { ..._result[i], isCheck: false }
            }
            this.setState({
                SpaFacilities: _result,

            }, () => {
                this.spa_registration_types()
            })
        }


    }

    async spa_registration_types() {
        const response = await fetch(
            api_endpoint + `/lkup/spa_registration_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        for (let i = 0; i < _result.length; i++) {
            if (this.state.SpaRegistration_types[0] !== undefined && _result[i].ID === this.state.SpaRegistration_types[0].ID) {
                _result[i] = { ..._result[i], isCheck: true }

            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            SpaRegistration_types: _result,
        }, () => {
            console.log(this.state.SpaRegistration_types)
            this.spa_service_types()
        })


    }

    async spa_service_types() {
        const response = await fetch(
            api_endpoint + `/lkup/spa_service_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (Array.isArray(this.state.Spa_service_types)) {
            for (let i = 0; i < _result.length; i++) {
                _result[i] = { ..._result[i], isCheck: false }
            }
            _result.map(e => { this.state.Spa_service_types.forEach(element => { if (e.ID === element.ID) { e.isCheck = true } }) })
            this.setState({
                Spa_service_types: _result,

            }, () => {
                this.attraction_utility_types()
            })
        }
        else {
            for (let i = 0; i < _result.length; i++) {
                _result[i] = { ..._result[i], isCheck: false }
            }
            this.setState({
                Spa_service_types: _result,

            }, () => {
                this.attraction_utility_types()
            })
        }

    }


    async attraction_utility_types() {
        const response = await fetch(
            api_endpoint + `/lkup/attraction_utility_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();

        if (Array.isArray(this.state.Utility)) {
            for (let i = 0; i < _result.length; i++) {
                for (let j = 0; j < _result[i].SubTypes.length; j++) {
                    _result[i].SubTypes[j] = { ..._result[i].SubTypes[j], isCheck: false }
                }
            }
            this.setState({
                Utility: _result,
                isLoading: false,
            })
        }
        else {
            for (let i = 0; i < _result.length; i++) {
                for (let j = 0; j < _result[i].SubTypes.length; j++) {
                    _result[i].SubTypes[j] = { ..._result[i].SubTypes[j], isCheck: false }
                }
            }
            _result.map(e => {
                Object.keys(this.state.Utility).forEach(key => {
                    if (e.ID === this.state.Utility[key].ID) {
                        e.SubTypes.map(e => {
                            this.state.Utility[key].SubType.forEach(element => {
                                if (e.ID === element.ID) {
                                    e.isCheck = true
                                }
                            })
                        }

                        )
                    }
                });
            })
            this.setState({
                Utility: _result,
                isLoading: false,
            })
        }



    }



    callback(value, target) {
        if (target === 'IsEnabled') {
            this.state.IsEnabled = value
            this.setState({
                IsEnabled: this.state.IsEnabled
            }, () => {
                console.log(this.state.IsEnabled)
            })
            return
        }
        if (target === 'Feedback') {
            this.setState({
                Feedback: value
            }, () => {
                console.log(this.state.IsEnabled)
            })
            return
        }
        if (target === 'SpaFacilities') {
            this.state.Spainfo.SpaFacilityTypeID = value
            this.setState({
                Spainfo: this.state.Spainfo
            }, () => {
                console.log(this.state.Spainfo)
            })
            return
        }
        if (target === 'Spa_service_types') {
            this.state.Spainfo.SpaServiceTypeID = value
            this.setState({
                Spainfo: this.state.Spainfo
            }, () => {
                console.log(this.state.Spainfo)
            })
            return
        }
        if (target === 'SpaRegistration_types') {
            this.state.Spainfo.SpaRegistrationTypeID = value
            this.setState({
                Spainfo: this.state.Spainfo
            }, () => {
                console.log(this.state.Spainfo)
            })
            return
        }
        if (target === 'StaffAmount') {
            this.state.Details.StaffAmount = value
            this.setState({
                Details: this.state.Details
            }, () => {
                console.log(this.state.Details)
            })
            return
        }
        if (target === 'TargetRemark') {
            this.state.Details.TargetRemark = value
            this.setState({
                Details: this.state.Details
            }, () => {
                console.log(this.state.Details)
            })
            return
        }
        if (target === 'LicenseNo') {
            this.state.Spainfo.LicenseNo = value
            this.setState({
                Spainfo: this.state.Spainfo
            }, () => {
                console.log(this.state.Spainfo)
            })
            return
        }
        if (target === 'LicenseDate') {
            this.state.Spainfo.LicenseDate = value
            this.setState({
                Spainfo: this.state.Spainfo
            }, () => {
                console.log(this.state.Spainfo)
            })
            return
        }
        if (target === 'LicenseExpireDate') {
            this.state.Spainfo.LicenseExpireDate = value
            this.setState({
                Spainfo: this.state.Spainfo
            }, () => {
                console.log(this.state.Spainfo)
            })
            return
        }
        if (target === 'Latitude') {
            value = parseFloat(value)
        }
        if (target === 'Longitude') {
            value = parseFloat(value)
        }
        let obj = this.state[this.state.lang]
        // console.log(this.state.lang)
        obj[target] = value;
        console.log(obj)
        this.setState({
            [this.state.lang]: obj
        }, () => {
            if (target === 'Province') {
                this.get_districts()
            }
            if (target === 'District') {
                this.get_subdistricts()
            }

        })
    }

    submitForm(target, value) {

        if (this.state.Submit[target] === undefined) {
            this.state.Submit[target] = value
        }
        else {
            this.state.Submit[target] = value
        }
        console.log(this.state.Submit)

    }

    async getLocationDetail(id) {
        let path = `/maininfo/readone`
        if (this.state.status !== null) { path = '/maininfo/readoneupdate' }
        const userData = JSON.parse(localStorage.getItem('authUser'))
        const response = await fetch(
            api_endpoint + path,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                    ID: Number(id),
                    IsBackend: 'Y'
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log('ค่าจาก api')
        console.log(_result)
        if (_result.result !== undefined) {
            console.log(_result)

            let OpenHours = []

            _result.result.OpenHours.Items.map(e => {
                OpenHours.push({
                    DayID: e.DayID,
                    StartTime: moment(e.StartTime).format('LTS').split(" ")[0],
                    EndTime: moment(e.EndTime).format('LTS').split(" ")[0],
                    IsAllDay: null
                })

            })


            let Images = []
            _result.result.Images.map(e => {
                Images.push(e)
            })
            console.log(Images)
            //ActivityName: "ทดสอบ"
            //AttractionActivitySubTypeID: 0
            //AttractionActivityTypeID: 2
            //Details: {Lang: "th", Name: "ทดสอบ", Detail: ""}
            //id: 0




            let th = this.state.th
            let en = this.state.en
            let zh = this.state.zh
            if (this.state.lang === 'th') {
                th = {
                    ...this.state.th,
                    CoverImages: _result.result.IntroImage,
                    MapImage: _result.result.MapImage == null ? {} : _result.result.MapImage,
                    Name: _result.result.Name[this.state.lang],
                    //-----------------------------------------------//
                    Details: _result.result.Detail[this.state.lang],
                    TravelRemark: _result.result.TravelRemark[this.state.lang],
                    OpenHourRemark: _result.result.OpenHourRemark[this.state.lang],
                    //-----------------------------------------------//
                    Address: _result.result.Address[this.state.lang],
                    Province: _result.result.Province[this.state.lang],
                    District: _result.result.District[this.state.lang],
                    County: _result.result.SubDistrict[this.state.lang],
                    Latitude: _result.result.Latitude,
                    Longitude: _result.result.Longitude,
                    //-----------------------------------------------//
                    Telephone: _result.result.Telephone,
                    Mobile: _result.result.Mobile,
                    Email: _result.result.Email,
                    Website: _result.result.Website,
                    ContactName: _result.result.ContactName[this.state.lang],
                    ContactPosition: _result.result.ContactPosition[this.state.lang],
                    VdoYoutubeUrl: _result.result.VdoYoutubeUrl,
                    FacebookUrl: _result.result.FacebookUrl,
                    LineID: _result.result.LineID,
                    Twitter: _result.result.Twitter,
                    MoreDetail: _result.result.MoreDetail[this.state.lang],
                }
            }
            if (this.state.lang === 'en') {
                en = {
                    ...this.state.en,
                    CoverImages: _result.result.IntroImage,
                    MapImage: _result.result.MapImage == null ? {} : _result.result.MapImage,
                    Name: _result.result.Name[this.state.lang],
                    //-----------------------------------------------//
                    Details: _result.result.Detail[this.state.lang],
                    TravelRemark: _result.result.TravelRemark[this.state.lang],
                    OpenHourRemark: _result.result.OpenHourRemark[this.state.lang],
                    //-----------------------------------------------//
                    Address: _result.result.Address[this.state.lang],
                    Province: _result.result.Province[this.state.lang],
                    District: _result.result.District[this.state.lang],
                    County: _result.result.SubDistrict[this.state.lang],
                    Latitude: _result.result.Latitude,
                    Longitude: _result.result.Longitude,
                    //-----------------------------------------------//
                    Telephone: _result.result.Telephone,
                    Mobile: _result.result.Mobile,
                    Email: _result.result.Email,
                    Website: _result.result.Website,
                    ContactName: _result.result.ContactName[this.state.lang],
                    ContactPosition: _result.result.ContactPosition[this.state.lang],
                    VdoYoutubeUrl: _result.result.VdoYoutubeUrl,
                    FacebookUrl: _result.result.FacebookUrl,
                    LineID: _result.result.LineID,
                    Twitter: _result.result.Twitter,
                    MoreDetail: _result.result.MoreDetail[this.state.lang],
                }
            }
            if (this.state.lang === 'zh') {
                zh = {
                    ...this.state.zh,
                    CoverImages: _result.result.IntroImage,
                    MapImage: _result.result.MapImage == null ? {} : _result.result.MapImage,
                    Name: _result.result.Name[this.state.lang],
                    //-----------------------------------------------//
                    Details: _result.result.Detail[this.state.lang],
                    TravelRemark: _result.result.TravelRemark[this.state.lang],
                    OpenHourRemark: _result.result.OpenHourRemark[this.state.lang],
                    //-----------------------------------------------//
                    Address: _result.result.Address[this.state.lang],
                    Province: _result.result.Province[this.state.lang],
                    District: _result.result.District[this.state.lang],
                    County: _result.result.SubDistrict[this.state.lang],
                    Latitude: _result.result.Latitude,
                    Longitude: _result.result.Longitude,
                    //-----------------------------------------------//
                    Telephone: _result.result.Telephone,
                    Mobile: _result.result.Mobile,
                    Email: _result.result.Email,
                    Website: _result.result.Website,
                    ContactName: _result.result.ContactName[this.state.lang],
                    ContactPosition: _result.result.ContactPosition[this.state.lang],
                    VdoYoutubeUrl: _result.result.VdoYoutubeUrl,
                    FacebookUrl: _result.result.FacebookUrl,
                    LineID: _result.result.LineID,
                    Twitter: _result.result.Twitter,
                    MoreDetail: _result.result.MoreDetail[this.state.lang],
                }
            }
            let SpaRegistration_types = [...this.state.SpaRegistration_types]
            SpaRegistration_types.push(_result.result.Spa.Info.SpaRegistrationType)

            let StaffAmount = this.state.Details.StaffAmount
            StaffAmount = _result.result.Spa.Details.StaffAmount[this.state.lang]

            let OpenHourRemark = this.state.Details.OpenHourRemark
            OpenHourRemark = _result.result.Spa.Details.OpenHourRemark[this.state.lang]

            let TargetRemark = this.state.Details.TargetRemark
            TargetRemark = _result.result.Spa.Details.TargetRemark[this.state.lang]



            // let LicenseDate = this.state.Spainfo.LicenseDate
            // LicenseDate = _result.result.Spa.Info.LicenseDate


            // let LicenseExpireDate = this.state.Spainfo.LicenseExpireDate
            // LicenseExpireDate = _result.result.Spa.Info.LicenseExpireDate

            let SpaRegistrationTypeID = this.state.Spainfo.SpaRegistrationTypeID
            SpaRegistrationTypeID = _result.result.Spa.Info.SpaRegistrationTypeID

            this.state.Spainfo.LicenseNo = _result.result.Spa.Info.LicenseNo
            this.state.Spainfo.LicenseDate = _result.result.Spa.Info.LicenseDate
            this.state.Spainfo.LicenseExpireDate = _result.result.Spa.Info.LicenseExpireDate
            this.state.Spainfo.SpaRegistrationTypeID = _result.result.Spa.Info.SpaRegistrationTypeID
            this.state.IDEditter = _result.result.ID,
                this.state.IsEnabled = _result.result.IsEnabled,
                this.state.Feedback = _result.result.Feedback,
                this.setState({
                    th: th,
                    en: en,
                    zh: zh,
                    StaffAmount: StaffAmount,
                    // LicenseDate: LicenseDate,
                    // LicenseNo: LicenseNo,
                    SpaRegistration_types: SpaRegistration_types,
                    TravelTypeID: _result.result.TravelTypes.Items,
                    Details: {
                        Lang: this.state.lang,
                        OpenHourRemark: OpenHourRemark,
                        StaffAmount: StaffAmount,
                        CostRate: null,
                        TargetRemark: TargetRemark
                    },
                    Info: {
                        SpaRegistrationTypeID: this.state.Spainfo.SpaRegistrationTypeID,
                        LicenseNo: this.state.Spainfo.LicenseNo,
                        LicenseDate: this.state.Spainfo.LicenseDate,
                        LicenseExpireDate: this.state.Spainfo.LicenseExpireDate,
                    },

                    Spa_service_types: _result.result.Spa.ServiceTypes,
                    SpaFacilities: _result.result.Spa.Facilities,


                    Images: Images,
                    OpenHours: OpenHours,
                    placeId: this.state.status === null ? this.state.placeId : _result.result.MainInfoID,
                    isLoadingDetail: false
                }, () => {
                    this.attraction_types()
                    console.log(this.state.CoverImages)
                })

        }
        else {
            console.log('Error : getLocationDetail ')
        }

    }


    async submitData() {
        if (this.state[this.state.lang].Name === "") {
            return alert('กรุณาใส่ชื่อ')
        }
        if (this.state[this.state.lang].Details === "") {
            return alert('กรุณาใส่รายละเอียด')
        }
        if (this.state[this.state.lang].Address === "") {
            return alert('กรุณาใส่ที่อยู่')
        }
        if (this.state[this.state.lang].Province === "") {
            return alert('กรุณาเลือกจังหวัด')
        }
        if (this.state[this.state.lang].County === "") {
            return alert('กรุณาเลือก อำเภอ และ ตำบล')
        }
        if (this.state[this.state.lang].Latitude === "") {
            return alert('กรุณาใส่ พิกัดละติจูด')
        }
        if (this.state[this.state.lang].Longitude === "") {
            return alert('กรุณาใส่ พิกัดลองติจูด')
        }
        // if (this.state[this.state.lang].District === "") {
        //     return alert('กรุณาเลือกตำบล')
        // }
        if (this.state.Submit.ImageUpload === undefined) {
            return alert('กรุณาใส่รูป')
        }

        let IsEnabled = this.state.IsEnabled
        let Feedback = this.state.Feedback
        const userData = JSON.parse(localStorage.getItem('authUser'))
        if (userData.UserSubType === "Editor") {
            console.log('Editer >>>>')
            IsEnabled = "N"
            console.log(IsEnabled)
        }
        let obj = {
            MainTypeID: 5,
            IsEnabled: IsEnabled,
            Feedback: Feedback,
            // ID: userData.UserID,
            Main: {
                Info: {
                    SubDistrictCode: this.state[this.state.lang].County,
                    DistrictCode: this.state[this.state.lang].District,
                    ProvinceCode: this.state[this.state.lang].Province,
                    RegionID: 1,
                    PostCode: "",
                    Telephone: this.state[this.state.lang].Telephone,
                    Fax: "",
                    Mobile: this.state[this.state.lang].Mobile,
                    Website: this.state[this.state.lang].Website,
                    Email: this.state[this.state.lang].Email,
                    FacebookUrl: this.state[this.state.lang].FacebookUrl,
                    LineID: this.state[this.state.lang].LineID,
                    Twitter: this.state[this.state.lang].Twitter,
                    Latitude: this.state[this.state.lang].Latitude,
                    Longitude: this.state[this.state.lang].Longitude,
                    HashName: this.state.Submit.ImageUpload[0].HashName,
                    Path: this.state.Submit.ImageUpload[0].Path,
                    VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl,
                    IntroImage: this.state.Submit.IntroImage,
                    MapImage: this.state.Submit.MapImage,
                },
                Details: {
                    [this.state.lang]: {
                        Lang: this.state.lang,
                        Name: this.state[this.state.lang].Name,
                        Detail: this.state[this.state.lang].Details,
                        Location: "",
                        Address: this.state[this.state.lang].Address,
                        Moo: "",
                        Soi: "",
                        Road: "",
                        Remark: "",
                        ContactName: this.state[this.state.lang].ContactName,
                        ContactPosition: this.state[this.state.lang].ContactPosition,
                        TravelRemark: this.state[this.state.lang].TravelRemark,
                        OpenHourRemark: this.state[this.state.lang].OpenHourRemark,

                        TravelMoreDetail: "",
                        MoreDetail: this.state[this.state.lang].MoreDetail,

                    }
                },
                Kinds: this.state.Kinds,
                OpenHours: this.state.Submit.OpenHours,
                TravelTypes: this.state.Submit.Travel_types,
                Images: this.state.Submit.ImageUpload,
            },
            Spa: {
                Info: {
                    SpaRegistrationTypeID: this.state.Spainfo.SpaRegistrationTypeID,
                    LicenseNo: this.state.Spainfo.LicenseNo,
                    LicenseDate: this.state.Spainfo.LicenseDate,
                    LicenseExpireDate: this.state.Spainfo.LicenseExpireDate,
                },
                Details: {
                    [this.state.lang]: {
                        Lang: this.state.Details.Lang,
                        OpenHourRemark: this.state.Details.OpenHourRemark,
                        StaffAmount: this.state.Details.StaffAmount,
                        CostRate: this.state.Details.CostRate,
                        TargetRemark: this.state.Details.TargetRemark,
                    },
                },
                ServiceTypes: this.state.Submit.SpaServiceTypeID,
                Facilities: this.state.Submit.SpaFacilities
            }



        }
        // ปิดส่ง
        console.log(JSON.stringify(obj))
        // return
        const response = await fetch(
            api_endpoint + `/maininfo/create`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (_result.status === "ok") {
            alert('เพิ่มสปาสำเร็จ')
            window.location.href = "/Spa";
        }
        else {
            console.log('error : submitData')
        }
    }

    async updateData() {
        const userData = JSON.parse(localStorage.getItem('authUser'))
        let obj = {}
        if (this.state.lang === 'th') {
            obj = {
                MainTypeID: 5,
                ID: Number(this.state.placeId),
                IsEnabled: this.state.IsEnabled,
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        SubDistrictCode: this.state[this.state.lang].County,
                        DistrictCode: this.state[this.state.lang].District,
                        ProvinceCode: this.state[this.state.lang].Province,
                        RegionID: 1,
                        PostCode: "",
                        Telephone: this.state[this.state.lang].Telephone,
                        Fax: "",
                        Mobile: this.state[this.state.lang].Mobile,
                        Website: this.state[this.state.lang].Website,
                        Email: this.state[this.state.lang].Email,
                        FacebookUrl: this.state[this.state.lang].FacebookUrl,
                        LineID: this.state[this.state.lang].LineID,
                        Twitter: this.state[this.state.lang].Twitter,
                        Latitude: this.state[this.state.lang].Latitude,
                        Longitude: this.state[this.state.lang].Longitude,
                        IntroImage: this.state.Submit.IntroImage,
                        MapImage: this.state.Submit.MapImage,
                        VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl

                        // HashName: this.state.Submit.ImageUpload[0].HashName,
                        // Path: this.state.Submit.ImageUpload[0].Path,

                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            Location: "",
                            Address: this.state[this.state.lang].Address,
                            Moo: "",
                            Soi: "",
                            Road: "",
                            Remark: "",
                            ContactName: this.state[this.state.lang].ContactName,
                            ContactPosition: this.state[this.state.lang].ContactPosition,
                            TravelRemark: this.state[this.state.lang].TravelRemark,
                            OpenHourRemark: this.state[this.state.lang].OpenHourRemark,

                            TravelMoreDetail: "",
                            MoreDetail: this.state[this.state.lang].MoreDetail,

                        }
                    },
                    Kinds: this.state.Kinds,
                    OpenHours: this.state.Submit.OpenHours,
                    TravelTypes: this.state.Submit.Travel_types,
                    Images: this.state.Submit.ImageUpload,
                },
                Spa: {
                    Info: {
                        SpaRegistrationTypeID: this.state.Spainfo.SpaRegistrationTypeID,
                        LicenseNo: this.state.Spainfo.LicenseNo,
                        LicenseDate: this.state.Spainfo.LicenseDate,
                        LicenseExpireDate: this.state.Spainfo.LicenseExpireDate,
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.Details.Lang,
                            OpenHourRemark: this.state.Details.OpenHourRemark,
                            StaffAmount: this.state.Details.StaffAmount,
                            CostRate: this.state.Details.CostRate,
                            TargetRemark: this.state.Details.TargetRemark,
                        },
                    },
                    ServiceTypes: this.state.Submit.SpaServiceTypeID,
                    Facilities: this.state.Submit.SpaFacilities
                }
            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }
        }
        if (this.state.lang === 'en') {
            obj = {
                MainTypeID: 5,
                ID: Number(this.state.placeId),
                IsEnabled: this.state.IsEnabled,
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        SubDistrictCode: this.state[this.state.lang].County,
                        DistrictCode: this.state[this.state.lang].District,
                        ProvinceCode: this.state[this.state.lang].Province,
                        RegionID: 1,
                        PostCode: "",
                        Telephone: this.state[this.state.lang].Telephone,
                        Fax: "",
                        Mobile: this.state[this.state.lang].Mobile,
                        Website: this.state[this.state.lang].Website,
                        Email: this.state[this.state.lang].Email,
                        FacebookUrl: this.state[this.state.lang].FacebookUrl,
                        LineID: this.state[this.state.lang].LineID,
                        Twitter: this.state[this.state.lang].Twitter,
                        Latitude: this.state[this.state.lang].Latitude,
                        Longitude: this.state[this.state.lang].Longitude,
                        IntroImage: this.state.Submit.IntroImage,
                        MapImage: this.state.Submit.MapImage,
                        VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl

                        // HashName: this.state.Submit.ImageUpload[0].HashName,
                        // Path: this.state.Submit.ImageUpload[0].Path,

                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            Location: "",
                            Address: this.state[this.state.lang].Address,
                            Moo: "",
                            Soi: "",
                            Road: "",
                            Remark: "",
                            ContactName: this.state[this.state.lang].ContactName,
                            ContactPosition: this.state[this.state.lang].ContactPosition,
                            TravelRemark: this.state[this.state.lang].TravelRemark,
                            OpenHourRemark: this.state[this.state.lang].OpenHourRemark,

                            TravelMoreDetail: "",
                            MoreDetail: this.state[this.state.lang].MoreDetail,

                        }
                    },
                    Kinds: this.state.Kinds,
                    OpenHours: this.state.Submit.OpenHours,
                    TravelTypes: this.state.Submit.Travel_types,
                    Images: this.state.Submit.ImageUpload,
                },
                Spa: {
                    Info: {
                        SpaRegistrationTypeID: this.state.Spainfo.SpaRegistrationTypeID,
                        LicenseNo: this.state.Spainfo.LicenseNo,
                        LicenseDate: this.state.Spainfo.LicenseDate,
                        LicenseExpireDate: this.state.Spainfo.LicenseExpireDate,
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.Details.Lang,
                            OpenHourRemark: this.state.Details.OpenHourRemark,
                            StaffAmount: this.state.Details.StaffAmount,
                            CostRate: this.state.Details.CostRate,
                            TargetRemark: this.state.Details.TargetRemark,
                        },
                    },
                    ServiceTypes: this.state.Submit.SpaServiceTypeID,
                    Facilities: this.state.Submit.SpaFacilities
                }
            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }
        }
        if (this.state.lang === 'zh') {
            obj = {
                MainTypeID: 5,
                ID: Number(this.state.placeId),
                IsEnabled: this.state.IsEnabled,
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        SubDistrictCode: this.state[this.state.lang].County,
                        DistrictCode: this.state[this.state.lang].District,
                        ProvinceCode: this.state[this.state.lang].Province,
                        RegionID: 1,
                        PostCode: "",
                        Telephone: this.state[this.state.lang].Telephone,
                        Fax: "",
                        Mobile: this.state[this.state.lang].Mobile,
                        Website: this.state[this.state.lang].Website,
                        Email: this.state[this.state.lang].Email,
                        FacebookUrl: this.state[this.state.lang].FacebookUrl,
                        LineID: this.state[this.state.lang].LineID,
                        Twitter: this.state[this.state.lang].Twitter,
                        Latitude: this.state[this.state.lang].Latitude,
                        Longitude: this.state[this.state.lang].Longitude,
                        IntroImage: this.state.Submit.IntroImage,
                        MapImage: this.state.Submit.MapImage,
                        VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl

                        // HashName: this.state.Submit.ImageUpload[0].HashName,
                        // Path: this.state.Submit.ImageUpload[0].Path,

                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            Location: "",
                            Address: this.state[this.state.lang].Address,
                            Moo: "",
                            Soi: "",
                            Road: "",
                            Remark: "",
                            ContactName: this.state[this.state.lang].ContactName,
                            ContactPosition: this.state[this.state.lang].ContactPosition,
                            TravelRemark: this.state[this.state.lang].TravelRemark,
                            OpenHourRemark: this.state[this.state.lang].OpenHourRemark,

                            TravelMoreDetail: "",
                            MoreDetail: this.state[this.state.lang].MoreDetail,

                        }
                    },
                    Kinds: this.state.Kinds,
                    OpenHours: this.state.Submit.OpenHours,
                    TravelTypes: this.state.Submit.Travel_types,
                    Images: this.state.Submit.ImageUpload,
                },
                Spa: {
                    Info: {
                        SpaRegistrationTypeID: this.state.Spainfo.SpaRegistrationTypeID,
                        LicenseNo: this.state.Spainfo.LicenseNo,
                        LicenseDate: this.state.Spainfo.LicenseDate,
                        LicenseExpireDate: this.state.Spainfo.LicenseExpireDate,
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.Details.Lang,
                            OpenHourRemark: this.state.Details.OpenHourRemark,
                            StaffAmount: this.state.Details.StaffAmount,
                            CostRate: this.state.Details.CostRate,
                            TargetRemark: this.state.Details.TargetRemark,
                        },
                    },
                    ServiceTypes: this.state.Submit.SpaServiceTypeID,
                    Facilities: this.state.Submit.SpaFacilities
                }
            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }
        }



        const response = await fetch(
            api_endpoint + `/maininfo/update`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (_result.status === "ok") {


            alert('แก้ไขสปาสำเร็จ')
            if (userData.UserSubType !== 'Admin' && userData.UserSubType !== 'SuperAdmin') {
                window.location.href = "/Spa";
            }

        }
        else {
            console.log('error : updateData')
        }
    }

    async Approve() {
        const userData = JSON.parse(localStorage.getItem('authUser'))
        let obj = {}
        if (this.state.lang === 'th') {
            obj = {
                MainTypeID: 5,
                ID: Number(this.state.IDEditter),
                IsEnabled: this.state.IsEnabled,
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        SubDistrictCode: this.state[this.state.lang].County,
                        DistrictCode: this.state[this.state.lang].District,
                        ProvinceCode: this.state[this.state.lang].Province,
                        RegionID: 1,
                        PostCode: "",
                        Telephone: this.state[this.state.lang].Telephone,
                        Fax: "",
                        Mobile: this.state[this.state.lang].Mobile,
                        Website: this.state[this.state.lang].Website,
                        Email: this.state[this.state.lang].Email,
                        FacebookUrl: this.state[this.state.lang].FacebookUrl,
                        LineID: this.state[this.state.lang].LineID,
                        Twitter: this.state[this.state.lang].Twitter,
                        Latitude: this.state[this.state.lang].Latitude,
                        Longitude: this.state[this.state.lang].Longitude,
                        IntroImage: this.state.Submit.IntroImage,
                        MapImage: this.state.Submit.MapImage,
                        VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl

                        // HashName: this.state.Submit.ImageUpload[0].HashName,
                        // Path: this.state.Submit.ImageUpload[0].Path,

                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            Location: "",
                            Address: this.state[this.state.lang].Address,
                            Moo: "",
                            Soi: "",
                            Road: "",
                            Remark: "",
                            ContactName: this.state[this.state.lang].ContactName,
                            ContactPosition: this.state[this.state.lang].ContactPosition,
                            TravelRemark: this.state[this.state.lang].TravelRemark,
                            OpenHourRemark: this.state[this.state.lang].OpenHourRemark,

                            TravelMoreDetail: "",
                            MoreDetail: this.state[this.state.lang].MoreDetail,

                        }
                    },
                    Kinds: this.state.Kinds,
                    OpenHours: this.state.Submit.OpenHours,
                    TravelTypes: this.state.Submit.Travel_types,
                    Images: this.state.Submit.ImageUpload,
                },
                Spa: {
                    Info: {
                        SpaRegistrationTypeID: this.state.Spainfo.SpaRegistrationTypeID,
                        LicenseNo: this.state.Spainfo.LicenseNo,
                        LicenseDate: this.state.Spainfo.LicenseDate,
                        LicenseExpireDate: this.state.Spainfo.LicenseExpireDate,
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.Details.Lang,
                            OpenHourRemark: this.state.Details.OpenHourRemark,
                            StaffAmount: this.state.Details.StaffAmount,
                            CostRate: this.state.Details.CostRate,
                            TargetRemark: this.state.Details.TargetRemark,
                        },
                    },
                    ServiceTypes: this.state.Submit.SpaServiceTypeID,
                    Facilities: this.state.Submit.SpaFacilities
                }
            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }
        }
        if (this.state.lang === 'en') {
            obj = {
                MainTypeID: 5,
                ID: Number(this.state.IDEditter),
                IsEnabled: this.state.IsEnabled,
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        SubDistrictCode: this.state[this.state.lang].County,
                        DistrictCode: this.state[this.state.lang].District,
                        ProvinceCode: this.state[this.state.lang].Province,
                        RegionID: 1,
                        PostCode: "",
                        Telephone: this.state[this.state.lang].Telephone,
                        Fax: "",
                        Mobile: this.state[this.state.lang].Mobile,
                        Website: this.state[this.state.lang].Website,
                        Email: this.state[this.state.lang].Email,
                        FacebookUrl: this.state[this.state.lang].FacebookUrl,
                        LineID: this.state[this.state.lang].LineID,
                        Twitter: this.state[this.state.lang].Twitter,
                        Latitude: this.state[this.state.lang].Latitude,
                        Longitude: this.state[this.state.lang].Longitude,
                        IntroImage: this.state.Submit.IntroImage,
                        MapImage: this.state.Submit.MapImage,
                        VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl

                        // HashName: this.state.Submit.ImageUpload[0].HashName,
                        // Path: this.state.Submit.ImageUpload[0].Path,

                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            Location: "",
                            Address: this.state[this.state.lang].Address,
                            Moo: "",
                            Soi: "",
                            Road: "",
                            Remark: "",
                            ContactName: this.state[this.state.lang].ContactName,
                            ContactPosition: this.state[this.state.lang].ContactPosition,
                            TravelRemark: this.state[this.state.lang].TravelRemark,
                            OpenHourRemark: this.state[this.state.lang].OpenHourRemark,

                            TravelMoreDetail: "",
                            MoreDetail: this.state[this.state.lang].MoreDetail,

                        }
                    },
                    Kinds: this.state.Kinds,
                    OpenHours: this.state.Submit.OpenHours,
                    TravelTypes: this.state.Submit.Travel_types,
                    Images: this.state.Submit.ImageUpload,
                },
                Spa: {
                    Info: {
                        SpaRegistrationTypeID: this.state.Spainfo.SpaRegistrationTypeID,
                        LicenseNo: this.state.Spainfo.LicenseNo,
                        LicenseDate: this.state.Spainfo.LicenseDate,
                        LicenseExpireDate: this.state.Spainfo.LicenseExpireDate,
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.Details.Lang,
                            OpenHourRemark: this.state.Details.OpenHourRemark,
                            StaffAmount: this.state.Details.StaffAmount,
                            CostRate: this.state.Details.CostRate,
                            TargetRemark: this.state.Details.TargetRemark,
                        },
                    },
                    ServiceTypes: this.state.Submit.SpaServiceTypeID,
                    Facilities: this.state.Submit.SpaFacilities
                }
            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }
        }
        if (this.state.lang === 'zh') {
            obj = {
                MainTypeID: 5,
                ID: Number(this.state.IDEditter),
                IsEnabled: this.state.IsEnabled,
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        SubDistrictCode: this.state[this.state.lang].County,
                        DistrictCode: this.state[this.state.lang].District,
                        ProvinceCode: this.state[this.state.lang].Province,
                        RegionID: 1,
                        PostCode: "",
                        Telephone: this.state[this.state.lang].Telephone,
                        Fax: "",
                        Mobile: this.state[this.state.lang].Mobile,
                        Website: this.state[this.state.lang].Website,
                        Email: this.state[this.state.lang].Email,
                        FacebookUrl: this.state[this.state.lang].FacebookUrl,
                        LineID: this.state[this.state.lang].LineID,
                        Twitter: this.state[this.state.lang].Twitter,
                        Latitude: this.state[this.state.lang].Latitude,
                        Longitude: this.state[this.state.lang].Longitude,
                        IntroImage: this.state.Submit.IntroImage,
                        MapImage: this.state.Submit.MapImage,
                        VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl

                        // HashName: this.state.Submit.ImageUpload[0].HashName,
                        // Path: this.state.Submit.ImageUpload[0].Path,

                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            Location: "",
                            Address: this.state[this.state.lang].Address,
                            Moo: "",
                            Soi: "",
                            Road: "",
                            Remark: "",
                            ContactName: this.state[this.state.lang].ContactName,
                            ContactPosition: this.state[this.state.lang].ContactPosition,
                            TravelRemark: this.state[this.state.lang].TravelRemark,
                            OpenHourRemark: this.state[this.state.lang].OpenHourRemark,

                            TravelMoreDetail: "",
                            MoreDetail: this.state[this.state.lang].MoreDetail,

                        }
                    },
                    Kinds: this.state.Kinds,
                    OpenHours: this.state.Submit.OpenHours,
                    TravelTypes: this.state.Submit.Travel_types,
                    Images: this.state.Submit.ImageUpload,
                },
                Spa: {
                    Info: {
                        SpaRegistrationTypeID: this.state.Spainfo.SpaRegistrationTypeID,
                        LicenseNo: this.state.Spainfo.LicenseNo,
                        LicenseDate: this.state.Spainfo.LicenseDate,
                        LicenseExpireDate: this.state.Spainfo.LicenseExpireDate,
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.Details.Lang,
                            OpenHourRemark: this.state.Details.OpenHourRemark,
                            StaffAmount: this.state.Details.StaffAmount,
                            CostRate: this.state.Details.CostRate,
                            TargetRemark: this.state.Details.TargetRemark,
                        },
                    },
                    ServiceTypes: this.state.Submit.SpaServiceTypeID,
                    Facilities: this.state.Submit.SpaFacilities
                }
            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }
        }
        const response = await fetch(
            api_endpoint + `/maininfo/approve`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj),
            }
        );
        var _result = await response.json();
        if (_result.status === "ok") {


            alert('อนุมัติแก้ไขสปาสำเร็จ')

        }
        else {
            console.log('error : Approve')
        }
    }


    async reject() {
        console.log(Number(this.state.IDEditter))
        const userData = JSON.parse(localStorage.getItem('authUser'))
        let obj = {}
        if (this.state.lang === 'th') {
            obj = {
                ID: Number(this.state.IDEditter),
            }
            // if (obj.Main.Images === undefined) {
            //     delete obj.Main.Images
            // }
        }
        if (this.state.lang === 'en') {
            obj = {
                ID: Number(this.state.IDEditter),
            }
        }
        if (this.state.lang === 'zh') {
            obj = {
                ID: Number(this.state.IDEditter),
            }
        }
        obj['Feedback'] = this.state.Feedback

        const response = await fetch(
            api_endpoint + `/maininfo/reject`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj),
            }
        );
        var _result = await response.json();
        console.log(_result)
        if (_result.status === "ok") {


            alert('แจ้งส่งกลับแก้ไขสปาสำเร็จ')

        }
        else {
            console.log('error : reject')
        }
    }


    componentDidMount() {



        const search = window.location.search;
        const params = new URLSearchParams(search);
        const id = params.get('id');
        const status = params.get('status');
        if (id !== null) {
            console.log('in')
            this.setState({
                placeId: id,
                status: status,
                cloneId: id,
                isLoadingDetail: true
            }, () => {

                this.getLocationDetail(id)

            })

        }
        else {
            console.log('out')
            this.attraction_types()
        }




    }

    componentWillReceiveProps(nextProps) {

        // console.log(nextProps)
        if (nextProps.lang) {
            this.setState({
                lang: nextProps.lang
            }, () => {
                // console.log(this.state.lang)
            })
        }
    }



    render() {
        console.log(this.state.Images)
        // console.log(this.state.isLoading)
        // console.log(this.state.isLoadingDetail )

        if (this.state.isLoading === true || this.state.isLoadingDetail === true) {
            return (
                <div className="text-center" >
                    <FadeIn>
                        <div style={{ display: "flex", justifyContent: 'center' }}>

                            <div >
                                <Lottie options={defaultOptions} height={300} width={300} />
                                <div>
                                    <h4 style={{ color: 'black', padding: 30 }}>กำลังโหลด</h4>
                                </div>

                            </div>


                        </div>

                    </FadeIn>
                </div>
            )
        }
        else {
            const userData = JSON.parse(localStorage.getItem('authUser'))
            console.log(userData)
            console.log(this.state.status)
            return (

                <div>
                    <Form onSubmit={() => { this.test() }}>
                        <Card>
                            <CardBody>
                                <General callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Types={this.state.Types} SpaFacilities={this.state.SpaFacilities} Spa_service_types={this.state.Spa_service_types} submit={this.submitForm} />

                                {/* <Button
                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                                    type="submit"

                                    className="chat-send w-md waves-effect waves-light"
                                >
                                    <span className="d-none d-sm-inline-block font-style-16 text-16" style={{ color: '#808080' }}>ค้นหาละเอียด </span>{" "}
                                </Button> */}
                            </CardBody>
                        </Card>
                    </Form>
                    <Card>
                        <CardBody>
                            <Registration callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Types={this.state.Types} SpaRegistration_types={this.state.SpaRegistration_types} Spainfo={this.state.Spainfo} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Address callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} provinces={this.state.provinces} districts={this.state.districts} subdistricts={this.state.subdistricts} submit={this.submitForm} />                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Contact callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Picturesvideos callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Images={this.state.Images} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <DateTime callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} OpenHours={this.state.OpenHours} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Numberinformation callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Spainfo={this.state.Spainfo} Details={this.state.Details} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Journey callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Travel_types={this.state.TravelTypeID} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    {/* <Card>
                        <CardBody>
                            <Target callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Target={this.state.Targets} submit={this.submitForm} />
                        </CardBody>
                    </Card> */}
                    {/* <Card>
                        <CardBody>
                            <Facilities callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Facilities={this.state.Facilities} submit={this.submitForm} />
                        </CardBody>
                    </Card> */}
                    {/* <Card>
                        <CardBody>
                            <Utilities callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Utility={this.state.Utility} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                   
                    {/* <Card>
                        <CardBody>
                            <Activity callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Attraction={this.state.Attraction} Activities={this.state.Activities} submit={this.submitForm} />
                        </CardBody>
                    </Card> */}
                    <Card>
                        <CardBody>
                            <More callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Attraction={this.state.Attraction} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    {this.state.status === 'Y' ?
                        (userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ?
                            <Card>
                                <CardBody>
                                    <Feedback callback={this.callback} lang={this.state.lang} disabled={true} data={this.state} submit={this.submitForm} />
                                </CardBody>
                            </Card>
                            :
                            <Card>
                                <CardBody>
                                    <Feedback callback={this.callback} lang={this.state.lang} disabled={false} data={this.state} submit={this.submitForm} />
                                </CardBody>
                            </Card>
                        :
                        <div />
                    }
                    <Card>
                        <CardBody>
                            {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.placeId === null ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                            {this.state.placeId !== null && this.state.status !== 'Y' && (userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                            {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.status === 'Y' ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                            {this.state.placeId !== null && this.state.status !== 'Y' && userData.UserSubType !== 'Admin' && userData.UserSubType !== 'SuperAdmin' ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                            {/* < Status /> */}
                            {this.state.placeId === null ?
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                            type="submit"
                                            onClick={() => { this.submitData() }}

                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}

                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/Spa">
                                            <Button
                                                style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                            </Button>
                                        </Link>
                                    </div>
                                </Row>
                                :

                                <div>

                                </div>
                            }
                            {this.state.placeId !== null && this.state.status !== 'Y' ?
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                            type="submit"
                                            onClick={() => { this.updateData() }}

                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">แก้ไข</span>{" "}
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/Spa">
                                            <Button
                                                style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                            </Button>
                                        </Link>
                                    </div>
                                </Row>
                                :
                                <div></div>
                            }
                            {this.state.status === 'Y' && userData.UserSubType === 'Admin' && userData.UserType === 'B' ?
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                            type="submit"
                                            onClick={() => { this.Approve() }}

                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb"> อนุมัติการแก้ไข</span>{" "}
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#F4D006', borderColor: '#F4D006' }}
                                            className="chat-send w-md waves-effect waves-light"
                                            onClick={() => { this.reject() }}
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">แจ้งส่งกลับแก้ไข</span>{" "}

                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/notifyinformation">
                                            <Button
                                                style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                            </Button>
                                        </Link>

                                    </div>
                                </Row>
                                :
                                <div>

                                </div>
                            }
                            {this.state.status === 'Y' && userData.UserSubType === 'Editor' && userData.UserType === 'B' ?
                                <div></div>
                                :
                                <div></div>

                            }
                        </CardBody>
                    </Card>





                    {/* <button onClick={() => { this.submitData() }}>Submit</button> */}

                </div>

            )
        }

    }
}
