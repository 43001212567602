import React, { Component } from 'react'
import { Col, Input, Label, Row } from "reactstrap"

export default class Feedback extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>

                {this.props.lang === 'th' ?
                    <div>
                        <p className={'text-24'}>Feedback</p>
                        <Label for="Address">ผู้ตรวจสอบข้อมูลแสดงข้อคิดเห็นเกี่ยวกับข้อมูล</Label>
                        <Row className={'mt-3 text-16'}>
                            <Col lg="12">
                                <Input
                                    type="textarea"
                                    id="textarea"
                                    onChange={(e) => { this.props.callback(e.target.value, 'Feedback') }}
                                    // maxLength="225"
                                    rows="3"
                                    placeholder=""
                                    value={this.props.data.Feedback}
                                    disabled={!this.props.disabled}
                                />
                                {this.state.textareabadge ? (
                                    <span className="badgecount badge badge-success">
                                        {" "}
                                        {this.state.textcount} / 225{" "}
                                    </span>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                    :
                    <div></div>
                }
            </div>
        )
    }
}
