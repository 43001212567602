import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"

export default class Price extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
        console.log(props)
    }
    render() {
        return (
            <div>
                <p className={'text-24'}>อัตราค่าบริการและเวลาร่วมกิจกรรม</p>
                {this.props.lang === 'th' ?
                    <Row className={'mt-3 font-style-16 cl-black'}>
                        <Col lg="12">
                            <FormGroup>
                                <Label for="agencyName">ราคาที่ใช้การทำกิจกรรม</Label>
                                <Input required type="text" className="form-control" id="Name" placeholder="ระบุราคาที่ใช้การทำกิจกรรม (ไม่บังคับ)" value={this.props.data.CostRate} onChange={(e) => { this.props.callback(e.target.value, 'CostRate') }} />
                            </FormGroup>
                        </Col>
                        <Col lg="12">
                            <FormGroup>
                                <Label for="agencyName">ระยะเวลาของกิจกรรม</Label>
                                <Input required type="text" className="form-control" id="Name" placeholder="ระบุระยะเวลาของกิจกรรม (ไม่บังคับ)" value={this.props.data.UseTime} onChange={(e) => { this.props.callback(e.target.value, 'UseTime') }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    :
                    <div>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }

            </div>
        )
    }
}
