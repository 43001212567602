import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"

export default class Status extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Nonestatus: false,
            status: false,
            IsEnabled: this.props.IsEnabled
        }
        console.log(this.props)
        console.log(this.state.IsEnabled)
        console.log(this.props.userData)
    }
    selectedHandle(data, type) {
        console.log(data)

        if (type === 'IsEnabled') {
            console.log(data)
            this.props.callback(data, 'IsEnabled')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
    }



    render() {
        return (
            <div>
                <Row>
                    <Col lg={12}>
                        <Label className={'text-24'}>สถานะข้อมูล</Label>
                    </Col>
                </Row>
                {this.props.lang === 'th' ?
                    <Row className={'mt-3'}>
                        <Col lg={2}>
                            <Label className="font-style-16 cl-black" for="Name">สถานะข้อมูล </Label><Label className="required"> *</Label>
                        </Col>
                        <Col lg={2}>
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    id={'IsEnabled'}
                                    onChange={() => false}
                                    checked={this.props.IsEnabled === "Y" ? true : false}
                                />
                                <label
                                    className="custom-control-label"
                                    onClick={() => {
                                        this.selectedHandle("Y", 'IsEnabled')
                                    }}
                                >
                                    <Label className="font-style-16 cl-black">เผยแพร่</Label>
                                </label>
                            </div>
                        </Col>
                        <Col lg={2}>
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    id={'IsEnabled'}
                                    onChange={() => false}
                                    checked={this.props.IsEnabled === "N" ? true : false}
                                />
                                <label
                                    className="custom-control-label"
                                    onClick={() => {
                                        this.selectedHandle("N", 'IsEnabled')
                                    }}
                                >
                                    <Label className="font-style-16 cl-black">ไม่เผยแพร่</Label>
                                </label>
                            </div>
                        </Col>
                    </Row>
                    :
                    <div>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }

            </div >
        )
    }
}
