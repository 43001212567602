import React, { Component } from 'react'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    CardSubtitle
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import pana from '../../assets/images/ttd/pana.svg'
import { Link } from "react-router-dom"
import { api_endpoint } from '../../global-config'

import ManageteamLangTh from "./ManageteamLang/ManageteamLangTh"
import ManageteamLangEn from "./ManageteamLang/ManageteamLangEn"
import ManageteamLangZh from "./ManageteamLang/ManageteamLangZh"
import * as loadingData from "../../loadingttd.json";
import Lottie from "react-lottie";
import FadeIn from "react-fade-in";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};


export default class Manageteam extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lang: "th",
            isLoading: true,
            Thaipage: true,
            Engpage: false,
            Chinapage: false,
            provinceSelected: null,
            districtSelected: null,
            subDistrictSelected: null,
            provinces: [],
            districts: [],
            subdistricts: [],
            Maininfo_types: [],
            userData: JSON.parse(localStorage.getItem('authUser'))
        }

        this.Thaipage = this.Thaipage.bind(this);
        this.Engpage = this.Engpage.bind(this);
        this.Chinapage = this.Chinapage.bind(this);
        this.selectedHandle = this.selectedHandle.bind(this);
        console.log(props)
    }


    async Maininfo_types() {
        const response = await fetch(
            api_endpoint + `/lkup/maininfo_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (Array.isArray(_result)) {

            const asyncRes = await Promise.all(_result.map(async (e) => {
                return { ...e, isCheck: false }
            }));
            this.setState({
                Maininfo_types: asyncRes,
            }, () => {
                console.log(this.state.Maininfo_types)
                this.getProvinces()
            })
        }
        else {
            console.log('error : Maininfo_types')
        }
    }

    async getProvinces() {
        const response = await fetch(
            api_endpoint + `/lkup/editor_provinces`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + this.state.userData.token
                },
                body: JSON.stringify({
                    RegionID: [],
                    Code: []
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (Array.isArray(_result)) {
            const asyncRes = await Promise.all(_result.map(async (e) => {
                return { label: e.Details[this.state.lang].Name, value: e.Code }
            }));
            this.setState({
                provinces: asyncRes,
                isLoading: false
            }, () => {
                console.log(this.state.provinces)
            })
        }
        else {
            console.log('error : getProvinces')
        }
    }

    async getDistricts() {

        const response = await fetch(
            api_endpoint + `/lkup/districts`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + this.state.userData.token
                },
                body: JSON.stringify({
                    ProvinceCode: this.state.provinceSelected.value

                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (Array.isArray(_result)) {
            const asyncRes = await Promise.all(_result.map(async (e) => {
                return { label: e.Details[this.state.lang].Name, value: e.Code }
            }));
            this.setState({
                districts: asyncRes
            }, () => {
                console.log(this.state.districts)
            })
        }
        else {
            console.log('error : getProvinces')
        }
    }


    async getSubdistricts() {

        const response = await fetch(
            api_endpoint + `/lkup/subdistricts`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + this.state.userData.token
                },
                body: JSON.stringify({
                    ProvinceCode: this.state.provinceSelected.value,
                    DistrictCode: this.state.districtSelected.value,

                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (Array.isArray(_result)) {
            const asyncRes = await Promise.all(_result.map(async (e) => {
                return { label: e.Details[this.state.lang].Name, value: e.Code }
            }));
            this.setState({
                subdistricts: asyncRes
            }, () => {
                console.log(this.state.subdistricts)
            })
        }
        else {
            console.log('error : getSubdistricts')
        }
    }


    selectedHandle(value, type) {
        console.log(value, type)
        if (type === 'Provinces') {
            this.setState({
                provinceSelected: value,
                districtSelected: null,
                subDistrictSelected: null
            }, () => {
                this.getDistricts()
            })
        }
        if (type === 'Districts') {
            this.setState({
                districtSelected: value,
                subDistrictSelected: null
            }, () => {
                this.getSubdistricts()
            })
        }
        if (type === 'subDistricts') {
            this.setState({
                subDistrictSelected: value
            }, () => {

            })
        }
    }


    Thaipage() {

        this.setState({
            Thaipage: true,
            Engpage: false,
            Chinapage: false,
        })
    }

    Engpage() {

        this.setState({
            Engpage: true,
            Thaipage: false,
            Chinapage: false,
        })
    }

    Chinapage() {

        this.setState({
            Chinapage: true,
            Engpage: false,
            Thaipage: false,

        })
    }

    componentDidMount() {
        // this.getProvinces()
        // this.getDistricts()
        // this.getSubdistricts()
        this.Maininfo_types()
    }

    render() {
        if (this.state.isLoading === true) {
            return (
                <div className="text-center" >
                    <FadeIn>
                        <div style={{ display: "flex", justifyContent: 'center' }}>

                            <div >
                                <Lottie options={defaultOptions} height={300} width={300} />
                                <div>
                                    <h4 style={{ color: 'black', padding: 30 }}>กำลังโหลด</h4>
                                </div>

                            </div>


                        </div>

                    </FadeIn>
                </div>
            )
        }
        else {
            return (
                <React.Fragment>
                    <div className="page-content">
                        <Container fluid={true}>
                        
                            <Breadcrumbs title="หน้าแรก" link0="/dashboard-blog" breadcrumbItem="รายการทีมผู้จัดทำข้อมูล" link1="/Manageteammainpage"  breadcrumbItems="เพิ่มทีมผู้จัดทำข้อมูล" />

                            <Card>
                                <CardBody>
                                    <Row style={{ padding: 15 }}>
                                        <div style={{ alignSelf: 'center', width: '70%' }}>
                                            <h1 className="text-24" style={{ color: '#173865' }}>เพิ่มทีมผู้จัดทำข้อมูล</h1>
                                            <CardSubtitle className="text-16">กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนดำเนินการต่อไป </CardSubtitle>
                                        </div>
                                        <div style={{ textAlign: 'center', width: '30%' }}>
                                            <img style={{ width: '10vw', height: 'auto' }} src={pana}></img>
                                        </div>




                                    </Row>

                                    <hr></hr>
                                    <Row>
                                        <Col lg={4} >
                                            <div style={{ textAlign: 'center' }}>
                                                <h3 className="text-18" style={{ color: this.state.Thaipage == true ? '#173865' : '#808080' }} onClick={() => { this.Thaipage() }}>ภาษาไทย</h3>
                                                {this.state.Thaipage == true && <div class="hr-line"></div>}
                                            </div>

                                        </Col>
                                        <Col lg={4} style={{ cursor: 'pointer' }}>

                                            <div style={{ textAlign: 'center' }}>
                                                <h3 className="text-18" style={{ color: this.state.Engpage == true ? '#173865' : '#808080' }} onClick={() => { this.Engpage() }}>English</h3>
                                                {this.state.Engpage == true && <div class="hr-line"></div>}
                                            </div>


                                        </Col>
                                        <Col lg={4} style={{ cursor: 'pointer' }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <h3 className="text-18" style={{ color: this.state.Chinapage == true ? '#173865' : '#808080' }} onClick={() => { this.Chinapage() }}>简体中文</h3>
                                                {this.state.Chinapage == true && <div class="hr-line"></div>}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            {this.state.Thaipage === true && (

                                <ManageteamLangTh
                                    Maininfo_types={this.state.Maininfo_types}
                                    provinces={this.state.provinces}
                                    districts={this.state.districts}
                                    subdistricts={this.state.subdistricts}
                                    provinceSelected={this.state.provinceSelected}
                                    districtSelected={this.state.districtSelected}
                                    subDistrictSelected={this.state.subDistrictSelected}
                                    selectedHandle={this.selectedHandle}
                                    goBack={this.props.history.goBack}
                                />
                            )}
                            {this.state.Engpage === true && (
                                <ManageteamLangEn />
                            )}
                            {this.state.Chinapage === true && (
                                < ManageteamLangZh />
                            )}


                        </Container>
                    </div>
                </React.Fragment>
            )
        }
    }
}
