import React, { Component } from 'react'
import PropTypes, { element } from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { Link } from "react-router-dom"
import { api_endpoint, openapi_endpoint } from '../../../global-config'

import Map from "../../Dashboard-blog/MapsGoogle"
import Status from './topicOpenApi/Status'
import General from './topicOpenApi/General'
import DataApiKey from './topicOpenApi/DataApiKey'
import Information from './topicOpenApi/Information'
import * as loadingData from "../../../loadingttd.json";
import Lottie from "react-lottie";
import FadeIn from "react-fade-in";
// import Feedback from './topicAll/Feedback'

const moment = require('moment'); // require
import localization from 'moment/locale/th';
moment.updateLocale('th', localization);


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export default class CampaignTap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            IDEditter: null,

            isLoading: false,
            isLoadingDetail: false,
            placeId: null,
            data: {
                _id: "",
                ContactTitleName: "",
                ContactFirstName: "",
                ContactLastName: "",
                ContactOrganizationName: "",
                ContactEmail: "",
                ContactAddress: "",
                ContactTelephone: "",
                WebsiteName: "",
                WebsiteURL: "",
                AppAndroidName: "",
                AppAndroidUrl: "",
                AppIosName: "",
                AppIosUrl: "",
                Objective: "",
                ApiLevel: "Y",
            },
            Submit: {},
            status: null,
        }
        this.callback = this.callback.bind(this);
        // this.submitForm = this.submitForm.bind(this);
    }

    async getOpenApiDetail(id) {
        let path = `/users/` + id
        // if (this.state.status !== null) { path = '/maininfo/readoneupdate' }
        const userData = JSON.parse(localStorage.getItem('authUser'))
        const response = await fetch(
            openapi_endpoint + path,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
            }
        );

        var _result = await response.json();
        if (typeof _result === 'object') {

            this.setState({
                data: _result,
                isLoadingDetail: false,
            })

        } else {
            console.log('Error : getOpenApiDetail ')
        }

    }

    async submitData() {
        if (this.state.data.ContactTitleName === "") {
            return alert('กรุณากรอกคำนำหน้า')
        }
        if (this.state.data.ContactFirstName === "") {
            return alert('กรุณากรอกชื่อ')
        }
        if (this.state.data.ContactLastName === "") {
            return alert('กรุณากรอกนามสกุล')
        }
        if (this.state.data.ContactOrganizationName === "") {
            return alert('กรุณากรอกชื่อบริษัท/หน่วยงาน')
        }
        if (this.state.data.ContactEmail === "") {
            return alert('กรุณากรอกอีเมล์')
        }
        if (this.state.data.ContactTelephone === "") {
            return alert('กรุณากรอกเบอร์โทรศัพท์')
        }
        // if (this.state.data.Objective === "") {
        //     return alert('กรุณากรอกรายละเอียดการนำไปใช้')
        // }
        let ApiLevel = this.state.data.ApiLevel
        let Feedback = this.state.Feedback
        const userData = JSON.parse(localStorage.getItem('authUser'))
        if (userData.UserSubType === "Editor") {
            console.log('Editer >>>>')
            ApiLevel = "N"
            console.log(ApiLevel)
        }
        let obj = this.state.data
        //ปิดส่ง
        console.log(JSON.stringify(obj))
        // return
        const response = await fetch(
            openapi_endpoint + `/users`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (_result.status === "success") {
            alert('เพิ่มข้อมูล OpenApi สำเร็จ')
            window.location.href = "/Openapi";
        }
        else {
            console.log('error : submitData')
        }
    }

    callback(value, target) {
        if (target === 'ApiLevel') {
            this.state.data.ApiLevel = value
            this.setState({
                ApiLevel: this.state.data.ApiLevel
            }, () => {
                console.log(this.state.data.ApiLevel)
            })
            return
        }
        let obj = this.state.data
        obj[target] = value;
        this.setState({
            data: obj
        })
    }

    // submitForm(target, value) {
    //     if (this.state.Submit[target] === undefined) {
    //         this.state.Submit[target] = value
    //     }
    //     else {
    //         this.state.Submit[target] = value
    //     }
    //     console.log(this.state.Submit)
    // }

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const id = params.get('id');
        const status = params.get('status');

        if (id !== null) {
            this.setState({
                placeId: id,
                status: status,
                cloneId: id,
                isLoadingDetail: true

            }, () => {
                this.getOpenApiDetail(id)
            })
        }
        else {
            // this.attraction_types()
        }
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.lang) {
    //         this.setState({
    //             lang: nextProps.lang
    //         }, () => {
    //         })
    //     }
    // }

    render() {
        // console.log(this.state.Images)
        if (this.state.isLoading === true || this.state.isLoadingDetail === true) {
            return (
                <div className="text-center" >
                    <FadeIn>
                        <div style={{ display: "flex", justifyContent: 'center' }}>
                            <div >
                                <Lottie options={defaultOptions} height={300} width={300} />
                                <div>
                                    <h4 style={{ color: 'black', padding: 30 }}>กำลังโหลด</h4>
                                </div>
                            </div>
                        </div>
                    </FadeIn>
                </div>
            )
        }
        else {
            const userData = JSON.parse(localStorage.getItem('authUser'))
            console.log(userData)
            console.log(this.state.status)
            return (
                <div>
                    <Form onSubmit={() => { this.test() }}>
                        <Card>
                            <CardBody>
                                <General callback={this.callback} data={this.state.data} />
                            </CardBody>
                        </Card>
                    </Form>
                    <Card>
                        <CardBody>
                            <Information callback={this.callback} data={this.state.data} />
                        </CardBody>
                    </Card>
                    {this.state.data._id === "" ?
                        <></>
                        :
                        <Card>
                            <CardBody>
                                <DataApiKey data={this.state.data} />
                            </CardBody>
                        </Card>
                    }

                    <div>
                        <Card>
                            <CardBody>
                                {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ? <div><Status callback={this.callback} data={this.state.data} /> <hr></hr></div> : <div></div>}


                                {/* < Status /> */}
                                {this.state.placeId === null ?
                                    <Row style={{ justifyContent: 'flex-end' }}>
                                        <div className="col-auto">
                                            <Button
                                                style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                                type="submit"
                                                onClick={() => { this.submitData() }}
                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}
                                            </Button>
                                        </div>
                                        <div className="col-auto">
                                            <Link to="/Openapi">
                                                <Button
                                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}
                                                </Button>
                                            </Link>
                                        </div>
                                    </Row>
                                    :
                                    <div>

                                    </div>
                                }
                                {this.state.placeId !== null && this.state.status !== 'Y' ?
                                    <Row style={{ justifyContent: 'flex-end' }}>
                                        <div className="col-auto">
                                            <Button
                                                style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                                type="submit"
                                                onClick={() => { this.submitData() }}
                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb">แก้ไข</span>{" "}
                                            </Button>
                                        </div>
                                        <div className="col-auto">
                                            <Link to="/Openapi">
                                                <Button
                                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}
                                                </Button>
                                            </Link>
                                        </div>
                                    </Row>
                                    :
                                    <div></div>
                                }
                                {this.state.status === 'Y' && userData.UserSubType === 'Editor' && userData.UserType === 'B' ?
                                    <div></div>
                                    :
                                    <div></div>

                                }
                            </CardBody>
                        </Card>
                    </div>
                </div>

            )
        }

    }
}
