import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import logoplus from "../../../../assets/images/ttd/Vector.svg"
import Select from "react-select"
import { Link } from "react-router-dom"


const optionGroup = [
    {
        label: "Picnic",
        options: [
            { label: "วันอาทิตย์", value: 1 },
            { label: "วันจันทร์", value: 2 },
            { label: "วันอังคาร", value: 3 },
            { label: "วันพุธ", value: 4 },
            { label: "วันพฤหัสบดี", value: 5 },
            { label: "วันศุกร์", value: 6 },
            { label: "วันเสาร์", value: 7 },
            { label: "วันหยุดนักขัตฤกษ์", value: 8 },
        ],
    },
]

export default class DateTime extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedGroup: null,
            startTime: "08:00:00",
            endTime: "17:00:00",
            OpenHours: this.props.OpenHours
        }
        this.props.submit('OpenHours', this.state.OpenHours)
        console.log(props)
    }


    handleSelectGroup = selectedGroup => {
        console.log(selectedGroup)
        this.setState({ selectedGroup, startTime: "08:00:00", endTime: "17:00:00" })
    }

    addOpenHours() {
        if (this.state.selectedGroup === null) {
            return alert('กรุณาเลือกวัน')
        }
        let arr = this.state.OpenHours
        let filter = arr.filter(item => item.DayID === this.state.selectedGroup.value)
        if (filter.length < 1) {
            arr.push({ DayID: this.state.selectedGroup.value, IsAllDay: null, StartTime: this.state.startTime, EndTime: this.state.endTime })
            this.setState({
                OpenHours: arr,
                startTime: "08:00:00",
                endTime: "17:00:00",
            }, () => {
                this.props.submit('OpenHours', this.state.OpenHours)
            })
        }
        else {
            let index = arr.map((e) => { return e.DayID }).indexOf(this.state.selectedGroup.value)
            arr[index].StartTime = this.state.startTime
            arr[index].EndTime = this.state.endTime
            this.props.submit('OpenHours', arr)
        }
    }

    deleteOpenHours(index) {
        if (confirm('คุณต้องการลบช่วงเวลาที่เลือกใช่ไหม')) {
            let arr = this.state.OpenHours
            arr.splice(index, 1)
            this.setState({
                OpenHours: arr
            }, () => {
                this.props.submit('OpenHours', arr)
            })
        }
        else {
            return
        }
    }

    render() {
        const { selectedGroup } = this.state
        return (
            <div>
                <Label className={'text-24'}>วันที่และเวลาทำการ</Label>
                {this.props.lang === 'th' &&
                    <blockquote className="p-4 border-dashed rounded mb-3 mt-3">
                        {this.state.OpenHours.map((e, i) => {
                            let day = e.DayID
                            // console.log(day)
                            switch (day) {
                                case 1:
                                    day = 'วันอาทิตย์'
                                    // code block
                                    break;
                                case 2:
                                    day = 'วันจันทร์'
                                    // code block
                                    break;
                                case 3:
                                    day = 'วันอังคาร'
                                    // code block
                                    break;
                                case 4:
                                    day = 'วันพุธ'
                                    // code block
                                    break;
                                case 5:
                                    day = 'วันพฤหัสบดี'
                                    // code block
                                    break;
                                case 6:
                                    day = 'วันศุกร์'
                                    // code block
                                    break;
                                case 7:
                                    day = 'วันเสาร์'
                                    // code block
                                    break;
                                case 8:
                                    day = 'วันหยุดนักขัตฤกษ์'
                                    // code block
                                    break;
                                default:
                                // code block
                            }
                            return (
                                <Row style={{ width: '100%', minWidth: 150 }}>
                                    <h5 style={{ padding: 10 }}>{day}</h5>
                                    <h5 style={{ padding: 10 }}>{e.StartTime} น. -  {e.EndTime} น.</h5>
                                    <h5 style={{ padding: 10 }}><i class="fas fa-trash" style={{ color: 'red', cursor: 'pointer' }} onClick={() => { this.deleteOpenHours(i) }}></i></h5>
                                </Row>
                            )
                        })}
                        {this.state.OpenHours.length < 1 &&
                            <div className="d-flex text-16" style={{ justifyContent: 'center' }}>
                                <h5 className="font-style-16 cl-black" style={{ margin: '1rem' }}>กรุณาเพิ่มวันทำการ</h5>
                            </div>
                        }
                    </blockquote>
                }
                {this.props.lang === 'th' ?
                    <Row className="font-style-16 cl-black">
                        <Col lg="2" className={'mt-3'}>
                            <FormGroup className="select2-container">
                                <Label>วัน</Label>
                                <Select
                                    value={selectedGroup}
                                    onChange={this.handleSelectGroup}
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="2" className={'mt-3'}>
                            <FormGroup>
                                <Label for="agencyName">เวลาเปิด</Label>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <input
                                            style={{ fontSize: 16 }}
                                            className="form-control"
                                            type="time"
                                            defaultValue={this.state.startTime}
                                            value={this.state.startTime}
                                            id="example-time-input"
                                            onChange={(e) => { this.setState({ startTime: e.target.value }) }}
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        </Col>
                        <p className={'mt-3'} style={{ alignSelf: 'center', paddingTop: 26 }}>-</p>
                        <Col lg="2" className={'mt-3'}>
                            <FormGroup>
                                <Label for="agencyName">เวลาปิด</Label>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <input
                                            style={{ fontSize: 16 }}
                                            className="form-control"
                                            type="time"
                                            defaultValue={this.state.endTime}
                                            value={this.state.endTime}
                                            id="example-time-input"
                                            onChange={(e) => { this.setState({ endTime: e.target.value }) }}
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col lg="2" style={{ alignSelf: 'center', marginTop: '1.9rem' }}>
                            <Link
                                style={{ backgroundColor: '#173865', borderColor: '#173865' }}

                                className="btn btn-primary waves-effect waves-light w-lg "
                                onClick={() => { this.addOpenHours() }}
                            >
                                <img src={logoplus} style={{ padding: '0px 10px 3px 0px', width: 30 }} />
                                {/* <i className="mdi mdi-chevron-right ml-1"></i> */}
                                <span className="d-none d-sm-inline-block font-style-16nb">เพิ่มเวลาทำการ</span>{" "}
                            </Link>
                        </Col>
                    </Row>
                    :
                    <div className="font-style-16 cl-black">
                        <p style={{ color: '#173865' }}>
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }
                <Row className="text-16">
                    <Col lg="6" className={'mt-3 font-style-16 cl-black'}>
                        {this.props.lang === 'th' &&
                            <FormGroup>
                                <Label for="businesshours">หมายเหตุเวลาทำการ</Label>
                                <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="businesshours" placeholder="กรุณากรอกหมายเหตุเวลาทำการ" value={this.props.data.OpenHourRemark} onChange={(e) => { this.props.callback(e.target.value, 'OpenHourRemark') }} />
                            </FormGroup>
                        }
                        {this.props.lang === 'en' &&
                            <FormGroup>
                                <Label for="businesshours">หมายเหตุเวลาทำการ</Label>
                                <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="businesshours" placeholder="กรุณากรอกหมายเหตุเวลาทำการ" value={this.props.data.OpenHourRemark} onChange={(e) => { this.props.callback(e.target.value, 'OpenHourRemark') }} />
                            </FormGroup>
                        }
                        {this.props.lang === 'zh' &&
                            <FormGroup>
                                <Label for="businesshours">หมายเหตุเวลาทำการ</Label>
                                <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="businesshours" placeholder="กรุณากรอกหมายเหตุเวลาทำการ" value={this.props.data.OpenHourRemark} onChange={(e) => { this.props.callback(e.target.value, 'OpenHourRemark') }} />
                            </FormGroup>
                        }
                    </Col>
                    {/* <Col lg="6" className={'mt-3 font-style-16 cl-black'}>
                        {this.props.lang === 'th' &&
                            <FormGroup>
                                <Label for="agencyName">ช่วงที่เหมาะสม</Label>
                                <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Suitablerange" placeholder="กรุณากรอกช่วงที่เหมาะสม" value={this.props.Suitablerange} onChange={(e) => { this.props.callback(e.target.value, 'Suitablerange') }} />
                            </FormGroup>
                        }
                        {this.props.lang === 'en' &&
                            <FormGroup>
                                <Label for="agencyName">ช่วงที่เหมาะสม</Label>
                                <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Suitablerange" placeholder="กรุณากรอกช่วงที่เหมาะสม" value={this.props.Suitablerange} onChange={(e) => { this.props.callback(e.target.value, 'Suitablerange') }} />
                            </FormGroup>
                        }
                        {this.props.lang === 'zh' &&
                            <FormGroup>
                                <Label for="agencyName">ช่วงที่เหมาะสม</Label>
                                <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Suitablerange" placeholder="กรุณากรอกช่วงที่เหมาะสม" value={this.props.Suitablerange} onChange={(e) => { this.props.callback(e.target.value, 'Suitablerange') }} />
                            </FormGroup>
                        }
                    </Col> */}
                </Row>
            </div>
        )
    }
}
