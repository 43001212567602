import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"
import { api_endpoint } from "global-config"

class Stack extends Component {
    constructor(props) {
        super(props)
        this.state = {
            StartDate: this.props.StartDate,
            EndDate: this.props.EndDate,
            Limit: this.props.Limit,
            Sort: this.props.Sort,
            userData: JSON.parse(localStorage.getItem('authUser')),
            yAxis: {
                data: []
            },
            series: [
                {
                    name: "",
                    data: []
                }
            ]

        },
            // console.log("props stack", props)
            this.getMainInfoType = this.getMainInfoType.bind(this)
    }

    componentDidMount() {
        this.getMainInfoType();
    }
    //จำนวนข้อมูลแยกตามประเภท
    async getMainInfoType() {
        const url = api_endpoint + "/report/province/maininfo/type/view/count";
        const body = {
            StartDate: this.state.StartDate,
            EndDate: this.state.EndDate,
            Limit: this.state.Limit,
            Sort: this.state.Sort
        }

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: 'bearer ' + this.state.userData.token
            },
            body: JSON.stringify(body)
        })

        const _result = await response.json();
        // console.log("_result", _result)
        this.setState({
            yAxis: _result.chartData.yAxis,
            series: _result.chartData.series
        }, () => {
            // console.log("data", this.state.data)
            // console.log("series", this.state.series)
        })

    }

    getOption = () => {
        const categories = this.state.yAxis.data;

        const series = this.state.series.map((data) => {
            // console.log("dataaaaaa", data)
            return ({
                ...data,
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: data.data
            })
        })

        // console.log("series", series)
        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            toolbox: {
                show: true,
                feature: {
                    dataView: { readOnly: false },
                    // magicType: { type: ['line', 'bar'] },
                    // restore: {},
                    saveAsImage: {}
                }
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value'
            },
            yAxis: {
                type: 'category',
                data: categories
            },
            series
        };
    }

    render() {
        return (
            <React.Fragment>
                <ReactEcharts style={{ height: "450px" }} option={this.getOption()} />
            </React.Fragment>
        );
    }
}

export default Stack;
