import React, { Component } from "react"
import PropTypes from 'prop-types'

import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react"
import { connect } from "react-redux"
import { Card, CardBody, CardSubtitle, CardTitle, Col, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const LoadingContainer = () => <div>Loading...</div>

const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: '10px'
}

class MapsGoogle extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            initialCenter: {
                lat: 13.736717,
                lng: 100.523186
            },
            initialSelected: {
                lat: props.lat === "" ? 0 : props.lat,
                lng: props.lng === "" ? 0 : props.lng
            }
        }
        // console.log(props)
    }

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };


    componentWillReceiveProps(nextProps) {
        // console.log(nextProps)
        if (nextProps.lat !== "" && nextProps.lng !== "") {
            this.setState({
                initialSelected: {
                    lat: parseFloat(nextProps.lat),
                    lng: parseFloat(nextProps.lng),
                }
            }, () => {
                // console.log(this.state.initialSelected)
            })
        }
    }


    markerHandle(data) {
        // console.log(this._map.LatLng)

        // this.setState({
        //     initialSelected:{
        //         lat:data.initialCenter.lat,
        //         lng:data.initialCenter.lng
        //     }
        // })
    }



    render() {
        // console.log(this.state.initialSelected)
        // console.log(this.state.initialCenter)
        return (
            <React.Fragment>
                <Row>
                    <Col lg={12}>
                        <div
                            id="gmaps-markers"
                            className="gmaps"
                            style={{ position: "relative", width: '100%', height: 350 }}

                        >

                            <Map
                                containerStyle={containerStyle}
                                // style={{ height: this.props.height }}
                                onClick={this.onMapClicked}
                                google={this.props.google}
                                zoom={this.props.zoom}
                                initialCenter={this.state.initialSelected.lat !== 0 && this.state.initialSelected.lng !== 0 ? this.state.initialSelected : this.state.initialCenter}
                                center={this.state.initialSelected.lat !== 0 && this.state.initialSelected.lng !== 0 ? this.state.initialSelected : this.state.initialCenter}
                                onClick={e => { this.markerHandle(e) }}
                            >
                                {this.state.initialSelected.lat !== 0 && this.state.initialSelected.lng !== 0 ?
                                    <Marker position={this.state.initialSelected} /> :
                                    null
                                }


                                <InfoWindow
                                    marker={this.state.activeMarker}
                                    visible={this.state.showingInfoWindow}>
                                    <div>
                                        <h1>{this.state.selectedPlace.name}</h1>
                                    </div>
                                </InfoWindow>
                            </Map>
                        </div>

                    </Col>
                </Row>

            </React.Fragment>
        )
    }
}

MapsGoogle.propTypes = {
    google: PropTypes.object
}

export default connect(
    null,
    {}
)(
    GoogleApiWrapper({
        apiKey: 'AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs',
        LoadingContainer: LoadingContainer,
        v: "3",
    })(MapsGoogle)
)
