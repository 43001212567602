import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
  TabPane,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,


} from "reactstrap"
export default class Target extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Lang: 'th',
      Targets: this.props.Target,
      StandardTargets: this.props.StandardTarget,
      //Taget ซ้าย
      Family: false,
      Elderly: false,
      Working: false,
      Student: false,
      youth: false,
      Teenagers: false,
      Women: false,
      Muslim: false,
      Couples: false,
      MarriedCouples: false,
      //Taget ขวา
      Golf: false,
      Wedding: false,
      Health: false,
      Soft: false,
      Religious: false,
      MICE: false,
      Volunteer: false,
      Art: false,
      Lifestyle: false,

    }

  }
  componentDidMount() {
    let filter = this.state.StandardTargets.filter(item => item.isCheck === true)
    for (let i = 0; i < filter.length; i++) {
      filter[i] = { StandardTargetID: filter[i].ID }
    }
    this.props.submit('StandardTargets', filter)

  }

  render() {
    return (
      <div>

        <Label className={'mb-3 text-24'}>กลุ่มเป้าหมาย</Label>
        {this.props.lang === 'th' ?
          <Row className="font-style-16 cl-black">
            {this.state.StandardTargets.map((e, i) => (
              <Col lg={4} key={i}>
                <div className="custom-control custom-checkbox mb-3"
                  onClick={() => {
                    let arr = this.state.StandardTargets
                    arr[i].isCheck = !arr[i].isCheck
                    this.setState({
                      StandardTargets: arr
                    }, () => {
                      let filter = this.state.StandardTargets.filter(item => item.isCheck === true)
                      for (let i = 0; i < filter.length; i++) {
                        filter[i] = { StandardTargetID: filter[i].ID }
                      }
                      this.props.submit('StandardTargets', filter)
                    })
                  }}>
                  <input
                    key={i}
                    type="checkbox"
                    className="custom-control-input"
                    checked={e.isCheck}
                  />
                  <label
                    className="custom-control-label font-style-16b4"
                  >
                    {e.Details[this.state.Lang].Name}
                  </label>
                </div>
              </Col>
            ))}
          </Row>
          :
          <FormGroup className="font-style-16">
            <p style={{ color: '#173865' }}>
              (ให้จัดการข้อมูลที่ภาษาไทย)
            </p>
          </FormGroup>
        }
      </div>
    )
  }
}
