import React, { Component } from "react"
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import logoplus from "../../assets/images/ttd/Vector.svg"
import { api_endpoint } from '../../global-config'
import DataGrid from '../../components/Table/Table'
import Edit from '../../assets/images/ttd/Edit.svg'

const typeOption = [
  { label: "ทั้งหมด", value: null },
  { label: "เผยแพร่", value: "Y" },
  { label: "ไม่เผยแพร่", value: "N" }
]

class Accommodation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedGroup: null,
      search: "",
      lang: 'th',
      skip: 0,
      limit: 10,
      dataTable: [],
      isLoading: true,
      sortModel: [{
        field: 'id',
        sort: 'desc'
      }],
      selected_date_start: null,
      selected_date_end: null,
      IsEnabled: { label: "ทั้งหมด", value: null },
      regions: [],
      provinces: [],
      proviceCode: null,
      regionCode: null,
      rowsPerPageOptions: [10, 25, 50],
      pageSize: 10
    }
  }

  setPageSize = (value) => {
    this.setState({
      isLoading: true,
      skip: value,
      limit: value,
      pageSize: value
    }, () => {
      this.searchLocation()
    })
  }

  // async getAccommodation() {
  //   let sortBy = []
  //   if (this.state.sortModel[0].field === 'Name') {
  //     sortBy = [
  //       {
  //         field: "Main.Details." + this.state.lang + ".Name",
  //         sort: this.state.sortModel[0].sort === 'asc' ? 1 : -1
  //       }]
  //   }
  //   if (this.state.sortModel[0].field === 'Province') {
  //     sortBy = [
  //       {
  //         field: "Main.Info.Province." + this.state.lang + ".Name",
  //         sort: this.state.sortModel[0].sort === 'asc' ? 1 : -1
  //       }]
  //   }
  //   if (this.state.sortModel[0].field === 'ViewCount') {
  //     sortBy = [
  //       {
  //         field: "View.ViewCount",
  //         sort: this.state.sortModel[0].sort === 'asc' ? 1 : -1
  //       }]
  //   }
  //   const response = await fetch(
  //     api_endpoint + `/maininfo/read`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json"
  //       },
  //       body: JSON.stringify({
  //         // ID: this.state.place_id,
  //         Search: this.state.search === "" ? null : this.state.search,
  //         Lang: this.state.lang,
  //         Skip: this.state.skip,
  //         MainTypeID: [4],
  //         Limit: this.state.limit,
  //         // SortByView: 1,
  //         SortBy: sortBy,
  //         IsBackend: 'Y'

  //       }), // body data type must match "Content-Type" header
  //     }
  //   );

  //   var _result = await response.json();
  //   // console.log(_result)
  //   if (_result.results !== undefined) {
  //     let arr = []
  //     _result.results.map((e) => {

  //       arr.push({
  //         id: e.ID,
  //         Name: e.Name[this.state.lang],
  //         Province: e.Province[this.state.lang],
  //         District: e.District[this.state.lang],
  //         ViewCount: e.ViewCount,
  //         NeedApproval: e.NeedApproval,
  //       })

  //     })
  //     this.setState({
  //       countData: _result.count,
  //       dataTable: arr,
  //       isLoading: false
  //     })
  //   }
  //   else {
  //     // console.log('error : getAccommodation')
  //   }

  // }

  async searchLocation() {
    let sortBy = []
    // console.log(this.state.sortModel[0])
    const sortFields = {
      'id': 'ID',
      'Name': "Main.Details." + this.state.lang + ".Name",
      'Province': "Main.Info.Province." + this.state.lang + ".Name",
      'ViewCount': "View.ViewCount",
      'IsEnabled': "IsEnabled"
    }
    if (this.state.sortModel[0].field !== undefined) {
      sortBy = [
        {
          field: sortFields[this.state.sortModel[0].field],
          sort: this.state.sortModel[0].sort === 'asc' ? 1 : -1
        }]
    }
    const response = await fetch(
      api_endpoint + `/maininfo/search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body:
          JSON.stringify({
            // ID: this.state.place_id,
            Search: this.state.search === "" ? null : this.state.search,
            Lang: this.state.lang,
            Skip: this.state.skip,
            MainTypeID: [4],
            Limit: this.state.limit,
            Subtype: [],
            ProvinceCode: this.state.proviceCode === null ? [] : [this.state.proviceCode.value],
            RegionID: (this.state.regionCode === null || this.state.regionCode.value === 0) ? [] : [Number(this.state.regionCode.value)],
            UpdatedBegin: this.state.selected_date_start,
            UpdatedEnd: this.state.selected_date_end,
            SortBy: sortBy,
            IsEnabled: this.state.IsEnabled.value,
            IsBackend: 'Y'
          }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    if (_result.sortedResults !== undefined) {
      let arr = []
      _result.sortedResults.map((e) => {
        let user = ''
        if (e.User.CreatedBy && e.User.CreatedBy.FirstName) {
          user = e.User.CreatedBy.FirstName
        }
        if (e.User.CreatedBy && e.User.CreatedBy.LastName) {
          user += ' ' + e.User.CreatedBy.LastName
        }
        arr.push({
          id: e.ID,
          Name: e.Name[this.state.lang],
          Province: e.Province[this.state.lang],
          District: e.District[this.state.lang],
          ViewCount: e.ViewCount,
          User: user,
          IsEnabled: e.IsEnabled,
        })

      })
      this.setState({
        isSerching: true,
        countData: _result.count,
        dataTable: arr,
        isLoading: false
      })
      // console.log(arr)
    }
    else {
      // console.log('error : searchLocation')
    }
  }

  handleSort = (value) => {
    let arr = this.state.sortModel
    // console.log(value)
    if (this.state.isSerching === true) {
      if (value.sortModel[0] === undefined) {
        arr[0].sort = 'asc'
        this.setState({
          sortModel: arr
        }, () => {
          this.searchLocation()
        })
      }
      else {
        arr[0].field = value.sortModel[0].field
        arr[0].sort = value.sortModel[0].sort
        this.setState({
          sortModel: arr
        }, () => {
          this.searchLocation()
        })
      }
    }
    else {
      arr[0].field = value.sortModel[0].field
      arr[0].sort = value.sortModel[0].sort
      this.setState({
        sortModel: arr
      })
      this.searchLocation()
    }
  }

  handleSelect(target, value) {
    if (target === 'regions') { this.setState({ regionCode: value, proviceCode: null }, () => { this.get_provinces() }) }
    if (target === 'provinces') { this.setState({ proviceCode: value }) }
  }

  dataGridPagging = (value) => {
    if ((value.page * this.state.pageSize) === this.state.limit) {
      this.setState({
        isLoading: true,
        limit: this.state.limit + this.state.pageSize
      }, () => {
        console.log(this.state.limit)
        this.searchLocation()
      })
    }
  }

  buttonHandle(type) {
    if (type === 'Search') {
      this.searchLocation()
    }
    if (type === 'Clear') {
      this.setState({
        search: "",
        selected_date_start: "",
        selected_date_end: "",
        IsEnabled: { label: "ทั้งหมด", value: null },
      }, () => {
        this.searchLocation()
      })
    }
  }

  inputHandle(value, type) {
    if (type === 'Search') {
      this.setState({
        search: value
      })
    }
    if (type === 'Date_Start') {
      this.setState({
        selected_date_start: value
      }, () => {
        // console.log(this.state.selected_date_start)
      })
    }
    if (type === 'Date_End') {
      this.setState({
        selected_date_end: value
      })
    }
    if (type === 'IsEnabled') {
      this.setState({
        IsEnabled: value
      }, () => {
        // console.log(this.state.IsEnabled)
      })
    }
  }

  async get_regions() {
    const response = await fetch(
      api_endpoint + `/lkup/regions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ID: []

          // ID: this.state.place_id,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    let arr = [{ value: 0, label: 'ทุกภูมิภาค' }]
    if (Array.isArray(_result)) { _result.map(e => { arr.push({ value: e.ID, label: e.Details[this.state.lang].Name }) }) }
    // console.log('---------' + arr)
    this.setState({
      regions: arr
    }, () => {
      this.get_provinces()
    })
  }

  async get_provinces() {
    const response = await fetch(
      api_endpoint + `/lkup/provinces`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          RegionID: (this.state.regionCode === null || this.state.regionCode.value === 0) ? [] : [Number(this.state.regionCode.value)],
          Code: [],
          // ID: this.state.place_id,
          Lang: this.state.lang,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    let arr = []
    if (Array.isArray(_result)) { _result.map(e => { arr.push({ value: e.Code, label: e.Details[this.state.lang].Name }) }) }
    this.setState({
      provinces: arr
    })
  }

  componentDidMount() {
    // this.getLocation()
    this.get_regions()
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="หน้าแรก" breadcrumbItem="ที่พัก" link="/Accommodation/AccommodationDetail" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <Row>
                      <Col>
                        <h1 style={{ color: '#173865' }}>ที่พัก</h1>
                      </Col>
                      <Col style={{ textAlign: 'end', alignSelf: 'center' }}>
                        <Link
                          style={{ backgroundColor: '#173865', borderColor: '#173865' }}
                          to="/Accommodation/AccommodationDetail"
                          className="btn btn-primary waves-effect waves-light w-lg "
                        >
                          <img src={logoplus} style={{ padding: '0px 10px 3px 0px', width: 30 }} />
                          {/* <i className="mdi mdi-chevron-right ml-1"></i> */}
                          <span className="font-style-16nb">เพิ่มที่พัก</span>{" "}
                        </Link>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody className="pb-auto">
                    <Row>
                      <Col>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control rounded chat-input"
                            style={{ fontSize: 16 }}
                            placeholder="ค้นหาสิ่งที่คุณต้องการ..."
                            value={this.state.search}
                            onChange={(e) => { this.inputHandle(e.target.value, 'Search') }}
                          />
                        </div>
                      </Col>
                      <div className="col-auto">
                        <Button
                          style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                          type="submit"
                          onClick={() => { this.buttonHandle('Clear') }}
                          className="chat-send w-md waves-effect waves-light"
                        >
                          <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ล้างค่าค้นหา</span>{" "}
                        </Button>
                      </div>
                      <div className="col-auto">
                        <Button
                          style={{ backgroundColor: '#F47806', borderColor: '#F47806' }}
                          type="submit"
                          onClick={() => { this.buttonHandle('Search') }}
                          className="chat-send w-md waves-effect waves-light"
                        >
                          <span className="d-none d-sm-inline-block font-style-16nb">ค้นหา</span>{" "}
                        </Button>
                      </div>
                    </Row>
                    <Row className={'mt-3'}>
                      <Col lg={4}>
                        <div className="form-group">
                          <label className="font-style-16 cl-black">ปรับปรุงตั้งแต่วันที่</label>
                          <input type="date" value={this.state.selected_date_start}
                            max={this.state.selected_date_end}
                            onChange={(e) => {
                              this.inputHandle(e.target.value, 'Date_Start')
                            }} placeholder="ปรับปรุงตั้งแต่วันที่" className="form-control" style={{ fontSize: 16 }}></input>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="form-group">
                          <label className="font-style-16 cl-black">ถึงวันที่</label>
                          <input type="date" value={this.state.selected_date_end}
                            min={this.state.selected_date_start}
                            onChange={(e) => {
                              this.inputHandle(e.target.value, 'Date_End')
                            }} placeholder="ถึงวันที่" className="form-control" style={{ fontSize: 16 }}></input>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <FormGroup className="select2-container">
                          <label className="font-style-16 cl-black">สถานะ</label>
                          <Select
                            value={this.state.IsEnabled}
                            onChange={(e) => { this.inputHandle(e, 'IsEnabled') }}
                            options={typeOption}
                            classNamePrefix="select2-selection" className="font-style-16b4"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className="select2-container">
                          <label className="font-style-16 cl-black">ภูมิภาค</label>
                          <Select
                            value={this.state.regionCode === null ? { label: 'กรุณาเลือกภูมิภาค', value: -1 } : this.state.regionCode}
                            onChange={(e) => { this.handleSelect('regions', e) }}
                            options={this.state.regions}
                            classNamePrefix="select2-selection" className="font-style-16b4"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup className="select2-container">
                          <label className="font-style-16 cl-black">จังหวัด</label>
                          <Select
                            value={this.state.proviceCode === null ? { label: 'กรุณาเลือกจังหวัด', value: -1 } : this.state.proviceCode}
                            onChange={(e) => { this.handleSelect('provinces', e) }}
                            options={this.state.provinces}
                            classNamePrefix="select2-selection" className="font-style-16b4"
                          />
                          {/* <input type="date" value={this.state.selected_type} onChange={(e) => {
                            this.setState({
                              selected_type: e.target.value
                            })
                          }} placeholder="ประเภท" className="form-control" ></input> */}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  {/* <div className="p-3 chat-input-section">
                    <Row>
                      <div className="col-auto">
                        <Button
                          style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                          type="submit"
                          className="chat-send w-md waves-effect waves-light"
                        >
                          <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ค้นหาละเอียด </span>{" "}
                          <img src={logodown} style={{ paddingRight: 10, height: '7px' }} />
                        </Button>
                      </div>
                    </Row>
                  </div> */}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <DataGrid
                      header={
                        [
                          { field: 'id', headerName: 'ลำดับ', width: 120 },
                          { field: 'Name', headerName: 'ชื่อสถานที่', flex: 2 },
                          { field: 'Province', headerName: 'จังหวัด', flex: 1 },
                          { field: 'District', headerName: 'ตำบล', flex: 1 },
                          { field: 'User', headerName: 'ผู้ใช้งาน', flex: 1, sortable: false },
                          { field: 'ViewCount', headerName: 'จำนวนการเข้าถึง', flex: 1 },
                          {
                            field: 'IsEnabled', headerName: 'สถานะ', flex: 1,
                            renderCell: (params) => (
                              <div>
                                {params.value === 'Y' ?
                                  <span>
                                    เผยแพร่
                                  </span>
                                  :
                                  <span>
                                    ไม่เผยแพร่
                                  </span>
                                }
                              </div>
                            )
                          },
                          {
                            field: 'Action', headerName: 'จัดการ', width: 120,
                            renderCell: (params) => (
                              <strong>
                                <Link to={"/Accommodation/AccommodationDetail?id=" + params.id}
                                >
                                  <img src={Edit} style={{ paddingRight: 10 }} />
                                  {/* <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
            >
              Edit
      </Button> */}
                                </Link>
                              </strong>
                            ),
                          }]
                      }
                      sortModel={this.state.sortModel}
                      data={this.state.dataTable}
                      rowsCount={this.state.countData}
                      pageSize={this.state.pageSize}
                      pagging={this.dataGridPagging}
                      rowsPerPageOptions={this.state.rowsPerPageOptions}
                      loading={this.state.isLoading}
                      sort={this.handleSort}
                      setPageSize={this.setPageSize}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Accommodation
