
import React, { Component, useEffect, useState } from 'react'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    CardSubtitle,
    Modal,
} from "reactstrap"
import Select from "react-select"
import { Link } from "react-router-dom"
import { api_endpoint } from '../../global-config'
import General from 'pages/management/component/topicDisaster/General'
import Status from 'pages/management/component/topicDisaster/Status'
const moment = require('moment'); // require
import localization from 'moment/locale/th';
moment.updateLocale('th', localization);

export default class DisasterForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lang: props.lang,
            placeId: null,
            isLoading: false,
            isLoadingDetail: false,
            IDEditter: null,
            //------------------------------------------------------//
            userData: JSON.parse(localStorage.getItem('authUser')),
            IsEnabled: "Y",
            Details: {
                Lang: "th",
                Title: "",
                Detail: ""
            },
            th: {
                CoverImages: {},
                Lang: "",
                Title: "",
                Detail: ""
            },
            en: {
                Lang: "",
                Title: "",
                Detail: ""
            },
            zh: {
                Lang: "",
                Title: "",
                Detail: ""
            },
            PublishDate: null,
            PublishDown: null,
            // Image: [],
            Submit: {},
            status: null,
            placeId: null,
        }
        this.callback = this.callback.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }


    async SubmitAddDisaster() {
        const userData = JSON.parse(localStorage.getItem('authUser'))
        console.log("userData", userData)
        if (userData.UserSubType === "Editor") {
            console.log('Editer >>>>')
            IsEnabled = "N"
            // console.log(IsEnabled)
        }
        const body = {
            IsEnabled: this.state.IsEnabled,
            Main: {
                Info: {
                    PublishDate: this.state.PublishDate,
                    PublishDown: this.state.PublishDown
                },
                Details: {
                    [this.state.lang]: {
                        Lang: this.state.lang,
                        Title: this.state[this.state.lang].Title,
                        Detail: this.state[this.state.lang].Detail
                    },
                },
                Image: this.state.Submit.IntroImage,
            }
        }
        //ปิดส่ง
        console.log("check submit", JSON.stringify(body))
        // return
        const url = api_endpoint + '/newsinfo/create';
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + userData.token
            },
            body: JSON.stringify(body)
        })
        console.log("response", response)
        if (response.ok) {
            const _result = await response.json();
            console.log("_result Disaster", _result);
            if (_result.status === "ok") {
                alert('เพิ่มข้อมูลการแจ้งเตือนภัยพิบัติสำเร็จ');
                window.location.href = "/Disaster";
            } else {
                console.log('error : เพิ่มข้อมูลไม่สำเร็จ');
            }
        } else {
            console.log('Error in response:', response.status);
        }
    }

    async getDisasterDetail(id) {
        const url = api_endpoint + "/newsinfo/readone"
        const userData = JSON.parse(localStorage.getItem('authUser'))
        const response = await fetch(url,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify({
                    Lang: this.state.lang,
                    ID: Number(id),
                    // IsBackend: 'Y'
                }),
            }
        );
        const _result = await response.json();
        console.log("_result getDisaster", _result)
        if (_result.result !== undefined) {
            let th = this.state.th
            let en = this.state.en
            let zh = this.state.zh
            if (this.state.lang === 'th') {
                th = {
                    ...this.state.th,
                    CoverImages: _result.result.Image,
                    Title: _result.result.Title[this.state.lang],
                    Detail: _result.result.Detail[this.state.lang],
                    Lang: 'th'
                }
            }
            if (this.state.lang === 'en') {
                en = {
                    ...this.state.th,
                    CoverImages: _result.result.Image,
                    Title: _result.result.Title[this.state.lang],
                    Detail: _result.result.Detail[this.state.lang],
                    Lang: 'en'
                }
            }
            if (this.state.lang === 'zh') {
                zh = {
                    ...this.state.th,
                    CoverImages: _result.result.Image,
                    Title: _result.result.Title[this.state.lang],
                    Detail: _result.result.Detail[this.state.lang],
                    Lang: 'zh'
                }
            }
            this.state.PublishDate = _result.result.Main.Info.PublishDate,
                this.state.PublishDown = _result.result.Main.Info.PublishDown,
                this.setState({
                    [this.state.lang]: {
                        ...this.state[this.state.lang],
                        Title: _result.result.Title[this.state.lang],
                        Detail: _result.result.Detail[this.state.lang],
                        PublishDate: moment(this.state.PublishDate).format("YYYY-MM-DD"),
                        PublishDown: moment(this.state.PublishDown).format("YYYY-MM-DD"),
                        CoverImages: _result.result.Main.Image,
                        Lang: this.state.lang
                    },
                    IsEnabled: _result.result.IsEnabled,
                    isLoadingDetail: false
                }, () => {
                    // console.log("final state", this.state[this.state.lang])
                })
        } else {
            console.log('Error : getDisasterDetail ')
        }
    }

    async updateData() {
        const userData = JSON.parse(localStorage.getItem('authUser'))
        let body = {};
        if (this.state.lang === 'th') {
            body = {
                ID: Number(this.state.placeId),
                IsEnabled: this.state.IsEnabled,
                IsDeleted: "N",
                Main: {
                    Info: {
                        PublishDate: moment(this.state.PublishDate).format("YYYY-MM-DD"),
                        PublishDown: moment(this.state.PublishDown).format("YYYY-MM-DD")
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Title: this.state[this.state.lang].Title,
                            Detail: this.state[this.state.lang].Detail
                        }
                    },
                    Image: this.state[this.state.lang].CoverImages === null ? this.state.Submit.IntroImage : this.state[this.state.lang].CoverImages
                }
            }
            if (body.Main.Images === undefined) {
                delete body.Main.Images
            }
        }
        if (this.state.lang === 'en') {
            body = {
                ID: Number(this.state.placeId),
                IsEnabled: this.state.IsEnabled,
                IsDeleted: "N",
                Main: {
                    Info: {
                        PublishDate: this.state.PublishDate,
                        PublishDown: this.state.PublishDown
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Title: this.state[this.state.lang].Title,
                            Detail: this.state[this.state.lang].Detail
                        }
                    },
                    Image: this.state[this.state.lang].CoverImages === null ? this.state.Submit.IntroImage : this.state[this.state.lang].CoverImages
                }
            }
            if (body.Main.Images === undefined) {
                delete body.Main.Images
            }
        }
        if (this.state.lang === 'zh') {
            body = {
                ID: Number(this.state.placeId),
                IsEnabled: this.state.IsEnabled,
                IsDeleted: "N",
                Main: {
                    Info: {
                        PublishDate: this.state.PublishDate,
                        PublishDown: this.state.PublishDown
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Title: this.state[this.state.lang].Title,
                            Detail: this.state[this.state.lang].Detail
                        }
                    },
                    Image: this.state[this.state.lang].CoverImages === null ? this.state.Submit.IntroImage : this.state[this.state.lang].CoverImages
                }
            }
            if (body.Main.Images === undefined) {
                delete body.Main.Images
            }
        }


        //ปิดส่ง
        console.log(JSON.stringify(body))
        // return
        const url = api_endpoint + "/newsinfo/update"
        const response = await fetch(url,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(body), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log("_result", _result)
        if (_result.status === "ok") {
            alert('แก้ไขข้อมูลการแจ้งเตือนสำเร็จ')
            window.location.href = "/Disaster";
        }
        else {
            console.log('error : updateData')
        }
    }


    callback(value, target) {
        if (target === 'IsEnabled') {
            this.state.IsEnabled = value
            this.setState({
                IsEnabled: this.state.IsEnabled
            }, () => {
                console.log(this.state.IsEnabled)
            })
            return
        }
        if (target === 'PublishDate') {
            // console.log(value)
            this.setState({
                PublishDate: value
            }, () => {
                console.log(this.state.PublishDate)
            })
            return
        }
        if (target === 'PublishDown') {
            this.setState({
                PublishDown: value
            }, () => {
                console.log(this.state.PublishDown)
            })
            return
        }
        let obj = this.state[this.state.lang]
        // console.log("lang", this.state.lang)
        obj[target] = value;
        console.log("obj", obj)
        this.setState({
            [this.state.lang]: obj
        })
    }
    submitForm(target, value) {
        if (this.state.Submit[target] === undefined) {
            this.state.Submit[target] = value;
        } else {
            this.state.Submit[target] = value;
        }
        console.log("submitForm", this.state.Submit.IntroImage);
    }

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const id = params.get('id');
        const status = params.get('status');
        if (id !== null) {
            console.log('in')
            this.setState({
                placeId: id,
                status: status,
                cloneId: id,
                isLoadingDetail: true
            }, () => {
                this.getDisasterDetail(id);
            })
        }
        else {

        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.lang) {
            this.setState({
                lang: nextProps.lang
            }, () => {
                // console.log(this.state.lang)
            })
        }
    }
    render() {
        // console.log("sdadfsdfsdfsdf", this.state[this.state.lang])
        const userData = JSON.parse(localStorage.getItem('authUser'))
        return (
            <div>
                <Card>
                    <CardBody>
                        <General callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} submit={this.submitForm} />
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.placeId === null ?
                            <div>
                                <Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} data={this.state[this.state.lang]} submit={this.submitForm} />
                                <hr></hr>
                            </div> : <div></div>}
                        {this.state.placeId !== null && this.state.status !== 'Y' && (userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ?
                            <div>
                                <Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} data={this.state[this.state.lang]} submit={this.submitForm} />
                                <hr></hr>
                            </div> : <div></div>}
                        {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.status === 'Y' ?
                            <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} data={this.state[this.state.lang]} submit={this.submitForm} />
                                <hr></hr>
                            </div> : <div></div>}
                        {this.state.placeId !== null && this.state.status !== 'Y' && userData.UserSubType !== 'Admin' && userData.UserSubType !== 'SuperAdmin' ?
                            <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} data={this.state[this.state.lang]} submit={this.submitForm} />
                                <hr></hr>
                            </div> : <div></div>}
                    </CardBody>
                </Card>

                <div>
                    <Card>
                        <CardBody>
                            {this.state.placeId === null ?
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                            type="submit"
                                            onClick={() => { this.SubmitAddDisaster() }}
                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/Disaster">
                                            <Button
                                                style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                            </Button>
                                        </Link>
                                    </div>
                                </Row>
                                :
                                <div></div>
                            }
                            {this.state.placeId !== null && this.state.status !== 'Y' ?
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                            type="submit"
                                            onClick={() => { this.updateData() }}

                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">แก้ไข</span>{" "}
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/News">
                                            <Button
                                                style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                            </Button>
                                        </Link>
                                    </div>
                                </Row>
                                :
                                <div></div>
                            }
                            {this.state.status === 'Y' && userData.UserSubType === 'Editor' && userData.UserType === 'B' ?
                                <div></div>
                                :
                                <div></div>

                            }
                        </CardBody>
                    </Card>
                </div>
            </div>
        )
    }
}
