import React, { Component } from 'react'
import PropTypes from 'prop-types'
import logoplus from "../../../../assets/images/ttd/Vector.svg"
import pana from '../../../../assets/images/ttd/pana.svg'

import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    CardSubtitle,
    Modal,
} from "reactstrap"
import Flat from '../../../../assets/images/ttd/Flat.svg'
import { api_endpoint } from '../../../../global-config'

const memberType = [
    { label: "ข้าราชการ/พนักงานสังกัดสำนักงานท่องเที่ยวและกีฬาจังหวัด", value: 'official', isCheck: true },
    { label: "ข้าราชการ/พนักงานสังกัดส่วนราชการอื่น", value: 'officialo', isCheck: false },
    { label: "ผู้ประกอบการธุรกิจ/เอกชน/ชุมชน", value: 'private', isCheck: false },
    { label: "ศูนย์ปฏิบัติการ Digital Tourism ส่วนกลาง", value: 'tdtc', isCheck: false },
    { label: "อื่นๆ", value: 'other', isCheck: false },
]

export default class membersEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal_standard: false,
            userData: JSON.parse(localStorage.getItem('authUser')),
            isLoading: true,
            memberType: memberType,
            memberID: "",
            passwordCheck: null,
            submitData: {
                UserID: "",
                EditorTeam: {
                    EditorTeamID: "",
                    MemberLevel: "Editor",
                    MemberType: "official",
                    MemberTypeOther: null,
                    IsEnable: "Y"
                },
                User: {
                    Username: "",
                    TitleName: "",
                    FirstName: "",
                    LastName: "",
                    Telephone: "",
                    Mobile: "",
                    Email: ""
                }

            }
        }

        this.tog_standard = this.tog_standard.bind(this)
    }
    tog_standard() {
        this.setState(prevState => ({
            modal_standard: !prevState.modal_standard,
        }))
        this.removeBodyCss()
    }

    removeBodyCss() {
        document.body.classList.add("no_padding")
    }


    async deleteTeamMember() {
        const response = await fetch(
            api_endpoint + `/auth/delete`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + this.state.userData.token
                },
                body: JSON.stringify({
                    // EditorTeamID: this.state.submitData.EditorTeam.EditorTeamID,
                    // MemberLevel: this.state.memberTypeSelected.value === -1 ? null : this.state.memberTypeSelected.value,
                    // IsEnable: this.state.memberStatusSelected.value === -1 ? null : this.state.memberStatusSelected.value,
                    Username: this.state.submitData.User.Username
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        if (_result.status === 'error') {
            alert(_result.message)
        }
        else {
            this.props.history.goBack()
            alert('ลบสมาชิกสำเร็จ')
        }
    }



    async getTeamMember(value) {
        const response = await fetch(
            api_endpoint + `/editorteam/member/readone`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + this.state.userData.token
                },
                body: JSON.stringify({
                    // EditorTeamID: this.state.submitData.EditorTeam.EditorTeamID,
                    // MemberLevel: this.state.memberTypeSelected.value === -1 ? null : this.state.memberTypeSelected.value,
                    // IsEnable: this.state.memberStatusSelected.value === -1 ? null : this.state.memberStatusSelected.value,
                    UserID: Number(value)
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        if (_result.status === "ok") {
            let obj = this.state.submitData
            let arr = this.state.memberType
            arr.forEach(e => {

                if (e.value === _result.user.EditorTeam.MemberType) {
                    e.isCheck = true
                }
                else {
                    e.isCheck = false
                }
            })
            delete obj.User.Password
            obj.UserID = _result.user.ID
            obj.User.FirstName = _result.user.FirstName
            obj.User.LastName = _result.user.LastName
            obj.User.Username = _result.user.Username
            obj.User.Email = _result.user.Email
            obj.User.Mobile = _result.user.Mobile
            obj.User.Telephone = _result.user.Telephone
            obj.EditorTeam.MemberLevel = _result.user.UserSubType
            obj.EditorTeam.MemberType = _result.user.EditorTeam.MemberType
            obj.EditorTeam.MemberTypeOther = _result.user.EditorTeam.MemberTypeOther
            obj.EditorTeam.IsEnable = _result.user.EditorTeam.IsEnable
            this.setState({
                submitData: obj,
                memberType: arr,
                isLoading: false
            })
        }
        else {
            console.log('error : getTeamMember')
        }
    }

    inpuntHandle(value, type) {
        let obj = this.state.submitData
        if (type === "FirstName") {
            obj.User.FirstName = value
            this.setState({
                submitData: obj
            })
        }
        if (type === "LastName") {
            obj.User.LastName = value
            this.setState({
                submitData: obj
            })
        }
        if (type === "Email") {
            obj.User.Email = value
            this.setState({
                submitData: obj
            })
        }
        if (type === "Mobile") {
            obj.User.Mobile = value
            this.setState({
                submitData: obj
            })
        }
        if (type === "Telephone") {
            obj.User.Telephone = value
            this.setState({
                submitData: obj
            })
        }
        if (type === "MemberTypeOther") {
            obj.EditorTeam.MemberTypeOther = value
            this.setState({
                submitData: obj
            })
        }
        if (type === "Username") {
            obj.User.Username = value
            this.setState({
                submitData: obj
            })
        }
        if (type === "PasswordCheck") {
            this.setState({
                passwordCheck: value
            })
        }
        if (type === "Password") {
            obj.User.Password = value
            this.setState({
                submitData: obj
            })
        }
    }

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const teamID = params.get('teamID');
        const memberID = params.get('memberID');
        let obj = this.state.submitData
        obj.EditorTeam.EditorTeamID = Number(teamID)
        this.setState({
            teamID: teamID,
            memberID: memberID,
            submitData: obj
        }, () => {
            this.getTeamMember(memberID)
        })
    }

    async buttonHandle(type) {
        if (type === 'Update') {
            let obj = this.state.submitData
            console.log(this.state.submitData)
            if (obj.User.FirstName === "") {
                return alert('กรุณากรอกชื่อจริง')
            }
            if (obj.User.LastName === "") {
                return alert('กรุณากรอกนามสกุล')
            }
            if (obj.User.Email === "") {
                return alert('กรุณากรอกอีเมล')
            }
            if (obj.EditorTeam.MemberType === "other" && obj.EditorTeam.MemberTypeOther === null) {
                return alert('กรุณากรอกชื่อหน่วยงานอื่นๆ')
            }
            if (obj.User.Username === "") {
                return alert('กรุณากรอกชื่อบัญชี')
            }
            if (obj.User.Password === "") {
                return alert('กรุณากรอกรหัสผ่าน')
            }
            if (this.state.passwordCheck !== null && obj.User.Password === undefined) {
                return alert('กรุณากรอกรหัสผ่านให้ครบถ้วน')

            }
            if (obj.User.Password !== undefined && obj.User.Password !== this.state.passwordCheck) {
                return alert('กรุณากรอกรหัสผ่านให้ตรงกัน')
            }
            else {
                const response = await fetch(
                    api_endpoint + `/editorteam/member/update`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: 'bearer ' + this.state.userData.token
                        },
                        body: JSON.stringify(this.state.submitData), // body data type must match "Content-Type" header
                    }
                );
                var _result = await response.json();
                if (_result.status === 'error') {
                    alert(_result.message)
                }
                else {
                    // this.props.history.goBack()
                    alert('แก้ไชสมาชิกสำเร็จ')
                }
            }
        }

        if (type === 'ChnagePassword') {
            let obj = this.state.submitData
            console.log(this.state.submitData)
            if (obj.User.Password === "") {
                return alert('กรุณากรอกรหัสผ่าน')
            }
            if (this.state.passwordCheck !== null && obj.User.Password === undefined) {
                return alert('กรุณากรอกรหัสผ่านให้ครบถ้วน')

            }
            if (obj.User.Password !== undefined && obj.User.Password !== this.state.passwordCheck) {
                return alert('กรุณากรอกรหัสผ่านให้ตรงกัน')
            }
            else {
                const response = await fetch(
                    api_endpoint + `/user/update`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: 'bearer ' + this.state.userData.token
                        },
                        body: JSON.stringify({
                            Username: obj.User.Username,
                            Password: obj.User.Password,
                        }), // body data type must match "Content-Type" header
                    }
                );
                var _result = await response.json();
                if (_result.status === 'error') {
                    alert(_result.message)
                }
                else {
                    // this.props.history.goBack()
                    alert('แก้ไชสมาชิกสำเร็จ')
                }
            }
        }
        if (type === 'Clear') {
            this.getTeamMember(this.state.memberID)
            this.setState({
                passwordCheck: null
            }, () => {
                console.log(this.state.submitData)
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col style={{ alignSelf: 'center' }}>
                                        <h1 className="text-24" style={{ color: '#173865' }}>ปรับปรุงข้อมูลสมาชิก</h1>
                                        <CardSubtitle className="text-16">หากต้องการแก้ไขข้อมูลกรุณาตรวจสอบข้อมูลก่อนกดบันทึก</CardSubtitle>
                                    </Col>
                                    {/* <img src={pana}></img> */}
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <p className={'text-24'}>ข้อมูลทั่วไป</p>
                                <Row>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label className="font-style-16 cl-black" for="Name">ชื่อ </Label><Label className="required"> *</Label>
                                            <Input style={{ fontSize: 14 }} required type="text" className="form-control" id="FirstName" value={this.state.submitData.User.FirstName} placeholder="กรุณากรอกชื่อ" onChange={(e) => { this.inpuntHandle(e.target.value, "FirstName") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label className="font-style-16 cl-black" for="Name">นามสกุล </Label><Label className="required"> *</Label>
                                            <Input style={{ fontSize: 14 }} required type="text" className="form-control" id="LastName" value={this.state.submitData.User.LastName} placeholder="กรุณากรอกนามสกุล" onChange={(e) => { this.inpuntHandle(e.target.value, "LastName") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label className="font-style-16 cl-black" for="Name">อีเมล </Label><Label className="required"> *</Label>
                                            <Input style={{ fontSize: 14 }} required type="email" className="form-control" id="Email" value={this.state.submitData.User.Email} placeholder="กรุณากรอกอีเมล" onChange={(e) => { this.inpuntHandle(e.target.value, "Email") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label className="font-style-16 cl-black" for="Name">เบอร์มือถือ</Label>
                                            <Input style={{ fontSize: 14 }} type="tel" className="form-control" id="Mobile" value={this.state.submitData.User.Mobile} placeholder="กรุณากรอกเบอร์มือถือ" onChange={(e) => { this.inpuntHandle(e.target.value, "Mobile") }} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label className="font-style-16 cl-black" for="Name">เบอร์โทรศัพท์</Label>
                                            <Input style={{ fontSize: 14 }} type="tel" className="form-control" id="Telephone" value={this.state.submitData.User.Telephone} placeholder="กรุณากรอกเบอร์โทรศัพท์" onChange={(e) => { this.inpuntHandle(e.target.value, "Telephone") }} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={2}>
                                        <Label className="font-style-16 cl-black" for="Name">ประเภท </Label><Label className="required"> *</Label>
                                    </Col>
                                    <Col lg={4}>
                                        {this.state.memberType.map((e, i) => {
                                            if (e.label === 'อื่นๆ') {
                                                return (
                                                    <div key={i}>
                                                        <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                            onClick={() => {
                                                                let arr = this.state.memberType
                                                                let obj = this.state.submitData
                                                                obj.EditorTeam.MemberType = arr[i].value
                                                                arr.forEach(e => {
                                                                    if (arr[i].label === e.label) {
                                                                        e.isCheck = true
                                                                    }
                                                                    else {
                                                                        e.isCheck = false
                                                                    }
                                                                })
                                                                this.setState({
                                                                    memberType: arr,
                                                                    submitData: obj
                                                                }, () => {
                                                                    console.log(this.state.submitData)
                                                                })
                                                            }}
                                                        >
                                                            <input
                                                                type="radio"
                                                                className="custom-control-input"
                                                                checked={e.isCheck}

                                                            />
                                                            <label style={{ paddingLeft: 10 }}
                                                                className="custom-control-label font-style-16 cl-black"
                                                                htmlFor={"type" + i}
                                                            >
                                                                {e.label}
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <Input style={{ fontSize: 14, marginLeft: '2rem' }} type="text" className="form-control" id="Telephone" value={this.state.submitData.EditorTeam.MemberTypeOther} placeholder="กรุณากรอกรายละเอียดอื่นๆ" onChange={(e) => { this.inpuntHandle(e.target.value, "MemberTypeOther") }} />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            else {
                                                return (
                                                    <div key={i} className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                        onClick={() => {
                                                            let arr = this.state.memberType
                                                            let obj = this.state.submitData
                                                            obj.EditorTeam.MemberType = arr[i].value
                                                            arr.forEach(e => {
                                                                if (arr[i].label === e.label) {
                                                                    e.isCheck = true
                                                                }
                                                                else {
                                                                    e.isCheck = false
                                                                }
                                                            })
                                                            this.setState({
                                                                memberType: arr,
                                                                submitData: obj
                                                            }, () => {
                                                                console.log(this.state.submitData)
                                                            })
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"

                                                            className="custom-control-input"
                                                            checked={e.isCheck}
                                                        />
                                                        <label style={{ paddingLeft: 10 }}
                                                            className="custom-control-label font-style-16 cl-black"
                                                            htmlFor={"type" + i}
                                                        >
                                                            {e.label}
                                                        </label>
                                                    </div>
                                                )
                                            }

                                        })}
                                        <Row>


                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Label className={'text-24'}>จัดการบัญชีผู้ใช้งาน</Label>
                                <FormGroup>
                                    <Label className="font-style-16 cl-black" for="Name">ชื่ผู้ใช้งาน </Label><Label className="required"> *</Label>
                                    <Input style={{ fontSize: 14 }} disabled type="text" className="form-control" id="Username" placeholder="กรุณาชื่อผู้ใช้งาน" value={this.state.submitData.User.Username} onChange={(e) => { this.inpuntHandle(e.target.value, "Username") }} />
                                    {/* <p style={{ color: '#808080' }}>Username จะไม่สามารถเปลี่ยนแปลงได้ในภายหลัง</p> */}
                                </FormGroup>
                                {/* <FormGroup>
                                    <Label for="Name">รหัสผ่าน </Label><Label className="required"> *</Label>
                                    <Input type="text" className="form-control" id="Name" placeholder="กรุณากรอกชื่อ" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="Name">ยืนยันรหัสผ่าน * </Label><Label className="required"> *</Label>
                                    <Input type="text" className="form-control" id="Name" placeholder="กรุณากรอกชื่อ" />
                                </FormGroup> */}
                                <Row>
                                    <Col lg={2}>
                                        <Label className="font-style-16 cl-black" for="Name">ระดับสมาชิก </Label><Label className="required"> *</Label>
                                    </Col>
                                    <Col lg={10}>
                                        <Row>
                                            <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                onClick={() => {
                                                    let obj = this.state.submitData
                                                    obj.EditorTeam.MemberLevel = "SuperEditor"
                                                    this.setState({
                                                        submitData: obj
                                                    })
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    id="SuperEditor"
                                                    name="SuperEditor"
                                                    className="custom-control-input"
                                                    checked={this.state.submitData.EditorTeam.MemberLevel === "SuperEditor" ? true : false}
                                                />
                                                <label style={{ paddingLeft: 10 }}
                                                    className="custom-control-label font-style-16 cl-black"
                                                    htmlFor="customRadio4"
                                                >
                                                    หัวหน้าทีม
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                onClick={() => {
                                                    let obj = this.state.submitData
                                                    obj.EditorTeam.MemberLevel = "Editor"
                                                    this.setState({
                                                        submitData: obj
                                                    })
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    id="Editor"
                                                    name="Editor"
                                                    className="custom-control-input"
                                                    checked={this.state.submitData.EditorTeam.MemberLevel === "Editor" ? true : false}
                                                />
                                                <label style={{ paddingLeft: 10 }}
                                                    className="custom-control-label font-style-16 cl-black"
                                                    htmlFor="customRadio5"
                                                >
                                                    สมาชิก
                                                </label>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <p className="text-24">สถานะข้อมูล</p>
                                <Row>
                                    <Col lg={2}>
                                        <Label className="font-style-16 cl-black" for="Name">สถานะข้อมูล </Label><Label className="required"> *</Label>
                                    </Col>
                                    <Col lg={10}>
                                        <Row>
                                            <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                onClick={() => {
                                                    let obj = this.state.submitData
                                                    obj.EditorTeam.IsEnable = "Y"
                                                    this.setState({
                                                        submitData: obj
                                                    })
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    id="isEnable"
                                                    name="isEnable"
                                                    className="custom-control-input"
                                                    checked={this.state.submitData.EditorTeam.IsEnable === "Y" ? true : false}
                                                />
                                                <label style={{ paddingLeft: 10 }}
                                                    className="custom-control-label font-style-16 cl-black"
                                                    htmlFor="customRadio4"
                                                >
                                                    ปกติ
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                onClick={() => {
                                                    let obj = this.state.submitData
                                                    obj.EditorTeam.IsEnable = "N"
                                                    this.setState({
                                                        submitData: obj
                                                    })
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    id="isNotEnable"
                                                    name="isNotEnable"
                                                    className="custom-control-input"
                                                    checked={this.state.submitData.EditorTeam.IsEnable === "N" ? true : false}
                                                />
                                                <label style={{ paddingLeft: 10 }}
                                                    className="custom-control-label font-style-16 cl-black"
                                                    htmlFor="customRadio5"
                                                >
                                                    ระงับการใช้งาน
                                                </label>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                                <hr></hr>
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto">
                                        <Button type="button" className="chat-send w-md waves-effect waves-light" style={{ backgroundColor: '#DC3545', borderColor: '#DC3545' }}
                                            onClick={() => {
                                                if (confirm('คุณต้องการลบสมาชิกหมายเลข ' + this.state.memberID + ' ใช่ไหม')) {
                                                    this.deleteTeamMember()
                                                }
                                                else {

                                                }
                                            }}
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">ลบข้อมูล</span>{" "}
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Button type="button" className="chat-send w-md waves-effect waves-light"
                                            onClick={() => { this.buttonHandle('Update') }}
                                            data-toggle="modal"
                                            data-target="#myModal" style={{ backgroundColor: '#41AB00', borderColor: '#41AB00', width: '100%' }}>
                                            <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Button type="button" className="chat-send w-md waves-effect waves-light" style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                                            onClick={() => { this.buttonHandle('Clear') }}
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}
                                        </Button>
                                    </div>
                                </Row>
                                <Modal
                                    isOpen={this.state.modal_standard}
                                    toggle={this.tog_standard}
                                >
                                    <div>
                                        {/* <h5 className="modal-title mt-0" id="myModalLabel">
                                                Modal Heading
                            </h5> */}
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_standard: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div>
                                        <div className="col-lg-12" style={{ paddingTop: 20 }}>
                                            <div style={{ textAlign: 'center', marginRight: -67 }}>
                                                <img src={Flat} width="200" />
                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <p className="mt-3">ยืนยันการดำเนินการ?</p>
                                            <p className="mt-3">กรุณาตรวจสอบข้อมูลก่อนกดยืนยัน</p>
                                        </div>


                                    </div>
                                    <div className="modal-footer">
                                        <div className="col-auto">
                                            <Button
                                                style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                                                type="submit"
                                                onClick={this.tog_standard}
                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                            </Button>
                                        </div>
                                        <div className="col-auto">
                                            <Button
                                                style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                                type="submit"
                                                onClick={this.tog_standard}
                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb">ยืนยัน</span>{" "}

                                            </Button>
                                        </div>
                                        {/* <button
                                                type="button"
                                                onClick={this.tog_standard}
                                                className="btn btn-secondary waves-effect"
                                                data-dismiss="modal"
                                            >
                                                Close
                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-primary waves-effect waves-light"
                                            >
                                                Save changes
                            </button> */}
                                    </div>
                                </Modal>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <p className="text-24">เปลี่ยนรหัสผ่าน</p>
                                <Row>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label className="font-style-16 cl-black" for="Name">รหัสผ่านใหม่ </Label><Label className="required"> *</Label>
                                            <Input style={{ fontSize: 14 }} type="text" className="form-control" id="passwordCheck" placeholder="กรุณากรอกรหัสผ่านใหม่" value={this.state.passwordCheck === null ? "" : this.state.passwordCheck} onChange={(e) => { this.inpuntHandle(e.target.value, "PasswordCheck") }} />
                                            {/* <p style={{ color: '#808080' }}>Username จะไม่สามารถเปลี่ยนแปลงได้ในภายหลัง</p> */}
                                        </FormGroup>
                                    </Col>
                                    <Col style={{ alignSelf: 'flex-end' }}>
                                        <p className="font-style-14" style={{ color: '#AAAAAA' }}>คำแนะนำในการตั้งรหัสผ่าน</p>
                                        <p className="font-style-14" style={{ color: '#41AB00' }}><i class="fas fa-check-circle"></i> ควรมีความยาว อย่างน้อย 8 ตัว</p>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label className="font-style-16 cl-black" for="Name">ยืนยันรหัสผ่านใหม่  </Label><Label className="required"> *</Label>
                                            <Input style={{ fontSize: 14 }} type="text" className="form-control" id="Password" placeholder="กรุณากรอกรหัสผ่านใหม่" value={this.state.submitData.User.Password !== undefined ? this.state.submitData.User.Password : ""} onChange={(e) => { this.inpuntHandle(e.target.value, "Password") }} />
                                            {/* <p style={{ color: '#808080' }}>Username จะไม่สามารถเปลี่ยนแปลงได้ในภายหลัง</p> */}
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <hr></hr>
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto">
                                        <button onClick={() => { this.buttonHandle('ChnagePassword') }} type="button" class="btn btn-primary" style={{ backgroundColor: '#173865', borderColor: '#173865', width: '100%' }}>
                                            <span className="d-none d-sm-inline-block font-style-16nb">เปลี่ยนรหัสผ่าน</span>{" "}
                                        </button>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>

                    </Container>
                </div>
            </React.Fragment >
        )
    }
}
