import React, { Component } from "react"
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import logoplus from "../../assets/images/ttd/Vector.svg"
import logodown from "../../assets/images/ttd/donw.png"
import DataGrid from '../../components/Table/Table'
import { api_endpoint } from '../../global-config'
import Edit from '../../assets/images/ttd/Edit.svg'


const typeOptions = [
  { label: "ตามหมวดหมู่", value: "F" },
  { label: "ตามพื้นที่", value: "A" }
]

const statusOptions = [
  { label: "ใช้งาน", value: "Y" },
  { label: "ไม่ใช้งาน", value: "N" }
]


class Manageteammainpage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedGroup: null,
      lang: 'th',
      skip: 0,
      limit: 10,
      dataTable: [],
      mannageType: "",
      provinceSelected: "",
      nameSearch: "",
      IsEnable: "",
      sortModel: [{
        field: 'id',
        sort: 'desc'
      }],
      isLoading: true,
      rowsPerPageOptions: [10, 25, 50],
      pageSize: 10,
      userData: JSON.parse(localStorage.getItem('authUser'))
    }
    this.getTeams = this.getTeams.bind(this);
    // this.dataGridPagging = this.dataGridPagging.bind(this);
    console.log(props)
  }

  handleSort = (value) => {
    let arr = this.state.sortModel
    console.log(value.sortModel[0])
    if (value.sortModel[0] === undefined) {
      arr[0].sort = 'asc'
      this.setState({
        sortModel: arr
      }, () => {

      })
    }
    else {
      arr[0].field = value.sortModel[0].field
      arr[0].sort = value.sortModel[0].sort
      this.setState({
        sortModel: arr
      })

    }
  }

  setPageSize = (value) => {
    this.setState({
      isLoading: true,
      skip: value,
      limit: value,
      pageSize: value
    }, () => {
      this.getTeams()
    })
  }

  inputHandle(value, type) {
    if (type === 'Search') {
      this.setState({
        nameSearch: value
      })
    }
  }

  handleSelected(value, type) {
    if (type === 'type') {
      this.setState({
        mannageType: value.value
      }, () => {
        this.getTeams()
      })
    }
    if (type === 'status') {
      this.setState({
        IsEnable: value.value
      }, () => {
        this.getTeams()
      })
    }
    if (type === 'provinces') {
      this.setState({
        provinceSelected: value
      }, () => {
        this.getTeams()
      })
    }
  }


  selectedValue(type) {
    if (type === 'type') {
      if (this.state.mannageType === "") {
        return { label: 'ทั้งหมด', value: '-1' }

      }
      else {
        if (this.state.mannageType === 'F') {
          return { label: 'ตามหมวดหมู่', value: 'F' }
        }
        if (this.state.mannageType === 'A') {
          return { label: 'ตามพื้นที่', value: 'A' }
        }
      }
    }
    if (type === 'status') {
      if (this.state.IsEnable === "") {
        return { label: 'ทั้งหมด', value: '-1' }

      }
      else {
        if (this.state.IsEnable === 'Y') {
          return { label: 'ใช้งาน', value: 'Y' }
        }
        if (this.state.IsEnable === 'N') {
          return { label: 'ไม่ใช้งาน', value: 'N' }
        }
      }
    }
    if (type === 'provinces') {
      if (this.state.provinceSelected === "") {
        return { label: 'ทั้งหมด', value: '-1' }

      }
      else {
        return { label: this.state.provinceSelected.label, value: this.state.provinceSelected.value }
      }

    }
  }

  clearFilter() {
    this.setState({
      mannageType: "",
      provinceSelected: "",
      IsEnable: "",
      nameSearch: ""
    }, () => {
      this.getTeams()
    })
  }


  async getTeams() {
    const response = await fetch(
      api_endpoint + `/editorteam/read`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: 'bearer ' + this.state.userData.token
        },
        body: JSON.stringify({
          // ID: this.state.place_id,
          Lang: this.state.lang,
          Skip: this.state.skip,
          Limit: this.state.limit,
          Search: this.state.nameSearch === "" ? null : this.state.nameSearch,
          ManageProvinceCode: this.state.provinceSelected === "" ? null : this.state.provinceSelected.value,
          ManageType: this.state.mannageType === "" ? null : this.state.mannageType,
          IsEnable: this.state.IsEnable === "" ? null : this.state.IsEnable,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    console.log(_result)
    if (_result.results !== undefined) {
      let arr = []
      _result.results.map((e) => {
        console.log(e)
        arr.push({
          id: e.ID,
          Name: e.Details !== undefined ? e.Details[this.state.lang].Name : "",
          ManageProvinceName: e.ManageProvinceName,
          ManageType: e.ManageType,
          IsEnable: e.IsEnable,
        })

      })
      // const asyncRes = await Promise.all(_result.results.map(async (e, i) => {
      //   return { ...e, Name: e.Details[this.state.lang].Name, id: Number(e.ID) }
      // }));
      this.setState({
        countData: _result.count,
        dataTable: arr,
        isLoading: false
      }, () => {
        console.log(this.state.dataTable)
      })
    }
    else {
      console.log('error : getTeams')
    }
  }

  dataGridPagging = (value) => {
    if ((value.page * this.state.pageSize) === this.state.limit) {
      this.setState({
        isLoading: true,
        limit: this.state.limit + this.state.pageSize
      }, () => {
        console.log(this.state.limit)
        this.getTeams()
      })
    }
  }
  async getProvinces() {

    const response = await fetch(
      api_endpoint + `/lkup/provinces`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: 'bearer ' + this.state.userData.token
        },
        body: JSON.stringify({
          RegionID: [],
          Code: []

        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    if (Array.isArray(_result)) {
      const asyncRes = await Promise.all(_result.map(async (e) => {
        return { label: e.Details[this.state.lang].Name, value: e.Code }
      }));
      this.setState({
        provinces: asyncRes
      }, () => {
        this.getTeams()
      })
    }
    else {
      console.log('error : getProvinces')
    }
  }





  componentDidMount() {
    this.getProvinces()
  }


  render() {
    const { selectedGroup } = this.state
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="หน้าแรก" breadcrumbItem="รายการทีมผู้จัดทำข้อมูล" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <Row>
                      <Col>
                        <h1 style={{ color: '#173865' }}>รายการทีมผู้จัดทำข้อมูล</h1>
                      </Col>

                      <Col style={{ textAlign: 'end', alignSelf: 'center' }}>
                        <Link
                          style={{ backgroundColor: '#173865', borderColor: '#173865' }}
                          to="/Manageteam"
                          className="btn btn-primary waves-effect waves-light w-lg "
                        >
                          <img src={logoplus} style={{ padding: '0px 10px 3px 0px', width: 30 }} />
                          <span className="font-style-16nb">เพิ่มทีมผู้จัดทำข้อมูล</span>{" "}
                        </Link>
                      </Col>

                    </Row>


                  </CardBody>
                  <CardBody className="pb-auto">
                    <Row>
                      <Col>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control rounded chat-input"
                            style={{ fontSize: 16 }}
                            placeholder="ค้นหาสิ่งที่คุณต้องการ..."
                            onChange={(e) => { this.inputHandle(e.target.value, 'Search') }}
                            value={this.state.nameSearch}
                          />

                        </div>
                      </Col>
                      <div className="col-auto">
                        <Button
                          style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                          type="submit"
                          onClick={() => { this.clearFilter() }}
                          className="chat-send w-md waves-effect waves-light"
                        >
                          <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ล้างค่าค้นหา</span>{" "}

                        </Button>
                      </div>
                      <div className="col-auto">
                        <Button
                          style={{ backgroundColor: '#F47806', borderColor: '#F47806' }}
                          type="submit"
                          onClick={() => { this.getTeams() }}
                          className="chat-send w-md waves-effect waves-light"
                        >
                          <span className="d-none d-sm-inline-block font-style-16nb">ค้นหา</span>{" "}

                        </Button>
                      </div>
                    </Row>
                    <Row className={'mt-3'}>

                      <Col lg={4}>
                        <FormGroup className="select2-container">
                          <label className="font-style-16 cl-black">ประเภท</label>
                          <Select
                            value={this.selectedValue('type')}
                            onChange={(e) => this.handleSelected(e, 'type')}
                            options={typeOptions}
                            classNamePrefix="select2-selection" className="font-style-16b4"
                          />
                          {/* <input type="date" value={this.state.selected_type} onChange={(e) => {
                            this.setState({
                              selected_type: e.target.value
                            })
                          }} placeholder="ประเภท" className="form-control" ></input> */}
                        </FormGroup>
                      </Col>
                      <Col lg={4}>
                        <FormGroup className="select2-container">
                          <label className="font-style-16 cl-black">จังหวัด</label>
                          <Select
                            value={this.selectedValue('provinces')}
                            onChange={(e) => this.handleSelected(e, 'provinces')}
                            options={this.state.provinces}
                            classNamePrefix="select2-selection" className="font-style-16b4"
                          />
                          {/* <input type="date" value={this.state.selected_type} onChange={(e) => {
                            this.setState({
                              selected_type: e.target.value
                            })
                          }} placeholder="ประเภท" className="form-control" ></input> */}
                        </FormGroup>
                      </Col>
                      <Col lg={4}>
                        <FormGroup className="select2-container">
                          <label className="font-style-16 cl-black">สถานะ</label>
                          <Select
                            value={this.selectedValue('status')}
                            onChange={(e) => this.handleSelected(e, 'status')}
                            options={statusOptions}
                            classNamePrefix="select2-selection" className="font-style-16b4"
                          />
                          {/* <input type="date" value={this.state.selected_type} onChange={(e) => {
                            this.setState({
                              selected_type: e.target.value
                            })
                          }} placeholder="ประเภท" className="form-control" ></input> */}
                        </FormGroup>
                      </Col>

                    </Row>
                    {/* <hr></hr> */}
                    {/* <Row>

                      <div className="col-12">
                        <Button
                          style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                          type="submit"

                          className="chat-send w-md waves-effect waves-light"
                        >

                          <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ค้นหาละเอียด </span>{" "}
                          <img src={logodown} style={{ paddingRight: 10, height: '7px' }} />
                        </Button>
                      </div>
                    </Row> */}

                  </CardBody>

                </Card>

              </Col>
            </Row>
            <div>
              <Card>
                <CardBody>
                  <DataGrid
                    header={
                      [
                        { field: 'id', headerName: 'ลำดับ', width: 120 },
                        { field: 'Name', headerName: 'ชื่อทีม', flex: 1 },
                        { field: 'ManageProvinceName', headerName: 'จังหวัด', flex: 1 },
                        {
                          field: 'ManageType', headerName: 'ประเภท', flex: 1,
                          renderCell: (params) => {
                            if (params.value === 'F') {
                              return (<div>ตามหมวดหมู่</div>)
                            }
                            if (params.value === 'A') {
                              return <div>ตามพิ้นที</div>
                            }
                          }
                        },
                        {
                          field: 'IsEnable', headerName: 'สถานะ', flex: 1,
                          renderCell: (params) => {
                            if (params.value === 'Y') {
                              return (<div>ปกติ</div>)
                            }
                            if (params.value === 'N') {
                              return <div>ระงับ</div>
                            }
                          }
                        },
                        {
                          field: 'Action', headerName: 'จัดการ', width: 120,
                          renderCell: (params) => (

                            <strong>
                              <Link
                                to={"/Manageteammainpage/ManageteamLangDetail?teamID=" + params.row.id}
                              >
                                <img src={Edit} style={{ paddingRight: 10 }} />
                                {/* <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  style={{ marginLeft: 16 }}
                                >
                                  Edit
</Button> */}
                              </Link>

                            </strong>
                          ),
                        }]
                    }

                    data={this.state.dataTable}
                    rowsCount={this.state.countData}
                    pageSize={this.state.pageSize}
                    pagging={this.dataGridPagging}
                    rowsPerPageOptions={this.state.rowsPerPageOptions}
                    loading={this.state.isLoading}
                    setPageSize={this.setPageSize}
                  />



                </CardBody>
              </Card>

            </div>





          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Manageteammainpage
