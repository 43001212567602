import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal
} from "reactstrap"


import { Link } from "react-router-dom"
import DataGrid from '../component/Table'
import classnames from "classnames"

export default class Activity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal_standard: false,
            customActiveTab: "1",
            idSelected: "",
            subActivity: [],
            Attraction: this.props.Attraction,
            AttractionActivityTypeID: "",
            AttractionActivitySubTypeID: "",
            ActivityName: "",
            ActivityDetail: "",
            dataSubmit: this.props.Activities,
            reload: false
        }

        this.tog_standard = this.tog_standard.bind(this)
        this.toggleCustom = this.toggleCustom.bind(this)

    }
    toggleCustom(tab) {
        if (this.state.customActiveTab !== tab) {
            this.setState({
                customActiveTab: tab,
            })
        }
    }


    tog_standard() {
        this.setState(prevState => ({
            modal_standard: !prevState.modal_standard,
        }))
        this.removeBodyCss()
    }

    removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    onChangeThai() {
        this.setState({
            Thai: true
        })
    }
    onChangeEnglish() {
        this.setState({
            English: true
        })
    }

    onChangeChina() {
        this.setState({
            China: true
        })
    }

    subActivity(id) {

        let arr = this.state.Attraction
        console.log(arr)
        let filter = arr.filter(item => item.ID === Number(id))
        if (filter[0].SubTypes !== undefined) {
            this.setState({
                subActivity: filter[0].SubTypes
            }, () => {
                // console.log(this.state.subActivity)
            })
        }
        else {
            this.setState({
                subActivity: []
            })
        }



    }

    submitActivity() {
        let arr = this.state.dataSubmit
        arr.push({
            id: arr.length,
            ActivityName: this.state.ActivityName,
            AttractionActivityTypeID: Number(this.state.AttractionActivityTypeID),
            AttractionActivitySubTypeID: Number(this.state.AttractionActivitySubTypeID),
            Details: {
                Lang: this.props.lang,
                Name: this.state.ActivityName,
                Detail: this.state.ActivityDetail
            }
        })
        this.setState({
            dataSubmit: arr,
            modal_standard: !this.state.modal_standard,
            reload: false
        }, () => {
            this.props.submit('Attraction', this.state.dataSubmit)

        })
    }

    componentDidMount() {
        this.props.submit('Attraction', this.state.dataSubmit)
    }




    render() {
        // console.log(this.props)
        return (
            <div>

                <Label className={'text-24'}>ข้อมูลเชฟ</Label>
                <Row className={'text-16'} style={{ paddingBottom: 15 }}>
                    <Col style={{ textAlign: 'end', alignSelf: 'center' }}>
                        <button type="button" onClick={this.tog_standard} class="btn btn-primary" data-toggle="modal" data-target="#myModal" style={{ backgroundColor: '#173865', borderColor: '#173865' }}>
                            เพิ่มข้อมูล{" "}
                        </button>

                    </Col>

                    <Modal
                        isOpen={this.state.modal_standard}
                        toggle={this.tog_standard}
                    >

                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                ข้อมูลกิจกรรม
                            </h5>
                            <button
                                type="button"
                                onClick={() =>
                                    this.setState({ modal_standard: false })
                                }
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <Nav tabs className="nav-tabs-custom">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: this.state.customActiveTab === "1",
                                        })}
                                        onClick={() => {
                                            this.toggleCustom("1")
                                        }}
                                    >
                                        <span className="d-none d-sm-block">ภาษาไทย</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: this.state.customActiveTab === "2",
                                        })}
                                        onClick={() => {
                                            this.toggleCustom("2")
                                        }}
                                    >
                                        <span className="d-none d-sm-block">English</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: this.state.customActiveTab === "3",
                                        })}
                                        onClick={() => {
                                            this.toggleCustom("3")
                                        }}
                                    >
                                        <span className="d-none d-sm-block">简体中文</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                        <TabContent activeTab={this.state.customActiveTab}>
                            <TabPane tabId="1" className="p-3">

                                <FormGroup>
                                    <Label for="Address">ชื่อกิจกรรม</Label><Label className="required"> *</Label>
                                    <Input required type="text" className="form-control" id="Address" placeholder="กรุณากรอกชื่อกิจกรรม" value={this.state.ActivityName} onChange={(e) => { this.setState({ ActivityName: e.target.value }, () => { }) }} />
                                </FormGroup>
                                <Label for="Address">ประเภทกิจกรรม </Label><Label className="required"> *</Label>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <select className="form-control" onChange={(e) => {
                                            console.log(e.target.value)
                                            this.setState({
                                                idSelected: e.target.value,
                                                AttractionActivityTypeID: e.target.value
                                            }, () => {
                                                console.log(e.target.value)
                                                this.subActivity(this.state.idSelected)
                                            })
                                        }} >
                                            {this.state.Attraction.map((e, i) => {
                                                return (
                                                    <option key={i} value={e.ID}>{e.Details.th.Name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <Label for="Address">ประเภทกิจกรรมรอง </Label><Label className="required"> *</Label>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <select className="form-control" value={'กรุณาเลือก'} onChange={(e) => {
                                            console.log(e.target.value)
                                            this.setState({
                                                AttractionActivitySubTypeID: e.target.value
                                            }, () => {
                                                console.log(this.state)
                                                this.subActivity(this.state.idSelected)
                                            })
                                        }}>
                                            {this.state.subActivity.map((e, i) => {
                                                return (
                                                    <option key={i} value={e.ID}>{e.Details.th.Name}</option>
                                                )
                                            })}

                                        </select>
                                    </div>
                                </div>
                                <Label>ลักษณะเด่น</Label>
                                <Input
                                    type="textarea"
                                    id="textarea"
                                    onChange={(e) => { this.setState({ ActivityDetail: e.target.value }) }}
                                    // maxLength="225"
                                    rows="3"
                                    placeholder=""

                                />
                                {this.state.textareabadge ? (
                                    <span className="badgecount badge badge-success">
                                        {" "}
                                        {this.state.textcount} / 225{" "}
                                    </span>
                                ) : null}
                            </TabPane>
                            <TabPane tabId="2" className="p-3">

                            </TabPane>
                            <TabPane tabId="3" className="p-3">

                            </TabPane>

                        </TabContent>


                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={this.tog_standard}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                                onClick={() => { this.setState({ reload: true }, () => { this.submitActivity() }) }}
                            >
                                Save changes
                            </button>
                        </div>

                    </Modal>
                </Row>

                {this.state.reload === false &&

                    <DataGrid pageSize={5}

                        data={[
                            { field: 'id', headerName: 'ลำดับ', width: 300 },
                            { field: 'ActivityName', headerName: 'ชื่อกิจกรรม', width: 300 },
                            { field: 'AttractionActivityTypeID', headerName: 'หมวดหมู่', width: 300 },
                            { field: 'AttractionActivitySubTypeID', headerName: 'หมวดหมู่รอง', width: 300 },
                            {
                                field: 'Operation', headerName: 'จัดการ', width: 300,
                                renderCell: (params) => (
                                    <strong>
                                        <Link

                                        >

                                            {/* <i className="mdi mdi-chevron-right ml-1"></i> */}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{ marginLeft: 16 }}
                                            // onClick={() => { this.tableHandle(params.id) }}
                                            >
                                                ลบ
                                            </Button>
                                        </Link>

                                    </strong>
                                ),
                            }]}

                        dataSubmit={this.state.dataSubmit}

                        checkboxSelection />
                }




            </div>
        )
    }
}
