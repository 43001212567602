import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"


const animatedComponents = makeAnimated()



export default class room extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',
            Types: this.props.Types,
            AccommodationInfo: this.props.AccommodationInfo,
            HighSeasonStartDate: this.props.AccommodationInfo.HighSeasonStartDate,
            HighSeasonEndDate: this.props.AccommodationInfo.HighSeasonEndDate,
            LowSeasonStartDate: this.props.AccommodationInfo.LowSeasonStartDate,
            LowSeasonEndDate: this.props.AccommodationInfo.LowSeasonEndDate,
            MeetingRoomSize: this.props.Details.MeetingRoomSize,
            Nature: false,
            Manmade: false,
            Sport: false,
            Event: false,
            selectedGroup: null,
        }
    }
    handleSelectGroup = selectedGroup => {
        this.setState({ selectedGroup })
    }
    selectedHandle(data, type) {
        console.log(data)

        if (type === 'RoomAmount') {
            this.props.callback(data, 'RoomAmount')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'MeetingRoomAmount') {
            this.props.callback(data, 'MeetingRoomAmount')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'MeetingRoomSize') {
            this.props.callback(data, 'MeetingRoomSize')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'HighSeasonStartDate') {
            this.props.callback(data, 'HighSeasonStartDate')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'HighSeasonEndDate') {
            this.props.callback(data, 'HighSeasonEndDate')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'LowSeasonStartDate') {
            this.props.callback(data, 'LowSeasonStartDate')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'LowSeasonEndDate') {
            this.props.callback(data, 'LowSeasonEndDate')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }

    }

    render() {
        const { selectedGroup } = this.state

        return (
            <div>
                <p className={'text-24'}>ข้อมูลห้องพัก</p>

                <Row className="font-style-16 cl-black">
                    {this.props.lang === 'th' ?
                        <Col lg={6}>
                            <FormGroup>
                                <Label for="agencyName">จำนวนห้องพัก </Label>
                                <Input required type="number" className="form-control" id="Name" placeholder="กรุณากรอกจำนวนห้องพัก" value={this.props.AccommodationInfo.RoomAmount} onChange={(e) => { this.props.callback(e.target.value, 'RoomAmount') }} />
                            </FormGroup>
                        </Col>
                        :
                        <Col lg={6}>
                            <Label className="font-style-16 cl-black">จำนวนห้องพัก</Label>
                            <p className="font-style-16 cl-darkblue">
                                (ให้จัดการข้อมูลที่ภาษาไทย)
                            </p>
                        </Col>
                    }
                    {this.props.lang === 'th' ?
                        <Col lg={6}>
                            <FormGroup>
                                <Label for="agencyName">จำนวนห้องประชุม </Label>
                                <Input required type="number" className="form-control" id="Name" placeholder="กรุณากรอกจำนวนห้องประชุม" value={this.props.AccommodationInfo.MeetingRoomAmount} onChange={(e) => { this.props.callback(e.target.value, 'MeetingRoomAmount') }} />
                            </FormGroup>
                        </Col>
                        :
                        <Col lg={6}>
                            <Label className="font-style-16 cl-black">จำนวนห้องประชุม</Label>
                            <p className="font-style-16 cl-darkblue">
                                (ให้จัดการข้อมูลที่ภาษาไทย)
                            </p>
                        </Col>
                    }
                </Row>
                <Row className="font-style-16 cl-black">
                    <Col lg="12">
                        <FormGroup>
                            <Label for="agencyName">ขนาดห้องประชุม</Label>
                            <Input required type="text" className="form-control" id="MeetingRoomSize" placeholder="กรุณากรอกขนาดห้องประชุม" value={this.props.Details.MeetingRoomSize} onChange={(e) => { this.props.callback(e.target.value, 'MeetingRoomSize') }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={'mt-3 font-style-16 cl-black'}>
                    {this.props.lang === 'th' ?
                        <Col lg={6}>
                            <div className="form-group">
                                <label>วันที่เริ่ม High Season</label>
                                <input type="date" value={this.state.HighSeasonStartDate} onChange={(e) => {
                                    this.setState({
                                        HighSeasonStartDate: e.target.value
                                    }, () => {
                                        this.selectedHandle(e.target.value, 'HighSeasonStartDate')
                                    })
                                }} placeholder="ปรับปรุงตั้งแต่วันที่" className="form-control" ></input>
                            </div>
                        </Col>
                        :
                        <Col lg={6}>
                            <Label className="font-style-16 cl-black">วันที่เริ่ม High Season</Label>
                            <p className="font-style-16 cl-darkblue">
                                (ให้จัดการข้อมูลที่ภาษาไทย)
                            </p>
                        </Col>
                    }
                    {this.props.lang === 'th' ?
                        <Col lg={6}>
                            <div className="form-group">
                                <label>วันที่สิ้นสุด High Season</label>
                                <input type="date" value={this.state.HighSeasonEndDate} onChange={(e) => {
                                    this.setState({
                                        HighSeasonEndDate: e.target.value
                                    }, () => {
                                        this.selectedHandle(e.target.value, 'HighSeasonEndDate')
                                    })
                                }} placeholder="ปรับปรุงตั้งแต่วันที่" className="form-control" ></input>
                            </div>
                        </Col>
                        :
                        <Col lg={6}>
                            <Label className="font-style-16 cl-black">วันที่สิ้นสุด High Season</Label>
                            <p className="font-style-16 cl-darkblue">
                                (ให้จัดการข้อมูลที่ภาษาไทย)
                            </p>
                        </Col>
                    }
                </Row>
                <Row className={'mt-3 font-style-16 cl-black'}>
                    {this.props.lang === 'th' ?
                        <Col lg={6}>
                            <div className="form-group">
                                <label>วันที่เริ่ม Low Season</label>
                                <input type="date" value={this.state.LowSeasonStartDate} onChange={(e) => {
                                    this.setState({
                                        LowSeasonStartDate: e.target.value
                                    }, () => {
                                        this.selectedHandle(e.target.value, 'LowSeasonStartDate')
                                    })
                                }} placeholder="ปรับปรุงตั้งแต่วันที่" className="form-control" ></input>
                            </div>
                        </Col>
                        :
                        <Col lg={6}>
                            <Label className="font-style-16 cl-black">วันที่เริ่ม Low Season</Label>
                            <p className="font-style-16 cl-darkblue">
                                (ให้จัดการข้อมูลที่ภาษาไทย)
                            </p>
                        </Col>
                    }
                    {this.props.lang === 'th' ?
                        <Col lg={6}>
                            <div className="form-group">
                                <label>วันที่สิ้นสุด Low Season</label>
                                <input type="date" value={this.state.LowSeasonEndDate} onChange={(e) => {
                                    this.setState({
                                        LowSeasonEndDate: e.target.value
                                    }, () => {
                                        this.selectedHandle(e.target.value, 'LowSeasonEndDate')
                                    })
                                }} placeholder="ปรับปรุงตั้งแต่วันที่" className="form-control" ></input>
                            </div>
                        </Col>
                        :
                        <Col lg={6}>
                            <Label className="font-style-16 cl-black">วันที่สิ้นสุด Low Season</Label>
                            <p className="font-style-16 cl-darkblue">
                                (ให้จัดการข้อมูลที่ภาษาไทย)
                            </p>
                        </Col>
                    }
                </Row>
                {/* 
                <Row className={'mt-3'}>
                    <Col lg="12">
                        <FormGroup className="select2-container">
                            <Label>หมวดหมู่ *</Label>
                            <Select
                                value={selectedGroup}
                                onChange={this.handleSelectGroup}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                            />
                        </FormGroup>
                    </Col>
                </Row> */}
                {/* <Row className={'mt-3'}>
                    <Col lg="12">
                        <FormGroup className="select2-container">
                            <Label>จังหวัด</Label>
                            <Select
                                value={selectedGroup}
                                onChange={this.handleSelectGroup}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                            />
                        </FormGroup>
                    </Col>
                </Row> */}


                {/* <Row className={'mt-3'}>
                    <Col lg="12">
                        <Label>รายละเอียด  *</Label>

                        <Input
                            type="textarea"
                            id="textarea"
                            onChange={this.textareachange}
                            maxLength="225"
                            rows="3"
                            placeholder=""
                        />
                        {this.state.textareabadge ? (
                            <span className="badgecount badge badge-success">
                                {" "}
                                {this.state.textcount} / 225{" "}
                            </span>
                        ) : null}
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col lg="12">
                        <FormGroup className="select2-container">
                            <Label>ประเภททะเบียน *</Label>
                            <Select
                                value={selectedGroup}
                                onChange={this.handleSelectGroup}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={2}>
                        <Label for="Name">การดำเนินการ</Label>
                    </Col>
                    <Col lg={4}>
                        <p for="Name">เป็นสมาชิกสมาคมโรงแรม</p>
                        <p for="Name">จดทะเบียนตาม พรบ. โรงแรม</p>
                    </Col>
                </Row> */}
                {/* <Row>
                    <Col lg="12">
                        <FormGroup>
                            <Label for="agencyName">เลขที่ใบอนุญาต</Label>
                            <Input required type="text" className="form-control" id="Name" placeholder="กรุณากรอกชื่อ" value={this.props.data.Name} onChange={(e) => { this.props.callback(e.target.value, 'Name') }} />
                        </FormGroup>
                    </Col>
                </Row> */}
                {/* <Row className={'mt-3'}>

                    <Col lg={6}>
                        <div className="form-group">
                            <label>วันที่อนุญาต</label>
                            <input type="date" disabled={true} value={this.state.selected_date_start} onChange={(e) => {
                                this.setState({
                                    selected_date_start: e.target.value
                                })
                            }} placeholder="ปรับปรุงตั้งแต่วันที่" className="form-control" ></input>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="form-group">
                            <label>วันที่หมดอายุ</label>
                            <input type="date" disabled={true} value={this.state.selected_date_end} onChange={(e) => {
                                this.setState({
                                    selected_date_end: e.target.value
                                })
                            }} placeholder="ถึงวันที่" className="form-control" ></input>
                        </div>
                    </Col>
                </Row> */}



            </div>
        )
    }
}
