import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"

export default class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <div>

                <Row >
                    <Col lg="12">
                        <Label className={'text-24'}>ข้อมูลติดต่อ และ Social Network</Label>
                    </Col>
                    <Col lg="6" className={'mt-3 font-style-16 cl-black'}>
                        {this.props.lang === 'th' ?
                            <FormGroup>
                                <Label for="Telephone">เบอร์โทรติดต่อ</Label>
                                <Input style={{ fontSize: 16 }} type="text" className="form-control" id="Telephone" placeholder="กรุณากรอกเบอร์โทรติดต่อ" value={this.props.data.Telephone} onChange={(e) => { this.props.callback(e.target.value, 'Telephone') }} />
                            </FormGroup>
                            :
                            <FormGroup>
                                <Label for="Telephone">เบอร์โทรติดต่อ</Label>
                                <p className="font-style-16" style={{ color: '#173865' }}>
                                    (ให้จัดการข้อมูลที่ภาษาไทย)
                                </p>
                            </FormGroup>
                        }
                    </Col>
                    <Col lg="6" className={'mt-3 font-style-16 cl-black'}>
                        {this.props.lang === 'th' ?
                            <FormGroup>
                                <Label for="Mobile">เบอร์โทรศัพท์มือถือ</Label>
                                <Input style={{ fontSize: 16 }} type="text" className="form-control" id="Mobile" placeholder="กรุณากรอกเบอร์โทรศัพท์มือถือ" value={this.props.data.Mobile} onChange={(e) => { this.props.callback(e.target.value, 'Mobile') }} />
                            </FormGroup>
                            :
                            <FormGroup>
                                <Label for="Telephone">เบอร์โทรศัพท์มือถือ</Label>
                                <p style={{ color: '#173865' }}>
                                    (ให้จัดการข้อมูลที่ภาษาไทย)
                                </p>
                            </FormGroup>
                        }
                    </Col>
                    <Col lg="6" className="font-style-16 cl-black">
                        {this.props.lang === 'th' ?
                            <FormGroup>
                                <Label for="Email">Email</Label>
                                <Input style={{ fontSize: 16 }} type="text" className="form-control" id="Email" placeholder="กรุณากรอกอีเมล" value={this.props.data.Email} onChange={(e) => { this.props.callback(e.target.value, 'Email') }} />
                            </FormGroup>
                            :
                            <FormGroup>
                                <Label for="Email">Email</Label>
                                <p style={{ color: '#173865' }}>
                                    (ให้จัดการข้อมูลที่ภาษาไทย)
                                </p>
                            </FormGroup>
                        }
                    </Col>
                    <Col lg="6" className="font-style-16 cl-black">
                        {this.props.lang === 'th' ?
                            <FormGroup>
                                <Label for="Website">Website URL</Label>
                                <Input style={{ fontSize: 16 }} type="text" className="form-control" id="Website" placeholder="กรุณากรอกเว็บไซต์ URL ตัวอย่าง https://www.test.com/" value={this.props.data.Website} onChange={(e) => { this.props.callback(e.target.value, 'Website') }} />
                            </FormGroup>
                            :
                            <FormGroup>
                                <Label for="Website">Website URL</Label>
                                <p style={{ color: '#173865' }}>
                                    (ให้จัดการข้อมูลที่ภาษาไทย)
                                </p>
                            </FormGroup>
                        }
                    </Col>
                    <Col lg="6" className="font-style-16 cl-black">
                        {this.props.lang === 'th' ?
                            <FormGroup>
                                <Label for="ContactName">ชื่อผู้ติดต่อ</Label>
                                <Input style={{ fontSize: 16 }} type="text" className="form-control" id="ContactName" placeholder="กรุณากรอกชื่อผู้ติดต่อ" value={this.props.data.ContactName} onChange={(e) => { this.props.callback(e.target.value, 'ContactName') }} />
                            </FormGroup>
                            :
                            <FormGroup>
                                <Label for="ContactName">ชื่อผู้ติดต่อ</Label>
                                <p style={{ color: '#173865' }}>
                                    (ให้จัดการข้อมูลที่ภาษาไทย)
                                </p>
                            </FormGroup>
                        }
                    </Col>
                    <Col lg="6" className="font-style-16 cl-black">
                        {this.props.lang === 'th' ?
                            <FormGroup>
                                <Label for="ContactPosition">ตำแหน่งผู้ติดต่อ</Label>
                                <Input style={{ fontSize: 16 }} type="text" className="form-control" id="ContactPosition" placeholder="กรุณากรอกตำแหน่งผู้ติดต่อ" value={this.props.data.ContactPosition} onChange={(e) => { this.props.callback(e.target.value, 'ContactPosition') }} />
                            </FormGroup>
                            :
                            <FormGroup>
                                <Label for="ContactPosition">ตำแหน่งผู้ติดต่อ</Label>
                                <p style={{ color: '#173865' }}>
                                    (ให้จัดการข้อมูลที่ภาษาไทย)
                                </p>
                            </FormGroup>
                        }
                    </Col>
                    <Col lg="6" className="font-style-16 cl-black">
                        {this.props.lang === 'th' ?
                            <FormGroup>
                                <Label for="FacebookUrl">Facebook Url</Label>
                                <Input style={{ fontSize: 16 }} type="text" className="form-control" id="FacebookUrl" placeholder="กรุณากรอก Facebook Url ตัวอย่าง https://www.facebook.com/test/" value={this.props.data.FacebookUrl} onChange={(e) => { this.props.callback(e.target.value, 'FacebookUrl') }} />
                            </FormGroup>
                            :
                            <FormGroup>
                                <Label for="FacebookUrl">Facebook Url</Label>
                                <p style={{ color: '#173865' }}>
                                    (ให้จัดการข้อมูลที่ภาษาไทย)
                                </p>
                            </FormGroup>
                        }
                    </Col>
                    <Col lg="6" className="font-style-16 cl-black">
                        {this.props.lang === 'th' ?
                            <FormGroup>
                                <Label for="LineID">Line ID</Label>
                                <Input style={{ fontSize: 16 }} type="text" className="form-control" id="LineID" placeholder="กรุณากรอก LineID" value={this.props.data.LineID} onChange={(e) => { this.props.callback(e.target.value, 'LineID') }} />
                            </FormGroup>
                            :
                            <FormGroup>
                                <Label for="LineID">Line ID</Label>
                                <p style={{ color: '#173865' }}>
                                    (ให้จัดการข้อมูลที่ภาษาไทย)
                                </p>
                            </FormGroup>
                        }
                    </Col>
                    <Col lg="6" className="font-style-16 cl-black">
                        {this.props.lang === 'th' ?
                            <FormGroup>
                                <Label for="Twitter">Twitter</Label>
                                <Input style={{ fontSize: 16 }} type="text" className="form-control" id="Twitter" placeholder="กรุณากรอก Twitter ตัวอย่าง https://twitter.com/test" value={this.props.data.Twitter} onChange={(e) => { this.props.callback(e.target.value, 'Twitter') }} />
                            </FormGroup>
                            :
                            <FormGroup>
                                <Label for="Twitter">Twitter</Label>
                                <p style={{ color: '#173865' }}>
                                    (ให้จัดการข้อมูลที่ภาษาไทย)
                                </p>
                            </FormGroup>
                        }
                    </Col>
                </Row>
            </div>
        )
    }
}
