import React, { Component } from "react"
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import logoplus from "../../assets/images/ttd/Vector.svg"
import { api_endpoint, openapi_endpoint } from '../../global-config'
import DataGrid from '../../components/Table/Table'
import Edit from '../../assets/images/ttd/Edit.svg'

const typeOption = [
  { label: "ทั้งหมด", value: null },
  { label: "ใช้งาน", value: "Y" },
  { label: "ระงับการใช้งาน", value: "N" }
]
class OpenApi extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: "",
      lang: 'th',
      dataTable: [],
      isLoading: true,
      sortModel: [{
        field: 'id',
        sort: 'desc'
      }],
      ApiLevel: { label: "ทั้งหมด", value: null },
    }
  }

  async searchLocation() {
    let sortBy = []
    console.log(this.state.sortModel[0])
    const sortFields = {
      'id': '_id',
      'ContactName': "ContactFirstName" + " " + "ContactLastName",
      'ContactOrganizationName': "ContactOrganizationName",
      'ContactEmail': "ContactEmail",
      'ApiLevel': "ApiLevel"
    }
    if (this.state.sortModel[0].field !== undefined) {
      sortBy = [
        {
          field: sortFields[this.state.sortModel[0].field],
          sort: this.state.sortModel[0].sort === 'asc' ? 1 : -1
        }]
    }
    const response = await fetch(
      openapi_endpoint + `/users`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      }
    );
    var _result = await response.json();
    console.log(_result)
    if (_result !== undefined) {
      let arr = []
      _result.map((e) => {
        arr.push({
          id: e._id,
          ContactName: e.ContactFirstName === null ? "" : e.ContactFirstName + " " + e.ContactLastName,
          ContactOrganizationName: e.ContactOrganizationName,
          ContactEmail: e.ContactEmail,
          ApiLevel: e.ApiLevel,
        })

      })
      this.setState({
        isSerching: true,
        countData: _result.count,
        dataTable: arr,
        isLoading: false
      })
      console.log(arr)
    }
    else {
      console.log('error : searchLocation')
    }
  }

  handleSort = (value) => {
    let arr = this.state.sortModel
    console.log(value.sortModel[0])
    if (value.sortModel[0] === undefined) {
      arr[0].sort = 'asc'
      this.setState({
        sortModel: arr
      }, () => {
        this.searchLocation()
      })
    }
    else {
      arr[0].field = value.sortModel[0].field
      arr[0].sort = value.sortModel[0].sort
      this.setState({
        sortModel: arr
      })
      this.searchLocation()
    }
  }

  dataGridPagging = (value) => {
    if (this.state.isSerching === true) {
      if ((value.page * 10) === this.state.limit) {
        this.setState({
          isLoading: true,
          limit: this.state.limit + 10
        }, () => {
          this.searchLocation()
        })
      }
      else {
        return
      }
    }
    else {
      if ((value.page * 10) === this.state.limit) {
        this.setState({
          isLoading: true,
          limit: this.state.limit + 10
        }, () => {
          this.searchLocation()
        })
      }
      else {
        return
      }
    }
  }

  buttonHandle(type) {
    if (type === 'Search') {
      this.searchLocation()
    }
    if (type === 'Clear') {
      this.setState({
        search: "",
        ApiLevel: { label: "ทั้งหมด", value: null },
      }, () => {
        this.searchLocation()
      })
    }
  }

  inputHandle(value, type) {
    if (type === 'Search') {
      this.setState({
        search: value
      })
    }
    if (type === 'ApiLevel') {
      this.setState({
        ApiLevel: value
      }, () => {
        console.log(this.state.ApiLevel)
      })
    }
  }

  componentDidMount() {

  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="หน้าแรก" breadcrumbItem="รายการ OpenApi" link="/Openapi" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <Row>
                      <Col>
                        <h1 style={{ color: '#173865' }}>รายการ OpenApi</h1>
                      </Col>
                      <Col style={{ textAlign: 'end', alignSelf: 'center' }}>
                        <Link
                          style={{ backgroundColor: '#173865', borderColor: '#173865' }}
                          to="/OpenapiDetail"
                          className="btn btn-primary waves-effect waves-light w-lg "
                        >
                          <img src={logoplus} style={{ padding: '0px 10px 3px 0px', width: 30 }} />
                          {/* <i className="mdi mdi-chevron-right ml-1"></i> */}
                          <span className="font-style-16nb">เพิ่มข้อมูล</span>{" "}
                        </Link>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody className="pb-auto">
                    <Row>
                      <Col>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control rounded chat-input"
                            style={{ fontSize: 16 }}
                            placeholder="ค้นหาสิ่งที่คุณต้องการ..."
                            value={this.state.search}
                            onChange={(e) => { this.inputHandle(e.target.value, 'Search') }}
                          />
                        </div>
                      </Col>
                      <div className="col-auto">
                        <Button
                          style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                          type="submit"
                          onClick={() => { this.buttonHandle('Clear') }}
                          className="chat-send w-md waves-effect waves-light"
                        >
                          <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ล้างค่าค้นหา</span>{" "}
                        </Button>
                      </div>
                      <div className="col-auto">
                        <Button
                          style={{ backgroundColor: '#F47806', borderColor: '#F47806' }}
                          type="submit"
                          onClick={() => { this.buttonHandle('Search') }}
                          className="chat-send w-md waves-effect waves-light"
                        >
                          <span className="d-none d-sm-inline-block font-style-16nb">ค้นหา</span>{" "}
                        </Button>
                      </div>
                    </Row>
                    <Row className={'mt-3'}>
                      <Col lg={6}>
                        <FormGroup className="select2-container">
                          <label className="font-style-16 cl-black">สถานะ</label>
                          <Select
                            value={this.state.ApiLevel}
                            onChange={(e) => { this.inputHandle(e, 'ApiLevel') }}
                            options={typeOption}
                            classNamePrefix="select2-selection" className="font-style-16b4"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <label className="font-style-16 cl-black">คู่มือการใช้งาน OpenApi</label>
                        <div>
                          <Button
                            style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                            className="chat-send w-md waves-effect waves-light"
                          >
                            <span className="d-none d-sm-inline-block font-style-16nb"><a href="/manual/ttd-open-api.pdf" style={{ color: '#808080' }}>กดเพื่อดูรายละเอียดการใช้งาน</a></span>{" "}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <DataGrid pageSize={10}
                      header={
                        [
                          { field: 'id', headerName: 'ลำดับ', width: 120 },
                          { field: 'ContactName', headerName: 'ชื่อ-นามสกุล', flex: 1 },
                          { field: 'ContactOrganizationName', headerName: 'บริษัท/หน่วยงาน', flex: 1 },
                          { field: 'ContactEmail', headerName: 'อีเมล์', flex: 1 },
                          {
                            field: 'ApiLevel', headerName: 'สถานะ', flex: 1,
                            renderCell: (params) => (
                              <div>
                                {params.value === 'Y' ?
                                  <span>
                                    ใช้งาน
                                  </span>
                                  :
                                  <span>
                                    ระงับการใช้งาน
                                  </span>
                                }
                              </div>
                            )
                          },
                          {
                            field: 'Action', headerName: 'จัดการ', width: 120,
                            renderCell: (params) => (
                              <strong>
                                <Link to={"/OpenapiDetail?id=" + params.id}>
                                  <img src={Edit} style={{ paddingRight: 10 }} />
                                  {/* <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
            >
              Edit
      </Button> */}
                                </Link>
                              </strong>
                            ),
                          }]
                      }
                      sortModel={this.state.sortModel}
                      data={this.state.dataTable}
                      rowsCount={this.state.countData}
                      pagging={this.dataGridPagging}
                      loading={this.state.isLoading}
                      sort={this.handleSort}
                    /></CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    )
  }
}

export default OpenApi
