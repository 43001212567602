import React, { Component } from "react"
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import logoplus from "../../assets/images/ttd/Vector.svg"
import logodown from "../../assets/images/ttd/donw.png"
import { api_endpoint } from '../../global-config'
import DataGrid from '../../components/Table/Table'
import Edit from '../../assets/images/ttd/Edit.svg'
const moment = require('moment'); // require
import localization from 'moment/locale/th';
moment.updateLocale('th', localization);

class Commentlist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedGroup: null,
      skip: 0,
      take: 10,
      isLoading: true,
      sortModel: [{
        field: 'id',
        sort: 'desc'
      }],
      lang: 'th',
      dataTable: [],
      rowsPerPageOptions: [10, 15, 30],
      pageSize: 10,
      userData: JSON.parse(localStorage.getItem('authUser'))
    }

  }
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }

  setPageSize = (value) => {
    this.setState({
      isLoading: true,
      skip: value,
      take: value,
      pageSize: value
    }, () => {
      this.get_comment()
    })
  }

  handleSort = (value) => {
    let arr = this.state.sortModel
    // console.log(value)
    if (value.sortModel[0] === undefined) {
      arr[0].sort = 'desc'
      this.setState({
        sortModel: arr
      }, () => {
        this.get_comment()
      })
    }
    else {
      arr[0].field = value.sortModel[0].field
      arr[0].sort = value.sortModel[0].sort
      this.setState({
        sortModel: arr
      }, () => {
        this.get_comment()
      })
    }

  }



  async get_comment() {
    let sortBy = []
    console.log(this.state.sortModel[0])
    const sortFields = {
      'id': 'ID',
      // 'Name': "Main.Details." + this.state.lang + ".Name",
      // 'Province': "Main.Info.Province." + this.state.lang + ".Name",
      // 'ViewCount': "View.ViewCount",
      // 'IsEnabled': "IsEnabled"
    }
    if (this.state.sortModel[0].field !== undefined) {
      sortBy = [
        {
          field: sortFields[this.state.sortModel[0].field],
          sort: this.state.sortModel[0].sort === 'asc' ? 1 : -1
        }]
    }
    const response = await fetch(
      api_endpoint + `/maininfo/review/read`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: 'bearer ' + this.state.userData.token
        },
        body: JSON.stringify({
          skip: this.state.skip,
          take: this.state.take,
          SortBy: sortBy,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    // console.log(_result)
    if (_result.results !== undefined) {
      let arr = []
      _result.results.map((e) => {
        arr.push({
          id: e.ID,
          UserId: e.user.Username,
          Name: e.main_info.Name[this.state.lang],
          Comment: e.comment,
          Star: e.star,
          Created_At: e.created_at,
          Status: e.IsEnabled,
          Link: e.link
        })

      })
      this.setState({
        countData: _result.count,
        dataTable: arr,
        isLoading: false
      })
    }
    else {
      // console.log('error : get_comment')
    }
  }

  async approveComment(data) {
    if (confirm('คุณต้องการอนุมัติการแสดงความคิดเห็น\nID: ' + data.id + '\nเนื้อหา: ' + data.Comment)) {
      const response = await fetch(
        api_endpoint + `/maininfo/review/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: 'bearer ' + this.state.userData.token
          },
          body: JSON.stringify({
            ID: data.id,
            star: data.Star,
            comment: data.Comment,
            IsEnabled: "Y"

          }), // body data type must match "Content-Type" header
        }
      );
      var _result = await response.json();
      if (_result.success === true) {
        alert('อนุมัติการแสดงความคิดเห็นสำเร็จ')
      }
      else {
        // console.log('error : approveComment')
      }
    }
    else {
      return
    }
  }
  dataGridPagging = (value) => {
    if ((value.page * this.state.pageSize) === this.state.take) {
      this.setState({
        isLoading: true,
        take: this.state.take + this.state.pageSize
      }, () => {
        console.log(this.state.take)
        this.get_comment()
      })
    }
  }
  componentDidMount() {
    this.get_comment()
  }

  render() {
    const { selectedGroup } = this.state
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="หน้าแรก" breadcrumbItem="รายการแสดงความคิดเห็น" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <Row>
                      <Col>
                        <h1 style={{ color: '#173865' }}>รายการแสดงความคิดเห็น</h1>
                      </Col>

                      {/* <Col style={{ textAlign: 'end', alignSelf: 'center' }}>
                        <Link
                          style={{ backgroundColor: '#173865', borderColor: '#173865' }}
                          to="/Spa/SpaDetail"
                          className="btn btn-primary waves-effect waves-light w-lg "
                        >
                          <img src={logoplus} style={{ padding: '0px 10px 3px 0px', width: 30 }} />
                          เพิ่มสปา{" "}
                        </Link>
                      </Col> */}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <DataGrid
                      header={
                        [
                          { field: 'id', headerName: 'ลำดับ', width: 120 },
                          { field: 'UserId', headerName: 'ผู้ใช้งาน', width: 180 },
                          { field: 'Name', headerName: 'ชื่อสถานที่', flex: 2 },
                          { field: 'Comment', headerName: 'ความคิดเห็น', flex: 2 },
                          { field: 'Star', headerName: 'ดาว', width: 100 },
                          {
                            field: 'Created_At', headerName: 'เวลา', flex: 2,
                            renderCell: (params) => (
                              <div>
                                {moment(params.value).format('llll')}
                              </div>
                            )
                          },
                          {
                            field: 'Status', headerName: 'สถานะ', flex: 1,
                            renderCell: (params) => (
                              <div>
                                {params.value === 'N' ?
                                  <span>
                                    รอการอนุมัติ
                                  </span>
                                  :
                                  <span>
                                    อนุมัติแล้ว
                                  </span>
                                }
                              </div>
                            )
                          },
                          {
                            field: 'Link', headerName: 'Link', flex: 1,
                            renderCell: (params) => (
                              <a href={`${params.value}`} target="_blank" rel="noopener noreferrer">ดูข้อมูล</a>
                            )
                          },
                          {
                            field: 'Action', headerName: 'จัดการ', width: 120,
                            renderCell: (params) => (
                              <strong>
                                <Link
                                  onClick={() => {
                                    this.approveComment(params.row)
                                  }}
                                >
                                  <img src={Edit} style={{ paddingRight: 10 }} />
                                </Link>

                              </strong>
                            ),
                          }]
                      }
                      sortModel={this.state.sortModel}
                      data={this.state.dataTable}
                      rowsCount={this.state.countData}
                      pageSize={this.state.pageSize}
                      pagging={this.dataGridPagging}
                      rowsPerPageOptions={this.state.rowsPerPageOptions}
                      loading={this.state.isLoading}
                      sort={this.handleSort}
                      setPageSize={this.setPageSize}

                    /></CardBody>


                </Card>

              </Col>
            </Row>





          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Commentlist
