

import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'


import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    CardSubtitle,
    Modal,
} from "reactstrap"
import Select from "react-select"
// import DataGrid from '../../../components/Table/Table'
import Flat from '../../../assets/images/ttd/Flat.svg'
import { Link } from "react-router-dom"
import { api_endpoint } from '../../../global-config'
// const [show, setShow] = useState(false);


export default class ManageteamLangTh extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: "th",
            textcount: 0,
            data: false,
            modal_xlarge: false,
            modal_standard: false,
            Maininfo_types: this.props.Maininfo_types,
            //------------------------------------------------------//
            userData: JSON.parse(localStorage.getItem('authUser')),
            provinces: this.props.provinces,
            districts: this.props.districts,
            subdistricts: this.props.subdistricts,
            provinceSelected: this.props.provinceSelected,
            districtSelected: this.props.districtSelected,
            subDistrictSelected: this.props.subDistrictSelected,
            teamName: "",
            teamDetail: "",
            teamTelephone: "",
            ManageType: "A"
        }
        this.tog_standard = this.tog_standard.bind(this)
        this.textareachange = this.textareachange.bind(this)
        this.tog_xlarge = this.tog_xlarge.bind(this)

    }
    tog_standard() {
        this.setState(prevState => ({
            modal_standard: !prevState.modal_standard,
        }))
        this.removeBodyCss()
    }


    tog_xlarge() {
        this.setState(prevState => ({
            modal_xlarge: !prevState.modal_xlarge,
        }))
        this.removeBodyCss()
    }

    removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    textareachange(event) {
        const count = event.target.value.length
        if (count > 0) {
            this.setState({ textareabadge: true })
        } else {
            this.setState({ textareabadge: false })
        }
        this.setState({ textcount: event.target.value.length })
    }

    async checkSubmit() {

        if (this.state.teamName === "") {
            return alert('กรุณากรอกชื่อทีม')
        }
        if (this.state.teamDetail === "") {
            return alert('กรุณากรอกรายระเอียดทีม')
        }
        if (this.state.teamTelephone === "") {
            return alert('กรุณากรอกเบอร์โทร')
        }
        if (this.state.provinceSelected === null) {
            return alert('กรุณาเลือกจังหวัด')
        }

        if (this.state.ManageType === "A" && this.state.provinceSelected.value != '01' && this.state.subDistrictSelected === null) {
            return alert('กรุณาเลือกตำบล')
        }
        if (this.state.ManageType === "A" && this.state.provinceSelected.value != '01' && this.state.districtSelected === null) {
            return alert('กรุณาเลือกอำเภอ')
        }
        let arr = this.state.Maininfo_types
        let filter = []
        const asyncRes = await Promise.all(arr.map(async (e) => {
            if (e.isCheck === true) {
                return filter.push({ MainTypeID: e.Code })
            }
            else {
                return
            }
        })
        )

        let SubDistrictCode = null
        if (this.state.ManageType === "A" && this.state.provinceSelected.value != '01') {
          SubDistrictCode = this.state.subDistrictSelected.value
        }
        let DistrictCode = null
        if (this.state.ManageType === "A" && this.state.provinceSelected.value != '01') {
          DistrictCode = this.state.districtSelected.value
        }
        let obj = {
            ManageType: this.state.ManageType,
            ManageProvinceCode: this.state.provinceSelected.value,
            SubDistrictCode: SubDistrictCode,
            DistrictCode: DistrictCode,
            Telephone: this.state.teamTelephone,
            Details: {
                [this.state.Lang]: {
                    Name: this.state.teamName,
                    Description: this.state.teamDetail
                }
            },
            MainTypes: filter
        }

        const response = await fetch(
            api_endpoint + `/editorteam/create`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + this.state.userData.token
                },
                body: JSON.stringify(
                    obj
                ), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        if (_result.status === 'ok') {
            this.props.goBack()
            alert('เพิ่มทีมสำเร็จ')
        }
        else {
            alert('error : editorteam/create ')
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            Maininfo_types: nextProps.Maininfo_types,
            districtSelected: nextProps.districtSelected,
            districts: nextProps.districts,
            provinceSelected: nextProps.provinceSelected,
            provinces: nextProps.provinces,
            subDistrictSelected: nextProps.subDistrictSelected,
            subdistricts: nextProps.subdistricts,
        }, () => {
            console.log(this.state.subDistrictSelected)
        })
    }

    render() {

        return (
            <div>
                <div>
                    <Card>
                        <CardBody>
                            <p className={'text-24'}>ข้อมูลทั่วไป</p>
                            <Row className={'mt-3'}>
                                <Col lg="12">
                                    <FormGroup>
                                        <Label for="Name" className="font-style-16 cl-black">ชื่อ </Label><Label className="required"> *</Label>
                                        <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Name" placeholder="กรุณากรอกชื่อ" value={this.state.teamName} onChange={(e) => { this.setState({ teamName: e.target.value }) }} />
                                    </FormGroup>
                                </Col>
                                <Col lg="12">
                                    <Label className="font-style-16 cl-black">รายละเอียด </Label><Label className="required"> *</Label>
                                    <Input
                                        style={{ fontSize: 16 }}
                                        type="textarea"
                                        id="textarea"
                                        alue={this.state.teamDetail}
                                        onChange={(e) => { this.setState({ teamDetail: e.target.value }) }}
                                        // maxLength="225"
                                        rows="3"
                                        placeholder=""
                                    />
                                    {this.state.textareabadge ? (
                                        <span className="badgecount badge badge-success">
                                            {" "}
                                            {this.state.textcount} / 225{" "}
                                        </span>
                                    ) : null}
                                </Col>
                                <Col className="mt-3" lg="12">
                                    <FormGroup>
                                        <Label className="font-style-16 cl-black" for="Name">เบอร์โทรศัพท์ </Label><Label className="required"> *</Label>
                                        <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Name" placeholder="กรุณากรอกเบอร์โทรศัพท์" value={this.state.teamTelephone} onChange={(e) => { this.setState({ teamTelephone: e.target.value }) }} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <div>
                        <Card>
                            <CardBody>
                                <p className={'text-24'}>สิทธิ์การดำเนินการ</p>
                                <FormGroup>
                                    <FormGroup className="select2-container">
                                        <Label className="font-style-16" for="Name">ภายใต้จังหวัด </Label><Label className="required"> *</Label>
                                        <Select
                                            value={this.state.provinceSelected === null ? { label: 'กรุณาเลือกจังหวัด', value: -1 } : this.state.provinceSelected}
                                            onChange={(e) => this.props.selectedHandle(e, 'Provinces')}
                                            options={this.state.provinces}
                                            classNamePrefix="select2-selection" className="font-style-16"
                                        />
                                    </FormGroup>
                                </FormGroup>
                                <Row className="mt-3">
                                    <Col lg={2}>
                                        <Label className="font-style-16 cl-black" for="Name">ประเภท </Label><Label className="required"> *</Label>

                                    </Col>
                                    <Col lg={10} style={{ zIndex: 0 }}>
                                        <Row>
                                            <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}>
                                                <input
                                                    type="radio"
                                                    id="customRadio1"
                                                    name="customRadio"
                                                    className="custom-control-input"
                                                    checked={this.state.ManageType === "A"}
                                                />
                                                <label style={{ paddingLeft: 10 }}
                                                    className="custom-control-label font-style-16 cl-black"
                                                    htmlFor="customRadio1"
                                                    onClick={() => {
                                                        this.setState({
                                                            ManageType: "A"
                                                        })
                                                    }}
                                                >
                                                    ตามพื้นที่
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}>
                                                <input
                                                    type="radio"
                                                    id="customRadio2"
                                                    name="customRadio"
                                                    className="custom-control-input"
                                                    checked={this.state.ManageType === "F"}
                                                // onChange={() => true}
                                                // checked={this.state.Category}
                                                />
                                                <label style={{ paddingLeft: 10 }}
                                                    className="custom-control-label font-style-16 cl-black"
                                                    htmlFor="customRadio2"
                                                    onClick={() => {
                                                        this.setState({
                                                            ManageType: "F"
                                                        })
                                                    }}

                                                >
                                                    ตามหมวดหมู่
                                                </label>
                                            </div>
                                            {/* <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}>
                                                <input
                                                    type="radio"
                                                    id="customRadio3"
                                                    name="customRadio"
                                                    className="custom-control-input"
                                                // onChange={() => true}
                                                // checked={this.state.data}
                                                />
                                                <label style={{ paddingLeft: 10 }}
                                                    className="custom-control-label"
                                                    htmlFor="customRadio3"
                                                    onClick={() => {
                                                        this.setState({
                                                            Category: false,
                                                            data: true
                                                        })
                                                    }}
                                                >
                                                    ตามข้อมูล
                            </label>
                                            </div> */}
                                        </Row>
                                        <p className="font-style-16 cl-black" style={{ marginLeft: -10 }}><span style={{ color: 'red' }}>หมายเหตุ:</span> เมื่อเลือกประเภท และจังหวัดที่อยู่ภายใต้แล้ว จะไม่สามารถเปลี่ยนแปลงในภายหลัง</p>

                                    </Col>

                                </Row>
                                {(this.state.ManageType === "A" && this.state.provinceSelected && this.state.provinceSelected.value != '01') && (
                                    <Row className="mt-3">
                                        <Col lg={6}>
                                            <FormGroup className="select2-container">
                                                <Label className="font-style-16 cl-black" for="Name">อำเภอ </Label><Label className="required"> *</Label>
                                                <Select
                                                    value={this.state.districtSelected === null ? { label: 'กรุณาเลือกอำเภอ', value: -1 } : this.state.districtSelected}
                                                    onChange={(e) => this.props.selectedHandle(e, 'Districts')}
                                                    options={this.state.districts}
                                                    classNamePrefix="select2-selection" className="font-style-16"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={6}>
                                            <FormGroup className="select2-container">
                                                <Label className="font-style-16 cl-black" for="Name">ตำบล </Label><Label className="required"> *</Label>
                                                <Select
                                                    value={this.state.subDistrictSelected === null ? { label: 'กรุณาเลือกตำบล', value: -1 } : this.state.subDistrictSelected}
                                                    onChange={(e) => this.props.selectedHandle(e, 'subDistricts')}
                                                    options={this.state.subdistricts}
                                                    classNamePrefix="select2-selection" className="font-style-16"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                                {this.state.ManageType === "F" && (
                                    <Row>
                                        <Col lg={2}>
                                            <Label className="font-style-16 cl-black" for="Name">หมวดหมู่ </Label><Label className="required"> *</Label>
                                        </Col>
                                        <Col lg={10} style={{ marginLeft: -12 }}>
                                            {this.state.Maininfo_types.map((e, i) => {
                                                return (
                                                    <div key={i}
                                                        onClick={() => {
                                                            let arr = this.state.Maininfo_types
                                                            arr[i].isCheck = !arr[i].isCheck
                                                            this.setState({
                                                                Maininfo_types: arr
                                                            }, () => {
                                                                console.log(this.state.Maininfo_types)
                                                            })
                                                        }}
                                                    >
                                                        <div className="custom-control custom-checkbox mb-3"

                                                        >
                                                            <input
                                                                key={i}
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                checked={e.isCheck}

                                                            />
                                                            <label
                                                                className="custom-control-label font-style-16 cl-black"
                                                            >
                                                                {e.Details[this.state.Lang].Name}
                                                            </label>
                                                        </div>
                                                    </div>
                                                )
                                            })}



                                        </Col>
                                    </Row>
                                )}
                                {this.state.data == true && (

                                    <div>
                                        <div style={{ textAlign: 'end', paddingBottom: 10 }}>
                                            <button type="button" class="btn btn-primary" onClick={this.tog_xlarge} style={{ backgroundColor: '#173865', borderColor: '#173865' }}>
                                                เพิ่มสถานที่ดูแล{" "}
                                            </button>
                                        </div>

                                        <Modal
                                            size="xl"
                                            isOpen={this.state.modal_xlarge}
                                            toggle={this.tog_xlarge}
                                        >
                                            <div className="modal-header">
                                                <h5
                                                    className="modal-title mt-0"
                                                    id="myLargeModalLabel"
                                                >
                                                    รายการสถานที่
                                                </h5>
                                                <button
                                                    onClick={() =>
                                                        this.setState({ modal_xlarge: false })
                                                    }
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <Row>
                                                    <Col lg={10}>
                                                        <FormGroup>
                                                            <Input required type="text" className="form-control" id="Name" style={{ fontSize: 14 }}
                                                                placeholder="ค้นหาสิ่งที่คุณต้องการ..." value={this.props.Phone} onChange={(e) => { this.props.callback(e.target.value, 'Phone') }} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <button type="button" class="btn btn-primary" style={{ backgroundColor: '#F47806', borderColor: '#F47806', width: '100%' }}>
                                                            ค้นหา{" "}
                                                        </button>
                                                    </Col>
                                                </Row>

                                                <Row className="mt-3">
                                                    <Col lg={6}>
                                                        <FormGroup>
                                                            <Label for="Name">อำเภอ </Label><Label className="required"> *</Label>
                                                            <div className="form-group row">
                                                                <div className="col-md-12">
                                                                    <select className="form-control">
                                                                        <option>---- โปรดเลือก ----</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </FormGroup>

                                                    </Col>
                                                    <Col lg={6}>
                                                        <FormGroup>
                                                            <Label for="Name">ตำบล </Label><Label className="required"> *</Label>
                                                            <div className="form-group row">
                                                                <div className="col-md-12">
                                                                    <select className="form-control">
                                                                        <option>---- โปรดเลือก ----</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                                <Row className="mt-3">
                                                    <Col lg={6}>
                                                        <FormGroup>
                                                            <Label for="Name">หมวดหมู่ </Label><Label className="required"> *</Label>
                                                            <div className="form-group row">
                                                                <div className="col-md-12">
                                                                    <select className="form-control">
                                                                        <option>---- โปรดเลือก ----</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </FormGroup>

                                                    </Col>
                                                    <Col lg={6}>
                                                        <FormGroup>
                                                            <Label for="Name">สถานะ </Label><Label className="required"> *</Label>
                                                            <div className="form-gro up row">
                                                                <div className="col-md-12">
                                                                    <select className="form-control">
                                                                        <option>---- โปรดเลือก ----</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                {/* <DataGrid pageSize={5} className="mt-3"
                                                    data={[{ field: 'activity', headerName: 'ชื่อกิจกรรม', width: 300 },
                                                    { field: 'category', headerName: 'หมวดหมู่', width: 300 },
                                                    { field: 'secondarycategory', headerName: 'หมวดหมู่รอง', width: 300 },
                                                    { field: 'manage', headerName: 'จัดการ', width: 300 }]}
                                                /> */}
                                                <hr></hr>
                                                <Row style={{ justifyContent: 'flex-end' }}>
                                                    <Col lg={2}>
                                                        <button type="button" class="btn btn-primary" style={{ backgroundColor: '#173865', borderColor: '#173865', width: '100%' }}>
                                                            ยืนยัน{" "}
                                                        </button>
                                                    </Col>

                                                </Row>

                                            </div>
                                        </Modal>
                                        <div>
                                            {/* <DataGrid pageSize={5} className="mt-3"
                                                data={[{ field: 'activity', headerName: 'ชื่อกิจกรรม', width: 300 },
                                                { field: 'category', headerName: 'หมวดหมู่', width: 300 },
                                                { field: 'secondarycategory', headerName: 'หมวดหมู่รอง', width: 300 },
                                                { field: 'manage', headerName: 'จัดการ', width: 300 }]}
                                            /> */}
                                        </div>

                                    </div>

                                )}

                            </CardBody>
                        </Card>
                    </div>
                    <div>
                        <Card>
                            <CardBody>

                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto" style={{ marginRight: -12 }}>
                                        <Button
                                            style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                            type="submit"
                                            // onClick={this.tog_standard}
                                            onClick={() => { this.checkSubmit() }}
                                            data-toggle="modal"
                                            data-target="#myModal"
                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}

                                        </Button>
                                    </div>
                                    <Modal
                                        isOpen={this.state.modal_standard}
                                        toggle={this.tog_standard}
                                    >
                                        <div>
                                            {/* <h5 className="modal-title mt-0" id="myModalLabel">
                                                Modal Heading
                            </h5> */}
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    this.setState({ modal_standard: false })
                                                }
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div>
                                            <div className="col-lg-12" style={{ paddingTop: 20 }}>
                                                <div style={{ textAlign: 'center', marginRight: -67 }}>
                                                    <img src={Flat} width="200" />
                                                </div>
                                            </div>
                                            <div style={{ textAlign: 'center' }}>
                                                <p className="mt-3">ยืนยันการดำเนินการ?</p>
                                                <p className="mt-3">กรุณาตรวจสอบข้อมูลก่อนกดยืนยัน</p>
                                            </div>


                                        </div>
                                        <div className="modal-footer">
                                            <div className="col-auto">
                                                <Button
                                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                                                    type="submit"
                                                    onClick={this.tog_standard}
                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                                </Button>
                                            </div>
                                            <div className="col-auto">
                                                <Button
                                                    style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                                    type="submit"
                                                    onClick={this.tog_standard}
                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb">ยืนยัน</span>{" "}

                                                </Button>
                                            </div>
                                            {/* <button
                                                type="button"
                                                onClick={this.tog_standard}
                                                className="btn btn-secondary waves-effect"
                                                data-dismiss="modal"
                                            >
                                                Close
                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-primary waves-effect waves-light"
                                            >
                                                Save changes
                            </button> */}
                                        </div>
                                    </Modal>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                                            type="submit"

                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                        </Button>
                                    </div>

                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>

            </div>


        )
    }
}
