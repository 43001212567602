import React, { Component } from "react"
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
  TabPane,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import logoplus from "../../assets/images/ttd/Vector.svg"
import logodown from "../../assets/images/ttd/donw.png"
import Map from "../Dashboard-blog/MapsGoogle"
import StoryTap from './component/StoryTap'
import Hotel from '../../assets/images/ttd/hotel.svg'
import "../../assets/scss/Customttd.scss"

const optionGroup = [
  {
    label: "Picnic",
    options: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" }
    ]
  },
  {
    label: "Camping",
    options: [
      { label: "Tent", value: "Tent" },
      { label: "Flashlight", value: "Flashlight" },
      { label: "Toilet Paper", value: "Toilet Paper" }
    ]
  }
]


class StoryDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedGroup: null,
      activeTabJustify: "5",
      lang: 'th',
      Thaipage: true,
      Engpage: false,
      Chinapage: false,
      placeId: null,
      status: null
    }
    console.log(this.props)
    this.toggleCustomJustified = this.toggleCustomJustified.bind(this)
    this.Thaipage = this.Thaipage.bind(this);
    this.Engpage = this.Engpage.bind(this);
    this.Chinapage = this.Chinapage.bind(this);
  }

  Thaipage() {

    this.setState({
      Thaipage: true,
      Engpage: false,
      Chinapage: false,
    })
  }

  Engpage() {

    this.setState({
      Engpage: true,
      Thaipage: false,
      Chinapage: false,
    })
  }

  Chinapage() {

    this.setState({
      Chinapage: true,
      Engpage: false,
      Thaipage: false,

    })
  }

  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }

  toggleCustomJustified(tab) {
    if (this.state.activeTabJustify !== tab) {
      this.setState({
        activeTabJustify: tab,
      })
    }
  }

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    const status = params.get('status');
    this.setState({
      placeId: id,
      status: status,
    }, () => {
      console.log(this.state.placeId)
      console.log(this.state.status)
    })
  }


  render() {
    const { selectedGroup } = this.state
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="หน้าแรก" link0="/dashboard-blog" breadcrumbItem="รายการเรื่องราว" link1="/Story" breadcrumbItems={this.state.placeId === null || this.state.status === null ? "เพิ่มเรื่องราว" : 'รายละเอียดเรื่องราว'} />
            <Card>
              <CardBody>
                <Row style={{ padding: 15 }}>
                  <div style={{ alignSelf: 'center', width: '70%' }}>
                    <h1 className="text-24" style={{ color: '#173865' }}>{this.state.placeId === null || this.state.status === null ? "เพิ่มเรื่องราว" : 'รายละเอียดเรื่องราว'}</h1>
                    <CardSubtitle className="text-16">{this.state.placeId !== null ? "กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนดำเนินการต่อไป" : 'การกรอกข้อมูลให้ละเอียดและถูกต้องจะทำให้ผู้ใช้งานเว็บคนอื่นๆหาสถานที่ของคุณเจอได้ง่ายขึ้น'} </CardSubtitle>
                  </div>
                  <div style={{ textAlign: 'center', width: '30%' }}>
                    <img style={{ width: '12vw', height: 'auto' }} src={Hotel}></img>
                  </div>
                </Row>
                <hr></hr>
                <Row>
                  <Col lg={4} style={{ cursor: 'pointer' }}>
                    <div style={{ textAlign: 'center' }}>
                      <h3 className="text-18" style={{ color: this.state.Thaipage == true ? '#173865' : '#808080' }} onClick={() => { this.Thaipage() }}>ภาษาไทย</h3>
                      {this.state.Thaipage == true && <div class="hr-line"></div>}
                    </div>
                  </Col>
                  <Col lg={4} style={{ cursor: 'pointer' }}>
                    <div style={{ textAlign: 'center' }}>
                      <h3 className="text-18" style={{ color: this.state.Engpage == true ? '#173865' : '#808080' }} onClick={() => { this.Engpage() }}>English</h3>
                      {this.state.Engpage == true && <div class="hr-line"></div>}
                    </div>
                  </Col>
                  <Col lg={4} style={{ cursor: 'pointer' }}>
                    <div style={{ textAlign: 'center' }}>
                      <h3 className="text-18" style={{ color: this.state.Chinapage == true ? '#173865' : '#808080' }} onClick={() => { this.Chinapage() }}>简体中文</h3>
                      {this.state.Chinapage == true && <div class="hr-line"></div>}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {this.state.Thaipage === true && (
              <StoryTap lang={this.state.lang} />
            )}
            {this.state.Engpage === true && (
              <StoryTap lang={'en'} />
            )}
            {this.state.Chinapage === true && (
              <StoryTap lang={'zh'} />
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default StoryDetail
