import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"

export default class Status extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Nonestatus: false,
            status: false,
            IsEnabled: this.props.IsEnabled,
            PublishDate: this.props.data.PublishDate,
            PublishDown: this.props.data.PublishDown,
        }
        // console.log(this.state.IsEnabled)
        // console.log(this.props.userData)
        console.log(props)
    }
    selectedHandle(data, type) {
        console.log(data)
        if (type === 'IsEnabled') {
            this.props.callback(data, 'IsEnabled')
            return
        }
        if (type === 'PublishDate') {
            this.props.callback(data, 'PublishDate')
            return
        }
        if (type === 'PublishDown') {
            this.props.callback(data, 'PublishDown')
            return
        }
    }

    render() {
        console.log(this.props)
        return (
            <div>
                <Row>
                    <Col lg={12}>
                        <Label className={'text-24'}>สถานะข้อมูล</Label>
                    </Col>
                </Row>
                <Row>
                    {this.props.lang === 'th' ?
                        <Col lg={6}>
                            <div className="form-group">
                                <label className="font-style-16 cl-black">วันที่ประกาศ</label>
                                <input type="date" value={this.state.PublishDate}
                                    max={this.state.PublishDown}
                                    onChange={(e) => {
                                        this.setState({
                                            PublishDate: e.target.value
                                        }, () => {
                                            this.selectedHandle(e.target.value, 'PublishDate')
                                        })
                                    }} placeholder="วันที่ประกาศ" className="form-control"></input>
                            </div>
                        </Col>
                        :
                        <Col lg={6}>
                            <Label className="font-style-16 cl-black">วันที่ประกาศ</Label>
                            <p className="font-style-16 cl-darkblue">
                                (ให้จัดการข้อมูลที่ภาษาไทย)
                            </p>
                        </Col>
                    }
                    {this.props.lang === 'th' ?
                        <Col lg={6}>
                            <div className="form-group">
                                <label className="font-style-16 cl-black">วันที่ปิดประกาศ</label>
                                <input type="date" value={this.state.PublishDown}
                                    min={this.state.PublishDate}
                                    onChange={(e) => {
                                        this.setState({
                                            PublishDown: e.target.value
                                        }, () => {
                                            this.selectedHandle(e.target.value, 'PublishDown')
                                        })
                                    }} placeholder="วันที่ปิดประกาศ" className="form-control"></input>
                            </div>
                        </Col>
                        :
                        <Col lg={6}>
                            <Label className="font-style-16 cl-black">วันที่ปิดประกาศ</Label>
                            <p className="font-style-16 cl-darkblue">
                                (ให้จัดการข้อมูลที่ภาษาไทย)
                            </p>
                        </Col>
                    }
                </Row>
                {this.props.lang === 'th' ?
                    <Row>
                        <Col lg={2}>
                            <Label className="font-style-16 cl-black" for="Name">สถานะข้อมูล </Label><Label className="required"> *</Label>
                        </Col>
                        <Col lg={2}>
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    id={'IsEnabled'}
                                    onChange={() => false}
                                    checked={this.props.IsEnabled === "Y" ? true : false}
                                />
                                <label
                                    className="custom-control-label font-style-16 cl-black"
                                    onClick={() => {
                                        this.selectedHandle("Y", 'IsEnabled')
                                    }}
                                >
                                    เผยแพร่
                                </label>
                            </div>
                        </Col>
                        <Col lg={2}>
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    type="radio"
                                    className="custom-control-input"
                                    id={'IsEnabled'}
                                    onChange={() => false}
                                    checked={this.props.IsEnabled === "N" ? true : false}
                                />
                                <label
                                    className="custom-control-label font-style-16 cl-black"
                                    onClick={() => {

                                        this.selectedHandle("N", 'IsEnabled')
                                    }}
                                >
                                    ไม่เผยแพร่
                                </label>
                            </div>
                        </Col>
                    </Row>
                    :
                    <div>
                        <Label className="font-style-16 cl-black">สถานะข้อมูล</Label>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }
            </div >
        )
    }
}
