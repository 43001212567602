

import React, { Component, useEffect, useState } from 'react'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    CardSubtitle,
    Modal,
} from "reactstrap"
import Select from "react-select"
import Flat from '../../../assets/images/ttd/Flat.svg'
import { Link } from "react-router-dom"
import { api_endpoint } from '../../../global-config'

export default class AddUserLangTh extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: "th",
            selected_date_start: null,
            data: false,
            //------------------------------------------------------//
            userData: JSON.parse(localStorage.getItem('authUser')),
            forms: {
                TitleName: "",
                FirstName: "",
                LastName: "",
                Email: "",
                Username: "",
                Password: "",
                UserType: "E",
                UserSubType: null,
                UserGroupID: null,
                DepartmentID: null,
            }
        }
    }

    registerHandler(e, field) {
        const value = e.target.value;
        console.log("field", field)
        this.setState(prevState => ({
            forms: {
                ...prevState.forms,
                [field]: value,
            }
        }), () => {
            console.log("forms", this.state.forms)
        });
    }

    async registerSubmit() {
        const url = api_endpoint + "/auth/register";
        const body = {
            Username: this.state.forms.Username,
            Password: this.state.forms.Password,
            UserType: "E",
            UserSubType: null,
            UserGroupID: null,
            DepartmentID: null,
            TitleName: this.state.forms.TitleName,
            FirstName: this.state.forms.FirstName,
            LastName: this.state.forms.LastName,
            Email: this.state.forms.Email
        }
        const { Password, ConfirmPassword } = this.state.forms;

        if (Password !== ConfirmPassword) {
            alert("รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน");
            return;
        }
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'bearer ' + this.state.userData.token
            },
            body: JSON.stringify(body)
        })
        const _result = await response.json();
        if (_result.status === 'ok' && _result.success === true) {
            alert("บันทึกสำเร็จแล้ว");
            window.location.href = '/ManageUserRole';
        } else {
            console.log('error : register failed!')
        }
    }

    render() {
        return (
            <div>
                <div>
                    <Card>
                        <CardBody>
                            <p className={'text-24'}>ข้อมูลทั่วไป</p>
                            <Row className={'mt-3'}>
                                <Col lg="12">
                                    <FormGroup>
                                        <Label for="TitleName" className="font-style-16 cl-black">คำนำหน้า </Label><Label className="required"> *</Label>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <select className="form-control" value={this.state.forms.TitleName} onChange={(e) => this.registerHandler(e, "TitleName")}>
                                                    <option value="">กรุณาเลือกคำนำหน้า</option>
                                                    <option value="นาย">นาย</option>
                                                    <option value="นาง">นาง</option>
                                                    <option value="นางสาว">นางสาว</option>
                                                </select>
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <Label for="Username" className="font-style-16 cl-black">ชื่อผู้ใช้งาน </Label><Label className="required"> *</Label>
                                        <Input
                                            style={{ fontSize: 16 }}
                                            required
                                            type="text"
                                            className="form-control"
                                            id="Username"
                                            placeholder="กรุณากรอกชื่อผู้ใช้งาน"
                                            value={this.state.forms.Username}
                                            onChange={(e) => this.registerHandler(e, "Username")}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <Label for="Email" className="font-style-16 cl-black">อีเมล </Label><Label className="required"> *</Label>
                                        <Input
                                            style={{ fontSize: 16 }}
                                            required
                                            type="text"
                                            className="form-control"
                                            id="Email"
                                            placeholder="กรุณากรอกอีเมล"
                                            value={this.state.forms.Email}
                                            onChange={(e) => this.registerHandler(e, "Email")}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <Label for="Password" className="font-style-16 cl-black">รหัสผ่าน </Label><Label className="required"> *</Label>
                                        <Input
                                            style={{ fontSize: 16 }}
                                            required
                                            type="password"
                                            className="form-control"
                                            id="Password"
                                            placeholder="กรุณากรอกรหัสผ่าน"
                                            value={this.state.forms.Password}
                                            onChange={(e) => this.registerHandler(e, "Password")}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <Label for="ConfirmPassword" className="font-style-16 cl-black">ยืนยันรหัสผ่าน </Label><Label className="required"> *</Label>
                                        <Input
                                            style={{ fontSize: 16 }}
                                            required
                                            type="password"
                                            className="form-control"
                                            id="ConfirmPassword"
                                            placeholder="กรุณากรอกรหัสผ่าน"
                                            value={this.state.forms.ConfirmPassword}
                                            onChange={(e) => this.registerHandler(e, "ConfirmPassword")}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>

                    <div>
                        <Card>
                            <CardBody>
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto" style={{ marginRight: -12 }}>
                                        <Button
                                            style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                            type="submit"
                                            onClick={() => { this.registerSubmit() }}
                                            data-toggle="modal"
                                            data-target="#myModal"
                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}

                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                                            type="submit"
                                            onClick={() => window.location.href = "/ManageUserRole"}
                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}
                                        </Button>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}
