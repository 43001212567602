import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"
import { api_endpoint } from "global-config"
class Pie extends Component {
  constructor(props) {
    super(props)
    this.state = {
      StartDate: this.props.StartDate,
      EndDate: this.props.EndDate,
      userData: JSON.parse(localStorage.getItem('authUser')),
      legend: {
        data: [],
      },
      series: [
        {
          name: "",
          data: []
        }
      ]
    },
      // console.log("props Pie", props)
      this.getMainInfoType = this.getMainInfoType.bind(this)
  }

  componentDidMount() {
    this.getMainInfoType();
  }
  //จำนวนการแจ้งแก้ไขข้อมูลแยกตามประเภท
  async getMainInfoType() {
    const url = api_endpoint + "/report/maininfo_update/type/count";
    const body = {
      StartDate: this.state.StartDate,
      EndDate: this.state.EndDate
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: 'bearer ' + this.state.userData.token
      },
      body: JSON.stringify(body)
    })

    const _result = await response.json();
    // console.log("_result", _result)
    this.setState({
      legend: _result.chartData.legend,
      series: _result.chartData.series
    }, () => {
      // console.log("legend", this.state.legend)
      // console.log("series", this.state.series)
    })
  }

  getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { readOnly: false },
          // magicType: { type: ['line', 'bar'] },
          // restore: {},
          saveAsImage: {}
        }
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: this.state.legend.data,
        textStyle: {
          color: ["#74788d"],
        },
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { readOnly: false },
          // magicType: { type: ['line', 'bar'] },
          // restore: {},
          saveAsImage: {}
        }
      },
      color: ["#02a499", "#f8b425", "#ec4561", "#38a4f8", "#3c4ccf", "#2a0139", "#f68b32"],
      series: [
        {
          name: this.state.series[0].name,
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: this.state.series[0].data,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "450px" }} option={this.getOption()} />
      </React.Fragment>
    )
  }
}
export default Pie
