import React, { Component } from 'react'
import PropTypes, { element } from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { Link } from "react-router-dom"
import { api_endpoint } from '../../../global-config'

import Map from "../../Dashboard-blog/MapsGoogle"
import Facilities from './topic/Facilities'
import Utilities from './topic/Utilities'
import Journey from './topic/Journey'
import Activity from './topicrestaurant/Activity'
import More from './topic/More'
import Status from './topic/Status'
import General from './topicrestaurant/General'
import Menu from './topicrestaurant/Menu'
import Address from './topicrestaurant/Address'
import Contact from './topic/Contact'
import Picturesvideos from './topic/Picturesvideos'
import DateTime from './topic/DateTime'
import Rates from './topic/Rates'
import Foodtypes from './topicrestaurant/Foodtypes'
import * as loadingData from "../../../loadingttd.json";
import Lottie from "react-lottie";
import FadeIn from "react-fade-in";
import Feedback from './topicAll/Feedback';

const moment = require('moment'); // require
import localization from 'moment/locale/th';
moment.updateLocale('th', localization);

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export default class RestaurantTap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            IDEditter: null,
            IsEnabled: "Y",
            // activeTabJustify: "5",
            lang: props.lang,
            isLoading: true,
            isLoadingDetail: false,
            placeId: null,
            Attraction: [],
            Activities: [],
            FoodTypes: [],
            Facilities: [],
            Utility: [],
            Types: [],
            TravelTypeID: [],
            OpenHours: [],
            Images: [],
            ImagesUpload: [],
            provinces: [],
            districts: [],
            subdistricts: [],
            Feedback: "",
            costrates: [],
            seats: [],
            restaurantInfo: {
                HasParking: "N",
                HasPreOrder: "N",
                HasDelivery: "N",
                RestaurantCostRateID: -1,
                RestaurantSeatID: -1
            },
            th: {
                Name: '',
                Activities: [],
                CoverImages: {},
                Details: "",
                TravelRemark: "",
                History: "",
                AreaSize: "",
                ParkingDetail: "",
                ResponseDepartment: "",
                Feature: "",
                //
                Address: "",
                MapImage: {},
                RegionID: "",
                Province: "",
                District: "",
                County: "",
                Latitude: "",
                Longitude: "",
                //
                Telephone: "",
                Mobile: "",
                Email: "",
                Website: "",
                ContactName: "",
                ContactPosition: "",
                //
                OpenHourRemark: "",
                OpencloseRemark: "",
                businesshours: "",
                Suitablerange: "",
                // HasDelivery: "N",
                // HasParking: "N",
                // HasPreOrder: "N",
                VdoYoutubeUrl: "",
                MoreDetail: "",
            },
            en: {
                Name: '',
                Activities: [],
                Details: "",
                TravelRemark: "",
                History: "",
                AreaSize: "",
                ParkingDetail: "",
                ResponseDepartment: '',
                Feature: "",
                //
                Address: "",
                RegionID: "",
                Province: "",
                District: "",
                County: "",
                Latitude: "",
                Longitude: "",
                //
                Telephone: "",
                Mobile: "",
                Email: "",
                Website: "",
                ContactName: "",
                ContactPosition: "",
                //
                OpenHourRemark: "",
                OpencloseRemark: "",
                businesshours: "",
                Suitablerange: "",
                MoreDetail: "",

            },
            zh: {
                Name: '',
                Activities: [],
                Details: "",
                TravelRemark: "",
                History: "",
                AreaSize: "",
                ParkingDetail: "",
                ResponseDepartment: '',
                Feature: "",
                //
                Address: "",
                RegionID: "",
                Province: "",
                District: "",
                County: "",
                Latitude: "",
                Longitude: "",
                //
                Telephone: "",
                Mobile: "",
                Email: "",
                Website: "",
                ContactName: "",
                ContactPosition: "",
                //
                OpenHourRemark: "",
                OpencloseRemark: "",
                businesshours: "",
                Suitablerange: "",
                MoreDetail: "",
            },
            Submit: {},
            status: null
        }
        this.callback = this.callback.bind(this);
        this.attraction_types = this.attraction_types.bind(this);
        this.get_attraction = this.get_attraction.bind(this);
        this.get_restaurant_food_types = this.get_restaurant_food_types.bind(this);
        // this.get_attraction_facilities = this.get_attraction_facilities.bind(this);
        // this.attraction_utility_types = this.attraction_utility_types.bind(this);

        this.submitForm = this.submitForm.bind(this);

    }



    async attraction_types() {
        const response = await fetch(
            api_endpoint + `/lkup/attraction_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();

        for (let i = 0; i < _result.length; i++) {
            for (let j = 0; j < _result[i].SubTypes.length; j++) {
                _result[i].SubTypes[j] = { ..._result[i].SubTypes[j], isCheck: false }
            }
        }
        this.setState({
            Types: _result,
        }, () => {
            this.restaurant_cost_rates()
        })
    }

    async restaurant_cost_rates() {
        const response = await fetch(
            api_endpoint + `/lkup/restaurant_cost_rates`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        for (let i = 0; i < _result.length; i++) {
            if (_result[i].ID === this.state.restaurantInfo.RestaurantCostRateID) {
                _result[i] = { ..._result[i], isCheck: true }
                let obj = this.state[this.state.lang]
                // obj.Province = _result[i].Code
                // obj.RegionID = _result[i].RegionID
                this.setState({
                    [this.state.lang]: obj
                })
            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            costrates: _result,
        }, () => {
            // console.log(this.state.costrates)
            this.restaurant_seats()
        })
    }


    async restaurant_seats() {
        const response = await fetch(
            api_endpoint + `/lkup/restaurant_seats`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        // console.log(_result)
        for (let i = 0; i < _result.length; i++) {
            if (_result[i].ID === this.state.restaurantInfo.RestaurantSeatID) {
                _result[i] = { ..._result[i], isCheck: true }
                let obj = this.state[this.state.lang]
                // obj.Province = _result[i].Code
                // obj.RegionID = _result[i].RegionID
                this.setState({
                    [this.state.lang]: obj
                })
            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            seats: _result,
        }, () => {

            this.get_provinces()
        })
    }




    async get_provinces() {
        const response = await fetch(
            api_endpoint + `/lkup/provinces`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    RegionID: [],
                    Code: [],

                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();

        for (let i = 0; i < _result.length; i++) {
            if (_result[i].Details[this.state.lang].Name === this.state[this.state.lang].Province) {
                _result[i] = { ..._result[i], isCheck: true }
                let obj = this.state[this.state.lang]
                obj.Province = _result[i].Code
                obj.RegionID = _result[i].RegionID
                this.setState({
                    [this.state.lang]: obj
                })
            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            provinces: _result
        }, () => {
            this.get_districts()
        })
    }


    async get_districts() {
        if (this.state[this.state.lang].District === "" && this.state[this.state.lang].Province === "") {
            this.get_attraction()
            return
        }
        const response = await fetch(
            api_endpoint + `/lkup/districts`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ProvinceCode: this.state[this.state.lang].Province.toString(),
                    // Code: [],

                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();


        for (let i = 0; i < _result.length; i++) {
            if (_result[i].Details[this.state.lang].Name === this.state[this.state.lang].District) {
                _result[i] = { ..._result[i], isCheck: true }
                let obj = this.state[this.state.lang]
                obj.District = _result[i].Code
                this.setState({
                    [this.state.lang]: obj
                })
            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            districts: _result,
        }, () => {
            this.get_subdistricts()
        })
    }

    async get_subdistricts() {
        if (this.state[this.state.lang].County === "" && this.state[this.state.lang].District === "") {
            this.get_attraction()
            return
        }
        const response = await fetch(
            api_endpoint + `/lkup/subdistricts`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ProvinceCode: this.state[this.state.lang].Province.toString(),
                    DistrictCode: this.state[this.state.lang].District.toString(),

                    // Code: [],

                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        for (let i = 0; i < _result.length; i++) {
            if (_result[i].Details[this.state.lang].Name === this.state[this.state.lang].County) {
                _result[i] = { ..._result[i], isCheck: true }
                let obj = this.state[this.state.lang]
                obj.County = _result[i].Code
                this.setState({
                    [this.state.lang]: obj
                })
            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            subdistricts: _result,
        }, () => {
            this.get_attraction()
        })
    }




    async get_attraction() {
        const response = await fetch(
            api_endpoint + `/lkup/attraction_activity_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();

        this.setState({
            Attraction: _result,
        }, () => {
            this.get_restaurant_food_types()
        })
    }


    async get_restaurant_food_types() {
        const response = await fetch(
            api_endpoint + `/lkup/restaurant_food_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        console.log(this.state.FoodTypes)
        if (Array.isArray(this.state.FoodTypes)) {
            for (let i = 0; i < _result.length; i++) {
                _result[i] = { ..._result[i], isCheck: false }
            }
            _result.map(e => {
                this.state.FoodTypes.forEach(element => {
                    if (e.ID === element.ID) { e.isCheck = true }
                    // console.log(element)
                })
            })
            this.setState({
                FoodTypes: _result,

            }, () => {
                // console.log('xxx')
                console.log(this.state.FoodTypes)
                this.get_travel_types()
            })
        }
        else {
            for (let i = 0; i < _result.length; i++) {
                _result[i] = { ..._result[i], isCheck: false }
            }
            this.setState({
                FoodTypes: _result,

            }, () => {
                console.log(this.state.FoodTypes)
                this.get_travel_types()
            })
        }
    }

    // async get_attraction_facilities() {
    //     const response = await fetch(
    //         api_endpoint + `/lkup/attraction_facilities`,
    //         {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json"
    //             },
    //             body: JSON.stringify({
    //                 // ID: this.state.place_id,
    //                 Lang: this.state.lang,
    //             }), // body data type must match "Content-Type" header
    //         }
    //     );
    //     var _result = await response.json();
    //     for (let i = 0; i < _result.length; i++) {
    //         // console.log(this.state.Facilities)
    //         // console.log(_result[i])
    //         if (_result[i].AttractionFacilityTypeID === 1) {
    //             this.state.Facilities.map((e) => {
    //                 if (e.ID === _result[i].ID) {
    //                     _result[i] = { ..._result[i], total: 0, quantity: 0, isCheck: true }
    //                 }
    //                 else {
    //                     if (_result[i].isCheck === true) {
    //                         return
    //                     }
    //                     else {
    //                         _result[i] = { ..._result[i], total: 0, quantity: 0, isCheck: false }
    //                     }
    //                 }
    //             })

    //         }
    //         else {
    //             this.state.Facilities.map((e) => {
    //                 if (e.ID === _result[i].ID) {

    //                     _result[i] = { ..._result[i], quantity: 0, isCheck: true }
    //                 }
    //                 else {
    //                     if (_result[i].isCheck === true) {
    //                         return
    //                     }
    //                     else {
    //                         _result[i] = { ..._result[i], quantity: 0, isCheck: false }
    //                     }

    //                 }
    //             })

    //         }

    //     }
    //     console.log(_result)
    //     this.setState({
    //         Facilities: _result,

    //     }, () => {
    //         this.get_travel_types()
    //     })


    // }

    async get_travel_types() {
        const response = await fetch(
            api_endpoint + `/lkup/travel_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (Array.isArray(this.state.TravelTypeID)) {
            for (let i = 0; i < _result.length; i++) {
                _result[i] = { ..._result[i], isCheck: false }
            }
            _result.map(e => { this.state.TravelTypeID.forEach(element => { if (e.ID === element.ID) { e.isCheck = true } }) })
            this.setState({
                TravelTypeID: _result,
                isLoading: false,
            }, () => {
                // this.attraction_utility_types()
            })
        }
        else {
            for (let i = 0; i < _result.length; i++) {
                _result[i] = { ..._result[i], isCheck: false }
            }
            this.setState({
                TravelTypeID: _result,
                isLoading: false,
            }, () => {
                // this.attraction_utility_types()
            })
        }





    }

    // async attraction_utility_types() {
    //     const response = await fetch(
    //         api_endpoint + `/lkup/attraction_utility_types`,
    //         {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json"
    //             },
    //             body: JSON.stringify({
    //                 // ID: this.state.place_id,
    //                 Lang: this.state.lang,
    //             }), // body data type must match "Content-Type" header
    //         }
    //     );
    //     var _result = await response.json();

    //     if (Array.isArray(this.state.Utility)) {
    //         for (let i = 0; i < _result.length; i++) {
    //             for (let j = 0; j < _result[i].SubTypes.length; j++) {
    //                 _result[i].SubTypes[j] = { ..._result[i].SubTypes[j], isCheck: false }
    //             }
    //         }
    //         this.setState({
    //             Utility: _result,
    //             isLoading: false,
    //         })
    //     }
    //     else {
    //         for (let i = 0; i < _result.length; i++) {
    //             for (let j = 0; j < _result[i].SubTypes.length; j++) {
    //                 _result[i].SubTypes[j] = { ..._result[i].SubTypes[j], isCheck: false }
    //             }
    //         }
    //         _result.map(e => {
    //             Object.keys(this.state.Utility).forEach(key => {
    //                 if (e.ID === this.state.Utility[key].ID) {
    //                     e.SubTypes.map(e => {
    //                         this.state.Utility[key].SubType.forEach(element => {
    //                             if (e.ID === element.ID) {
    //                                 e.isCheck = true
    //                             }
    //                         })
    //                     }

    //                     )
    //                 }
    //             });
    //         })
    //         this.setState({
    //             Utility: _result,

    //         })
    //     }



    // }

    // test() {
    //     console.log('x')
    // }

    callback(value, target) {
        if (target === 'IsEnabled') {
            this.state.IsEnabled = value
            this.setState({
                IsEnabled: this.state.IsEnabled
            }, () => {
                console.log(this.state.IsEnabled)
            })
            return
        }
        if (target === 'Feedback') {
            this.setState({
                Feedback: value
            }, () => {
                console.log(this.state.IsEnabled)
            })
            return
        }
        if (target === 'Latitude') {
            value = parseFloat(value)
        }
        if (target === 'Longitude') {
            value = parseFloat(value)
        }
        if (target === 'costrates') {
            console.log(this.state.restaurantInfo)
            console.log(value)
            // this.state.restaurantInfo.HasParking = value
            // this.state.restaurantInfo.HasPreOrder = value
            // this.state.restaurantInfo.HaHasDelivery = value
            this.state.restaurantInfo.RestaurantCostRateID = value
            this.setState({
                restaurantInfo: this.state.restaurantInfo
            }, () => {
                console.log(this.state.restaurantInfo)
            })
            return
        }
        if (target === 'seats') {
            this.state.restaurantInfo.RestaurantSeatID = value
            this.setState({
                restaurantInfo: this.state.restaurantInfo
            }, () => {
                console.log(this.state.restaurantInfo)
            })
            return
        }
        if (target === 'HasParking') {
            this.state.restaurantInfo.HasParking = value
            this.setState({
                restaurantInfo: this.state.restaurantInfo
            }, () => {
                console.log(this.state.restaurantInfo)
            })
            return
        }
        if (target === 'HasPreOrder') {
            this.state.restaurantInfo.HasPreOrder = value
            this.setState({
                restaurantInfo: this.state.restaurantInfo
            }, () => {
                console.log(this.state.restaurantInfo)
            })
            return
        }
        if (target === 'HasDelivery') {
            this.state.restaurantInfo.HasDelivery = value
            this.setState({
                restaurantInfo: this.state.restaurantInfo
            }, () => {
                console.log(this.state.restaurantInfo)
            })
            return
        }
        let obj = this.state[this.state.lang]
        // console.log(this.state.lang)
        obj[target] = value;
        console.log(value)
        this.setState({
            [this.state.lang]: obj
        }, () => {
            if (target === 'Province') {
                this.get_districts()
                return
            }
            if (target === 'District') {
                this.get_subdistricts()
                return
            }

        })
    }

    submitForm(target, value) {

        if (this.state.Submit[target] === undefined) {
            this.state.Submit[target] = value
        }
        else {
            this.state.Submit[target] = value
        }
        console.log(this.state.Submit)

    }

    async getRestaurantDetail(id) {
        let path = `/maininfo/readone`
        if (this.state.status !== null) { path = '/maininfo/readoneupdate' }
        const userData = JSON.parse(localStorage.getItem('authUser'))
        const response = await fetch(
            api_endpoint + path,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                    ID: Number(id),
                    IsBackend: 'Y'
                    // Update: "Y"
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        if (_result.result !== undefined) {
            // console.log(_result.result.Restaurant.FoodTypes)            
            let OpenHours = []
            _result.result.OpenHours.Items.map(e => {
                OpenHours.push({
                    DayID: e.DayID,
                    StartTime: moment(e.StartTime).format('LTS').split(" ")[0],
                    EndTime: moment(e.EndTime).format('LTS').split(" ")[0],
                    IsAllDay: null
                })

            })
            let Images = []
            _result.result.Images.map(e => {
                Images.push(e)
            })
            console.log(Images)

            let th = this.state.th
            let en = this.state.en
            let zh = this.state.zh
            if (this.state.lang === 'th') {
                th = {
                    ...this.state.th,
                    CoverImages: _result.result.IntroImage,
                    MapImage: _result.result.MapImage == null ? {} : _result.result.MapImage,
                    Name: _result.result.Name[this.state.lang],
                    //-----------------------------------------------//
                    Details: _result.result.Detail[this.state.lang],
                    TravelRemark: _result.result.TravelRemark[this.state.lang],
                    OpenHourRemark: _result.result.OpenHourRemark[this.state.lang],
                    Address: _result.result.Address[this.state.lang],
                    Province: _result.result.Province[this.state.lang],
                    District: _result.result.District[this.state.lang],
                    County: _result.result.SubDistrict[this.state.lang],
                    Latitude: _result.result.Latitude,
                    Longitude: _result.result.Longitude,
                    //-----------------------------------------------//
                    Telephone: _result.result.Telephone,
                    Mobile: _result.result.Mobile,
                    Email: _result.result.Email,
                    Website: _result.result.Website,
                    ContactName: _result.result.ContactName[this.state.lang],
                    ContactPosition: _result.result.ContactPosition[this.state.lang],
                    VdoYoutubeUrl: _result.result.VdoYoutubeUrl,
                    FacebookUrl: _result.result.FacebookUrl,
                    LineID: _result.result.LineID,
                    Twitter: _result.result.Twitter,
                    MoreDetail: _result.result.MoreDetail[this.state.lang],

                    //-----------------------------------------------//

                }
            }
            if (this.state.lang === 'en') {

                en = {
                    ...this.state.en,
                    Name: _result.result.Name[this.state.lang],
                    Details: _result.result.Detail[this.state.lang],
                    Province: _result.result.Province[this.state.lang],
                    District: _result.result.District[this.state.lang],
                    Address: _result.result.Address[this.state.lang],
                    OpenHourRemark: _result.result.OpenHourRemark[this.state.lang],
                    // AreaSize: _result.result.Attraction.Details.AreaSize !== undefined ?
                    //     _result.result.Attraction.Details.AreaSize[this.state.lang]
                    //     :
                    //     ""
                    // , ParkingDetail: _result.result.Attraction.Details.ParkingDetail !== undefined ?
                    //     _result.result.Attraction.Details.ParkingDetail[this.state.lang]
                    //     :
                    //     ""
                    // , ResponseDepartment: _result.result.Attraction.Details.ResponseDepartment !== undefined ?
                    //     _result.result.Attraction.Details.ResponseDepartment[this.state.lang]
                    //     :
                    //     ""
                    // , History: _result.result.Attraction.Details.History !== undefined ?
                    //     _result.result.Attraction.Details.History[this.state.lang]
                    //     :
                    //     ""
                    // ,
                    // Feature: _result.result.Attraction.Details.Feature !== undefined ?
                    //     _result.result.Attraction.Details.Feature[this.state.lang]
                    //     :
                    //     ""
                    // ,
                    MoreDetail: _result.result.MoreDetail[this.state.lang],

                }
            }
            if (this.state.lang === 'zh') {

                zh = {
                    ...this.state.en,
                    Name: _result.result.Name[this.state.lang],
                    Details: _result.result.Detail[this.state.lang],
                    Province: _result.result.Province[this.state.lang],
                    District: _result.result.District[this.state.lang],
                    Address: _result.result.Address[this.state.lang],
                    OpenHourRemark: _result.result.OpenHourRemark[this.state.lang],
                    // AreaSize: _result.result.Attraction.Details.AreaSize !== undefined ?
                    //     _result.result.Attraction.Details.AreaSize[this.state.lang]
                    //     :
                    //     ""
                    // , ParkingDetail: _result.result.Attraction.Details.ParkingDetail !== undefined ?
                    //     _result.result.Attraction.Details.ParkingDetail[this.state.lang]
                    //     :
                    //     ""
                    // , ResponseDepartment: _result.result.Attraction.Details.ResponseDepartment !== undefined ?
                    //     _result.result.Attraction.Details.ResponseDepartment[this.state.lang]
                    //     :
                    //     ""
                    // , History: _result.result.Attraction.Details.History !== undefined ?
                    //     _result.result.Attraction.Details.History[this.state.lang]
                    //     :
                    //     ""
                    // ,
                    // Feature: _result.result.Attraction.Details.Feature !== undefined ?
                    //     _result.result.Attraction.Details.Feature[this.state.lang]
                    //     :
                    //     ""
                    // ,
                    MoreDetail: _result.result.MoreDetail[this.state.lang],

                }
            }
            this.state.IDEditter = _result.result.ID,
                this.state.IsEnabled = _result.result.IsEnabled,
                this.state.Feedback = _result.result.Feedback,
                this.state.restaurantInfo.HasParking = _result.result.Restaurant.Info.HasParking,
                this.state.restaurantInfo.HasPreOrder = _result.result.Restaurant.Info.HasPreOrder,
                this.state.restaurantInfo.HasDelivery = _result.result.Restaurant.Info.HasDelivery,
                this.state.restaurantInfo.RestaurantCostRateID = _result.result.Restaurant.Info.RestaurantCostRate.ID,
                this.state.restaurantInfo.RestaurantSeatID = _result.result.Restaurant.Info.RestaurantSeat.ID,
                this.setState({
                    th: th,
                    en: en,
                    zh: zh,
                    FoodTypes: _result.result.Restaurant.FoodTypes,
                    restaurantInfo: this.state.restaurantInfo,
                    // restaurantInfo: _result.result.Restaurant.Info.RestaurantCostRate,
                    // seats: _result.result.Restaurant.Info.RestaurantSeatID,
                    // Targets: _result.result.Attraction.Targets.Items,
                    // Utility: _result.result.Attraction.Utilities,
                    TravelTypeID: _result.result.TravelTypes.Items,
                    // Facilities: _result.result.Attraction.Facilities,
                    // Activities: Attraction,
                    Images: Images,
                    OpenHours: OpenHours,
                    placeId: this.state.status === null ? this.state.placeId : _result.result.MainInfoID,
                    isLoadingDetail: false
                }, () => {

                    this.attraction_types()
                })

        }
        else {
            console.log('Error : getRestaurantDetail ')
        }

    }


    async submitData() {
        if (this.state[this.state.lang].Name === "") {
            return alert('กรุณาใส่ชื่อ')
        }
        if (this.state[this.state.lang].Details === "") {
            return alert('กรุณาใส่รายละเอียด')
        }
        if (this.state[this.state.lang].Address === "") {
            return alert('กรุณาใส่ที่อยู่')
        }
        if (this.state[this.state.lang].Province === "") {
            return alert('กรุณาเลือกจังหวัด')
        }
        if (this.state[this.state.lang].County === "") {
            return alert('กรุณาเลือก อำเภอ และ ตำบล')
        }
        if (this.state[this.state.lang].Latitude === "") {
            return alert('กรุณาใส่ พิกัดละติจูด')
        }
        if (this.state[this.state.lang].Longitude === "") {
            return alert('กรุณาใส่ พิกัดลองติจูด')
        }
        // if (this.state[this.state.lang].District === "") {
        //     return alert('กรุณาเลือกตำบล')
        // }
        if (this.state.Submit.ImageUpload === undefined) {
            return alert('กรุณาใส่รูป')
        }
        let IsEnabled = this.state.IsEnabled
        let Feedback = this.state.Feedback
        const userData = JSON.parse(localStorage.getItem('authUser'))
        if (userData.UserSubType === "Editor") {
            console.log('Editer >>>>')
            IsEnabled = "N"
            console.log(IsEnabled)
        }
        let obj = {
            MainTypeID: 6,
            IsEnabled: IsEnabled,
            Feedback: Feedback,
            // ID: userData.UserID,
            Main: {
                Info: {
                    SubDistrictCode: this.state[this.state.lang].County,
                    DistrictCode: this.state[this.state.lang].District,
                    ProvinceCode: this.state[this.state.lang].Province,
                    RegionID: 1,
                    PostCode: "",
                    Telephone: this.state[this.state.lang].Telephone,
                    Fax: "",
                    Mobile: this.state[this.state.lang].Mobile,
                    Website: this.state[this.state.lang].Website,
                    Email: this.state[this.state.lang].Email,
                    FacebookUrl: this.state[this.state.lang].FacebookUrl,
                    LineID: this.state[this.state.lang].LineID,
                    Twitter: this.state[this.state.lang].Twitter,
                    Latitude: this.state[this.state.lang].Latitude,
                    Longitude: this.state[this.state.lang].Longitude,
                    HashName: this.state.Submit.ImageUpload[0].HashName,
                    Path: this.state.Submit.ImageUpload[0].Path,
                    IntroImage: this.state.Submit.IntroImage,
                    MapImage: this.state.Submit.MapImage,
                    VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl
                },
                Details: {
                    [this.state.lang]: {
                        Lang: this.state.lang,
                        Name: this.state[this.state.lang].Name,
                        Detail: this.state[this.state.lang].Details,
                        Location: "",
                        Address: this.state[this.state.lang].Address,
                        Moo: "",
                        Soi: "",
                        Road: "",
                        Remark: "",
                        ContactName: this.state[this.state.lang].ContactName,
                        ContactPosition: this.state[this.state.lang].ContactPosition,
                        TravelRemark: this.state[this.state.lang].TravelRemark,
                        OpenHourRemark: this.state[this.state.lang].OpenHourRemark,

                        TravelMoreDetail: "",
                        MoreDetail: this.state[this.state.lang].MoreDetail,

                    }
                },
                OpenHours: this.state.Submit.OpenHours,
                TravelTypes: this.state.Submit.Travel_types,
                Images: this.state.Submit.ImageUpload,
            },
            Restaurant: {
                Info: {
                    HasParking: this.state.restaurantInfo.HasParking,
                    HasPreOrder: this.state.restaurantInfo.HasPreOrder,
                    HasDelivery: this.state.restaurantInfo.HasDelivery,
                    RestaurantCostRateID: this.state.restaurantInfo.RestaurantCostRateID,
                    RestaurantSeatID: this.state.restaurantInfo.RestaurantSeatID,

                },
                FoodTypes: this.state.Submit.FoodTypes,
            }
        }
        // ปิดส่ง
        // console.log(JSON.stringify(obj))
        // return
        const response = await fetch(
            api_endpoint + `/maininfo/create`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (_result.status === "ok") {
            alert('เพิ่มร้านอาหารสำเร็จ')
            window.location.href = "/Restaurant";
        }
        else {
            console.log('error : submitData')
        }
    }

    async updateData() {
        const userData = JSON.parse(localStorage.getItem('authUser'))
        let obj = {}
        if (this.state.lang === 'th') {
            obj = {
                MainTypeID: 6,
                ID: Number(this.state.placeId),
                IsEnabled: this.state.IsEnabled,
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        SubDistrictCode: this.state[this.state.lang].County,
                        DistrictCode: this.state[this.state.lang].District,
                        ProvinceCode: this.state[this.state.lang].Province,
                        RegionID: 1,
                        PostCode: "",
                        Telephone: this.state[this.state.lang].Telephone,
                        Fax: "",
                        Mobile: this.state[this.state.lang].Mobile,
                        Website: this.state[this.state.lang].Website,
                        Email: this.state[this.state.lang].Email,
                        FacebookUrl: this.state[this.state.lang].FacebookUrl,
                        LineID: this.state[this.state.lang].LineID,
                        Twitter: this.state[this.state.lang].Twitter,
                        Latitude: this.state[this.state.lang].Latitude,
                        Longitude: this.state[this.state.lang].Longitude,
                        IntroImage: this.state.Submit.IntroImage,
                        MapImage: this.state.Submit.MapImage,
                        VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl
                        // HashName: this.state.Submit.ImageUpload[0].HashName,
                        // Path: this.state.Submit.ImageUpload[0].Path
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            Location: "",
                            Address: this.state[this.state.lang].Address,
                            Moo: "",
                            Soi: "",
                            Road: "",
                            Remark: "",
                            ContactName: this.state[this.state.lang].ContactName,
                            ContactPosition: this.state[this.state.lang].ContactPosition,
                            TravelRemark: this.state[this.state.lang].TravelRemark,
                            OpenHourRemark: this.state[this.state.lang].OpenHourRemark,

                            TravelMoreDetail: "",
                            MoreDetail: this.state[this.state.lang].MoreDetail,

                        }
                    },
                    OpenHours: this.state.Submit.OpenHours,
                    TravelTypes: this.state.Submit.Travel_types,
                    Images: this.state.Submit.ImageUpload,
                },
                Restaurant: {
                    Info: {
                        HasParking: this.state.restaurantInfo.HasParking,
                        HasPreOrder: this.state.restaurantInfo.HasPreOrder,
                        HasDelivery: this.state.restaurantInfo.HasDelivery,
                        RestaurantCostRateID: this.state.restaurantInfo.RestaurantCostRateID,
                        RestaurantSeatID: this.state.restaurantInfo.RestaurantSeatID,

                    },
                    FoodTypes: this.state.Submit.FoodTypes,
                }

            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }
        }
        if (this.state.lang === 'en') {
            obj = {
                MainTypeID: 6,
                ID: Number(this.state.placeId),
                Main: {
                    // Info: {
                    //     SubDistrictCode: this.state[this.state.lang].County,
                    //     DistrictCode: this.state[this.state.lang].District,
                    //     ProvinceCode: this.state[this.state.lang].Province,
                    //     RegionID: 1,
                    //     PostCode: "",
                    //     Telephone: this.state[this.state.lang].Telephone,
                    //     Fax: "",
                    //     Mobile: this.state[this.state.lang].Mobile,
                    //     Website: this.state[this.state.lang].Website,
                    //     Email: this.state[this.state.lang].Email,
                    //     FacebookUrl: "facebook.com/abcd",
                    //     LineID: "",
                    //     Twitter: "",
                    //     Latitude: this.state[this.state.lang].Latitude,
                    //     Longitude: this.state[this.state.lang].Longitude,
                    //     // HashName: this.state.Submit.ImageUpload[0].HashName,
                    //     // Path: this.state.Submit.ImageUpload[0].Path

                    // },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            // Location: "",
                            Address: this.state[this.state.lang].Address,
                            Moo: this.state[this.state.lang].Moo,
                            Soi: this.state[this.state.lang].Soi,
                            Road: this.state[this.state.lang].Road,
                            MoreDetail: this.state[this.state.lang].MoreDetail,

                            // Remark: "",
                            // ContactName: "",
                            // ContactPosition: "",
                            // TravelRemark: this.state[this.state.lang].TravelRemark,
                            OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
                            // MoreDetail: "",
                            // TravelMoreDetail: ""
                        }
                    },
                    // OpenHours: this.state.Submit.OpenHours,
                    // TravelTypes: this.state.Submit.Travel_types,
                    // Images: this.state.Submit.ImageUpload,
                },


            }

        }
        if (this.state.lang === 'zh') {
            obj = {
                MainTypeID: 6,
                ID: Number(this.state.placeId),
                Main: {
                    // Info: {
                    //     SubDistrictCode: this.state[this.state.lang].County,
                    //     DistrictCode: this.state[this.state.lang].District,
                    //     ProvinceCode: this.state[this.state.lang].Province,
                    //     RegionID: 1,
                    //     PostCode: "",
                    //     Telephone: this.state[this.state.lang].Telephone,
                    //     Fax: "",
                    //     Mobile: this.state[this.state.lang].Mobile,
                    //     Website: this.state[this.state.lang].Website,
                    //     Email: this.state[this.state.lang].Email,
                    //     FacebookUrl: "facebook.com/abcd",
                    //     LineID: "",
                    //     Twitter: "",
                    //     Latitude: this.state[this.state.lang].Latitude,
                    //     Longitude: this.state[this.state.lang].Longitude,
                    //     // HashName: this.state.Submit.ImageUpload[0].HashName,
                    //     // Path: this.state.Submit.ImageUpload[0].Path

                    // },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            // Location: "",
                            Address: this.state[this.state.lang].Address,
                            MoreDetail: this.state[this.state.lang].MoreDetail,

                            // Moo: "",
                            // Soi: "",
                            // Road: "",
                            // Remark: "",
                            // ContactName: "",
                            // ContactPosition: "",
                            // TravelRemark: this.state[this.state.lang].TravelRemark,
                            OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
                            // MoreDetail: "",
                            // TravelMoreDetail: ""
                        }
                    },
                    // OpenHours: this.state.Submit.OpenHours,
                    // TravelTypes: this.state.Submit.Travel_types,
                    // Images: this.state.Submit.ImageUpload,
                },


            }

        }

        const response = await fetch(
            api_endpoint + `/maininfo/update`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        if (_result.status === "ok") {


            alert('แก้ไขร้านอาหารสำเร็จ')
            if (userData.UserSubType !== 'Admin' && userData.UserSubType !== 'SuperAdmin') {
                window.location.href = "/Restaurant";
            }

        }
        else {
            console.log('error : updateData')
        }
    }
    //     if (_result.status === "ok") {
    //         if (userData.UserType === 'B' && (userData.UserSubType === 'Admin' || userData.UserSubType === 'Superadmin')) {

    //             const response = await fetch(
    //                 api_endpoint + `/maininfo/approve`,
    //                 {
    //                     method: "POST",
    //                     headers: {
    //                         "Content-Type": "application/json",
    //                         Authorization: 'bearer ' + userData.token
    //                     },
    //                     body: JSON.stringify({
    //                         ID: Number(this.state.cloneId),
    //                         MainInfoID: Number(this.state.placeId)
    //                     }), // body data type must match "Content-Type" header
    //                 }
    //             );
    //             var _result = await response.json();
    //             if (_result.status === "ok") {
    //                 alert('แก้ไขสถานที่สำเร็จ')
    //             }
    //             else {
    //                 console.log('เกิดข้อผิดพลาด')
    //             }

    //         }
    //         else {
    //             alert('แก้ไขสถานที่สำเร็จ')
    //         }

    //     }
    //     else {
    //         console.log('error : updateData')
    //     }
    // }


    async Approve() {
        const userData = JSON.parse(localStorage.getItem('authUser'))
        let obj = {}
        if (this.state.lang === 'th') {
            obj = {
                MainTypeID: 6,
                ID: Number(this.state.IDEditter),
                IsEnabled: this.state.IsEnabled,
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        SubDistrictCode: this.state[this.state.lang].County,
                        DistrictCode: this.state[this.state.lang].District,
                        ProvinceCode: this.state[this.state.lang].Province,
                        RegionID: 1,
                        PostCode: "",
                        Telephone: this.state[this.state.lang].Telephone,
                        Fax: "",
                        Mobile: this.state[this.state.lang].Mobile,
                        Website: this.state[this.state.lang].Website,
                        Email: this.state[this.state.lang].Email,
                        FacebookUrl: this.state[this.state.lang].FacebookUrl,
                        LineID: this.state[this.state.lang].LineID,
                        Twitter: this.state[this.state.lang].Twitter,
                        Latitude: this.state[this.state.lang].Latitude,
                        Longitude: this.state[this.state.lang].Longitude,
                        IntroImage: this.state.Submit.IntroImage,
                        MapImage: this.state.Submit.MapImage,
                        VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl
                        // HashName: this.state.Submit.ImageUpload[0].HashName,
                        // Path: this.state.Submit.ImageUpload[0].Path
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            Location: "",
                            Address: this.state[this.state.lang].Address,
                            Moo: "",
                            Soi: "",
                            Road: "",
                            Remark: "",
                            ContactName: this.state[this.state.lang].ContactName,
                            ContactPosition: this.state[this.state.lang].ContactPosition,
                            TravelRemark: this.state[this.state.lang].TravelRemark,
                            OpenHourRemark: this.state[this.state.lang].OpenHourRemark,

                            TravelMoreDetail: "",
                            MoreDetail: this.state[this.state.lang].MoreDetail,

                        }
                    },
                    OpenHours: this.state.Submit.OpenHours,
                    TravelTypes: this.state.Submit.Travel_types,
                    Images: this.state.Submit.ImageUpload,
                },
                Restaurant: {
                    Info: {
                        HasParking: this.state.restaurantInfo.HasParking,
                        HasPreOrder: this.state.restaurantInfo.HasPreOrder,
                        HasDelivery: this.state.restaurantInfo.HasDelivery,
                        RestaurantCostRateID: this.state.restaurantInfo.RestaurantCostRateID,
                        RestaurantSeatID: this.state.restaurantInfo.RestaurantSeatID,

                    },
                    FoodTypes: this.state.Submit.FoodTypes,
                }

            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }
        }
        if (this.state.lang === 'en') {
            obj = {
                MainTypeID: 6,
                ID: Number(this.state.IDEditter),
                Main: {
                    // Info: {
                    //     SubDistrictCode: this.state[this.state.lang].County,
                    //     DistrictCode: this.state[this.state.lang].District,
                    //     ProvinceCode: this.state[this.state.lang].Province,
                    //     RegionID: 1,
                    //     PostCode: "",
                    //     Telephone: this.state[this.state.lang].Telephone,
                    //     Fax: "",
                    //     Mobile: this.state[this.state.lang].Mobile,
                    //     Website: this.state[this.state.lang].Website,
                    //     Email: this.state[this.state.lang].Email,
                    //     FacebookUrl: "facebook.com/abcd",
                    //     LineID: "",
                    //     Twitter: "",
                    //     Latitude: this.state[this.state.lang].Latitude,
                    //     Longitude: this.state[this.state.lang].Longitude,
                    //     // HashName: this.state.Submit.ImageUpload[0].HashName,
                    //     // Path: this.state.Submit.ImageUpload[0].Path

                    // },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            // Location: "",
                            Address: this.state[this.state.lang].Address,
                            Moo: this.state[this.state.lang].Moo,
                            Soi: this.state[this.state.lang].Soi,
                            Road: this.state[this.state.lang].Road,
                            MoreDetail: this.state[this.state.lang].MoreDetail,

                            // Remark: "",
                            // ContactName: "",
                            // ContactPosition: "",
                            // TravelRemark: this.state[this.state.lang].TravelRemark,
                            OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
                            // MoreDetail: "",
                            // TravelMoreDetail: ""
                        }
                    },
                    // OpenHours: this.state.Submit.OpenHours,
                    // TravelTypes: this.state.Submit.Travel_types,
                    // Images: this.state.Submit.ImageUpload,
                },


            }

        }
        if (this.state.lang === 'zh') {
            obj = {
                MainTypeID: 6,
                ID: Number(this.state.IDEditter),
                Main: {
                    // Info: {
                    //     SubDistrictCode: this.state[this.state.lang].County,
                    //     DistrictCode: this.state[this.state.lang].District,
                    //     ProvinceCode: this.state[this.state.lang].Province,
                    //     RegionID: 1,
                    //     PostCode: "",
                    //     Telephone: this.state[this.state.lang].Telephone,
                    //     Fax: "",
                    //     Mobile: this.state[this.state.lang].Mobile,
                    //     Website: this.state[this.state.lang].Website,
                    //     Email: this.state[this.state.lang].Email,
                    //     FacebookUrl: "facebook.com/abcd",
                    //     LineID: "",
                    //     Twitter: "",
                    //     Latitude: this.state[this.state.lang].Latitude,
                    //     Longitude: this.state[this.state.lang].Longitude,
                    //     // HashName: this.state.Submit.ImageUpload[0].HashName,
                    //     // Path: this.state.Submit.ImageUpload[0].Path

                    // },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            // Location: "",
                            Address: this.state[this.state.lang].Address,
                            MoreDetail: this.state[this.state.lang].MoreDetail,

                            // Moo: "",
                            // Soi: "",
                            // Road: "",
                            // Remark: "",
                            // ContactName: "",
                            // ContactPosition: "",
                            // TravelRemark: this.state[this.state.lang].TravelRemark,
                            OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
                            // MoreDetail: "",
                            // TravelMoreDetail: ""
                        }
                    },
                    // OpenHours: this.state.Submit.OpenHours,
                    // TravelTypes: this.state.Submit.Travel_types,
                    // Images: this.state.Submit.ImageUpload,
                },


            }

        }

        const response = await fetch(
            api_endpoint + `/maininfo/approve`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj),
            }
        );
        var _result = await response.json();
        if (_result.status === "ok") {


            alert('อนุมัติแก้ไขร้านอาหารสำเร็จ')

        }
        else {
            console.log('error : Approve')
        }
    }

    async reject() {
        console.log(Number(this.state.IDEditter))
        const userData = JSON.parse(localStorage.getItem('authUser'))
        let obj = {}
        if (this.state.lang === 'th') {
            obj = {
                ID: Number(this.state.IDEditter),
            }
            // if (obj.Main.Images === undefined) {
            //     delete obj.Main.Images
            // }
        }
        if (this.state.lang === 'en') {
            obj = {
                ID: Number(this.state.IDEditter),
            }
        }
        if (this.state.lang === 'zh') {
            obj = {
                ID: Number(this.state.IDEditter),
            }
        }
        obj['Feedback'] = this.state.Feedback

        const response = await fetch(
            api_endpoint + `/maininfo/reject`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj),
            }
        );
        var _result = await response.json();
        console.log(_result)
        if (_result.status === "ok") {


            alert('แจ้งส่งกลับแก้ไขร้านอาหารสำเร็จ')

        }
        else {
            console.log('error : reject')
        }
    }




    componentDidMount() {



        const search = window.location.search;
        const params = new URLSearchParams(search);
        const id = params.get('id');
        const status = params.get('status');

        if (id !== null) {
            console.log('in')
            this.setState({
                placeId: id,
                status: status,
                cloneId: id,
                isLoadingDetail: true
            }, () => {

                this.getRestaurantDetail(id)

            })

        }
        else {
            console.log('out')
            this.attraction_types()
        }




    }

    componentWillReceiveProps(nextProps) {

        // console.log(nextProps)
        if (nextProps.lang) {
            this.setState({
                lang: nextProps.lang
            }, () => {
                // console.log(this.state.lang)
            })
        }
    }



    render() {
        // console.log(this.state.Images)
        // console.log(this.state.isLoading)
        // console.log(this.state.isLoadingDetail )

        if (this.state.isLoading === true || this.state.isLoadingDetail === true) {
            return (
                <div className="text-center" >
                    <FadeIn>
                        <div style={{ display: "flex", justifyContent: 'center' }}>

                            <div >
                                <Lottie options={defaultOptions} height={300} width={300} />
                                <div>
                                    <h4 style={{ color: 'black', padding: 30 }}>กำลังโหลด</h4>
                                </div>

                            </div>


                        </div>

                    </FadeIn>
                </div>
            )
        }
        else {
            const userData = JSON.parse(localStorage.getItem('authUser'))
            console.log(userData)
            console.log(this.state.status)
            return (

                <div>
                    <Form onSubmit={() => { this.test() }}>
                        <Card>
                            <CardBody>
                                <General callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Types={this.state.Types} submit={this.submitForm} />

                                {/* <Button
                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                                    type="submit"

                                    className="chat-send w-md waves-effect waves-light"
                                >
                                    <span className="d-none d-sm-inline-block font-style-16 text-16" style={{ color: '#808080' }}>ค้นหาละเอียด </span>{" "}
                                </Button> */}
                            </CardBody>
                        </Card>
                    </Form>
                    <Card>
                        <CardBody>
                            <Foodtypes callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} FoodTypes={this.state.FoodTypes} costrates={this.state.costrates} seats={this.state.seats} restaurantInfo={this.state.restaurantInfo} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Address callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} provinces={this.state.provinces} districts={this.state.districts} subdistricts={this.state.subdistricts} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Contact callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Picturesvideos callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Images={this.state.Images} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <DateTime callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} OpenHours={this.state.OpenHours} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    {/* <Card>
                        <CardBody>
                            <Rates />
                        </CardBody>
                    </Card> */}
                    {/* <Card>
                        <CardBody>
                            <Target callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Target={this.state.Targets} submit={this.submitForm} />
                        </CardBody>
                    </Card> */}
                    {/* <Card>
                        <CardBody>
                            <Facilities callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Facilities={this.state.Facilities} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Utilities callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Utility={this.state.Utility} submit={this.submitForm} />
                        </CardBody>
                    </Card> */}
                    <Card style={{ zIndex: '0' }}>
                        <CardBody>
                            <Journey callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Travel_types={this.state.TravelTypeID} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    {/* <Card>
                        <CardBody>
                            <Activity callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Attraction={this.state.Attraction} Activities={this.state.Activities} submit={this.submitForm} />
                        </CardBody>
                    </Card> */}
                    {/* <Card>
                        <CardBody>
                            <Menu callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} submit={this.submitForm} />
                        </CardBody>
                    </Card> */}

                    <Card>
                        <CardBody>
                            <More callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Attraction={this.state.Attraction} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    {this.state.status === 'Y' ?
                        (userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ?
                            <Card>
                                <CardBody>
                                    <Feedback callback={this.callback} lang={this.state.lang} disabled={true} data={this.state} submit={this.submitForm} />
                                </CardBody>
                            </Card>
                            :
                            <Card>
                                <CardBody>
                                    <Feedback callback={this.callback} lang={this.state.lang} disabled={false} data={this.state} submit={this.submitForm} />
                                </CardBody>
                            </Card>
                        :
                        <div />
                    }
                    <Card>
                        <CardBody>
                            {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.placeId === null ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                            {this.state.placeId !== null && this.state.status !== 'Y' && (userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                            {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.status === 'Y' ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                            {this.state.placeId !== null && this.state.status !== 'Y' && userData.UserSubType !== 'Admin' && userData.UserSubType !== 'SuperAdmin' ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                            {/* < Status /> */}
                            {this.state.placeId === null ?
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                            type="submit"
                                            onClick={() => { this.submitData() }}

                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}

                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/Restaurant">
                                            <Button
                                                style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                            </Button>
                                        </Link>
                                    </div>
                                </Row>
                                :

                                <div>

                                </div>
                            }
                            {this.state.placeId !== null && this.state.status !== 'Y' ?
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                            type="submit"
                                            onClick={() => { this.updateData() }}

                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">แก้ไข</span>{" "}
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/Restaurant">
                                            <Button
                                                style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                            </Button>
                                        </Link>
                                    </div>
                                </Row>
                                :
                                <div></div>
                            }
                            {this.state.status === 'Y' && userData.UserSubType === 'Admin' && userData.UserType === 'B' ?
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                            type="submit"
                                            onClick={() => { this.Approve() }}

                                            className="chat-send w-md waves-effect waves-light"
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb"> อนุมัติการแก้ไข</span>{" "}
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Button
                                            style={{ backgroundColor: '#F4D006', borderColor: '#F4D006' }}
                                            className="chat-send w-md waves-effect waves-light"
                                            onClick={() => { this.reject() }}
                                        >
                                            <span className="d-none d-sm-inline-block font-style-16nb">แจ้งส่งกลับแก้ไข</span>{" "}

                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/notifyinformation">
                                            <Button
                                                style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                            </Button>
                                        </Link>

                                    </div>
                                </Row>
                                :
                                <div>

                                </div>
                            }
                            {this.state.status === 'Y' && userData.UserSubType === 'Editor' && userData.UserType === 'B' ?
                                <div></div>
                                :
                                <div></div>

                            }
                        </CardBody>
                    </Card>





                    {/* <button onClick={() => { this.submitData() }}>Submit</button> */}

                </div>

            )
        }

    }
}
