import React, { Component } from "react"
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import logoplus from "../../assets/images/ttd/Vector.svg"
import logodown from "../../assets/images/ttd/donw.png"
import DataGrid from '../../components/Table/Table'
import { api_endpoint } from '../../global-config'
import Edit from '../../assets/images/ttd/Edit.svg'


// const typeOptions = [
//   { label: "ลงทะเบียนผ่านระบบ", value: "F" },
//   { label: "ตามพื้นที่", value: "A" }
// ]

// const data = [
//   { id: 1, name: "teerath5424", date: "2023-08-16", type: "ลงทะเบียนผ่านระบบ" },
//   { id: 2, name: "myadmin", date: "2023-07-16", type: "ตามพื้นที่" },
//   { id: 3, name: "frong123", date: "2023-09-16", type: "ลงทะเบียนผ่านระบบ" }
// ]


class ManageUserRoleTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      skip: 0,
      limit: 10,
      dataTable: [],
      isLoading: true,
      sortModel: [{
        field: 'id',
        sort: 'desc'
      }],
      rowsPerPageOptions: [10, 25, 50],
      pageSize: 10,
      nameSearch: "",
      userData: JSON.parse(localStorage.getItem('authUser')),
    }
    this.getUsers = this.getUsers.bind(this);

    // console.log(props)
  }

  setPageSize = (value) => {
    this.setState({
      isLoading: true,
      skip: value,
      limit: value,
      pageSize: value
    }, () => {
      this.getUsers()
    })
  }

  inputHandle(value, type) {
    if (type === 'Search') {
      this.setState({
        nameSearch: value
      })
    }
  }

  clearFilter() {
    this.setState({
      nameSearch: ""
    }, () => {
      this.getUsers()
    })
  }


  async getUsers() {
    const response = await fetch(
      api_endpoint + `/users/read`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: 'bearer ' + this.state.userData.token
        },
        body: JSON.stringify({
          Skip: this.state.skip,
          Limit: this.state.limit,
          UserType: "E",
          Search: this.state.nameSearch === "" ? null : this.state.nameSearch,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    // console.log("result", _result)
    if (_result.results !== undefined) {
      let arr = []
      _result.results.map((e) => {
        // console.log(e)
        let dateOnly = ""
        if (e.created_at) {
          const dateTimeString = e.created_at;
          const dateArray = dateTimeString.split('T');
          dateOnly = dateArray[0];
          // console.log(dateOnly);
        }
        arr.push({
          id: e.ID,
          Username: e.Username !== null ? e.Username : "",
          created_at: dateOnly || "",
          UserType: e.UserType,
        })

      })
      this.setState({
        countData: _result.count,
        dataTable: arr,
        isLoading: false
      }, () => {
        // console.log(this.state.dataTable)
      })
    }
    else {
      console.log('error : getUsers')
    }
  }

  dataGridPagging = (value) => {
    if ((value.page * this.state.pageSize) === this.state.limit) {
      this.setState({
        isLoading: true,
        limit: this.state.limit + this.state.pageSize
      }, () => {
        // console.log(this.state.limit)
        this.getUsers()
      })
    }
  }
  handleSort = (value) => {
    let arr = this.state.sortModel
    console.log(value.sortModel[0])
    if (value.sortModel[0] === undefined) {
      arr[0].sort = 'asc'
      this.setState({
        sortModel: arr
      }, () => {

      })
    }
    else {
      arr[0].field = value.sortModel[0].field
      arr[0].sort = value.sortModel[0].sort
      this.setState({
        sortModel: arr
      })

    }
  }

  componentDidMount() {
    this.getUsers()
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="หน้าแรก" breadcrumbItem="รายการจัดการสมาชิก (ภายนอก)" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <Row>
                      <Col>
                        <h1 style={{ color: '#173865' }}>รายการจัดการสมาชิก (ภายนอก)</h1>
                      </Col>
                      <Col style={{ textAlign: 'end', alignSelf: 'center' }}>
                        <Link
                          style={{ backgroundColor: '#173865', borderColor: '#173865' }}
                          to="/ManageUserRole/AddUser"
                          className="btn btn-primary waves-effect waves-light w-lg "
                        >
                          <img src={logoplus} style={{ padding: '0px 10px 3px 0px', width: 30 }} />
                          <span className="font-style-16nb">เพิ่มสมาชิก</span>{" "}
                        </Link>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody className="pb-auto">
                    <Row>
                      <Col>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control rounded chat-input"
                            style={{ fontSize: 16 }}
                            placeholder="ค้นหาสิ่งที่คุณต้องการ..."
                            onChange={(e) => { this.inputHandle(e.target.value, 'Search') }}
                            value={this.state.nameSearch}
                          />
                        </div>
                      </Col>
                      <div className="col-auto">
                        <Button
                          style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                          type="submit"
                          onClick={() => { this.clearFilter() }}
                          className="chat-send w-md waves-effect waves-light"
                        >
                          <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ล้างค่าค้นหา</span>{" "}
                        </Button>
                      </div>
                      <div className="col-auto">
                        <Button
                          style={{ backgroundColor: '#F47806', borderColor: '#F47806' }}
                          type="submit"
                          onClick={() => { this.getUsers() }}
                          className="chat-send w-md waves-effect waves-light"
                        >
                          <span className="d-none d-sm-inline-block font-style-16nb">ค้นหา</span>{" "}
                        </Button>
                      </div>
                    </Row>
                    {/* <Row className={'mt-3'}>
                      <Col lg={4}>
                        <FormGroup className="select2-container">
                          <label className="font-style-16 cl-black">ประเภท</label>
                          <Select
                            value={this.selectedValue('type')}
                            onChange={(e) => this.handleSelected(e, 'type')}
                            options={typeOptions}
                            classNamePrefix="select2-selection" className="font-style-16b4"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={4}>
                        <div className="form-group">
                          <label className="font-style-16 cl-black">วันที่</label>
                          <input type="date" value={this.state.selected_date_start}
                            onChange={(e) => {
                              this.inputHandle(e.target.value, 'Date_Start')
                            }} placeholder="ปรับปรุงตั้งแต่วันที่" className="form-control" style={{ fontSize: 16 }} ></input>
                        </div>
                      </Col>
                    </Row> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div>
              <Card>
                <CardBody>
                  <DataGrid
                    header={
                      [
                        { field: 'id', headerName: 'ลำดับ', width: 120 },
                        { field: 'Username', headerName: 'ชื่อผู้ใช้งาน', flex: 1 },
                        { field: 'created_at', headerName: 'วันที่', flex: 1 },
                        {
                          field: 'UserType', headerName: 'ประเภท', flex: 1,
                          renderCell: (params) => {
                            if (params.value === 'E') {
                              return (<div>ผู้ใช้งานทั่วไป</div>)
                            }

                          }
                        },
                        {
                          field: 'Action', headerName: 'จัดการ', width: 120,
                          renderCell: (params) => (
                            <strong>
                              <Link
                                to={{
                                  pathname: `/ManageUserRole/EditUser`,
                                  state: { userId: params.row.id }
                                }}

                              >
                                <img src={Edit} style={{ paddingRight: 10 }} />
                              </Link>
                            </strong>
                          ),
                        }]
                    }
                    data={this.state.dataTable}
                    rowsCount={this.state.countData}
                    pageSize={this.state.pageSize}
                    pagging={this.dataGridPagging}
                    sort={this.handleSort}
                    rowsPerPageOptions={this.state.rowsPerPageOptions}
                    loading={this.state.isLoading}
                    setPageSize={this.setPageSize}
                  />
                </CardBody>
              </Card>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ManageUserRoleTable
