import React, { Component } from "react"
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap"
import Dropzone from "react-dropzone"

// Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

import { Link } from "react-router-dom"
import { api_endpoint, endpoint } from '../../../../global-config'
import Target from "../topic/Target"
class FormUpload extends Component {
  constructor(props) {
    super(props)
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.state = {
      selectedFiles: [],
      Images: this.props.Images
    }
  }

  handleAcceptedFiles = async (files) => {
    var formData = new FormData(); // Currently empty
    for (let file of files) {
      formData.append('files', file, file.name);
    }
    let upload_response = await fetch(api_endpoint + '/file/upload', {
      method: "POST",
      body: formData
    })
    let upload_response_object = await upload_response.json()
    if (upload_response_object.status == 'success') {
      upload_response_object.uploads
      let tmp_images = []
      for (let upload of upload_response_object.uploads) {
        tmp_images.push(upload)
      }
      // `${endpoint}/assets/upload${upload.Path}${upload.HashName}`,
      let Images = this.state.Images
      Images = Images.concat(tmp_images)
      this.setState({
        Images: Images
      }, () => {
        this.props.submit('ImageUpload', Images)
      })
    } else {
      alert('เกิดข้อผิดพลาดในการอัพโหลดไฟล์')
    }
    // let arr = this.state.selectedFiles
    // files.map(file => {
    //   Object.assign(file, {
    //     preview: URL.createObjectURL(file),
    //     formattedSize: this.formatBytes(file.size),
    //   })
    //   arr.push(file)
    // }
    // )

    // this.setState({ selectedFiles: arr }, () => { console.log(this.state.selectedFiles) })
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }


  async uploadPic(data) {
    let formData = new FormData()
    for (let file of data) {
      formData.append('files[]', file, file.name);
    }
    const response = await fetch(
      api_endpoint + `/file/upload`,
      {
        method: "POST",
        headers: {

        },
        body: formData
      }
    );
    var _result = await response.json();
    if (_result.status === 'success') {
      const userData = JSON.parse(localStorage.getItem('authUser'))
      const asyncRes = await Promise.all(_result.uploads.map(async (e) => {
        return { ...e, Created: new Date(), CreatedBy: userData.UserID }
      }));
      this.props.submit('ImageUpload', asyncRes)
      alert('อัพโหลดภาพสำเร็จ')
    }
    else {
      console.log('error uploadPic')
    }
  }
  deleteImage(i) {
    let Images = this.state.Images
    Images.splice(i, 1);
    this.setState({
      Images: Images
    }, () => {
      this.props.submit('ImageUpload', Images)
    }
    )

  }
  render() {
    return (
      <React.Fragment>

        <Row>
          <Col className="col-12">
            <h5 className="font-style-16 cl-black">รูปภาพแกลอรี่</h5>
            <Form>
              <Dropzone
                onDrop={acceptedFiles =>
                  // console.log(acceptedFiles)
                  this.handleAcceptedFiles(acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div
                      className="dz-message needsclick mt-2"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h4>Drop files here or click to upload.</h4>
                      <h4 className="mt-3" style={{ color: '#173865', fontSize: 14 }}>ขนาดไฟล์ไม่เกิน 2 MB, เฉพาะไฟล์นามสกุล: .jpg, .jpeg, .gif, .png </h4>
                      <h4 className="mt-3" style={{ color: '#173865', fontSize: 14 }}>และขนาดไฟล์ที่เหมาะสม: 800 x 600 pixel</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div
                className="dropzone-previews mt-3"
                id="file-previews"
              >
                {this.state.Images.map((e, i) => {
                  console.log(e)
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col lg="1">
                            <a href={`${endpoint}/assets/upload${e.Path}/${e.HashName}`} target="_blank">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={`${endpoint}/assets/upload${e.Path}/${e.HashName}`}
                                src={`${endpoint}/assets/upload${e.Path}/${e.HashName}`}
                              />
                            </a>
                          </Col>
                          <Col lg="9">
                            <a href={`${endpoint}/assets/upload${e.Path}/${e.HashName}`}
                              className="text-muted font-weight-bold" target="_blank"
                            >
                              {`${endpoint}/assets/upload${e.Path}/${e.HashName}`}
                            </a>
                            <p className="mb-0">
                              <strong>{e.formattedSize}</strong>
                            </p>
                          </Col>
                          <Col lg="2" style={{ display: 'flex', justifyContent: 'center' }}>
                            <Link

                              onClick={() => { this.deleteImage(i) }}
                            >
                              <p className="mb-0">
                                <strong>ลบ</strong>
                              </p>
                            </Link>

                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )
                })}


                {this.state.selectedFiles.map((f, i) => {
                  console.log(f)
                  // this.uploadPic(f)
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={f.name}
                              src={f.preview}
                            />
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                            >
                              {f.name}
                            </Link>
                            <p className="mb-0">
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )
                })}
              </div>
            </Form>

            {/* <div className="text-center mt-4">
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
              // onClick={() => { this.uploadPic(this.state.selectedFiles) }}
              >
                Send Files
              </button>
            </div> */}

          </Col>
        </Row>

      </React.Fragment >
    )
  }
}

export default FormUpload
