import React, { Component } from 'react'
import PropTypes, { element } from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { Link } from "react-router-dom"
import { api_endpoint } from '../../../global-config'

import Map from "../../Dashboard-blog/MapsGoogle"
import More from './topicContent/More'
import Status from './topicCampaign/Status'
import General from './topicStory/General'
import ImageGallery from './topicStory/ImageGallery'
import * as loadingData from "../../../loadingttd.json";
import Lottie from "react-lottie";
import FadeIn from "react-fade-in";
import Feedback from './topicAll/Feedback'
import Recommend from './topicCampaign/Recommend'
const moment = require('moment'); // require
import localization from 'moment/locale/th';
moment.updateLocale('th', localization);

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export default class StoryTap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            IDEditter: null,
            IsEnabled: "Y",
            // activeTabJustify: "5",
            lang: props.lang,
            isLoading: false,
            isLoadingDetail: false,
            placeId: null,
            Type: "S",
            PublishDate: null,
            PublishDown: null,
            Images: [],
            Details: {
                lang: "th",
                Title: "",
                IntroText: "",
                Detail: ""
            },
            th: {
                CoverImages: {},
                Title: "",
                IntroText: "",
                Detail: ""
            },
            en: {
                Title: "",
                IntroText: "",
                Detail: ""
            },
            zh: {
                Title: "",
                IntroText: "",
                Detail: ""
            },
            MainInfo: [],
            Submit: {},
            status: null,
        }
        this.callback = this.callback.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }


    async getStoryDetail(id) {
        let path = `/contentinfo/readone`
        // if (this.state.status !== null) { path = '/maininfo/readoneupdate' }
        const userData = JSON.parse(localStorage.getItem('authUser'))
        const response = await fetch(
            api_endpoint + path,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                    ID: Number(id),
                    // IsBackend: 'Y'
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        if (_result.result !== undefined) {
            console.log(_result)
            let MainInfo = []
            _result.result.MainInfo.map(e => {
                MainInfo.push({
                    id: e.ID, Name: e.Name[this.state.lang],
                    Province: e.Province[this.state.lang],
                    District: e.District[this.state.lang],
                    MainInfoType: e.MainInfoType[this.state.lang].Name,
                    IntroImage: e.IntroImage
                })
            })
            let Images = []
            _result.result.Images.map(e => {
                Images.push(e)
            })
            console.log(Images)
            let th = this.state.th
            let en = this.state.en
            let zh = this.state.zh

            if (this.state.lang === 'th') {
                th = {
                    ...this.state.th,
                    CoverImages: _result.result.IntroImage,
                    Title: _result.result.Title[this.state.lang],
                    IntroText: _result.result.IntroText[this.state.lang],
                    Detail: _result.result.Detail[this.state.lang],
                    Type: _result.result.Type,
                }
            }
            if (this.state.lang === 'en') {
                en = {
                    ...this.state.th,
                    CoverImages: _result.result.IntroImage,
                    Title: _result.result.Title[this.state.lang],
                    IntroText: _result.result.IntroText[this.state.lang],
                    Detail: _result.result.Detail[this.state.lang],
                    Type: _result.result.Type,
                }
            }
            if (this.state.lang === 'zh') {
                zh = {
                    ...this.state.th,
                    CoverImages: _result.result.IntroImage,
                    Title: _result.result.Title[this.state.lang],
                    IntroText: _result.result.IntroText[this.state.lang],
                    Detail: _result.result.Detail[this.state.lang],
                    Type: _result.result.Type,
                }
            }
            this.state.PublishDate = _result.result.Main.Info.PublishDate,
                this.state.PublishDown = _result.result.Main.Info.PublishDown,
                this.state.IDEditter = _result.result.ID,
                this.state.IsEnabled = _result.result.IsEnabled,
                this.state.Feedback = _result.result.Feedback,
                this.setState({
                    [this.state.lang]: {
                        ...this.state[this.state.lang],
                        Title: _result.result.Title[this.state.lang],
                        IntroText: _result.result.IntroText[this.state.lang],
                        Detail: _result.result.Detail[this.state.lang],
                        PublishDate: this.state.PublishDate,
                        PublishDown: this.state.PublishDown,
                        CoverImages: _result.result.Main.Info.IntroImage,
                    },
                    MainInfo: MainInfo,
                    Images: Images,
                    IsEnabled: this.state.IsEnabled,
                    isLoadingDetail: false

                }, () => {
                    console.log(this.state.Images)
                })

        } else {
            console.log('Error : getStoryDetail ')
        }

    }


    async submitData() {
        if (this.state[this.state.lang].Title === "") {
            return alert('กรุณาใส่ชื่อ')
        }
        // if (this.state[this.state.lang].IntroText === "") {
        //     return alert('กรุณาใส่รายละเอียด')
        // }
        // if (this.state[this.state.lang].Address === "") {
        //     return alert('กรุณาใส่ที่อยู่')
        // }
        // if (this.state[this.state.lang].Province === "") {
        //     return alert('กรุณาเลือกจังหวัด')
        // }
        // if (this.state[this.state.lang].County === "") {
        //     return alert('กรุณาเลือก อำเภอ และ ตำบล')
        // }
        // if (this.state[this.state.lang].Latitude === "") {
        //     return alert('กรุณาใส่ พิกัดละติจูด')
        // }
        // if (this.state[this.state.lang].Longitude === "") {
        //     return alert('กรุณาใส่ พิกัดลองติจูด')
        // }
        // if (this.state[this.state.lang].District === "") {
        //     return alert('กรุณาเลือกตำบล')
        // }
        // if (this.state.Submit.IntroImage === undefined) {
        //     return alert('กรุณาใส่รูป')
        // }
        let IsEnabled = this.state.IsEnabled
        let Feedback = this.state.Feedback
        const userData = JSON.parse(localStorage.getItem('authUser'))
        if (userData.UserSubType === "Editor") {
            console.log('Editer >>>>')
            IsEnabled = "N"
            console.log(IsEnabled)
        }
        let obj = {
            IsEnabled: IsEnabled,
            Feedback: Feedback,
            // ID: userData.UserID,
            Main: {
                Info: {
                    ContentCategoryID: 1,
                    Type: this.state.Type,
                    PublishDate: this.state.PublishDate,
                    PublishDown: this.state.PublishDown,
                    // HashName: this.state.Submit.ImageUpload[0].HashName,
                    // Path: this.state.Submit.ImageUpload[0].Path,
                    IntroImage: this.state.Submit.IntroImage,
                },
                Details: {
                    [this.state.lang]: {
                        Lang: this.state.lang,
                        Title: this.state[this.state.lang].Title,
                        IntroText: this.state[this.state.lang].IntroText,
                        Detail: this.state[this.state.lang].Detail
                    },
                },
                Images: this.state.Submit.ImageUpload,
            },
            MainInfo: this.state.Submit.MainInfo,
        }
        //ปิดส่ง
        console.log(JSON.stringify(obj))
        // return
        const response = await fetch(
            api_endpoint + `/contentinfo/create`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (_result.status === "ok") {
            alert('เพิ่มเรื่องราวสำเร็จ')
            window.location.href = "/Story";
        }
        else {
            console.log('error : submitData')
        }
    }

    async updateData() {
        const userData = JSON.parse(localStorage.getItem('authUser'))
        let obj = {}
        if (this.state.lang === 'th') {
            obj = {
                ID: Number(this.state.placeId),
                IsEnabled: this.state.IsEnabled,
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        ContentCategoryID: 1,
                        Type: this.state.Type,
                        PublishDate: this.state.PublishDate,
                        PublishDown: this.state.PublishDown,
                        // HashName: this.state.Submit.IntroImage.HashName,
                        // Path: this.state.Submit.IntroImage.Path,
                        IntroImage: this.state.th.CoverImages !== this.state.th.CoverImages ? this.state.submit.IntroImage : this.state.th.CoverImages,
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Title: this.state[this.state.lang].Title,
                            IntroText: this.state[this.state.lang].IntroText,
                            Detail: this.state[this.state.lang].Detail
                        },
                    },
                    Images: this.state.Submit.ImageUpload,
                },
                MainInfo: this.state.Submit.MainInfo,
            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }
        }
        if (this.state.lang === 'en') {
            obj = {
                ID: Number(this.state.placeId),
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        ContentCategoryID: 1,
                        Type: this.state.Type,
                        PublishDate: this.state.PublishDate,
                        PublishDown: this.state.PublishDown,
                        // HashName: this.state.Submit.IntroImage.HashName,
                        // Path: this.state.Submit.IntroImage.Path,
                        // IntroImage: this.state.Submit.IntroImage,
                        // BackgroundImage: this.state.Submit.BackgroundImage,
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Title: this.state[this.state.lang].Title,
                            IntroText: this.state[this.state.lang].IntroText,
                            Detail: this.state[this.state.lang].Detail
                        },
                    },
                },
                // MainInfo: this.state.Submit.MainInfo,
            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }

        }
        if (this.state.lang === 'zh') {
            obj = {
                ID: Number(this.state.placeId),
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        ContentCategoryID: 1,
                        Type: this.state.Type,
                        PublishDate: this.state.PublishDate,
                        PublishDown: this.state.PublishDown,
                        // HashName: this.state.Submit.IntroImage.HashName,
                        // Path: this.state.Submit.IntroImage.Path,
                        // IntroImage: this.state.Submit.IntroImage,
                        // BackgroundImage: this.state.Submit.BackgroundImage,
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Title: this.state[this.state.lang].Title,
                            IntroText: this.state[this.state.lang].IntroText,
                            Detail: this.state[this.state.lang].Detail
                        },
                    },
                },
            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }
        }
        //ปิดส่ง
        console.log(JSON.stringify(obj))
        // return
        const response = await fetch(
            api_endpoint + `/contentinfo/update`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (_result.status === "ok") {
            alert('เพิ่มเรื่องราวสำเร็จ')
            // window.location.href = "/Story";

        }
        else {
            console.log('error : updateData')
        }
    }

    callback(value, target) {
        if (target === 'IsEnabled') {
            this.state.IsEnabled = value
            this.setState({
                IsEnabled: this.state.IsEnabled
            }, () => {
                console.log(this.state.IsEnabled)
            })
            return
        }
        if (target === 'Feedback') {
            this.setState({
                Feedback: value
            }, () => {
                console.log(this.state.IsEnabled)
            })
            return
        }
        if (target === 'PublishDate') {
            console.log(value)
            this.setState({
                PublishDate: value
            }, () => {
                console.log(this.state.PublishDate)
            })
            return
        }
        if (target === 'PublishDown') {
            this.setState({
                PublishDown: value
            }, () => {
                console.log(this.state.PublishDown)
            })
            return
        }
        let obj = this.state[this.state.lang]
        // console.log(this.state.lang)
        obj[target] = value;
        console.log(obj)
        this.setState({
            [this.state.lang]: obj
        })
    }

    submitForm(target, value) {
        if (this.state.Submit[target] === undefined) {
            this.state.Submit[target] = value
        }
        else {
            this.state.Submit[target] = value
        }
        console.log(this.state.Submit)
    }


    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const id = params.get('id');
        const status = params.get('status');
        if (id !== null) {
            console.log('in')
            this.setState({
                placeId: id,
                status: status,
                cloneId: id,
                isLoadingDetail: true
            }, () => {
                this.getStoryDetail(id)
            })
        }
        else {

        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.lang) {
            this.setState({
                lang: nextProps.lang
            }, () => {
                // console.log(this.state.lang)
            })
        }
    }
    render() {
        console.log(this.state.Images)
        // console.log(this.state.isLoading)
        // console.log(this.state.isLoadingDetail )

        if (this.state.isLoading === true || this.state.isLoadingDetail === true) {
            return (
                <div className="text-center" >
                    <FadeIn>
                        <div style={{ display: "flex", justifyContent: 'center' }}>
                            <div >
                                <Lottie options={defaultOptions} height={300} width={300} />
                                <div>
                                    <h4 style={{ color: 'black', padding: 30 }}>กำลังโหลด</h4>
                                </div>
                            </div>
                        </div>
                    </FadeIn>
                </div>
            )
        }
        else {
            const userData = JSON.parse(localStorage.getItem('authUser'))
            console.log(userData)
            console.log(this.state.status)
            return (

                <div>
                    <Form onSubmit={() => { this.test() }}>
                        <Card>
                            <CardBody>
                                <General callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} submit={this.submitForm} />
                            </CardBody>
                        </Card>
                    </Form>
                    <Card>
                        <CardBody>
                            <More callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <ImageGallery callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Images={this.state.Images} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Recommend lang={this.state.lang} submit={this.submitForm} mainInfo={this.state.MainInfo} />
                        </CardBody>
                    </Card>
                    {this.state.status === 'Y' ?
                        (userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ?
                            <Card>
                                <CardBody>
                                    <Feedback callback={this.callback} lang={this.state.lang} disabled={true} data={this.state} submit={this.submitForm} />
                                </CardBody>
                            </Card>
                            :
                            <Card>
                                <CardBody>
                                    <Feedback callback={this.callback} lang={this.state.lang} disabled={false} data={this.state} submit={this.submitForm} />
                                </CardBody>
                            </Card>
                        :
                        <div />
                    }
                    <div>
                        <Card>
                            <CardBody>
                                {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.placeId === null ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} data={this.state[this.state.lang]} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                                {this.state.placeId !== null && this.state.status !== 'Y' && (userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} data={this.state[this.state.lang]} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                                {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.status === 'Y' ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} data={this.state[this.state.lang]} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                                {this.state.placeId !== null && this.state.status !== 'Y' && userData.UserSubType !== 'Admin' && userData.UserSubType !== 'SuperAdmin' ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} data={this.state[this.state.lang]} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                                {/* < Status /> */}
                                {this.state.placeId === null ?
                                    <Row style={{ justifyContent: 'flex-end' }}>
                                        <div className="col-auto">
                                            <Button
                                                style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                                type="submit"
                                                onClick={() => { this.submitData() }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}

                                            </Button>
                                        </div>
                                        <div className="col-auto">
                                            <Link to="/Story">
                                                <Button
                                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                                </Button>
                                            </Link>
                                        </div>
                                    </Row>
                                    :

                                    <div>

                                    </div>
                                }
                                {this.state.placeId !== null && this.state.status !== 'Y' ?
                                    <Row style={{ justifyContent: 'flex-end' }}>
                                        <div className="col-auto">
                                            <Button
                                                style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                                type="submit"
                                                onClick={() => { this.updateData() }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb">แก้ไข</span>{" "}
                                            </Button>
                                        </div>
                                        <div className="col-auto">
                                            <Link to="/Story">
                                                <Button
                                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                                </Button>
                                            </Link>
                                        </div>
                                    </Row>
                                    :
                                    <div></div>
                                }
                                {this.state.status === 'Y' && userData.UserSubType === 'Editor' && userData.UserType === 'B' ?
                                    <div></div>
                                    :
                                    <div></div>

                                }
                            </CardBody>
                        </Card>
                    </div>




                    {/* <button onClick={() => { this.submitData() }}>Submit</button> */}

                </div>

            )
        }

    }
}
