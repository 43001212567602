import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,


} from "reactstrap"
export default class Target extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',
            Targets: this.props.Target,
            //Taget ซ้าย
            Family: false,
            Elderly: false,
            Working: false,
            Student: false,
            youth: false,
            Teenagers: false,
            Women: false,
            Muslim: false,
            Couples: false,
            MarriedCouples: false,
            //Taget ขวา
            Golf: false,
            Wedding: false,
            Health: false,
            Soft: false,
            Religious: false,
            MICE: false,
            Volunteer: false,
            Art: false,
            Lifestyle: false,

        }

    }
    componentDidMount() {
        console.log(this.state.Targets)
        let filter = this.state.Targets.filter(item => item.isCheck === true)
        for (let i = 0; i < filter.length; i++) {
            filter[i] = { AccommodationTargetID: filter[i].ID, }
        }
        this.props.submit('Targets', filter)


    }

    render() {
        return (
            <div>

                <Label className={'mb-3 text-24'}>กลุ่มเป้าหมาย</Label>
                {this.props.lang === 'th' ?
                    <Row>
                        <Col lg={6}>
                            <Label className={'mb-3 font-style-16 cl-black'}>กลุ่มเป้าหมายกระแสหลัก</Label>
                            {this.state.Targets.map((e, i) => {
                                if (e.AccommodationTargetGroupID === null) {
                                    return (
                                        <div key={i}>
                                            <div className="custom-control custom-checkbox mb-3"
                                                onClick={() => {
                                                    let arr = this.state.Targets
                                                    arr[i].isCheck = !arr[i].isCheck
                                                    this.setState({
                                                        Targets: arr
                                                    }, () => {
                                                        let filter = this.state.Targets.filter(item => item.isCheck === true)
                                                        for (let i = 0; i < filter.length; i++) {
                                                            filter[i] = { AccommodationTargetID: filter[i].ID }
                                                        }
                                                        this.props.submit('Targets', filter)
                                                    })
                                                }}>
                                                <input
                                                    key={i}
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={e.isCheck}
                                                />
                                                <label
                                                    className="custom-control-label font-style-16b4 cl-black"
                                                >
                                                    {e.Details[this.state.Lang].Name}
                                                </label>
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                    </Row>
                    :
                    <FormGroup className="font-style-16 cl-black">
                        <p style={{ color: '#173865' }}>
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </FormGroup>
                }
            </div>
        )
    }
}
