import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap"

class Breadcrumbs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      placeId: null,
      status: null
    }
  }
  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    const status = params.get('status');
    this.setState({
      placeId: id,
      status: status,
    }, () => {
      // console.log(this.state.placeId)
      // console.log(this.state.status)
    })
  }
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs="12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              {/* <Row>
                <h4 className="mb-0 font-size-18">{this.props.title} / {this.props.breadcrumbItem}</h4> <h4>   / {this.props.breadcrumbItems}</h4>
              </Row> */}
              <div className="page-title-right">
                <Breadcrumb listClassName="m-0">
                  <BreadcrumbItem>
                    <Link to='/dashboard-blog' className="font-style-14">{this.props.title}</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to={this.props.link1} className="font-style-14 cl-black">{this.props.breadcrumbItem}</Link>
                  </BreadcrumbItem>
                  {this.props.breadcrumbItems === undefined ||
                    <BreadcrumbItem active>
                      <Link to={this.state.placeId !== null ? this.props.link2 + this.state.placeId : this.props.link2} className="font-style-14">{this.props.breadcrumbItems}</Link>
                    </BreadcrumbItem>}
                </Breadcrumb>
              </div>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

Breadcrumbs.propTypes = {
  breadcrumbItem: PropTypes.string,
  breadcrumbItems: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumbs
