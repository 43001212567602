import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormEditors from '../component/FormEditorsContent'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
export default class More extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
        console.log('Detail =>>>>>>', this.props.data)
    }


    render() {
        return (
            <div>
                <FormEditors details={this.props.data.Detail} callback={this.props.callback} />
            </div>
        )
    }
}
