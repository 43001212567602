import React, { Component } from "react"
import { Card, CardBody, CardSubtitle, CardTitle, Col, Row } from "reactstrap"
import PropTypes from 'prop-types'

import ReactApexChart from "react-apexcharts"
import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react"
import { connect } from "react-redux"
import map from "./MapsGoogle"
import MapsGoogle from "./MapsGoogle"
const LoadingContainer = () => <div>Loading...</div>

export default class Cards extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    }
    this.onMarkerClick = this.onMarkerClick.bind(this)
  }
  onMarkerClick() {
    alert("You clicked in this marker")
  }
  render() {
    return (
      <React.Fragment>
        <Col xl={8}>
          <Row>
            <Col lg={4}>
              <Card className="mini-stats-wid" >
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="mr-3">
                      <p className="text-muted mb-2">สถานที่ทั้งหมด</p>
                      <h5 className="mb-0">50,004</h5>
                    </div>

                    <div className="avatar-sm ml-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className="bx bxs-book-bookmark"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="blog-stats-wid">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="mr-3">
                      <p className="text-muted mb-2">ผู้นำเที่ยวทั้งหมด</p>
                      <h5 className="mb-0">50,004</h5>
                    </div>

                    <div className="avatar-sm ml-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className="bx bxs-note"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="blog-stats-wid">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="mr-3">
                      <p className="text-muted mb-2">ผู้ประกอบการธุรกิจ</p>
                      <h5 className="mb-0">50,004</h5>
                    </div>

                    <div className="avatar-sm ml-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className="bx bxs-message-square-dots"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <MapsGoogle height={382} /> */}







        </Col>
      </React.Fragment>
    )
  }
}

