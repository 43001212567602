import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"

export default class Utilities extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',
            Utility: this.props.Utility,
            //part1
            Incinerator: false,
            Landfill: false,
            Recyclewaste: false,
            Garbagesorting: false,
            Other1: false,
            //part2
            Electricgenerator: false,
            Electricity: false,
            Other2: false,
            //part3
            Undergroundwater: false,
            Watersystem: false,
            Naturalwater: false,
            plumbing: false,
            Other3: false,
            //part4
            CCTV: false,
            Emergencyexit: false,
            Fireextinguishers: false,
            Securityguard: false,
            FirstAidUnitsample: false,
            FirstAidUnit: false,
            Alarm: false,
            Other4: false,
            //part5
            Wifisignal: false,
            Publicphone: false,
            Telephonesignal: false,
            Internetsignal: false,
            Postoffice: false,
            Other5: false,
            //part6
            Wastewatertreatment: false,
        }
        // console.log(this.state.Utility)
    }

    componentDidMount() {
        let data = []
        let filter = this.state.Utility.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
        filter.map((e) => {
            e.forEach(element => { if (element.isCheck === true) { data.push({ AttractionUtilityTypeID: element.AttractionUtilityTypeID, AttractionUtilitySubTypeID: element.ID }) } });
        })
        this.props.submit('Utility', data)
    }

    render() {
        return (
            <div>

                <Label className={'text-24'}>สาธารณูปโภค</Label>
                {this.props.lang === 'th' &&
                    <Row className="font-style-16 cl-black">
                        <Col lg={6}>
                            <Label>ระบบกำจัดขยะ</Label>
                            {this.state.Utility.map((e, i) => {
                                if (e.ID === 2) {
                                    return (
                                        <div key={i}>
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Utility
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Utility: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Utility.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AttractionUtilityTypeID: element.AttractionUtilityTypeID, AttractionUtilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Utility', data)
                                                            })
                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4"
                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                        <Col lg={6}>
                            <Label className={'mt-3'}>ระบบไฟฟ้า</Label>
                            {this.state.Utility.map((e, i) => {
                                if (e.ID === 3) {
                                    return (
                                        <div key={i}>
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Utility
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Utility: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Utility.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AttractionUtilityTypeID: element.AttractionUtilityTypeID, AttractionUtilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Utility', data)
                                                            })
                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4"

                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                        <Col lg={6}>
                            <Label className={'mt-3'}>ระบบน้ำใช้</Label>
                            {this.state.Utility.map((e, i) => {
                                if (e.ID === 4) {
                                    return (
                                        <div key={i}>
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Utility
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Utility: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Utility.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AttractionUtilityTypeID: element.AttractionUtilityTypeID, AttractionUtilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Utility', data)
                                                            })
                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4"
                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                        <Col lg={6}>
                            <Label className={'mt-3'}>ระบบรักษาความปลอดภัย</Label>
                            {this.state.Utility.map((e, i) => {
                                if (e.ID === 5) {
                                    return (
                                        <div key={i}>
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Utility
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Utility: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Utility.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AttractionUtilityTypeID: element.AttractionUtilityTypeID, AttractionUtilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Utility', data)
                                                            })
                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4"
                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                        <Col lg={6}>
                            <Label className={'mt-3'}>ระบบการสื่อสาร</Label>
                            {this.state.Utility.map((e, i) => {
                                if (e.ID === 6) {
                                    return (
                                        <div key={i}>
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Utility
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Utility: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Utility.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AttractionUtilityTypeID: element.AttractionUtilityTypeID, AttractionUtilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Utility', data)
                                                            })
                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4"
                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                        <Col lg={6}>
                            <Label className={'mt-3'}>ระบบบำบัดน้ำเสีย</Label>
                            {this.state.Utility.map((e, i) => {
                                if (e.ID === 7) {
                                    return (
                                        <div key={i}>
                                            {e.SubTypes.map((j, k) => {
                                                return (
                                                    <div className="custom-control custom-checkbox mb-3"
                                                        onClick={() => {
                                                            let arr = this.state.Utility
                                                            arr[i].SubTypes[k].isCheck = !arr[i].SubTypes[k].isCheck
                                                            this.setState({
                                                                Utility: arr
                                                            }, () => {
                                                                let data = []
                                                                let filter = this.state.Utility.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
                                                                filter.map((e) => {
                                                                    e.forEach(element => { if (element.isCheck === true) { data.push({ AttractionUtilityTypeID: element.AttractionUtilityTypeID, AttractionUtilitySubTypeID: element.ID }) } });
                                                                })
                                                                this.props.submit('Utility', data)
                                                            })
                                                        }}>
                                                        <input
                                                            key={k}
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            checked={j.isCheck}
                                                        />
                                                        <label
                                                            className="custom-control-label font-style-16b4"
                                                        >
                                                            {j.Details[this.state.Lang].Name}
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                }
                                else {
                                    return null
                                }
                            })}
                        </Col>
                    </Row>
                }
                {this.props.lang === 'en' &&
                    <FormGroup className="font-style-16 cl-black">
                        <p style={{ color: '#173865' }}>
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </FormGroup>
                }
                {this.props.lang === 'zh' &&
                    <FormGroup className="font-style-16 cl-black">
                        <p style={{ color: '#173865' }}>
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </FormGroup>
                }
            </div>
        )
    }
}
