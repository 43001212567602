import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"

export default class Menu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',

        }

    }



    render() {
        return (
            <div>
                <p className={'text-24'}>เมนูอาหาร</p>
                <Row >
                    <Col lg="12">
                        <CardTitle>เมนูอาหาร</CardTitle>

                        <div className="custom-file">
                            <input
                                type="file"
                                className="custom-file-input"
                                id="customFile"
                            />
                            <label className="custom-file-label" htmlFor="customFile">
                                Choose file
                            </label>
                            <p className="mt-3" style={{ color: '#173865' }}>ขนาดไฟล์ไม่เกิน 2 MB, เฉพาะไฟล์นามสกุล: .jpg, .jpeg, .gif, .png และขนาดไฟล์ที่เหมาะสม: 800 x 600 pixel</p>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
