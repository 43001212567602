import React, { Component } from "react"
import PropTypes from 'prop-types'

import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react"
import { connect } from "react-redux"
import { Card, CardBody, CardSubtitle, CardTitle, Col, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const LoadingContainer = () => <div>Loading...</div>



class MapsGoogle extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    }

  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };



  render() {

    return (
      <React.Fragment>
        <Row>
          <Col lg={12}>
            <div
              id="gmaps-markers"
              className="gmaps"
              style={{ position: "relative" }}
              onClick={e => console.log(e)}
            >

              <Map
                style={{ height: this.props.height }}
                onClick={this.onMapClicked}
                google={this.props.google}
                zoom={14}
              >

                <Marker onClick={this.onMarkerClick}
                  name={'Current location'} />
                <InfoWindow
                  marker={this.state.activeMarker}
                  visible={this.state.showingInfoWindow}>
                  <div>
                    <h1>{this.state.selectedPlace.name}</h1>
                  </div>
                </InfoWindow>
              </Map>
            </div>

          </Col>
        </Row>

      </React.Fragment>
    )
  }
}

MapsGoogle.propTypes = {
  google: PropTypes.object
}

export default connect(
  null,
  {}
)(
  GoogleApiWrapper({
    apiKey: 'AIzaSyD0AIarEA25PY-NvHNsZDAVqM3qa8krHfs',
    LoadingContainer: LoadingContainer,
    v: "3",
  })(MapsGoogle)
)
