import React, { Component } from "react"
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"




class mainpage extends Component {
  constructor(props) {
    super(props)
    this.state = {


    }

  }

  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs breadcrumbItem="หน้าแรก" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>

                  </CardBody>
                </Card>
              </Col>
            </Row>






          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default mainpage
