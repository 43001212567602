import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"

class LineBar extends Component {
  getOption = () => {
    return {
      // title: {
      //   text: 'Stacked Area Chart'
      // },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        data: ['แหล่งท่องเที่ยว', 'ร้านอาหาร', 'สปา', 'ร้านค้า', 'ผู้ประกอบธุรกิจนำเที่ยว', 'กิจกรรมท่องเที่ยว', 'ที่พัก'],
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { readOnly: false },
          // magicType: { type: ['line', 'bar'] },
          // restore: {},
          saveAsImage: {}
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: ['แหล่งท่องเที่ยว', 'ร้านอาหาร', 'สปา', 'ร้านค้า', 'ผู้ประกอบธุรกิจนำเที่ยว', 'กิจกรรมท่องเที่ยว', 'ที่พัก'],
          axisLabel: {
            show: true, // แสดงชื่อ
            rotate: 45, // หมุนข้อความถ้าต้องการ
          },
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'แหล่งท่องเที่ยว',
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          name: 'ร้านอาหาร',
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: [220, 182, 191, 234, 290, 330, 310]
        },
        {
          name: 'สปา',
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: [150, 232, 201, 154, 190, 330, 410]
        },
        {
          name: 'ร้านค้า',
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: [320, 332, 301, 334, 390, 330, 320]
        },
        {
          name: 'ผู้ประกอบธุรกิจนำเที่ยว',
          type: 'line',
          stack: 'Total',
          label: {
            show: true,
            position: 'top'
          },
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: [820, 932, 901, 934, 1290, 1330, 1320]
        },
        {
          name: 'กิจกรรมท่องเที่ยว',
          type: 'line',
          stack: 'Total',
          label: {
            show: true,
            position: 'top'
          },
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: [820, 1000, 901, 934, 1290, 1330, 1320]
        },
        {
          name: 'ที่พัก',
          type: 'line',
          stack: 'Total',
          label: {
            show: true,
            position: 'top'
          },
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: [820, 2000, 901, 934, 1290, 1330, 1320]
        }
      ],
    }
  };
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "450px" }} option={this.getOption()} />
      </React.Fragment>
    )
  }
}
export default LineBar
