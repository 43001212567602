import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"


const animatedComponents = makeAnimated()




export default class Registration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',
            AgencyRegistration_types: this.props.AgencyRegistration_types,

            // LicenseDate: this.props.AgencyRegistrationinfo.LicenseDate,
            // LicenseExpireDate: this.props.AgencyRegistrationinfo.LicenseDate,
            Details: this.props.Details,

            idSelected: "",
        }
        console.log(this.state.Details)
    }
    selectedHandle(data, type) {
        console.log('sssssssssssssssssss')
        console.log(data)
        if (type === 'AgencyRegistration_types') {
            this.props.callback(JSON.parse(data).ID, 'AgencyRegistration_types')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'GuidePlace') {
            this.props.callback(data, 'GuidePlace')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'ServiceCharge') {
            this.props.callback(data, 'ServiceCharge')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'LicenseExpireDate') {
            this.props.callback(data, 'LicenseExpireDate')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }

    }



    render() {


        return (
            <div>
                <p className={'text-24'}>ข้อมูลการค่าบริการและสถานที่นำเที่ยว</p>
                <Row className={'mt-3 font-style-16 cl-black'}>
                    <Col lg="6">
                        <FormGroup>
                            <Label for="agencyName">ค่าบริการ</Label>
                            <Input required type="text" className="form-control" id="Price" placeholder="ระบุค่าบริการ (ไม่บังคับ)" value={this.props.Details.ServiceCharge !== undefined ? this.props.Details.ServiceCharge[this.state.Lang] : null} onChange={(e) => { this.props.callback(e.target.value, 'ServiceCharge') }} />
                        </FormGroup>
                    </Col>
                    <Col lg="6">
                        <FormGroup>
                            <Label for="agencyName">สถานที่นำเที่ยว</Label>
                            <Input required type="text" className="form-control" id="Place" placeholder="ระบุสถานที่นำเที่ยว (ไม่บังคับ)" value={this.props.Details.GuidePlace !== undefined ? this.props.Details.GuidePlace[this.state.Lang] : null} onChange={(e) => { this.props.callback(e.target.value, 'GuidePlace') }} />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        )
    }
}
