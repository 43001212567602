import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,


} from "reactstrap"
import Select from "react-select"
export default class Foodtypes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',
            FoodTypes: this.props.FoodTypes,
            costrates: this.props.costrates,
            seats: this.props.seats,
            restaurantInfo: this.props.restaurantInfo,
            idSelected: "",
        }
        console.log(this.props.data.HasDelivery)
        console.log(this.props.restaurantInfo)
    }

    selectedHandle(data, type) {
        console.log(data)
        if (type === 'costrates') {
            this.props.callback(JSON.parse(data).ID, 'costrates')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'seats') {
            this.props.callback(JSON.parse(data).ID, 'seats')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'HasParking') {
            this.props.callback(data, 'HasParking')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'HasPreOrder') {
            this.props.callback(data, 'HasPreOrder')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'HasDelivery') {
            this.props.callback(data, 'HasDelivery')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        // if (type === 'Districts') {
        //     this.props.callback(JSON.parse(data).Code, 'District')
        //     return
        // }
        // else {
        //     this.props.callback(JSON.parse(data).Code, 'County')
        //     return
        // }
    }




    componentWillReceiveProps(nextProps) {

        this.setState({
            costrates: nextProps.costrates,
            seats: nextProps.seats,
            // FoodTypes: nextProps.FoodTypes,


        })
    }

    componentDidMount() {
        console.log(this.state.FoodTypes)
        let filter = this.state.FoodTypes.filter(item => item.isCheck === true)
        for (let i = 0; i < filter.length; i++) {
            filter[i] = { RestaurantFoodTypeID: filter[i].ID }
        }
        this.props.submit('FoodTypes', filter)


    }

    render() {
        return (
            <div>

                <Label className={'mb-3 text-24'}>ข้อมูลร้านอาหาร</Label>
                {this.props.lang === 'th' &&
                    <Row>
                        <Col lg={6}>
                            <Label className={'mb-3 font-style-16 cl-black'}>ประเภท</Label>
                            {this.state.FoodTypes.map((e, i) => {
                                if (i <= 24) {
                                    return (
                                        <div key={i}>
                                            <div className="custom-control custom-checkbox mb-3"
                                                onClick={() => {
                                                    let arr = this.state.FoodTypes
                                                    arr[i].isCheck = !arr[i].isCheck
                                                    this.setState({
                                                        FoodTypes: arr
                                                    }, () => {
                                                        let filter = this.state.FoodTypes.filter(item => item.isCheck === true)
                                                        for (let i = 0; i < filter.length; i++) {
                                                            filter[i] = { RestaurantFoodTypeID: filter[i].ID }
                                                        }
                                                        this.props.submit('FoodTypes', filter)
                                                    })
                                                }}>
                                                <input
                                                    key={i}
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={e.isCheck}
                                                />
                                                <label
                                                    className="custom-control-label font-style-16b4 cl-black"
                                                >
                                                    {e.Details[this.state.Lang].Name}
                                                </label>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </Col>
                        <Col lg={6}>
                            {this.state.FoodTypes.map((e, i) => {
                                if (i > 24) {
                                    return (
                                        <div key={i}>
                                            <div className="custom-control custom-checkbox mb-3"
                                                onClick={() => {
                                                    let arr = this.state.FoodTypes
                                                    arr[i].isCheck = !arr[i].isCheck
                                                    this.setState({
                                                        FoodTypes: arr
                                                    }, () => {
                                                        let filter = this.state.FoodTypes.filter(item => item.isCheck === true)
                                                        for (let i = 0; i < filter.length; i++) {
                                                            filter[i] = { RestaurantFoodTypeID: filter[i].ID }
                                                        }
                                                        this.props.submit('FoodTypes', filter)
                                                    })
                                                }}>
                                                <input
                                                    key={i}
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    checked={e.isCheck}
                                                />
                                                <label
                                                    className="custom-control-label font-style-16b4 cl-black"
                                                >
                                                    {e.Details[this.state.Lang].Name}
                                                </label>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </Col>

                        <Col lg="6" className="font-style-16 cl-black">
                            <FormGroup>
                                <Label for="Province">ช่วงราคา </Label><Label className="required"> *</Label>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <select className="form-control" onChange={(e) => {
                                            console.log(e.target)
                                            if (e.target.value === -1) {
                                                return
                                            }
                                            else {
                                                this.selectedHandle(e.target.value, 'costrates')
                                            }
                                        }} >
                                            {this.state.costrates.filter(item => item.isCheck === true).length < 1 ? <option value={-1}>กรุณาเลือกช่วงราคา</option> : null}
                                            {this.state.costrates.map((e, i) => {

                                                if (e.isCheck === true) {

                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                            {this.state.costrates.map((e, i) => {
                                                if (e.isCheck === false) {
                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col lg="6" className="font-style-16 cl-black">
                            <FormGroup>
                                <Label for="Province">จำนวนที่นั่ง </Label><Label className="required"> *</Label>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <select className="form-control" onChange={(e) => {
                                            if (e.target.value === -1) {
                                                return
                                            }
                                            else {
                                                this.selectedHandle(e.target.value, 'seats')
                                            }
                                        }} >
                                            {this.state.seats.filter(item => item.isCheck === true).length < 1 ? <option value={-1}>กรุณาเลือกจำนวนที่น่ั่ง</option> : null}
                                            {this.state.seats.map((e, i) => {
                                                if (e.isCheck === true) {
                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                            {this.state.seats.map((e, i) => {
                                                if (e.isCheck === false) {
                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </FormGroup>
                        </Col>


                        <Col lg={3} className={'mt-3 '}>
                            <h6 className="font-style-16 cl-black">ที่จอดรถ</h6>
                        </Col>
                        <Col lg={9} className={'mt-3 '}>
                            <Row>
                                <Col lg={4}>
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            id={'HasParking'}
                                            onChange={() => false}
                                            checked={this.state.restaurantInfo.HasParking === "N" ? true : false}

                                        />
                                        <label
                                            className="custom-control-label font-style-16 cl-black"
                                            onClick={() => {
                                                this.selectedHandle("N", 'HasParking')
                                            }}
                                        >
                                            ไม่มี
                                        </label>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            id={'HasParking'}
                                            onChange={() => false}
                                            checked={this.state.restaurantInfo.HasParking === "Y" ? true : false}
                                        />
                                        <label
                                            className="custom-control-label font-style-16 cl-black"
                                            onClick={() => {
                                                this.selectedHandle("Y", 'HasParking')

                                            }}
                                        >
                                            มี
                                        </label>
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                        <Col lg={3} className={'mt-3 '}>
                            <h6 className="text-16">รับจองล่วงหน้า</h6>
                        </Col>
                        <Col lg={9} className={'mt-3 '}>
                            <Row>
                                <Col lg={4}>
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            id={'HasPreOrder'}
                                            onChange={() => false}
                                            checked={this.state.restaurantInfo.HasPreOrder === "N" ? true : false}

                                        />
                                        <label
                                            className="custom-control-label font-style-16 cl-black"

                                            onClick={() => {
                                                this.selectedHandle("N", 'HasPreOrder')
                                            }}
                                        >
                                            ไม่มี
                                        </label>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            id={'HasPreOrder'}
                                            onChange={() => false}
                                            checked={this.state.restaurantInfo.HasPreOrder === "Y" ? true : false}
                                        />
                                        <label
                                            className="custom-control-label font-style-16 cl-black"
                                            onClick={() => {
                                                this.selectedHandle("Y", 'HasPreOrder')

                                            }}
                                        >
                                            มี
                                        </label>
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                        <Col lg={3} className={'mt-3 '}>
                            <h6 className="font-style-16 cl-black">มีบริการเดลิเวอรี่</h6>
                        </Col>
                        <Col lg={9} className={'mt-3 '}>
                            <Row>
                                <Col lg={4}>
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            id={'HasDelivery'}
                                            onChange={() => false}
                                            checked={this.state.restaurantInfo.HasDelivery === "N" ? true : false}

                                        />
                                        <label
                                            className="custom-control-label font-style-16 cl-black"
                                            onClick={() => {
                                                this.selectedHandle("N", 'HasDelivery')
                                            }}
                                        >
                                            ไม่มี
                                        </label>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="custom-control custom-radio mb-3">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            id={'HasDelivery'}
                                            onChange={() => false}
                                            checked={this.state.restaurantInfo.HasDelivery === "Y" ? true : false}
                                        />
                                        <label
                                            className="custom-control-label font-style-16 cl-black"
                                            onClick={() => {
                                                this.selectedHandle("Y", 'HasDelivery')

                                            }}
                                        >
                                            มี
                                        </label>
                                    </div>
                                </Col>

                            </Row>
                        </Col>

                    </Row>

                }
                {this.props.lang === 'en' &&
                    <FormGroup className="font-style-16 cl-black">
                        <p style={{ color: '#173865' }}>
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </FormGroup>
                }
                {this.props.lang === 'zh' &&
                    <FormGroup className="font-style-16 cl-black">
                        <p style={{ color: '#173865' }}>
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </FormGroup>
                }
            </div>
        )
    }
}
