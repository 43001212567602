import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
  TabPane,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,

} from "reactstrap"
import { api_endpoint, endpoint } from '../../../../global-config'

export default class General extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Lang: 'th',
      Types: this.props.Types,
      StandardTypes: this.props.StandardTypes,
      Nature: false,
      Manmade: false,
      Sport: false,
      Event: false,
      upload_images_loading: false,
      placeType: []
    }
  }

  handleAcceptedFiles = async (files) => {
    if (files.length == 1) {
      let [file] = files
      console.log(file)
      var formData = new FormData(); // Currently empty
      formData.append('files', file, file.name);
      let upload_response = await fetch(api_endpoint + '/file/upload', {
        method: "POST",
        body: formData
      })
      let upload_response_object = await upload_response.json()
      if (upload_response_object.status == 'success') {
        console.log(upload_response_object.uploads[0])
        this.props.callback(upload_response_object.uploads[0], 'CoverImages')
        this.props.submit('IntroImage', upload_response_object.uploads[0])
      } else {
        alert('เกิดข้อผิดพลาดในการอัพโหลดไฟล์')
      }
    }
    this.setState({
      upload_images_loading: false
    })

  }

  typeSelected(value, target) {
    if (value) {
      this.state.placeType.push(target)
    }
    else {
      this.state.placeType.splice(this.state.placeType.indexOf(target), 1)
    }
    this.props.callback(this.state.placeType, 'Activities')
  }

  componentDidMount() {
    let data = []
    let filter = this.state.Types.map(e => { return e.SubTypes.filter(item => item.isCheck === true) })
    filter.map((e) => {
      e.forEach(element => { if (element.isCheck === true) { data.push({ AttractionTypeID: element.ID }) } });
    })
    this.props.submit('Types', data)
    
    
    let standardTypedata = []
    this.state.StandardTypes.forEach(element => { 
      if (element.isCheck === true) { 
        standardTypedata.push({ StandardAttractionTypeID: element.ID }) 
      } 
    });
    this.props.submit('StandardTypes', standardTypedata)
  }

  render() {
    console.log(this.props)
    return (
      <div>
        <p className={'text-24'}>ข้อมูลทั่วไป</p>
        {/* {this.props.lang === 'en' ?
                    <p className={'text-24'}>General</p>
                    :
                    <div></div>
                }
                {this.props.lang === 'zh' ?
                    <p className={'text-24'}>一般信息
                    </p>
                    :
                    <div></div>
                } */}
        <Row className={'mt-3'}>
          <Col className="font-style-16 cl-black" lg="12">
            {this.props.lang === 'th' &&
              <FormGroup>
                <Label for="Name">ชื่อ </Label><Label className="required"> *</Label>
                <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Name" placeholder="กรุณากรอกชื่อ" value={this.props.data.Name} onChange={(e) => { this.props.callback(e.target.value, 'Name') }} />
              </FormGroup>
            }
            {this.props.lang === 'en' &&
              <FormGroup>
                <Label for="Name">ชื่อ </Label><Label className="required"> *</Label>
                <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Name" placeholder="กรุณากรอกชื่อ" value={this.props.data.Name} onChange={(e) => { this.props.callback(e.target.value, 'Name') }} />
              </FormGroup>
            }
            {this.props.lang === 'zh' &&
              <FormGroup>
                <Label for="Name">ชื่อ </Label><Label className="required"> *</Label>
                <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Name" placeholder="กรุณากรอกชื่อ" value={this.props.data.Name} onChange={(e) => { this.props.callback(e.target.value, 'Name') }} />
              </FormGroup>
            }
          </Col>
        </Row>
        {this.props.lang === 'th' ?
          <Row className="font-style-16 cl-black">
            <Col lg={12}>
              <Label className="mt-3">ประเภทของสถานที่</Label>
            </Col>
            {this.state.StandardTypes.map((e, i) => (
              <Col lg={4}>
                <div key={i} className="custom-control custom-checkbox " style={{ marginTop: 5 }}
                  onClick={() => {
                    let arr = this.state.StandardTypes
                    arr[i].isCheck = !arr[i].isCheck
                    this.setState({
                      StandardTypes: arr
                    }, () => {
                      let data = []
                      this.state.StandardTypes.forEach(element => { 
                        if (element.isCheck === true) { 
                          data.push({ StandardAttractionTypeID: element.ID }) 
                        } 
                      });
                      this.props.submit('StandardTypes', data)
                    })
                  }}
                  >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={e.isCheck}
                  />
                  <label
                    className="custom-control-label font-style-16 cl-gray"
                  >
                    {e.Details[this.state.Lang].Name}
                  </label>
                </div>
              </Col>
            ))}
          </Row>
          :
          <div>
            <Label className="mt-3 font-style-16 cl-black">ประเภทของสถานที่</Label>
            <p className="font-style-16 cl-darkblue">
              (ให้จัดการข้อมูลที่ภาษาไทย)
            </p>
          </div>
        }
        {this.props.lang === 'th' &&
          <Row className={'mt-3'}>
            <Col lg="12">
              <Label className="font-style-16 cl-black">รายละเอียด </Label><Label className="required"> *</Label>
              <Input
                style={{ fontSize: 16 }}
                type="textarea"
                id="textarea"
                onChange={(e) => { this.props.callback(e.target.value, 'Details') }}
                // maxLength="225"
                rows="3"
                placeholder=""
                value={this.props.data.Details}
              />
              {this.state.textareabadge ? (
                <span className="badgecount badge badge-success">
                  {" "}
                  {this.state.textcount} / 225{" "}
                </span>
              ) : null}
            </Col>
          </Row>
        }
        {this.props.lang === 'en' &&
          <Row className={'mt-3'}>
            <Col lg="12">
              <Label className="font-style-16 cl-black">รายละเอียด </Label><Label className="required"> *</Label>
              <Input
                style={{ fontSize: 16 }}
                type="textarea"
                id="textarea"
                onChange={(e) => { this.props.callback(e.target.value, 'Details') }}
                // maxLength="225"
                rows="3"
                placeholder=""
                value={this.props.data.Details}
              />
              {this.state.textareabadge ? (
                <span className="badgecount badge badge-success">
                  {" "}
                  {this.state.textcount} / 225{" "}
                </span>
              ) : null}
            </Col>
          </Row>
        }
        {this.props.lang === 'zh' &&
          <Row className={'mt-3'}>
            <Col lg="12">
              <Label className="font-style-16 cl-black">รายละเอียด </Label><Label className="required"> *</Label>
              <Input
                style={{ fontSize: 16 }}
                type="textarea"
                id="textarea"
                onChange={(e) => { this.props.callback(e.target.value, 'Details') }}
                // maxLength="225"
                rows="3"
                placeholder=""
                value={this.props.data.Details}
              />
              {this.state.textareabadge ? (
                <span className="badgecount badge badge-success">
                  {" "}
                  {this.state.textcount} / 225{" "}
                </span>
              ) : null}
            </Col>
          </Row>
        }
        {this.props.lang === 'th' ?
          <Row className={'mt-3 text-16'}>
            <Col lg="12">
              <Label className="font-style-16 cl-black">รูปภาพหน้าปก {this.props.data.test} </Label><Label className="required"> * {this.state.upload_images_loading && (<font color="green">โปรดรอกำลังอัพโหลดรูปภาพ</font>)}</Label>
              <div style={{ display: typeof this.props.data.CoverImages.HashName != 'undefined' ? 'none' : '' }} className="custom-file" >
                <input
                  onChange={e => {
                    this.setState({
                      upload_images_loading: true
                    }, () => {
                      this.handleAcceptedFiles(e.target.files)
                      e.target.value = ""
                    }
                    )
                  }}
                  accept=".jpeg,.gif,.png,.jpg"
                  type="file"
                  className="custom-file-input"
                  id="customFile"
                />
                <label className="custom-file-label" htmlFor="customFile">
                  <span className="font-style-16b4 cl-gray"> Choose file</span>
                  {/* {this.state.selectedFiles.length < 1 ?
                                     <span> Choose file</span>
                                     :
                                     <div>
                                         {this.state.selectedFiles.map(f => {
                                             return (<span style={{ marginRight: 10 }}>{f.name}</span>)
                                         })}
                                     </div>
                                 } */}
                </label>
                <p className="mt-3 font-style-14 cl-darkblue">ขนาดไฟล์ไม่เกิน 2 MB, เฉพาะไฟล์นามสกุล: .jpg, .jpeg, .gif, .png และขนาดไฟล์ที่เหมาะสม: 800 x 600 pixel</p>
              </div>
              {typeof this.props.data.CoverImages.HashName != 'undefined' && (
                <div>
                  <div style={{ textAlign: 'center' }}>
                    <img style={{ maxWidth: 350 }} src={`${endpoint}/assets/upload${this.props.data.CoverImages.Path}/${this.props.data.CoverImages.HashName}`}></img>
                    <div style={{ marginTop: 25 }}>
                      <button onClick={() => {
                        this.props.callback({}, 'CoverImages')
                        this.props.submit('IntroImage', {})
                      }} type="button" class="btn btn-danger waves-effect waves-light">ยกเลิกไฟล์</button>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          :
          <Row className={'mt-3'}>
            <Col lg="12">
              <Label className="font-style-16 cl-black">รูปภาพหน้าปก </Label><Label className="required"> *</Label>
              <p className="font-style-16" style={{ color: '#173865' }}>
                (ให้จัดการข้อมูลที่ภาษาไทย)
              </p>
            </Col>
          </Row>
        }
        {this.props.lang === 'th' &&
          <Row className={'mt-3'}>
            <Col lg="6">
              <Label className="font-style-16 cl-black">ลักษณะเด่น</Label>
              <Input
                style={{ fontSize: 16 }}
                type="textarea"
                id="textarea"
                onChange={(e) => { this.props.callback(e.target.value, 'Feature') }}
                // maxLength="225"
                rows="3"
                placeholder=""
                value={this.props.data.Feature}
              />
              {this.state.textareabadge ? (
                <span className="badgecount badge badge-success">
                  {" "}
                  {this.state.textcount} / 225{" "}
                </span>
              ) : null}
            </Col>
            <Col lg="6">
              <Label className="font-style-16 cl-black">ประวัติความเป็นมา</Label>
              <Input
                style={{ fontSize: 16 }}
                type="textarea"
                id="textarea"
                onChange={(e) => { this.props.callback(e.target.value, 'History') }}
                // maxLength="225"
                rows="3"
                placeholder=""
                value={this.props.data.History}
              />
              {this.state.textareabadge ? (
                <span className="badgecount badge badge-success">
                  {" "}
                  {this.state.textcount} / 225{" "}
                </span>
              ) : null}
            </Col>
          </Row>
        }
        {this.props.lang === 'en' &&
          <Row className={'mt-3'}>
            <Col lg="6">
              <Label className="font-style-16 cl-black">ลักษณะเด่น</Label>
              <Input
                style={{ fontSize: 16 }}
                type="textarea"
                id="textarea"
                onChange={(e) => { this.props.callback(e.target.value, 'Feature') }}
                // maxLength="225"
                rows="3"
                placeholder=""
                value={this.props.data.Feature}
              />
              {this.state.textareabadge ? (
                <span className="badgecount badge badge-success">
                  {" "}
                  {this.state.textcount} / 225{" "}
                </span>
              ) : null}
            </Col>
            <Col lg="6">
              <Label className="font-style-16 cl-black">ประวัติความเป็นมา</Label>
              <Input
                style={{ fontSize: 16 }}
                type="textarea"
                id="textarea"
                onChange={(e) => { this.props.callback(e.target.value, 'History') }}
                // maxLength="225"
                rows="3"
                placeholder=""
                value={this.props.data.History}
              />
              {this.state.textareabadge ? (
                <span className="badgecount badge badge-success">
                  {" "}
                  {this.state.textcount} / 225{" "}
                </span>
              ) : null}
            </Col>
          </Row>
        }
        {this.props.lang === 'zh' &&
          <Row className={'mt-3'}>
            <Col lg="6">
              <Label className="font-style-16 cl-black">ลักษณะเด่น</Label>
              <Input
                style={{ fontSize: 16 }}
                type="textarea"
                id="textarea"
                onChange={(e) => { this.props.callback(e.target.value, 'Feature') }}
                // maxLength="225"
                rows="3"
                placeholder=""
                value={this.props.data.Feature}
              />
              {this.state.textareabadge ? (
                <span className="badgecount badge badge-success">
                  {" "}
                  {this.state.textcount} / 225{" "}
                </span>
              ) : null}
            </Col>
            <Col lg="6">
              <Label className="font-style-16 cl-black">ประวัติความเป็นมา</Label>
              <Input
                style={{ fontSize: 16 }}
                type="textarea"
                id="textarea"
                onChange={(e) => { this.props.callback(e.target.value, 'History') }}
                // maxLength="225"
                rows="3"
                placeholder=""
                value={this.props.data.History}
              />
              {this.state.textareabadge ? (
                <span className="badgecount badge badge-success">
                  {" "}
                  {this.state.textcount} / 225{" "}
                </span>
              ) : null}
            </Col>
          </Row>
        }
        <Row className={'mt-3 text-16'}>
          <Col lg="6">
            <FormGroup>
              <Label for="AreaSize">ขนาดพื้นที่ (ไร่)</Label>
              <Input style={{ fontSize: 16 }} type="text" className="form-control" id="AreaSize" placeholder="กรุณากรอกขนาดพื้นที่" value={this.props.data.AreaSize} onChange={(e) => { this.props.callback(e.target.value, 'AreaSize') }} />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label for="ParkingDetail">ข้อมูลที่จอดรถ</Label>
              <Input style={{ fontSize: 16 }} type="text" className="form-control" id="ParkingDetail" placeholder="กรุณากรอกข้อมูลที่จอดรถ" value={this.props.data.ParkingDetail} onChange={(e) => { this.props.callback(e.target.value, 'ParkingDetail') }} />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label for="ResponseDepartment">หน่วยงานที่รับผิดชอบ</Label>
              <Input style={{ fontSize: 16 }} type="text" className="form-control" id="ResponseDepartment" placeholder="กรุณากรอกหน่วยงานที่รับผิดชอบ" value={this.props.data.ResponseDepartment} onChange={(e) => { this.props.callback(e.target.value, 'ResponseDepartment') }} />
            </FormGroup>
          </Col>
        </Row>
      </div>
    )
  }
}
