import React, { Component } from "react"
import PropTypes from 'prop-types'

import { Alert, Card, CardBody, Col, Container, Row, FormGroup, Input, Label } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

//Import config
import { facebook, google } from "../../config"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// actions
import { apiError, loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo-sm-light.png"
import Check from '../../assets/images/ttd/check.svg'
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {

      resetstatus: false,
      submitData: {
        UserID: "",
        EditorTeam: {
          EditorTeamID: "",
          MemberLevel: "Editor",
          MemberType: "official",
          MemberTypeOther: null,
          IsEnable: "Y"
        },
        User: {
          Username: "",
          TitleName: "",
          FirstName: "",
          LastName: "",
          Telephone: "",
          Mobile: "",
          Email: ""
        }

      }
    }

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.loginUser(values, this.props.history)
  }

  componentDidMount() {
    this.props.apiError("")
  }

  Resetpasswordchange() {
    this.setState({
      resetstatus: true,

    })
  }



  render() {
    return (
      <React.Fragment>
        <div className="auth-full-bg">
          <div className="bg-overlay">
            <div className="my-5 pt-sm-5">

              <Container >
                <Row className="justify-content-center">

                  <Col md={8} lg={6} xl={5}>

                    <Card className="overflow-hidden" style={{ minHeight: '100%' }}>
                      <div >
                        <Row>
                          <Col className="col-12">
                            <div className="text-primary p-4 ">
                              <h5 className="text-24 text-center" style={{ color: 'black' }}>เปลี่ยนรหัสผ่าน</h5>

                            </div>
                          </Col>

                        </Row>
                      </div>
                      {this.state.resetstatus === false && (
                        <div>
                          <CardBody className="pt-0">

                            <div className="p-2">
                              <FormGroup>
                                <Label for="Name">รหัสผ่านใหม่ </Label><Label className="required"> *</Label>
                                <Input type="text" className="form-control" id="passwordCheck" placeholder="กรุณากรอกรหัสผ่านใหม่" value={this.state.passwordCheck === null ? "" : this.state.passwordCheck} onChange={(e) => { this.inpuntHandle(e.target.value, "PasswordCheck") }} />
                                {/* <p style={{ color: '#808080' }}>Username จะไม่สามารถเปลี่ยนแปลงได้ในภายหลัง</p> */}
                              </FormGroup>
                              <FormGroup>
                                <Label for="Name">ยืนยันรหัสผ่านใหม่  </Label><Label className="required"> *</Label>
                                <Input type="text" className="form-control" id="Password" placeholder="กรุณากรอกรหัสผ่านใหม่" value={this.state.submitData.User.Password !== undefined ? this.state.submitData.User.Password : ""} onChange={(e) => { this.inpuntHandle(e.target.value, "Password") }} />
                                {/* <p style={{ color: '#808080' }}>Username จะไม่สามารถเปลี่ยนแปลงได้ในภายหลัง</p> */}
                              </FormGroup>

                            </div>
                            <div className="p-2" >
                              <div className="mt-3">
                                <button onClick={() => { this.Resetpasswordchange() }}
                                  style={{ backgroundColor: '#173865', borderColor: '#173865' }}
                                  className="btn btn-primary btn-block waves-effect waves-light"
                                  type="submit"
                                >
                                  ยืนยันรหัสผ่านใหม่
                                </button>
                              </div>

                            </div>




                          </CardBody>
                        </div>
                      )}
                      {this.state.resetstatus === true && (
                        <div>
                          <CardBody className="pt-0" style={{ alignContent: 'center', alignSelf: 'center', textAlign: 'center' }}>

                            <div className="p-2">
                              <img style={{ width: '10vw', textAlign: 'center' }} src={Check}></img>
                            </div>
                            <Row className="p-3">

                              <div className="justify-content-center text-center" >
                                <Label for="Name" className='text-16-bold' style={{ color: "#173865" }} >เปลี่ยนรหัสผ่านสำเร็จ</Label>

                              </div>
                              <Label for="Name" className='text-14-bold' style={{ color: "#173865" }} >ระบบจะพาคุณไปหน้า เข้าสู่ระบบ อัตโนมัติหากไม่มีการตอบสนองสามารถคลิกได้จากด้านล่าง</Label>


                            </Row>


                          </CardBody>
                        </div>
                      )}
                      <Row className="p-3">
                        <Col className="col-12">
                          <div className="justify-content-center text-center" >
                            <hr></hr>
                            <Label for="Name" className='text-16-bold' style={{ color: "#173865" }} >เข้าสู่หน้าหลักของเว็ปไซต์</Label>
                          </div>
                        </Col>

                      </Row>




                    </Card>


                    <div className="mt-5 text-center">

                      <p style={{ color: "white" }}>
                        © {new Date().getFullYear()} Thailand Tourism Directory.

                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>




      </React.Fragment>
    )
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)
