import React, { Component } from "react"
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
  TabPane,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import logoplus from "../../assets/images/ttd/Vector.svg"
import logodown from "../../assets/images/ttd/donw.png"
import Map from "../Dashboard-blog/MapsGoogle"
import ManaggeTap1 from './component/Managetap1'
import company from '../../assets/images/ttd/Company.svg'
import "../../assets/scss/Customttd.scss"




class managelocationDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedGroup: null,
      activeTabJustify: "5",
      lang: 'th',
      Thaipage: true,
      Engpage: false,
      Chinapage: false,
      placeId: null,
      status: null
    }
    // console.log(this.props)
    this.toggleCustomJustified = this.toggleCustomJustified.bind(this)
    this.Thaipage = this.Thaipage.bind(this);
    this.Engpage = this.Engpage.bind(this);
    this.Chinapage = this.Chinapage.bind(this);
  }

  Thaipage() {

    this.setState({
      Thaipage: true,
      Engpage: false,
      Chinapage: false,
    })
  }

  Engpage() {

    this.setState({
      Engpage: true,
      Thaipage: false,
      Chinapage: false,
    })
  }

  Chinapage() {

    this.setState({
      Chinapage: true,
      Engpage: false,
      Thaipage: false,

    })
  }

  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }

  toggleCustomJustified(tab) {
    if (this.state.activeTabJustify !== tab) {
      this.setState({
        activeTabJustify: tab,
      })
    }
  }


  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    const status = params.get('status');
    this.setState({
      placeId: id,
      status: status,
    }, () => {
      // console.log(this.state.placeId)
      // console.log(this.state.status)
    })
  }


  render() {
    const { selectedGroup } = this.state
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="หน้าแรก" link0="/dashboard-blog" breadcrumbItem="แหล่งท่องเที่ยว" link1="/managelocation" breadcrumbItems={this.state.placeId !== null ? "รายละเอียดแหล่งท่องเที่ยว" : 'เพิ่มแหล่งท่องเที่ยว'} link2={this.state.placeId !== null ? "/managelocationDetail?id=" : "/managelocationDetail"} />
            <Card>
              <CardBody>
                <Row style={{ padding: 15 }}>
                  <div style={{ alignSelf: 'center', width: '70%' }}>
                    <h1 className="text-24" style={{ color: '#173865' }}>{this.state.placeId !== null ? "รายละเอียดแหล่งท่องเที่ยว" : 'เพิ่มแหล่งท่องเที่ยว'}</h1>
                    <CardSubtitle className="text-16">{this.state.placeId !== null ? "กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนดำเนินการต่อไป" : 'การกรอกข้อมูลให้ละเอียดและถูกต้องจะทำให้ผู้ใช้งานเว็บคนอื่นๆหาสถานที่ของคุณเจอได้ง่ายขึ้น'} </CardSubtitle>
                  </div>
                  <div style={{ textAlign: 'center', width: '30%' }}>
                    <img style={{ width: '15vw' }} src={company}></img>
                  </div>




                </Row>

                <hr></hr>
                <Row>
                  <Col lg={4} style={{ cursor: 'pointer' }}>
                    <div style={{ textAlign: 'center' }}>
                      <h3 className="text-18" style={{ color: this.state.Thaipage == true ? '#173865' : '#808080' }} onClick={() => { this.Thaipage() }}>ภาษาไทย</h3>
                      {this.state.Thaipage == true && <div class="hr-line"></div>}
                    </div>

                  </Col>
                  <Col lg={4} style={{ cursor: 'pointer' }}>

                    <div style={{ textAlign: 'center' }}>
                      <h3 className="text-18" style={{ color: this.state.Engpage == true ? '#173865' : '#808080' }} onClick={() => { this.Engpage() }}>English</h3>
                      {this.state.Engpage == true && <div class="hr-line"></div>}
                    </div>


                  </Col>
                  <Col lg={4} style={{ cursor: 'pointer' }}>
                    <div style={{ textAlign: 'center' }}>
                      <h3 className="text-18" style={{ color: this.state.Chinapage == true ? '#173865' : '#808080' }} onClick={() => { this.Chinapage() }}>简体中文</h3>
                      {this.state.Chinapage == true && <div class="hr-line"></div>}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {this.state.Thaipage === true && (

              <ManaggeTap1 lang={this.state.lang} />
            )}
            {this.state.Engpage === true && (
              <ManaggeTap1 lang={'en'} />
            )}
            {this.state.Chinapage === true && (
              <ManaggeTap1 lang={'zh'} />
              // <ManaggeTap1 lang={'zh'} />
            )}
            {/* <Row>
              <Col lg="12" >
                <Card>
                  <CardBody className="border-bottom">
                    <Row>
                      <Col style={{ alignSelf: 'center' }}>
                        <h1 className="text-24" style={{ color: '#173865' }}>เพิ่มแหล่งท่องเที่ยว</h1>
                        <CardSubtitle className="text-16">การกรอกข้อมูลให้ละเอียดและถูกต้องจะทำให้ผู้ใช้งานเว็บคนอื่นๆหาสถานที่ของคุณเจอได้ง่ายขึ้น </CardSubtitle>
                      </Col>
                      <img src={company}></img>
                    </Row>
                  </CardBody>
                  <CardBody className="pb-auto">

                    <Nav tabs className="nav-tabs-custom nav-justified">

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTabJustify === "5",
                          })}
                          onClick={() => {
                            this.setState({
                              lang: 'th'
                            }, () => {
                              this.toggleCustomJustified("5")
                            })

                          }}
                        >
                          <span className="text-24">ภาษาไทย</span>
                        </NavLink>
                      </NavItem>


                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTabJustify === "6",
                          })}
                          onClick={() => {
                            this.setState({
                              lang: 'en'
                            }, () => {
                              this.toggleCustomJustified("6")
                            })

                          }}
                        >
                          <span className="text-24">English</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTabJustify === "7",
                          })}
                          onClick={() => {
                            this.toggleCustomJustified("7")
                          }}
                        >
                          <span className="text-24">简体中文</span>
                        </NavLink>
                      </NavItem>

                    </Nav>





                  </CardBody>

                </Card>
                <Card>
                  <CardBody>
                    <TabContent activeTab={this.state.activeTabJustify}>
                      <TabPane tabId="5" className="p-3">

                        <ManaggeTap1 lang={this.state.lang} />



                      </TabPane>
                      <TabPane tabId="6" className="p-3">
                        <ManaggeTap1 lang={this.state.lang} />

                      </TabPane>
                      <TabPane tabId="7" className="p-3">
                        <Row>
                          <Col sm="12">
                            <CardText>
                              <h1 style={{ textAlign: 'center' }}>กำลังพัฒนา จะมาในเร็วๆนี้</h1>
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>


                    </TabContent>


                  </CardBody>
                </Card>



              </Col>
            </Row> */}






          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default managelocationDetail
