import React from "react"
import { Redirect } from "react-router-dom"

// Pages File Manager
import FileManager from "../pages/FileManager/index"

// User profile
import UserProfile from "../pages/Authentication/UserProfile"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

import Loginnew from "../pages/Authentication/Loginnew"

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"


// Charts
import ChartApex from "../pages/Charts/Apexcharts"
import ChartistChart from "../pages/Charts/ChartistChart"
import ChartjsChart from "../pages/Charts/ChartjsChart"
import EChart from "../pages/Charts/EChart"
import SparklineChart from "../pages/Charts/SparklineChart"
import ToastUIChart from "../pages/Charts/ToastUIChart"
import ChartsKnob from "../pages/Charts/charts-knob"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

import DashboardBlog from "../pages/Dashboard-blog/index"
import DashboardReport from "../pages/DashboardReport/index"

//mainpage
import mainpage from "../pages/mainpage/mainpage"

//management
import managelocation from "../pages/management/managelocation"
import managelocationDetail from "../pages/management/managelocationDetail"
import Travelactivities from "../pages/management/ Travelactivities"
import TravelactivitiesDetail from "../pages/management/ TravelactivitiesDetail"
import Accommodation from "../pages/management/Accommodation"
import AccommodationDetail from "../pages/management/AccommodationDetail"
import Travelbusinessoperator from "../pages/management/Travelbusinessoperator"
import TravelbusinessoperatorDetail from "../pages/management/TravelbusinessoperatorDetail"
import Spa from "../pages/management/Spa"
import SpaDetail from "../pages/management/SpaDetail"
import Content from "../pages/management/Content"
import ContentDetail from "../pages/management/ContentDetail"
import News from "../pages/management/News"
import NewsDetail from "../pages/management/NewsDetail"
import Story from "../pages/management/Story"
import StoryDetail from "../pages/management/StoryDetail"
import Campaign from "../pages/management/Campaign"
import CampaignDetail from "../pages/management/CampaignDetail"
import OpenApi from "../pages/management/OpenApi"
import OpenApiDetail from "../pages/management/OpenApiDetail"


import TouristRoutes from "../pages/management/TouristRoutes"
import TouristRoutesDetail from "../pages/management/TouristRoutesDetail"
import Restaurant from "../pages/management/Restaurant"
import RestaurantDetail from "../pages/management/RestaurantDetail"
import Shop from "../pages/management/Shop"
import ShopDetail from "../pages/management/ShopDetail"
import Commentlist from "../pages/management/Commentlist"
import notifyinformation from "../pages/management/notifyinformation"

//Manageteam
import Manageteammainpage from "../pages/ManageUser/Manageteammainpage"
import ManageUserRoleTable from "../pages/ManageUser/ManageUserRoleTable"
import Manageteam from "../pages/ManageUser/Manageteam"
import ManageteamLangDetailTh from "../pages/ManageUser/ManageteamLang/ManageteamLangDetailTh"
import membersAdd from "../pages/ManageUser/ManageteamLang/Addmember/membersAdd"
import membersEdit from "../pages/ManageUser/ManageteamLang/Addmember/membersEdit"
import AddUser from "../pages/ManageUser/ManageUserRole/AddUser";
import EditUser from "../pages/ManageUser/ManageUserRole/EditUser"

//แจ้งภัยพิบัติ
import Disaster from "../pages/Disaster/Disaster"
import DisasterTab from "../pages/Disaster/DisasterTab"

const authProtectedRoutes = [
  //mainpage
  { path: "/dashboard-blog", component: DashboardBlog },
  { path: "/dashboard-report", component: DashboardReport },
  { path: "/mainpage", component: DashboardBlog },
  //management
  { path: "/managelocation", component: managelocation },
  { path: "/managelocationDetail", component: managelocationDetail },
  { path: "/Travelactivities", component: Travelactivities },
  { path: "/TravelactivitiesDetail", component: TravelactivitiesDetail },
  { path: "/Accommodation", component: Accommodation },
  { path: "/Accommodation/AccommodationDetail", component: AccommodationDetail },
  { path: "/Travelbusinessoperator", component: Travelbusinessoperator },
  { path: "/Travelbusinessoperator/TravelbusinessoperatorDetail", component: TravelbusinessoperatorDetail },
  { path: "/Spa", component: Spa },
  { path: "/Spa/SpaDetail", component: SpaDetail },
  { path: "/TouristRoutes", component: TouristRoutes },
  { path: "/TouristRoutes/TouristRoutesDetail", component: TouristRoutesDetail },
  { path: "/Restaurant", component: Restaurant },
  { path: "/Restaurant/RestaurantDetail", component: RestaurantDetail },
  { path: "/Shop", component: Shop },
  { path: "/Shop/ShopDetail", component: ShopDetail },
  { path: "/Commentlist", component: Commentlist },
  { path: "/notifyinformation", component: notifyinformation },
  { path: "/Content", component: Content },
  { path: "/ContentDetail", component: ContentDetail },
  { path: "/News", component: News },
  { path: "/NewsDetail", component: NewsDetail },
  { path: "/StoryDetail", component: StoryDetail },
  { path: "/Story", component: Story },
  { path: "/Campaign", component: Campaign },
  { path: "/CampaignDetail", component: CampaignDetail },
  { path: "/Openapi", component: OpenApi },
  { path: "/OpenapiDetail", component: OpenApiDetail },
  { path: "/Disaster", component: Disaster },
  { path: "/Disaster/Add", component: DisasterTab },
  { path: "/Disaster/Edit", component: DisasterTab },
  //Manageteam
  { path: "/Manageteam", component: Manageteam },
  { path: "/ManageUserRole", component: ManageUserRoleTable },
  { path: "/Manageteammainpage", component: Manageteammainpage },
  { path: "/Manageteammainpage/ManageteamLangDetail", component: ManageteamLangDetailTh },
  { path: "/Manageteammainpage/ManageteamLangDetail/membersAdd", component: membersAdd },
  { path: "/Manageteammainpage/ManageteamLangDetail/membersEdit", component: membersEdit },
  { path: "/ManageUserRole/AddUser", component: AddUser },
  { path: "/ManageUserRole/EditUser", component: EditUser },

  //profile
  { path: "/profile", component: UserProfile },

  //File Manager
  { path: "/apps-filemanager", component: FileManager },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartist-charts", component: ChartistChart },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },
  { path: "/tui-charts", component: ToastUIChart },
  { path: "/charts-knob", component: ChartsKnob },



  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },


  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard-blog" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },



  // Authentication Inner

  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-login-new", component: Loginnew },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { authProtectedRoutes, publicRoutes }
