import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
  TabPane,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,

} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { api_endpoint, endpoint } from '../../../../global-config'



export default class General extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Lang: 'th',
      Types: this.props.Types,
      StandardTypes: this.props.StandardTypes,
      life_style: this.props.life_style,
      activity_categories: this.props.activity_categories,
      life_styleSelected: this.props.life_styleSelected,
      StartDate: this.props.StartDate,
      EndDate: this.props.EndDate,
      activity_categoriesSelected: this.props.activity_categoriesSelected,
      Nature: false,
      Manmade: false,
      Sport: false,
      Event: false,
      selectedGroup: null,
      placeType: [],
      upload_images_loading: false,
    }
    console.log(this.state.life_style)
    console.log(this.state.activity_categories)
  }
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }

  handleAcceptedFiles = async (files) => {
    if (files.length == 1) {
      let [file] = files
      console.log(file)
      var formData = new FormData(); // Currently empty
      formData.append('files', file, file.name);
      let upload_response = await fetch(api_endpoint + '/file/upload', {
        method: "POST",
        body: formData
      })
      let upload_response_object = await upload_response.json()
      if (upload_response_object.status == 'success') {
        console.log(upload_response_object.uploads[0])
        this.props.callback(upload_response_object.uploads[0], 'CoverImages')
        this.props.submit('IntroImage', upload_response_object.uploads[0])
      } else {
        alert('เกิดข้อผิดพลาดในการอัพโหลดไฟล์')
      }
    }
    this.setState({
      upload_images_loading: false
    })

  }

  render() {
    const { selectedGroup } = this.state

    return (
      <div>
        <p className={'text-24'}>ข้อมูลทั่วไป</p>
        <Row className={'mt-3'}>
          <Col lg="12">
            <FormGroup>
              <Label className="font-style-16 cl-black" for="agencyName">ชื่อ </Label><Label className="required"> *</Label>
              <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Name" placeholder="กรุณากรอกชื่อ" value={this.props.data.Name} onChange={(e) => { this.props.callback(e.target.value, 'Name') }} />
            </FormGroup>
          </Col>
        </Row>
        {this.props.lang === 'th' ?
          <Row>
            <Col lg={12}>
              <Label className={'mt-3 font-style-16 cl-black'}>ประเภทของกิจกรรมการท่องเที่ยว</Label>
            </Col>
            {this.state.StandardTypes.map((e, i) => {
              if (i < 13) {
                return (
                  <Col lg={4} key={i}>
                    <div className="custom-control custom-checkbox " style={{ marginTop: 5 }}
                      onClick={() => {
                        let arr = this.state.StandardTypes
                        arr[i].isCheck = !arr[i].isCheck
                        this.setState({
                          StandardTypes: arr
                        }, () => {
                          let data = []
                          let filter = this.state.StandardTypes.filter(item => item.isCheck === true)
                          console.log(filter)
                          filter.forEach(element => { if (element.isCheck === true) { data.push({ StandardActivityTypeID: element.ID }) } });
                          this.props.submit('StandardTypes', data)
                        })
                      }}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={e.isCheck}
                      />
                      <label
                        className="custom-control-label font-style-16b4"
                      >
                        {e.Details[this.state.Lang].Name}
                      </label>
                    </div>
                  </Col>
                )
              }
            })}
          </Row>
          :
          <div>
            <Label className="font-style-16 cl-black">ประเภทของกิจกรรมการท่องเที่ยว</Label>
            <p className="font-style-16 cl-darkblue">
              (ให้จัดการข้อมูลที่ภาษาไทย)
            </p>
          </div>
        }
        <Row className={'mt-3 text-16'}>
          <Col lg="12">
            <Label className="font-style-16 cl-black">รายละเอียด </Label><Label className="required"> *</Label>
            <Input
              style={{ fontSize: 16 }}
              type="textarea"
              id="textarea"
              onChange={(e) => { this.props.callback(e.target.value, 'Details') }}
              // maxLength="225"
              rows="3"
              placeholder=""
              value={this.props.data.Details}
            />
            {this.state.textareabadge ? (
              <span className="badgecount badge badge-success">
                {" "}
                {this.state.textcount} / 225{" "}
              </span>
            ) : null}
          </Col>
        </Row>
        {this.props.lang === 'th' ?
          <Row className={'mt-3 text-16'}>
            <Col lg="12">
              <Label className="font-style-16 cl-black">รูปภาพหน้าปก {this.props.data.test} </Label><Label className="required"> * {this.state.upload_images_loading && (<font color="green">โปรดรอกำลังอัพโหลดรูปภาพ</font>)}</Label>
              <div style={{ display: typeof this.props.data.CoverImages.HashName != 'undefined' ? 'none' : '' }} className="custom-file" >
                <input
                  onChange={e => {
                    this.setState({
                      upload_images_loading: true
                    }, () => {
                      this.handleAcceptedFiles(e.target.files)
                      e.target.value = ""
                    }
                    )
                  }}
                  accept=".jpeg,.gif,.png,.jpg"
                  type="file"
                  className="custom-file-input"
                  id="customFile"
                />
                <label className="custom-file-label" htmlFor="customFile">
                  <span className="font-style-16nb cl-gray"> Choose file</span>
                  {/* {this.state.selectedFiles.length < 1 ?
                                        <span> Choose file</span>
                                        :
                                        <div>
                                            {this.state.selectedFiles.map(f => {
                                                return (<span style={{ marginRight: 10 }}>{f.name}</span>)
                                            })}
                                        </div>
                                    } */}
                </label>
                <p className="mt-3 font-style-14 cl-darkblue">ขนาดไฟล์ไม่เกิน 2 MB, เฉพาะไฟล์นามสกุล: .jpg, .jpeg, .gif, .png และขนาดไฟล์ที่เหมาะสม: 800 x 600 pixel</p>
              </div>
              {typeof this.props.data.CoverImages.HashName != 'undefined' && (
                <div>
                  <div style={{ textAlign: 'center' }}>
                    <img style={{ maxWidth: 350 }} src={`${endpoint}/assets/upload${this.props.data.CoverImages.Path}/${this.props.data.CoverImages.HashName}`}></img>
                    <div style={{ marginTop: 25 }}>
                      <button onClick={() => {
                        this.props.callback({}, 'CoverImages')
                        this.props.submit('IntroImage', {})
                      }} style={{ marginLeft: 25 }} type="button" class="btn btn-danger waves-effect waves-light">ยกเลิกไฟล์</button>
                    </div>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          :
          <Row className={'mt-3 font-style-16 cl-black'}>
            <Col lg="12">
              <Label>รูปภาพหน้าปก </Label><Label className="required"> *</Label>
              <p style={{ color: '#173865' }}>
                (ให้จัดการข้อมูลที่ภาษาไทย)
              </p>
            </Col>
          </Row>
        }
        {this.props.lang === 'th' ?
          <Row className={'mt-3 font-style-16b4'}>
            <Col lg="12">
              <FormGroup className="select2-container">
                <Label className="font-style-16 cl-black">หมวดหมู่ Life Style</Label>
                <Select
                  value={this.state.life_styleSelected === null ? { label: 'เลือกหมวดหมู่ Life Style', value: -1 } : this.state.life_styleSelected}
                  onChange={(e) => {
                    this.setState({
                      life_styleSelected: { label: e.label, value: e.value }
                    }, () => {
                      this.props.callback(e, 'life_style')
                    })
                  }}
                  options={this.state.life_style}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </Col>
          </Row>
          :
          <div>
            <Label className="font-style-16 cl-black">หมวดหมู่ Life Style</Label>
            <p className="font-style-16 cl-darkblue">
              (ให้จัดการข้อมูลที่ภาษาไทย)
            </p>
          </div>
        }
        {this.props.lang === 'th' ?
          <Row className={'mt-3 font-style-16b4'}>
            <Col lg="12">
              <FormGroup className="select2-container">
                <Label className="font-style-16 cl-black">หมวดหมู่การท่องเที่ยว</Label>
                <Select
                  value={this.state.activity_categoriesSelected === null ? { label: 'เลือกหมวดหมู่กิจกรรม', value: -1 } : this.state.activity_categoriesSelected}
                  onChange={(e) =>
                    this.setState({
                      activity_categoriesSelected: { label: e.label, value: e.value }
                    }, () => {
                      this.props.callback(e, 'activity_categories')
                    })
                  }
                  options={this.state.activity_categories}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </Col>
          </Row>
          :
          <div>
            <Label className="font-style-16 cl-black">หมวดหมู่การท่องเที่ยว</Label>
            <p className="font-style-16 cl-darkblue">
              (ให้จัดการข้อมูลที่ภาษาไทย)
            </p>
          </div>
        }

        <Row className={'mt-3 font-style-16b4'}>
          {this.props.lang === 'th' ?
            <Col lg={6}>
              <div className="form-group">
                <label className="font-style-16 cl-black">วันที่เริ่มต้น (เดือน/ปี/วัน)</label>
                <input type="date" value={this.state.StartDate} onChange={(e) => {
                  this.setState({
                    StartDate: e.target.value
                  }, () => {
                    this.props.callback(e.target.value, 'StartDate')
                  })
                }} placeholder="ปรับปรุงตั้งแต่วันที่" className="form-control" ></input>
              </div>
            </Col>
            :
            <Col lg={6}>
              <Label className="font-style-16 cl-black">วันที่เริ่มต้น</Label>
              <p className="font-style-16" style={{ color: '#173865' }}>
                (ให้จัดการข้อมูลที่ภาษาไทย)
              </p>
            </Col>
          }
          {this.props.lang === 'th' ?
            <Col lg={6}>
              <div className="form-group">
                <label>วันที่สิ้นสุด (เดือน/ปี/วัน)</label>
                <input type="date" value={this.state.EndDate} onChange={(e) => {
                  this.setState({
                    EndDate: e.target.value
                  }, () => {
                    this.props.callback(e.target.value, 'EndDate')
                  })
                }} placeholder="ถึงวันที่" className="form-control" ></input>
              </div>
            </Col>
            :
            <Col lg={6}>
              <Label className="font-style-16 cl-black">วันที่สิ้นสุด</Label>
              <p className="font-style-16" style={{ color: '#173865' }}>
                (ให้จัดการข้อมูลที่ภาษาไทย)
              </p>
            </Col>
          }
        </Row>
        <Row className={'mt-3 font-style-16 cl-black'}>
          <Col lg="12">
            <FormGroup>
              <Label for="agencyName">กำหนดการเพิ่มเติม</Label>
              <Input type="text" className="form-control" id="agencyName" placeholder="กำหนดการเพิ่มเติม (ไม่บังคับ)" value={this.props.data.OpenHourRemark} onChange={(e) => { this.props.callback(e.target.value, 'OpenHourRemark') }} />
            </FormGroup>
          </Col>
        </Row>

      </div >
    )
  }
}
