import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"


const animatedComponents = makeAnimated()




export default class Agencylanguages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',
            Agency_languages: this.props.agencylanguages,

            // LicenseDate: this.props.AgencyRegistrationinfo.LicenseDate,
            // LicenseExpireDate: this.props.AgencyRegistrationinfo.LicenseDate,
            // Details: this.props.Details,

            idSelected: "",
        }
    }

    componentDidMount() {
        console.log('this.state.Agency_languages')
        console.log(this.state.Agency_languages)
        // let filter = this.state.Agency_languages.filter(item => item.isCheck === true)
        // for (let i = 0; i < filter.length; i++) {
        //     filter[i] = { TargetID: filter[i].ID, TargetGroupID: filter[i].AttractionTargetGroupID }
        // }
        // this.props.submit('Agency_languages', filter)


    }
    selectedHandle(data, type) {
        console.log('sssssssssssssssssss')
        console.log(data)
        if (type === 'AgencyRegistration_types') {
            this.props.callback(JSON.parse(data).ID, 'AgencyRegistration_types')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'GuidePlace') {
            this.props.callback(data, 'GuidePlace')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'ServiceCharge') {
            this.props.callback(data, 'ServiceCharge')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'LicenseExpireDate') {
            this.props.callback(data, 'LicenseExpireDate')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }

    }



    render() {


        return (
            <div>
                <p className={'text-24'}>ภาษาที่รองรับ</p>
                {this.props.lang === 'th' ?
                    <Row className={'mt-3 font-style-16b4 cl-black'}>
                        <Col lg="6">
                            <FormGroup>
                                {this.state.Agency_languages.map((e, i) => {
                                    if (i < 100) {
                                        return (
                                            <div key={i}>
                                                <div className="custom-control custom-checkbox mb-3"
                                                    onClick={() => {
                                                        let arr = this.state.Agency_languages
                                                        arr[i].isCheck = !arr[i].isCheck
                                                        this.setState({
                                                            Agency_languages: arr
                                                        }, () => {
                                                            let filter = this.state.Agency_languages.filter(item => item.isCheck === true)
                                                            for (let i = 0; i < filter.length; i++) {
                                                                filter[i] = { LanguageID: filter[i].ID }
                                                            }
                                                            this.props.submit('Agency_languages', filter)
                                                        })
                                                    }}>
                                                    <input
                                                        key={i}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        checked={e.isCheck}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                    >
                                                        {e.Details[this.state.Lang].Name}
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        null
                                    }
                                })}
                                {/* <FormGroup>
                            <Label for="agencyName">ค่าบริการ</Label>
                            <Input required type="text" className="form-control" id="Price" placeholder="ระบุค่าบริการ (ไม่บังคับ)" value={this.props.Details.ServiceCharge} onChange={(e) => { this.props.callback(e.target.value, 'ServiceCharge') }} />
                        </FormGroup> */}
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                {this.state.Agency_languages.map((e, i) => {
                                    if (i > 100) {
                                        return (
                                            <div key={i}>
                                                <div className="custom-control custom-checkbox mb-3"
                                                    onClick={() => {
                                                        let arr = this.state.Agency_languages
                                                        arr[i].isCheck = !arr[i].isCheck
                                                        this.setState({
                                                            Agency_languages: arr
                                                        }, () => {
                                                            let filter = this.state.Agency_languages.filter(item => item.isCheck === true)
                                                            for (let i = 0; i < filter.length; i++) {
                                                                filter[i] = { LanguageID: filter[i].ID }
                                                            }
                                                            this.props.submit('Agency_languages', filter)
                                                        })
                                                    }}>
                                                    <input
                                                        key={i}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        checked={e.isCheck}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                    >
                                                        {e.Details[this.state.Lang].Name}
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        null
                                    }
                                })}
                                {/* <FormGroup>
                            <Label for="agencyName">ค่าบริการ</Label>
                            <Input required type="text" className="form-control" id="Price" placeholder="ระบุค่าบริการ (ไม่บังคับ)" value={this.props.Details.ServiceCharge} onChange={(e) => { this.props.callback(e.target.value, 'ServiceCharge') }} />
                        </FormGroup> */}
                            </FormGroup>
                        </Col>
                    </Row>
                    :
                    <div>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }
            </div>
        )
    }
}
