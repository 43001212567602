import React, { Component } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import {
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"
import imagelogin from "../../assets/images/ttd/login.svg"

export default class CarouselPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Col xl={8}>
          <div className="auth-full-bg">
            <div className="w-100">
              <div className="bg-overlay"></div>
              <div className="d-flex h-100 flex-column " style={{justifyContent:'center',alignContent:'center'}}>
                <div className="account-pages my-5 pt-sm-5" >
                  <div className="row justify-content-center" >
                    <div className="align-self-center col-lg-8 ">
                      <div className="justify-content-center" >

                        <h6 className="mb-3" style={{ color: 'white', alignSelf: 'center', fontSize: 50}}>

                          Welcome To

                        </h6>
                        <h6 style={{ color: 'white', fontSize: 50 }}>
                          Thailand Digital Tourism


                        </h6>
                        <h6 className="mt-3" style={{ color: 'white', fontSize:30 }}>
                        มาตรฐานใหม่ข้อมูลการท่องเที่ยวของประเทศไทย
                          

                        </h6>

                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>
          {/* <CardImg className="img-fluid" src={imagelogin} alt="Skote" /> */}
          </div>




        </Col>
      </React.Fragment >
    )
  }
}
