import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { api_endpoint, endpoint } from '../../../../global-config'






export default class General extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',
            SpaFacilities: this.props.SpaFacilities,
            Spa_service_types: this.props.Spa_service_types,
            upload_images_loading: false,
            // registration_types: this.props.registration_types,
            // accommodation_service_types: this.props.accommodation_service_types,
            // AccommodationInfo: this.props.AccommodationInfo,
            // LicenseDate: this.props.AccommodationInfo.LicenseDate,
            // LicenseExpireDate: this.props.AccommodationInfo.LicenseDate,
            // cost_levels: this.props.cost_levels,
            Nature: false,
            Manmade: false,
            Sport: false,
            Event: false,
            idSelected: "",
        }
        console.log(this.state.SpaFacilities)
    }

    handleAcceptedFiles = async (files) => {
        if (files.length == 1) {
            let [file] = files
            console.log(file)
            var formData = new FormData(); // Currently empty
            formData.append('files', file, file.name);
            let upload_response = await fetch(api_endpoint + '/file/upload', {
                method: "POST",
                body: formData
            })
            let upload_response_object = await upload_response.json()
            if (upload_response_object.status == 'success') {
                console.log(upload_response_object.uploads[0])
                this.props.callback(upload_response_object.uploads[0], 'CoverImages')
                this.props.submit('IntroImage', upload_response_object.uploads[0])
            } else {
                alert('เกิดข้อผิดพลาดในการอัพโหลดไฟล์')
            }
        }
        this.setState({
            upload_images_loading: false
        })

    }

    selectedHandle(data, type) {
        console.log(data)
        if (type === 'registration_types') {
            this.props.callback(JSON.parse(data).ID, 'registration_types')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'SpaFacilities') {
            this.props.callback(JSON.parse(data).ID, 'SpaFacilities')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'Spa_service_types') {
            this.props.callback(JSON.parse(data).ID, 'Spa_service_types')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        // if (type === 'IsThaiHotalAssociation') {
        //     this.props.callback(data, 'IsThaiHotalAssociation')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'IsHotalRegistration') {
        //     this.props.callback(data, 'IsHotalRegistration')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'LicenseDate') {
        //     this.props.callback(data, 'LicenseDate')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'LicenseExpireDate') {
        //     this.props.callback(data, 'LicenseExpireDate')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'LicenseNo') {
        //     this.props.callback(data, 'LicenseNo')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }



    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            // registration_types: nextProps.registration_types,
            cost_levels: nextProps.cost_levels,
            // FoodTypes: nextProps.FoodTypes,


        })
    }

    componentDidMount() {
        let filter = this.state.Spa_service_types.filter(item => item.isCheck === true)
        for (let i = 0; i < filter.length; i++) {
            filter[i] = { SpaServiceTypeID: filter[i].ID }
        }
        this.props.submit('SpaServiceTypeID', filter)


    }

    render() {
        const { selectedGroup } = this.state
        console.log(this.props)
        return (
            <div>
                <p className={'text-24'}>ข้อมูลทั่วไป</p>

                {/* <CardTitle className={'mt-3'}>ข้อมูลทั่วไป</CardTitle> */}
                <Row className={'mt-3 font-style-16 cl-black'}>
                    <Col lg="12">
                        <FormGroup>
                            <Label for="agencyName">ชื่อ </Label><Label className="required"> *</Label>
                            <Input required type="text" className="form-control" id="Name" placeholder="กรุณากรอกชื่อ" value={this.props.data.Name} onChange={(e) => { this.props.callback(e.target.value, 'Name') }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={'mt-3 font-style-16 cl-black'}>
                    <Col lg="12">
                        <Label>รายละเอียด </Label><Label className="required"> *</Label>
                        <Input
                            type="textarea"
                            id="textarea"
                            onChange={(e) => { this.props.callback(e.target.value, 'Details') }}
                            // maxLength="225"
                            rows="3"
                            placeholder=""
                            value={this.props.data.Details}
                        />
                        {this.state.textareabadge ? (
                            <span className="badgecount badge badge-success">
                                {" "}
                                {this.state.textcount} / 225{" "}
                            </span>
                        ) : null}
                    </Col>
                </Row>
                {this.props.lang === 'th' &&
                    <Row className={'mt-3 font-style-16 cl-black'}>
                        <Col lg="12">
                            <Label>รูปภาพหน้าปก {this.props.data.test} </Label><Label className="required"> * {this.state.upload_images_loading && (<font color="green">โปรดรอกำลังอัพโหลดรูปภาพ</font>)}</Label>
                            <div style={{ display: typeof this.props.data.CoverImages.HashName != 'undefined' ? 'none' : '' }} className="custom-file" >
                                <input
                                    onChange={e => {
                                        this.setState({
                                            upload_images_loading: true
                                        }, () => {
                                            this.handleAcceptedFiles(e.target.files)
                                            e.target.value = ""
                                        }
                                        )
                                    }}
                                    accept=".jpeg,.gif,.png,.jpg"
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                />
                                <label className="custom-file-label" htmlFor="customFile">
                                    <span className="font-style-16b4 cl-gray"> Choose file</span>
                                    {/* {this.state.selectedFiles.length < 1 ?
                                        <span> Choose file</span>
                                        :
                                        <div>
                                            {this.state.selectedFiles.map(f => {
                                                return (<span style={{ marginRight: 10 }}>{f.name}</span>)
                                            })}
                                        </div>
                                    } */}
                                </label>
                                <p className="mt-3 font-style-14 darkblue" style={{ color: '#173865', fontSize: 14 }}>ขนาดไฟล์ไม่เกิน 2 MB, เฉพาะไฟล์นามสกุล: .jpg, .jpeg, .gif, .png และขนาดไฟล์ที่เหมาะสม: 800 x 600 pixel</p>
                            </div>
                            {typeof this.props.data.CoverImages.HashName != 'undefined' && (
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <img style={{ maxWidth: 350 }} src={`${endpoint}/assets/upload${this.props.data.CoverImages.Path}/${this.props.data.CoverImages.HashName}`}></img>
                                        <div style={{ marginTop: 25 }}>
                                            <button onClick={() => {
                                                this.props.callback({}, 'CoverImages')
                                                this.props.submit('IntroImage', {})
                                            }} type="button" class="btn btn-danger waves-effect waves-light">ยกเลิกไฟล์</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                }
                {this.props.lang === 'th' ?
                    <Row className={'mt-3 font-style-16 cl-black'}>
                        <Col lg={12}>
                            <p className={'text-16-bold'}>ประเภทการให้บริการ</p>
                        </Col>
                        <Col lg={12}>
                            {this.state.Spa_service_types.map((e, i) => {
                                // console.log(e)
                                return (
                                    <div key={i} className="text-16">
                                        <div className="custom-control custom-checkbox mb-3"
                                            onClick={() => {
                                                let arr = this.state.Spa_service_types
                                                arr[i].isCheck = !arr[i].isCheck
                                                this.setState({
                                                    Spa_service_types: arr
                                                }, () => {
                                                    let filter = this.state.Spa_service_types.filter(item => item.isCheck === true)
                                                    for (let i = 0; i < filter.length; i++) {
                                                        filter[i] = { SpaServiceTypeID: filter[i].ID }
                                                    }
                                                    this.props.submit('SpaServiceTypeID', filter)
                                                })
                                            }}>
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                checked={e.isCheck}
                                            />
                                            <label
                                                className="custom-control-label font-style-16b4 cl-black"
                                            >
                                                {e.Details[this.state.Lang].Name}
                                            </label>
                                        </div>
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                    :
                    <div>
                        <Label className="mt-3 font-style-16 cl-black">ประเภทการให้บริการ</Label>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }
                {/* <Row className={'mt-3'}>
                    <Col lg="12">
                        <FormGroup>
                            <Label for="Province">ประเภททะเบียน </Label><Label className="required"> *</Label>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <select className="form-control" onChange={(e) => {
                                        console.log(e.target)
                                        if (e.target.value === -1) {
                                            return
                                        }
                                        else {
                                            this.selectedHandle(e.target.value, 'registration_types')
                                        }
                                    }} >
                                        {this.state.registration_types.filter(item => item.isCheck === true).length < 1 ? <option value={-1}>กรุณาเลือกประเภททะเบียน</option> : null}
                                        {this.state.registration_types.map((e, i) => {

                                            if (e.isCheck === true) {

                                                return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                            }
                                        })}
                                        {this.state.registration_types.map((e, i) => {
                                            if (e.isCheck === false) {
                                                return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                            }
                                        })}
                                    </select>
                                </div>
                            </div>
                        </FormGroup>
                    </Col>
                </Row> */}
                {/* <Row className={'mt-3'}>
                    <Col lg="12">
                        <FormGroup>
                            <Label for="Province">ประเภทการให้บริการ </Label><Label className="required"> *</Label>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <select className="form-control" onChange={(e) => {
                                        console.log(e.target)
                                        if (e.target.value === -1) {
                                            return
                                        }
                                        else {
                                            this.selectedHandle(e.target.value, 'accommodation_service_types')
                                        }
                                    }} >
                                        {this.state.accommodation_service_types.filter(item => item.isCheck === true).length < 1 ? <option value={-1}>กรุณาเลือกประเภทการให้บริการ</option> : null}
                                        {this.state.accommodation_service_types.map((e, i) => {

                                            if (e.isCheck === true) {

                                                return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                            }
                                        })}
                                        {this.state.accommodation_service_types.map((e, i) => {
                                            if (e.isCheck === false) {
                                                return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                            }
                                        })}
                                    </select>
                                </div>
                            </div>
                        </FormGroup>
                    </Col>
                </Row> */}
                {/* <Row className={'mt-3'}>
                    <Col lg="12">
                        <FormGroup>
                            <Label for="Province">ช่วงราคา </Label><Label className="required"> *</Label>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <select className="form-control" onChange={(e) => {
                                        console.log(e.target)
                                        if (e.target.value === -1) {
                                            return
                                        }
                                        else {
                                            this.selectedHandle(e.target.value, 'cost_levels')
                                        }
                                    }} >
                                        {this.state.cost_levels.filter(item => item.isCheck === true).length < 1 ? <option value={-1}>กรุณาเลือกช่วงราคา</option> : null}
                                        {this.state.cost_levels.map((e, i) => {

                                            if (e.isCheck === true) {

                                                return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                            }
                                        })}
                                        {this.state.cost_levels.map((e, i) => {
                                            if (e.isCheck === false) {
                                                return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                            }
                                        })}
                                    </select>
                                </div>
                            </div>
                        </FormGroup>
                    </Col>
                </Row> */}
                {/* <Row>
                   
                    <Col lg={3} className={'mt-3 '}>
                        <h6>การดำเนินการ</h6>
                    </Col>
                    <Col lg={9} className={'mt-3 '}>
                        <Row>
                            <Col lg={4}>
                                <div className="custom-control custom-radio mb-3">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        id={'IsThaiHotalAssociation'}
                                        onChange={() => false}
                                        checked={this.state.AccommodationInfo.IsThaiHotalAssociation === "Y" ? true : false}

                                    />
                                    <label
                                        className="custom-control-label"
                                        onClick={() => {
                                            this.selectedHandle("Y", 'IsThaiHotalAssociation')
                                        }}
                                    >
                                        เป็นสมาชิกสมาคมโรงแรม
                                    </label>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="custom-control custom-radio mb-3">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        id={'IsHotalRegistration'}
                                        onChange={() => false}
                                        checked={this.state.AccommodationInfo.IsHotalRegistration === "Y" ? true : false}
                                    />
                                    <label
                                        className="custom-control-label"
                                        onClick={() => {
                                            this.selectedHandle("Y", 'IsHotalRegistration')

                                        }}
                                    >
                                        จดทะเบียนตาม พรบ. โรงแรม
                                    </label>
                                </div>
                            </Col>

                        </Row>
                    </Col>
                </Row> */}
                {/* <Row>
                    <Col lg="12">
                        <FormGroup>
                            <Label for="agencyName">เลขที่ใบอนุญาต</Label>
                            <Input required type="text" className="form-control" id="LicenseNo" placeholder="กรุณา เลขที่ใบอนุญาต" value={this.props.data.LicenseNo} onChange={(e) => { this.props.callback(e.target.value, 'LicenseNo') }} />
                        </FormGroup>
                    </Col>
                </Row> */}
                {/* <Row className={'mt-3'}>

                    <Col lg={6}>
                        <div className="form-group">
                            <label>วันที่เริ่มต้น</label>
                            <input type="date" value={this.state.LicenseDate} onChange={(e) => {
                                this.setState({
                                    LicenseDate: e.target.value
                                }, () => {
                                    this.selectedHandle(e.target.value, 'LicenseDate')
                                })
                            }} placeholder="ปรับปรุงตั้งแต่วันที่" className="form-control" ></input>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="form-group">
                            <label>วันที่สิ้นสุด</label>
                            <input type="date" value={this.state.LicenseExpireDate} onChange={(e) => {
                                this.setState({
                                    LicenseExpireDate: e.target.value
                                }, () => {
                                    this.selectedHandle(e.target.value, 'LicenseExpireDate')
                                })
                            }} placeholder="ถึงวันที่" className="form-control" ></input>
                        </div>
                    </Col>
                </Row> */}



            </div>
        )
    }
}
