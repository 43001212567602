import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Col,
    Container,
    Modal,
    Row,
    FormGroup,
    Label
} from "reactstrap"

import { Link } from "react-router-dom"
import $ from 'jquery'
import logoplus from "../../../../assets/images/ttd/Vector.svg"
import listicon from "../../../../assets/images/ttd/list.svg"
import logolist from "../../../../assets/images/ttd/Group (2).svg"
import image from "../../../../assets/images/ttd/image.png"
import Delete from "../../../../assets/images/ttd/delete.svg"
import sentang from "../../../../assets/images/ttd/sentang.svg"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Table from "../../../ManageUser/ManageteamLang/Addmember/Table"
import { api_endpoint } from '../../../../global-config'
import MyTable from "./Table/MyTable"
import DataGrid from '../../../../components/Table/Table'



export default class list extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lang: 'th',
            modal_xlarge: false,
            provinces: [],
            proviceCode: null,
            districtCode: null,
            maininfoTypeCode: null,
            regionCode: null,
            districts: [],
            regions: [],
            maininfoType: [],
            countData: null,
            dataTable: [],
            sortModel: [{
                field: 'Name',
                sort: 'desc'
            }],
            routeData: this.props.route,
            search: "",
            skip: 0,
            limit: 5,
            isLoading: true
        }

        this.tog_xlarge = this.tog_xlarge.bind(this)
        this.delete = this.delete.bind(this)
    }

    removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    tog_xlarge() {
        this.setState(prevState => ({
            modal_xlarge: !prevState.modal_xlarge,
        }))
        this.clearFilter()
        this.removeBodyCss()
    }

    delete(item) {
        console.log(item)
        if (confirm('คุณต้องการลบ ' + item.Name + ' ในแผนการท่องเที่ยวหรือไม่')) {
            let arr = this.state.routeData.filter(i => i.id !== item.id)
            console.log(arr)
            this.setState({
                routeData: arr
            }, () => {
                let routeData = []
                arr.map(e => {
                    routeData.push({
                        MainInfoID: Number(e.id),
                    })
                })
                this.props.submit('Route', routeData)
            })

        }
    }

    clearFilter() {
        this.setState({
            proviceCode: null,
            districtCode: null,
            maininfoTypeCode: null,
            regionCode: null,
            district: []
        }, () => {
            this.get_maininfo_type()
        })
    }

    async get_maininfo_type() {
        const response = await fetch(
            api_endpoint + `/lkup/maininfo_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({

                    // ID: this.state.place_id,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        this.setState({
            maininfoType: _result
        }, () => {
            this.get_regions()
        })
    }

    async get_regions() {
        const response = await fetch(
            api_endpoint + `/lkup/regions`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ID: []

                    // ID: this.state.place_id,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        this.setState({
            regions: _result
        }, () => {
            this.get_provinces()
        })
    }

    async get_provinces() {
        const response = await fetch(
            api_endpoint + `/lkup/provinces`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    RegionID: this.state.regionCode === null ? [] : [Number(this.state.regionCode)],
                    Code: [],

                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();

        for (let i = 0; i < _result.length; i++) {
            // if (_result[i].Details[this.state.lang].Name === this.state[this.state.lang].Province) {
            //     _result[i] = { ..._result[i], isCheck: true }
            //     let obj = this.state[this.state.lang]
            //     obj.Province = _result[i].Code
            //     obj.RegionID = _result[i].RegionID
            //     this.setState({
            //         [this.state.lang]: obj
            //     })
            // }
            // else {
            _result[i] = { ..._result[i], isCheck: false }
            // }
        }
        this.setState({
            provinces: _result
        }, () => {
            this.getLocation()
        })
    }




    async getLocation() {
        let sortBy = []
        if (this.state.sortModel[0].field === 'Name') {
            sortBy = [
                {
                    field: "Main.Details." + this.state.lang + ".Name",
                    sort: this.state.sortModel[0].sort === 'asc' ? 1 : -1
                }]
        }
        if (this.state.sortModel[0].field === 'Province') {
            sortBy = [
                {
                    field: "Main.Info.Province." + this.state.lang + ".Name",
                    sort: this.state.sortModel[0].sort === 'asc' ? 1 : -1
                }]
        }
        if (this.state.sortModel[0].field === 'ViewCount') {
            sortBy = [
                {
                    field: "View.ViewCount",
                    sort: this.state.sortModel[0].sort === 'asc' ? 1 : -1
                }]
        }

        const response = await fetch(
            api_endpoint + `/maininfo/read`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Search: this.state.search === "" ? null : this.state.search,
                    Lang: this.state.lang,
                    Skip: this.state.skip,
                    MainTypeID: this.state.maininfoTypeCode === null ? [] : [Number(this.state.maininfoTypeCode)],
                    ProvinceCode: this.state.proviceCode === null ? [] : [this.state.proviceCode],
                    RegionID: this.state.regionCode === null ? [] : [Number(this.state.regionCode)],
                    Limit: this.state.limit,
                    // SortByView: 1,
                    SortBy: sortBy

                }), // body data type must match "Content-Type" header
            }
        );

        var _result = await response.json();
        console.log(_result)
        if (_result.results !== undefined) {
            let arr = []
            _result.results.map((e) => {

                arr.push({
                    id: e.ID,
                    Name: e.Name[this.state.lang],
                    Province: e.Province[this.state.lang],
                    District: e.District[this.state.lang],
                    ViewCount: e.ViewCount,
                    NeedApproval: e.NeedApproval,
                    IntroImage: e.IntroImage,
                    MainInfoType: e.MainInfoType[this.state.lang]
                })

            })
            this.setState({
                countData: _result.count,
                dataTable: arr,
                isLoading: false
            })
        }
        else {
            console.log('error : getLocation')
        }

    }


    handleSort = (value) => {
        let arr = this.state.sortModel
        console.log(value)
        if (this.state.isSerching === true) {
            if (value.sortModel[0] === undefined) {
                arr[0].sort = 'asc'
                this.setState({
                    sortModel: arr
                }, () => {
                    //   this.searchLocation()
                })
            }
            else {
                arr[0].field = value.sortModel[0].field
                arr[0].sort = value.sortModel[0].sort
                this.setState({
                    sortModel: arr
                }, () => {
                    //   this.searchLocation()
                })
            }
        }
        else {
            if (value.sortModel[0] === undefined) {
                arr[0].sort = 'asc'
                this.setState({
                    sortModel: arr
                }, () => {
                    this.getLocation()
                })
            }
            else {
                arr[0].field = value.sortModel[0].field
                arr[0].sort = value.sortModel[0].sort
                this.setState({
                    sortModel: arr
                }, () => {
                    this.getLocation()
                })
            }
        }

    }

    dataGridPagging = (value) => {
        if (this.state.isSerching === true) {
            if ((value.page * 5) === this.state.limit) {
                this.setState({
                    isLoading: true,
                    limit: this.state.limit + 5
                }, () => {
                    this.searchLocation()
                })
            }
            else {
                return
            }
        }
        else {
            if ((value.page * 5) === this.state.limit) {
                this.setState({
                    isLoading: true,
                    limit: this.state.limit + 5
                }, () => {
                    this.getLocation()
                })
            }
            else {
                return
            }
        }
    }

    componentDidMount() {
        let arr = this.state.routeData

        this.setState({
            routeData: arr
        }, () => {
            let routeData = []
            arr.map(e => {
                routeData.push({ MainInfoID: Number(e.id) })
            })
            this.props.submit('Route', routeData)
        })
    }




    render() {
        return (
            <div>
                {this.props.lang === 'th' ?
                    <div>
                        <Row style={{ marginBottom: 20 }}>
                            <Col>
                                <p className={'text-24'}>รายการเส้นทางท่องเที่ยว</p>
                            </Col>
                            <Col style={{ textAlign: 'end', alignSelf: 'center' }}>
                                <Link
                                    onClick={this.tog_xlarge}
                                    style={{ backgroundColor: '#173865', borderColor: '#173865' }}
                                    className="btn btn-primary waves-effect waves-light w-lg "
                                >
                                    <img src={logoplus} style={{ padding: '0px 10px 3px 0px', width: 30 }} />
                                    {/* <i className="mdi mdi-chevron-right ml-1"></i> */}
                                    <span className="d-none d-sm-inline-block font-style-16nb">เพิ่มสถานที่ท่องเที่ยว</span>{" "}
                                </Link>
                            </Col>
                        </Row>
                        <Card style={{ height: 30, background: ' #F4D006', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <h5 className="modal-title">
                                ลำดับสถานที่ท่องเที่ยว
                            </h5>
                        </Card>
                    </div>
                    :
                    <div>
                        <Label className={'text-24'}>รายการเส้นทางท่องเที่ยว</Label>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }

                <Modal
                    size="xl"
                    isOpen={this.state.modal_xlarge}
                    toggle={this.tog_xlarge}
                >
                    <div className="modal-header">
                        <h5
                            className="font-style-24 modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            รายการสถานที่
                        </h5>
                        <button
                            onClick={() =>
                                this.setState({ modal_xlarge: false })
                            }
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <Row>
                                <Col>
                                    <form className="app-search d-none d-lg-block">
                                        <div className="position-relative">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search..."
                                            />
                                            <span className="bx bx-search-alt" />
                                        </div>
                                    </form>
                                </Col>
                                <div className="col-auto" style={{ alignSelf: 'center' }}>
                                    <Button
                                        style={{ backgroundColor: '#F47806', borderColor: '#F47806' }}
                                        type="submit"
                                        onClick={() => { this.clearFilter() }}
                                        className="chat-send w-md waves-effect waves-light"
                                    >
                                        <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: 'white' }}>ล้างค่าค้นหา</span>{" "}
                                    </Button>
                                </div>
                                <div className="col-auto" style={{ alignSelf: 'center' }}>
                                    <Button
                                        style={{ backgroundColor: '#F47806', borderColor: '#F47806' }}
                                        type="submit"
                                        className="chat-send w-md waves-effect waves-light"
                                    >
                                        <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: 'white' }}>ค้นหา</span>{" "}
                                    </Button>
                                </div>
                            </Row>
                            <Row className="mt-3" style={{ marginBottom: 20 }}>
                                <Col lg={4}>
                                    <FormGroup>
                                        <Label for="Name">ภูมิภาค </Label><Label className="required"> *</Label>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <select className="form-control"
                                                    id="regions-select"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            regionCode: e.target.value
                                                        }, () => {
                                                            this.get_maininfo_type()
                                                        })
                                                    }}
                                                >
                                                    {this.state.regionCode === null &&
                                                        <option value="" selected disabled hidden>---- โปรดเลือก ----</option>
                                                    }

                                                    {this.state.regions.map(e => {
                                                        return (
                                                            <option value={e.ID}>{e.Details[this.state.lang].Name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </FormGroup>

                                </Col>
                                <Col lg={4}>
                                    <FormGroup>
                                        <Label for="Name">จังหวัด </Label><Label className="required"> *</Label>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <select className="form-control"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            proviceCode: e.target.value
                                                        }, () => {
                                                            this.get_maininfo_type()
                                                        })
                                                    }}
                                                >
                                                    {this.state.proviceCode === null &&
                                                        <option value="" selected disabled hidden>---- โปรดเลือก ----</option>
                                                    }
                                                    {this.state.provinces.map(e => {
                                                        return (
                                                            <option value={e.Code}>{e.Details[this.state.lang].Name}</option>
                                                        )
                                                    })}
                                                </select>

                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg={4}>
                                    <FormGroup>
                                        <Label for="Name">ประเภท </Label><Label className="required"> *</Label>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <select className="form-control"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            maininfoTypeCode: e.target.value
                                                        }, () => {
                                                            this.get_maininfo_type()
                                                        })
                                                    }}
                                                >
                                                    {this.state.maininfoTypeCode === null &&
                                                        <option value="" selected disabled hidden>---- โปรดเลือก ----</option>
                                                    }
                                                    {this.state.maininfoType.map(e => {
                                                        return (
                                                            <option value={e.Code}>{e.Details[this.state.lang].Name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                {/* <Col lg={6}>
                                    <FormGroup>
                                        <Label for="Name">หมวดหมู่</Label>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <select className="form-control">
                                                    <option>---- โปรดเลือก ----</option>
                                                </select>
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Label for="Name">สถานะ</Label>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <select className="form-control">
                                                    <option>---- โปรดเลือก ----</option>
                                                </select>
                                            </div>
                                        </div>
                                    </FormGroup>
                                </Col> */}
                            </Row>
                            <DataGrid pageSize={5}
                                header={
                                    [
                                        { field: 'id', headerName: 'ลำดับ', width: 120 },
                                        { field: 'Name', headerName: 'ชื่อสถานที่', flex: 2 },
                                        { field: 'Province', headerName: 'จังหวัด', flex: 1 },
                                        { field: 'District', headerName: 'ตำบล', flex: 1 },
                                        { field: 'ViewCount', headerName: 'จำนวนการเข้าถึง', flex: 1 },
                                        {
                                            field: 'NeedApproval', headerName: 'สถานะ', flex: 1,
                                            renderCell: (params) => (
                                                <div>
                                                    {params.value === 'Y' ?
                                                        <span>
                                                            มีการแก้ไข
                                                        </span>
                                                        :
                                                        <span>
                                                            ปกติ
                                                        </span>
                                                    }
                                                </div>
                                            )
                                        },
                                        {
                                            field: 'Action', headerName: 'จัดการ',
                                            renderCell: (params) => (
                                                <strong>
                                                    {/* <img src={Edit} style={{ paddingRight: 10 }} /> */}
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        style={{ marginLeft: 16 }}
                                                        onClick={() => {
                                                            if (confirm('คุณต้องเพิ่ม ' + params.row.Name + ' ใช่ไหมลงในแผนการท่องเที่ยวหรือไม่')) {
                                                                let arr = this.state.routeData
                                                                arr.push(params.row)

                                                                this.setState({
                                                                    routeData: arr
                                                                }, () => {
                                                                    let routeData = []
                                                                    arr.map(e => {
                                                                        routeData.push({ MainInfoID: Number(e.id) })
                                                                    })
                                                                    this.props.submit('Route', routeData)
                                                                })
                                                            }
                                                            else {

                                                            }
                                                        }}
                                                    >
                                                        เลือก
                                                    </Button>
                                                </strong>
                                            ),
                                        }]
                                }
                                sortModel={this.state.sortModel}
                                data={this.state.dataTable}
                                rowsCount={this.state.countData}
                                pagging={this.dataGridPagging}
                                loading={this.state.isLoading}
                                sort={this.handleSort}
                            />
                        </div>
                    </div>
                </Modal>
                {/* <MyTable /> */}
                {/* <Card className="mt-3" style={{ backgroundColor: '#F4D006' }}>
                    <Row className="mt-3">
                        <Col lg={1} style={{ textAlign: 'center' }}>
                            <p className="text-16" style={{ color: 'white', fontWeight: 'bold' }}>ลำดับ</p>
                        </Col>
                        <Col lg={11} style={{ textAlign: 'center' }}>
                            <p className="text-16" style={{ color: 'white', fontWeight: 'bold' }}>สถานที่ท่องเที่ยว</p>
                        </Col>
                    </Row>
                </Card>
                <Card style={{ backgroundColor: '#F0F0F0' }}>
                    <CardBody>
                        <Row style={{ alignItems: 'center' }}>
                            <Col lg={1}>
                                <img src={logolist} style={{ paddingRight: 15 }}></img>
                                <a className="text-16">1</a>
                            </Col>
                            <Col lg={1}>
                                <img alt="Skote" className="rounded-circle avatar-sm" src={image}></img>
                            </Col>
                            <Col lg={2} className="text-16">
                                <Row>
                                    ชื่อสถานที่ท่องเที่ยว
                               </Row>
                                <Row>
                                    หมวดหมู่ : แหล่งท่องเที่ยว
                               </Row>
                            </Col>
                            <Col lg={8} style={{ textAlign: 'end' }}>
                                <img src={Delete} ></img>
                            </Col>
                        </Row>
                    </CardBody>
                </Card> */}

                <DragDropContext onDragEnd={(e) => {
                    let arr = this.state.routeData
                    let element = arr[e.source.index];
                    let routeData = []
                    arr.splice(e.source.index, 1);
                    arr.splice(e.destination.index, 0, element);
                    arr.map(e => {
                        routeData.push({ MainInfoID: Number(e.id) })
                    })
                    this.props.submit('Route', routeData)
                }}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            //   style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {this.state.routeData.map((item, index) => {
                                    console.log(this.state.routeData)
                                    return (
                                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                //   style={getItemStyle(
                                                //     snapshot.isDragging,
                                                //     provided.draggableProps.style
                                                //   )}
                                                >
                                                    {/* <Card style={{ height: 80, background: ' #F0F0F0', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', padding: 15 }}>
                                                        <div className={'mt-3'} style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img src={listicon} style={{ paddingRight: 10, color: 'gray' }} />
                                                        </div>
                                                        <div className={'ml-3'} style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                <div>
                                                                    <span className={'text-16-bold'} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', color: 'black' }}>
                                                                        {item.Name}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <span className={'text-12'} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', color: 'black' }}>
                                                                        จังหวัด : {item.Province}
                                                                    </span>
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5 }}>
                                                                    <span className={'text-12'} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', color: 'black' }}>
                                                                        เมือง : {item.District}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card> */}
                                                    <div className="recommendTap">
                                                        <Row style={{ margin: 5 }}>
                                                            <Col lg={1} style={{ textAlign: 'end', borderRight: '1px solid #CED4DA' }}>
                                                                <h5 className="font-style-18 cl-black" style={{ marginTop: '3rem' }}>
                                                                    {index + 1}.
                                                                </h5>
                                                            </Col>
                                                            <Col lg={10}>
                                                                <Row>
                                                                    <img src={item.IntroImage} style={{ width: 80, height: 80, borderRadius: 60, margin: '1rem' }} alt=""></img>
                                                                    <div style={{ display: 'block' }}>
                                                                        <h5 className="font-style-16 cl-black" style={{ marginTop: 10 }}>
                                                                            {item.Name}
                                                                        </h5>
                                                                        <div className="row" style={{ margin: 0 }}>
                                                                            <h5 className="font-style-16 cl-black" style={{ marginTop: 10 }}>
                                                                                {item.Province === '' ?
                                                                                    <span>จังหวัด: -</span>
                                                                                    :
                                                                                    <span>จังหวัด: {item.Province}</span>
                                                                                }
                                                                            </h5>
                                                                            <h5 className="font-style-16 cl-black" style={{ marginTop: 10, marginLeft: 15 }}>
                                                                                {item.District === '' ?
                                                                                    <span>เมือง: - </span>
                                                                                    :
                                                                                    <span>เมือง: {item.District}</span>
                                                                                }
                                                                            </h5>
                                                                        </div>
                                                                        <h5 className="font-style-16 cl-black" style={{ marginTop: 10 }}>
                                                                            หมวดหมู่: {item.MainInfoType}
                                                                        </h5>
                                                                    </div>
                                                                </Row>
                                                            </Col>
                                                            <Col lg={1} style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <Row style={{ alignItems: 'center' }}>
                                                                    <img src={'/images/icon/bin.svg'} style={{ width: 15, height: 15, cursor: 'pointer' }} alt=""
                                                                        onClick={() => { this.delete(item) }}>
                                                                    </img>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                }
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        )
    }
}
