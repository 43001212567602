import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import MapsGoogle from "../../../../components/Map/Map"
import ReactEcharts from 'echarts-for-react'
import Select from "react-select"
import { api_endpoint, endpoint } from '../../../../global-config'
export default class Address extends Component {
    constructor(props) {
        super(props)
        this.state = {
            provinces: this.props.provinces,
            subdistricts: this.props.subdistricts,
            districts: this.props.districts,
            lat: this.props.data.Latitude,
            lng: this.props.data.Longitude,
            idSelected: "",
            idSelecteddistricts: "",
            subprovinces: [],
            subprovincesdistricts: [],
        }
    }

    selectedHandle(data, type) {
        if (type === 'Province') {
            this.props.callback(JSON.parse(data).RegionID, 'RegionID')
            this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'Districts') {
            this.props.callback(JSON.parse(data).Code, 'District')
            return
        }
        else {
            this.props.callback(JSON.parse(data).Code, 'County')
            return
        }
    }

    handleAcceptedFiles = async (files) => {
        if (files.length == 1) {
            let [file] = files
            console.log(file)
            var formData = new FormData(); // Currently empty
            formData.append('files', file, file.name);
            let upload_response = await fetch(api_endpoint + '/file/upload', {
                method: "POST",
                body: formData
            })
            let upload_response_object = await upload_response.json()
            if (upload_response_object.status == 'success') {
                console.log(upload_response_object.uploads[0])
                this.props.callback(upload_response_object.uploads[0], 'MapImage')
                this.props.submit('MapImage', upload_response_object.uploads[0])
            } else {
                alert('เกิดข้อผิดพลาดในการอัพโหลดไฟล์')
            }
        }
        this.setState({
            upload_images_loading: false
        })

    }


    latlngHandle(type, data) {
        if (type === 'lat') {
            this.setState({
                lat: parseFloat(data)
            }, () => {
                this.props.callback(data, 'Latitude')
            })
        }
        else {
            this.setState({
                lng: parseFloat(data)
            }, () => {
                this.props.callback(data, 'Longitude')
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.log(nextProps)
        // this.state = nextProps
        this.setState({
            provinces: nextProps.provinces,
            districts: nextProps.districts,
            subdistricts: nextProps.subdistricts
        })
    }


    render() {
        // console.log(this.props)
        return (
            <div>
                <Row >
                    <Col lg="12">
                        <Label className={'text-24'}>ข้อมูลที่อยู่</Label>
                    </Col>
                    <Col lg="6" className='mt-3 text-16'>
                        {this.props.lang === 'th' &&
                            <FormGroup>
                                <Label className="font-style-16 cl-black" for="Address">ที่อยู่ </Label><Label className="required"> *</Label>
                                <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Address" placeholder="กรุณากรอกข้อมูลที่อยู่" value={this.props.data.Address} onChange={(e) => { this.props.callback(e.target.value, 'Address') }} />
                            </FormGroup>
                        }
                        {this.props.lang === 'en' &&
                            <FormGroup>
                                <Label className="font-style-16 cl-black" for="Address">ที่อยู่ </Label><Label className="required"> *</Label>
                                <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Address" placeholder="กรุณากรอกข้อมูลที่อยู่" value={this.props.data.Address} onChange={(e) => { this.props.callback(e.target.value, 'Address') }} />
                            </FormGroup>
                        }
                        {this.props.lang === 'zh' &&
                            <FormGroup>
                                <Label className="font-style-16 cl-black" for="Address">ที่อยู่ </Label><Label className="required"> *</Label>
                                <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Address" placeholder="กรุณากรอกข้อมูลที่อยู่" value={this.props.data.Address} onChange={(e) => { this.props.callback(e.target.value, 'Address') }} />
                            </FormGroup>
                        }
                        {this.props.lang === 'th' ?
                            <FormGroup>
                                <Label className="font-style-16 cl-black" for="Province">จังหวัด </Label><Label className="required"> *</Label>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <select className="form-control" onChange={(e) => {
                                            if (e.target.value === -1) {
                                                return
                                            }
                                            else {
                                                this.selectedHandle(e.target.value, 'Province')
                                            }
                                        }} >
                                            {this.state.provinces.filter(item => item.isCheck === true).length < 1 ? <option value={-1}>กรุณาเลือกจังหวัด</option> : null}
                                            {this.state.provinces.map((e, i) => {
                                                if (e.isCheck === true) {
                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                            {this.state.provinces.map((e, i) => {
                                                if (e.isCheck === false) {
                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </FormGroup>
                            :
                            <div>
                                <Label className="font-style-16 cl-black" for="Province" >จังหวัด </Label><Label className="required"> *</Label>
                                <p className="font-style-16" style={{ color: '#173865' }}>
                                    (ให้จัดการข้อมูลที่ภาษาไทย)
                                </p>
                            </div>
                        }
                        {this.props.lang === 'th' ?
                            <FormGroup>
                                <Label className="font-style-16 cl-black" for="County">เขต/อำเภอ </Label><Label className="required"> *</Label>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <select className="form-control" style={{ fontSize: 16, height: '2.66rem', color: '#6c757d' }} onChange={(e) => {
                                            if (e.target.value === -1) {
                                                return
                                            }
                                            else {
                                                this.selectedHandle(e.target.value, 'Districts')
                                            }
                                        }} >
                                            {this.state.districts.length < 1 || this.state.districts.filter(item => item.isCheck === true).length < 1 ? <option value={-1}>กรุณาเลือกอำเภอ</option> : null}
                                            {this.state.districts.map((e, i) => {
                                                if (e.isCheck === true) {
                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                            {this.state.districts.map((e, i) => {
                                                if (e.isCheck === false) {
                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </FormGroup>
                            :
                            <div>
                                <Label className="font-style-16 cl-black" for="Province">เขต/อำเภอ </Label><Label className="required"> *</Label>
                                <p className="font-style-16" style={{ color: '#173865' }}>
                                    (ให้จัดการข้อมูลที่ภาษาไทย)
                                </p>
                            </div>
                        }
                        {this.props.lang === 'th' ?
                            <FormGroup>
                                <Label className="font-style-16 cl-black" for="District">แขวง/ตำบล </Label><Label className="required"> *</Label>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <select className="form-control" style={{ fontSize: 16, height: '2.66rem', color: '#6c757d' }} onChange={(e) => {
                                            if (e.target.value === -1) {
                                                return
                                            }
                                            else {
                                                this.selectedHandle(e.target.value, 'SubDistricts')
                                            }
                                        }} >
                                            {this.state.subdistricts.length < 1 || this.state.subdistricts.filter(item => item.isCheck === true).length < 1 ? <option value={-1}>กรุณาเลือกตำบล</option> : null}
                                            {this.state.subdistricts.map((e, i) => {
                                                if (e.isCheck === true) {
                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                            {this.state.subdistricts.map((e, i) => {
                                                if (e.isCheck === false) {
                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </FormGroup>
                            :
                            <div>
                                <Label className="font-style-16 cl-black" for="District">แขวง/ตำบล </Label><Label className="required"> *</Label>
                                <p className="font-style-16" style={{ color: '#173865' }}>
                                    (ให้จัดการข้อมูลที่ภาษาไทย)
                                </p>
                            </div>
                        }
                    </Col>
                    <Col lg="6" className={'mt-3'}>
                        <MapsGoogle google={this.props.google}
                            className={'map'}
                            zoom={10}
                            lat={this.state.lat}
                            lng={this.state.lng}
                        >
                        </MapsGoogle>
                        <Row className={'mt-3'}>
                            <Col>
                                {this.props.lang === 'th' ?
                                    <FormGroup>
                                        <Label className="font-style-16 cl-black" for="Latitude">พิกัดละติจูด </Label><Label className="required"> *</Label>
                                        <Input style={{ fontSize: 16 }} required type="number" className="form-control" id="Latitude" placeholder="Latitude" value={parseFloat(this.props.data.Latitude)} onChange={(e) => { this.latlngHandle('lat', e.target.value) }} />
                                    </FormGroup>
                                    :
                                    <FormGroup>
                                        <Label className="font-style-16 cl-black" for="Latitude">พิกัดละติจูด </Label><Label className="required"> *</Label>
                                        <p className="font-style-16" style={{ color: '#173865' }}>
                                            (ให้จัดการข้อมูลที่ภาษาไทย)
                                        </p>
                                    </FormGroup>
                                }
                            </Col>
                            <Col>
                                {this.props.lang === 'th' ?
                                    <FormGroup>
                                        <Label className="font-style-16 cl-black" for="Longitude">พิกัดลองจิจูด </Label><Label className="required"> *</Label>
                                        <Input style={{ fontSize: 16 }} required type="number" className="form-control" id="Longitude" placeholder="Longitude" value={parseFloat(this.props.data.Longitude)} onChange={(e) => { this.latlngHandle('lng', e.target.value) }} />
                                    </FormGroup>
                                    :
                                    <FormGroup>
                                        <Label className="font-style-16 cl-black" for="Longitude">พิกัดลองจิจูด </Label><Label className="required"> *</Label>
                                        <p className="font-style-16" style={{ color: '#173865' }}>
                                            (ให้จัดการข้อมูลที่ภาษาไทย)
                                        </p>
                                    </FormGroup>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col lg="12">
                        {this.props.lang === 'th' ?
                            <Row className={'mt-3 text-16'}>
                                <Col lg="12">
                                    <Label className="font-style-16 cl-black">รูปภาพแผนที่ </Label><Label className="required"> *  {this.state.upload_images_loading && (<font color="green">โปรดรอกำลังอัพโหลดรูปภาพ</font>)}</Label>
                                    <div style={{ display: typeof this.props.data.MapImage.HashName != 'undefined' ? 'none' : '' }} className="custom-file" >
                                        <input
                                            onChange={e => {
                                                this.setState({
                                                    upload_images_loading: true
                                                }, () => {
                                                    this.handleAcceptedFiles(e.target.files)
                                                    e.target.value = ""
                                                }
                                                )
                                            }}
                                            accept=".jpeg,.gif,.png,.jpeg"
                                            type="file"
                                            className="custom-file-input"
                                            id="customFile"
                                        />
                                        <label className="custom-file-label" htmlFor="customFile">
                                            <span className="font-style-16b4 cl-gray">Choose file</span>
                                            {/* {this.state.selectedFiles.length < 1 ?
                                        <span> Choose file</span>
                                        :
                                        <div>
                                            {this.state.selectedFiles.map(f => {
                                                return (<span style={{ marginRight: 10 }}>{f.name}</span>)
                                            })}
                                        </div>
                                    } */}
                                        </label>
                                        <p className="mt-3 font-style-14 cl-darkblue">ขนาดไฟล์ไม่เกิน 2 MB, เฉพาะไฟล์นามสกุล: .jpg, .jpeg, .gif, .png และขนาดไฟล์ที่เหมาะสม: 800 x 600 pixel</p>
                                    </div>
                                    {typeof this.props.data.MapImage.HashName != 'undefined' && (
                                        <div>
                                            <div style={{ textAlign: 'center' }}>
                                                <img style={{ maxWidth: 350 }} src={`${endpoint}/assets/upload${this.props.data.MapImage.Path}/${this.props.data.MapImage.HashName}`}></img>
                                                <div style={{ marginTop: 25 }}>
                                                    <button onClick={() => {
                                                        let data
                                                        this.props.callback({}, 'MapImage')
                                                        this.props.submit('MapImage', {})
                                                    }} type="button" class="btn btn-danger waves-effect waves-light">ยกเลิกไฟล์</button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            :
                            <Row className={'mt-3'}>
                                <Col lg="12">
                                    <Label className="font-style-16 cl-black">รูปภาพแผนที่ </Label><Label className="required"> *</Label>
                                    <p className="font-style-16" style={{ color: '#173865' }}>
                                        (ให้จัดการข้อมูลที่ภาษาไทย)
                                    </p>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </div>
        )
    }
}
