import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,


} from "reactstrap"

export default class Rates extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: this.props.lang,
            Info: this.props.Info,
            HasCost: this.props.Info.HasCost,
            // : this.props.info,info
            // HasCost: this.props.data.HasCost,
            // CostForeignAdult: this.props.info.CostForeignAdult,
            // CostForeignChild: this.props.info.CostForeignChild,
            // CostThaiAdult: this.props.info.CostThaiAdult,
            // CostThaiChild: this.props.info.CostThaiChild,
            // LabelAmount: this.props.info.LabelAmount,
            Noneprice: true,
            Price: false,
            lang: this.props.lang
        }
        console.log(this.props)
    }
    // handleChange = newValue => {
    //     this.setState({ value: newValue })
    // }
    selectedHandle(data, type) {
        console.log(data)

        if (type === 'HasCost') {
            console.log(data)
            this.props.callback(data, 'HasCost')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'CostForeignAdult') {
            this.props.callback(data, 'CostForeignAdult')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'CostThaiAdult') {
            this.props.callback(data, 'CostThaiAdult')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'CostForeignChild') {
            this.props.callback(data, 'CostForeignChild')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'CostThaiChild') {
            this.props.callback(data, 'CostThaiChild')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'LabelAmount') {
            this.props.callback(data, 'LabelAmount')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }


    }


    render() {
        return (
            <div>
                <Label className={'mb-3 text-24'}>อัตราค่าเข้าชมและเวลาเข้าชม</Label>
                {this.state.lang === 'th' ?
                    <Row>
                        <Col lg={12} className={'mt-3 '}>
                            <Row>
                                <Col lg={9} className={'mt-3 '}>
                                    <Row>
                                        <Col lg={4} style={{ zIndex: '0' }}>
                                            <div className="custom-control custom-radio mb-3">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    id={'HasCost'}
                                                    onChange={() => false}
                                                    checked={this.state.Info.HasCost === 0 ? true : false}
                                                />
                                                <label
                                                    className="custom-control-label font-style-16 cl-black"
                                                    onClick={() => {
                                                        this.selectedHandle(0, 'HasCost')
                                                    }}
                                                >
                                                    ไม่มี
                                                </label>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="custom-control custom-radio mb-3">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    id={'HasCost'}
                                                    onChange={() => false}
                                                    checked={this.state.Info.HasCost === 1 ? true : false}
                                                />
                                                <label
                                                    className="custom-control-label font-style-16 cl-black"
                                                    onClick={() => {
                                                        this.selectedHandle(1, 'HasCost')
                                                    }}
                                                >
                                                    มี
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    :
                    <div>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }


                {this.state.Info.HasCost === 1 ?
                    <div className="font-style-16 cl-black">
                        <div>
                            {this.state.lang === 'th' ?
                                <blockquote className="p-4 border-dashed rounded mb-3 mt-3 " style={{ backgroundColor: '#FAFAFA' }}>
                                    <div>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="agencyName">ต่างชาติ ผู้ใหญ่ (บาท)</Label>
                                                    <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="CostForeignAdult" placeholder="ต่างชาติ ผู้ใหญ่ (บาท)" value={this.state.Info.CostForeignAdult} onChange={(e) => { this.props.callback(e.target.value, 'CostForeignAdult') }} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="agencyName">ไทย ผู้ใหญ่ (บาท)</Label>
                                                    <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="agencyName" placeholder="ไทย ผู้ใหญ่ (บาท)" value={this.state.Info.CostThaiAdult} onChange={(e) => { this.props.callback(e.target.value, 'CostThaiAdult') }} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="agencyName">ต่างชาติ เด็ก (บาท)</Label>
                                                    <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="agencyName" placeholder="ต่างชาติ เด็ก (บาท)" value={this.state.Info.CostForeignChild} onChange={(e) => { this.props.callback(e.target.value, 'CostForeignChild') }} />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="agencyName">ไทย เด็ก (บาท)</Label>
                                                    <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="agencyName" placeholder="ไทย เด็ก (บาท)" value={this.state.Info.CostThaiChild} onChange={(e) => { this.props.callback(e.target.value, 'CostThaiChild') }} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </blockquote>
                                :
                                null
                            }
                        </div>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <Label for="agencyName">หมายเหตุอัตราค่าเข้าชม</Label>
                                    <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="agencyName" placeholder="หมายเหตุอัตราค่าเข้าชม" value={this.props.data.CostRemark} onChange={(e) => { this.props.callback(e.target.value, 'CostRemark') }} />
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <Label for="agencyName">เวลาโดยประมาณที่ใช้เที่ยวสถานที่นี้</Label>
                                    <Input required type="text" className="form-control" id="agencyName" placeholder="เวลาโดยประมาณที่ใช้เที่ยวสถานที่นี้" onChange={(e) => { this.handleChange(e, undefined) }} />
                                </FormGroup>
                            </Col>
                        </Row> */}
                    </div>
                    :
                    <div></div>
                }
            </div>
        )
    }
}
