import React, { Component } from "react"
import { Card, CardBody, CardTitle, Col, Container, Row, Button } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// Charts
import Line from "./allchart/linechart"
import LineBar from "./allchart/linebarchart"
import Doughnut from "./allchart/doughnutchart"
import Pie from "./allchart/piechart"
import BarBasic from "./allchart/barchart"
import Stack from "./allchart/stackchart"
// import Scatter from "./allchart/scatterchart"
// import Bubble from "./allchart/bubblechart"
// import Gauge from "./allchart/gaugechart"
// import Candlestick from "./allchart/candlestickchart"

class AllDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      select_date: {
        StartDate: null,
        EndDate: null
      },
      Limit: 10,
    }

  }

  handleChange = (dateType, event) => {
    const { select_date } = this.state;
    const newDateValue = event.target.value;

    this.setState({
      select_date: {
        ...select_date,
        [dateType]: newDateValue
      }
    });
  };

  clearFilter() {
    this.setState({
      select_date: {
        StartDate: null,
        EndDate: null
      },
    })
  }
  render() {
    const { StartDate, EndDate } = this.state.select_date;
    const { Limit } = this.state;
    if (!StartDate || !EndDate) {
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid={true}>
              <Row className={'mt-3'}>
                <Col lg={4}>
                  <div className="form-group">
                    <label className="font-style-16 cl-black">วันที่เริ่มต้น</label>
                    <input
                      type="date"
                      value={StartDate || ''}
                      onChange={(e) => this.handleChange('StartDate', e)}
                      placeholder="วันที่เริ่มต้น"
                      className="form-control"
                      style={{ fontSize: 16 }}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="form-group">
                    <label className="font-style-16 cl-black">วันที่สิ้นสุด</label>
                    <input
                      type="date"
                      value={EndDate || ''}
                      onChange={(e) => this.handleChange('EndDate', e)}
                      placeholder="วันที่สิ้นสุด"
                      className="form-control"
                      style={{ fontSize: 16 }}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="form-group">
                    <Button
                      style={{ backgroundColor: '#FFFFFF', borderColor: '#808080', marginTop: '1.95rem' }}
                      type="submit"
                      onClick={() => { this.clearFilter() }}
                      className="chat-send w-md waves-effect waves-light"
                    >
                      <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ล้างค่าค้นหา</span>{" "}
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6" >
                  <Card>
                    <CardBody>
                      <CardTitle style={{ fontSize: 16 }}>จำนวนข้อมูลแยกตามประเภท</CardTitle>
                      <div id="bar-chart" className="e-chart">
                        <BarBasic
                          StartDate={StartDate}
                          EndDate={EndDate}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" >
                  <Card>
                    <CardBody>
                      <CardTitle style={{ fontSize: 16 }}>แจ้งปรับปรุงข้อมูล</CardTitle>
                      <div id="pie-chart" className="e-chart">
                        <Pie
                          StartDate={StartDate}
                          EndDate={EndDate}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <CardTitle style={{ fontSize: 16 }}>การเรียกดูข้อมูลของสมาชิกแต่ละกลุ่ม (10 อันดับมากที่สุด)</CardTitle>
                      <div id="bar-chart" className="e-chart">
                        <Stack
                          key={`${StartDate}-${EndDate}`}
                          StartDate={StartDate}
                          EndDate={EndDate}
                          Limit={Limit}
                          Sort={-1} />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6">
                  <Card>
                    <CardBody>
                      <CardTitle style={{ fontSize: 16 }}>การเรียกดูข้อมูลของสมาชิกแต่ละกลุ่ม (10 อันดับน้อยที่สุด)</CardTitle>
                      <div id="bar-chart" className="e-chart">
                        <Stack
                          key={`${StartDate}-${EndDate}`}
                          StartDate={StartDate}
                          EndDate={EndDate}
                          Limit={Limit}
                          Sort={1} />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            {/* Render Breadcrumb */}
            {/* <Breadcrumbs title="Charts" breadcrumbItem="E-Charts" /> */}
            <Row className={'mt-3'}>
              <Col lg={4}>
                <div className="form-group">
                  <label className="font-style-16 cl-black">วันที่เริ่มต้น</label>
                  <input
                    type="date"
                    value={StartDate || ''}
                    onChange={(e) => this.handleChange('StartDate', e)}
                    placeholder="วันที่เริ่มต้น"
                    className="form-control"
                    style={{ fontSize: 16 }}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group">
                  <label className="font-style-16 cl-black">วันที่สิ้นสุด</label>
                  <input
                    type="date"
                    value={EndDate || ''}
                    onChange={(e) => this.handleChange('EndDate', e)}
                    placeholder="วันที่สิ้นสุด"
                    className="form-control"
                    style={{ fontSize: 16 }}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group">
                  <Button
                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080', marginTop: '1.95rem' }}
                    type="submit"
                    onClick={() => { this.clearFilter() }}
                    className="chat-send w-md waves-effect waves-light"
                  >
                    <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ล้างค่าค้นหา</span>{" "}
                  </Button>
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <CardTitle style={{ fontSize: 16 }}>การเข้าใช้งานเว็บไซด์</CardTitle>
                    <div id="line-chart" className="e-chart">
                      <Line />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <CardTitle style={{ fontSize: 16 }}>หน้าที่ถูกเรียกใช้งานบ่อยที่สุด</CardTitle>
                    <div id="mix-line-bar" className="e-chart">
                      <LineBar />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
            <Row>
              {/* <Col lg="6">
                <Card>
                  <CardBody>
                    <CardTitle style={{ fontSize: 16 }}>ข้อมูลประเภท</CardTitle>
                    <div id="doughnut-chart" className="e-chart">
                      <Doughnut />
                    </div>
                  </CardBody>
                </Card>
              </Col> */}
              <Col lg="6" >
                <Card>
                  <CardBody>
                    <CardTitle style={{ fontSize: 16 }}>จำนวนข้อมูลแยกตามประเภท</CardTitle>
                    <div id="bar-chart" className="e-chart">
                      <BarBasic
                        key={`${StartDate}-${EndDate}`}
                        StartDate={StartDate}
                        EndDate={EndDate}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" >
                <Card>
                  <CardBody>
                    <CardTitle style={{ fontSize: 16 }}>แจ้งปรับปรุงข้อมูล</CardTitle>
                    <div id="pie-chart" className="e-chart">
                      <Pie
                        key={`${StartDate}-${EndDate}`}
                        StartDate={StartDate}
                        EndDate={EndDate}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <CardTitle style={{ fontSize: 16 }}>การเรียกดูข้อมูลของสมาชิกแต่ละกลุ่ม (10 อันดับมากที่สุด)</CardTitle>
                    <div id="bar-chart" className="e-chart">
                      <Stack
                        key={`${StartDate}-${EndDate}`}
                        StartDate={StartDate}
                        EndDate={EndDate}
                        Limit={Limit}
                        Sort={-1} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <CardTitle style={{ fontSize: 16 }}>การเรียกดูข้อมูลของสมาชิกแต่ละกลุ่ม (10 อันดับน้อยที่สุด)</CardTitle>
                    <div id="bar-chart" className="e-chart">
                      <Stack
                        key={`${StartDate}-${EndDate}`}
                        StartDate={StartDate}
                        EndDate={EndDate}
                        Limit={Limit}
                        Sort={1} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* <Row>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <CardTitle>Scatter Chart</CardTitle>
                    <div id="scatter-chart" className="e-chart">
                      <Scatter />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <CardTitle>Bubble Chart</CardTitle>
                    <div id="bubble-chart" className="e-chart">
                      <Bubble />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <CardTitle>Candlestick Chart</CardTitle>
                    <div id="candlestick-chart" className="e-chart">
                      <Candlestick />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card>
                  <CardBody>
                    <CardTitle>Gauge Chart</CardTitle>
                    <div id="gauge-chart" className="e-chart">
                      <Gauge />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default AllDashboard
