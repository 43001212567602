import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MaterialTable from 'material-table';
import { Card, CardBody, CardSubtitle, CardTitle, Col, Row, FormGroup, Button } from "reactstrap"
import { api_endpoint } from '../../global-config'
import service from './data.js';
import pdfMake from 'pdfmake/build/pdfmake';
import {
    createMuiTheme,
    MuiThemeProvider
} from "@material-ui/core/styles";

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Prompt',
            // 'BlinkMacSystemFont',
            // '"Segoe UI"',
            // 'Roboto',
            // '"Helvetica Neue"',
            // 'Arial',
            // 'sans-serif',
            // '"Apple Color Emoji"',
            // '"Segoe UI Emoji"',
            // '"Segoe UI Symbol"',
        ].join(','),
    },
});

export default class UpdatedMaininfosTable extends Component {
    constructor(props) {
        super(props);
        this.employees = service.getEmployees();
        this.state = {
            selected_date_start: null,
            selected_date_end: null
        }
        // console.log(this.employees);
    }

    async getDataUpdatedMaininfos() {
        const response = await fetch(
            api_endpoint + `/stats/updated_maininfos`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    UpdatedBegin: this.state.selected_date_start === "" ? null : this.state.selected_date_start,
                    UpdatedEnd: this.state.selected_date_end === "" ? null : this.state.selected_date_end,
                }),
            }
        );

        var _result = await response.json();
        // console.log(_result)
        if (_result !== undefined) {
            let arr = []
            _result.map((e) => {
                arr.push(e)
            })
            this.setState({
                countData: _result.count,
                dataTable: arr,
                isLoading: false
            }, () => {
                // console.log(this.state.dataTable)
            })
        }
        else {
            // console.log('error')
        }

    }

    componentDidMount() {
        this.getDataUpdatedMaininfos()
    }

    render() {
        return (
            <Card>
                <CardBody>
                    <Row className={'mt-3'}>

                        <Col lg={4}>
                            <div className="form-group">
                                <label className="font-style-16 cl-black">ปรับปรุงตั้งแต่วันที่</label>
                                <input type="month" value={this.state.selected_date_start} onChange={(e) => {
                                    this.setState({
                                        selected_date_start: e.target.value
                                    })
                                }} placeholder="ปรับปรุงตั้งแต่วันที่" className="form-control" style={{ fontSize: 16 }}></input>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="form-group">
                                <label className="font-style-16 cl-black">ถึงวันที่</label>
                                <input type="month" value={this.state.selected_date_end} onChange={(e) => {
                                    this.setState({
                                        selected_date_end: e.target.value
                                    })
                                }} placeholder="ถึงวันที่" className="form-control" style={{ fontSize: 16 }} ></input>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="form-group">
                                <Button
                                    style={{ backgroundColor: '#173865', borderColor: '#173865', marginTop: '1.95rem' }}
                                    type="submit"
                                    onClick={() => { this.getDataUpdatedMaininfos() }}
                                    className="chat-send w-md waves-effect waves-light"
                                >
                                    <span className="d-none d-sm-inline-block font-style-16nb">เลือก</span>{" "}

                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <MuiThemeProvider theme={theme}>
                        <MaterialTable
                            title="จำนวนการเพิ่มเติมปรับปรุงและได้รับการอนุมัติให้เผยแพร่"
                            columns={[
                                { title: 'ภาค', field: 'Region' },
                                { title: 'จังหวัด', field: 'Name' },
                                { title: 'รวม', field: 'Sum' },
                                { title: 'แหล่งท่องเที่ยว', field: '1' },
                                { title: 'กิจกรรมท่องเที่ยว', field: '2' },
                                { title: 'ที่พัก', field: '4' },
                                { title: 'สปา', field: '5' },
                                { title: 'ร้านอาหาร', field: '6' },
                                { title: 'ร้านค้าของฝาก', field: '7' },
                                { title: 'เส้นทางท่องเที่ยว', field: '9' },
                            ]}
                            data={this.state.dataTable}
                            options={{
                                pageSize: 77,
                                exportButton: true,
                                exportPdf: (columns, data) => {
                                    const filteredColumns = columns.filter(
                                        (c) => typeof c.field === 'string' && typeof c.title === 'string'
                                    );
                                    const fields = filteredColumns.map((c) => c.field);

                                    const docDefinition = {
                                        content: [
                                            {
                                                layout: 'lightHorizontalLines',
                                                table: {
                                                    headerRows: 1,
                                                    widths: filteredColumns.map(() => '*'),

                                                    body: [
                                                        filteredColumns.map((c) => c.title),
                                                        ...data.map((row) => fields.map((f) => row[f])),
                                                    ],
                                                },
                                            },
                                        ],
                                        defaultStyle: {
                                            font: 'THSarabunNew'
                                        },
                                        pageSize: 'A3',
                                        pageOrientation: 'landscape'
                                    };

                                    pdfMake.fonts = {
                                        THSarabunNew: {
                                            normal: 'https://admin.thailandtourismdirectory.go.th/fonts/THSarabunNew.ttf',
                                            bold: 'https://admin.thailandtourismdirectory.go.th/fonts/THSarabunNewBold.ttf',
                                            italics: 'https://admin.thailandtourismdirectory.go.th/fonts/THSarabunNewItalic.ttf',
                                            bolditalics: 'https://admin.thailandtourismdirectory.go.th/fonts/THSarabunNewBoldItalic.ttf'
                                        },

                                        Roboto: {
                                            normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                                            bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                                            italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                                            bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
                                        }
                                    }
                                    pdfMake.createPdf(docDefinition).download('จำนวนการเพิ่มเติมปรับปรุงและได้รับการอนุมัติให้เผยแพร่');
                                }
                            }}

                        />
                    </MuiThemeProvider>



                </CardBody>
            </Card>




        )
    }
}
