import React, { Component } from 'react'
import PropTypes, { element } from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { Link } from "react-router-dom"
import { api_endpoint } from '../../../global-config'

import Map from "../../Dashboard-blog/MapsGoogle"
import Facilities from './topicAccommodationTap/Facilities'
import Utilities from './topic/Utilities'
import Journey from './topic/Journey'
import Activity from './topic/Activity'
import More from './topic/More'
import Status from './topicCampaign/Status'
import General from './topicCampaign/General'
import Address from './topicAccommodationTap/Address'
import Contact from './topic/Contact'
import File from './topicContent/File'
import Recommend from './topicCampaign/Recommend'
import Picturesvideos from './topic/Picturesvideos'
import Standard from './topicAccommodationTap/Standard'
import Room from './topicAccommodationTap/room'
import DateTime from './topic/DateTime'
import Rates from './topic/Rates'
import Target from './topicAccommodationTap/Target'
import * as loadingData from "../../../loadingttd.json";
import Lottie from "react-lottie";
import FadeIn from "react-fade-in";
import Feedback from './topicAll/Feedback'

const moment = require('moment'); // require
import localization from 'moment/locale/th';
moment.updateLocale('th', localization);


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export default class CampaignTap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            IDEditter: null,
            IsEnabled: "Y",
            // activeTabJustify: "5",
            lang: props.lang,
            isLoading: false,
            isLoadingDetail: false,
            placeId: null,
            Type: "CP",
            PublishDate: null,
            PublishDown: null,
            Details: {
                lang: "th",
                Title: "",
                IntroText: "",
            },
            th: {
                CoverImages: {},
                CoverBackgroundImages: {},
                // IntroImage: {},
                // BackgroundImage: {},
                Title: "",
                IntroText: ""
            },
            en: {
                Title: "",
                IntroText: "",
            },
            zh: {
                Title: "",
                IntroText: ""
            },
            MainInfo: [],
            Submit: {},
            status: null,
        }
        this.callback = this.callback.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    async getCampaignDetail(id) {
        let path = `/contentinfo/readone`
        // if (this.state.status !== null) { path = '/maininfo/readoneupdate' }
        const userData = JSON.parse(localStorage.getItem('authUser'))
        const response = await fetch(
            api_endpoint + path,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                    ID: Number(id),
                    // IsBackend: 'Y'
                }), // body data type must match "Content-Type" header
            }
        );

        var _result = await response.json();
        if (_result.result !== undefined) {
            console.log(_result)
            // console.log(JSON.stringify(_result))

            let MainInfo = []
            _result.result.MainInfo.map(e => {
                MainInfo.push({
                    id: e.ID, Name: e.Name[this.state.lang],
                    Province: e.Province[this.state.lang],
                    District: e.District[this.state.lang],
                    MainInfoType: e.MainInfoType[this.state.lang].Name,
                    IntroImage: e.IntroImage
                })
            })
            // let Images = []
            // _result.result.Images.map(e => {
            //     Images.push(e)
            // })
            // console.log(Images)          
            let th = this.state.th
            let en = this.state.en
            let zh = this.state.zh
            if (this.state.lang === 'th') {
                th = {
                    ...this.state.th,
                    CoverImages: _result.result.Main.Info.IntroImage,
                    CoverBackgroundImages: _result.result.Main.Info.BackgroundImage,
                    Title: _result.result.Title[this.state.lang],
                    IntroText: _result.result.IntroText[this.state.lang],
                    Type: _result.result.Type,
                    // PublishDate: _result.result.PublishDate,
                    // PublishDown: _result.result.PublishDown
                }

            }
            if (this.state.lang === 'en') {
                en = {
                    ...this.state.en,
                    CoverImages: _result.result.Main.Info.IntroImage,
                    CoverBackgroundImages: _result.result.Main.Info.BackgroundImage,
                    Title: _result.result.Title[this.state.lang],
                    IntroText: _result.result.IntroText[this.state.lang],
                    Type: _result.result.Type,
                    // PublishDate: _result.result.PublishDate,
                    // PublishDown: _result.result.PublishDown
                }
            }
            if (this.state.lang === 'zh') {
                zh = {
                    ...this.state.en,
                    CoverImages: _result.result.Main.Info.IntroImage,
                    CoverBackgroundImages: _result.result.Main.Info.BackgroundImage,
                    Title: _result.result.Title[this.state.lang],
                    IntroText: _result.result.IntroText[this.state.lang],
                    Type: _result.result.Type,
                    // PublishDate: _result.result.PublishDate,
                    // PublishDown: _result.result.PublishDown
                }
            }
            this.state.PublishDate = _result.result.Main.Info.PublishDate,
                this.state.PublishDown = _result.result.Main.Info.PublishDown,
                this.state.IDEditter = _result.result.ID,
                this.state.IsEnabled = _result.result.IsEnabled,
                this.state.Feedback = _result.result.Feedback,
                this.setState({
                    [this.state.lang]: {
                        ...this.state[this.state.lang],
                        Title: _result.result.Title[this.state.lang],
                        IntroText: _result.result.IntroText[this.state.lang],
                        PublishDate: this.state.PublishDate,
                        PublishDown: this.state.PublishDown,
                        CoverImages: _result.result.Main.Info.IntroImage,
                        CoverBackgroundImages: _result.result.Main.Info.BackgroundImage
                    },
                    // Info: {
                    //     SpaRegistrationTypeID: this.state.Spainfo.SpaRegistrationTypeID,
                    //     LicenseNo: this.state.Spainfo.LicenseNo,
                    //     LicenseDate: this.state.Spainfo.LicenseDate,
                    //     LicenseExpireDate: this.state.Spainfo.LicenseExpireDate,
                    // },
                    // IsEnabled: this.state.IsEnabled,
                    MainInfo: MainInfo,
                    isLoadingDetail: false,
                }, () => {
                    console.log(this.state.th.CoverImages)
                    // console.log(_result.result.IsEnabled)
                })
        } else {
            console.log('Error : getCampaignDetail ')
        }

    }

    async submitData() {
        if (this.state[this.state.lang].Title === "") {
            return alert('กรุณาใส่ชื่อ')
        }
        if (this.state[this.state.lang].IntroText === "") {
            return alert('กรุณาใส่ชื่อรอง')
        }
        if (this.state.Submit.IntroImage === undefined) {
            return alert('กรุณาใส่รูปพื้นหลัง')
        }
        if (this.state.Submit.BackgroundImage === undefined) {
            return alert('กรุณาใส่รูป Cover')
        }
        let IsEnabled = this.state.IsEnabled
        let Feedback = this.state.Feedback
        const userData = JSON.parse(localStorage.getItem('authUser'))
        if (userData.UserSubType === "Editor") {
            console.log('Editer >>>>')
            IsEnabled = "N"
            console.log(IsEnabled)
        }
        let obj = {
            IsEnabled: IsEnabled,
            Feedback: Feedback,
            // ID: userData.UserID,
            Main: {
                Info: {
                    ContentCategoryID: 4,
                    Type: this.state.Type,
                    PublishDate: this.state.PublishDate,
                    PublishDown: this.state.PublishDown,
                    // HashName: this.state.Submit.IntroImage.HashName,
                    // Path: this.state.Submit.IntroImage.Path,
                    IntroImage: this.state.Submit.IntroImage,
                    BackgroundImage: this.state.Submit.BackgroundImage,
                },
                Details: {
                    [this.state.lang]: {
                        Lang: this.state.lang,
                        Title: this.state[this.state.lang].Title,
                        IntroText: this.state[this.state.lang].IntroText,
                    },
                    // en: {
                    //     Lang: this.state.lang,
                    //     Title: this.state[this.state.lang].Title,
                    //     IntroText: this.state[this.state.lang].IntroText,
                    // },
                    // zh: {
                    //     Lang: this.state.lang,
                    //     Title: this.state[this.state.lang].Title,
                    //     IntroText: this.state[this.state.lang].IntroText,
                    // }
                },
            },
            MainInfo: this.state.Submit.MainInfo
        }
        //ปิดส่ง
        console.log(JSON.stringify(obj))
        // return
        const response = await fetch(
            api_endpoint + `/contentinfo/create`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (_result.status === "ok") {
            alert('เพิ่ม Campaign สำเร็จ')
            window.location.href = "/Campaign";
        }
        else {
            console.log('error : submitData')
        }
    }

    async updateData() {
        const userData = JSON.parse(localStorage.getItem('authUser'))
        let obj = {}
        if (this.state.lang === 'th') {
            obj = {
                ID: Number(this.state.placeId),
                IsEnabled: this.state.IsEnabled,
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        ContentCategoryID: 4,
                        Type: this.state.Type,
                        PublishDate: this.state.PublishDate,
                        PublishDown: this.state.PublishDown,
                        // HashName: this.state.Submit.IntroImage.HashName,
                        // Path: this.state.Submit.IntroImage.Path,
                        IntroImage: this.state.th.CoverImages !== this.state.th.CoverImages ? this.state.submit.IntroImage : this.state.th.CoverImages,
                        BackgroundImage: this.state.th.CoverBackgroundImages !== this.state.th.CoverBackgroundImages ? this.state.submit.BackgroundImages : this.state.th.CoverBackgroundImages,
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Title: this.state[this.state.lang].Title,
                            IntroText: this.state[this.state.lang].IntroText,
                        },
                        // en: {
                        //     Lang: this.state.lang,
                        //     Title: this.state[this.state.lang].Title,
                        //     IntroText: this.state[this.state.lang].IntroText,
                        // },
                        // zh: {
                        //     Lang: this.state.lang,
                        //     Title: this.state[this.state.lang].Title,
                        //     IntroText: this.state[this.state.lang].IntroText,
                        // }
                    },
                },
                MainInfo: this.state.Submit.MainInfo,
            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }
        }
        if (this.state.lang === 'en') {
            obj = {
                ID: Number(this.state.placeId),
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        ContentCategoryID: 4,
                        Type: this.state.Type,
                        PublishDate: this.state.PublishDate,
                        PublishDown: this.state.PublishDown,
                        // HashName: this.state.Submit.IntroImage.HashName,
                        // Path: this.state.Submit.IntroImage.Path,
                        // IntroImage: this.state.Submit.IntroImage,
                        // BackgroundImage: this.state.Submit.BackgroundImage,
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Title: this.state[this.state.lang].Title,
                            IntroText: this.state[this.state.lang].IntroText,
                        },
                        // en: {
                        //     Lang: this.state.lang,
                        //     Title: this.state[this.state.lang].Title,
                        //     IntroText: this.state[this.state.lang].IntroText,
                        // },
                        // zh: {
                        //     Lang: this.state.lang,
                        //     Title: this.state[this.state.lang].Title,
                        //     IntroText: this.state[this.state.lang].IntroText,
                        // }
                    },
                },
                MainInfo: this.state.Submit.MainInfo,
            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }

        }
        if (this.state.lang === 'zh') {
            obj = {
                ID: Number(this.state.placeId),
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        ContentCategoryID: 4,
                        Type: this.state.Type,
                        PublishDate: this.state.PublishDate,
                        PublishDown: this.state.PublishDown,
                        // HashName: this.state.Submit.IntroImage.HashName,
                        // Path: this.state.Submit.IntroImage.Path,
                        // IntroImage: this.state.Submit.IntroImage,
                        // BackgroundImage: this.state.Submit.BackgroundImage,
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Title: this.state[this.state.lang].Title,
                            IntroText: this.state[this.state.lang].IntroText,
                        },
                        // en: {
                        //     Lang: this.state.lang,
                        //     Title: this.state[this.state.lang].Title,
                        //     IntroText: this.state[this.state.lang].IntroText,
                        // },
                        // zh: {
                        //     Lang: this.state.lang,
                        //     Title: this.state[this.state.lang].Title,
                        //     IntroText: this.state[this.state.lang].IntroText,
                        // }
                    },
                },
                MainInfo: this.state.Submit.MainInfo,
            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }
        }
        //ปิดส่ง
        console.log(JSON.stringify(obj))
        // return
        const response = await fetch(
            api_endpoint + `/contentinfo/update`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (_result.status === "ok") {
            alert('แก้ไข Campaign สำเร็จ')
            // window.location.href = "/Campaign";

        }
        else {
            console.log('error : updateData')
        }
    }

    callback(value, target) {
        if (target === 'IsEnabled') {
            this.state.IsEnabled = value
            this.setState({
                IsEnabled: this.state.IsEnabled
            }, () => {
                console.log(this.state.IsEnabled)
            })
            return
        }
        if (target === 'Feedback') {
            this.setState({
                Feedback: value
            }, () => {
                console.log(this.state.IsEnabled)
            })
            return
        }
        if (target === 'PublishDate') {
            console.log(value)
            this.setState({
                PublishDate: value
            }, () => {
                console.log(this.state.PublishDate)
            })
            return
        }
        if (target === 'PublishDown') {
            this.setState({
                PublishDown: value
            }, () => {
                console.log(this.state.PublishDown)
            })
            return
        }
        let obj = this.state[this.state.lang]
        obj[target] = value;
        console.log(obj)
        this.setState({
            [this.state.lang]: obj
        })
    }
    submitForm(target, value) {
        if (this.state.Submit[target] === undefined) {
            this.state.Submit[target] = value
        }
        else {
            this.state.Submit[target] = value
        }
        console.log(this.state.Submit)

    }

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const id = params.get('id');
        const status = params.get('status');

        if (id !== null) {
            this.setState({
                placeId: id,
                status: status,
                cloneId: id,
                isLoadingDetail: true

            }, () => {
                this.getCampaignDetail(id)

            })
        }
        else {
            // this.attraction_types()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.lang) {
            this.setState({
                lang: nextProps.lang
            }, () => {
            })
        }
    }



    render() {
        // console.log(this.state.Images)
        if (this.state.isLoading === true || this.state.isLoadingDetail === true) {
            return (
                <div className="text-center" >
                    <FadeIn>
                        <div style={{ display: "flex", justifyContent: 'center' }}>
                            <div >
                                <Lottie options={defaultOptions} height={300} width={300} />
                                <div>
                                    <h4 style={{ color: 'black', padding: 30 }}>กำลังโหลด</h4>
                                </div>
                            </div>
                        </div>
                    </FadeIn>
                </div>
            )
        }
        else {
            const userData = JSON.parse(localStorage.getItem('authUser'))
            console.log(userData)
            console.log(this.state.status)
            return (

                <div>
                    <Form onSubmit={() => { this.test() }}>
                        <Card>
                            <CardBody>
                                <General callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} submit={this.submitForm} />
                            </CardBody>
                        </Card>
                    </Form>
                    <Card>
                        <CardBody>
                            <Recommend lang={this.state.lang} submit={this.submitForm} mainInfo={this.state.MainInfo} />
                        </CardBody>
                    </Card>
                    {this.state.status === 'Y' ?
                        (userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ?
                            <Card>
                                <CardBody>
                                    <Feedback callback={this.callback} lang={this.state.lang} disabled={true} data={this.state} submit={this.submitForm} />
                                </CardBody>
                            </Card>
                            :
                            <Card>
                                <CardBody>
                                    <Feedback callback={this.callback} lang={this.state.lang} disabled={false} data={this.state} submit={this.submitForm} />
                                </CardBody>
                            </Card>
                        :
                        <div />
                    }
                    <div>
                        <Card>
                            <CardBody>
                                {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.placeId === null ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} data={this.state[this.state.lang]} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                                {this.state.placeId !== null && this.state.status !== 'Y' && (userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} data={this.state[this.state.lang]} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                                {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.status === 'Y' ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} data={this.state[this.state.lang]} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                                {this.state.placeId !== null && this.state.status !== 'Y' && userData.UserSubType !== 'Admin' && userData.UserSubType !== 'SuperAdmin' ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} data={this.state[this.state.lang]} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                                {/* < Status /> */}
                                {this.state.placeId === null ?
                                    <Row style={{ justifyContent: 'flex-end' }}>
                                        <div className="col-auto">
                                            <Button
                                                style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                                type="submit"
                                                onClick={() => { this.submitData() }}
                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}

                                            </Button>
                                        </div>
                                        <div className="col-auto">
                                            <Link to="/Campaign">
                                                <Button
                                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                                </Button>
                                            </Link>
                                        </div>
                                    </Row>
                                    :

                                    <div>

                                    </div>
                                }
                                {this.state.placeId !== null && this.state.status !== 'Y' ?
                                    <Row style={{ justifyContent: 'flex-end' }}>
                                        <div className="col-auto">
                                            <Button
                                                style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                                type="submit"
                                                onClick={() => { this.updateData() }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb">แก้ไข</span>{" "}
                                            </Button>
                                        </div>
                                        <div className="col-auto">
                                            <Link to="/Campaign">
                                                <Button
                                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                                </Button>
                                            </Link>
                                        </div>
                                    </Row>
                                    :
                                    <div></div>
                                }
                                {this.state.status === 'Y' && userData.UserSubType === 'Editor' && userData.UserType === 'B' ?
                                    <div></div>
                                    :
                                    <div></div>

                                }
                            </CardBody>
                        </Card>
                    </div>




                    {/* <button onClick={() => { this.submitData() }}>Submit</button> */}

                </div>

            )
        }

    }
}
