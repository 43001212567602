import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
  TabPane,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,

} from "reactstrap"
import { Link } from "react-router-dom"

import Address from "./topicactivities/Address"
import General from './topicactivities/General'
import Contact from './topic/Contact'
import Picturesvideos from './topic/Picturesvideos'
import Price from './topicactivities/Price'
import Target from './topicactivities/Target'
import Journey from './topic/Journey'
import More from './topicactivities/More'
import ActivityStatus from './topic/ActivityStatus'
///
import Status from './topic/Status'
import Feedback from './topicAll/Feedback';
import { api_endpoint } from '../../../global-config'

const moment = require('moment'); // require
import localization from 'moment/locale/th';
moment.updateLocale('th', localization);


export default class Activitiestap1 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      IDEditter: null,
      IsEnabled: "Y",
      ActivityStatus: "EventScheduled",
      // activeTabJustify: "5",
      lang: props.lang,
      isLoading: true,
      isLoadingDetail: false,
      placeId: null,
      Attraction: [],
      StandardTargets: [],
      Facilities: [],
      Utility: [],
      Types: [],
      StandardTypes: [],
      life_style: [],
      activity_categories: [],
      TravelTypeID: [],
      OpenHours: [],
      Images: [],
      ImagesUpload: [],
      provinces: [],
      districts: [],
      subdistricts: [],
      Feedback: "",
      th: {
        Name: '',
        // Activities: [],
        Details: "",
        TravelRemark: "",
        // History: "",
        // AreaSize: "",
        Agenda: "",
        CostRate: "",
        UseTime: "",

        // ParkingDetail: "",
        // ResponseDepartment: "",
        //
        Address: "",
        MapImage: {},
        RegionID: "",
        Province: "",
        District: "",
        County: "",
        Latitude: "",
        Longitude: "",
        //
        Telephone: "",
        Mobile: "",
        Email: "",
        Website: "",
        ContactName: "",
        ContactPosition: "",
        CoverImages: {},
        VdoYoutubeUrl: "",
        MoreDetail: "",
        OpenHourRemark: "",
        // OpencloseRemark: "",
        // businesshours: "",
        // Suitablerange: "",
      },
      en: {
        Name: '',
        Activities: [],
        Details: "",
        // TravelRemark: "",
        History: "",
        // AreaSize: "",
        Agenda: "",
        CostRate: "",
        UseTime: "",
        // ParkingDetail: "",
        // ResponseDepartment: '',
        //
        Address: "",
        RegionID: "",
        Province: "",
        District: "",
        County: "",
        Latitude: "",
        Longitude: "",
        //
        Telephone: "",
        Mobile: "",
        Email: "",
        Website: "",
        ContactName: "",
        ContactPosition: "",
        MoreDetail: "",
        OpenHourRemark: "",
        // OpencloseRemark: "",
        // businesshours: "",
        // Suitablerange: "",

      },
      zh: {
        Name: '',
        Activities: [],
        Details: "",
        // TravelRemark: "",
        History: "",
        // AreaSize: "",
        Agenda: "",
        CostRate: "",
        UseTime: "",
        // ParkingDetail: "",
        // ResponseDepartment: '',
        //
        Address: "",
        RegionID: "",
        Province: "",
        District: "",
        County: "",
        Latitude: "",
        Longitude: "",
        //
        Telephone: "",
        Mobile: "",
        Email: "",
        Website: "",
        ContactName: "",
        ContactPosition: "",
        MoreDetail: "",
        OpenHourRemark: "",
        // OpencloseRemark: "",
        // businesshours: "",
        // Suitablerange: "",

      },
      life_styleSelected: null,
      activity_categoriesSelected: null,
      EndDate: null,
      EndDate: null,
      Submit: {},
      status: null
    }
    this.callback = this.callback.bind(this);
    this.attraction_types = this.attraction_types.bind(this);
    this.attraction_standard_types = this.attraction_standard_types.bind(this);
    this.get_attraction = this.get_attraction.bind(this);
    this.get_attraction_targets = this.get_attraction_targets.bind(this);
    // this.get_attraction_facilities = this.get_attraction_facilities.bind(this);
    // this.attraction_utility_types = this.attraction_utility_types.bind(this);
    this.selectedHandle = this.selectedHandle.bind(this);
    this.submitForm = this.submitForm.bind(this);

  }

  async attraction_types() {
    const response = await fetch(
      api_endpoint + `/lkup/activity_types`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          // ID: this.state.place_id,
          Lang: this.state.lang,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    console.log(_result)
    let types = this.state.Types
    for (let i = 0; i < _result.length; i++) {
      types.forEach((e) => {
        if (e.ID === _result[i].ID) {
          _result[i] = { ..._result[i], isCheck: true }
        }
        else {
          if (_result[i].isCheck === true) {
            return
          }
          _result[i] = { ..._result[i], isCheck: false }
        }
      })
    }

    this.setState({
      Types: _result,
    }, () => {
      this.attraction_standard_types()
    })
  }

  async attraction_standard_types() {
    const response = await fetch(
      api_endpoint + `/lkup/activity_standard_types`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          // ID: this.state.place_id,
          Lang: this.state.lang,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    let types = this.state.StandardTypes
    for (let i = 0; i < _result.length; i++) {
      types.forEach((e) => {
        if (e.ID === _result[i].ID) {
          _result[i] = { ..._result[i], isCheck: true }
        }
        else {
          if (_result[i].isCheck === true) {
            return
          }
          _result[i] = { ..._result[i], isCheck: false }
        }
      })
    }

    this.setState({
      StandardTypes: _result,
    }, () => {
      this.activity_life_style()
    })
  }


  async activity_life_style() {
    const response = await fetch(
      api_endpoint + `/lkup/activity_life_styles`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          // ID: this.state.place_id,
          Lang: this.state.lang,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    console.log(_result)
    if (Array.isArray(_result)) {
      const asyncRes = await Promise.all(_result.map(async (e) => {
        return { label: e.Details[this.state.lang].Name, value: e.ID }
      }));
      this.setState({
        life_style: asyncRes,

      }, () => {
        console.log(this.state.life_style)
        this.activity_categories()

      })
    }
    else {
      console.log('error : getProvinces')
      this.activity_categories()
    }
  }

  async activity_categories() {
    const response = await fetch(
      api_endpoint + `/lkup/activity_categories`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          // ID: this.state.place_id,
          Lang: this.state.lang,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    console.log(_result)
    if (Array.isArray(_result)) {
      const asyncRes = await Promise.all(_result.map(async (e) => {
        return { label: e.Details[this.state.lang].Name, value: e.ID }
      }));
      this.setState({
        activity_categories: asyncRes,

      }, () => {
        console.log(this.state.activity_categories)
        this.get_provinces()

      })
    }
    else {
      console.log('error : getProvinces')
      this.get_provinces()
    }
  }



  async get_provinces() {
    const response = await fetch(
      api_endpoint + `/lkup/provinces`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          RegionID: [],
          Code: [],

          // ID: this.state.place_id,
          Lang: this.state.lang,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();

    for (let i = 0; i < _result.length; i++) {
      if (_result[i].Details[this.state.lang].Name === this.state[this.state.lang].Province) {
        _result[i] = { ..._result[i], isCheck: true }
        let obj = this.state[this.state.lang]
        obj.Province = _result[i].Code
        obj.RegionID = _result[i].RegionID
        this.setState({
          [this.state.lang]: obj
        })
      }
      else {
        _result[i] = { ..._result[i], isCheck: false }
      }
    }
    this.setState({
      provinces: _result
    }, () => {
      this.get_districts()
    })
  }


  async get_districts() {
    if (this.state[this.state.lang].District === "" && this.state[this.state.lang].Province === "") {
      this.get_attraction()
      return
    }
    const response = await fetch(
      api_endpoint + `/lkup/districts`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ProvinceCode: this.state[this.state.lang].Province.toString(),
          // Code: [],

          // ID: this.state.place_id,
          Lang: this.state.lang,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();


    for (let i = 0; i < _result.length; i++) {
      if (_result[i].Details[this.state.lang].Name === this.state[this.state.lang].District) {
        _result[i] = { ..._result[i], isCheck: true }
        let obj = this.state[this.state.lang]
        obj.District = _result[i].Code
        this.setState({
          [this.state.lang]: obj
        })
      }
      else {
        _result[i] = { ..._result[i], isCheck: false }
      }
    }
    this.setState({
      districts: _result,
    }, () => {
      this.get_subdistricts()
    })
  }

  async get_subdistricts() {
    if (this.state[this.state.lang].County === "" && this.state[this.state.lang].District === "") {
      this.get_attraction()
      return
    }
    const response = await fetch(
      api_endpoint + `/lkup/subdistricts`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ProvinceCode: this.state[this.state.lang].Province.toString(),
          DistrictCode: this.state[this.state.lang].District.toString(),

          // Code: [],

          // ID: this.state.place_id,
          Lang: this.state.lang,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    console.log(_result)
    for (let i = 0; i < _result.length; i++) {
      if (_result[i].Details[this.state.lang].Name === this.state[this.state.lang].County) {
        _result[i] = { ..._result[i], isCheck: true }
        let obj = this.state[this.state.lang]
        obj.County = _result[i].Code
        this.setState({
          [this.state.lang]: obj
        })
      }
      else {
        _result[i] = { ..._result[i], isCheck: false }
      }
    }
    this.setState({
      subdistricts: _result,
    }, () => {
      this.get_attraction()
    })
  }




  async get_attraction() {
    const response = await fetch(
      api_endpoint + `/lkup/attraction_activity_types`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          // ID: this.state.place_id,
          Lang: this.state.lang,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();

    this.setState({
      Attraction: _result,
    }, () => {
      this.get_attraction_targets()
    })
  }

  async get_attraction_targets() {
    const response = await fetch(
      api_endpoint + `/lkup/attraction_standard_targets `,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          // ID: this.state.place_id,
          Lang: this.state.lang,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    console.log('กลุ่มเป้าหมาย')
    console.log(_result)
    if (Array.isArray(this.state.StandardTargets)) {
      for (let i = 0; i < _result.length; i++) {
        _result[i] = { ..._result[i], isCheck: false }
      }
      _result.map(e => { this.state.StandardTargets.forEach(element => { if (e.ID === element.ID) { e.isCheck = true } }) })
      this.setState({
        StandardTargets: _result,

      }, () => {
        console.log(this.state.StandardTargets)
        this.get_travel_types()
      })
    }
    else {
      for (let i = 0; i < _result.length; i++) {
        _result[i] = { ..._result[i], isCheck: false }
      }
      this.setState({
        StandardTargets: _result,

      }, () => {
        this.get_travel_types()
      })
    }


  }

  // async get_attraction_facilities() {
  //     const response = await fetch(
  //         api_endpoint + `/lkup/attraction_facilities`,
  //         {
  //             method: "POST",
  //             headers: {
  //                 "Content-Type": "application/json"
  //             },
  //             body: JSON.stringify({
  //                 // ID: this.state.place_id,
  //                 Lang: this.state.lang,
  //             }), // body data type must match "Content-Type" header
  //         }
  //     );
  //     var _result = await response.json();
  //     for (let i = 0; i < _result.length; i++) {
  //         if (_result[i].AttractionFacilityTypeID === 1) {
  //             _result[i] = { ..._result[i], total: 0, quantity: 0, isCheck: false }
  //         }
  //         else {
  //             _result[i] = { ..._result[i], quantity: 0, isCheck: false }
  //         }

  //     }
  //     this.setState({
  //         Facilities: _result,

  //     }, () => {
  //         this.get_travel_types()
  //     })


  // }

  async get_travel_types() {
    const response = await fetch(
      api_endpoint + `/lkup/travel_types`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          // ID: this.state.place_id,
          Lang: this.state.lang,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    if (Array.isArray(this.state.TravelTypeID)) {
      for (let i = 0; i < _result.length; i++) {
        _result[i] = { ..._result[i], isCheck: false }
      }
      _result.map(e => { this.state.TravelTypeID.forEach(element => { if (e.ID === element.ID) { e.isCheck = true } }) })
      this.setState({
        TravelTypeID: _result,
        isLoading: false,

      }, () => {
        // this.attraction_utility_types()
      })
    }
    else {
      for (let i = 0; i < _result.length; i++) {
        _result[i] = { ..._result[i], isCheck: false }
      }
      this.setState({
        TravelTypeID: _result,
        isLoading: false,

      }, () => {
        // this.attraction_utility_types()
      })
    }
  }

  callback(value, target) {
    console.log(value)
    if (target === 'IsEnabled') {
      this.state.IsEnabled = value
      this.setState({
        IsEnabled: this.state.IsEnabled
      }, () => {
        console.log(this.state.IsEnabled)
      })
      return
    }
    if (target === 'ActivityStatus') {
      this.state.ActivityStatus = value
      this.setState({
        ActivityStatus: this.state.ActivityStatus
      }, () => {
        console.log(this.state.IsEnabled)
      })
      return
    }
    if (target === 'Feedback') {
      this.setState({
        Feedback: value
      }, () => {
        console.log(this.state.IsEnabled)
      })
      return
    }
    if (target === 'Latitude') {
      value = parseFloat(value)
    }
    if (target === 'Longitude') {
      value = parseFloat(value)
    }
    if (target === 'StartDate') {
      this.setState({
        StartDate: value
      })
    }
    if (target === 'EndDate') {
      this.setState({
        EndDate: value
      })
    }
    if (target === 'life_style') {
      this.setState({
        life_styleSelected: value,
      })
    }
    if (target === 'activity_categories') {
      this.setState({
        activity_categories: value,
      })
    }

    let obj = this.state[this.state.lang]
    // console.log(this.state.lang)
    obj[target] = value;
    console.log(obj)
    this.setState({
      [this.state.lang]: obj
    }, () => {
      if (target === 'Province') {
        this.get_districts()
      }
      if (target === 'District') {
        this.get_subdistricts()
      }
    })
  }

  submitForm(target, value) {

    if (this.state.Submit[target] === undefined) {
      this.state.Submit[target] = value
    }
    else {
      this.state.Submit[target] = value
    }
    console.log('this.state.Submit')
    console.log(this.state.Submit)

  }

  async getLocationDetail(id) {
    let path = `/maininfo/readone`
    if (this.state.status !== null) { path = '/maininfo/readoneupdate' }
    const userData = JSON.parse(localStorage.getItem('authUser'))
    const response = await fetch(
      api_endpoint + path,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: 'bearer ' + userData.token
        },
        body: JSON.stringify({
          // ID: this.state.place_id,
          Lang: this.state.lang,
          ID: Number(id),
          IsBackend: 'Y'
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    console.log('ค่ามาจาก Api')
    console.log(_result)
    if (_result.result !== undefined) {
      console.log(_result)
      // if (this.state.lang === 'th') {
      let OpenHours = []
      let Attraction = []
      // let lifestyle = {label: 'เลือกหมวดหมู่ Life Style' , value: -1}
      // let activity = {label: 'เลือกหมวดหมู่กิจกรรม' , value: -1}
      // _result.result.Attraction.Activities.map((e, i) => {
      //     Attraction.push({
      //         ActivityName: e.SubType[this.state.lang],
      //         AttractionActivitySubTypeID: e.SubType.ID,
      //         AttractionActivityTypeID: e.ID,
      //         Details: { Lang: this.state.lang, Name: e.SubType[this.state.lang], Detail: "" },
      //         id: i
      //     })

      // })

      // _result.result.OpenHours.Items.map(e => {
      //     OpenHours.push({
      //         DayID: e.DayID,
      //         StartTime: moment(e.StartTime).format('LTS').split(" ")[0],
      //         EndTime: moment(e.EndTime).format('LTS').split(" ")[0],
      //         IsAllDay: null
      //     })

      // })

      let Images = []
      _result.result.Images.map(e => {
        Images.push(e)
      })
      console.log(Images)
      //ActivityName: "ทดสอบ"
      //AttractionActivitySubTypeID: 0
      //AttractionActivityTypeID: 2
      //Details: {Lang: "th", Name: "ทดสอบ", Detail: ""}
      //id: 0
      let th = this.state.th
      let en = this.state.en
      let zh = this.state.zh
      if (this.state.lang === 'th') {
        th = {
          ...this.state.th,
          CoverImages: _result.result.IntroImage,
          MapImage: _result.result.MapImage == null ? {} : _result.result.MapImage,
          Name: _result.result.Name[this.state.lang],
          //-----------------------------------------------//
          Details: _result.result.Detail[this.state.lang],
          TravelRemark: _result.result.TravelRemark[this.state.lang],
          UseTime: _result.result.Activity.Details.UseTime[this.state.lang],
          CostRate: _result.result.Activity.Details.CostRate[this.state.lang],
          Agenda: _result.result.Activity.Details.Agenda[this.state.lang],
          OpenHourRemark: _result.result.OpenHourRemark[this.state.lang],
          // History: _result.result.Attraction.Details.History !== undefined ?
          //     _result.result.Attraction.Details.History[this.state.lang]
          //     :
          //     ""
          // ,
          // AreaSize: _result.result.Attraction.Details.AreaSize !== undefined ?
          //     _result.result.Attraction.Details.AreaSize[this.state.lang]
          //     :
          //     ""
          // ,
          // ParkingDetail: _result.result.Attraction.Details.ParkingDetail !== undefined ?
          //     _result.result.Attraction.Details.ParkingDetail[this.state.lang]
          //     :
          //     ""
          // ,
          // ResponseDepartment: _result.result.Attraction.Details.ResponseDepartment !== undefined ?
          //     _result.result.Attraction.Details.ResponseDepartment[this.state.lang]
          //     :
          //     ""
          // ,
          // Feature: _result.result.Attraction.Details.Feature !== undefined ?
          //     _result.result.Attraction.Details.Feature[this.state.lang]
          //     :
          //     ""
          // ,
          //-----------------------------------------------//
          Address: _result.result.Address[this.state.lang],
          Province: _result.result.Province[this.state.lang],
          District: _result.result.District[this.state.lang],
          County: _result.result.SubDistrict[this.state.lang],
          Latitude: _result.result.Latitude,
          Longitude: _result.result.Longitude,
          //-----------------------------------------------//
          Telephone: _result.result.Telephone,
          Mobile: _result.result.Mobile,
          Email: _result.result.Email,
          Website: _result.result.Website,
          ContactName: _result.result.ContactName[this.state.lang],
          ContactPosition: _result.result.ContactPosition[this.state.lang],
          VdoYoutubeUrl: _result.result.VdoYoutubeUrl,
          FacebookUrl: _result.result.FacebookUrl,
          LineID: _result.result.LineID,
          Twitter: _result.result.Twitter,
          MoreDetail: _result.result.MoreDetail[this.state.lang],
          Activity: _result.result.Activity
          //-----------------------------------------------//
        }
      }
      if (this.state.lang === 'en') {

        en = {
          ...this.state.en,
          Name: _result.result.Name[this.state.lang],
          Details: _result.result.Detail[this.state.lang],
          Province: _result.result.Province[this.state.lang],
          District: _result.result.District[this.state.lang],
          Address: _result.result.Address[this.state.lang],
          UseTime: _result.result.Activity.Details.UseTime[this.state.lang],
          CostRate: _result.result.Activity.Details.CostRate[this.state.lang],
          Agenda: _result.result.Activity.Details.Agenda[this.state.lang],
          OpenHourRemark: _result.result.OpenHourRemark[this.state.lang],
          // AreaSize: _result.result.Attraction.Details.AreaSize !== undefined ?
          //     _result.result.Attraction.Details.AreaSize[this.state.lang]
          //     :
          //     ""
          // ParkingDetail: _result.result.Attraction.Details.ParkingDetail !== undefined ?
          //     _result.result.Attraction.Details.ParkingDetail[this.state.lang]
          //     :
          //     ""
          // , ResponseDepartment: _result.result.Attraction.Details.ResponseDepartment !== undefined ?
          //     _result.result.Attraction.Details.ResponseDepartment[this.state.lang]
          //     :
          //     ""
          // , History: _result.result.Attraction.Details.History !== undefined ?
          //     _result.result.Attraction.Details.History[this.state.lang]
          //     :
          //     ""
          // ,
          // Feature: _result.result.Attraction.Details.Feature !== undefined ?
          //     _result.result.Attraction.Details.Feature[this.state.lang]
          //     :
          //     ""
          // ,
          MoreDetail: _result.result.MoreDetail[this.state.lang],
          Activity: _result.result.Activity
        }
      }
      if (this.state.lang === 'zh') {

        zh = {
          ...this.state.en,
          Name: _result.result.Name[this.state.lang],
          Details: _result.result.Detail[this.state.lang],
          Province: _result.result.Province[this.state.lang],
          District: _result.result.District[this.state.lang],
          Address: _result.result.Address[this.state.lang],
          UseTime: _result.result.Activity.Details.UseTime[this.state.lang],
          CostRate: _result.result.Activity.Details.CostRate[this.state.lang],
          Agenda: _result.result.Activity.Details.Agenda[this.state.lang],
          OpenHourRemark: _result.result.OpenHourRemark[this.state.lang],
          // AreaSize: _result.result.Attraction.Details.AreaSize !== undefined ?
          //     _result.result.Attraction.Details.AreaSize[this.state.lang]
          //     :
          //     ""
          // ParkingDetail: _result.result.Attraction.Details.ParkingDetail !== undefined ?
          //     _result.result.Attraction.Details.ParkingDetail[this.state.lang]
          //     :
          //     ""
          // , ResponseDepartment: _result.result.Attraction.Details.ResponseDepartment !== undefined ?
          //     _result.result.Attraction.Details.ResponseDepartment[this.state.lang]
          //     :
          //     ""
          // , History: _result.result.Attraction.Details.History !== undefined ?
          //     _result.result.Attraction.Details.History[this.state.lang]
          //     :
          //     ""
          // ,
          // Feature: _result.result.Attraction.Details.Feature !== undefined ?
          //     _result.result.Attraction.Details.Feature[this.state.lang]
          //     :
          //     ""
          // ,
          MoreDetail: _result.result.MoreDetail[this.state.lang],
          Activity: _result.result.Activity
        }

      }
      this.state.IDEditter = _result.result.ID,
        this.state.IsEnabled = _result.result.IsEnabled,
        this.state.ActivityStatus = _result.result.Activity.Info.ActivityStatus,
        this.state.Feedback = _result.result.Feedback,
        this.setState({
          th: th,
          en: en,
          zh: zh,
          StandardTargets: _result.result.Activity.StandardTargets.Items,
          Types: _result.result.Activity.Types,
          StandardTypes: _result.result.Activity.StandardTypes,
          // Utility: _result.result.Attraction.Utilities,
          TravelTypeID: _result.result.TravelTypes.Items,
          // Facilities: _result.result.Attraction.Facilities,
          Activities: Attraction,
          Images: Images,
          OpenHours: OpenHours,
          life_styleSelected: {
            label: _result.result.Activity.Info.ActivityLife[this.state.lang] !== undefined ? _result.result.Activity.Info.ActivityLife[this.state.lang] : "เลือกหมวดหมู่ Life Style",
            value: _result.result.Activity.Info.ActivityLife.ID !== undefined ? _result.result.Activity.Info.ActivityLife.ID : -1
          },
          activity_categoriesSelected: {
            label: _result.result.Activity.Info.ActivityCategory[this.state.lang] !== undefined ? _result.result.Activity.Info.ActivityCategory[this.state.lang] : "เลือกหมวดหมู่กิจกรรม",
            value: _result.result.Activity.Info.ActivityCategory.ID !== undefined ? _result.result.Activity.Info.ActivityCategory.ID : -1
          },
          StartDate: _result.result.Activity.Info.StartDate,
          EndDate: _result.result.Activity.Info.EndDate,
          placeId: this.state.status === null ? this.state.placeId : _result.result.MainInfoID,
          isLoadingDetail: false,
          Activity: _result.result.Activity,
          UseTime: _result.result.Activity.Details.UseTime[this.state.lang],
          CostRate: _result.result.Activity.Details.CostRate[this.state.lang],
          Agenda: _result.result.Activity.Details.Agenda[this.state.lang],
        }, () => {
          this.attraction_types()
        })
      // }
    }
    else {
      console.log('Error : getLocationDetail ')
    }

  }


  async submitData() {
    if (this.state[this.state.lang].Name === "") {
      return alert('กรุณาใส่ชื่อ')
    }
    if (this.state[this.state.lang].Details === "") {
      return alert('กรุณาใส่รายละเอียด')
    }
    if (this.state[this.state.lang].Address === "") {
      return alert('กรุณาใส่ที่อยู่')
    }
    if (this.state[this.state.lang].Province === "") {
      return alert('กรุณาเลือกจังหวัด')
    }
    if (this.state[this.state.lang].County === "") {
      return alert('กรุณาเลือก อำเภอ และ ตำบล')
    }
    if (this.state[this.state.lang].Latitude === "") {
      return alert('กรุณาใส่ พิกัดละติจูด')
    }
    if (this.state[this.state.lang].Longitude === "") {
      return alert('กรุณาใส่ พิกัดลองติจูด')
    }
    // if (this.state[this.state.lang].District === "") {
    //     return alert('กรุณาเลือกตำบล')
    // }
    // if (this.state.Submit.ImageUpload === undefined) {
    //     return alert('กรุณาใส่รูป')
    // }
    console.log(this.state.life_styleSelected?.value)
    let IsEnabled = this.state.IsEnabled
    let ActivityStatus = this.state.ActivityStatus
    let Feedback = this.state.Feedback
    const userData = JSON.parse(localStorage.getItem('authUser'))
    if (userData.UserSubType === "Editor") {
      console.log('Editer >>>>')
      IsEnabled = "N"
      console.log(IsEnabled)
    }
    let obj = {
      MainTypeID: 2,
      IsEnabled: IsEnabled,
      Feedback: Feedback,
      // ID: userData.UserID,
      Main: {
        Info: {
          SubDistrictCode: this.state[this.state.lang].County,
          DistrictCode: this.state[this.state.lang].District,
          ProvinceCode: this.state[this.state.lang].Province,
          RegionID: 1,
          PostCode: "",
          Telephone: this.state[this.state.lang].Telephone,
          Fax: "",
          Mobile: this.state[this.state.lang].Mobile,
          Website: this.state[this.state.lang].Website,
          Email: this.state[this.state.lang].Email,
          FacebookUrl: this.state[this.state.lang].FacebookUrl,
          LineID: this.state[this.state.lang].LineID,
          Twitter: this.state[this.state.lang].Twitter,
          Latitude: this.state[this.state.lang].Latitude,
          Longitude: this.state[this.state.lang].Longitude,
          // HashName: this.state.Submit.ImageUpload[0].HashName,
          // Path: this.state.Submit.ImageUpload[0].Path,
          IntroImage: this.state.Submit.IntroImage,
          MapImage: this.state.Submit.MapImage,
          VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl
        },
        Details: {
          [this.state.lang]: {
            Lang: this.state.lang,
            Name: this.state[this.state.lang].Name,
            Detail: this.state[this.state.lang].Details,
            Location: "",
            Address: this.state[this.state.lang].Address,
            Moo: "",
            Soi: "",
            Road: "",
            Remark: "",
            ContactName: this.state[this.state.lang].ContactName,
            ContactPosition: this.state[this.state.lang].ContactPosition,
            // TravelRemark: this.state[this.state.lang].TravelRemark,
            OpenHourRemark: this.state[this.state.lang].OpenHourRemark,

            TravelMoreDetail: "",
            MoreDetail: this.state[this.state.lang].MoreDetail,
          }
        },
        // OpenHours: this.state.Submit.OpenHours,
        TravelTypes: this.state.Submit.Travel_types,
        Images: this.state.Submit.ImageUpload,
        // Activities: this.state.Submit.Attraction,
        // Facilities: this.state.Submit.Facilities,

        // Utilities: this.state.Submit.Utility,
      },
      Activity: {
        Info: {
          ActivityLifeStyleID: this.state.life_styleSelected?.value === undefined ? { label: 'เลือกหมวดหมู่ Life Style', value: -1 } : this.state.life_styleSelected?.value,
          ActivityCategoryID: this.state.activity_categories?.value === undefined ? { label: 'หมวดหมู่การท่องเที่ยว', value: -1 } : this.state.activity_categories?.value,
          LicenseNo: null,
          StartDate: this.state.StartDate,
          EndDate: this.state.EndDate,
          ActivityStatus: ActivityStatus,
        },
        Types: this.state.Submit.Types,
        StandardTypes: this.state.Submit.StandardTypes,
        Details: {
          [this.state.lang]: {
            // ID:"",
            Lang: this.state.lang,
            Agenda: this.state[this.state.lang].Agenda,
            CostRate: this.state[this.state.lang].CostRate,
            UseTime: this.state[this.state.lang].UseTime,
            TargetRemark: null
          }
        },
        StandardTargets: this.state.Submit.StandardTargets
      }


    }
    // console.log('ปิดส่ง')
    console.log(JSON.stringify(obj))
    // return

    const response = await fetch(
      api_endpoint + `/maininfo/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: 'bearer ' + userData.token
        },
        body: JSON.stringify(obj), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    if (_result.status === "ok") {
      alert('เพิ่มกิจกรรมสำเร็จ')
      window.location.href = "/Travelactivities";
    }
    else {
      console.log('error : submitData')
    }
    console.log(_result)
  }

  async updateData() {
    const userData = JSON.parse(localStorage.getItem('authUser'))
    let obj = {}
    if (this.state.lang === 'th') {
      obj = {
        MainTypeID: 2,
        ID: Number(this.state.placeId),
        IsEnabled: this.state.IsEnabled,
        Feedback: this.state.Feedback,
        Main: {
          Info: {
            SubDistrictCode: this.state[this.state.lang].County,
            DistrictCode: this.state[this.state.lang].District,
            ProvinceCode: this.state[this.state.lang].Province,
            RegionID: 1,
            PostCode: "",
            Telephone: this.state[this.state.lang].Telephone,
            Fax: "",
            Mobile: this.state[this.state.lang].Mobile,
            Website: this.state[this.state.lang].Website,
            Email: this.state[this.state.lang].Email,
            FacebookUrl: this.state[this.state.lang].FacebookUrl,
            LineID: this.state[this.state.lang].LineID,
            Twitter: this.state[this.state.lang].Twitter,
            Latitude: this.state[this.state.lang].Latitude,
            Longitude: this.state[this.state.lang].Longitude,
            IntroImage: this.state.Submit.IntroImage,
            MapImage: this.state.Submit.MapImage,
            VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl
            // HashName: this.state.Submit.ImageUpload[0].HashName,
            // Path: this.state.Submit.ImageUpload[0].Path

          },
          Details: {
            [this.state.lang]: {
              Lang: this.state.lang,
              Name: this.state[this.state.lang].Name,
              Detail: this.state[this.state.lang].Details,
              Location: "",
              Address: this.state[this.state.lang].Address,
              Moo: "",
              Soi: "",
              Road: "",
              Remark: "",
              ContactName: this.state[this.state.lang].ContactName,
              ContactPosition: this.state[this.state.lang].ContactPosition,
              // TravelRemark: this.state[this.state.lang].TravelRemark,
              OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
              TravelMoreDetail: "",
              MoreDetail: this.state[this.state.lang].MoreDetail,
            }
          },
          // OpenHours: this.state.Submit.OpenHours,
          TravelTypes: this.state.Submit.Travel_types,
          Images: this.state.Submit.ImageUpload,
          // Activities: this.state.Submit.Attraction,
          // Facilities: this.state.Submit.Facilities,
          // Utilities: this.state.Submit.Utility,
        },
        Activity: {
          Info: {
            ActivityLifeStyleID: this.state.life_styleSelected?.value === undefined ? { label: 'เลือกหมวดหมู่ Life Style', value: -1 } : this.state.life_styleSelected?.value,
            ActivityCategoryID: this.state.activity_categories?.value === undefined ? { label: 'หมวดหมู่การท่องเที่ยว', value: -1 } : this.state.activity_categories?.value,
            LicenseNo: null,
            StartDate: this.state.StartDate,
            EndDate: this.state.EndDate,
            ActivityStatus: this.state.ActivityStatus,
          },
          Types: this.state.Submit.Types,
          StandardTypes: this.state.Submit.StandardTypes,
          Details: {
            [this.state.lang]: {
              // ID:"",
              Lang: this.state.lang,
              Agenda: this.state[this.state.lang].Agenda,
              CostRate: this.state[this.state.lang].CostRate,
              UseTime: this.state[this.state.lang].UseTime,
              TargetRemark: null
            }
          },
          StandardTargets: this.state.Submit.StandardTargets
        }

      }
      if (obj.Main.Images === undefined) {
        delete obj.Main.Images
      }
    }
    if (this.state.lang === 'en') {
      obj = {
        MainTypeID: 2,
        ID: Number(this.state.placeId),
        Main: {
          // Info: {
          //     SubDistrictCode: this.state[this.state.lang].County,
          //     DistrictCode: this.state[this.state.lang].District,
          //     ProvinceCode: this.state[this.state.lang].Province,
          //     RegionID: 1,
          //     PostCode: "",
          //     Telephone: this.state[this.state.lang].Telephone,
          //     Fax: "",
          //     Mobile: this.state[this.state.lang].Mobile,
          //     Website: this.state[this.state.lang].Website,
          //     Email: this.state[this.state.lang].Email,
          //     FacebookUrl: "facebook.com/abcd",
          //     LineID: "",
          //     Twitter: "",
          //     Latitude: this.state[this.state.lang].Latitude,
          //     Longitude: this.state[this.state.lang].Longitude,
          //     // HashName: this.state.Submit.ImageUpload[0].HashName,
          //     // Path: this.state.Submit.ImageUpload[0].Path

          // },
          Details: {
            [this.state.lang]: {
              Lang: this.state.lang,
              Name: this.state[this.state.lang].Name,
              Detail: this.state[this.state.lang].Details,
              // Location: "",
              Address: this.state[this.state.lang].Address,
              MoreDetail: this.state[this.state.lang].MoreDetail,
              // Moo: "",
              // Soi: "",
              // Road: "",
              // Remark: "",
              // ContactName: "",
              // ContactPosition: "",
              // TravelRemark: this.state[this.state.lang].TravelRemark,
              OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
              // MoreDetail: "",
              // TravelMoreDetail: ""
            }
          },
          // OpenHours: this.state.Submit.OpenHours,
          // TravelTypes: this.state.Submit.Travel_types,
          // Images: this.state.Submit.ImageUpload,
        },
        Attraction: {
          // Info: {
          //     HasCost: "N",
          //     CostForeignAdult: null,
          //     CostForeignChild: null,
          //     CostThaiAdult: null,
          //     CostThaiChild: null,
          //     LabelAmount: null

          // },
          // Types: this.state.Submit.Types === undefined ? [] : this.state.Submit.Types,
          Details: {
            [this.state.lang]: {
              Lang: this.state.lang,
              History: this.state[this.state.lang].History,
              OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
              // HighSeason: "",
              Feature: this.state[this.state.lang].Feature,
              // RewardOther: "",
              // Keyword: "",
              // KeywordRemark: null,
              // CostRemark: "",
              // UseTime: "",
              // Detail: null,
              ResponseDepartment: this.state[this.state.lang].ResponseDepartment,
              // AreaSize: this.state[this.state.lang].AreaSize,
              // ParkingDetail: this.state[this.state.lang].ParkingDetail,
              // TargetRemark: null

            }
          },
          // Activities: this.state.Submit.Attraction,
          // Facilities: this.state.Submit.Facilities,
          // Targets: this.state.Submit.Targets,
          // Utilities: this.state.Submit.Utility,
        }

      }

    }
    if (this.state.lang === 'zh') {
      obj = {
        MainTypeID: 2,
        ID: Number(this.state.placeId),
        Main: {
          // Info: {
          //     SubDistrictCode: this.state[this.state.lang].County,
          //     DistrictCode: this.state[this.state.lang].District,
          //     ProvinceCode: this.state[this.state.lang].Province,
          //     RegionID: 1,
          //     PostCode: "",
          //     Telephone: this.state[this.state.lang].Telephone,
          //     Fax: "",
          //     Mobile: this.state[this.state.lang].Mobile,
          //     Website: this.state[this.state.lang].Website,
          //     Email: this.state[this.state.lang].Email,
          //     FacebookUrl: "facebook.com/abcd",
          //     LineID: "",
          //     Twitter: "",
          //     Latitude: this.state[this.state.lang].Latitude,
          //     Longitude: this.state[this.state.lang].Longitude,
          //     // HashName: this.state.Submit.ImageUpload[0].HashName,
          //     // Path: this.state.Submit.ImageUpload[0].Path

          // },
          Details: {
            [this.state.lang]: {
              Lang: this.state.lang,
              Name: this.state[this.state.lang].Name,
              Detail: this.state[this.state.lang].Details,
              // Location: "",
              Address: this.state[this.state.lang].Address,
              MoreDetail: this.state[this.state.lang].MoreDetail,
              // Moo: "",
              // Soi: "",
              // Road: "",
              // Remark: "",
              // ContactName: "",
              // ContactPosition: "",
              // TravelRemark: this.state[this.state.lang].TravelRemark,
              OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
              // MoreDetail: "",
              // TravelMoreDetail: ""
            }
          },
          // OpenHours: this.state.Submit.OpenHours,
          // TravelTypes: this.state.Submit.Travel_types,
          // Images: this.state.Submit.ImageUpload,
        },
        Attraction: {
          // Info: {
          //     HasCost: "N",
          //     CostForeignAdult: null,
          //     CostForeignChild: null,
          //     CostThaiAdult: null,
          //     CostThaiChild: null,
          //     LabelAmount: null

          // },
          Types: this.state.Submit.Types === undefined ? [] : this.state.Submit.Types,
          StandardTypes: this.state.Submit.StandardTypes === undefined ? [] : this.state.Submit.StandardTypes,
          Details: {
            [this.state.lang]: {
              Lang: this.state.lang,
              History: this.state[this.state.lang].History,
              OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
              // HighSeason: "",
              Feature: this.state[this.state.lang].Feature,
              // RewardOther: "",
              // Keyword: "",
              // KeywordRemark: null,
              // CostRemark: "",
              // UseTime: "",
              // Detail: null,
              ResponseDepartment: this.state[this.state.lang].ResponseDepartment,
              // AreaSize: this.state[this.state.lang].AreaSize,
              // ParkingDetail: this.state[this.state.lang].ParkingDetail,
              // TargetRemark: null

            }
          },
          // Activities: this.state.Submit.Attraction,
          // Facilities: this.state.Submit.Facilities,
          // Targets: this.state.Submit.Targets,
          // Utilities: this.state.Submit.Utility,
        }

      }

    }
    if (obj.Main.Images === undefined) {
      delete obj.Main.Images
    }
    const response = await fetch(
      api_endpoint + `/maininfo/update`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: 'bearer ' + userData.token
        },
        body: JSON.stringify(obj), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();

    if (_result.status === "ok") {


      alert('แก้ไขกิจกรรมสำเร็จ')
      if (userData.UserSubType !== 'Admin' && userData.UserSubType !== 'SuperAdmin') {
        window.location.href = "/Travelactivities";
      }

    }
    else {
      console.log('error : updateData')
    }
  }

  async Approve() {
    const userData = JSON.parse(localStorage.getItem('authUser'))
    let obj = {}
    if (this.state.lang === 'th') {
      obj = {
        MainTypeID: 2,
        ID: Number(this.state.IDEditter),
        IsEnabled: this.state.IsEnabled,
        Feedback: this.state.Feedback,
        Main: {
          Info: {
            SubDistrictCode: this.state[this.state.lang].County,
            DistrictCode: this.state[this.state.lang].District,
            ProvinceCode: this.state[this.state.lang].Province,
            RegionID: 1,
            PostCode: "",
            Telephone: this.state[this.state.lang].Telephone,
            Fax: "",
            Mobile: this.state[this.state.lang].Mobile,
            Website: this.state[this.state.lang].Website,
            Email: this.state[this.state.lang].Email,
            FacebookUrl: this.state[this.state.lang].FacebookUrl,
            LineID: this.state[this.state.lang].LineID,
            Twitter: this.state[this.state.lang].Twitter,
            Latitude: this.state[this.state.lang].Latitude,
            Longitude: this.state[this.state.lang].Longitude,
            IntroImage: this.state.Submit.IntroImage,
            MapImage: this.state.Submit.MapImage,
            VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl
            // HashName: this.state.Submit.ImageUpload[0].HashName,
            // Path: this.state.Submit.ImageUpload[0].Path

          },
          Details: {
            [this.state.lang]: {
              Lang: this.state.lang,
              Name: this.state[this.state.lang].Name,
              Detail: this.state[this.state.lang].Details,
              Location: "",
              Address: this.state[this.state.lang].Address,
              Moo: "",
              Soi: "",
              Road: "",
              Remark: "",
              ContactName: this.state[this.state.lang].ContactName,
              ContactPosition: this.state[this.state.lang].ContactPosition,
              // TravelRemark: this.state[this.state.lang].TravelRemark,
              OpenHourRemark: this.state[this.state.lang].OpenHourRemark,

              TravelMoreDetail: "",
              MoreDetail: this.state[this.state.lang].MoreDetail,
            }
          },
          // OpenHours: this.state.Submit.OpenHours,
          TravelTypes: this.state.Submit.Travel_types,
          Images: this.state.Submit.ImageUpload,
          // Activities: this.state.Submit.Attraction,
          // Facilities: this.state.Submit.Facilities,
          // Utilities: this.state.Submit.Utility,
        },
        Activity: {
          Info: {
            ActivityLifeStyleID: this.state.life_styleSelected.value,
            ActivityCategoryID: this.state.activity_categories.value,
            LicenseNo: null,
            StartDate: this.state.EndDate,
            EndDate: this.state.EndDate,
            ActivityStatus: this.state.ActivityStatus,
          },
          Types: this.state.Submit.Types,
          StandardTypes: this.state.Submit.StandardTypes,
          Details: {
            [this.state.lang]: {
              // ID:"",
              Lang: this.state.lang,
              Agenda: this.state[this.state.lang].Agenda,
              CostRate: this.state[this.state.lang].CostRate,
              UseTime: this.state[this.state.lang].UseTime,
              TargetRemark: null
            }
          },
          StandardTargets: this.state.Submit.StandardTargets
        }

      }
      if (obj.Main.Images === undefined) {
        delete obj.Main.Images
      }
    }
    if (this.state.lang === 'en') {
      obj = {
        MainTypeID: 2,
        ID: Number(this.state.IDEditter),
        Main: {
          // Info: {
          //     SubDistrictCode: this.state[this.state.lang].County,
          //     DistrictCode: this.state[this.state.lang].District,
          //     ProvinceCode: this.state[this.state.lang].Province,
          //     RegionID: 1,
          //     PostCode: "",
          //     Telephone: this.state[this.state.lang].Telephone,
          //     Fax: "",
          //     Mobile: this.state[this.state.lang].Mobile,
          //     Website: this.state[this.state.lang].Website,
          //     Email: this.state[this.state.lang].Email,
          //     FacebookUrl: "facebook.com/abcd",
          //     LineID: "",
          //     Twitter: "",
          //     Latitude: this.state[this.state.lang].Latitude,
          //     Longitude: this.state[this.state.lang].Longitude,
          //     // HashName: this.state.Submit.ImageUpload[0].HashName,
          //     // Path: this.state.Submit.ImageUpload[0].Path

          // },
          Details: {
            [this.state.lang]: {
              Lang: this.state.lang,
              Name: this.state[this.state.lang].Name,
              Detail: this.state[this.state.lang].Details,
              // Location: "",
              Address: this.state[this.state.lang].Address,
              MoreDetail: this.state[this.state.lang].MoreDetail,
              // Moo: "",
              // Soi: "",
              // Road: "",
              // Remark: "",
              // ContactName: "",
              // ContactPosition: "",
              // TravelRemark: this.state[this.state.lang].TravelRemark,
              OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
              // MoreDetail: "",
              // TravelMoreDetail: ""
            }
          },
          // OpenHours: this.state.Submit.OpenHours,
          // TravelTypes: this.state.Submit.Travel_types,
          // Images: this.state.Submit.ImageUpload,
        },
        Attraction: {
          // Info: {
          //     HasCost: "N",
          //     CostForeignAdult: null,
          //     CostForeignChild: null,
          //     CostThaiAdult: null,
          //     CostThaiChild: null,
          //     LabelAmount: null

          // },
          // Types: this.state.Submit.Types === undefined ? [] : this.state.Submit.Types,
          Details: {
            [this.state.lang]: {
              Lang: this.state.lang,
              History: this.state[this.state.lang].History,
              OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
              // HighSeason: "",
              Feature: this.state[this.state.lang].Feature,
              // RewardOther: "",
              // Keyword: "",
              // KeywordRemark: null,
              // CostRemark: "",
              // UseTime: "",
              // Detail: null,
              ResponseDepartment: this.state[this.state.lang].ResponseDepartment,
              // AreaSize: this.state[this.state.lang].AreaSize,
              // ParkingDetail: this.state[this.state.lang].ParkingDetail,
              // TargetRemark: null

            }
          },
          // Activities: this.state.Submit.Attraction,
          // Facilities: this.state.Submit.Facilities,
          // Targets: this.state.Submit.Targets,
          // Utilities: this.state.Submit.Utility,
        }

      }

    }
    if (this.state.lang === 'zh') {
      obj = {
        MainTypeID: 2,
        ID: Number(this.state.IDEditter),
        Main: {
          // Info: {
          //     SubDistrictCode: this.state[this.state.lang].County,
          //     DistrictCode: this.state[this.state.lang].District,
          //     ProvinceCode: this.state[this.state.lang].Province,
          //     RegionID: 1,
          //     PostCode: "",
          //     Telephone: this.state[this.state.lang].Telephone,
          //     Fax: "",
          //     Mobile: this.state[this.state.lang].Mobile,
          //     Website: this.state[this.state.lang].Website,
          //     Email: this.state[this.state.lang].Email,
          //     FacebookUrl: "facebook.com/abcd",
          //     LineID: "",
          //     Twitter: "",
          //     Latitude: this.state[this.state.lang].Latitude,
          //     Longitude: this.state[this.state.lang].Longitude,
          //     // HashName: this.state.Submit.ImageUpload[0].HashName,
          //     // Path: this.state.Submit.ImageUpload[0].Path

          // },
          Details: {
            [this.state.lang]: {
              Lang: this.state.lang,
              Name: this.state[this.state.lang].Name,
              Detail: this.state[this.state.lang].Details,
              // Location: "",
              Address: this.state[this.state.lang].Address,
              MoreDetail: this.state[this.state.lang].MoreDetail,
              // Moo: "",
              // Soi: "",
              // Road: "",
              // Remark: "",
              // ContactName: "",
              // ContactPosition: "",
              // TravelRemark: this.state[this.state.lang].TravelRemark,
              OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
              // MoreDetail: "",
              // TravelMoreDetail: ""
            }
          },
          // OpenHours: this.state.Submit.OpenHours,
          // TravelTypes: this.state.Submit.Travel_types,
          // Images: this.state.Submit.ImageUpload,
        },
        Attraction: {
          // Info: {
          //     HasCost: "N",
          //     CostForeignAdult: null,
          //     CostForeignChild: null,
          //     CostThaiAdult: null,
          //     CostThaiChild: null,
          //     LabelAmount: null

          // },
          Types: this.state.Submit.Types === undefined ? [] : this.state.Submit.Types,
          StandardTypes: this.state.Submit.StandardTypes === undefined ? [] : this.state.Submit.StandardTypes,
          Details: {
            [this.state.lang]: {
              Lang: this.state.lang,
              History: this.state[this.state.lang].History,
              OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
              // HighSeason: "",
              Feature: this.state[this.state.lang].Feature,
              // RewardOther: "",
              // Keyword: "",
              // KeywordRemark: null,
              // CostRemark: "",
              // UseTime: "",
              // Detail: null,
              ResponseDepartment: this.state[this.state.lang].ResponseDepartment,
              // AreaSize: this.state[this.state.lang].AreaSize,
              // ParkingDetail: this.state[this.state.lang].ParkingDetail,
              // TargetRemark: null

            }
          },
          // Activities: this.state.Submit.Attraction,
          // Facilities: this.state.Submit.Facilities,
          // Targets: this.state.Submit.Targets,
          // Utilities: this.state.Submit.Utility,
        }

      }

    }
    if (obj.Main.Images === undefined) {
      delete obj.Main.Images
    }
    const response = await fetch(
      api_endpoint + `/maininfo/approve`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: 'bearer ' + userData.token
        },
        body: JSON.stringify(obj), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    if (_result.status === "ok") {


      alert('อนุมัติแก้ไขกิจกรรมสำเร็จ')

    }
    else {
      console.log('error : Approve')
    }
  }

  async reject() {
    const userData = JSON.parse(localStorage.getItem('authUser'))
    let obj = {}
    if (this.state.lang === 'th') {
      obj = {
        ID: Number(this.state.IDEditter),
      }
      // if (obj.Main.Images === undefined) {
      //     delete obj.Main.Images
      // }
    }
    if (this.state.lang === 'en') {
      obj = {
        ID: Number(this.state.IDEditter),
      }
    }
    if (this.state.lang === 'zh') {
      obj = {
        ID: Number(this.state.IDEditter),
      }
    }
    obj['Feedback'] = this.state.Feedback

    const response = await fetch(
      api_endpoint + `/maininfo/reject`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: 'bearer ' + userData.token
        },
        body: JSON.stringify(obj),
      }
    );
    var _result = await response.json();
    if (_result.status === "ok") {


      alert('แจ้งส่งกลับแก้ไขกิจกรรมสำเร็จ')

    }
    else {
      console.log('error : reject')
    }
  }


  selectedHandle(value, type) {
    if (type === 'life_style') {
      this.setState({
        life_styleSelected: value,
      })
    }
    if (type === 'activity_categories') {
      this.setState({
        activity_categories: value,
      })
    }
  }

  componentDidMount() {



    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    const status = params.get('status');
    if (id !== null) {
      console.log('in')
      this.setState({
        placeId: id,
        status: status,
        cloneId: id,
        isLoadingDetail: true
      }, () => {

        this.getLocationDetail(id)

      })

    }
    else {
      console.log('out')
      this.attraction_types()
    }




  }

  componentWillReceiveProps(nextProps) {

    // console.log(nextProps)
    if (nextProps.lang) {
      this.setState({
        lang: nextProps.lang
      }, () => {
        // console.log(this.state.lang)
      })
    }
  }

  render() {
    if (this.state.isLoading === true || this.state.isLoadingDetail === true) {
      return (
        <div className="text-center" >



        </div>
      )
    }
    else {
      const userData = JSON.parse(localStorage.getItem('authUser'))
      // console.log(userData)
      return (

        <div>
          <Form onSubmit={() => { this.test() }}>
            <Card>
              <CardBody>
                <General callback={this.callback}
                  lang={this.state.lang} data={this.state[this.state.lang]} 
                  Types={this.state.Types} 
                  StandardTypes={this.state.StandardTypes}
                  life_style={this.state.life_style}
                  activity_categories={this.state.activity_categories}
                  activity_categoriesSelected={this.state.activity_categoriesSelected}
                  life_styleSelected={this.state.life_styleSelected}
                  StartDate={this.state.StartDate}
                  EndDate={this.state.EndDate}
                  selectedHandle={this.selectedHandle}
                  submit={this.submitForm}
                />
              </CardBody>
            </Card>
          </Form>
          <Card>
            <CardBody>
              <Address callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} provinces={this.state.provinces} districts={this.state.districts} subdistricts={this.state.subdistricts} submit={this.submitForm} />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Contact callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} />
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Picturesvideos callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Images={this.state.Images} submit={this.submitForm} />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Price callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} UseTime={this.state.UseTime} CostRate={this.state.CostRate} submit={this.submitForm} />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Target callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} StandardTargets={this.state.StandardTargets} submit={this.submitForm} />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Journey callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Travel_types={this.state.TravelTypeID} submit={this.submitForm} />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <More callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Images={this.state.Images} submit={this.submitForm} />

            </CardBody>
          </Card>
          {this.state.status === 'Y' ?
            (userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ?
              <Card>
                <CardBody>
                  <Feedback callback={this.callback} lang={this.state.lang} disabled={true} data={this.state} submit={this.submitForm} />
                </CardBody>
              </Card>
              :
              <Card>
                <CardBody>
                  <Feedback callback={this.callback} lang={this.state.lang} disabled={false} data={this.state} submit={this.submitForm} />
                </CardBody>
              </Card>
            :
            <div />
          }
          <Card>
            <CardBody>
              <div><ActivityStatus lang={this.state.lang} callback={this.callback} ActivityStatus={this.state.ActivityStatus} submit={this.submitForm} /> <hr></hr></div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.placeId === null ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
              {this.state.placeId !== null && this.state.status !== 'Y' && (userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
              {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.status === 'Y' ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
              {this.state.placeId !== null && this.state.status !== 'Y' && userData.UserSubType !== 'Admin' && userData.UserSubType !== 'SuperAdmin' ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
              {/* < Status /> */}
              {this.state.placeId === null ?
                <Row style={{ justifyContent: 'flex-end' }}>
                  <div className="col-auto">
                    <Button
                      style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                      type="submit"
                      onClick={() => { this.submitData() }}

                      className="chat-send w-md waves-effect waves-light"
                    >
                      <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}

                    </Button>
                  </div>
                  <div className="col-auto">
                    <Link to="/Travelactivities">
                      <Button
                        style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                        className="chat-send w-md waves-effect waves-light"
                      >
                        <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                      </Button>
                    </Link>
                  </div>
                </Row>
                :

                <div>

                </div>
              }
              {this.state.placeId !== null && this.state.status !== 'Y' ?
                <Row style={{ justifyContent: 'flex-end' }}>
                  <div className="col-auto">
                    <Button
                      style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                      type="submit"
                      onClick={() => { this.updateData() }}

                      className="chat-send w-md waves-effect waves-light"
                    >
                      <span className="d-none d-sm-inline-block font-style-16nb">แก้ไข</span>{" "}
                    </Button>
                  </div>
                  <div className="col-auto">
                    <Link to="/Travelactivities">
                      <Button
                        style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                        className="chat-send w-md waves-effect waves-light"
                      >
                        <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                      </Button>
                    </Link>
                  </div>
                </Row>
                :
                <div></div>
              }
              {this.state.status === 'Y' && userData.UserSubType === 'Admin' && userData.UserType === 'B' ?
                <Row style={{ justifyContent: 'flex-end' }}>
                  <div className="col-auto">
                    <Button
                      style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                      type="submit"
                      onClick={() => { this.Approve() }}

                      className="chat-send w-md waves-effect waves-light"
                    >
                      <span className="d-none d-sm-inline-block font-style-16nb"> อนุมัติการแก้ไข</span>{" "}
                    </Button>
                  </div>
                  <div className="col-auto">
                    <Button
                      style={{ backgroundColor: '#F4D006', borderColor: '#F4D006' }}
                      className="chat-send w-md waves-effect waves-light"
                      onClick={() => { this.reject() }}
                    >
                      <span className="d-none d-sm-inline-block font-style-16nb">แจ้งส่งกลับแก้ไข</span>{" "}

                    </Button>
                  </div>
                  <div className="col-auto">
                    <Link to="/notifyinformation">
                      <Button
                        style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                        className="chat-send w-md waves-effect waves-light"
                      >
                        <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                      </Button>
                    </Link>

                  </div>
                </Row>
                :
                <div>

                </div>
              }
              {this.state.status === 'Y' && userData.UserSubType === 'Editor' && userData.UserType === 'B' ?
                <div></div>
                :
                <div></div>

              }
            </CardBody>
          </Card>
        </div>

      )
    }

  }
}
