import PropTypes from 'prop-types'
import React, { Component } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Media,
  Row, CardSubtitle, Label, FormGroup, Input
} from "reactstrap"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { editProfile } from "../../store/actions"
import Profilepic from '../../assets/images/ttd/profile.svg'

//api
import { api_endpoint } from '../../global-config'
class UserProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      name: "",
      idx: 1,
      Profile: true,
      ChangePassword: false,
      Username: "",
      TitleName: "",
      FirstName: "",
      LastName: "",
      Email: "",
      UserType: null,
      UserSubType: null,
      UserGroupID: null,
      DepartmentID: null,
      OldPassword: '',
      NewPassword: '',
      ConfirmPassword: '',
      passwordLength: false,
      containsNumbers: false,
      isUpperCase: false,
      isLowerCase: false,
      isSamePassword: false
    }

    this.update_handle = this.update_handle.bind(this);
    this.Profiletab = this.Profiletab.bind(this)
    this.Changepasswordtab = this.Changepasswordtab.bind(this)
  }

  Profiletab() {
    this.setState({
      Profile: true,
      ChangePassword: false,
    })
  }

  Changepasswordtab() {
    this.setState({
      Profile: false,
      ChangePassword: true,
    })

  }

  update_handle(target, value) {
    this.setState({
      [target]: value
    })
  }

  async update_profile(event) {
    event.preventDefault();
    const response = await fetch(
      api_endpoint + `/user/update`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.state.token
        },
        body: JSON.stringify({
          Username: this.state.Username,
          Password: this.state.Password,
          UserType: this.state.UserType,
          UserSubType: this.state.UserSubType,
          UserGroupID: this.state.UserGroupID,
          DepartmentID: this.state.DepartmentID,
          TitleName: this.state.TitleName,
          FirstName: this.state.FirstName,
          LastName: this.state.LastName,
          Email: this.state.Email
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    // console.log(_result)
    if (_result.status === 'ok') {
      //localStorage.setItem("user_data", JSON.stringify(_result))
      let user_data = localStorage.getItem('authUser')
      localStorage.setItem("authUser", JSON.stringify({
        "DepartmentID": JSON.parse(user_data).DepartmentID,
        "Email": this.state.Email,
        "FirstName": this.state.FirstName,
        "LastName": this.state.LastName,
        "TitleName": this.state.TitleName,
        "UserGroupID": this.state.UserGroupID,
        "UserID": this.state.UserID,
        "UserSubType": this.state.UserSubType,
        "UserType": this.state.UserType,
        "Username": this.state.Username,
        "token": this.state.token,
        "_id": JSON.parse(user_data)._id
      }))
      // alert('แก้ไขโปรไฟล์และข้อมูลส่วนตัวเรียบร้อยแล้ว')
      window.location.reload()
      return
    }
    else {
      alert(_result.message)
      return
    }
  }

  //get user data
  async get_user_read() {
    const response = await fetch(
      api_endpoint + `/user/read`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Username: JSON.parse(localStorage.getItem('authUser')).Username,
        }),
      }
    );
    var _result = await response.json();
    // console.log(_result)

    if (_result.status === 'ok') {
      this.setState({
        TitleName: _result.TitleName,
        FirstName: _result.FirstName,
        LastName: _result.LastName,
        Username: _result.Username,
        Email: _result.Email,
        UserType: _result.UserType,
        UserSubType: _result.UserSubType,
        UserGroupID: _result.UserGroupID,
        DepartmentID: _result.DepartmentID,
        token: JSON.parse(localStorage.getItem('authUser')).token
      })
    }
  }

  async update_password(event) {
    event.preventDefault();
    const response = await fetch(
      api_endpoint + `/user/change_password`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + this.state.token
        },
        body: JSON.stringify({
          Username: this.state.Username,
          Oldpassword: this.state.OldPassword,
          Password: this.state.NewPassword
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    // console.log(_result)
    if (_result.status === 'ok') {
      // console.log(_result)
      this.setState({
        OldPassword: '',
        NewPassword: '',
        ConfirmPassword: ''
      })
      alert('แก้ไขรหัสผ่านเรียบร้อยแล้ว')
      // window.location.reload()
      return
    } else {
      alert(_result.message)
      return
    }
  }

  // check to see if there is any number
  checkForNumbers(string) {
    var matches = string.match(/\d+/g);
    this.setState({
      containsNumbers: matches !== null ? true : false
    });
  }

  // check for upper case
  checkForUpperCase(string) {
    var matches = string.match(/[A-Z]/);
    this.setState({
      isUpperCase: matches !== null ? true : false
    });
  }

  checkForLowerCase(string) {
    var matches = string.match(/[a-z]/);
    this.setState({
      isLowerCase: matches !== null ? true : false
    });
  }

  // handle NewPassword
  handleNewPassword = input => e => {
    let targetValue = e.target.value.replace(/\s/g, '');
    this.checkForNumbers(targetValue)
    this.checkForUpperCase(targetValue)
    this.checkForLowerCase(targetValue)
    this.setState({
      [input]: targetValue,
      passwordLength: targetValue.length >= 8 ? true : false
    }, () => {
      // console.log('O:', this.state.OldPassword)
      // console.log('N:', this.state.NewPassword)
      // console.log('C:', this.state.ConfirmPassword)
      if (this.state.ConfirmPassword.length > 1 && this.state.NewPassword.length > 1) {
        // console.log(this.state.NewPassword === this.state.ConfirmPassword)
        this.setState({
          isSamePassword: this.state.NewPassword === this.state.ConfirmPassword
        })
      }
    });
  }

  componentDidMount() {
    this.get_user_read()
  }

  render() {
    let {
      OldPassword,
      NewPassword,
      ConfirmPassword,
      passwordLength,
      containsNumbers,
      isUpperCase,
      isLowerCase,
      isSamePassword,
    } = this.state
    let btnStatus = passwordLength && containsNumbers && isUpperCase && isLowerCase && isSamePassword ? false : true;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="หน้าแรก" link0="/dashboard-blog" breadcrumbItem="โปรไฟล์" link1="/Restaurant" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row style={{ padding: 15 }}>
                      <div style={{ alignSelf: 'center', width: '70%' }}>
                        <h1 className="text-24" style={{ color: '#173865' }}>โปรไฟล์และข้อมูลส่วนตัว</h1>
                        <CardSubtitle className="text-16">กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนกดบันทึก</CardSubtitle>
                      </div>
                      <div style={{ textAlign: 'center', width: '30%' }}>
                        <img style={{ width: '10vw' }} src={Profilepic}></img>
                      </div>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col lg={6} >
                        <div style={{ textAlign: 'center' }}>
                          <h3 className="text-18" style={{ color: this.state.Profile == true ? '#173865' : '#808080' }} onClick={() => { this.Profiletab() }}>ข้อมูลส่วนตัว</h3>
                          {this.state.Profile == true && <div class="hr-line"></div>}
                        </div>
                      </Col>
                      <Col lg={6} style={{ cursor: 'pointer' }}>
                        <div style={{ textAlign: 'center' }}>
                          <h3 className="text-18" style={{ color: this.state.ChangePassword == true ? '#173865' : '#808080' }} onClick={() => { this.Changepasswordtab() }}>เปลี่ยนรหัสผ่าน</h3>
                          {this.state.ChangePassword == true && <div class="hr-line"></div>}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.state.Profile === true && (
              <Card>
                <CardBody>
                  <Label className={'mt-3 text-16'}>ข้อมูลทั่วไป</Label>
                  <Row>
                    <Col lg={2}>
                      <FormGroup>
                        <Label for="Name">คำนำหน้า</Label><Label className="required"> *</Label>
                        <Input required type="text" className="form-control" id="TitleName" value={this.state.TitleName} placeholder="กรุณากรอกคำนำหน้า" onChange={(e) => { this.update_handle('TitleName', e.target.value) }} />
                      </FormGroup>
                    </Col>
                    <Col lg={5}>
                      <FormGroup>
                        <Label for="Name">ชื่อ </Label><Label className="required"> *</Label>
                        <Input required type="text" className="form-control" id="FirstName" value={this.state.FirstName} placeholder="กรุณากรอกชื่อ" onChange={(e) => { this.update_handle('FirstName', e.target.value) }} />
                      </FormGroup>
                    </Col>
                    <Col lg={5}>
                      <FormGroup>
                        <Label for="Name">นามสกุล </Label><Label className="required"> *</Label>
                        <Input required type="text" className="form-control" id="LastName" value={this.state.LastName} placeholder="กรุณากรอกนามสกุล" onChange={(e) => { this.update_handle('LastName', e.target.value) }} />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label for="Name">ชื่อผู้ใช้งาน </Label>
                        <Input disabled type="text" className="form-control" id="Username" value={this.state.Username} onChange={(e) => { this.update_handle('Username', e.target.value) }} />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label for="Name">อีเมล </Label><Label className="required"> *</Label>
                        <Input required type="email" className="form-control" id="Email" value={this.state.Email} placeholder="กรุณากรอกอีเมล" onChange={(e) => { this.update_handle('Email', e.target.value) }} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row style={{ justifyContent: 'flex-end' }}>

                    <Col lg={2}>
                      <button type="button" class="btn btn-primary"
                        onClick={(e) => { this.update_profile(e) }}
                        data-toggle="modal"
                        data-target="#myModal" style={{ backgroundColor: '#41AB00', borderColor: '#41AB00', width: '100%' }}>
                        บันทึก{" "}
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}


            {this.state.ChangePassword === true && (
              <Card>
                <CardBody>
                  <Label className={'mt-3 text-16'}>เปลี่ยนรหัสผ่าน</Label>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label for="Name">รหัสผ่านเดิม </Label><Label className="required"> *</Label>
                        <Input type="password" className="form-control" id="OldPassword" onChange={(e) => { this.update_handle('OldPassword', e.target.value) }} value={OldPassword} placeholder="กรุณากรอกรหััสผ่านเดิม" />
                        {/* <p style={{ color: '#808080' }}>Username จะไม่สามารถเปลี่ยนแปลงได้ในภายหลัง</p> */}
                      </FormGroup>
                      <FormGroup>
                        <Label for="Name">รหัสผ่านใหม่ </Label><Label className="required"> *</Label>
                        <Input type="password" className="form-control" id="NewPassword" onChange={this.handleNewPassword('NewPassword')} value={NewPassword} placeholder="กรุณากรอกรหััสผ่านใหม่" />
                        {/* <Input type="text" className="form-control" id="passwordCheck" placeholder="กรุณากรอกรหัสผ่านใหม่" value={this.state.passwordCheck === null ? "" : this.state.passwordCheck} onChange={(e) => { this.inpuntHandle(e.target.value, "PasswordCheck") }} /> */}
                        {/* <p style={{ color: '#808080' }}>Username จะไม่สามารถเปลี่ยนแปลงได้ในภายหลัง</p> */}
                      </FormGroup>
                      <FormGroup>
                        <Label for="Name">ยืนยันรหัสผ่านใหม่  </Label><Label className="required"> *</Label>
                        <Input type="password" className="form-control" onChange={this.handleNewPassword('ConfirmPassword')} value={ConfirmPassword} placeholder="กรุณากรอกรหััสผ่านใหม่" />
                        {/* <Input type="text" className="form-control" id="Password" placeholder="กรุณากรอกรหัสผ่านใหม่" value={this.state.submitData.User.Password !== undefined ? this.state.submitData.User.Password : ""} onChange={(e) => { this.inpuntHandle(e.target.value, "Password") }} /> */}
                        {/* <p style={{ color: '#808080' }}>Username จะไม่สามารถเปลี่ยนแปลงได้ในภายหลัง</p> */}
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <p>คำแนะนำในการตั้งรหัสผ่าน</p>
                      <p style={{ color: passwordLength ? '#41AB00' : '#000000' }}><i class="fas fa-check-circle"></i> ควรมีความยาว อย่างน้อย 8 ตัว</p>
                      <p style={{ color: containsNumbers ? '#41AB00' : '#000000' }}><i class="fas fa-check-circle"></i> ควรมีตัวเลข [0-9] อย่างน้อย 1 ตัว</p>
                      <p style={{ color: isUpperCase ? '#41AB00' : '#000000' }}><i class="fas fa-check-circle"></i> ควรมีอักษรภาษาอังกฤษพิมพ์ใหม่ [A-Z] อย่างน้อย 1 ตัว</p>
                      <p style={{ color: isLowerCase ? '#41AB00' : '#000000' }}><i class="fas fa-check-circle"></i> ควรมีอักษรภาษาอังกฤษ [a-z] อย่างน้อย 1 ตัว</p>
                      <p style={{ color: isSamePassword ? '#41AB00' : '#000000' }}><i class="fas fa-check-circle"></i> รหัสผ่านใหม่ไม่ตรงกัน</p>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row style={{ justifyContent: 'flex-end' }}>
                    <Col lg={2}>
                      <button disabled={btnStatus} type="button" class="btn btn-primary" style={{ backgroundColor: '#173865', borderColor: '#173865', width: '100%' }} onClick={(e) => { this.update_password(e) }}>
                        เปลี่ยนรหัสผ่าน
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any
}

const mapStateToProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStateToProps, { editProfile })(UserProfile)
)
