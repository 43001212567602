import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"

export default class Status extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data
        }
    }
    selectedHandle(data, type) {
        console.log(data)
        if (type === 'ApiLevel') {
            this.props.callback(data, 'ApiLevel')
            return
        }
    }
    inputHandle(value, type) {
        if (type === 'ApiLevel') {
            this.setState({
                data: value
            }, () => {
            })
        }
    }


    render() {
        console.log(this.props)
        return (
            <div>
                <Row>
                    <Col lg={12}>
                        <Label className={'text-24'}>สถานะข้อมูล</Label>
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col lg={2}>
                        <Label className="font-style-16 cl-black" for="Name">สถานะข้อมูล </Label><Label className="required"> *</Label>
                    </Col>
                    <Col lg={2}>
                        <div className="custom-control custom-radio mb-3">
                            <input
                                type="radio"
                                className="custom-control-input"
                                id={'ApiLevel'}
                                onChange={() => false}
                                checked={this.props.data.ApiLevel === "Y" ? true : false}
                            />
                            <label
                                className="custom-control-label"
                                onClick={() => {

                                    this.selectedHandle("Y", 'ApiLevel')
                                }}
                            >
                                <Label className="font-style-16 cl-black">ใช้งาน</Label>
                            </label>
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="custom-control custom-radio mb-3">
                            <input
                                type="radio"
                                className="custom-control-input"
                                id={'ApiLevel'}
                                onChange={() => false}
                                checked={this.props.data.ApiLevel === "N" ? true : false}
                            />
                            <label
                                className="custom-control-label"
                                onClick={() => {

                                    this.selectedHandle("N", 'ApiLevel')
                                }}
                            >
                                <Label className="font-style-16 cl-black">ระงับการใช้งาน</Label>
                            </label>
                        </div>
                    </Col>
                </Row>

            </div >
        )
    }
}
