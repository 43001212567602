import React, { Component } from "react"
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import logoplus from "../../assets/images/ttd/Vector.svg"
import { api_endpoint } from '../../global-config'
import DataGrid from '../../components/Table/Table'
import Edit from '../../assets/images/ttd/Edit.svg'

const moment = require('moment'); // require
import localization from 'moment/locale/th';
moment.updateLocale('th', localization);

const typeOption = [
  { label: "ทั้งหมด", value: null },
  { label: "เผยแพร่", value: "Y" },
  { label: "ไม่เผยแพร่", value: "N" }
]

class News extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedGroup: null,
      search: "",
      lang: 'th',
      skip: 0,
      limit: 10,
      dataTable: [],
      isLoading: true,
      sortModel: [{
        field: 'id',
        sort: 'desc'
      }],
      selected_date_start: null,
      selected_date_end: null,
      IsEnabled: { label: "ทั้งหมด", value: null },
      rowsPerPageOptions: [10, 25, 50],
      pageSize: 10
    }
  }
  setPageSize = (value) => {
    this.setState({
      isLoading: true,
      skip: value,
      limit: value,
      pageSize: value
    }, () => {
      this.searchDisaster()
    })
  }
  async searchDisaster() {
    let sortBy = []
    console.log(this.state.sortModel[0])
    const sortFields = {
      'id': 'ID',
      'Title': "Main.Details." + this.state.lang + ".Title",
      'PublishDate': "Main.Info.PublishDate",
      'PublishDown': "Main.Info.PublishDown",
      // 'ViewCount': "View.ViewCount",
      'IsEnabled': "IsEnabled"
    }
    if (this.state.sortModel[0].field !== undefined) {
      sortBy = [
        {
          field: sortFields[this.state.sortModel[0].field],
          sort: this.state.sortModel[0].sort === 'asc' ? 1 : -1
        }]
    }
    const url = api_endpoint + '/newsinfo/search'
    const response = await fetch(url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body:
          JSON.stringify({
            Search: this.state.search === "" ? null : this.state.search,
            Lang: this.state.lang,
            Skip: this.state.skip,
            Limit: this.state.limit,
            // Type: "N",
            // PublishDate: this.state.selected_date_start,
            // PublishDown: this.state.selected_date_end,
            // SortBy: sortBy,
            // IsEnabled: this.state.IsEnabled.value,
            // IsBackend: 'Y',
          }), // body data type must match "Content-Type" header
      }
    );
    const _result = await response.json();
    console.log("result searchDisaster", _result)
    if (_result.sortedResults !== undefined) {
      let arr = []
      _result.sortedResults.map((e) => {
        arr.push({
          id: e.ID,
          Title: e.Title[this.state.lang],
          PublishDate: e.PublishDate === null ? "-" : moment(e.PublishDate).format("YYYY-MM-DD"),
          PublishDown: e.PublishDown === null ? "-" : moment(e.PublishDown).format("YYYY-MM-DD"),
          // ViewCount: e.ViewCount,
          IsEnabled: e.IsEnabled,
        })

      })
      this.setState({
        countData: _result.count,
        dataTable: arr,
        isLoading: false
      })
      // console.log(arr)
    }
    else {
      console.log('error : searchDisaster')
    }
  }

  handleSort = (value) => {
    let arr = this.state.sortModel
    console.log(value.sortModel[0])
    if (value.sortModel[0] === undefined) {
      arr[0].sort = 'asc'
      this.setState({
        sortModel: arr
      }, () => {
        this.searchDisaster()
      })
    }
    else {
      arr[0].field = value.sortModel[0].field
      arr[0].sort = value.sortModel[0].sort
      this.setState({
        sortModel: arr
      })
      this.searchDisaster()
    }
  }

  dataGridPagging = (value) => {
    if ((value.page * this.state.pageSize) === this.state.limit) {
      this.setState({
        isLoading: true,
        limit: this.state.limit + this.state.pageSize
      }, () => {
        console.log(this.state.limit)
        this.searchDisaster()
      })
    }
  }

  buttonHandle(type) {
    if (type === 'Search') {
      this.searchDisaster()
    }
    if (type === 'Clear') {
      this.setState({
        search: "",
        selected_date_start: "",
        selected_date_end: "",
        IsEnabled: { label: "ทั้งหมด", value: null },
      }, () => {
        this.searchDisaster()
      })
    }
  }

  inputHandle(value, type) {
    if (type === 'Search') {
      this.setState({
        search: value
      })
    }
    if (type === 'PublishDate') {
      this.setState({
        selected_date_start: value
      }, () => {
        console.log(this.state.selected_date_start)
      })
    }
    if (type === 'PublishDown') {
      this.setState({
        selected_date_end: value
      })
    }
    if (type === 'IsEnabled') {
      this.setState({
        IsEnabled: value
      }, () => {
        console.log(this.state.IsEnabled)
      })
    }
  }

  componentDidMount() {

  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="หน้าแรก" breadcrumbItem="รายการจัดการแจ้งเตือนภัยพิบัติ" link="/Disaster" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <Row>
                      <Col>
                        <h1 style={{ color: '#173865' }}>รายการจัดการแจ้งเตือนภัยพิบัติ</h1>
                      </Col>
                      <Col style={{ textAlign: 'end', alignSelf: 'center' }}>
                        <Link
                          style={{ backgroundColor: '#173865', borderColor: '#173865' }}
                          to="/Disaster/Add"
                          className="btn btn-primary waves-effect waves-light w-lg "
                        >
                          <img src={logoplus} style={{ padding: '0px 10px 3px 0px', width: 30 }} />
                          {/* <i className="mdi mdi-chevron-right ml-1"></i> */}
                          <span className="font-style-16nb">เพิ่มข้อมูลการแจ้งเตือน</span>{" "}
                        </Link>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody className="pb-auto">
                    <Row>
                      <Col>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control rounded chat-input"
                            style={{ fontSize: 16 }}
                            placeholder="ค้นหาสิ่งที่คุณต้องการ..."
                            value={this.state.search}
                            onChange={(e) => { this.inputHandle(e.target.value, 'Search') }}
                          />
                        </div>
                      </Col>
                      <div className="col-auto">
                        <Button
                          style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                          type="submit"
                          onClick={() => { this.buttonHandle('Clear') }}
                          className="chat-send w-md waves-effect waves-light"
                        >
                          <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ล้างค่าค้นหา</span>{" "}
                        </Button>
                      </div>
                      <div className="col-auto">
                        <Button
                          style={{ backgroundColor: '#F47806', borderColor: '#F47806' }}
                          type="submit"
                          onClick={() => { this.buttonHandle('Search') }}
                          className="chat-send w-md waves-effect waves-light"
                        >
                          <span className="d-none d-sm-inline-block font-style-16nb">ค้นหา</span>{" "}
                        </Button>
                      </div>
                    </Row>
                    <Row className={'mt-3'}>
                      <Col lg={4}>
                        <div className="form-group">
                          <label className="font-style-16 cl-black">วันที่เผยแพร่</label>
                          <input type="date" value={this.state.selected_date_start}
                            max={this.state.selected_date_end}
                            onChange={(e) => {
                              this.inputHandle(e.target.value, 'PublishDate')
                            }} placeholder="วันที่เผยแพร่" className="form-control" style={{ fontSize: 16 }}></input>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="form-group">
                          <label className="font-style-16 cl-black">เผยแพร่ถึงวันที่</label>
                          <input type="date" value={this.state.selected_date_end}
                            min={this.state.selected_date_start}
                            onChange={(e) => {
                              this.inputHandle(e.target.value, 'PublishDown')
                            }} placeholder="เผยแพร่ถึงวันที่" className="form-control" style={{ fontSize: 16 }}></input>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <FormGroup className="select2-container">
                          <label className="font-style-16 cl-black">สถานะ</label>
                          <Select
                            value={this.state.IsEnabled}
                            onChange={(e) => { this.inputHandle(e, 'IsEnabled') }}
                            options={typeOption}
                            classNamePrefix="select2-selection" className="font-style-16b4"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <DataGrid
                      header={
                        [
                          { field: 'id', headerName: 'ลำดับ', width: 120 },
                          { field: 'Title', headerName: 'ชื่อเหตุแจ้งเตือน', flex: 1 },
                          { field: 'PublishDate', headerName: 'วันที่เผยแพร่', flex: 1 },
                          { field: 'PublishDown', headerName: 'เผยแพร่ถึงวันที่', flex: 1 },
                          // { field: 'ViewCount', headerName: 'จำนวนการเข้าถึง', flex: 1 },
                          {
                            field: 'IsEnabled', headerName: 'สถานะ', flex: 1,
                            renderCell: (params) => (
                              <div>
                                {params.value === 'Y' ?
                                  <span>
                                    เผยแพร่
                                  </span>
                                  :
                                  <span>
                                    ไม่เผยแพร่
                                  </span>
                                }
                              </div>
                            )
                          },
                          {
                            field: 'Action', headerName: 'จัดการ', width: 120,
                            renderCell: (params) => (
                              <strong>
                                <Link to={"/Disaster/Edit/?id=" + params.id}>
                                  <img src={Edit} style={{ paddingRight: 10 }} />
                                </Link>
                              </strong>
                            ),
                          }]
                      }
                      sortModel={this.state.sortModel}
                      data={this.state.dataTable}
                      rowsCount={this.state.countData}
                      pageSize={this.state.pageSize}
                      pagging={this.dataGridPagging}
                      rowsPerPageOptions={this.state.rowsPerPageOptions}
                      loading={this.state.isLoading}
                      sort={this.handleSort}
                      setPageSize={this.setPageSize}
                    /></CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment >
    )
  }
}

export default News
