import React, { Component } from 'react'
import CarouselPage from "../AuthenticationInner/CarouselPage"
import { Col, Container, Form, FormGroup, Label, Row, Input, Button } from "reactstrap"
import logologin from "../../assets/images/ttd/logo-d1.svg"
import { Link, withRouter } from "react-router-dom"
import { api_endpoint } from '../../global-config'
import { Route, Redirect } from "react-router-dom"
import { AvField, AvForm } from "availity-reactstrap-validation"


export default class Loginnew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lang: "th",

            username: '',
            password: '',

            login: false

        }


        this.loginagain = this.loginagain.bind(this);

        // this.push = this.push.bind(this);
        // console.log(props)
    }


    loginagain() {
        this.setState({
            login: false
        })
    }


    username_handle(e) {
        this.setState({
            username: e.target.value
        }, () => {
            // console.log(this.state.username)
        })
    }

    password_handle(e) {
        this.setState({
            password: e.target.value
        }, () => {
            // console.log(this.state.password)
        })
    }

    async login() {
        const response = await fetch(
            api_endpoint + `/auth/login`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Username: this.state.username,
                    Password: this.state.password,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        // console.log(_result)
        if (_result.status === 'ok') {
            localStorage.setItem("user_data", JSON.stringify(_result))
            // console.log(localStorage.getItem('user_data'))

            this.props.history.push('/dashboard-blog')



        }
        if (this.props.login_check !== undefined) {
            this.props.login_check()
        }
        else {
            alert(_result.message)
            return
        }
    }




    render() {
        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="no-gutters">
                            <CarouselPage />
                            <Col xl={4}>
                                <div className="auth-full-page-content p-md-5 p-4">
                                    <div className="w-100">
                                        <div className="d-flex flex-column h-100" >
                                            <div style={{ paddingLeft: 80 }}>
                                                <Link to="dashboard" className="ml-5 d-block auth-logo">
                                                    <img
                                                        src={logologin}
                                                        alt=""
                                                        className="auth-logo-dark"
                                                    />
                                                    <img
                                                        src={logologin}
                                                        alt=""

                                                        className="auth-logo-light"
                                                    />
                                                </Link>
                                            </div>
                                            <div className="style-card-login">


                                                <FormGroup>
                                                    <Label className="normal-text-16" for="exampleInputEmail1" style={{ color: 'black' }}>{'Username'}</Label>
                                                    <Input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={'Enter Username'} onChange={(e) => { this.username_handle(e) }}></Input>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label className="normal-text-16" for="exampleInputPassword1 " style={{ color: 'black' }}>{'Password'}</Label>
                                                    <Input type="password" class="form-control" id="exampleInputPassword1" placeholder={'Enter Password'} onChange={(e) => { this.password_handle(e) }}></Input>
                                                </FormGroup>
                                                <div class="form-check">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-6">
                                                            <input type="checkbox" class="form-check-input" id="exampleCheck1"></input>
                                                            <label class="form-check-label normal-text-16" for="exampleCheck1" style={{ color: 'black' }}>{'Remember my account'}</label>
                                                        </div>
                                                        <div className="col-lg-6 col-6" style={{ textAlign: 'right' }}>
                                                            <label className="normal-text-16" style={{ color: 'black' }}>{'Forgot your password ?'}</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <p className="alert-text">ไม่พบข้อมูลของคุณในระบบ</p> */}
                                                <Button className="bt-login normal-text " type="submit" onClick={() => { this.login() }}>{'Login'}</Button>
                                                {/* <div className="row" style={{ justifyContent: 'center', marginTop: 12 }}>
                                                    <div className="col-lg-3 col-sm-3 col-12">
                                                        <hr className="border-hr-right"></hr>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-12 normal-text-16" style={{ textAlign: 'center', alignSelf: 'center' }}>
                                                        {'Login with'}
                                                    </div>
                                                    <div className="col-lg-3 col-sm-3 col-12">
                                                        <hr className="border-hr-left"></hr>
                                                    </div>
                                                </div> */}
                                                {/* <div className="row" style={{ justifyContent: 'center', marginTop: 12 }}>
                                                    <img src={publicUrl + "assets/img/icons/Facebook.png"} alt="Facebook" />
                                                    <img style={{ paddingLeft: 12 }} src={publicUrl + "assets/img/icons/Google.png"} alt="Google" />
                                                </div>
                                                <div className="row" style={{ justifyContent: 'center', marginTop: 16 }}>
                                                    <label className="normal-text" style={{ color: 'black' }}>{'If you are not a member yet'} <span style={{ color: '#FFBF00', cursor: 'pointer' }} onClick={() => { this.registermodal() }}>{'Register'}</span> <span>{'Here'}</span></label>
                                                </div> */}
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
