import React, { Component } from "react"
import { Container, Row, Col, Card, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Cards from "./Cards"
import Posts from "./Posts"
import Settings from "./Settings"
import Comments from "./Comments"
import TapVisitors from "./TopVisitors"
import Activity from "./Activity"
import PopularPost from "./PopularPost"
import DataGrid from '../../components/Table/Table'

import UpdatedMaininfosTable from "./UpdatedMaininfosTable"


const series = [
  {
    name: "Current",
    data: [18, 21, 45, 36, 65, 47, 51, 32, 40, 28, 31, 26],
  },
  {
    name: "Previous",
    data: [30, 11, 22, 18, 32, 23, 58, 45, 30, 36, 15, 34],
  },
]

const options = {
  chart: {
    height: 350,
    type: "area",
    toolbar: {
      show: false,
    },
  },
  colors: ["#556ee6", "#f1b44c"],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 2,
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [20, 100, 100, 100],
    },
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },

  markers: {
    size: 3,
    strokeWidth: 3,

    hover: {
      size: 4,
      sizeOffset: 2,
    },
  },
  legend: {
    position: "top",
    horizontalAlign: "right",
  },
}

export default class index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cardreport: {
        options: options,
        series: series,
        dataTable: [],
      },
      modal: true
    }
  }

  toggle() {
    this.setState({modal: false})
  }

  componentDidMount() {
  }
  
  render() {
    return (

      <React.Fragment>



        <div className="page-content">
          <Container fluid>


            {/* Render Breadcrumb */}
            <Breadcrumbs title="หน้าแรก" breadcrumbItem="แดชบอร์ด" />
            
            {/* <Row> */}
              {/* card user */}
              {/* <Cards cardreport={this.state.cardreport} /> */}
              {/* <Settings /> */}
            {/* </Row> */}

            <Container fluid>
              <UpdatedMaininfosTable />
            </Container>

        <div>
          <Modal isOpen={this.state.modal} style={{maxWidth: "1080px"}}>
            <ModalBody>
            <img class="img-responsive" src="/images/copyright.png" alt="image" />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => this.toggle()} style={{fontSize: "1.5rem"}}>ยอมรับ</Button>
            </ModalFooter>
          </Modal>
        </div>
            {/* <Row>
              <Posts />
              <Comments />
              <TapVisitors />
            </Row> */}

          </Container>








        </div>




      </React.Fragment>
    )
  }
}
