import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    CardSubtitle
} from "reactstrap"


import { Link } from "react-router-dom"

export default class ManageteamLangEn extends Component {
    static propTypes = {
        prop: PropTypes
    }

    render() {
        return (
            <Card>
                <CardBody>
                    <h1>English</h1>
                </CardBody>
            </Card>
        )
    }
}
