import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"

export default class DataApiKey extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }

    }

    render() {
        console.log(this.props)
        return (
            <div>
                <p className={'text-24'}>ข้อมูล Key</p>
                <Row className={'mt-3'}>
                    <Col>
                        <Label className="font-style-16 cl-black">API Secret Key:</Label>
                        <span className="font-style-16 cl-black" style={{ marginLeft: 10 }}>{this.props.data.secret}</span>
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col>
                        <Label className="font-style-16 cl-black">AccessToken:</Label>
                        <span className="font-style-16 cl-black" style={{ marginLeft: 10 }}>{this.props.data.token}</span>
                    </Col>
                </Row>
            </div>
        )
    }
}
