import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"

import FormUploadContent from "../../component/component/FormUploadContent"

export default class ImageGallery extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
        console.log(props)
    }

    render() {
        return (
            <div>
                {this.props.lang === 'th' ?
                    <Row>
                        <Col lg="12">
                            <Label className={'text-24'}>รูปภาพแกลอรี่</Label>
                            <div>
                                <FormUploadContent Images={this.props.Images} submit={this.props.submit} />
                            </div>
                        </Col>
                    </Row>
                    :
                    <div>
                        <Label className={'text-24'}>รูปภาพแกลอรี่</Label>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }
            </div>
        )
    }
}
