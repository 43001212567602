import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { api_endpoint, endpoint } from '../../../../global-config'
import '../../../../assets/scss/custom.scss'


export default class General extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',
            upload_images_loading: false,
            upload_images_cover_loading: false,
        }
    }

    handleAcceptedFiles = async (files) => {
        if (files.length == 1) {
            let [file] = files
            console.log(file)
            var formData = new FormData(); // Currently empty
            formData.append('files', file, file.name);
            let upload_response = await fetch(api_endpoint + '/file/upload', {
                method: "POST",
                body: formData
            })
            let upload_response_object = await upload_response.json()
            if (upload_response_object.status == 'success') {
                console.log(upload_response_object.uploads[0])
                this.props.callback(upload_response_object.uploads[0], 'CoverImages')
                this.props.submit('IntroImage', upload_response_object.uploads[0])
            } else {
                alert('เกิดข้อผิดพลาดในการอัพโหลดไฟล์')
            }
        }
        this.setState({
            upload_images_loading: false
        })

    }

    handleAcceptedFilesCover = async (files) => {
        if (files.length == 1) {
            let [file] = files
            console.log(file)
            var formData = new FormData(); // Currently empty
            formData.append('files', file, file.name);
            let upload_response = await fetch(api_endpoint + '/file/upload', {
                method: "POST",
                body: formData
            })
            let upload_response_object_cover = await upload_response.json()
            if (upload_response_object_cover.status == 'success') {
                console.log(upload_response_object_cover.uploads[0])
                this.props.callback(upload_response_object_cover.uploads[0], 'CoverBackgroundImages')
                this.props.submit('BackgroundImage', upload_response_object_cover.uploads[0])
            } else {
                alert('เกิดข้อผิดพลาดในการอัพโหลดไฟล์')
            }
        }
        this.setState({
            upload_images_cover_loading: false
        })

    }


    render() {
        console.log(this.props)
        return (
            <div>
                <p className={'text-24'}>ข้อมูลทั่วไป</p>
                <Row className={'mt-3'}>
                    <Col lg="12">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="campaignName" className="font-style-16 cl-black">ชื่อ </Label><Label className="required"> *</Label>
                            <Input required type="text" className="form-control" id="Name" placeholder="กรุณากรอกชื่อ" value={this.props.data.Title} onChange={(e) => { this.props.callback(e.target.value, 'Title') }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col lg="12">
                        <FormGroup>
                            <Label className="font-style-16 cl-black" for="campaignSecondName" className="font-style-16 cl-black">ชื่อรอง </Label><Label className="required"> *</Label>
                            <Input required type="text" className="form-control" id="SecondName" placeholder="กรุณากรอกชื่อรอง" value={this.props.data.IntroText} onChange={(e) => { this.props.callback(e.target.value, 'IntroText') }} />
                        </FormGroup>
                    </Col>
                </Row>
                {this.props.lang === 'th' ?
                    <Row className={'mt-3 text-16'}>
                        <Col lg="12">
                            <Label className="font-style-16 cl-black">รูปภาพพื้นหลังหน้าแรก {this.props.data.test} </Label><Label className="required"> * {this.state.upload_images_loading && (<font color="green">โปรดรอกำลังอัพโหลดรูปภาพ</font>)}</Label>
                            <div style={{ display: typeof this.props.data.CoverImages.HashName !== 'undefined' ? 'none' : '' }} className="custom-file" >
                                <input
                                    onChange={e => {
                                        this.setState({
                                            upload_images_loading: true
                                        }, () => {
                                            this.handleAcceptedFiles(e.target.files)
                                            e.target.value = ""
                                        }
                                        )
                                    }}
                                    accept=".jpeg,.gif,.png,.jpg"
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                />
                                <label className="custom-file-label" htmlFor="customFile">
                                    <span className="font-style-16b4 cl-gray"> Choose file</span>
                                </label>
                                <p className="mt-3 font-style-14 cl-darkblue">ขนาดไฟล์ไม่เกิน 2 MB, เฉพาะไฟล์นามสกุล: .jpg, .jpeg, .gif, .png และขนาดไฟล์ที่เหมาะสม: 800 x 600 pixel</p>
                            </div>
                            {typeof this.props.data.CoverImages.HashName != 'undefined' && (
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <img style={{ maxWidth: 350 }} src={`${endpoint}/assets/upload${this.props.data.CoverImages.Path}/${this.props.data.CoverImages.HashName}`}></img>
                                        <div style={{ marginTop: 25 }}>
                                            <button onClick={() => {
                                                this.props.callback({}, 'CoverImages')
                                                this.props.submit('IntroImage', {})
                                            }} type="button" class="btn btn-danger waves-effect waves-light">ยกเลิกไฟล์</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                    :
                    <div>
                        <Label className="mt-3 font-style-16 cl-black">รูปภาพหน้าปก</Label>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }
                {this.props.lang === 'th' ?
                    <Row className={'mt-3 text-16'}>
                        <Col lg="12">
                            <Label className="font-style-16 cl-black">รูปภาพ Cover {this.props.data.test} </Label><Label className="required"> * {this.state.upload_images_cover_loading && (<font color="green">โปรดรอกำลังอัพโหลดรูปภาพ</font>)}</Label>
                            <div style={{ display: typeof this.props.data.CoverBackgroundImages.HashName !== 'undefined' ? 'none' : '' }} className="custom-file" >
                                <input
                                    onChange={e => {
                                        this.setState({
                                            upload_images_cover_loading: true
                                        }, () => {
                                            this.handleAcceptedFilesCover(e.target.files)
                                            e.target.value = ""
                                        }
                                        )
                                    }}
                                    accept=".jpeg,.gif,.png,.jpg"
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile1"
                                />
                                <label className="custom-file-label" htmlFor="customFile1">
                                    <span className="font-style-16b4 cl-gray"> Choose file</span>
                                </label>
                                <p className="mt-3 font-style-14 cl-darkblue">ขนาดไฟล์ไม่เกิน 2 MB, เฉพาะไฟล์นามสกุล: .jpg, .jpeg, .gif, .png และขนาดไฟล์ที่เหมาะสม: 800 x 600 pixel</p>
                            </div>
                            {typeof this.props.data.CoverBackgroundImages.HashName != 'undefined' && (
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <img style={{ maxWidth: 350 }} src={`${endpoint}/assets/upload${this.props.data.CoverBackgroundImages.Path}/${this.props.data.CoverBackgroundImages.HashName}`}></img>
                                        <div style={{ marginTop: 25 }}>
                                            <button onClick={() => {
                                                this.props.callback({}, 'CoverBackgroundImages')
                                                this.props.submit('BackgroundImage', {})
                                            }} type="button" class="btn btn-danger waves-effect waves-light">ยกเลิกไฟล์</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                    :
                    <div>
                        <Label className="mt-3 font-style-16 cl-black">รูปภาพหน้าปก</Label>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }
            </div>
        )
    }
}
