import React, { Component } from 'react'
import PropTypes, { element } from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { Link } from "react-router-dom"
import { api_endpoint } from '../../../global-config'

import Map from "../../Dashboard-blog/MapsGoogle"
import Facilities from './topicAccommodationTap/Facilities'
import Utilities from './topic/Utilities'
import Journey from './topic/Journey'
import Activity from './topic/Activity'
import More from './topic/More'
import Status from './topic/Status'
import General from './topicAccommodationTap/General'
import Address from './topicAccommodationTap/Address'
import Contact from './topic/Contact'
import Picturesvideos from './topic/Picturesvideos'
import Standard from './topicAccommodationTap/Standard'
import Room from './topicAccommodationTap/room'
import DateTime from './topic/DateTime'
import Rates from './topic/Rates'
import Target from './topicAccommodationTap/Target'
import * as loadingData from "../../../loadingttd.json";
import Lottie from "react-lottie";
import FadeIn from "react-fade-in";
import Feedback from './topicAll/Feedback'

const moment = require('moment'); // require
import localization from 'moment/locale/th';
moment.updateLocale('th', localization);

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export default class AccommodationTap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            IDEditter: null,
            IsEnabled: "Y",
            // activeTabJustify: "5",
            lang: props.lang,
            isLoading: true,
            isLoadingDetail: false,
            placeId: null,
            Attraction: [],
            Activities: [],
            Targets: [],
            Facilities: [],
            Utility: [],
            Types: [],
            TravelTypeID: [],
            OpenHours: [],
            Images: [],
            ImagesUpload: [],
            provinces: [],
            districts: [],
            subdistricts: [],
            Feedback: "",
            cost_levels: [],
            accommodation_facility_types: [],
            registration_types: [],
            accommodation_standards: [],
            accommodation_green_leafs: [],
            accommodation_thailand_tourism_standards: [],
            accommodation_service_types: [],
            AccommodationInfo: {
                AccommodationStandardID: null,
                RoomAmount: "",
                MeetingRoomAmount: "",
                HighSeasonStartDate: null,
                HighSeasonEndDate: null,
                LowSeasonStartDate: null,
                LowSeasonEndDate: null,
                HighRate: null,
                LowRate: null,
                IsThaiHotalAssociation: "N",
                IsHotalRegistration: "N",
                SubordicateGroupID: null,
                GreenLeafTypeID: null,
                ThailandTourismStandardTypeID: null,
                AccommodationServiceTypeID: null,
                CostLevelID: null,
                AccommodationRegistrationTypeID: null,
                LicenseNo: "",
                LicenseDate: null,
                LicenseExpireDate: null
            },
            Details: {
                Lang: "th",
                MeetingRoomSize: ""
            },
            th: {
                Name: '',
                Activities: [],
                CoverImages: {},
                Details: "",
                TravelRemark: "",
                History: "",
                AreaSize: "",
                ParkingDetail: "",
                ResponseDepartment: "",
                Feature: "",
                //
                Address: "",
                MapImage: {},
                RegionID: "",
                Province: "",
                District: "",
                County: "",
                Latitude: "",
                Longitude: "",
                //
                Telephone: "",
                Mobile: "",
                Email: "",
                Website: "",
                ContactName: "",
                ContactPosition: "",
                FacebookUrl: "",
                LineID: "",
                Twitter: "",
                //
                OpenHourRemark: "",
                OpencloseRemark: "",
                businesshours: "",
                Suitablerange: "",
                VdoYoutubeUrl: "",
                MoreDetail: ""
            },
            en: {
                Name: '',
                Activities: [],
                Details: "",
                TravelRemark: "",
                History: "",
                AreaSize: "",
                ParkingDetail: "",
                ResponseDepartment: '',
                Feature: "",
                //
                Address: "",
                RegionID: "",
                Province: "",
                District: "",
                County: "",
                Latitude: "",
                Longitude: "",
                //
                Telephone: "",
                Mobile: "",
                Website: "",
                ContactName: "",
                ContactPosition: "",
                FacebookUrl: "",
                LineID: "",
                Twitter: "",
                //
                OpenHourRemark: "",
                OpencloseRemark: "",
                businesshours: "",
                Suitablerange: "",
                VdoYoutubeUrl: "",
                MoreDetail: ""

            },
            zh: {
                Name: '',
                Activities: [],
                Details: "",
                TravelRemark: "",
                History: "",
                AreaSize: "",
                ParkingDetail: "",
                ResponseDepartment: '',
                Feature: "",
                //
                Address: "",
                RegionID: "",
                Province: "",
                District: "",
                County: "",
                Latitude: "",
                Longitude: "",
                //
                Telephone: "",
                Mobile: "",
                Email: "",
                Website: "",
                ContactName: "",
                ContactPosition: "",
                FacebookUrl: "",
                LineID: "",
                Twitter: "",
                //
                OpenHourRemark: "",
                OpencloseRemark: "",
                businesshours: "",
                Suitablerange: "",
                VdoYoutubeUrl: "",
                MoreDetail: ""

            },
            Submit: {},
            status: null,
        }
        this.callback = this.callback.bind(this);
        this.attraction_types = this.attraction_types.bind(this);
        // this.get_attraction = this.get_attraction.bind(this);
        this.get_attraction_targets = this.get_attraction_targets.bind(this);
        // this.accommodation_facility_types = this.accommodation_facility_types.bind(this);
        // this.attraction_utility_types = this.attraction_utility_types.bind(this);
        // this.selectedHandle = this.selectedHandle.bind(this);

        this.submitForm = this.submitForm.bind(this);
    }


    async attraction_types() {
        console.log('xxxxxxxxx')
        const response = await fetch(
            api_endpoint + `/lkup/attraction_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        for (let i = 0; i < _result.length; i++) {
            for (let j = 0; j < _result[i].SubTypes.length; j++) {
                _result[i].SubTypes[j] = { ..._result[i].SubTypes[j], isCheck: false }
            }
        }
        this.setState({
            Types: _result,
        }, () => {
            this.accommodation_registration_types()
        })
    }

    async accommodation_registration_types() {
        const response = await fetch(
            api_endpoint + `/lkup/accommodation_registration_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        console.log(this.state.registration_types)
        for (let i = 0; i < _result.length; i++) {
            if (this.state.registration_types[0] !== undefined && _result[i].ID === this.state.registration_types[0].ID) {
                _result[i] = { ..._result[i], isCheck: true }

            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            registration_types: _result,
        }, () => {
            console.log(this.state.registration_types)
            this.accommodation_standards()
        })
    }


    async accommodation_standards() {
        const response = await fetch(
            api_endpoint + `/lkup/accommodation_standards`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        for (let i = 0; i < _result.length; i++) {
            if (this.state.accommodation_standards[0] !== undefined && _result[i].ID === this.state.accommodation_standards[0].ID) {
                _result[i] = { ..._result[i], isCheck: true }

            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            accommodation_standards: _result,
        }, () => {
            console.log(this.state.accommodation_standards)
            this.accommodation_green_leafs()
        })
    }

    async accommodation_green_leafs() {
        const response = await fetch(
            api_endpoint + `/lkup/accommodation_green_leafs`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        for (let i = 0; i < _result.length; i++) {
            if (this.state.accommodation_green_leafs[0] !== undefined && _result[i].ID === this.state.accommodation_green_leafs[0].ID) {
                _result[i] = { ..._result[i], isCheck: true }

            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            accommodation_green_leafs: _result,
        }, () => {
            console.log(this.state.accommodation_green_leafs)
            this.accommodation_thailand_tourism_standards()
        })
    }

    async accommodation_thailand_tourism_standards() {
        const response = await fetch(
            api_endpoint + `/lkup/accommodation_thailand_tourism_standards`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log('_result')
        console.log(_result)
        for (let i = 0; i < _result.length; i++) {
            if (this.state.accommodation_thailand_tourism_standards[0] !== undefined && _result[i].ID === this.state.accommodation_thailand_tourism_standards[0].ID) {
                _result[i] = { ..._result[i], isCheck: true }

            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        console.log('=-=-=-+++++++_result')
        console.log(this.state.accommodation_thailand_tourism_standards)
        console.log(_result)
        this.setState({
            accommodation_thailand_tourism_standards: _result,
        }, () => {
            console.log(this.state.accommodation_thailand_tourism_standards)
            this.accommodation_service_types()
        })
    }

    async accommodation_service_types() {
        const response = await fetch(
            api_endpoint + `/lkup/accommodation_service_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        for (let i = 0; i < _result.length; i++) {
            if (this.state.accommodation_service_types[0] !== undefined && _result[i].ID === this.state.accommodation_service_types[0].ID) {
                _result[i] = { ..._result[i], isCheck: true }

            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            accommodation_service_types: _result,
        }, () => {
            console.log(this.state.accommodation_service_types)
            this.accommodation_cost_levels()
        })
    }






    async accommodation_cost_levels() {
        const response = await fetch(
            api_endpoint + `/lkup/accommodation_cost_levels`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        for (let i = 0; i < _result.length; i++) {
            if (this.state.cost_levels[0] !== undefined && _result[i].ID === this.state.cost_levels[0].ID) {
                _result[i] = { ..._result[i], isCheck: true }

            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            cost_levels: _result,
        }, () => {
            // console.log(this.state.costrates)
            this.get_provinces()
        })
    }




    async get_provinces() {
        const response = await fetch(
            api_endpoint + `/lkup/provinces`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    RegionID: [],
                    Code: [],

                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();

        for (let i = 0; i < _result.length; i++) {
            if (_result[i].Details[this.state.lang].Name === this.state[this.state.lang].Province) {
                _result[i] = { ..._result[i], isCheck: true }
                let obj = this.state[this.state.lang]
                obj.Province = _result[i].Code
                obj.RegionID = _result[i].RegionID
                this.setState({
                    [this.state.lang]: obj
                })
            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            provinces: _result
        }, () => {
            this.get_districts()
        })
    }


    async get_districts() {
        if (this.state[this.state.lang].District === "" && this.state[this.state.lang].Province === "") {
            this.get_attraction_targets()
            return
        }
        const response = await fetch(
            api_endpoint + `/lkup/districts`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ProvinceCode: this.state[this.state.lang].Province.toString(),
                    // Code: [],

                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();


        for (let i = 0; i < _result.length; i++) {
            if (_result[i].Details[this.state.lang].Name === this.state[this.state.lang].District) {
                _result[i] = { ..._result[i], isCheck: true }
                let obj = this.state[this.state.lang]
                obj.District = _result[i].Code
                this.setState({
                    [this.state.lang]: obj
                })
            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            districts: _result,
        }, () => {
            this.get_subdistricts()
        })
    }

    async get_subdistricts() {
        if (this.state[this.state.lang].County === "" && this.state[this.state.lang].District === "") {
            this.get_attraction_targets()
            return
        }
        const response = await fetch(
            api_endpoint + `/lkup/subdistricts`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ProvinceCode: this.state[this.state.lang].Province.toString(),
                    DistrictCode: this.state[this.state.lang].District.toString(),

                    // Code: [],

                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        for (let i = 0; i < _result.length; i++) {
            if (_result[i].Details[this.state.lang].Name === this.state[this.state.lang].County) {
                _result[i] = { ..._result[i], isCheck: true }
                let obj = this.state[this.state.lang]
                obj.County = _result[i].Code
                this.setState({
                    [this.state.lang]: obj
                })
            }
            else {
                _result[i] = { ..._result[i], isCheck: false }
            }
        }
        this.setState({
            subdistricts: _result,
        }, () => {
            this.get_attraction_targets()
        })
    }




    // async get_attraction() {
    //     const response = await fetch(
    //         api_endpoint + `/lkup/attraction_activity_types`,
    //         {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json"
    //             },
    //             body: JSON.stringify({
    //                 // ID: this.state.place_id,
    //                 Lang: this.state.lang,
    //             }), // body data type must match "Content-Type" header
    //         }
    //     );
    //     var _result = await response.json();

    //     this.setState({
    //         Attraction: _result,
    //     }, () => {
    //         this.get_attraction_targets()
    //     })
    // }

    async get_attraction_targets() {
        const response = await fetch(
            api_endpoint + `/lkup/accommodation_targets`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (Array.isArray(this.state.Targets)) {
            for (let i = 0; i < _result.length; i++) {
                _result[i] = { ..._result[i], isCheck: false }
            }
            _result.map(e => { this.state.Targets.forEach(element => { if (e.ID === element.ID) { e.isCheck = true } }) })
            this.setState({
                Targets: _result,

            }, () => {
                console.log(this.state.Targets)
                this.get_travel_types()
            })
        }
        else {
            for (let i = 0; i < _result.length; i++) {
                _result[i] = { ..._result[i], isCheck: false }
            }
            this.setState({
                Targets: _result,

            }, () => {
                this.get_travel_types()
            })
        }


    }








    async get_travel_types() {
        const response = await fetch(
            api_endpoint + `/lkup/travel_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (Array.isArray(this.state.TravelTypeID)) {
            for (let i = 0; i < _result.length; i++) {
                _result[i] = { ..._result[i], isCheck: false }
            }
            _result.map(e => { this.state.TravelTypeID.forEach(element => { if (e.ID === element.ID) { e.isCheck = true } }) })
            this.setState({
                TravelTypeID: _result,

            }, () => {
                this.accommodation_facility_types()
            })
        }
        else {
            for (let i = 0; i < _result.length; i++) {
                _result[i] = { ..._result[i], isCheck: false }
            }
            this.setState({
                TravelTypeID: _result,

            }, () => {
                this.accommodation_facility_types()
            })
        }





    }



    async accommodation_facility_types() {
        const response = await fetch(
            api_endpoint + `/lkup/accommodation_facility_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (Array.isArray(this.state.Facilities)) {
            for (let i = 0; i < _result.length; i++) {
                for (let j = 0; j < _result[i].SubTypes.length; j++) {
                    _result[i].SubTypes[j] = { ..._result[i].SubTypes[j], isCheck: false }
                }
            }
            this.setState({
                Facilities: _result,
                isLoading: false,
            }, () => {
                console.log(this.state.Facilities)
                // this.attraction_utility_types()
            })
        }
        else {
            for (let i = 0; i < _result.length; i++) {
                for (let j = 0; j < _result[i].SubTypes.length; j++) {
                    _result[i].SubTypes[j] = { ..._result[i].SubTypes[j], isCheck: false }
                }
            }
            _result.map(e => {
                Object.keys(this.state.Facilities).forEach(key => {
                    if (e.ID === this.state.Facilities[key].ID) {
                        e.SubTypes.map(e => {
                            this.state.Facilities[key].SubType.forEach(element => {
                                if (e.ID === element.ID) {
                                    e.isCheck = true
                                }
                            })
                        }

                        )
                    }
                });
            })
            this.setState({
                Facilities: _result,
                isLoading: false,
            }, () => {
                console.log(this.state.Facilities)
                // this.attraction_utility_types()
            })
        }
    }




    callback(value, target) {
        if (target === 'IsEnabled') {
            this.state.IsEnabled = value
            this.setState({
                IsEnabled: this.state.IsEnabled
            }, () => {
                console.log(this.state.IsEnabled)
            })
            return
        }
        if (target === 'Feedback') {
            this.setState({
                Feedback: value
            }, () => {
                console.log(this.state.IsEnabled)
            })
            return
        }

        if (target === 'Latitude') {
            value = parseFloat(value)
        }
        if (target === 'Longitude') {
            value = parseFloat(value)
        }
        //ประเภทลงทะเบียน
        if (target === 'registration_types') {
            this.state.AccommodationInfo.AccommodationRegistrationTypeID = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
        }
        //มาตรฐาน
        if (target === 'accommodation_standards') {
            this.state.AccommodationInfo.AccommodationStandardID = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
        }
        //ใบไม้สีเขียว
        if (target === 'accommodation_green_leafs') {
            this.state.AccommodationInfo.GreenLeafTypeID = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
        }
        //มาตรฐานการท่องเที่ยวไทย
        if (target === 'accommodation_thailand_tourism_standards') {
            this.state.AccommodationInfo.ThailandTourismStandardTypeID = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
        }
        if (target === 'accommodation_service_types') {
            this.state.AccommodationInfo.AccommodationServiceTypeID = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
        }
        if (target === 'cost_levels') {
            console.log(this.state.cost_levels)
            console.log(value)

            this.state.AccommodationInfo.CostLevelID = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
            return
        }
        if (target === 'IsThaiHotalAssociation') {
            this.state.AccommodationInfo.IsThaiHotalAssociation = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
            return
        }
        if (target === 'IsHotalRegistration') {
            this.state.AccommodationInfo.IsHotalRegistration = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
            return
        }

        if (target === 'LicenseDate') {
            this.state.AccommodationInfo.LicenseDate = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
            return
        }
        if (target === 'LicenseExpireDate') {
            this.state.AccommodationInfo.LicenseExpireDate = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
            return
        }
        if (target === 'LicenseNo') {
            this.state.AccommodationInfo.LicenseNo = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
            return
        }
        if (target === 'RoomAmount') {
            this.state.AccommodationInfo.RoomAmount = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
            return
        }
        if (target === 'MeetingRoomAmount') {
            this.state.AccommodationInfo.MeetingRoomAmount = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
            return
        }
        if (target === 'MeetingRoomSize') {
            this.state.Details.MeetingRoomSize = value
            this.setState({
                Details: this.state.Details
            }, () => {
                console.log(this.state.Details)
            })
            return
        }
        if (target === 'HighSeasonStartDate') {
            this.state.AccommodationInfo.HighSeasonStartDate = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
            return
        }
        if (target === 'HighSeasonEndDate') {
            this.state.AccommodationInfo.HighSeasonEndDate = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
            return
        }
        if (target === 'LowSeasonStartDate') {
            this.state.AccommodationInfo.LowSeasonStartDate = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
            return
        }
        if (target === 'LowSeasonEndDate') {
            this.state.AccommodationInfo.LowSeasonEndDate = value
            this.setState({
                AccommodationInfo: this.state.AccommodationInfo
            }, () => {
                console.log(this.state.AccommodationInfo)
            })
            return
        }






        let obj = this.state[this.state.lang]
        // console.log(this.state.lang)
        obj[target] = value;
        console.log(obj)
        this.setState({
            [this.state.lang]: obj
        }, () => {
            if (target === 'Province') {
                this.get_districts()
            }
            if (target === 'District') {
                this.get_subdistricts()
            }

        })
    }

    submitForm(target, value) {

        if (this.state.Submit[target] === undefined) {
            this.state.Submit[target] = value
        }
        else {
            this.state.Submit[target] = value
        }
        console.log(this.state.Submit)

    }

    async getAccommodationDetail(id) {
        let path = `/maininfo/readone`
        if (this.state.status !== null) { path = '/maininfo/readoneupdate' }
        const userData = JSON.parse(localStorage.getItem('authUser'))
        const response = await fetch(
            api_endpoint + path,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify({
                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                    ID: Number(id),
                    IsBackend: 'Y'
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        if (_result.result !== undefined) {
            console.log(_result)
            console.log('asdasdasaa')



            let OpenHours = []
            let Attraction = []

            // _result.result.Attraction.Activities.map((e, i) => {
            //     Attraction.push({
            //         ActivityName: e.SubType[this.state.lang],
            //         AttractionActivitySubTypeID: e.SubType.ID,
            //         AttractionActivityTypeID: e.ID,
            //         Details: { Lang: this.state.lang, Name: e.SubType[this.state.lang], Detail: "" },
            //         id: i
            //     })

            // })

            // _result.result.OpenHours.Items.map(e => {
            //     OpenHours.push({
            //         DayID: e.DayID,
            //         StartTime: moment(e.StartTime).format('LTS').split(" ")[0],
            //         EndTime: moment(e.EndTime).format('LTS').split(" ")[0],
            //         IsAllDay: null
            //     })

            // })

            let Images = []
            _result.result.Images.map(e => {
                Images.push(e)
            })
            console.log(Images)
            console.log('asdasdasdas')
            let th = this.state.th
            let en = this.state.en
            let zh = this.state.zh
            if (this.state.lang === 'th') {
                th = {
                    ...this.state.th,
                    CoverImages: _result.result.IntroImage,
                    MapImage: _result.result.MapImage == null ? {} : _result.result.MapImage,
                    Name: _result.result.Name[this.state.lang],
                    //-----------------------------------------------//
                    Details: _result.result.Detail[this.state.lang],
                    TravelRemark: _result.result.TravelRemark[this.state.lang],

                    //-----------------------------------------------//
                    Address: _result.result.Address[this.state.lang],
                    Province: _result.result.Province[this.state.lang],
                    District: _result.result.District[this.state.lang],
                    County: _result.result.SubDistrict[this.state.lang],
                    Latitude: _result.result.Latitude,
                    Longitude: _result.result.Longitude,
                    //-----------------------------------------------//
                    Telephone: _result.result.Telephone,
                    Mobile: _result.result.Mobile,
                    Email: _result.result.Email,
                    Website: _result.result.Website,
                    ContactName: _result.result.ContactName[this.state.lang],
                    ContactPosition: _result.result.ContactPosition[this.state.lang],
                    VdoYoutubeUrl: _result.result.VdoYoutubeUrl,
                    FacebookUrl: _result.result.FacebookUrl,
                    LineID: _result.result.LineID,
                    Twitter: _result.result.Twitter,
                    MoreDetail: _result.result.MoreDetail[this.state.lang],
                }
            }
            if (this.state.lang === 'en') {
                en = {
                    ...this.state.th,
                    CoverImages: _result.result.IntroImage,
                    MapImage: _result.result.MapImage == null ? {} : _result.result.MapImage,
                    Name: _result.result.Name[this.state.lang],
                    //-----------------------------------------------//
                    Details: _result.result.Detail[this.state.lang],
                    TravelRemark: _result.result.TravelRemark[this.state.lang],

                    //-----------------------------------------------//
                    Address: _result.result.Address[this.state.lang],
                    Province: _result.result.Province[this.state.lang],
                    District: _result.result.District[this.state.lang],
                    County: _result.result.SubDistrict[this.state.lang],
                    Latitude: _result.result.Latitude,
                    Longitude: _result.result.Longitude,
                    //-----------------------------------------------//
                    Telephone: _result.result.Telephone,
                    Mobile: _result.result.Mobile,
                    Email: _result.result.Email,
                    Website: _result.result.Website,
                    ContactName: _result.result.ContactName[this.state.lang],
                    ContactPosition: _result.result.ContactPosition[this.state.lang],
                    VdoYoutubeUrl: _result.result.VdoYoutubeUrl,
                    FacebookUrl: _result.result.FacebookUrl,
                    LineID: _result.result.LineID,
                    Twitter: _result.result.Twitter,
                    MoreDetail: _result.result.MoreDetail[this.state.lang],
                }
            }
            if (this.state.lang === 'zh') {
                zh = {
                    ...this.state.th,
                    CoverImages: _result.result.IntroImage,
                    MapImage: _result.result.MapImage == null ? {} : _result.result.MapImage,
                    Name: _result.result.Name[this.state.lang],
                    //-----------------------------------------------//
                    Details: _result.result.Detail[this.state.lang],
                    TravelRemark: _result.result.TravelRemark[this.state.lang],

                    //-----------------------------------------------//
                    Address: _result.result.Address[this.state.lang],
                    Province: _result.result.Province[this.state.lang],
                    District: _result.result.District[this.state.lang],
                    County: _result.result.SubDistrict[this.state.lang],
                    Latitude: _result.result.Latitude,
                    Longitude: _result.result.Longitude,
                    //-----------------------------------------------//
                    Telephone: _result.result.Telephone,
                    Mobile: _result.result.Mobile,
                    Email: _result.result.Email,
                    Website: _result.result.Website,
                    ContactName: _result.result.ContactName[this.state.lang],
                    ContactPosition: _result.result.ContactPosition[this.state.lang],
                    VdoYoutubeUrl: _result.result.VdoYoutubeUrl,
                    FacebookUrl: _result.result.FacebookUrl,
                    LineID: _result.result.LineID,
                    Twitter: _result.result.Twitter,
                    MoreDetail: _result.result.MoreDetail[this.state.lang],
                }
            }
            //RegisterTypes
            let registration_types = [...this.state.registration_types]
            registration_types.push(_result.result.Accommodation.Info.AccommodationRegistrationType)
            this.state.AccommodationInfo.AccommodationRegistrationTypeID = _result.result.Accommodation.Info.AccommodationRegistrationTypeID

            //ServiceTypes
            let accommodation_service_types = [...this.state.accommodation_service_types]
            accommodation_service_types.push(_result.result.Accommodation.Info.AccommodationServiceType)
            this.state.AccommodationInfo.AccommodationServiceTypeID = _result.result.Accommodation.Info.AccommodationServiceTypeID

            //CostLevel
            let cost_levels = [...this.state.cost_levels]
            cost_levels.push(_result.result.Accommodation.Info.CostLevel)
            this.state.AccommodationInfo.CostLevelID = _result.result.Accommodation.Info.CostLevelID

            //Standards
            let accommodation_standards = [...this.state.accommodation_standards]
            accommodation_standards.push(_result.result.Accommodation.Info.AccommodationStandard)
            this.state.AccommodationInfo.AccommodationStandardID = _result.result.Accommodation.Info.AccommodationStandardID

            //GreenLeafs
            let accommodation_green_leafs = [...this.state.accommodation_green_leafs]
            accommodation_green_leafs.push(_result.result.Accommodation.Info.GreenLeafType)
            this.state.AccommodationInfo.GreenLeafTypeID = _result.result.Accommodation.Info.GreenLeafTypeID

            //ThailandTourismStandards
            let accommodation_thailand_tourism_standards = [...this.state.accommodation_thailand_tourism_standards]
            accommodation_thailand_tourism_standards.push(_result.result.Accommodation.Info.ThailandTourismStandardType)
            this.state.AccommodationInfo.ThailandTourismStandardTypeID = _result.result.Accommodation.Info.ThailandTourismStandardTypeID

            //License
            let LicenseNo = this.state.AccommodationInfo.LicenseNo
            LicenseNo = _result.result.Accommodation.Info.LicenseNo

            //LicenseDate & LicenseExpireDate
            let LicenseDate = this.state.AccommodationInfo.LicenseDate
            LicenseDate = _result.result.Accommodation.Info.LicenseDate
            let LicenseExpireDate = this.state.AccommodationInfo.LicenseExpireDate
            LicenseExpireDate = _result.result.Accommodation.Info.LicenseExpireDate

            //IsThaiHotalAssociation
            let IsThaiHotalAssociation = this.state.AccommodationInfo.IsThaiHotalAssociation
            IsThaiHotalAssociation = _result.result.Accommodation.Info.IsThaiHotalAssociation

            //IsHotalRegistration
            let IsHotalRegistration = this.state.AccommodationInfo.IsHotalRegistration
            IsHotalRegistration = _result.result.Accommodation.Info.IsHotalRegistration

            //HighSeasonStartDate & HighSeasonEndDate 
            let HighSeasonStartDate = this.state.AccommodationInfo.HighSeasonStartDate
            HighSeasonStartDate = _result.result.Accommodation.Info.HighSeasonStartDate
            let HighSeasonEndDate = this.state.AccommodationInfo.HighSeasonEndDate
            HighSeasonEndDate = _result.result.Accommodation.Info.HighSeasonEndDate

            //LowSeasonStartDate & LowSeasonEndDate
            let LowSeasonStartDate = this.state.AccommodationInfo.LowSeasonStartDate
            LowSeasonStartDate = _result.result.Accommodation.Info.LowSeasonStartDate
            let LowSeasonEndDate = this.state.AccommodationInfo.LowSeasonEndDate
            LowSeasonEndDate = _result.result.Accommodation.Info.LowSeasonEndDate

            //RoomAmount
            let RoomAmount = this.state.AccommodationInfo.RoomAmount
            RoomAmount = _result.result.Accommodation.Info.RoomAmount

            //MeetingRoomAmount
            let MeetingRoomAmount = this.state.AccommodationInfo.MeetingRoomAmount
            MeetingRoomAmount = _result.result.Accommodation.Info.MeetingRoomAmount

            //MeetingRoomSize
            let MeetingRoomSize = this.state.Details.MeetingRoomSize
            MeetingRoomSize = _result.result.Accommodation.Details.MeetingRoomSize[this.state.lang]

            this.state.IDEditter = _result.result.ID,
                this.state.IsEnabled = _result.result.IsEnabled,
                this.state.Feedback = _result.result.Feedback,
                this.setState({
                    th: th,
                    en: en,
                    zh: zh,
                    AccommodationInfo: {
                        AccommodationRegistrationTypeID: this.state.AccommodationInfo.AccommodationRegistrationTypeID,
                        AccommodationServiceTypeID: this.state.AccommodationInfo.AccommodationServiceTypeID,
                        CostLevelID: this.state.AccommodationInfo.CostLevelID,
                        AccommodationStandardID: this.state.AccommodationInfo.AccommodationStandardID,
                        GreenLeafTypeID: this.state.AccommodationInfo.GreenLeafTypeID,
                        ThailandTourismStandardTypeID: this.state.AccommodationInfo.ThailandTourismStandardTypeID,
                        LicenseNo: LicenseNo,
                        LicenseDate: LicenseDate,
                        LicenseExpireDate: LicenseExpireDate,
                        IsThaiHotalAssociation: IsThaiHotalAssociation,
                        IsHotalRegistration: IsHotalRegistration,
                        HighSeasonStartDate: HighSeasonStartDate,
                        HighSeasonEndDate: HighSeasonEndDate,
                        LowSeasonStartDate: LowSeasonStartDate,
                        LowSeasonEndDate: LowSeasonEndDate,
                        RoomAmount: RoomAmount,
                        MeetingRoomAmount: MeetingRoomAmount
                    },
                    Details: {
                        Lang: this.state.lang,
                        MeetingRoomSize: MeetingRoomSize
                    },
                    registration_types: registration_types,
                    accommodation_service_types: accommodation_service_types,
                    cost_levels: cost_levels,
                    accommodation_green_leafs: accommodation_green_leafs,
                    accommodation_standards: accommodation_standards,
                    accommodation_thailand_tourism_standards: accommodation_thailand_tourism_standards,
                    Targets: _result.result.Accommodation.Targets,
                    TravelTypeID: _result.result.TravelTypes.Items,
                    Facilities: _result.result.Accommodation.Facilities,
                    Activities: Attraction,
                    Images: Images,
                    OpenHours: OpenHours,
                    placeId: this.state.status === null ? this.state.placeId : _result.result.MainInfoID,
                    isLoadingDetail: false

                }, () => {
                    console.log(this.state.RoomAmount)
                    console.log(this.state.MeetingRoomAmount)
                    this.attraction_types()
                })

        }
        else {
            console.log('Error : getAccommodationDetail ')
        }

    }


    async submitData() {
        if (this.state[this.state.lang].Name === "") {
            return alert('กรุณาใส่ชื่อ')
        }
        if (this.state[this.state.lang].Details === "") {
            return alert('กรุณาใส่รายละเอียด')
        }
        if (this.state[this.state.lang].Address === "") {
            return alert('กรุณาใส่ที่อยู่')
        }
        if (this.state[this.state.lang].Province === "") {
            return alert('กรุณาเลือกจังหวัด')
        }
        if (this.state[this.state.lang].County === "") {
            return alert('กรุณาเลือก อำเภอ และ ตำบล')
        }
        if (this.state[this.state.lang].Latitude === "") {
            return alert('กรุณาใส่ พิกัดละติจูด')
        }
        if (this.state[this.state.lang].Longitude === "") {
            return alert('กรุณาใส่ พิกัดลองติจูด')
        }
        // if (this.state[this.state.lang].District === "") {
        //     return alert('กรุณาเลือกตำบล')
        // }
        if (this.state.Submit.ImageUpload === undefined) {
            return alert('กรุณาใส่รูป')
        }
        let IsEnabled = this.state.IsEnabled
        let Feedback = this.state.Feedback
        const userData = JSON.parse(localStorage.getItem('authUser'))
        if (userData.UserSubType === "Editor") {
            console.log('Editer >>>>')
            IsEnabled = "N"
            console.log(IsEnabled)
        }
        let obj = {
            MainTypeID: 4,
            IsEnabled: IsEnabled,
            Feedback: Feedback,
            // ID: userData.UserID,
            Main: {
                Info: {
                    SubDistrictCode: this.state[this.state.lang].County,
                    DistrictCode: this.state[this.state.lang].District,
                    ProvinceCode: this.state[this.state.lang].Province,
                    RegionID: 1,
                    PostCode: "",
                    Telephone: this.state[this.state.lang].Telephone,
                    Fax: "",
                    Mobile: this.state[this.state.lang].Mobile,
                    Website: this.state[this.state.lang].Website,
                    Email: this.state[this.state.lang].Email,
                    FacebookUrl: this.state[this.state.lang].FacebookUrl,
                    LineID: this.state[this.state.lang].LineID,
                    Twitter: this.state[this.state.lang].Twitter,
                    Latitude: this.state[this.state.lang].Latitude,
                    Longitude: this.state[this.state.lang].Longitude,
                    HashName: this.state.Submit.ImageUpload[0].HashName,
                    Path: this.state.Submit.ImageUpload[0].Path,
                    IntroImage: this.state.Submit.IntroImage,
                    MapImage: this.state.Submit.MapImage,
                    VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl

                },
                Details: {
                    [this.state.lang]: {
                        Lang: this.state.lang,
                        Name: this.state[this.state.lang].Name,
                        Detail: this.state[this.state.lang].Details,
                        Location: "",
                        Address: this.state[this.state.lang].Address,
                        Moo: "",
                        Soi: "",
                        Road: "",
                        Remark: "",
                        ContactName: this.state[this.state.lang].ContactName,
                        ContactPosition: this.state[this.state.lang].ContactPosition,
                        TravelRemark: this.state[this.state.lang].TravelRemark,
                        OpenHourRemark: "",

                        TravelMoreDetail: "",
                        MoreDetail: this.state[this.state.lang].MoreDetail,
                    }
                },
                Kinds: [],
                // OpenHours: this.state.Submit.OpenHours,
                TravelTypes: this.state.Submit.Travel_types,
                Images: this.state.Submit.ImageUpload,
            },
            // Accommodation: {
            //     Info: this.state.AccommodationInfo,
            //     Details: {},
            //     Targets: this.state.Submit.Targets,
            //     Facilities: this.state.Submit.Facilities
            // },
            Accommodation: {
                Info: {
                    AccommodationRegistrationTypeID: this.state.AccommodationInfo.AccommodationRegistrationTypeID,
                    AccommodationServiceTypeID: this.state.AccommodationInfo.AccommodationServiceTypeID,
                    CostLevelID: this.state.AccommodationInfo.CostLevelID,
                    AccommodationStandardID: this.state.AccommodationInfo.AccommodationStandardID,
                    GreenLeafTypeID: this.state.AccommodationInfo.GreenLeafTypeID,
                    ThailandTourismStandardTypeID: this.state.AccommodationInfo.ThailandTourismStandardTypeID,
                    RoomAmount: this.state.AccommodationInfo.RoomAmount,
                    MeetingRoomAmount: this.state.AccommodationInfo.MeetingRoomAmount,
                    LicenseNo: this.state.AccommodationInfo.LicenseNo,
                    LicenseDate: this.state.AccommodationInfo.LicenseDate,
                    LicenseExpireDate: this.state.AccommodationInfo.LicenseExpireDate,
                    HighSeasonStartDate: this.state.AccommodationInfo.HighSeasonStartDate,
                    HighSeasonEndDate: this.state.AccommodationInfo.HighSeasonEndDate,
                    LowSeasonStartDate: this.state.AccommodationInfo.LowSeasonStartDate,
                    LowSeasonEndDate: this.state.AccommodationInfo.LowSeasonEndDate,
                    IsHotalRegistration: this.state.AccommodationInfo.IsHotalRegistration,
                    IsThaiHotalAssociation: this.state.AccommodationInfo.IsThaiHotalAssociation
                },
                Details: {
                    [this.state.lang]: {
                        Lang: this.state.Details.Lang,
                        MeetingRoomSize: this.state.Details.MeetingRoomSize,
                    },
                },
                Targets: this.state.Submit.Targets,
                Facilities: this.state.Submit.Facilities
            }



        }
        //ปิดส่ง
        console.log(JSON.stringify(obj))
        // return
        const response = await fetch(
            api_endpoint + `/maininfo/create`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (_result.status === "ok") {
            alert('เพิ่มที่พักสำเร็จ')
            window.location.href = "/Accommodation";
        }
        else {
            console.log('error : submitData')
        }
    }

    async updateData() {
        const userData = JSON.parse(localStorage.getItem('authUser'))
        let obj = {}
        if (this.state.lang === 'th') {
            obj = {
                MainTypeID: 4,
                ID: Number(this.state.placeId),
                IsEnabled: this.state.IsEnabled,
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        SubDistrictCode: this.state[this.state.lang].County,
                        DistrictCode: this.state[this.state.lang].District,
                        ProvinceCode: this.state[this.state.lang].Province,
                        RegionID: 1,
                        PostCode: "",
                        Telephone: this.state[this.state.lang].Telephone,
                        Fax: "",
                        Mobile: this.state[this.state.lang].Mobile,
                        Website: this.state[this.state.lang].Website,
                        Email: this.state[this.state.lang].Email,
                        FacebookUrl: this.state[this.state.lang].FacebookUrl,
                        LineID: this.state[this.state.lang].LineID,
                        Twitter: this.state[this.state.lang].Twitter,
                        Latitude: this.state[this.state.lang].Latitude,
                        Longitude: this.state[this.state.lang].Longitude,
                        IntroImage: this.state.Submit.IntroImage,
                        MapImage: this.state.Submit.MapImage,
                        VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl


                        // HashName: this.state.Submit.ImageUpload[0].HashName,
                        // Path: this.state.Submit.ImageUpload[0].Path

                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            Location: "",
                            Address: this.state[this.state.lang].Address,
                            Moo: "",
                            Soi: "",
                            Road: "",
                            Remark: "",
                            ContactName: this.state[this.state.lang].ContactName,
                            ContactPosition: this.state[this.state.lang].ContactPosition,
                            TravelRemark: this.state[this.state.lang].TravelRemark,

                            TravelMoreDetail: "",
                            MoreDetail: this.state[this.state.lang].MoreDetail,
                        }
                    },
                    OpenHours: this.state.Submit.OpenHours,
                    TravelTypes: this.state.Submit.Travel_types,
                    Images: this.state.Submit.ImageUpload,
                },
                // Accommodation: {
                //     Info: this.state.AccommodationInfo,
                //     Details: {},
                //     Targets: this.state.Submit.Targets,
                //     Facilities: this.state.Submit.Facilities
                // },
                Accommodation: {
                    Info: {
                        AccommodationRegistrationTypeID: this.state.AccommodationInfo.AccommodationRegistrationTypeID,
                        AccommodationServiceTypeID: this.state.AccommodationInfo.AccommodationServiceTypeID,
                        CostLevelID: this.state.AccommodationInfo.CostLevelID,
                        AccommodationStandardID: this.state.AccommodationInfo.AccommodationStandardID,
                        GreenLeafTypeID: this.state.AccommodationInfo.GreenLeafTypeID,
                        ThailandTourismStandardTypeID: this.state.AccommodationInfo.ThailandTourismStandardTypeID,
                        RoomAmount: this.state.AccommodationInfo.RoomAmount,
                        MeetingRoomAmount: this.state.AccommodationInfo.MeetingRoomAmount,
                        LicenseNo: this.state.AccommodationInfo.LicenseNo,
                        LicenseDate: this.state.AccommodationInfo.LicenseDate,
                        LicenseExpireDate: this.state.AccommodationInfo.LicenseExpireDate,
                        HighSeasonStartDate: this.state.AccommodationInfo.HighSeasonStartDate,
                        HighSeasonEndDate: this.state.AccommodationInfo.HighSeasonEndDate,
                        LowSeasonStartDate: this.state.AccommodationInfo.LowSeasonStartDate,
                        LowSeasonEndDate: this.state.AccommodationInfo.LowSeasonEndDate,
                        IsHotalRegistration: this.state.AccommodationInfo.IsHotalRegistration,
                        IsThaiHotalAssociation: this.state.AccommodationInfo.IsThaiHotalAssociation
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.Details.Lang,
                            MeetingRoomSize: this.state.Details.MeetingRoomSize,
                        },
                    },
                    Targets: this.state.Submit.Targets,
                    Facilities: this.state.Submit.Facilities
                }

            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }
        }
        if (this.state.lang === 'en') {
            obj = {
                MainTypeID: 4,
                ID: Number(this.state.placeId),
                Main: {
                    // Info: {
                    //     SubDistrictCode: this.state[this.state.lang].County,
                    //     DistrictCode: this.state[this.state.lang].District,
                    //     ProvinceCode: this.state[this.state.lang].Province,
                    //     RegionID: 1,
                    //     PostCode: "",
                    //     Telephone: this.state[this.state.lang].Telephone,
                    //     Fax: "",
                    //     Mobile: this.state[this.state.lang].Mobile,
                    //     Website: this.state[this.state.lang].Website,
                    //     Email: this.state[this.state.lang].Email,
                    //     FacebookUrl: "facebook.com/abcd",
                    //     LineID: "",
                    //     Twitter: "",
                    //     Latitude: this.state[this.state.lang].Latitude,
                    //     Longitude: this.state[this.state.lang].Longitude,
                    //     // HashName: this.state.Submit.ImageUpload[0].HashName,
                    //     // Path: this.state.Submit.ImageUpload[0].Path

                    // },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            // Location: "",
                            Address: this.state[this.state.lang].Address,
                            MoreDetail: this.state[this.state.lang].MoreDetail,
                            // Moo: "",
                            // Soi: "",
                            // Road: "",
                            // Remark: "",
                            // ContactName: "",
                            // ContactPosition: "",
                            // TravelRemark: this.state[this.state.lang].TravelRemark,
                            // OpenHourRemark: "",
                            // MoreDetail: "",
                            // TravelMoreDetail: ""
                        }
                    },
                    // OpenHours: this.state.Submit.OpenHours,
                    // TravelTypes: this.state.Submit.Travel_types,
                    // Images: this.state.Submit.ImageUpload,
                },
                Attraction: {
                    // Info: {
                    //     HasCost: "N",
                    //     CostForeignAdult: null,
                    //     CostForeignChild: null,
                    //     CostThaiAdult: null,
                    //     CostThaiChild: null,
                    //     LabelAmount: null

                    // },
                    // Types: this.state.Submit.Types === undefined ? [] : this.state.Submit.Types,
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            History: this.state[this.state.lang].History,
                            // OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
                            // HighSeason: "",
                            Feature: this.state[this.state.lang].Feature,
                            // RewardOther: "",
                            // Keyword: "",
                            // KeywordRemark: null,
                            // CostRemark: "",
                            // UseTime: "",
                            // Detail: null,
                            ResponseDepartment: this.state[this.state.lang].ResponseDepartment,
                            AreaSize: this.state[this.state.lang].AreaSize,
                            ParkingDetail: this.state[this.state.lang].ParkingDetail,
                            // TargetRemark: null

                        }
                    },
                    // Activities: this.state.Submit.Attraction,
                    // Facilities: this.state.Submit.Facilities,
                    // Targets: this.state.Submit.Targets,
                    // Utilities: this.state.Submit.Utility,
                }

            }

        }
        if (this.state.lang === 'zh') {
            obj = {
                MainTypeID: 4,
                ID: Number(this.state.placeId),
                Main: {
                    // Info: {
                    //     SubDistrictCode: this.state[this.state.lang].County,
                    //     DistrictCode: this.state[this.state.lang].District,
                    //     ProvinceCode: this.state[this.state.lang].Province,
                    //     RegionID: 1,
                    //     PostCode: "",
                    //     Telephone: this.state[this.state.lang].Telephone,
                    //     Fax: "",
                    //     Mobile: this.state[this.state.lang].Mobile,
                    //     Website: this.state[this.state.lang].Website,
                    //     Email: this.state[this.state.lang].Email,
                    //     FacebookUrl: "facebook.com/abcd",
                    //     LineID: "",
                    //     Twitter: "",
                    //     Latitude: this.state[this.state.lang].Latitude,
                    //     Longitude: this.state[this.state.lang].Longitude,
                    //     // HashName: this.state.Submit.ImageUpload[0].HashName,
                    //     // Path: this.state.Submit.ImageUpload[0].Path

                    // },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            // Location: "",
                            Address: this.state[this.state.lang].Address,
                            MoreDetail: this.state[this.state.lang].MoreDetail,
                            // Moo: "",
                            // Soi: "",
                            // Road: "",
                            // Remark: "",
                            // ContactName: "",
                            // ContactPosition: "",
                            // TravelRemark: this.state[this.state.lang].TravelRemark,
                            // OpenHourRemark: "",
                            // MoreDetail: "",
                            // TravelMoreDetail: ""
                        }
                    },
                    // OpenHours: this.state.Submit.OpenHours,
                    // TravelTypes: this.state.Submit.Travel_types,
                    // Images: this.state.Submit.ImageUpload,
                },
                Attraction: {
                    // Info: {
                    //     HasCost: "N",
                    //     CostForeignAdult: null,
                    //     CostForeignChild: null,
                    //     CostThaiAdult: null,
                    //     CostThaiChild: null,
                    //     LabelAmount: null

                    // },
                    // Types: this.state.Submit.Types === undefined ? [] : this.state.Submit.Types,
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            History: this.state[this.state.lang].History,
                            // OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
                            // HighSeason: "",
                            Feature: this.state[this.state.lang].Feature,
                            // RewardOther: "",
                            // Keyword: "",
                            // KeywordRemark: null,
                            // CostRemark: "",
                            // UseTime: "",
                            // Detail: null,
                            ResponseDepartment: this.state[this.state.lang].ResponseDepartment,
                            AreaSize: this.state[this.state.lang].AreaSize,
                            ParkingDetail: this.state[this.state.lang].ParkingDetail,
                            // TargetRemark: null

                        }
                    },
                    // Activities: this.state.Submit.Attraction,
                    // Facilities: this.state.Submit.Facilities,
                    // Targets: this.state.Submit.Targets,
                    // Utilities: this.state.Submit.Utility,
                }

            }

        }

        const response = await fetch(
            api_endpoint + `/maininfo/update`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (_result.status === "ok") {


            alert('แก้ไขที่พักสำเร็จ')
            if (userData.UserSubType !== 'Admin' && userData.UserSubType !== 'SuperAdmin') {
                window.location.href = "/Accommodation";
            }

        }
        else {
            console.log('error : updateData')
        }
    }


    async Approve() {
        const userData = JSON.parse(localStorage.getItem('authUser'))
        let obj = {}
        if (this.state.lang === 'th') {
            obj = {
                MainTypeID: 4,
                ID: Number(this.state.IDEditter),
                IsEnabled: this.state.IsEnabled,
                Feedback: this.state.Feedback,
                Main: {
                    Info: {
                        SubDistrictCode: this.state[this.state.lang].County,
                        DistrictCode: this.state[this.state.lang].District,
                        ProvinceCode: this.state[this.state.lang].Province,
                        RegionID: 1,
                        PostCode: "",
                        Telephone: this.state[this.state.lang].Telephone,
                        Fax: "",
                        Mobile: this.state[this.state.lang].Mobile,
                        Website: this.state[this.state.lang].Website,
                        Email: this.state[this.state.lang].Email,
                        FacebookUrl: "facebook.com/abcd",
                        LineID: "",
                        Twitter: "",
                        Latitude: this.state[this.state.lang].Latitude,
                        Longitude: this.state[this.state.lang].Longitude,
                        IntroImage: this.state.Submit.IntroImage,
                        MapImage: this.state.Submit.MapImage,
                        VdoYoutubeUrl: this.state[this.state.lang].VdoYoutubeUrl,

                        // HashName: this.state.Submit.ImageUpload[0].HashName,
                        // Path: this.state.Submit.ImageUpload[0].Path

                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            Location: "",
                            Address: this.state[this.state.lang].Address,
                            Moo: "",
                            Soi: "",
                            Road: "",
                            Remark: "",
                            ContactName: this.state[this.state.lang].ContactName,
                            ContactPosition: this.state[this.state.lang].ContactPosition,
                            TravelRemark: this.state[this.state.lang].TravelRemark,
                            OpenHourRemark: "",
                            // MoreDetail: "",
                            TravelMoreDetail: "",
                            MoreDetail: this.state[this.state.lang].MoreDetail,

                        }
                    },
                    OpenHours: this.state.Submit.OpenHours,
                    TravelTypes: this.state.Submit.Travel_types,
                    Images: this.state.Submit.ImageUpload,
                },
                // Accommodation: {
                //     Info: this.state.AccommodationInfo,
                //     Details: {},

                // },
                Accommodation: {
                    Info: {
                        AccommodationRegistrationTypeID: this.state.AccommodationInfo.AccommodationRegistrationTypeID,
                        AccommodationServiceTypeID: this.state.AccommodationInfo.AccommodationServiceTypeID,
                        CostLevelID: this.state.AccommodationInfo.CostLevelID,
                        AccommodationStandardID: this.state.AccommodationInfo.AccommodationStandardID,
                        GreenLeafTypeID: this.state.AccommodationInfo.GreenLeafTypeID,
                        ThailandTourismStandardTypeID: this.state.AccommodationInfo.ThailandTourismStandardTypeID,
                        RoomAmount: this.state.AccommodationInfo.RoomAmount,
                        MeetingRoomAmount: this.state.AccommodationInfo.MeetingRoomAmount,
                        LicenseNo: this.state.AccommodationInfo.LicenseNo,
                        LicenseDate: this.state.AccommodationInfo.LicenseDate,
                        LicenseExpireDate: this.state.AccommodationInfo.LicenseExpireDate,
                        HighSeasonStartDate: this.state.AccommodationInfo.HighSeasonStartDate,
                        HighSeasonEndDate: this.state.AccommodationInfo.HighSeasonEndDate,
                        LowSeasonStartDate: this.state.AccommodationInfo.LowSeasonStartDate,
                        LowSeasonEndDate: this.state.AccommodationInfo.LowSeasonEndDate,
                        IsHotalRegistration: this.state.AccommodationInfo.IsHotalRegistration,
                        IsThaiHotalAssociation: this.state.AccommodationInfo.IsThaiHotalAssociation
                    },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.Details.Lang,
                            MeetingRoomSize: this.state.Details.MeetingRoomSize,
                        },
                    },
                    Targets: this.state.Submit.Targets,
                    Facilities: this.state.Submit.Facilities
                }

            }
            if (obj.Main.Images === undefined) {
                delete obj.Main.Images
            }
        }
        if (this.state.lang === 'en') {
            obj = {
                MainTypeID: 4,
                ID: Number(this.state.IDEditter),
                Main: {
                    // Info: {
                    //     SubDistrictCode: this.state[this.state.lang].County,
                    //     DistrictCode: this.state[this.state.lang].District,
                    //     ProvinceCode: this.state[this.state.lang].Province,
                    //     RegionID: 1,
                    //     PostCode: "",
                    //     Telephone: this.state[this.state.lang].Telephone,
                    //     Fax: "",
                    //     Mobile: this.state[this.state.lang].Mobile,
                    //     Website: this.state[this.state.lang].Website,
                    //     Email: this.state[this.state.lang].Email,
                    //     FacebookUrl: "facebook.com/abcd",
                    //     LineID: "",
                    //     Twitter: "",
                    //     Latitude: this.state[this.state.lang].Latitude,
                    //     Longitude: this.state[this.state.lang].Longitude,
                    //     // HashName: this.state.Submit.ImageUpload[0].HashName,
                    //     // Path: this.state.Submit.ImageUpload[0].Path

                    // },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            // Location: "",
                            Address: this.state[this.state.lang].Address,
                            MoreDetail: this.state[this.state.lang].MoreDetail,

                            // Moo: "",
                            // Soi: "",
                            // Road: "",
                            // Remark: "",
                            // ContactName: "",
                            // ContactPosition: "",
                            // TravelRemark: this.state[this.state.lang].TravelRemark,
                            // OpenHourRemark: "",
                            // MoreDetail: "",
                            // TravelMoreDetail: ""
                        }
                    },
                    // OpenHours: this.state.Submit.OpenHours,
                    // TravelTypes: this.state.Submit.Travel_types,
                    // Images: this.state.Submit.ImageUpload,
                },
                Accommodation: {
                    // Info: {
                    //     HasCost: "N",
                    //     CostForeignAdult: null,
                    //     CostForeignChild: null,
                    //     CostThaiAdult: null,
                    //     CostThaiChild: null,
                    //     LabelAmount: null

                    // },
                    // Types: this.state.Submit.Types === undefined ? [] : this.state.Submit.Types,
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            History: this.state[this.state.lang].History,
                            // OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
                            // HighSeason: "",
                            Feature: this.state[this.state.lang].Feature,
                            // RewardOther: "",
                            // Keyword: "",
                            // KeywordRemark: null,
                            // CostRemark: "",
                            // UseTime: "",
                            // Detail: null,
                            ResponseDepartment: this.state[this.state.lang].ResponseDepartment,
                            AreaSize: this.state[this.state.lang].AreaSize,
                            ParkingDetail: this.state[this.state.lang].ParkingDetail,
                            // TargetRemark: null

                        }
                    },
                    // Activities: this.state.Submit.Attraction,
                    // Facilities: this.state.Submit.Facilities,
                    // Targets: this.state.Submit.Targets,
                    // Utilities: this.state.Submit.Utility,
                }

            }

        }
        if (this.state.lang === 'zh') {
            obj = {
                MainTypeID: 4,
                ID: Number(this.state.IDEditter),
                Main: {
                    // Info: {
                    //     SubDistrictCode: this.state[this.state.lang].County,
                    //     DistrictCode: this.state[this.state.lang].District,
                    //     ProvinceCode: this.state[this.state.lang].Province,
                    //     RegionID: 1,
                    //     PostCode: "",
                    //     Telephone: this.state[this.state.lang].Telephone,
                    //     Fax: "",
                    //     Mobile: this.state[this.state.lang].Mobile,
                    //     Website: this.state[this.state.lang].Website,
                    //     Email: this.state[this.state.lang].Email,
                    //     FacebookUrl: "facebook.com/abcd",
                    //     LineID: "",
                    //     Twitter: "",
                    //     Latitude: this.state[this.state.lang].Latitude,
                    //     Longitude: this.state[this.state.lang].Longitude,
                    //     // HashName: this.state.Submit.ImageUpload[0].HashName,
                    //     // Path: this.state.Submit.ImageUpload[0].Path

                    // },
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            Name: this.state[this.state.lang].Name,
                            Detail: this.state[this.state.lang].Details,
                            // Location: "",
                            Address: this.state[this.state.lang].Address,
                            MoreDetail: this.state[this.state.lang].MoreDetail,

                            // Moo: "",
                            // Soi: "",
                            // Road: "",
                            // Remark: "",
                            // ContactName: "",
                            // ContactPosition: "",
                            // TravelRemark: this.state[this.state.lang].TravelRemark,
                            // OpenHourRemark: "",
                            // MoreDetail: "",
                            // TravelMoreDetail: ""
                        }
                    },
                    // OpenHours: this.state.Submit.OpenHours,
                    // TravelTypes: this.state.Submit.Travel_types,
                    // Images: this.state.Submit.ImageUpload,
                },
                Accommodation: {
                    // Info: {
                    //     HasCost: "N",
                    //     CostForeignAdult: null,
                    //     CostForeignChild: null,
                    //     CostThaiAdult: null,
                    //     CostThaiChild: null,
                    //     LabelAmount: null

                    // },
                    // Types: this.state.Submit.Types === undefined ? [] : this.state.Submit.Types,
                    Details: {
                        [this.state.lang]: {
                            Lang: this.state.lang,
                            History: this.state[this.state.lang].History,
                            // OpenHourRemark: this.state[this.state.lang].OpenHourRemark,
                            // HighSeason: "",
                            Feature: this.state[this.state.lang].Feature,
                            // RewardOther: "",
                            // Keyword: "",
                            // KeywordRemark: null,
                            // CostRemark: "",
                            // UseTime: "",
                            // Detail: null,
                            ResponseDepartment: this.state[this.state.lang].ResponseDepartment,
                            AreaSize: this.state[this.state.lang].AreaSize,
                            ParkingDetail: this.state[this.state.lang].ParkingDetail,
                            // TargetRemark: null

                        }
                    },
                    // Activities: this.state.Submit.Attraction,
                    // Facilities: this.state.Submit.Facilities,
                    // Targets: this.state.Submit.Targets,
                    // Utilities: this.state.Submit.Utility,
                }

            }

        }

        const response = await fetch(
            api_endpoint + `/maininfo/approve`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (_result.status === "ok") {


            alert('อนุมัติแก้ไขที่พักสำเร็จ')

        }
        else {
            console.log('error : updateData')
        }
    }

    async reject() {
        console.log(Number(this.state.IDEditter))
        const userData = JSON.parse(localStorage.getItem('authUser'))
        let obj = {}
        if (this.state.lang === 'th') {
            obj = {
                ID: Number(this.state.IDEditter),
            }
            // if (obj.Main.Images === undefined) {
            //     delete obj.Main.Images
            // }
        }
        if (this.state.lang === 'en') {
            obj = {
                ID: Number(this.state.IDEditter),
            }
        }
        if (this.state.lang === 'zh') {
            obj = {
                ID: Number(this.state.IDEditter),
            }
        }
        obj['Feedback'] = this.state.Feedback

        const response = await fetch(
            api_endpoint + `/maininfo/reject`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + userData.token
                },
                body: JSON.stringify(obj),
            }
        );
        var _result = await response.json();
        console.log(_result)
        if (_result.status === "ok") {


            alert('แจ้งส่งกลับแก้ไขที่พักสำเร็จ')

        }
        else {
            console.log('error : reject')
        }
    }

    componentDidMount() {



        const search = window.location.search;
        const params = new URLSearchParams(search);
        const id = params.get('id');
        const status = params.get('status');
        if (id !== null) {
            console.log('in')
            this.setState({
                placeId: id,
                status: status,
                cloneId: id,
                isLoadingDetail: true
            }, () => {
                this.getAccommodationDetail(id)
            })

        }
        else {
            console.log('out')
            this.attraction_types()
        }




    }

    componentWillReceiveProps(nextProps) {

        // console.log(nextProps)
        if (nextProps.lang) {
            this.setState({
                lang: nextProps.lang
            }, () => {
                // console.log(this.state.lang)
            })
        }
    }



    render() {
        console.log(this.state.Images)
        console.log(this.state.isLoading)
        console.log(this.state.isLoadingDetail)

        if (this.state.isLoading === true || this.state.isLoadingDetail === true) {
            return (
                <div className="text-center" >
                    <FadeIn>
                        <div style={{ display: "flex", justifyContent: 'center' }}>

                            <div >
                                <Lottie options={defaultOptions} height={300} width={300} />
                                <div>
                                    <h4 style={{ color: 'black', padding: 30 }}>กำลังโหลด</h4>
                                </div>

                            </div>


                        </div>

                    </FadeIn>
                </div>
            )
        }
        else {
            const userData = JSON.parse(localStorage.getItem('authUser'))
            console.log(userData)
            console.log(this.state.status)
            return (

                <div>
                    <Form onSubmit={() => { this.test() }}>
                        <Card>
                            <CardBody>
                                <General callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Types={this.state.Types} registration_types={this.state.registration_types} accommodation_service_types={this.state.accommodation_service_types} cost_levels={this.state.cost_levels} AccommodationInfo={this.state.AccommodationInfo} submit={this.submitForm} />
                            </CardBody>
                        </Card>
                    </Form>
                    <Card>
                        <CardBody>
                            <Standard callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Types={this.state.Types} accommodation_standards={this.state.accommodation_standards} accommodation_green_leafs={this.state.accommodation_green_leafs} accommodation_thailand_tourism_standards={this.state.accommodation_thailand_tourism_standards} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Room callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} selectedHandle={this.selectedHandle} Types={this.state.Types} AccommodationInfo={this.state.AccommodationInfo} Details={this.state.Details} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Address callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} provinces={this.state.provinces} districts={this.state.districts} subdistricts={this.state.subdistricts} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Contact callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Picturesvideos callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Images={this.state.Images} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Target callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Target={this.state.Targets} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Facilities callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Facilities={this.state.Facilities} submit={this.submitForm} />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <Journey callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Travel_types={this.state.TravelTypeID} submit={this.submitForm} />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <More callback={this.callback} lang={this.state.lang} data={this.state[this.state.lang]} Attraction={this.state.Attraction} submit={this.submitForm} />
                        </CardBody>
                    </Card>
                    {this.state.status === 'Y' ?
                        (userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ?
                            <Card>
                                <CardBody>
                                    <Feedback callback={this.callback} lang={this.state.lang} disabled={true} data={this.state} submit={this.submitForm} />
                                </CardBody>
                            </Card>
                            :
                            <Card>
                                <CardBody>
                                    <Feedback callback={this.callback} lang={this.state.lang} disabled={false} data={this.state} submit={this.submitForm} />
                                </CardBody>
                            </Card>
                        :
                        <div />
                    }
                    <div>
                        <Card>
                            <CardBody>
                                {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.placeId === null ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                                {this.state.placeId !== null && this.state.status !== 'Y' && (userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                                {(userData.UserSubType === 'Admin' || userData.UserSubType === 'SuperAdmin') && this.state.status === 'Y' ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                                {this.state.placeId !== null && this.state.status !== 'Y' && userData.UserSubType !== 'Admin' && userData.UserSubType !== 'SuperAdmin' ? <div><Status lang={this.state.lang} callback={this.callback} IsEnabled={this.state.IsEnabled} submit={this.submitForm} /> <hr></hr></div> : <div></div>}
                                {/* < Status /> */}
                                {this.state.placeId === null ?
                                    <Row style={{ justifyContent: 'flex-end' }}>
                                        <div className="col-auto">
                                            <Button
                                                style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                                type="submit"
                                                onClick={() => { this.submitData() }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}

                                            </Button>
                                        </div>
                                        <div className="col-auto">
                                            <Link to="/Accommodation">
                                                <Button
                                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                                </Button>
                                            </Link>
                                        </div>
                                    </Row>
                                    :

                                    <div>

                                    </div>
                                }
                                {this.state.placeId !== null && this.state.status !== 'Y' ?
                                    <Row style={{ justifyContent: 'flex-end' }}>
                                        <div className="col-auto">
                                            <Button
                                                style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                                type="submit"
                                                onClick={() => { this.updateData() }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb">แก้ไข</span>{" "}
                                            </Button>
                                        </div>
                                        <div className="col-auto">
                                            <Link to="/Accommodation">
                                                <Button
                                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                                </Button>
                                            </Link>
                                        </div>
                                    </Row>
                                    :
                                    <div></div>
                                }
                                {this.state.status === 'Y' && userData.UserSubType === 'Admin' && userData.UserType === 'B' ?
                                    <Row style={{ justifyContent: 'flex-end' }}>
                                        <div className="col-auto">
                                            <Button
                                                style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                                type="submit"
                                                onClick={() => { this.Approve() }}

                                                className="chat-send w-md waves-effect waves-light"
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb"> อนุมัติการแก้ไข</span>{" "}
                                            </Button>
                                        </div>
                                        <div className="col-auto">
                                            <Button
                                                style={{ backgroundColor: '#F4D006', borderColor: '#F4D006' }}
                                                className="chat-send w-md waves-effect waves-light"
                                                onClick={() => { this.reject() }}
                                            >
                                                <span className="d-none d-sm-inline-block font-style-16nb">แจ้งส่งกลับแก้ไข</span>{" "}

                                            </Button>
                                        </div>
                                        <div className="col-auto">
                                            <Link to="/notifyinformation">
                                                <Button
                                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}

                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}

                                                </Button>
                                            </Link>

                                        </div>
                                    </Row>
                                    :
                                    <div>

                                    </div>
                                }
                                {this.state.status === 'Y' && userData.UserSubType === 'Editor' && userData.UserType === 'B' ?
                                    <div></div>
                                    :
                                    <div></div>

                                }
                            </CardBody>
                        </Card>
                    </div>




                    {/* <button onClick={() => { this.submitData() }}>Submit</button> */}

                </div>

            )
        }

    }
}
