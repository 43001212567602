import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
  TabPane,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal
} from "reactstrap"

import logoplus from "../../../../assets/images/ttd/Vector.svg"
import { Link } from "react-router-dom"
import DataGrid from '../component/Table'
import classnames from "classnames"

export default class Activity extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal_standard: false,
      customActiveTab: "1",
      idSelected: "",
      subActivity: [],
      Attraction: this.props.Attraction,
      AttractionActivityTypeID: "",
      AttractionActivitySubTypeID: "",
      ActivityMainName: "",
      SubActivityName: "",
      ActivityName: "",
      ActivityNameEN: "",
      ActivityNameZH: "",
      ActivityDetail: "",
      ActivityDetailEN: "",
      ActivityDetailZH: "",
      dataSubmit: this.props.Activities,
      reload: false,
      lang: this.props.lang
    }

    this.tog_standard = this.tog_standard.bind(this)
    this.toggleCustom = this.toggleCustom.bind(this)

  }
  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      })
    }
  }


  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
      AttractionActivityTypeID: "",
      AttractionActivitySubTypeID: "",
      ActivityName: "",
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  onChangeThai() {
    this.setState({
      Thai: true
    })
  }
  onChangeEnglish() {
    this.setState({
      English: true
    })
  }

  onChangeChina() {
    this.setState({
      China: true
    })
  }

  activity(id) {

    let arr = this.state.Attraction
    console.log(arr)
    let filter = arr.filter(item => item.ID === Number(id))
    if (filter[0].SubTypes !== undefined) {
      this.setState({
        subActivity: filter[0].SubTypes
      }, () => {
        console.log(this.state.subActivity)
      })
    }
    else {
      this.setState({
        subActivity: []
      })
    }



  }

  submitActivity() {
    if (this.state.ActivityName === "" || this.state.AttractionActivityTypeID === "" || this.state.AttractionActivitySubTypeID === "") {
      this.setState({
        reload: false
      }, () => {
        alert('กรุณากรอกข้อมูลให้ครบถ้วน')

      })
      return
    }
    let arr = [...this.state.dataSubmit]
    arr.push({
      id: arr.length + 1,
      ActivityName: this.state.ActivityName,
      AttractionActivityTypeID: Number(this.state.AttractionActivityTypeID),
      AttractionActivitySubTypeID: Number(this.state.AttractionActivitySubTypeID),
      ActivityMainName: this.state.ActivityMainName,
      SubActivityName: this.state.SubActivityName,
      Details: {
        th: {
          Lang: 'th',
          Name: this.state.ActivityName,
          Detail: this.state.ActivityDetail
        },
        en: {
          Lang: 'en',
          Name: this.state.ActivityNameEN,
          Detail: this.state.ActivityDetailEN
        },
        zh: {
          Lang: 'zh',
          Name: this.state.ActivityNameZH,
          Detail: this.state.ActivityDetailZH
        }

      }
    })
    this.setState({
      dataSubmit: arr,
      modal_standard: !this.state.modal_standard,

      reload: false
    }, () => {
      this.props.submit('Attraction', this.state.dataSubmit)

    })
  }

  componentDidMount() {
    this.props.submit('Attraction', this.state.dataSubmit)
  }

  render() {
    // console.log(this.props)
    return (
      <div>
        <Label className={'text-24'}>กิจกรรม</Label>
        {this.state.lang === 'th' ?
          <Row className={'text-16'} style={{ paddingBottom: 15 }}>
            <Col style={{ textAlign: 'end', alignSelf: 'center' }}>
              <button type="button" onClick={this.tog_standard} class="btn btn-primary" data-toggle="modal" data-target="#myModal" style={{ backgroundColor: '#173865', borderColor: '#173865' }}>
                <img src={logoplus} style={{ padding: '0px 10px 3px 0px', width: 30 }} /><span className="d-none d-sm-inline-block font-style-16nb">เพิ่มกิจกรรม</span>{" "}
              </button>
            </Col>
            <Modal
              isOpen={this.state.modal_standard}
              toggle={this.tog_standard}                    >

              <div className="modal-header">
                <Label className="modal-title mt-0 font-style-24 cl-black" id="myModalLabel">
                  ข้อมูลกิจกรรม
                </Label>
                <button
                  type="button"
                  onClick={() =>
                    this.setState({ modal_standard: false })
                  }
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Nav tabs className="nav-tabs-custom">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.customActiveTab === "1",
                      })}
                      onClick={() => {
                        this.toggleCustom("1")
                      }}
                    >
                      <span className="d-none d-sm-block font-style-16">ภาษาไทย</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.customActiveTab === "2",
                      })}
                      onClick={() => {
                        this.toggleCustom("2")
                      }}
                    >
                      <span className="d-none d-sm-block font-style-16">English</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.customActiveTab === "3",
                      })}
                      onClick={() => {
                        this.toggleCustom("3")
                      }}
                    >
                      <span className="d-none d-sm-block font-style-16">简体中文</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <TabContent activeTab={this.state.customActiveTab}>
                <TabPane tabId="1" className="p-3">
                  <FormGroup>
                    <Label className="font-style-16 cl-black" for="Address">ชื่อกิจกรรม</Label>
                    <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Address" placeholder="กรุณากรอกชื่อกิจกรรม" value={this.state.ActivityName} onChange={(e) => { this.setState({ ActivityName: e.target.value }, () => { }) }} />
                  </FormGroup>
                  <Label className="font-style-16 cl-black" for="Address">ประเภทกิจกรรม </Label><Label className="required"> *</Label>
                  <div className="form-group row">
                    <div className="col-md-12">
                      <select className="form-control font-style-16b4" onChange={(e) => {
                        let mainName = this.state.Attraction.filter(item => item.ID === Number(e.target.value))[0].Details[this.state.lang].Name
                        this.setState({
                          idSelected: e.target.value,
                          AttractionActivityTypeID: e.target.value,
                          ActivityMainName: mainName
                        }, () => {
                          this.activity(this.state.idSelected)
                        })
                      }}
                      >
                        {this.state.AttractionActivityTypeID === "" ? <option value={-1}>กรุณาเลือกประเภทกิจกรรมหลัก</option> : null}
                        {this.state.Attraction.map((e, i) => {
                          return (
                            <option key={i} value={e.ID}>{e.Details.th.Name}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <Label className="font-style-16 cl-black" for="Address">ประเภทกิจกรรมรอง </Label><Label className="required"> *</Label>
                  <div className="form-group row">
                    <div className="col-md-12">
                      <select className="form-control" onChange={(e) => {
                        let subName = this.state.subActivity.filter(item => item.ID === Number(e.target.value))[0].Details[this.state.lang].Name
                        this.setState({
                          AttractionActivitySubTypeID: e.target.value,
                          SubActivityName: subName
                        }, () => {
                          // this.subActivity(this.state.idSelected)
                        })
                      }}>
                        {this.state.AttractionActivityTypeID === "" && <option value={-1}>กรุณาเลือกประเภทกิจกรรมหลัก</option>}
                        {this.state.AttractionActivitySubTypeID === "" && <option value={-1}>กรุณาเลือกประเภทกิจกรรมรอง</option>
                        }
                        {this.state.subActivity.map((e, i) => {
                          return (
                            <option key={i} value={e.ID}>{e.Details.th.Name}</option>
                          )
                        })}

                      </select>
                    </div>
                  </div>
                  <Label className="font-style-16 cl-black">ลักษณะเด่น</Label>
                  <Input
                    style={{ fontSize: 16 }}
                    type="textarea"
                    id="textarea"
                    onChange={(e) => { this.setState({ ActivityDetail: e.target.value }) }}
                    // maxLength="225"
                    rows="3"
                    placeholder=""
                  />
                  {this.state.textareabadge ? (
                    <span className="badgecount badge badge-success">
                      {" "}
                      {this.state.textcount} / 225{" "}
                    </span>
                  ) : null}
                </TabPane>
                <TabPane tabId="2" className="p-3">
                  <FormGroup>
                    <Label className="font-style-16 cl-black" for="Address">ชื่อกิจกรรม</Label>
                    <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Address" placeholder="กรุณากรอกชื่อกิจกรรม" value={this.state.ActivityName} onChange={(e) => { this.setState({ ActivityName: e.target.value }, () => { }) }} />
                  </FormGroup>
                  <Label className="font-style-16 cl-black">ลักษณะเด่น</Label>
                  <Input
                    style={{ fontSize: 16 }}
                    type="textarea"
                    id="textarea"
                    onChange={(e) => { this.setState({ ActivityDetail: e.target.value }) }}
                    // maxLength="225"
                    rows="3"
                    placeholder=""
                  />
                  {this.state.textareabadge ? (
                    <span className="badgecount badge badge-success">
                      {" "}
                      {this.state.textcount} / 225{" "}
                    </span>
                  ) : null}
                </TabPane>
                <TabPane tabId="3" className="p-3">
                  <FormGroup>
                    <Label className="font-style-16 cl-black" for="Address">ชื่อกิจกรรม</Label>
                    <Input style={{ fontSize: 16 }} required type="text" className="form-control" id="Address" placeholder="กรุณากรอกชื่อกิจกรรม" value={this.state.ActivityName} onChange={(e) => { this.setState({ ActivityName: e.target.value }, () => { }) }} />
                  </FormGroup>
                  <Label className="font-style-16 cl-black">ลักษณะเด่น</Label>
                  <Input
                    style={{ fontSize: 16 }}
                    type="textarea"
                    id="textarea"
                    onChange={(e) => { this.setState({ ActivityDetail: e.target.value }) }}
                    // maxLength="225"
                    rows="3"
                    placeholder=""
                  />
                  {this.state.textareabadge ? (
                    <span className="badgecount badge badge-success">
                      {" "}
                      {this.state.textcount} / 225{" "}
                    </span>
                  ) : null}
                </TabPane>
              </TabContent>
              <div className="modal-footer">
                <Row style={{ justifyContent: 'flex-end' }}>
                  <div className="col-auto">
                    <Button
                      style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                      type="submit"
                      onClick={() => { this.setState({ reload: true }, () => { this.submitActivity() }) }}
                      className="chat-send w-md waves-effect waves-light"
                    >
                      <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}
                    </Button>
                  </div>
                  <div className="col-auto">
                    <Button
                      style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                      onClick={this.tog_standard}
                      className="chat-send w-md waves-effect waves-light"
                    >
                      <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}
                    </Button>
                  </div>
                </Row>
              </div>
            </Modal>
          </Row>
          :
          <div>
            <p className="font-style-16 cl-darkblue">
              (ให้จัดการข้อมูลที่ภาษาไทย)
            </p>
          </div>
        }

        {this.state.reload === false && this.state.lang === 'th' ?
          <DataGrid pageSize={5}
            data={[
              { field: 'id', headerName: 'ลำดับ', width: 200 },
              { field: 'ActivityName', headerName: 'ชื่อกิจกรรม', width: 200 },
              { field: 'ActivityMainName', headerName: 'หมวดหมู่', width: 200 },
              {
                field: 'SubActivityName', headerName: 'หมวดหมู่รอง', width: 200
              },
              {
                field: 'Operation', headerName: 'จัดการ', width: 200,
                renderCell: (params) => (
                  <strong>
                    <Link>
                      {/* <i className="mdi mdi-chevron-right ml-1"></i> */}
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        onClick={() => {
                          let arr = [...this.state.dataSubmit]
                          arr.splice(params.rowIndex, 1)
                          console.log(arr)
                          this.setState({
                            dataSubmit: arr
                          }, () => {
                            this.props.submit('Attraction', this.state.dataSubmit)
                          })
                        }}
                      >
                        ลบ
                      </Button>
                    </Link>
                  </strong>
                ),
              }]}
            dataSubmit={this.state.dataSubmit}
            checkboxSelection />
          :
          <div></div>
        }
      </div>
    )
  }
}
