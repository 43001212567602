import React, { Component } from 'react'
import {
    Button, Card, CardBody, CardSubtitle, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import pana from '../../../assets/images/ttd/pana.svg'
import DataGrid from '../../../components/Table/Table'
import MaterialTable from 'material-table';
import { Link } from "react-router-dom"
import logoplus from "../../../assets/images/ttd/Vector.svg"
import { api_endpoint } from '../../../global-config'
import Select from "react-select"
import Edit from '../../../assets/images/ttd/Edit.svg'
import FadeIn from "react-fade-in";
import * as loadingData from "../../../loadingttd.json";
import Lottie from "react-lottie";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export default class EditUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Thaipage: true,
            userData: JSON.parse(localStorage.getItem('authUser')),
            userId: this.props.location.state.userId,
            rowsPerPageOptions: [10, 25, 50],
            forms: {
                TitleName: "",
                FirstName: "",
                LastName: "",
                Email: "",
                Username: "",
                Password: "",
            },
            dataTable: [],
            isLoading: true
        }
        this.Thaipage = this.Thaipage.bind(this);
        console.log("userId", this.state.userId)
        this.getUserReadOne = this.getUserReadOne.bind(this)
    }

    Thaipage() {
        this.setState({
            Thaipage: true,
        })
    }
    updateHandler(e, field) {
        const value = e.target.value;
        console.log("field", field)
        this.setState(prevState => ({
            forms: {
                ...prevState.forms,
                [field]: value,
            }
        }), () => {
            console.log("forms", this.state.forms)
        });
    }
    async getUserReadOne() {
        try {
            const response = await fetch(
                api_endpoint + '/users/readone', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + this.state.userData.token
                },
                body: JSON.stringify({
                    ID: this.state.userId
                })
            }
            );

            if (!response.ok) {
                throw new Error('ไม่สามารถดึงข้อมูลได้');
            }

            const _result = await response.json();
            console.log("result", _result);
            this.setState((prevState) => ({
                forms: {
                    ...prevState.forms, // คัดลอกค่าเดิม
                    TitleName: _result.user.TitleName,
                    Username: _result.user.Username,
                    FirstName: _result.user.FirstName !== "" ? _result.user.FirstName : "",
                    LastName: _result.user.LastName !== "" ? _result.user.LastName : "",
                    Email: _result.user.Email,
                }
            }), () => {
                // console.log("forms", this.state.forms)
            });
            if (_result.user !== undefined) {
                const arr = _result.user.ViewsDetail.map((e) => {
                    // console.log("eee", e);
                    return {
                        id: e.ID,
                        IntroImage: e.IntroImage,
                        Name: e.Name,
                        MainType: e.MainType,
                        View: e.View + " ครั้ง"
                    };
                });
                this.setState({
                    dataTable: arr,
                    isLoading: false
                }, () => {
                    // console.log("dataTable", this.state.dataTable);
                });
            }
        } catch (error) {
            console.error('เกิดข้อผิดพลาดในการดึงข้อมูล:', error.message);
        }
    }

    async updateSubmit() {
        const url = api_endpoint + "/user/update";

        let body = {
            Username: this.state.forms.Username,
            UserType: "E",
            UserSubType: null,
            UserGroupID: null,
            DepartmentID: null,
            TitleName: this.state.forms.TitleName,
            FirstName: this.state.forms.FirstName,
            LastName: this.state.forms.LastName,
            Email: this.state.forms.Email,
        };

        if (this.state.forms.Password !== "") {
            body.Password = this.state.forms.Password;
        }

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'bearer ' + this.state.userData.token
            },
            body: JSON.stringify(body)
        })
        const _result = await response.json();
        if (_result.status === 'ok' && _result.success === true) {
            alert("แก้ไขสำเร็จแล้ว");
            window.location.href = '/ManageUserRole';
        } else {
            console.log('error : update failed!')
        }
    }
    componentDidMount() {
        this.getUserReadOne();
    }

    render() {
        if (this.state.isLoading === true) {
            return (
                <>
                    <div className="page-content">
                        <Container fluid={true}>
                            <Breadcrumbs title="หน้าแรก" link0="/dashboard-blog" breadcrumbItem="รายการจัดการสมาชิก (ภายนอก)" link1="/ManageUserRole" breadcrumbItems="แก้ไขสมาชิก" />
                            <Card>
                                <CardBody>
                                    <Row style={{ padding: 15 }}>
                                        <div style={{ alignSelf: 'center', width: '70%' }}>
                                            <h1 className="text-24" style={{ color: '#173865' }}>รายละเอียดสมาชิก</h1>
                                            <CardSubtitle className="text-16">กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนดำเนินการต่อไป </CardSubtitle>
                                        </div>
                                        <div style={{ textAlign: 'center', width: '30%' }}>
                                            <img style={{ width: '10vw', height: 'auto' }} src={pana}></img>
                                        </div>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col lg={4} >
                                            <div style={{ textAlign: 'center' }}>
                                                <h3 className="text-18" style={{ color: this.state.Thaipage == true ? '#173865' : '#808080' }} onClick={() => { this.Thaipage() }}>ภาษาไทย</h3>
                                                {this.state.Thaipage == true && <div class="hr-line"></div>}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <div className="text-center" >
                                <FadeIn>
                                    <div style={{ display: "flex", justifyContent: 'center' }}>
                                        <div >
                                            <Lottie options={defaultOptions} height={300} width={300} />
                                            <div>
                                                <h4 style={{ color: 'black', padding: 30 }}>กำลังโหลด</h4>
                                            </div>
                                        </div>
                                    </div>
                                </FadeIn>
                            </div>
                        </Container>
                    </div>
                </>

            )
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="หน้าแรก" link0="/dashboard-blog" breadcrumbItem="รายการจัดการสมาชิก (ภายนอก)" link1="/ManageUserRole" breadcrumbItems="แก้ไขสมาชิก" />
                        <Card>
                            <CardBody>
                                <Row style={{ padding: 15 }}>
                                    <div style={{ alignSelf: 'center', width: '70%' }}>
                                        <h1 className="text-24" style={{ color: '#173865' }}>รายละเอียดสมาชิก</h1>
                                        <CardSubtitle className="text-16">กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนดำเนินการต่อไป </CardSubtitle>
                                    </div>
                                    <div style={{ textAlign: 'center', width: '30%' }}>
                                        <img style={{ width: '10vw', height: 'auto' }} src={pana}></img>
                                    </div>
                                </Row>
                                <hr></hr>
                                <Row>
                                    <Col lg={4} >
                                        <div style={{ textAlign: 'center' }}>
                                            <h3 className="text-18" style={{ color: this.state.Thaipage == true ? '#173865' : '#808080' }} onClick={() => { this.Thaipage() }}>ภาษาไทย</h3>
                                            {this.state.Thaipage == true && <div class="hr-line"></div>}
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <p className={'text-24'}>ข้อมูลทั่วไป</p>
                                        <Row className={'mt-3'}>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <Label for="TitleName" className="font-style-16 cl-black">คำนำหน้า </Label><Label className="required"> *</Label>
                                                    <div className="form-group row">
                                                        <div className="col-md-12">
                                                            <select className="form-control" value={this.state.forms.TitleName} onChange={(e) => this.updateHandler(e, "TitleName")}>
                                                                <option value="">กรุณาเลือกคำนำหน้า</option>
                                                                <option value="นาย">นาย</option>
                                                                <option value="นาง">นาง</option>
                                                                <option value="นางสาว">นางสาว</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <Label for="Username" className="font-style-16 cl-black">ชื่อ </Label><Label className="required"> *</Label>
                                                    <Input
                                                        style={{ fontSize: 16 }}
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        id="Username"
                                                        placeholder="กรุณากรอกชื่อผู้ใช้งาน"
                                                        value={this.state.forms.FirstName}
                                                        onChange={(e) => this.updateHandler(e, "FirstName")}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <Label for="Username" className="font-style-16 cl-black">นามสกุล </Label><Label className="required"> *</Label>
                                                    <Input
                                                        style={{ fontSize: 16 }}
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        id="Username"
                                                        placeholder="กรุณากรอกชื่อผู้ใช้งาน"
                                                        value={this.state.forms.LastName}
                                                        onChange={(e) => this.updateHandler(e, "LastName")}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="Username" className="font-style-16 cl-black">ชื่อผู้ใช้งาน </Label><Label className="required"> *</Label>
                                                    <Input
                                                        style={{ fontSize: 16, backgroundColor: "#f0f0f0" }}
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        id="Username"
                                                        placeholder="กรุณากรอกชื่อผู้ใช้งาน"
                                                        value={this.state.forms.Username}
                                                        onChange={(e) => this.updateHandler(e, "Username")}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="Email" className="font-style-16 cl-black">อีเมล </Label><Label className="required"> *</Label>
                                                    <Input
                                                        style={{ fontSize: 16, backgroundColor: "#f0f0f0" }}
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        id="Email"
                                                        placeholder="กรุณากรอกอีเมล"
                                                        value={this.state.forms.Email}
                                                        onChange={(e) => this.updateHandler(e, "Email")}
                                                        readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="Password" className="font-style-16 cl-black">รหัสผ่าน </Label><Label className="required"> *สำหรับต้องการเปลี่ยนรหัสผ่าน</Label>
                                                    <Input
                                                        style={{ fontSize: 16, }}
                                                        required
                                                        type="password"
                                                        className="form-control"
                                                        id="Password"
                                                        placeholder="กรุณากรอกรหัสผ่าน"
                                                        value={this.state.forms.Password}
                                                        onChange={(e) => this.updateHandler(e, "Password")}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ justifyContent: 'flex-end' }}>

                                            <div className="col-auto" style={{ marginRight: -12 }}>
                                                <Button
                                                    style={{ backgroundColor: '#41AB00', borderColor: '#41AB00' }}
                                                    type="submit"
                                                    onClick={() => { this.updateSubmit() }}
                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb">บันทึก</span>{" "}
                                                </Button>
                                            </div>
                                            <div className="col-auto">
                                                <Button
                                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                                                    type="submit"
                                                    onClick={() => window.location.href = "/ManageUserRole"}
                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <p className={'text-24'} style={{ color: '#173865' }}>ประวัติการใช้งาน</p>
                                        <Row>
                                            <Col lg={12}>
                                                <Row>
                                                    <DataGrid
                                                        header={
                                                            [
                                                                { field: 'id', headerName: 'ลำดับ', width: 120 },
                                                                {
                                                                    field: 'IntroImage', headerName: 'รูปภาพ', width: 120,
                                                                    renderCell: (params) => (
                                                                        <img src={params.value} alt="รูปภาพ" style={{ width: 100, height: 100 }} />
                                                                    ),
                                                                },
                                                                { field: 'Name', headerName: 'ชื่อสถานที่', flex: 1 },
                                                                { field: 'MainType', headerName: 'ประเภทข้อมูล', flex: 1 },
                                                                { field: 'View', headerName: 'การเข้าดูใช้งาน', flex: 1 },
                                                            ]
                                                        }
                                                        data={this.state.dataTable}
                                                        rowsCount={this.state.countData}
                                                        pageSize={this.state.pageSize}
                                                        pagging={this.dataGridPagging}
                                                        rowsPerPageOptions={this.state.rowsPerPageOptions}
                                                        loading={this.state.isLoading}
                                                        setPageSize={this.setPageSize}
                                                    />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <p className={'text-24'} style={{ color: '#173865' }}>สถานะข้อมูล</p>
                                        <Row>
                                            <Col lg={2}>
                                                <Label className="font-style-16 cl-black" for="Name">สถานะข้อมูล </Label><Label className="required"> *</Label>
                                            </Col>
                                            <Col lg={10}>
                                                <Row>
                                                    <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}>
                                                        <input
                                                            type="radio"
                                                            id="IsEnableY"
                                                            name="IsEnableY"
                                                            className="custom-control-input"
                                                            checked={true}

                                                        />
                                                        <label style={{ paddingLeft: 10 }}
                                                            className="custom-control-label font-style-16 cl-black"
                                                            htmlFor="customRadio4"
                                                        >
                                                            เปิดใช้งาน
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}>
                                                        <input
                                                            type="radio"
                                                            id="IsEnableN"
                                                            name="IsEnableN"
                                                            className="custom-control-input"
                                                            checked={false}
                                                        />
                                                        <label style={{ paddingLeft: 10 }}
                                                            className="custom-control-label font-style-16 cl-black"
                                                            htmlFor="customRadio5"
                                                        >
                                                            ระงับการใช้งาน
                                                        </label>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <Row style={{ justifyContent: 'flex-end' }}>
                                            <div className="col-auto">
                                                <Button
                                                    style={{ backgroundColor: '#DC3545', borderColor: '#DC3545' }}
                                                    type="submit"
                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb">ลบข้อมูล</span>{" "}

                                                </Button>
                                            </div>
                                            <div className="col-auto">
                                                <Button
                                                    style={{ backgroundColor: '#F4D006', borderColor: '#F4D006' }}
                                                    type="submit"
                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb" >แก้ไขข้อมูล</span>{" "}
                                                </Button>
                                            </div>
                                            <div className="col-auto">
                                                <Button
                                                    style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                                                    type="submit"
                                                    className="chat-send w-md waves-effect waves-light"
                                                >
                                                    <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col> */}
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}
