import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"

class Line extends Component {
  getOption = () => {
    return {
      tooltip: {
        trigger: "axis",
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { readOnly: false },
          // magicType: { type: ['line', 'bar'] },
          // restore: {},
          saveAsImage: {}
        }
      },
      grid: {
        zlevel: 0,
        x: 50,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
      },
      xAxis: {
        type: "category",
        data: ['แหล่งท่องเที่ยว', 'ร้านอาหาร', 'สปา', 'ร้านค้า', 'ผู้ประกอบธุรกิจนำเที่ยว', 'กิจกรรมท่องเที่ยว', 'ที่พัก'],
        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLable: {
          color: "#ffffff",
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      series: [
        {
          data: [620, 832, 750, 934, 1290, 1330, 1400],
          type: "line",
        },
      ],
      color: ["#556ee6"],
      textStyle: {
        color: ["#74788d"],
      },
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "450px" }} option={this.getOption()} />
      </React.Fragment>
    )
  }
}
export default Line
