import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
  TabPane,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,

} from "reactstrap"

export default class ActivityStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Nonestatus: false,
      status: false,
      ActivityStatus: this.props.ActivityStatus
    }
    console.log(this.props)
    console.log(this.state.ActivityStatus)
    console.log(this.props.userData)
  }
  selectedHandle(data, type) {
    console.log(data)

    if (type === 'ActivityStatus') {
      console.log(data)
      this.props.callback(data, 'ActivityStatus')
      // this.props.callback(JSON.parse(data).Code, 'Province')
      return
    }
  }



  render() {
    return (
      <div>
        <Row>
          <Col lg={12}>
            <Label className={'text-24'}>สถานะของกิจกรรม</Label>
          </Col>
        </Row>
        {this.props.lang === 'th' ?
          <Row className={'mt-3'}>
            <Col lg={2}>
              <Label className="font-style-16 cl-black" for="Name">สถานะของกิจกรรม </Label><Label className="required"> *</Label>
            </Col>
            <Col lg={2}>
              <div className="custom-control custom-radio mb-3">
                <input
                  type="radio"
                  className="custom-control-input"
                  id={'ActivityStatus'}
                  onChange={() => false}
                  checked={this.props.ActivityStatus === "EventScheduled" ? true : false}
                />
                <label
                  className="custom-control-label"
                  onClick={() => {
                    this.selectedHandle("EventScheduled", 'ActivityStatus')
                  }}
                >
                  <Label className="font-style-16 cl-black">กิจกรรมเกิดขึ้นตามกำหนดการ</Label>
                </label>
              </div>
            </Col>
            <Col lg={2}>
              <div className="custom-control custom-radio mb-3">
                <input
                  type="radio"
                  className="custom-control-input"
                  id={'ActivityStatus'}
                  onChange={() => false}
                  checked={this.props.ActivityStatus === "EventCancelled" ? true : false}
                />
                <label
                  className="custom-control-label"
                  onClick={() => {
                    this.selectedHandle("EventCancelled", 'ActivityStatus')
                  }}
                >
                  <Label className="font-style-16 cl-black">กิจกรรมยกเลิก</Label>
                </label>
              </div>
            </Col>
            <Col lg={2}>
              <div className="custom-control custom-radio mb-3">
                <input
                  type="radio"
                  className="custom-control-input"
                  id={'ActivityStatus'}
                  onChange={() => false}
                  checked={this.props.ActivityStatus === "EventPostponed" ? true : false}
                />
                <label
                  className="custom-control-label"
                  onClick={() => {
                    this.selectedHandle("EventPostponed", 'ActivityStatus')
                  }}
                >
                  <Label className="font-style-16 cl-black">กิจกรรมเลื่อนออกไป และไม่มีกำหนดวันใหม่</Label>
                </label>
              </div>
            </Col>
            <Col lg={2}>
              <div className="custom-control custom-radio mb-3">
                <input
                  type="radio"
                  className="custom-control-input"
                  id={'ActivityStatus'}
                  onChange={() => false}
                  checked={this.props.ActivityStatus === "EventRescheduled" ? true : false}
                />
                <label
                  className="custom-control-label"
                  onClick={() => {
                    this.selectedHandle("EventRescheduled", 'ActivityStatus')
                  }}
                >
                  <Label className="font-style-16 cl-black">กิจกรรมเลื่อนออกไป และมีกำหนดวันใหม่</Label>
                </label>
              </div>
            </Col>
            <Col lg={2}>
              <div className="custom-control custom-radio mb-3">
                <input
                  type="radio"
                  className="custom-control-input"
                  id={'ActivityStatus'}
                  onChange={() => false}
                  checked={this.props.ActivityStatus === "EventMovedOnline" ? true : false}
                />
                <label
                  className="custom-control-label"
                  onClick={() => {
                    this.selectedHandle("EventMovedOnline", 'ActivityStatus')
                  }}
                >
                  <Label className="font-style-16 cl-black">กิจกรรมเปลี่ยนเป็นให้เข้าร่วมออนไลน์</Label>
                </label>
              </div>
            </Col>
          </Row>
          :
          <div>
            <p className="font-style-16 cl-darkblue">
              (ให้จัดการข้อมูลที่ภาษาไทย)
            </p>
          </div>
        }

      </div >
    )
  }
}
