import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import FormUploadVideo from "../../component/component/FormUploadVideo"

import FormUpload from "../../component/component/FormUpload"

export default class Picturesvideos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Noneuplode: true,
            Upload: false,
            youtube: false,
        }
        console.log(props)
    }

    render() {
        return (
            <div>
                {this.props.lang === 'th' ?
                    <Row>
                        <Col lg="12">
                            <Label className={'text-24'}>รูปภาพและวิดีโอ</Label>
                            <div>
                                <FormUpload Images={this.props.Images} submit={this.props.submit} />
                                <Label className={'mt-3 font-style-16 cl-black'}>วิดีโอแนะนำ</Label>
                                <Row className="font-style-16 cl-black">
                                    <Col lg={2}>
                                        <div className="custom-control custom-checkbox mb-3">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="CustomCheck1"
                                                onChange={() => true}
                                                checked={this.state.Noneuplode && this.props.data.VdoYoutubeUrl === ""}
                                            />
                                            <label
                                                style={{ lineHeight: '1.3rem' }}
                                                className="custom-control-label"
                                                onClick={() => {
                                                    this.setState({
                                                        Noneuplode: !this.state.Noneuplode,
                                                        Upload: false,
                                                        youtube: false
                                                    })
                                                }}
                                            >
                                                ไม่มี
                                            </label>
                                        </div>
                                    </Col>
                                    {/* <Col lg={2}>
                                    <div className="custom-control custom-checkbox mb-3">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="CustomCheck1"
                                            onChange={() => false}
                                            checked={this.state.Upload}
                                        />
                                        <label
                                            className="custom-control-label"
                                            onClick={() => {
                                                this.setState({
                                                    Upload: !this.state.Upload,
                                                    Noneuplode: false,
                                                    youtube: false,
                                                })
                                            }}
                                        >
                                            Upload
                                        </label>
                                    </div>
                                </Col> */}
                                    <Col lg={2}>
                                        <div className="custom-control custom-checkbox mb-3">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="CustomCheck1"
                                                onChange={() => false}
                                                checked={this.state.youtube || this.props.data.VdoYoutubeUrl !== ""}
                                            />
                                            <label
                                                style={{ lineHeight: '1.3rem' }}
                                                className="custom-control-label"
                                                onClick={() => {
                                                    this.setState({
                                                        youtube: !this.state.youtube,
                                                        Noneuplode: false,
                                                        Upload: false,
                                                    })
                                                }}
                                            >
                                                Youtube
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {this.state.Upload == true
                                ?
                                <div>
                                    <FormUploadVideo />
                                </div>
                                :
                                <div></div>
                            }
                            {this.props.data.VdoYoutubeUrl !== "" || this.state.youtube == true ?
                                <div>
                                    <FormGroup>
                                        <Label className="font-style-16 cl-black" for="agencyName">Link Video</Label>
                                        <Input style={{ fontSize: 16 }} ref="searchURL" placeholder="Enter URL..." value={this.props.data.VdoYoutubeUrl} onChange={(e) => { this.props.callback(e.target.value, 'VdoYoutubeUrl') }} />
                                    </FormGroup>
                                </div>
                                :
                                <div></div>
                            }
                        </Col>
                    </Row>
                    :
                    <div>
                        <Label className={'text-24'}>รูปภาพและวิดีโอ</Label>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }

            </div>
        )
    }
}
