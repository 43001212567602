import React, { Component } from 'react'
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
  TabPane,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,

} from "reactstrap"
import MapsGoogle from "../../../../components/Map/Map";
export default class Facilities extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Lang: 'th',
      Facilities: this.props.Facilities,
      radioValue: false,
    }
    // console.log(props)
  }
  // เมื่อเกิดการเปลี่ยนแปลงค่าของ radio buttons
  handleRadioChange = (event) => {
    this.setState({
      radioValue: event.target.value === 'true',
    });
  };
  componentDidMount() {
    let filter = this.state.Facilities.filter(item => item.isCheck === true)
    let addFacilities = []
    filter.forEach(element => {
      addFacilities.push({
        AttractionFacilityID: element.ID,
        HasFacility: "Y",
        Quantity: element.Quantity,
        Total: element.Total
      })
    });
    console.log(this.state.Facilities)
    this.props.submit('Facilities', addFacilities)
  }


  render() {
    console.log(this.state.Facilities)
    const { radioValue } = this.state;
    return (
      <div>
        <p className={'text-24'}>สิ่งอำนวยความสะดวก</p>
        {this.props.lang === 'th' &&
          <div className="font-style-16 cl-black">
            <Label className={'mt-3'}>ข้อมูลที่พักในแหล่งท่องเที่ยว</Label>
            {this.state.Facilities.map((e, i) => {
              // console.log(e)
              if (e.AttractionFacilityTypeID === 2) {
                return (
                  <div key={i}>
                    <Row className={'mt-3'}>
                      <Col lg={2}>
                        {e.Details[this.state.Lang].Name}
                      </Col>
                      <Col lg={10}>
                        <Row>
                          <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                            onClick={() => {
                              console.log(this.state.Facilities[i])
                              let arr = this.state.Facilities
                              arr[i].isCheck = false
                              this.setState({
                                Facilities: arr
                              }, () => {
                                let filter = this.state.Facilities.filter(item => item.isCheck === true)
                                let addFacilities = []
                                filter.forEach(element => {
                                  addFacilities.push({
                                    AttractionFacilityID: element.ID,
                                    HasFacility: "Y",
                                    Quantity: element.Quantity
                                  })
                                });

                                this.props.submit('Facilities', addFacilities)
                              })
                            }}>
                            <input
                              type="radio"
                              id={i}
                              className="custom-control-input"

                              checked={e.isCheck === true ? false : true}
                            />
                            <label style={{ paddingLeft: 10 }}
                              className="custom-control-label"
                            // htmlFor="customRadio4"
                            >
                              ไม่มี
                            </label>
                          </div>
                          <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                            onClick={() => {
                              let arr = this.state.Facilities
                              arr[i].isCheck = true
                              this.setState({
                                Facilities: arr
                              }, () => {
                                let filter = this.state.Facilities.filter(item => item.isCheck === true)
                                let addFacilities = []
                                filter.forEach(element => {
                                  addFacilities.push({
                                    AttractionFacilityID: element.ID,
                                    HasFacility: "Y",
                                    // Total: element.total,
                                    Quantity: element.Quantity
                                  })
                                });
                                this.props.submit('Facilities', addFacilities)
                              })
                            }} >
                            <input
                              type="radio"

                              className="custom-control-input"
                              checked={
                                e.isCheck === true ? true : false}
                              id={i}
                            />
                            <label style={{ paddingLeft: 10 }}
                              className="custom-control-label"
                            // htmlFor="customRadio5"
                            >
                              มี
                            </label>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    {e.isCheck === true
                      ?
                      <div>
                        <blockquote className="p-3 border-dashed rounded mb-3 mt-3">
                          <Row className="mt-3">
                            <Col lg={5}>
                              <FormGroup>
                                <Label for="Name">รองรับจำนวน {e.Details[this.state.Lang].Name}</Label>
                                <Input style={{ fontSize: 16 }} required type="number" className="form-control" id="Name" placeholder="" value={e.Quantity} onChange={(e) => {
                                  let arr = this.state.Facilities
                                  arr[i].Quantity = e.target.value
                                  this.setState(
                                    {
                                      Facilities: arr
                                    }, () => {
                                      let filter = this.state.Facilities.filter(item => item.isCheck === true)
                                      let addFacilities = []
                                      filter.forEach(element => {
                                        addFacilities.push({
                                          AttractionFacilityID: element.ID,
                                          HasFacility: "Y",
                                          // Total: element.total,
                                          Quantity: element.Quantity,
                                          Latitude: element.Latitude,
                                          Longitude: element.Longitude
                                        })
                                      });
                                      this.props.submit('Facilities', addFacilities)
                                    }
                                  )
                                }} />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col lg={5}>
                              <FormGroup>
                                <Label for="Name">Latitude</Label>
                                <Input style={{ fontSize: 16 }} required type="number" className="form-control" id="Name" placeholder="" value={e.Latitude} onChange={(e) => {
                                  let arr = this.state.Facilities
                                  arr[i].Latitude = e.target.value
                                  this.setState(
                                    {
                                      Facilities: arr
                                    }, () => {
                                      let filter = this.state.Facilities.filter(item => item.isCheck === true)
                                      let addFacilities = []
                                      filter.forEach(element => {
                                        addFacilities.push({
                                          AttractionFacilityID: element.ID,
                                          HasFacility: "Y",
                                          // Total: element.total,
                                          Quantity: element.Quantity,
                                          Latitude: element.Latitude,
                                          Longitude: element.Longitude
                                        })
                                      });
                                      this.props.submit('Facilities', addFacilities)
                                    }
                                  )
                                }} />
                              </FormGroup>
                            </Col>
                            <Col lg={5}>
                              <FormGroup>
                                <Label for="Name">Longitude</Label>
                                <Input style={{ fontSize: 16 }} required type="number" className="form-control" id="Name" placeholder="" value={e.Longitude} onChange={(e) => {
                                  let arr = this.state.Facilities
                                  arr[i].Longitude = e.target.value
                                  this.setState(
                                    {
                                      Facilities: arr
                                    }, () => {
                                      let filter = this.state.Facilities.filter(item => item.isCheck === true)
                                      let addFacilities = []
                                      filter.forEach(element => {
                                        addFacilities.push({
                                          AttractionFacilityID: element.ID,
                                          HasFacility: "Y",
                                          // Total: element.total,
                                          Quantity: element.Quantity,
                                          Latitude: element.Latitude,
                                          Longitude: element.Longitude
                                        })
                                        console.log(element)
                                      });
                                      this.props.submit('Facilities', addFacilities)
                                    }
                                  )
                                }} />
                              </FormGroup>
                            </Col>
                          </Row>
                          { this.state.Facilities[i].Latitude && this.state.Facilities[i].Longitude ?
                            <Row className='pl-2'>
                              <a 
                                href={"https://www.google.com/maps?q="+this.state.Facilities[i].Latitude+","+this.state.Facilities[i].Longitude} 
                                target="_blank"
                              >
                                Preview Map
                              </a>
                            </Row>
                          :
                            null
                          }
                        </blockquote>
                      </div>
                      :
                      <div></div>
                    }
                  </div>
                )
              }
              else {
                return null
              }
            })}
            <hr></hr>
          </div>
        }
        {
          this.props.lang === 'th' &&
          <div className="font-style-16 cl-black">
            <Label className={'mt-3'}>ข้อมูลสิ่งอำนวยความสะดวก</Label>
            {this.state.Facilities.map((e, i) => {
              // console.log(e)
              if (e.AttractionFacilityTypeID === 1) {
                return (
                  <div key={i}>
                    <Row className={'mt-3'}>
                      <Col lg={2}>
                        {e.Details[this.state.Lang].Name}
                      </Col>
                      <Col lg={10}>
                        <Row>
                          <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                            onClick={() => {
                              console.log(this.state.Facilities[i])
                              let arr = this.state.Facilities
                              arr[i].isCheck = false
                              this.setState({
                                Facilities: arr
                              }, () => {
                                let filter = this.state.Facilities.filter(item => item.isCheck === true)
                                let addFacilities = []
                                filter.forEach(element => {
                                  addFacilities.push({
                                    AttractionFacilityID: element.ID,
                                    HasFacility: "Y",
                                    Quantity: element.Quantity
                                  })
                                });

                                this.props.submit('Facilities', addFacilities)
                              })
                            }}>
                            <input
                              type="radio"
                              id={i}
                              className="custom-control-input"

                              checked={e.isCheck === true ? false : true}
                            />
                            <label style={{ paddingLeft: 10 }}
                              className="custom-control-label"
                            // htmlFor="customRadio4"
                            >
                              ไม่มี
                            </label>
                          </div>
                          <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                            onClick={() => {
                              let arr = this.state.Facilities
                              arr[i].isCheck = true
                              this.setState({
                                Facilities: arr
                              }, () => {
                                let filter = this.state.Facilities.filter(item => item.isCheck === true)
                                let addFacilities = []
                                filter.forEach(element => {
                                  addFacilities.push({
                                    AttractionFacilityID: element.ID,
                                    HasFacility: "Y",
                                    // Total: element.total,
                                    Quantity: element.Quantity
                                  })
                                });
                                this.props.submit('Facilities', addFacilities)
                              })
                            }} >
                            <input
                              type="radio"

                              className="custom-control-input"
                              checked={
                                e.isCheck === true ? true : false}
                              id={i}
                            />
                            <label style={{ paddingLeft: 10 }}
                              className="custom-control-label"
                            // htmlFor="customRadio5"
                            >
                              มี
                            </label>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    {e.isCheck === true
                      ?
                      <div>
                        <blockquote className="p-3 border-dashed rounded mb-3 mt-3">
                          <Row className="mt-3">
                            <Col lg={5}>
                              <FormGroup>
                                <Label for="Name">รองรับจำนวน {e.Details[this.state.Lang].Name}</Label>
                                <Input style={{ fontSize: 16 }} required type="number" className="form-control" id="Name" placeholder="" value={e.Quantity} onChange={(e) => {
                                  let arr = this.state.Facilities
                                  arr[i].Quantity = e.target.value
                                  this.setState(
                                    {
                                      Facilities: arr
                                    }, () => {
                                      let filter = this.state.Facilities.filter(item => item.isCheck === true)
                                      let addFacilities = []
                                      filter.forEach(element => {
                                        addFacilities.push({
                                          AttractionFacilityID: element.ID,
                                          HasFacility: "Y",
                                          // Total: element.total,
                                          Quantity: element.Quantity,
                                          Latitude: element.Latitude,
                                          Longitude: element.Longitude
                                        })
                                      });
                                      this.props.submit('Facilities', addFacilities)
                                    }
                                  )
                                }} />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col lg={5}>
                              <FormGroup>
                                <Label for="Name">Latitude</Label>
                                <Input style={{ fontSize: 16 }} required type="number" className="form-control" id="Name" placeholder="" value={e.Latitude} onChange={(e) => {
                                  let arr = this.state.Facilities
                                  arr[i].Latitude = e.target.value
                                  this.setState(
                                    {
                                      Facilities: arr
                                    }, () => {
                                      let filter = this.state.Facilities.filter(item => item.isCheck === true)
                                      let addFacilities = []
                                      filter.forEach(element => {
                                        addFacilities.push({
                                          AttractionFacilityID: element.ID,
                                          HasFacility: "Y",
                                          // Total: element.total,
                                          Quantity: element.Quantity,
                                          Latitude: element.Latitude,
                                          Longitude: element.Longitude
                                        })
                                      });
                                      this.props.submit('Facilities', addFacilities)
                                    }
                                  )
                                }} />
                              </FormGroup>
                            </Col>
                            <Col lg={5}>
                              <FormGroup>
                                <Label for="Name">Longitude</Label>
                                <Input style={{ fontSize: 16 }} required type="number" className="form-control" id="Name" placeholder="" value={e.Longitude} onChange={(e) => {
                                  let arr = this.state.Facilities
                                  arr[i].Longitude = e.target.value
                                  this.setState(
                                    {
                                      Facilities: arr
                                    }, () => {
                                      let filter = this.state.Facilities.filter(item => item.isCheck === true)
                                      let addFacilities = []
                                      filter.forEach(element => {
                                        addFacilities.push({
                                          AttractionFacilityID: element.ID,
                                          HasFacility: "Y",
                                          // Total: element.total,
                                          Quantity: element.Quantity,
                                          Latitude: element.Latitude,
                                          Longitude: element.Longitude
                                        })
                                        console.log(element)
                                      });
                                      this.props.submit('Facilities', addFacilities)
                                    }
                                  )
                                }} />
                              </FormGroup>
                            </Col>
                          </Row>
                          { this.state.Facilities[i].Latitude && this.state.Facilities[i].Longitude ?
                            <Row className='pl-2'>
                              <a 
                                href={"https://www.google.com/maps?q="+this.state.Facilities[i].Latitude+","+this.state.Facilities[i].Longitude} 
                                target="_blank"
                              >
                                Preview Map
                              </a>
                            </Row>
                          :
                            null
                          }
                        </blockquote>
                      </div>
                      :
                      <div></div>
                    }
                  </div>
                )
              }
              else {
                return null
              }
            })}
            <hr></hr>
          </div>
        }
        {
          this.props.lang === 'th' &&
          <div className="font-style-16 cl-black">
            <Label className={'mt-3'}>ข้อมูลสิ่งอำนวยความสะดวกสำหรับผู้พิการ/ผู้สูงอายุ</Label>
            {this.state.Facilities.map((e, i) => {
              if (e.AttractionFacilityTypeID === 3) {
                return (
                  <div key={i}>
                    <Row className={'mt-3'}>
                      <Col lg={2}>
                        {e.Details[this.state.Lang].Name}
                      </Col>
                      <Col lg={10}>
                        <Row>
                          <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                            onClick={() => {
                              console.log(this.state.Facilities[i])
                              let arr = this.state.Facilities
                              arr[i].isCheck = false
                              this.setState({
                                Facilities: arr
                              }, () => {
                                let filter = this.state.Facilities.filter(item => item.isCheck === true)
                                let addFacilities = []
                                filter.forEach(element => {
                                  addFacilities.push({
                                    AttractionFacilityID: element.ID,
                                    HasFacility: "Y",
                                    Quantity: element.Quantity
                                  })
                                });

                                this.props.submit('Facilities', addFacilities)
                              })
                            }}>
                            <input
                              type="radio"
                              id={i}
                              className="custom-control-input"

                              checked={e.isCheck === true ? false : true}
                            />
                            <label style={{ paddingLeft: 10 }}
                              className="custom-control-label"
                            // htmlFor="customRadio4"
                            >
                              ไม่มี
                            </label>
                          </div>
                          <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                            onClick={() => {
                              let arr = this.state.Facilities
                              arr[i].isCheck = true
                              this.setState({
                                Facilities: arr
                              }, () => {
                                let filter = this.state.Facilities.filter(item => item.isCheck === true)
                                let addFacilities = []
                                filter.forEach(element => {
                                  addFacilities.push({
                                    AttractionFacilityID: element.ID,
                                    HasFacility: "Y",
                                    // Total: element.total,
                                    Quantity: element.Quantity
                                  })
                                });
                                this.props.submit('Facilities', addFacilities)
                              })
                            }} >
                            <input
                              type="radio"

                              className="custom-control-input"
                              checked={
                                e.isCheck === true ? true : false}
                              id={i}
                            />
                            <label style={{ paddingLeft: 10 }}
                              className="custom-control-label"
                            // htmlFor="customRadio5"
                            >
                              มี
                            </label>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    {e.isCheck === true
                      ?
                      <div>
                        <blockquote className="p-3 border-dashed rounded mb-3 mt-3">
                          <Row className="mt-3">
                            <Col lg={5}>
                              <FormGroup>
                                <Label for="Name">รองรับจำนวน {e.Details[this.state.Lang].Name}</Label>
                                <Input style={{ fontSize: 16 }} required type="number" className="form-control" id="Name" placeholder="" value={e.Quantity} onChange={(e) => {
                                  let arr = this.state.Facilities
                                  arr[i].Quantity = e.target.value
                                  this.setState(
                                    {
                                      Facilities: arr
                                    }, () => {
                                      let filter = this.state.Facilities.filter(item => item.isCheck === true)
                                      let addFacilities = []
                                      filter.forEach(element => {
                                        addFacilities.push({
                                          AttractionFacilityID: element.ID,
                                          HasFacility: "Y",
                                          // Total: element.total,
                                          Quantity: element.Quantity,
                                          Latitude: element.Latitude,
                                          Longitude: element.Longitude
                                        })
                                      });
                                      this.props.submit('Facilities', addFacilities)
                                    }
                                  )
                                }} />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col lg={5}>
                              <FormGroup>
                                <Label for="Name">Latitude</Label>
                                <Input style={{ fontSize: 16 }} required type="number" className="form-control" id="Name" placeholder="" value={e.Latitude} onChange={(e) => {
                                  let arr = this.state.Facilities
                                  arr[i].Latitude = e.target.value
                                  this.setState(
                                    {
                                      Facilities: arr
                                    }, () => {
                                      let filter = this.state.Facilities.filter(item => item.isCheck === true)
                                      let addFacilities = []
                                      filter.forEach(element => {
                                        addFacilities.push({
                                          AttractionFacilityID: element.ID,
                                          HasFacility: "Y",
                                          // Total: element.total,
                                          Quantity: element.Quantity,
                                          Latitude: element.Latitude,
                                          Longitude: element.Longitude
                                        })
                                      });
                                      this.props.submit('Facilities', addFacilities)
                                    }
                                  )
                                }} />
                              </FormGroup>
                            </Col>
                            <Col lg={5}>
                              <FormGroup>
                                <Label for="Name">Longitude</Label>
                                <Input style={{ fontSize: 16 }} required type="number" className="form-control" id="Name" placeholder="" value={e.Longitude} onChange={(e) => {
                                  let arr = this.state.Facilities
                                  arr[i].Longitude = e.target.value
                                  this.setState(
                                    {
                                      Facilities: arr
                                    }, () => {
                                      let filter = this.state.Facilities.filter(item => item.isCheck === true)
                                      let addFacilities = []
                                      filter.forEach(element => {
                                        addFacilities.push({
                                          AttractionFacilityID: element.ID,
                                          HasFacility: "Y",
                                          // Total: element.total,
                                          Quantity: element.Quantity,
                                          Latitude: element.Latitude,
                                          Longitude: element.Longitude
                                        })
                                        console.log(element)
                                      });
                                      this.props.submit('Facilities', addFacilities)
                                    }
                                  )
                                }} />
                              </FormGroup>
                            </Col>
                          </Row>
                          { this.state.Facilities[i].Latitude && this.state.Facilities[i].Longitude ?
                            <Row className='pl-2'>
                              <a 
                                href={"https://www.google.com/maps?q="+this.state.Facilities[i].Latitude+","+this.state.Facilities[i].Longitude} 
                                target="_blank"
                              >
                                Preview Map
                              </a>
                            </Row>
                          :
                            null
                          }
                        </blockquote>
                      </div>
                      :
                      <div></div>
                    }
                  </div>
                )
              }
              else {
                return null
              }
            })}
          </div>
        }
        {this.props.lang === 'en' &&
          <FormGroup className="font-style-16 cl-black">
            <p style={{ color: '#173865' }}>
              (ให้จัดการข้อมูลที่ภาษาไทย)
            </p>
          </FormGroup>
        }
        {this.props.lang === 'zh' &&
          <FormGroup className="font-style-16 cl-black">
            <p style={{ color: '#173865' }}>
              (ให้จัดการข้อมูลที่ภาษาไทย)
            </p>
          </FormGroup>
        }
      </div>
    )
  }
}
