import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media, CardSubtitle, CardTitle, Nav, TabContent, CardText,
    TabPane,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,

} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { api_endpoint, endpoint } from '../../../../global-config'







export default class General extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Lang: 'th',
            registration_types: this.props.registration_types,
            accommodation_service_types: this.props.accommodation_service_types,
            AccommodationInfo: this.props.AccommodationInfo,
            LicenseDate: this.props.AccommodationInfo.LicenseDate,
            LicenseExpireDate: this.props.AccommodationInfo.LicenseExpireDate,
            cost_levels: this.props.cost_levels,
            Nature: false,
            Manmade: false,
            Sport: false,
            Event: false,
            idSelected: "",
            upload_images_loading: false,
        }
        console.log(props)
    }
    selectedHandle(data, type) {
        console.log(data)
        if (type === 'registration_types') {
            this.props.callback(JSON.parse(data).ID, 'registration_types')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'accommodation_service_types') {
            this.props.callback(JSON.parse(data).ID, 'accommodation_service_types')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'cost_levels') {
            this.props.callback(JSON.parse(data).ID, 'cost_levels')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'IsThaiHotalAssociation') {
            this.props.callback(data, 'IsThaiHotalAssociation')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'IsHotalRegistration') {
            this.props.callback(data, 'IsHotalRegistration')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'LicenseDate') {
            this.props.callback(data, 'LicenseDate')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'LicenseExpireDate') {
            this.props.callback(data, 'LicenseExpireDate')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }
        if (type === 'LicenseNo') {
            this.props.callback(data, 'LicenseNo')
            // this.props.callback(JSON.parse(data).Code, 'Province')
            return
        }


        // if (type === 'HasParking') {
        //     this.props.callback(data, 'HasParking')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'HasPreOrder') {
        //     this.props.callback(data, 'HasPreOrder')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'HasDelivery') {
        //     this.props.callback(data, 'HasDelivery')
        //     // this.props.callback(JSON.parse(data).Code, 'Province')
        //     return
        // }
        // if (type === 'Districts') {
        //     this.props.callback(JSON.parse(data).Code, 'District')
        //     return
        // }
        // else {
        //     this.props.callback(JSON.parse(data).Code, 'County')
        //     return
        // }
    }
    handleAcceptedFiles = async (files) => {
        if (files.length == 1) {
            let [file] = files
            console.log(file)
            var formData = new FormData(); // Currently empty
            formData.append('files', file, file.name);
            let upload_response = await fetch(api_endpoint + '/file/upload', {
                method: "POST",
                body: formData
            })
            let upload_response_object = await upload_response.json()
            if (upload_response_object.status == 'success') {
                console.log(upload_response_object.uploads[0])
                this.props.callback(upload_response_object.uploads[0], 'CoverImages')
                this.props.submit('IntroImage', upload_response_object.uploads[0])
            } else {
                alert('เกิดข้อผิดพลาดในการอัพโหลดไฟล์')
            }
        }
        this.setState({
            upload_images_loading: false
        })

    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            // registration_types: nextProps.registration_types,
            cost_levels: nextProps.cost_levels,
            // FoodTypes: nextProps.FoodTypes,


        })
    }

    componentDidMount() {
        // console.log(this.state.FoodTypes)
        // let filter = this.state.FoodTypes.filter(item => item.isCheck === true)
        // for (let i = 0; i < filter.length; i++) {
        //     filter[i] = { RestaurantFoodTypeID: filter[i].ID }
        // }
        // this.props.submit('FoodTypes', filter)


    }

    render() {

        return (
            <div>
                <p className={'text-24'}>ข้อมูลทั่วไป</p>
                <Row className={'mt-3 font-style-16 cl-black'}>
                    <Col lg="12">
                        <FormGroup>
                            <Label for="agencyName">ชื่อ </Label><Label className="required"> *</Label>
                            <Input required type="text" className="form-control" id="Name" placeholder="กรุณากรอกชื่อ" value={this.props.data.Name} onChange={(e) => { this.props.callback(e.target.value, 'Name') }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={'font-style-16 cl-black'}>
                    <Col lg="12">
                        <Label>รายละเอียด </Label><Label className="required"> *</Label>
                        <Input
                            type="textarea"
                            id="textarea"
                            onChange={(e) => { this.props.callback(e.target.value, 'Details') }}
                            // maxLength="225"
                            rows="3"
                            placeholder=""
                            value={this.props.data.Details}
                        />

                        {this.state.textareabadge ? (
                            <span className="badgecount badge badge-success">
                                {" "}
                                {this.state.textcount} / 225{" "}
                            </span>
                        ) : null}
                    </Col>
                </Row>
                {this.props.lang === 'th' ?
                    <Row className={'mt-3 font-style-16 cl-black'}>
                        <Col lg="12">
                            <Label>รูปภาพหน้าปก {this.props.data.test} </Label><Label className="required"> * {this.state.upload_images_loading && (<font color="green">โปรดรอกำลังอัพโหลดรูปภาพ</font>)}</Label>
                            <div style={{ display: typeof this.props.data.CoverImages.HashName != 'undefined' ? 'none' : '' }} className="custom-file" >
                                <input
                                    onChange={e => {
                                        this.setState({
                                            upload_images_loading: true
                                        }, () => {
                                            this.handleAcceptedFiles(e.target.files)
                                            e.target.value = ""
                                        }
                                        )
                                    }}
                                    accept=".jpeg,.gif,.png,.jpg"
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                />
                                <label className="custom-file-label" htmlFor="customFile">
                                    <span className="font-style-16b4 cl-gray"> Choose file</span>
                                    {/* {this.state.selectedFiles.length < 1 ?
                                        <span> Choose file</span>
                                        :
                                        <div>
                                            {this.state.selectedFiles.map(f => {
                                                return (<span style={{ marginRight: 10 }}>{f.name}</span>)
                                            })}
                                        </div>
                                    } */}
                                </label>
                                <p className="mt-3 font-style-14 darkblue">ขนาดไฟล์ไม่เกิน 2 MB, เฉพาะไฟล์นามสกุล: .jpg, .jpeg, .gif, .png และขนาดไฟล์ที่เหมาะสม: 800 x 600 pixel</p>
                            </div>
                            {typeof this.props.data.CoverImages.HashName != 'undefined' && (
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        <img style={{ maxWidth: 350 }} src={`${endpoint}/assets/upload${this.props.data.CoverImages.Path}/${this.props.data.CoverImages.HashName}`}></img>
                                        <div style={{ marginTop: 25 }}>
                                            <button onClick={() => {
                                                this.props.callback({}, 'CoverImages')
                                                this.props.submit('IntroImage', {})
                                            }} type="button" class="btn btn-danger waves-effect waves-light">ยกเลิกไฟล์</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                    :
                    <Row className={'mt-3 font-style-16 cl-black'}>
                        <Col lg="12">
                            <Label>รูปภาพหน้าปก </Label><Label className="required"> *</Label>
                            <p style={{ color: '#173865' }}>
                                (ให้จัดการข้อมูลที่ภาษาไทย)
                            </p>
                        </Col>
                    </Row>
                }
                {this.props.lang === 'th' ?
                    <Row className={'mt-3 font-style-16 cl-black'}>
                        <Col lg="12">
                            <FormGroup>
                                <Label for="Province">ประเภททะเบียน </Label><Label className="required"> *</Label>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <select className="form-control" onChange={(e) => {
                                            console.log(e.target)
                                            if (e.target.value === -1) {
                                                return
                                            }
                                            else {
                                                this.selectedHandle(e.target.value, 'registration_types')
                                            }
                                        }} >
                                            {this.state.registration_types.filter(item => item.isCheck === true).length < 1 ? <option value={-1}>กรุณาเลือกประเภททะเบียน</option> : null}
                                            {this.state.registration_types.map((e, i) => {

                                                if (e.isCheck === true) {

                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                            {this.state.registration_types.map((e, i) => {
                                                if (e.isCheck === false) {
                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    :
                    <div>
                        <Label className="font-style-16 cl-black">ประเภททะเบียน</Label>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }
                {this.props.lang === 'th' ?
                    <Row className={'mt-3 font-style-16 cl-black'}>
                        <Col lg="12">
                            <FormGroup>
                                <Label for="Province">ประเภทการให้บริการ </Label><Label className="required"> *</Label>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <select className="form-control" onChange={(e) => {
                                            console.log(e.target)
                                            if (e.target.value === -1) {
                                                return
                                            }
                                            else {
                                                this.selectedHandle(e.target.value, 'accommodation_service_types')
                                            }
                                        }} >
                                            {this.state.accommodation_service_types.filter(item => item.isCheck === true).length < 1 ? <option value={-1}>กรุณาเลือกประเภทการให้บริการ</option> : null}
                                            {this.state.accommodation_service_types.map((e, i) => {

                                                if (e.isCheck === true) {

                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                            {this.state.accommodation_service_types.map((e, i) => {
                                                if (e.isCheck === false) {
                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    :
                    <div>
                        <Label className="font-style-16 cl-black">ประเภทการให้บริการ</Label>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }
                {this.props.lang === 'th' ?
                    <Row className={'mt-3 font-style-16 cl-black'}>
                        <Col lg="12">
                            <FormGroup>
                                <Label for="Province">ช่วงราคา </Label><Label className="required"> *</Label>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <select className="form-control" onChange={(e) => {
                                            console.log(e.target)
                                            if (e.target.value === -1) {
                                                return
                                            }
                                            else {
                                                this.selectedHandle(e.target.value, 'cost_levels')
                                            }
                                        }} >
                                            {this.state.cost_levels.filter(item => item.isCheck === true).length < 1 ? <option value={-1}>กรุณาเลือกช่วงราคา</option> : null}
                                            {this.state.cost_levels.map((e, i) => {

                                                if (e.isCheck === true) {

                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                            {this.state.cost_levels.map((e, i) => {
                                                if (e.isCheck === false) {
                                                    return <option key={i} value={JSON.stringify(e)}>{e.Details[this.props.lang].Name}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    :
                    <div>
                        <Label className="font-style-16 cl-black">ประเภทการให้บริการ</Label>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }
                {this.props.lang === 'th' ?
                    <Row>
                        {/* <Col lg={2}>
                        <Label for="Name">การดำเนินการ</Label>
                    </Col>
                    <Col lg={4}>
                        <p for="Name">เป็นสมาชิกสมาคมโรงแรม</p>
                        <p for="Name">จดทะเบียนตาม พรบ. โรงแรม</p>
                    </Col> */}
                        <Col lg={3} className={'mt-3'}>
                            <h6 className="font-style-16 cl-black">การดำเนินการ</h6>
                        </Col>
                        <Col lg={9} className={'mt-3 font-style-16 cl-black'} style={{ flexDirection: 'column' }}>
                            <div>
                                <div className="custom-control custom-checkbox mb-3"
                                    onClick={() => {
                                        if (this.state.AccommodationInfo.IsThaiHotalAssociation === "Y") {
                                            this.selectedHandle("N", 'IsThaiHotalAssociation')
                                        }
                                        else {
                                            this.selectedHandle("Y", 'IsThaiHotalAssociation')
                                        }
                                    }}>
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={'IsThaiHotalAssociation'}
                                        checked={this.state.AccommodationInfo.IsThaiHotalAssociation === "Y" ? true : false}
                                    />
                                    <label
                                        className="custom-control-label"
                                    >
                                        เป็นสมาชิกสมาคมโรงแรม
                                    </label>
                                </div>
                                <div className="custom-control custom-checkbox mb-3"
                                    onClick={() => {
                                        if (this.state.AccommodationInfo.IsHotalRegistration === "Y") {
                                            this.selectedHandle("N", 'IsHotalRegistration')
                                        }
                                        else {
                                            this.selectedHandle("Y", 'IsHotalRegistration')
                                        }
                                    }}
                                >
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={'IsHotalRegistration'}
                                        onChange={() => false}
                                        checked={this.state.AccommodationInfo.IsHotalRegistration === "Y" ? true : false}
                                    />
                                    <label
                                        className="custom-control-label"
                                    >
                                        จดทะเบียนตาม พรบ. โรงแรม
                                    </label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    :
                    <div>
                        <Label className="font-style-16 cl-black">การดำเนินการ</Label>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }
                {this.props.lang === 'th' ?
                    <Row className="font-style-16 cl-black">
                        <Col lg="12">
                            <FormGroup>
                                <Label for="agencyName">เลขที่ใบอนุญาต</Label>
                                <Input required type="text" className="form-control" id="LicenseNo" placeholder="กรุณา เลขที่ใบอนุญาต" value={this.props.AccommodationInfo.LicenseNo} onChange={(e) => { this.props.callback(e.target.value, 'LicenseNo') }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    :
                    <div>
                        <Label className="font-style-16 cl-black">เลขที่ใบอนุญาต</Label>
                        <p className="font-style-16 cl-darkblue">
                            (ให้จัดการข้อมูลที่ภาษาไทย)
                        </p>
                    </div>
                }
                <Row className={'mt-3 font-style-16 cl-black'}>
                    {this.props.lang === 'th' ?
                        <Col lg={6}>
                            <div className="form-group">
                                <label>วันที่เริ่มต้น (เดือน/วัน/ปี)</label>
                                <input type="date" value={this.state.LicenseDate} onChange={(e) => {
                                    this.setState({
                                        LicenseDate: e.target.value
                                    }, () => {
                                        this.selectedHandle(e.target.value, 'LicenseDate')
                                    })
                                }} placeholder="ปรับปรุงตั้งแต่วันที่" className="form-control" ></input>
                            </div>
                        </Col>
                        :
                        <Col lg={6}>
                            <Label className="font-style-16 cl-black">วันที่เริ่มต้น</Label>
                            <p className="font-style-16 cl-darkblue">
                                (ให้จัดการข้อมูลที่ภาษาไทย)
                            </p>
                        </Col>
                    }
                    {this.props.lang === 'th' ?
                        <Col lg={6}>
                            <div className="form-group">
                                <label>วันที่สิ้นสุด (เดือน/วัน/ปี)</label>
                                <input type="date" value={this.state.LicenseExpireDate} onChange={(e) => {
                                    this.setState({
                                        LicenseExpireDate: e.target.value
                                    }, () => {
                                        this.selectedHandle(e.target.value, 'LicenseExpireDate')
                                    })
                                }} placeholder="ถึงวันที่" className="form-control" ></input>
                            </div>
                        </Col>
                        :
                        <Col lg={6}>
                            <Label className="font-style-16 cl-black">วันที่สิ้นสุด</Label>
                            <p className="font-style-16 cl-darkblue">
                                (ให้จัดการข้อมูลที่ภาษาไทย)
                            </p>
                        </Col>
                    }
                </Row>
            </div>
        )
    }
}
