import React, { Component } from 'react'
import {
    Button, Card, CardBody, CardSubtitle, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import pana from '../../../assets/images/ttd/pana.svg'
import DataGrid from '../../../components/Table/Table'
import MaterialTable from 'material-table';
import { Link } from "react-router-dom"
import logoplus from "../../../assets/images/ttd/Vector.svg"
import { api_endpoint } from '../../../global-config'
import Select from "react-select"
import Edit from '../../../assets/images/ttd/Edit.svg'

const memberLevelOptions = [
    { label: "SuperEditor", value: "SuperEditor" },
    { label: "Editor", value: "Editor" }
]

const statusOptions = [
    { label: "ใช้งาน", value: "Y" },
    { label: "ไม่ใช้งาน", value: "N" }
]

export default class ManageteamLangDetailTh extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lang: "th",
            Thaipage: true,
            Engpage: false,
            Chinapage: false,
            //---------------------------------------------//
            userData: JSON.parse(localStorage.getItem('authUser')),
            provinceSelected: null,
            districtSelected: null,
            subDistrictSelected: null,
            subDistrictSelected: null,
            memberTypeSelected: { label: 'โปรดเลือกประเภท', value: -1 },
            memberStatusSelected: { label: 'โปรดเลือดสถานะ', value: -1 },
            memberSearch: "",
            skip: 0,
            limit: 1,
            provinces: [],
            districts: [],
            subDistricts: [],
            teamData: [],
            mainInfoTypes: [],
            teamMember: [],
            teamName: ""
        }
        this.Thaipage = this.Thaipage.bind(this);
        this.Engpage = this.Engpage.bind(this);
        this.Chinapage = this.Chinapage.bind(this);
    }

    Thaipage() {

        this.setState({
            Thaipage: true,
            Engpage: false,
            Chinapage: false,
        })
    }

    Engpage() {

        this.setState({
            Engpage: true,
            Thaipage: false,
            Chinapage: false,
        })
    }

    Chinapage() {

        this.setState({
            Chinapage: true,
            Engpage: false,
            Thaipage: false,

        })
    }

    async getTeams(value) {
        const response = await fetch(
            api_endpoint + `/editorteam/readone`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + this.state.userData.token
                },
                body: JSON.stringify({
                    ID: Number(value)
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (_result.status === "ok") {
            //   const asyncRes = await Promise.all(_result.results.map(async (e, i) => {
            //     return { ...e, Name: e.Details[this.state.lang].Name, id: i.toString() }
            //   }));
            this.setState({
                teamData: _result.result,
                isLoading: false
            }, () => {
                this.get_provinces()
            })
        }
        else {
            console.log('error : getTeams')
        }
    }

    async get_provinces() {
        const response = await fetch(
            api_endpoint + `/lkup/editor_provinces`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    RegionID: [],
                    Code: [],
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        for (let i = 0; i < _result.length; i++) {
            if (_result[i].Code === this.state.teamData.ManageProvinceCode) {
                _result[i] = { label: _result[i].Details[this.state.lang].Name, value: _result[i].Code, isCheck: true }
                this.setState({
                    provinceSelected: _result[i]
                }, () => {
                    // console.log(this.state.provinceSelected)
                })
            }
            else {
                _result[i] = { label: _result[i].Details[this.state.lang].Name, value: _result[i].Code, isCheck: false }
            }
        }
        this.setState({
            provinces: _result
        }, () => {
            // console.log(this.state.teamData)
            this.mainInfoTypes()
            this.get_districts()
            // if (this.state.teamData.ManageType === "A") {
            //     this.get_districts()
            // }
        })
    }


    async get_districts() {
        const response = await fetch(
            api_endpoint + `/lkup/districts`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ProvinceCode: this.state.teamData.ManageProvinceCode,
                    // Code: [],

                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (Array.isArray(_result)) {
            for (let i = 0; i < _result.length; i++) {
                if (this.state.teamData.DistrictCode !== null) {
                    if (_result[i].Code === this.state.teamData.DistrictCode) {
                        this.setState({
                            districtSelected: { label: _result[i].Details[this.state.lang].Name, value: _result[i].Code, isCheck: true }
                        })
                        _result[i] = { label: _result[i].Details[this.state.lang].Name, value: _result[i].Code, isCheck: true }
                    }
                    else {
                        _result[i] = { label: _result[i].Details[this.state.lang].Name, value: _result[i].Code, isCheck: false }
                    }
                }
                else {
                    _result[i] = { label: _result[i].Details[this.state.lang].Name, value: _result[i].Code, isCheck: false }
                }
            }
            this.setState({
                districts: _result
            }, () => {
                this.get_subdistricts()
            })
        }
        else {
            console.log('error : get_districts')
        }

    }

    async get_subdistricts() {
        if (this.state.teamData.DistrictCode === null) {
            this.setState({
                isLoading: false
            }, () => {
                this.getTeamMember()
            })
            return
        }
        const response = await fetch(
            api_endpoint + `/lkup/subdistricts`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ProvinceCode: this.state.teamData.ManageProvinceCode,
                    DistrictCode: this.state.teamData.DistrictCode,

                    // Code: [],

                    // ID: this.state.place_id,
                    Lang: this.state.lang,
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        // console.log(_result)
        if (Array.isArray(_result)) {
            for (let i = 0; i < _result.length; i++) {
                if (this.state.teamData.SubDistrictCode !== null) {
                    if (_result[i].Code === this.state.teamData.SubDistrictCode) {
                        this.setState({
                            subDistrictSelected: { label: _result[i].Details[this.state.lang].Name, value: _result[i].Code, isCheck: true }
                        })
                        _result[i] = { label: _result[i].Details[this.state.lang].Name, value: _result[i].Code, isCheck: true }
                    }
                    else {
                        _result[i] = { label: _result[i].Details[this.state.lang].Name, value: _result[i].Code, isCheck: false }
                    }
                }
                else {
                    _result[i] = { label: _result[i].Details[this.state.lang].Name, value: _result[i].Code, isCheck: false }
                }
            }
            this.setState({
                subDistricts: _result,
                isLoading: false
            }, () => {
                this.getTeamMember()
            })
        }
        else {
            console.log('error : get_subdistricts')
        }
        this.setState({
            // subdistricts: _result,
            isLoading: false
        }, () => {
            this.getTeamMember()
        })
    }

    async getTeamMember() {
        const response = await fetch(
            api_endpoint + `/editorteam/member/read`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'bearer ' + this.state.userData.token
                },
                body: JSON.stringify({
                    EditorTeamID: this.state.teamData.ID,
                    MemberLevel: this.state.memberTypeSelected.value === -1 ? null : this.state.memberTypeSelected.value,
                    IsEnable: this.state.memberStatusSelected.value === -1 ? null : this.state.memberStatusSelected.value,
                    Search: this.state.memberSearch === "" ? null : this.state.memberSearch
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        console.log(_result)
        if (Array.isArray(_result.results)) {
            for (let i = 0; i < _result.results.length; i++) {
                _result.results[i] = {
                    id: _result.results[i].ID,
                    firstName: _result.results[i].FirstName,
                    lastName: _result.results[i].LastName,
                    meberLevel: _result.results[i].EditorTeam.MemberLevel,
                    isEnable: _result.results[i].EditorTeam.IsEnable
                }
                // for (let j = 0; j < this.state.teamData.MainTypes.length; j++) {
                //     if (this.state.teamData.MainTypes[j].MainTypeID === _result[i].ID) {
                //         _result[i] = { ..._result[i], isCheck: true }
                //     }
                //     else {
                //         if (_result[i].isCheck === true) {
                //             _result[i] = { ..._result[i], isCheck: true }
                //         }
                //         else {
                //             _result[i] = { ..._result[i], isCheck: false }
                //         }
                //     }
                // }
            }
            this.setState({
                teamMember: _result.results,
            }, () => {
                console.log(this.state.teamMember)
            })
        }
        else {
            console.log('error : getTeamMember')
        }
    }

    async mainInfoTypes() {
        const response = await fetch(
            api_endpoint + `/lkup/maininfo_types`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                }), // body data type must match "Content-Type" header
            }
        );
        var _result = await response.json();
        if (Array.isArray(_result)) {
            for (let i = 0; i < _result.length; i++) {
                for (let j = 0; j < this.state.teamData.MainTypes.length; j++) {
                    if (this.state.teamData.MainTypes[j].MainTypeID === _result[i].ID) {
                        _result[i] = { ..._result[i], isCheck: true }
                    }
                    else {
                        if (_result[i].isCheck === true) {
                            _result[i] = { ..._result[i], isCheck: true }
                        }
                        else {
                            _result[i] = { ..._result[i], isCheck: false }
                        }
                    }
                }
            }
            this.setState({
                mainInfoTypes: _result,
            }, () => {
                // console.log(this.state.mainInfoTypes)
            })
        }
        else {
            console.log('error : mainInfoTypes')
        }
    }




    inputHandle(value, type) {
        // console.log(value)
        // console.log(type)
        // console.log(this.state.teamData)
        if (type === 'Name') {
            this.state.teamData.Details[this.state.lang].Name = value
            this.setState({
                teamData: this.state.teamData
            })
        }
        if (type === 'Description') {
            this.state.teamData.Details[this.state.lang].Description = value
            this.setState({
                teamData: this.state.teamData
            })
        }
        if (type === 'Telephone') {
            this.state.teamData.Telephone = value
            this.setState({
                teamData: this.state.teamData
            })
        }
        if (type === 'MemberSearch') {
            this.setState({
                memberSearch: value
            })
        }
    }

    async selectedHandle(value, type) {
        // console.log(value)
        // console.log(type)
        // console.log(this.state.provinces)
        // console.log(this.state.provinceSelected)
        if (type === 'Provinces') {
            const asyncRes = await Promise.all(this.state.provinces.map(async (e) => {

                if (value.value === e.value) {
                    this.state.teamData.ManageProvinceCode = value.value
                    this.state.teamData.ManageProvinceName = value.label
                    this.state.teamData.DistrictCode = null
                    this.state.teamData.SubDistrictCode = null
                    return { ...e, isCheck: true }
                }
                else {
                    return { ...e, isCheck: false }
                }
            }))
            this.setState({
                provinces: asyncRes,
                provinceSelected: value,
                teamData: this.state.teamData,
                districtSelected: null,
                subDistrictSelected: null

            }, () => {
                this.get_districts()
            })
        }
        if (type === 'MainInfoTypes') {
            // console.log(this.state.mainInfoTypes)
            // console.log(value)
            const asyncRes = await Promise.all(this.state.mainInfoTypes.map(async (e) => {

                if (value.ID === e.ID) {
                    return { ...e, isCheck: !e.isCheck }
                }
                else {
                    return { ...e }
                }

            }))
            this.setState({
                mainInfoTypes: asyncRes
            })
        }
        if (type === 'typeControl') {
            this.state.teamData.ManageType = value
            this.setState({
                teamData: this.state.teamData
            }, () => {
                // console.log(this.state.teamData)
            })
        }
        if (type === 'isEnable') {
            this.state.teamData.IsEnable = value
            this.setState({
                teamData: this.state.teamData
            }, () => {
                // console.log(this.state.teamData)
            })
        }
        if (type === 'Districts') {
            const asyncRes = await Promise.all(this.state.districts.map(async (e) => {

                if (value.value === e.value) {
                    this.state.teamData.DistrictCode = value.value
                    this.state.teamData.SubDistrictCode = null
                    return { ...e, isCheck: true }
                }
                else {
                    return { ...e, isCheck: false }
                }

            }))
            this.setState({
                districts: asyncRes,
                districtSelected: value,
                teamData: this.state.teamData,
                subDistrictSelected: null
            }, () => {
                // console.log(this.state.teamData)
                this.get_subdistricts()
            })

        }
        if (type === 'SubDistricts') {
            const asyncRes = await Promise.all(this.state.subDistricts.map(async (e) => {

                if (value.value === e.value) {
                    this.state.teamData.SubDistrictCode = value.value
                    return { ...e, isCheck: true }
                }
                else {
                    return { ...e, isCheck: false }
                }

            }))
            this.setState({
                subDistricts: asyncRes,
                subDistrictSelected: value,
                teamData: this.state.teamData
            }, () => {
                // console.log(this.state.teamData)
            })

        }
        if (type === 'MemberType') {
            this.setState({
                memberTypeSelected: value
            }, () => {
                this.getTeamMember()
            })
        }
        if (type === 'MemberStatus') {
            this.setState({
                memberStatusSelected: value
            }, () => {
                this.getTeamMember()
            })
        }
    }

    async buttonHandle(type) {
        if (type === 'Update') {
            let filter = []
            await Promise.all(this.state.mainInfoTypes.map(async (e) => {
                if (e.isCheck === true) {
                    filter.push({ MainTypeID: e.ID })
                }
                else {
                    return
                }

            }))
            this.state.teamData.MainTypes = filter
            this.setState({
                teamData: this.state.teamData
            }, async () => {
                const response = await fetch(
                    api_endpoint + `/editorteam/update`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: 'bearer ' + this.state.userData.token
                        },
                        body: JSON.stringify(this.state.teamData), // body data type must match "Content-Type" header
                    }
                );
                var _result = await response.json();
                console.log(_result)
                if (_result.status === 'ok' && _result.success === true) {
                    alert('อัพเดทข้อมูลทีมสำเร็จ')
                    return
                }
                else {
                    alert('อัพเดทข้อมูลทีมไม่สำเร็จ')
                    console.log('error : Update')
                    return
                }
            })
        }
        if (type === 'MemberSearch') {
            this.getTeamMember()
        }
        if (type === 'ClearMemberSearch') {
            this.setState({
                MemberLevel: null,
                IsEnable: null,
                Search: null,
                memberTypeSelected: { label: 'โปรดเลือกประเภท', value: -1 },
                memberStatusSelected: { label: 'โปรดเลือดสถานะ', value: -1 },
                memberSearch: ""
            }, () => {
                this.getTeamMember()
            })
        }
    }

    dataGridPagging = (value) => {
        // if ((value.page * 5) === this.state.limit) {
        //   this.setState({
        //     isLoading: true,
        //     limit: this.state.limit + 5
        //   }, () => {
        //     this.getTeams()
        //   })
        // }
        // else {
        //   return
        // }
    }

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const teamID = params.get('teamID');
        this.setState({
            teamID: teamID
        })
        if (teamID !== null && teamID !== undefined) {
            this.getTeams(teamID)

        }

    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="หน้าแรก" link0="/dashboard-blog" breadcrumbItem="รายการทีมผู้จัดทำข้อมูล" link1="/Manageteammainpage" breadcrumbItems="รายละเอียดทีมผู้จัดทำข้อมูล" />
                        <Card>
                            <CardBody>
                                <Row style={{ padding: 15 }}>
                                    <div style={{ alignSelf: 'center', width: '70%' }}>
                                        <h1 className="text-24" style={{ color: '#173865' }}>รายละเอียดทีมผู้จัดทำข้อมูล</h1>
                                        <CardSubtitle className="text-16">กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนดำเนินการต่อไป </CardSubtitle>
                                    </div>
                                    <div style={{ textAlign: 'center', width: '30%' }}>
                                        <img style={{ width: '10vw', height: 'auto' }} src={pana}></img>
                                    </div>




                                </Row>
                                <hr></hr>
                                <Row>
                                    <Col lg={4} >
                                        <div style={{ textAlign: 'center' }}>
                                            <h3 className="text-18" style={{ color: this.state.Thaipage == true ? '#173865' : '#808080' }} onClick={() => { this.Thaipage() }}>ภาษาไทย</h3>
                                            {this.state.Thaipage == true && <div class="hr-line"></div>}
                                        </div>

                                    </Col>
                                    <Col lg={4} style={{ cursor: 'pointer' }}>

                                        <div style={{ textAlign: 'center' }}>
                                            <h3 className="text-18" style={{ color: this.state.Engpage == true ? '#173865' : '#808080' }} onClick={() => { this.Engpage() }}>English</h3>
                                            {this.state.Engpage == true && <div class="hr-line"></div>}
                                        </div>


                                    </Col>
                                    <Col lg={4} style={{ cursor: 'pointer' }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <h3 className="text-18" style={{ color: this.state.Chinapage == true ? '#173865' : '#808080' }} onClick={() => { this.Chinapage() }}>简体中文</h3>
                                            {this.state.Chinapage == true && <div class="hr-line"></div>}
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>


                        {this.state.Thaipage === true && this.state.isLoading === false && (
                            <Row>
                                <Col lg={6}>
                                    <Card style={{ minHeight: '50vh' }}>
                                        <CardBody>
                                            <p className={'text-24'}> ข้อมูลทั่วไป</p>
                                            <Row className={'mt-3 text-16'}>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <Label className="font-style-16 cl-black" for="Name">ชื่อ</Label><Label className="required"></Label><Label className="required">*</Label>
                                                        <Input style={{ fontSize: 14 }} required type="text" className="form-control" id="Name" placeholder="กรุณากรอกชื่อทีม" value={this.state.teamData.Details[this.state.lang].Name} onChange={(e) => { this.inputHandle(e.target.value, 'Name') }} />
                                                    </FormGroup>
                                                </Col>

                                            </Row>

                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <Label className="font-style-16 cl-black">รายละเอียด </Label><Label className="required">*</Label>
                                                        <Input
                                                            type="textarea"
                                                            id="textarea"
                                                            // maxLength="225"
                                                            rows="3"
                                                            placeholder="กรุณากรอกรายระเอียดทีม"
                                                            value={this.state.teamData.Details[this.state.lang].Description}
                                                            onChange={(e) => { this.inputHandle(e.target.value, 'Description') }}
                                                            isDisabled={true}
                                                        />
                                                        {this.state.textareabadge ? (
                                                            <span className="badgecount badge badge-success">
                                                                {" "}
                                                                {this.state.textcount} / 225{" "}
                                                            </span>
                                                        ) : null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <Label className="font-style-16 cl-black" for="Name">เบอร์โทรศัพท์ </Label><Label className="required"> *</Label>
                                                        <Input style={{ fontSize: 14 }} isDisabled={true} required className="form-control" id="Name" placeholder="กรุณากรอกเบอร์ติดต่อทีม" value={this.state.teamData.Telephone} onChange={(e) => { this.inputHandle(e.target.value, 'Telephone') }} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    <Card style={{ minHeight: '50vh' }}>
                                        <CardBody>
                                            <p className={'text-24'}> สิทธิ์การดำเนินการ</p>
                                            <Row className={'mt-3 '}>
                                                <Col lg="12">
                                                    <FormGroup className="select2-container">
                                                        <Label className="font-style-16 cl-black">จังหวัด </Label><Label className="required"> *</Label>
                                                        <Select styles={{
                                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                                        }}
                                                            value={this.state.provinceSelected}
                                                            onChange={(e) => this.selectedHandle(e, 'Provinces')}
                                                            options={this.state.provinces}
                                                            classNamePrefix="select2-selection" className="font-style-16"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={3}>
                                                    <Label className="font-style-16 cl-black" for="Name">ประเภท </Label><Label className="required"> *</Label>
                                                </Col>
                                                <Col lg={9}>
                                                    <Row>
                                                        <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                            onClick={() => this.selectedHandle("A", 'typeControl')}>
                                                            <input
                                                                type="radio"
                                                                id="typeArea"
                                                                name="typeArea"
                                                                className="custom-control-input"
                                                                checked={this.state.teamData.ManageType === "A" && true}
                                                            />
                                                            <label style={{ paddingLeft: 10 }}
                                                                className="custom-control-label font-style-16 cl-black"
                                                                htmlFor="customRadio4"
                                                            >
                                                                ตามพื้นที่
                                                            </label>
                                                        </div>
                                                        <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                            onClick={() => this.selectedHandle("F", 'typeControl')}>
                                                            <input
                                                                type="radio"
                                                                id="typeFunc"
                                                                name="typeFunc"
                                                                className="custom-control-input"
                                                                checked={this.state.teamData.ManageType === "F" && true}

                                                            />
                                                            <label style={{ paddingLeft: 10 }}
                                                                className="custom-control-label font-style-16 cl-black"
                                                                htmlFor="customRadio5"
                                                            >
                                                                ตามหมวดหมู่
                                                            </label>
                                                        </div>
                                                        {/* <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}>
                                                            <input
                                                                type="radio"
                                                                id="customRadio5"
                                                                name="status"
                                                                className="custom-control-input"
                                                            />
                                                            <label style={{ paddingLeft: 10 }}
                                                                className="custom-control-label"
                                                                htmlFor="customRadio5"
                                                            >
                                                                ตามข้อมูล
                            </label>
                                                        </div> */}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {(this.state.teamData.ManageType === "A" && this.state.provinceSelected && this.state.provinceSelected.value != '01') &&
                                                    <>
                                                        <Col lg={6}>
                                                            <FormGroup>
                                                                <Label className="font-style-16 cl-black" for="Name">อำเภอ </Label><Label className="required"> *</Label>
                                                                <Select
                                                                    value={this.state.districtSelected === null ? { label: 'กรุณาเลือกอำเภอ', value: -1 } : this.state.districtSelected}
                                                                    onChange={(e) => this.selectedHandle(e, 'Districts')}
                                                                    options={this.state.districts === null ? [] : this.state.districts}
                                                                    classNamePrefix="select2-selection" className="font-style-16 cl-black"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <FormGroup>
                                                                <Label className="font-style-16 cl-black" for="Name">ตำบล </Label><Label className="required"> *</Label>
                                                                <Select
                                                                    value={this.state.subDistrictSelected === null ? { label: 'กรุณาตำบล', value: -1 } : this.state.subDistrictSelected}
                                                                    onChange={(e) => this.selectedHandle(e, 'SubDistricts')}
                                                                    options={this.state.subDistricts === null ? [] : this.state.subDistricts}
                                                                    classNamePrefix="select2-selection" className="font-style-16 cl-black"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </>
                                                }
                                                {this.state.teamData.ManageType === "F" &&
                                                    <>
                                                        <Col lg={2}>
                                                            <Label className="font-style-16 cl-black" for="Name">หมวดหมู่ </Label><Label className="required"> *</Label>
                                                        </Col>
                                                        <Col lg={5} >
                                                            {this.state.mainInfoTypes.map((e, i) => {
                                                                if (i < 4) {
                                                                    return (
                                                                        <div key={i}
                                                                            onClick={() => this.selectedHandle(this.state.mainInfoTypes[i], 'MainInfoTypes')}>
                                                                            <div className="custom-control custom-checkbox mb-3">
                                                                                <input
                                                                                    key={i}
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    checked={e.isCheck}
                                                                                />
                                                                                <label
                                                                                    className="custom-control-label font-style-16 cl-black"
                                                                                >
                                                                                    {e.Details[this.state.lang].Name}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                            })}
                                                        </Col>
                                                        <Col lg={5} >
                                                            {this.state.mainInfoTypes.map((e, i) => {
                                                                if (i >= 4) {
                                                                    return (
                                                                        <div key={i}
                                                                            onClick={() => this.selectedHandle(this.state.mainInfoTypes[i], 'MainInfoTypes')}>
                                                                            <div className="custom-control custom-checkbox mb-3">
                                                                                <input
                                                                                    key={i}
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    checked={e.isCheck}
                                                                                />
                                                                                <label
                                                                                    className="custom-control-label font-style-16 cl-black"
                                                                                >
                                                                                    {e.Details[this.state.lang].Name}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                            })}
                                                        </Col>
                                                    </>
                                                }
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <p className={'text-24'} style={{ color: '#173865' }}>สถานะข้อมูล</p>
                                            <Row>
                                                <Col lg={2}>
                                                    <Label className="font-style-16 cl-black" for="Name">สถานะข้อมูล </Label><Label className="required"> *</Label>
                                                </Col>
                                                <Col lg={10}>
                                                    <Row>
                                                        <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                            onClick={() => this.selectedHandle("Y", 'isEnable')}>
                                                            <input
                                                                type="radio"
                                                                id="IsEnableY"
                                                                name="IsEnableY"
                                                                className="custom-control-input"
                                                                checked={this.state.teamData.IsEnable === "Y" && true}

                                                            />
                                                            <label style={{ paddingLeft: 10 }}
                                                                className="custom-control-label font-style-16 cl-black"
                                                                htmlFor="customRadio4"
                                                            >
                                                                เปิดใช้งาน
                                                            </label>
                                                        </div>
                                                        <div className="custom-control custom-radio mb-3" style={{ paddingRight: 20 }}
                                                            onClick={() => this.selectedHandle("N", 'isEnable')}>
                                                            <input
                                                                type="radio"
                                                                id="IsEnableN"
                                                                name="IsEnableN"
                                                                className="custom-control-input"
                                                                checked={this.state.teamData.IsEnable === "N" && true}
                                                            />
                                                            <label style={{ paddingLeft: 10 }}
                                                                className="custom-control-label font-style-16 cl-black"
                                                                htmlFor="customRadio5"
                                                            >
                                                                ระงับการใช้งาน
                                                            </label>
                                                        </div>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                            <Row style={{ justifyContent: 'flex-end' }}>
                                                {/* <div className="col-auto">
                                                    <Button
                                                        style={{ backgroundColor: '#DC3545', borderColor: '#DC3545' }}
                                                        type="submit"
                                                        onClick={this.tog_standard}
                                                        className="chat-send w-md waves-effect waves-light"
                                                    >
                                                        <span className="d-none d-sm-inline-block font-style-16nb">ลบข้อมูล</span>{" "}

                                                    </Button>
                                                </div> */}
                                                <div className="col-auto">
                                                    <Button
                                                        style={{ backgroundColor: '#F4D006', borderColor: '#F4D006' }}
                                                        type="submit"
                                                        onClick={() => { this.buttonHandle('Update') }}
                                                        className="chat-send w-md waves-effect waves-light"

                                                    >
                                                        <span className="d-none d-sm-inline-block font-style-16nb" >แก้ไขข้อมูล</span>{" "}

                                                    </Button>
                                                </div>
                                                {/* <div className="col-auto">
                                                    <Button
                                                        style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                                                        type="submit"
                                                        onClick={this.tog_standard}
                                                        className="chat-send w-md waves-effect waves-light"
                                                    >
                                                        <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ยกเลิก</span>{" "}
                                                    </Button>
                                                </div> */}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col lg={12}>
                                    {/* <h4 className="card-title mb-4">จัดการสมาชิก</h4> */}
                                    <Card>
                                        <CardBody>
                                            <Row style={{ justifyContent: 'center' }}>
                                                <Col>
                                                    <p className="text-24" style={{ color: '#173865' }}>รายการสมาชิก</p>
                                                </Col>

                                                <Col style={{ textAlign: 'end', alignSelf: 'center' }}>
                                                    <Link
                                                        style={{ backgroundColor: '#173865', borderColor: '#173865' }}
                                                        to={"/Manageteammainpage/ManageteamLangDetail/membersAdd?teamID=" + this.state.teamData.ID}
                                                        className="btn btn-primary waves-effect waves-light w-lg "
                                                    >
                                                        <img src={logoplus} style={{ padding: '0px 10px 3px 0px', width: 30 }} />
                                                        <span className="d-none d-sm-inline-block font-style-16nb" >เพิ่มสมาชิก</span>{" "}
                                                    </Link>
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                            <Row style={{ paddingTop: 10 }}>
                                                <Col>
                                                    <FormGroup>
                                                        <Input required type="text" className="form-control" id="Name" style={{ fontSize: 14 }}
                                                            placeholder="ค้นหาสิ่งที่คุณต้องการ..." value={this.state.memberSearch} onChange={(e) => { this.inputHandle(e.target.value, 'MemberSearch') }} />
                                                    </FormGroup>
                                                </Col>
                                                <div className="col-auto">
                                                    <div >
                                                        <Button
                                                            style={{
                                                                backgroundColor: '#FFFFFF', borderColor: '#808080'
                                                            }}
                                                            type="submit"
                                                            onClick={() => { this.buttonHandle('ClearMemberSearch') }}
                                                            className="chat-send w-md waves-effect waves-light"
                                                        >
                                                            <span className="d-none d-sm-inline-block font-style-16 " style={{ color: '#808080' }}>ล้างค่าค้นหา</span>{" "}
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <div  >
                                                        <Button
                                                            style={{ backgroundColor: '#F47806', borderColor: '#F47806' }}
                                                            type="submit"
                                                            onClick={() => { this.buttonHandle('MemberSearch') }}
                                                            className="chat-send w-md waves-effect waves-light"
                                                        >
                                                            <span className="d-none d-sm-inline-block font-style-16nb">ค้นหา</span>{" "}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row style={{ paddingBottom: 10 }}>
                                                <Col lg={6}>
                                                    <FormGroup>
                                                        <Label className="font-style-16 cl-black" for="Name">ประเภทสมาชิก</Label>
                                                        <Select
                                                            value={this.state.memberTypeSelected}
                                                            onChange={(e) => this.selectedHandle(e, 'MemberType')}
                                                            options={memberLevelOptions}
                                                            classNamePrefix="select2-selection" className="font-style-16"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={6}>
                                                    <FormGroup>
                                                        <Label className="font-style-16 cl-black" for="Name">สถานะ</Label>
                                                        <Select
                                                            value={this.state.memberStatusSelected}
                                                            onChange={(e) => this.selectedHandle(e, 'MemberStatus')}
                                                            options={statusOptions}
                                                            classNamePrefix="select2-selection" className="font-style-16"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <React.Fragment>
                                                <DataGrid
                                                    header={
                                                        [
                                                            { field: 'id', headerName: 'ลำดับ', width: 120 },
                                                            { field: 'firstName', headerName: 'ชื่อ', flex: 1 },
                                                            { field: 'lastName', headerName: 'นามสกุล', flex: 1 },
                                                            {
                                                                field: 'meberLevel', headerName: 'ประเภทผู้ใช้งาน', flex: 1
                                                            },
                                                            {
                                                                field: 'isEnable', headerName: 'สถานะ', flex: 1,
                                                                renderCell: (params) => {
                                                                    if (params.value === 'Y') {
                                                                        return (<div>ปกติ</div>)
                                                                    }
                                                                    if (params.value === 'N') {
                                                                        return <div>ระงับ</div>
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                field: 'Action', headerName: 'จัดการ',
                                                                renderCell: (params) => (
                                                                    <strong>
                                                                        <Link
                                                                            to={"/Manageteammainpage/ManageteamLangDetail/membersEdit?teamID=" + this.state.teamData.ID + "&memberID=" + params.row.id}
                                                                        >
                                                                            <img src={Edit} style={{ paddingRight: 10 }} />
                                                                            {/* <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                style={{ marginLeft: 16 }}
                                                              >
                                                                Edit
                              </Button> */}
                                                                        </Link>
                                                                    </strong>
                                                                ),
                                                            }]
                                                    }
                                                    data={this.state.teamMember}
                                                    rowsCount={this.state.teamMember.length}
                                                    pagging={this.dataGridPagging}
                                                    loading={this.state.isLoading}
                                                />
                                            </React.Fragment>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                        {this.state.Engpage == true && (
                            <p>Engpage</p>
                        )}
                        {this.state.Chinapage == true && (
                            <p>Chinapage</p>
                        )}
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}
