import React, { Component } from "react"
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row, Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import logoplus from "../../assets/images/ttd/Vector.svg"
import logodown from "../../assets/images/ttd/donw.png"
import { api_endpoint } from '../../global-config'
import DataGrid from '../../components/Table/NotiTable'
import Edit from '../../assets/images/ttd/Edit.svg'
const moment = require('moment'); // require
import localization from 'moment/locale/th';
moment.updateLocale('th', localization);


class notifyinformation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedGroup: null,
      maininfoType: [],
      regions: [],
      provinces: [],
      lang: 'th',
      skip: 0,
      limit: 10,
      updateBegin: null,
      updateEnd: null,
      maininfoTypeCode: null,
      proviceCode: null,
      regionCode: null,
      dataTable: [],
      sortModel: [{
        field: 'id',
        sort: 'desc'
      }],
      // pageSize: { value: 25, label: "25" },
      isLoading: true,
      rowsPerPageOptions: [10, 20, 50],
      pageSize: 10
    }

  }
  setPageSize = (value) => {
    this.setState({
      isLoading: true,
      skip: value,
      limit: value,
      pageSize: value
    }, () => {
      console.log(this.state.limit)
      this.getNeedApprove()
    })
  }

  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }

  handleSelect(target, value) {
    // console.log(target, value)
    if (target === 'regions') { this.setState({ regionCode: value, proviceCode: null }, () => { this.get_provinces() }) }
    if (target === 'provinces') { this.setState({ proviceCode: value }, () => { this.getNeedApprove() }) }
    if (target === 'maininfoType') { this.setState({ maininfoTypeCode: value }, () => { this.getNeedApprove() }) }
    // if (target === 'pageSize') { this.setState({ pageSize: value }, () => { this.getNeedApprove() }) }
  }

  handleSort = (value) => {
    let arr = this.state.sortModel
    // console.log(value)

    if (value.sortModel[0] === undefined) {
      arr[0].sort = 'asc'
      this.setState({
        sortModel: arr
      }, () => {
        this.getNeedApprove()
      })
    }
    else {
      arr[0].field = value.sortModel[0].field
      arr[0].sort = value.sortModel[0].sort
      this.setState({
        sortModel: arr
      }, () => {
        this.getNeedApprove()
      })
    }
  }
  dataGridPagging = (value) => {
    if ((value.page * 20) === this.state.limit) {
      this.setState({
        isLoading: true,
        limit: this.state.limit + 20
      }, () => {
        this.getNeedApprove()
      })
    }
    else {
      return
    }
  }
  dataGridPagging = (value) => {
    if ((value.page * this.state.pageSize) === this.state.limit) {
      this.setState({
        isLoading: true,
        limit: this.state.limit + this.state.pageSize
      }, () => {
        console.log(this.state.limit)
        this.getNeedApprove()
      })
    }
  }

  async get_maininfo_type() {
    const response = await fetch(
      api_endpoint + `/lkup/maininfo_types`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
        }),
      }
    );
    var _result = await response.json();
    let arr = []
    if (Array.isArray(_result)) { _result.map(e => { arr.push({ value: e.ID, label: e.Details[this.state.lang].Name }) }) }
    this.setState({
      maininfoType: arr
    }, () => {
      this.get_regions()
    })
  }

  async get_regions() {
    const response = await fetch(
      api_endpoint + `/lkup/regions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ID: []

          // ID: this.state.place_id,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    let arr = [{ value: 0, label: 'ทุกภูมิภาค' }]
    if (Array.isArray(_result)) { _result.map(e => { arr.push({ value: e.ID, label: e.Details[this.state.lang].Name }) }) }
    // console.log('---------' + arr)
    this.setState({
      regions: arr
    }, () => {
      this.get_provinces()
    })
  }

  async get_provinces() {
    const response = await fetch(
      api_endpoint + `/lkup/provinces`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          RegionID: (this.state.regionCode === null || this.state.regionCode.value === 0) ? [] : [Number(this.state.regionCode.value)],
          Code: [],
          // ID: this.state.place_id,
          Lang: this.state.lang,
        }), // body data type must match "Content-Type" header
      }
    );
    var _result = await response.json();
    let arr = []
    if (Array.isArray(_result)) { _result.map(e => { arr.push({ value: e.Code, label: e.Details[this.state.lang].Name }) }) }
    this.setState({
      provinces: arr
    }, () => {
      this.getNeedApprove()
    })
  }

  async getNeedApprove() {
    // if (this.state.pageSize.value > this.state.limit)
    //   this.state.limit = this.state.pageSize.value

    let sortBy = []
    const sortFields = {
      'id': 'ID',
      'MainInfoID': 'MainInfoID',
      'Name': "Main.Details." + this.state.lang + ".Name",
      'Province': "Main.Info.Province." + this.state.lang + ".Name",
      'MainTypeID': 'MainTypeID',
      'IsApproved': 'IsApproved',
      'CreatedAt': 'created_at'
    }
    if (this.state.sortModel[0].field !== undefined) {
      sortBy = [
        {
          field: sortFields[this.state.sortModel[0].field],
          sort: this.state.sortModel[0].sort === 'asc' ? 1 : -1
        }]
    }
    const userData = JSON.parse(localStorage.getItem('authUser'))
    const response = await fetch(
      api_endpoint + `/maininfo/auth/read`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: 'bearer ' + userData.token
        },
        body: JSON.stringify({
          Lang: this.state.lang,
          Skip: this.state.skip,
          MainTypeID: this.state.maininfoTypeCode === null ? [] : [Number(this.state.maininfoTypeCode.value)],
          ProvinceCode: this.state.proviceCode === null ? [] : [this.state.proviceCode.value],
          RegionID: (this.state.regionCode === null || this.state.regionCode.value === 0) ? [] : [Number(this.state.regionCode.value)],
          Limit: this.state.limit,
          SortBy: sortBy,
          UpdatedBegin: this.state.updateBegin,
          UpdatedEnd: this.state.updateEnd,
          Update: "Y",
          IsBackend: "Y"
        }), // body data type must match "Content-Type" header
      }
    );

    var _result = await response.json();

    // console.log(_result)
    if (_result.results !== undefined) {
      let arr = []
      _result.results.map((e) => {
        arr.push({
          id: e.ID,
          MainInfoID: e.MainInfoID,
          Name: e.Name[this.state.lang],
          Province: e.Province[this.state.lang],
          MainTypeID: e.MainTypeID,
          User: e.User.CreatedBy.Email,
          IsApproved: e.IsApproved,
          CreatedAt: e.CreatedAt
        })

      })
      this.setState({
        countData: _result.count,
        dataTable: arr,
        isLoading: false
      })
    }
    else {
      // console.log('error : getNeedApprove')
    }

  }





  componentDidMount() {
    this.get_maininfo_type()
  }

  render() {
    const { selectedGroup } = this.state
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="หน้าแรก" breadcrumbItem="แจ้งแก้ไขข้อมูล" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom">
                    <Row>
                      <Col>
                        <h1 style={{ color: '#173865' }}>แจ้งแก้ไขข้อมูล</h1>
                      </Col>

                      {/* <Col style={{ textAlign: 'end', alignSelf: 'center' }}>
                        <Link
                          style={{ backgroundColor: '#173865', borderColor: '#173865' }}
                          to="/Spa/SpaDetail"
                          className="btn btn-primary waves-effect waves-light w-lg "
                        >
                          <img src={logoplus} style={{ padding: '0px 10px 3px 0px', width: 30 }} />
                          เพิ่มสปา{" "}
                        </Link>
                      </Col> */}

                    </Row>


                  </CardBody>
                  <CardBody className="pb-auto">
                    {/* <Row>
                      <Col>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control rounded chat-input"
                            style={{ fontSize: 16 }}
placeholder="ค้นหาสิ่งที่คุณต้องการ..."
                          />

                        </div>
                      </Col>
                      <div className="col-auto">
                        <Button
                          style={{ backgroundColor: '#FFFFFF', borderColor: '#808080' }}
                          type="submit"

                          className="chat-send w-md waves-effect waves-light"
                        >
                          <span className="d-none d-sm-inline-block font-style-16nb" style={{ color: '#808080' }}>ล้างค่าค้นหา</span>{" "}

                        </Button>
                      </div>
                      <div className="col-auto">
                        <Button
                          style={{ backgroundColor: '#173865', borderColor: '#173865' }}
                          type="submit"

                          className="chat-send w-md waves-effect waves-light"
                        >
                          <span className="d-none d-sm-inline-block font-style-16nb">ค้นหา</span>{" "}

                        </Button>
                      </div>
                    </Row> */}
                    <Row >
                      <Col lg={4}>
                        <FormGroup className="select2-container">
                          <label className="font-style-16 cl-black">ภูมิภาค</label>
                          <Select
                            value={this.state.regionCode === null ? { label: 'กรุณาเลือกภูมิภาค', value: -1 } : this.state.regionCode}
                            onChange={(e) => { this.handleSelect('regions', e) }}
                            options={this.state.regions}
                            classNamePrefix="select2-selection" className="font-style-16b4"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={4}>
                        <FormGroup className="select2-container">
                          <label className="font-style-16 cl-black">จังหวัด</label>
                          <Select
                            value={this.state.proviceCode === null ? { label: 'กรุณาเลือกจังหวัด', value: -1 } : this.state.proviceCode}
                            onChange={(e) => { this.handleSelect('provinces', e) }}
                            options={this.state.provinces}
                            classNamePrefix="select2-selection" className="font-style-16b4"
                          />
                          {/* <input type="date" value={this.state.selected_type} onChange={(e) => {
                            this.setState({
                              selected_type: e.target.value
                            })
                          }} placeholder="ประเภท" className="form-control" ></input> */}
                        </FormGroup>
                      </Col>
                      <Col lg={4}>
                        <FormGroup className="select2-container">
                          <label className="font-style-16 cl-black">ประเภท</label>
                          <Select
                            value={this.state.maininfoTypeCode === null ? { label: 'กรุณาเลือกประเภท', value: -1 } : this.state.maininfoTypeCode}
                            onChange={(e) => { this.handleSelect('maininfoType', e) }}
                            options={this.state.maininfoType}
                            classNamePrefix="select2-selection" className="font-style-16"
                          />
                          {/* <input type="date" value={this.state.selected_type} onChange={(e) => {
                            this.setState({
                              selected_type: e.target.value
                            })
                          }} placeholder="ประเภท" className="form-control" ></input> */}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row >

                      <Col lg={4}>
                        <div className="form-group">
                          <label className="font-style-16 cl-black">ปรับปรุงตั้งแต่วันที่</label>
                          <input type="date" value={this.state.updateBegin} onChange={(e) => {
                            this.setState({
                              updateBegin: e.target.value
                            }, () => { this.getNeedApprove() })
                          }} placeholder="ปรับปรุงตั้งแต่วันที่" className="form-control" style={{ fontSize: 16 }}></input>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="form-group">
                          <label className="font-style-16 cl-black">ถึงวันที่</label>
                          <input type="date" value={this.state.updateEnd} onChange={(e) => {
                            this.setState({
                              updateEnd: e.target.value
                            }, () => { this.getNeedApprove() })
                          }} placeholder="ถึงวันที่" className="form-control" style={{ fontSize: 16 }}></input>
                        </div>
                      </Col>
                      {/* <Col lg={4}>
                        <FormGroup className="select2-container">
                          <label className="font-style-16 cl-black">จำนวนแถว</label>
                          <Select
                            value={this.state.pageSize}
                            onChange={(e) => { this.handleSelect('pageSize', e) }}
                            options={[{ value: 10, label: "10" }, { value: 25, label: "25" }, { value: 50, label: "50" }, { value: 100, label: "100" }]}
                            classNamePrefix="select2-selection" className="font-style-16"
                          />
                        </FormGroup>
                      </Col> */}

                    </Row>


                  </CardBody>

                </Card>

              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <DataGrid

                      header={
                        [
                          { field: 'id', headerName: 'ID การแก้ไข', flex: 1 },
                          { field: 'MainInfoID', headerName: 'ID ต้นฉบับ', flex: 1 },
                          { field: 'Name', headerName: 'ชื่อสถานที่', flex: 2 },
                          { field: 'Province', headerName: 'จังหวัด', flex: 1 },
                          {
                            field: 'MainTypeID', headerName: 'ประเภท', flex: 1,
                            renderCell: (params) => {
                              if (params.value === 1) { return (<div>แหล่งท่องเที่ยว</div>) }
                              if (params.value === 2) { return (<div>กิจกรรมท่องเที่ยว</div>) }
                              if (params.value === 3) { return (<div>ผู้ประกอบธุรกิจนำเที่ยว</div>) }
                              if (params.value === 4) { return (<div>ที่พัก</div>) }
                              if (params.value === 5) { return (<div>สปา</div>) }
                              if (params.value === 6) { return (<div>ร้านอาหาร</div>) }
                              if (params.value === 7) { return (<div>ร้านค้าของฝาก</div>) }
                              if (params.value === 8) { return (<div>มัคคุเทศก์</div>) }
                              if (params.value === 9) { return (<div>เส้นทางการท่องเที่ยว</div>) }
                            }
                          },
                          { field: 'User', headerName: 'ผู้ขอแก้ไข', flex: 1 },
                          {
                            field: 'IsApproved', headerName: 'การพิจารณา', flex: 1,
                            renderCell: (params) => {
                              if (params.value === 'N') { return (<div>รอพิจารณา</div>) }
                              if (params.value === 'Y') { return (<div>ผ่าน</div>) }
                              if (params.value === 'R') { return (<div>ปฏิเสธ</div>) }
                            }
                          },
                          {
                            field: 'CreatedAt', headerName: 'อัพเดท', flex: 1,
                            renderCell: (params) => (
                              <div>
                                {moment(params.value).format('llll')}
                              </div>
                            )
                          },
                          {
                            field: 'Action', headerName: 'จัดการ', width: 120,
                            renderCell: (params) => {
                              // console.log(params.row.MainTypeID)
                              let path = '/#'
                              if (params.row.MainTypeID === 1) { path = "/managelocationDetail?id=" + params.id + "&status=Y" }
                              if (params.row.MainTypeID === 2) { path = "/TravelactivitiesDetail?id=" + params.id + "&status=Y" }
                              if (params.row.MainTypeID === 3) { path = "/Travelbusinessoperator/TravelbusinessoperatorDetail?id=" + params.id + "&status=Y" }
                              if (params.row.MainTypeID === 4) { path = "/Accommodation/AccommodationDetail?id=" + params.id + "&status=Y" }
                              if (params.row.MainTypeID === 5) { path = "/Spa/SpaDetail?id=" + params.id + "&status=Y" }
                              if (params.row.MainTypeID === 6) { path = "/Restaurant/RestaurantDetail?id=" + params.id + "&status=Y" }
                              if (params.row.MainTypeID === 7) { path = "/Shop/ShopDetail?id=" + params.id + "&status=Y" }
                              if (params.row.MainTypeID === 9) { path = "/TouristRoutes/TouristRoutesDetail?id=" + params.id + "&status=Y" }
                              // if(params.row.MainTypeID === 8 ){path="/ShopDetail?id=" + params.id + "&status=Y"}
                              return (
                                <strong>
                                  <Link to={path}

                                  >
                                    <img src={Edit} style={{ paddingRight: 10 }} />
                                  </Link>

                                </strong>
                              )
                            },
                          }]
                      }
                      sortModel={this.state.sortModel}
                      data={this.state.dataTable}
                      rowsCount={this.state.countData}
                      pageSize={this.state.pageSize}
                      pagging={this.dataGridPagging}
                      rowsPerPageOptions={this.state.rowsPerPageOptions}
                      loading={this.state.isLoading}
                      sort={this.handleSort}
                      setPageSize={this.setPageSize}

                    /></CardBody>

                </Card>

              </Col>
            </Row>





          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default notifyinformation
